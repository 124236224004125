import { useTranslation } from 'react-i18next';

import PageContainer from '@/components/Container/PageContainer';
import FormStepper from './FormStepper';

const DealInitiation = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <PageContainer title={t('dealInitiation')}>
      <FormStepper />
    </PageContainer>
  );
};

export default DealInitiation;

import { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Paper, TextField } from '@mui/material';
import {
  Avatar,
  ChatContent,
  ChatWrapper,
  OppositeMassage,
  SelfMessage,
  SendBtn,
  SelfMessageLink,
  OppositeMessageLink,
} from '../style';
import { Text } from '@/components/UI/Typography/Text';
import { Send } from '@/components/Icon';
import { Flex } from '@/layout';
import { sendMessageToChatroom, getChatDetail } from '@/api/chat.api';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, unreadCounterChange } from '@/redux/auth/auth.slice';
import { convertTiming } from '@/utils/convert';
import Loading from '@/components/UI/Loading';
import { useSocket } from '@/hooks/useSocket';
import { useDispatch } from 'react-redux';

interface WSChatResponse {
  id: number;
  triggered_by: number;
  message: string;
  user_id: number;
  created_at: string;
  sender: {
    acc_coname: string;
    acc_fitstName: string;
    acc_lastName: string;
    acc_logo: string;
    id: number;
  };
}

const ChattingWindow = ({ id, chatRoomData }: { id: number; chatRoomData: ChatList }) => {
  // @ts-ignore
  const {t} = useTranslation();

  const [data, setData] = useState<ChatMessage[]>();
  const { handleSubmit, control, setValue } = useForm<{ message: string }>();
  const windowRef = useRef<HTMLDivElement>(null);
  const [sending, setSending] = useState(true);
  const dispatch = useDispatch();

  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  useSocket({
    type: 'JOIN_CHAT',
    callBack: (wsResponse: WSChatResponse) => {
      console.log('chat:', wsResponse);
      if (wsResponse.user_id !== userData.id) {
        if (data && wsResponse.id !== data[0].id) {
          updateMessage({
            id: wsResponse.id,
            user_id: wsResponse.user_id,
            message: wsResponse.message,
            created_at: wsResponse.created_at,
            sender: {
              acc_coname: wsResponse.sender?.acc_coname,
              acc_firstName: wsResponse.sender?.acc_fitstName,
              acc_lastName: wsResponse.sender?.acc_lastName,
              acc_logo: wsResponse.sender?.acc_logo,
              id: wsResponse.sender?.id,
            },
          });
          dispatch(unreadCounterChange(userData.unread_counter - 1));
        }
      }
    },
    roomId: id,
  });

  const updateMessage = (data: ChatMessage) => {
    setData((prev) => {
      if (prev) {
        return [...prev, data];
      } else {
        return [data];
      }
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    if (sending) return;
    if (id === 0) return;
    setSending(true);

    try {
      const res = (await sendMessageToChatroom(id, data)).data;
      if (res.success) {
        setValue('message', '');
        // 傳送成功後將資料加入data
        updateMessage({
          id: res.data.id,
          user_id: userData.id,
          message: res.data.message,
          created_at: res.data.created_at,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSending(false);
    }
  });

  const initData = useCallback(async () => {
    if (id === 0) return;
    const res = (await getChatDetail(id)).data;
    setData(res.data);
    setSending(false);
  }, [id]);

  // 監測data的變化，如果有變化就將視窗拉到最下面
  useEffect(() => {
    if (windowRef.current) {
      windowRef.current.scrollTop = windowRef.current.scrollHeight;
    }
  }, [data]);

  useEffect(() => {
    initData();
  }, [initData]);

  // 監視id變化來決定是否清空data和取消訂閱
  useEffect(() => {
    return () => {
      // 清空資料等待新的資料進來
      setData(undefined);
    };
  }, [id]);

  function FormatMessage({ text, side }: { text: string; side: 'opposite' | 'self' }) {
    const splitText = text.split(' ');

    const processText = (part: string, index: number) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      if (part.match(urlRegex)) {
        return side ? (
          <>
            {' '}
            <SelfMessageLink key={index} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </SelfMessageLink>
          </>
        ) : (
          <>
            {' '}
            <OppositeMessageLink key={index} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </OppositeMessageLink>
          </>
        );
      } else {
        return <span key={index}> {part}</span>;
      }
    };

    const processedText = splitText.map((part, index) => processText(part, index));

    return side === 'self' ? (
      <Text fontSize={17} color={'#fff'}>
        {processedText}
      </Text>
    ) : (
      <Text fontSize={17} marginBottom={'5px'}>
        {processedText}
      </Text>
    );
  }

  return (
    <ChatWrapper>
      {id === 0 ? (
        <div style={{ minHeight: 820 }}></div>
      ) : (
        <>
          <Text padding={'0 25px'} fontSize={20} fontWeight={'bold'}>
            {chatRoomData.name}
          </Text>
          {chatRoomData.type_id === 1 && (
            <Text padding={'0 25px'} fontSize={17}>
              {chatRoomData.participants_count} {t('info.members')}
            </Text>
          )}
          <ChatContent ref={windowRef}>
            {data &&
              data.map((item, index) => {
                if (item.user_id !== userData.id) {
                  return (
                    <OppositeMassage key={item.message + index}>
                      <Avatar>
                        <img src={item.sender?.acc_logo ?? '/images/default_company_logo.jpg'} alt="avatar" />
                      </Avatar>
                      <div className="content">
                        <Text fontSize={17} fontWeight={'bold'}>
                          {item.sender?.acc_firstName} {item.sender?.acc_lastName}
                        </Text>
                        <div className="message">
                          <FormatMessage text={item.message} side={'opposite'} />
                        </div>
                        <Text color={'#999999'} fontSize={17}>
                          {convertTiming(item.created_at)}
                        </Text>
                      </div>
                    </OppositeMassage>
                  );
                } else {
                  return (
                    <SelfMessage key={item.message + index}>
                      <div className="content">
                        <div className="message">
                          <FormatMessage text={item.message} side={'self'} />
                        </div>
                        <Text color={'#999999'} fontSize={17} textAlign={'right'}>
                          {convertTiming(item.created_at)}
                        </Text>
                      </div>
                    </SelfMessage>
                  );
                }
              })}
            {!data && <Loading />}
          </ChatContent>
          <form onSubmit={onSubmit}>
            <Flex gridGap={15} padding={'0 25px'}>
              <Controller
                control={control}
                name={'message'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label=""
                    variant="outlined"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    autoComplete="off"
                    placeholder="Enter"
                    disabled={sending}
                  />
                )}
              />
              <SendBtn type="submit">
                <Send.SendSVG />
              </SendBtn>
            </Flex>
          </form>
        </>
      )}
    </ChatWrapper>
  );
};

export default ChattingWindow;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import { ContainerWrapper, Title, CardWrapper, CardsList } from './style';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Link } from 'react-router-dom';
import useResize from '@/hooks/useResize';
import { getFeatures } from '@/api/frontData.api';
import PATH from '@/router/path';
import { LoadingPage } from '@/layout';
import { sify } from 'chinese-conv/dist'
import { errorHandler } from '@/utils/toastHandler';

const Features = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const { isTablet, isMobile } = useResize();
  const [isLoading, setIsLoading] = useState(false);

  const [features, setFeatures] = useState<BannerFeature[]>([]);

  const initData = async () => {
    setIsLoading(true);
    try {
      const features = (await getFeatures()).data;
      // 按照weight排序, 0為最優先, 若weight相同則按照created_at排序
      const sortedFeatures = features.sort((a, b) => {
        if (a.weight === b.weight) {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        }
        return b.weight - a.weight;
      });
      setFeatures(sortedFeatures);
    } catch (error) {
      console.log(error);
      errorHandler(t('error.server'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [lang]);

  return (
    <>
      <LoadingPage isLoading={isLoading} />
      <ContainerWrapper>
        <Title>{t('pages.index.Features.title')}</Title>
        <CardsList isTablet={isTablet} isMobile={isMobile}>
          {features.map((feature) => (
            <CardWrapper key={feature.title + feature.title_en} isTablet={isTablet} isMobile={isMobile}>
              <div className="pic">
                <img src={feature.img_url} alt="" />
              </div>
              <div className="content">
                <h2>{lang === 'en' ? feature.title_en : lang === 'tc' ? feature.title : sify(feature.title)}</h2>
                <p>{lang === 'en' ? feature.description_en : lang === 'tc' ? feature.description : sify(feature.description)}</p>
                <Link to={PATH.features.detail.replace(':id', feature.id.toString())}>{t('buttons.howtoUse')}</Link>
              </div>
            </CardWrapper>
          ))}
        </CardsList>
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default Features;

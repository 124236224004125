const categorySc = [
  "法律服务",
  "影视行业",
  "建筑工程",
  "金融及保险",
  "信息科技",
  "制造业",
  "专业或商业服务",
];

export default categorySc;

export const FormDataToRawData = (data: LEIRequestData, noReasonshipReasons: NoRelationshipReasonOptions[]) => {
  if (!data) return;
  if (!data.basicInformation) return;
  if (!data.address) return;
  if (!data.legalBusiness) return;
  if (!data.directParent) return;
  if (!data.ultimateParent) return;

  const { basicInformation, address, legalBusiness, directParent, ultimateParent } = data;
  return {
    LEIRecord: {
      legalCode: legalBusiness.businessRegistrationCertificateNumber,
      fileContact:
        basicInformation.contactIdentificationDocument instanceof File
          ? basicInformation.contactIdentificationDocument
          : undefined,
      fileCorporation:
        basicInformation.corporationIdentificationDocument instanceof File
          ? basicInformation.corporationIdentificationDocument
          : undefined,
      fileRA:
        legalBusiness.businessRegistrationCertificateDocument instanceof File
          ? legalBusiness.businessRegistrationCertificateDocument
          : undefined,
      fileRA1:
        legalBusiness.certificateOfIncorporationCopy instanceof File
          ? legalBusiness.certificateOfIncorporationCopy
          : undefined,
      fileRA2: legalBusiness.annualReturn instanceof File ? legalBusiness.annualReturn : undefined,
      fileBms: undefined,
      fileDirectRA:
        directParent.corporationIdentificationDocument instanceof File
          ? directParent.corporationIdentificationDocument
          : undefined,
      fileDirectHolding: directParent.cerifiedDocuments instanceof File ? directParent.cerifiedDocuments : undefined,
      fileUltimateRA:
        ultimateParent.corporationIdentificationDocument instanceof File
          ? ultimateParent.corporationIdentificationDocument
          : undefined,
      fileUltimateHolding:
        ultimateParent.cerifiedDocuments instanceof File ? ultimateParent.cerifiedDocuments : undefined,
      fileApplyPdf: undefined,
      fileTransferPdf: undefined,
      LEIRecord: {
        LegalNameLanguage: basicInformation.legalNameLanguage,
        LegalName: basicInformation.legalName,
        OtherLegalNameLanguage: basicInformation.otherLegalNameLanguage ? basicInformation.otherLegalNameLanguage : 'en',
        OtherLegalName: basicInformation.otherLegalName ? basicInformation.otherLegalName : '',
        EntityLegalCategory: basicInformation.entityLegalCategory,
        Corporation: basicInformation.corporation,
        HeadLEI: '',
        AssociatedLEIsHeadLEI: '',
        Email: basicInformation.email,
        EntityCreationDate: basicInformation.entityCreationDate,
        UsePurp: basicInformation.usePurpose ?? '',
        LegalForm: {
          LegalFormCode: legalBusiness.legalFormCode,
          OtherLegalFormName: '',
          LegalFormCountry: legalBusiness.legalFormCountry,
          LegalFormLanguage: legalBusiness.legalFormLanguage,
        },
        RegisterAddress: address.registerAddress,
        RegisterAddressOther: address.otherRegisterAddress,
        OfficeAddress: address.officeAddress,
        OfficeAddressOther: address.otherOfficeAddress,
        RAInformation: {
          CreditCode: legalBusiness.businessRegistrationCertificateNumber,
          RACode: legalBusiness.registrationAuthorityCode,
          RegisterName: legalBusiness.businessRegistrationOffice,
        },
        // BmsCode: legalBusiness.financialInsitutionCode || '',
        UserInfo: {
          ContactName: basicInformation.contactName,
          ContactDepartment: basicInformation.contactDepartment,
          ContactMail: basicInformation.contactMail,
          ContactTel: basicInformation.contactPhone,
        },
        DirectRelationShip: {
          Reason: !directParent.provideDirectParentCompanyInfo
            ? noReasonshipReasons[parseInt(directParent.reason)].code
            : '',
          RelationShip: {
            ParentLeiCode: directParent.directParentLeiCode,
            ParentNoCode: {
              LegalNameLanguage: directParent.legalNameLanguage,
              LegalName: directParent.legalName,
              OtherLegalNameLanguage: directParent.otherLegalNameLanguage ? directParent.otherLegalNameLanguage : 'en',
              OtherLegalName: directParent.otherLegalName ? directParent.otherLegalName : '',
              EntityLegalCategory: directParent.entityLegalCategory,
              Corporation: directParent.corporation,
              LegalForm: {
                LegalFormCode: directParent.legalFormCode,
                OtherLegalFormName: '',
                LegalFormCountry: directParent.legalFormCountry,
                LegalFormLanguage: directParent.legalFormLanguage,
              },
              RegisterAddress: directParent.registerAddress,
              RegisterAddressOther: directParent.otherRegisterAddress || undefined,
              OfficeAddress: directParent.officeAddress,
              OfficeAddressOther: directParent.otherOfficeAddress || undefined,
              RAInformation: {
                CreditCode: directParent.businessRegistrationCertificateNumber,
                RACode: directParent.registrationAuthorityCode,
                RegisterName: directParent.businessRegistrationOffice,
              },
            },
            CheckFileType: directParent.checkFileType,
            CriterionType: directParent.criterionType,
            RelationShipStartDate: directParent.relationShipStartDate,
            RelationShipEndDate: directParent.relationShipEndDate,
            AccountStartTime: directParent.accountStartTime,
            AccountEndTime: directParent.accountEndTime,
            DocStartTime: directParent.docStartTime,
            DocEndTime: directParent.docEndTime,
            FileCheckUrl: directParent.fileCheckUrl,
          },
        },
        UltimateRelationShip: {
          Reason: !ultimateParent.provideDirectParentCompanyInfo
            ? noReasonshipReasons[parseInt(ultimateParent.reason)].code
            : '',
          RelationShip: {
            ParentLeiCode: ultimateParent.ultimateParentLeiCode,
            ParentNoCode: {
              LegalNameLanguage: ultimateParent.legalNameLanguage,
              LegalName: ultimateParent.legalName,
              OtherLegalNameLanguage: ultimateParent.otherLegalNameLanguage ? ultimateParent.otherLegalNameLanguage : 'en',
              OtherLegalName: ultimateParent.otherLegalName ? ultimateParent.otherLegalName : '',
              EntityLegalCategory: ultimateParent.entityLegalCategory,
              Corporation: ultimateParent.corporation,
              LegalForm: {
                LegalFormCode: ultimateParent.legalFormCode,
                OtherLegalFormName: '',
                LegalFormCountry: ultimateParent.legalFormCountry,
                LegalFormLanguage: ultimateParent.legalFormLanguage,
              },
              RegisterAddress: ultimateParent.registerAddress,
              RegisterAddressOther: ultimateParent.otherRegisterAddress || undefined,
              OfficeAddress: ultimateParent.officeAddress,
              OfficeAddressOther: ultimateParent.otherOfficeAddress || undefined,
              RAInformation: {
                CreditCode: ultimateParent.businessRegistrationCertificateNumber,
                RACode: ultimateParent.registrationAuthorityCode,
                RegisterName: ultimateParent.businessRegistrationOffice,
              },
            },
            CheckFileType: ultimateParent.checkFileType,
            CriterionType: ultimateParent.criterionType,
            RelationShipStartDate: ultimateParent.relationShipStartDate,
            RelationShipEndDate: ultimateParent.relationShipEndDate,
            AccountStartTime: ultimateParent.accountStartTime,
            AccountEndTime: ultimateParent.accountEndTime,
            DocStartTime: ultimateParent.docStartTime,
            DocEndTime: ultimateParent.docEndTime,
            FileCheckUrl: ultimateParent.fileCheckUrl,
          },
        },
      },
    },
    LEIDataEx: {
      usePurpCode: basicInformation.usePurpCode,
      entitySubCategory: basicInformation.entityLegalCategory,
      website: basicInformation.website,
      position: basicInformation.contactPosition,
      telephone: basicInformation.contactPhoneCountryCode + basicInformation.contactPhone,
      idType: basicInformation.contactDocumentCategory,
      idNumber: basicInformation.contactDocumentNumber,
      fileRADate: legalBusiness.effectiveDateOfBusinessRegistrationCertificate,
      directEntitySubCategory: directParent.entityLegalCategory,
      fileDirectCreationDate: directParent.entityCreationDate,
      fileDirectRADate: directParent.effectiveDateOfBusinessRegistrationCertificate,
      directHoldingPercent: directParent.directHoldingPercent,
      ultimateEntitySubCategory: ultimateParent.entityLegalCategory,
      fileUltimateCreationDate: ultimateParent.entityCreationDate,
      fileUltimateRADate: ultimateParent.effectiveDateOfBusinessRegistrationCertificate,
      ultimateHoldingPercent: ultimateParent.ultimateHoldingPercent,
      isFileRA1: legalBusiness.fileRA1Reason !== '' ? true : false,
      fileRA1Reason: legalBusiness.fileRA1Reason,
    },
  };
};

export const RawDataToFormData = (rawData: LEIOriginalData, LEIOptions: LEIResponse) => {
  const { data } = rawData;
  const { LEIData, LEIDataEx } = data.leiData;
  const { LEIRecords } = LEIData;
  const { LEIRecord } = LEIRecords;
  const { DirectRelationShip, UltimateRelationShip } = LEIRecord;

  const DirectRelationShipDetail = DirectRelationShip.RelationShip;
  const UltimateRelationShipDetail = UltimateRelationShip.RelationShip;

  const findReasonIndex = (reason: string) => {
    if (reason === '') return '0';

    for (let i = 0; i < LEIOptions.NoRelationshipReason.length; i++) {
      if (LEIOptions.NoRelationshipReason[i].code === reason) {
        return i.toString();
      }
    }
  };

  const getFileName = (key: 'fileRA' | 'fileCorporation' | 'fileContact' | 'fileRA1' | 'fileDirectHolding' | 'fileUltimateHolding') => {
    if (!data.files) return '';
    // 找出和key相同的fileKey, 並將其fileName回傳
    const fileName = data.files.find((file) => file.fileKey === key)?.fileName;
    if (!fileName) return '';
    return fileName;
  };

  const restoreData = {
    basicInformation: {
      country: data.country,
      legalNameLanguage: LEIRecord.LegalNameLanguage,
      legalName: LEIRecord.LegalName,
      otherLegalNameLanguage: 'en',
      otherLegalName: '',
      transliteratedOtherNameLanguage: LEIDataEx.transliteratedOtherNameLanguage || 'en',
      transliteratedOtherName: LEIDataEx.transliteratedOtherName,
      formerName1Lang: LEIDataEx.formerName1Lang || 'en',
      formerName1: LEIDataEx.formerName1,
      formerName2Lang: LEIDataEx.formerName2Lang || 'en',
      formerName2: LEIDataEx.formerName2,
      formerName3Lang: LEIDataEx.formerName3Lang || 'en',
      formerName3: LEIDataEx.formerName3,
      header: data.legalCode,
      website: LEIDataEx.website,
      entityLegalCategory: LEIRecord.EntityLegalCategory,
      email: LEIRecord.Email,
      entityCreationDate: LEIRecord.EntityCreationDate,
      usePurpCode: LEIDataEx.usePurpCode,
      usePurpose: LEIRecord.UsePurp ?? '',
      corporation: LEIRecord.Corporation,
      contactName: LEIRecord.UserInfo.ContactName,
      contactPhone: LEIRecord.UserInfo.ContactTel,
      contactPhoneCountryCode: '+86',
      contactFix: '',
      contactFixCountryCode: '+86',
      contactMail: LEIRecord.UserInfo.ContactMail,
      contactDepartment: LEIRecord.UserInfo.ContactDepartment,
      contactPosition: LEIDataEx.position,
      contactDocumentCategory: LEIDataEx.idType,
      contactDocumentNumber: LEIDataEx.idNumber,
      corporationIdentificationDocument: getFileName('fileCorporation'),
      contactIdentificationDocument: getFileName('fileContact'),
    },
    address: {
      registerAddress: LEIRecord.RegisterAddress,
      officeAddress: LEIRecord.OfficeAddress,
      sameAddress:
        LEIRecord.OfficeAddress &&
        LEIRecord.OfficeAddress.Address !== '' &&
        LEIRecord.RegisterAddress.Address === LEIRecord.OfficeAddress.Address,
      otherRegisterAddressAvaliavle: LEIRecord.RegisterAddressOther ? true : false,
      otherRegisterAddress: LEIRecord.RegisterAddressOther
        ? LEIRecord.RegisterAddressOther
        : {
            Language: '',
            PostCode: '',
            Country: '',
            Province: '',
            City: '',
            Address: '',
          },
      otherOfficeAddressAvaliavle: LEIRecord.OfficeAddressOther ? true : false,
      otherOfficeAddress: LEIRecord.OfficeAddressOther
        ? LEIRecord.OfficeAddressOther
        : {
            Language: '',
            PostCode: '',
            Country: '',
            Province: '',
            City: '',
            Address: '',
          },
    },
    legalBusiness: {
      legalFormCountry: LEIRecord.LegalForm.LegalFormCountry || '',
      legalFormLanguage: LEIRecord.LegalForm.LegalFormLanguage || '',
      legalFormCode: LEIRecord.LegalForm.LegalFormCode || '',
      otherLegalFormCode: LEIRecord.LegalForm.OtherLegalFormName || '',
      registrationAuthorityCode: LEIRecord.RAInformation?.RACode || '',
      businessRegistrationCertificateNumber: LEIRecord.RAInformation?.CreditCode || '',
      businessRegistrationOffice: LEIRecord.RAInformation?.RegisterName || '',
      effectiveDateOfBusinessRegistrationCertificate: LEIDataEx.fileRADate || '',
      businessRegistrationCertificate: '',
      annualReturn: undefined,
      colorScanOfFinancialInsitutionCertificationDocuments: undefined,
      businessRegistrationCertificateDocument: getFileName('fileRA'),
      certificateOfIncorporationCopy: getFileName('fileRA1'),
      isFileRA1: LEIDataEx.isFileRA1,
      fileRA1Reason: LEIDataEx.fileRA1Reason || '',
    },
    directParent: {
      provideDirectParentCompanyInfo: DirectRelationShip.Reason ? false : true,
      haveLEICode: DirectRelationShipDetail.ParentLeiCode ? true : false,
      reason: findReasonIndex(DirectRelationShip.Reason) || '',
      legalNameLanguage: DirectRelationShipDetail.ParentNoCode.LegalNameLanguage ? DirectRelationShipDetail.ParentNoCode.LegalNameLanguage : 'en',
      legalName: DirectRelationShipDetail.ParentNoCode.LegalName ? DirectRelationShipDetail.ParentNoCode.LegalName : '',
      otherLegalNameLanguage: DirectRelationShipDetail.ParentNoCode.OtherLegalNameLanguage ? DirectRelationShipDetail.ParentNoCode.OtherLegalNameLanguage : 'en',
      otherLegalName: DirectRelationShipDetail.ParentNoCode.OtherLegalName ? DirectRelationShipDetail.ParentNoCode.OtherLegalName : '',
      entityLegalCategory: DirectRelationShipDetail.ParentNoCode.EntityLegalCategory ? DirectRelationShipDetail.ParentNoCode.EntityLegalCategory : '',
      corporation: DirectRelationShipDetail.ParentNoCode.Corporation ? DirectRelationShipDetail.ParentNoCode.Corporation : '',
      registerAddress: DirectRelationShipDetail.ParentNoCode.RegisterAddress,
      officeAddress: DirectRelationShipDetail.ParentNoCode.OfficeAddress,
      sameAddress:
        DirectRelationShipDetail.ParentNoCode.OfficeAddress &&
        DirectRelationShipDetail.ParentNoCode.OfficeAddress.Address !== '' &&
        DirectRelationShipDetail.ParentNoCode.RegisterAddress.Address ===
          DirectRelationShipDetail.ParentNoCode.OfficeAddress.Address,
      otherRegisterAddressAvaliavle: DirectRelationShipDetail.ParentNoCode.RegisterAddressOther ? true : false,
      otherRegisterAddress: DirectRelationShipDetail.ParentNoCode.RegisterAddressOther
        ? DirectRelationShipDetail.ParentNoCode.RegisterAddressOther
        : {
            Language: '',
            PostCode: '',
            Country: '',
            Province: '',
            City: '',
            Address: '',
          },
      otherOfficeAddressAvaliavle: DirectRelationShipDetail.ParentNoCode.OfficeAddressOther ? true : false,
      otherOfficeAddress: DirectRelationShipDetail.ParentNoCode.OfficeAddressOther
        ? DirectRelationShipDetail.ParentNoCode.OfficeAddressOther
        : {
            Language: '',
            PostCode: '',
            Country: '',
            Province: '',
            City: '',
            Address: '',
          },
      legalFormCountry: DirectRelationShipDetail.ParentNoCode.LegalForm.LegalFormCountry ? DirectRelationShipDetail.ParentNoCode.LegalForm.LegalFormCountry : '',
      legalFormLanguage: DirectRelationShipDetail.ParentNoCode.LegalForm.LegalFormLanguage ? DirectRelationShipDetail.ParentNoCode.LegalForm.LegalFormLanguage : '',
      otherLegalFormCode: DirectRelationShipDetail.ParentNoCode.LegalForm.OtherLegalFormName ? DirectRelationShipDetail.ParentNoCode.LegalForm.OtherLegalFormName : '',
      legalFormCode: DirectRelationShipDetail.ParentNoCode.LegalForm.LegalFormCode ? DirectRelationShipDetail.ParentNoCode.LegalForm.LegalFormCode : '',
      registrationAuthorityCode: DirectRelationShipDetail.ParentNoCode.RAInformation?.RACode ? DirectRelationShipDetail.ParentNoCode.RAInformation?.RACode : '',
      businessRegistrationCertificateNumber: DirectRelationShipDetail.ParentNoCode.RAInformation?.CreditCode ? DirectRelationShipDetail.ParentNoCode.RAInformation?.CreditCode : '',
      businessRegistrationOffice: DirectRelationShipDetail.ParentNoCode.RAInformation?.RegisterName ? DirectRelationShipDetail.ParentNoCode.RAInformation?.RegisterName : '',
      entityCreationDate: LEIDataEx.fileDirectCreationDate ? LEIDataEx.fileDirectCreationDate : '',
      effectiveDateOfBusinessRegistrationCertificate: LEIDataEx.fileDirectRADate ? LEIDataEx.fileDirectRADate : '',
      checkFileType: DirectRelationShipDetail.CheckFileType ? DirectRelationShipDetail.CheckFileType : '',
      criterionType: DirectRelationShipDetail.CriterionType ? DirectRelationShipDetail.CriterionType : '',
      relationShipStartDate: DirectRelationShipDetail.RelationShipStartDate ? DirectRelationShipDetail.RelationShipStartDate : '',
      relationShipEndDate: DirectRelationShipDetail.RelationShipEndDate ? DirectRelationShipDetail.RelationShipEndDate : '',
      accountStartTime: DirectRelationShipDetail.AccountStartTime ? DirectRelationShipDetail.AccountStartTime : '',
      accountEndTime: DirectRelationShipDetail.AccountEndTime ? DirectRelationShipDetail.AccountEndTime : '',
      docStartTime: DirectRelationShipDetail.DocStartTime ? DirectRelationShipDetail.DocStartTime : '',
      docEndTime: DirectRelationShipDetail.DocEndTime ? DirectRelationShipDetail.DocEndTime : '',
      quantitativeAttributes: '',
      fileCheckUrl: DirectRelationShipDetail.FileCheckUrl ? DirectRelationShipDetail.FileCheckUrl : '',
      corporationIdentificationDocument: undefined,
      certificateOfIncorporationCopy: undefined,
      cerifiedDocuments: getFileName('fileDirectHolding'),
      directParentLeiCode: DirectRelationShipDetail.ParentLeiCode ? DirectRelationShipDetail.ParentLeiCode : '',
      directHeadLEI: DirectRelationShipDetail.ParentLeiCode ? DirectRelationShipDetail.ParentLeiCode : '',
      directHoldingPercent: LEIDataEx.directHoldingPercent ? LEIDataEx.directHoldingPercent : 0,
    },
    ultimateParent: {
      provideDirectParentCompanyInfo: UltimateRelationShip.Reason ? false : true,
      haveLEICode: UltimateRelationShipDetail.ParentLeiCode ? true : false,
      reason: findReasonIndex(UltimateRelationShip.Reason) || '',
      legalNameLanguage: UltimateRelationShipDetail.ParentNoCode.LegalNameLanguage ? UltimateRelationShipDetail.ParentNoCode.LegalNameLanguage : 'en',
      legalName: UltimateRelationShipDetail.ParentNoCode.LegalName ? UltimateRelationShipDetail.ParentNoCode.LegalName : '',
      otherLegalNameLanguage: UltimateRelationShipDetail.ParentNoCode.OtherLegalNameLanguage ? UltimateRelationShipDetail.ParentNoCode.OtherLegalNameLanguage : 'en',
      otherLegalName: UltimateRelationShipDetail.ParentNoCode.OtherLegalName ? UltimateRelationShipDetail.ParentNoCode.OtherLegalName : '',
      entityLegalCategory: UltimateRelationShipDetail.ParentNoCode.EntityLegalCategory ? UltimateRelationShipDetail.ParentNoCode.EntityLegalCategory : '',
      corporation: UltimateRelationShipDetail.ParentNoCode.Corporation ? UltimateRelationShipDetail.ParentNoCode.Corporation : '',
      registerAddress: UltimateRelationShipDetail.ParentNoCode.RegisterAddress,
      officeAddress: UltimateRelationShipDetail.ParentNoCode.OfficeAddress,
      sameAddress:
        UltimateRelationShipDetail.ParentNoCode.OfficeAddress &&
        UltimateRelationShipDetail.ParentNoCode.OfficeAddress.Address !== '' &&
        UltimateRelationShipDetail.ParentNoCode.RegisterAddress.Address ===
          UltimateRelationShipDetail.ParentNoCode.OfficeAddress.Address,
      otherRegisterAddressAvaliavle: UltimateRelationShipDetail.ParentNoCode.RegisterAddressOther ? true : false,
      otherRegisterAddress: UltimateRelationShipDetail.ParentNoCode.RegisterAddressOther
        ? UltimateRelationShipDetail.ParentNoCode.RegisterAddressOther
        : {
            Language: '',
            PostCode: '',
            Country: '',
            Province: '',
            City: '',
            Address: '',
          },
      otherOfficeAddressAvaliavle: UltimateRelationShipDetail.ParentNoCode.OfficeAddressOther ? true : false,
      otherOfficeAddress: UltimateRelationShipDetail.ParentNoCode.OfficeAddressOther
        ? UltimateRelationShipDetail.ParentNoCode.OfficeAddressOther
        : {
            Language: '',
            PostCode: '',
            Country: '',
            Province: '',
            City: '',
            Address: '',
          },
      legalFormCountry: UltimateRelationShipDetail.ParentNoCode.LegalForm.LegalFormCountry ? UltimateRelationShipDetail.ParentNoCode.LegalForm.LegalFormCountry : '',
      legalFormLanguage: UltimateRelationShipDetail.ParentNoCode.LegalForm.LegalFormLanguage ? UltimateRelationShipDetail.ParentNoCode.LegalForm.LegalFormLanguage : '',
      otherLegalFormCode: UltimateRelationShipDetail.ParentNoCode.LegalForm.OtherLegalFormName ? UltimateRelationShipDetail.ParentNoCode.LegalForm.OtherLegalFormName : '',
      legalFormCode: UltimateRelationShipDetail.ParentNoCode.LegalForm.LegalFormCode ? UltimateRelationShipDetail.ParentNoCode.LegalForm.LegalFormCode : '',
      registrationAuthorityCode: UltimateRelationShipDetail.ParentNoCode.RAInformation?.RACode ? UltimateRelationShipDetail.ParentNoCode.RAInformation?.RACode : '',
      businessRegistrationCertificateNumber: UltimateRelationShipDetail.ParentNoCode.RAInformation?.CreditCode ? UltimateRelationShipDetail.ParentNoCode.RAInformation?.CreditCode : '',
      businessRegistrationOffice: UltimateRelationShipDetail.ParentNoCode.RAInformation?.RegisterName ? UltimateRelationShipDetail.ParentNoCode.RAInformation?.RegisterName : '',
      entityCreationDate: LEIDataEx.fileUltimateCreationDate ? LEIDataEx.fileUltimateCreationDate : '',
      effectiveDateOfBusinessRegistrationCertificate: LEIDataEx.fileUltimateRADate ? LEIDataEx.fileUltimateRADate : '',
      checkFileType: UltimateRelationShipDetail.CheckFileType ? UltimateRelationShipDetail.CheckFileType : '',
      criterionType: UltimateRelationShipDetail.CriterionType ? UltimateRelationShipDetail.CriterionType : '',
      relationShipStartDate: UltimateRelationShipDetail.RelationShipStartDate ? UltimateRelationShipDetail.RelationShipStartDate : '',
      relationShipEndDate: UltimateRelationShipDetail.RelationShipEndDate ? UltimateRelationShipDetail.RelationShipEndDate : '',
      accountStartTime: UltimateRelationShipDetail.AccountStartTime ? UltimateRelationShipDetail.AccountStartTime : '',
      accountEndTime: UltimateRelationShipDetail.AccountEndTime ? UltimateRelationShipDetail.AccountEndTime : '',
      docStartTime: UltimateRelationShipDetail.DocStartTime ? UltimateRelationShipDetail.DocStartTime : '',
      docEndTime: UltimateRelationShipDetail.DocEndTime ? UltimateRelationShipDetail.DocEndTime : '',
      quantitativeAttributes: '',
      fileCheckUrl: UltimateRelationShipDetail.FileCheckUrl ? UltimateRelationShipDetail.FileCheckUrl : '',
      sameAsDirectParent: false,
      corporationIdentificationDocument: undefined,
      certificateOfIncorporationCopy: undefined,
      cerifiedDocuments: getFileName('fileUltimateHolding'),
      ultimateParentLeiCode: UltimateRelationShipDetail.ParentLeiCode ? UltimateRelationShipDetail.ParentLeiCode : '',
      ultimateHoldingPercent: LEIDataEx.ultimateHoldingPercent ? LEIDataEx.ultimateHoldingPercent : 0,
    },
  };
  return restoreData;
};

import styled from 'styled-components';
import { darken, lighten } from 'polished';

const ModalForm = styled.form`
  position: absolute;
  top: 30%;
  left: calc(50% - 205px);
  width: 510px;
  background-color: #fff;
  padding: 25px;
  border-radius: 40px 40px 40px 0px;
`;

const ModalTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  text-align: center;
  margin-bottom: 15px;
`;

const InviteNoneDMPUserBtn = styled.div`
  display: inline-block;
  padding: 8px 15px;
  margin-right: auto;
  margin-bottom: 5px;
  background-color: ${(p) => p.theme.colors.gray200};
  color: ${(p) => p.theme.colors.light};
  border-radius: 5px;
  cursor: pointer;
  &.active {
    background-color: ${(p) => p.theme.colors.secondary400};
  }
  :hover {
    background-color: ${(p) => lighten(0.02, p.theme.colors.secondary500)};
  }
  :active {
    background-color: ${(p) => darken(0.02, p.theme.colors.secondary500)};
  }
`;

const TabButton = styled.button<{ fullWidth: boolean }>`
  flex: 1;
  display: inline-block;
  padding: 8px 15px;
  margin-left: auto;
  background-color: ${(p) => p.theme.colors.gray200};
  color: ${(p) => p.theme.colors.light};
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: ${(p) => (p.fullWidth ? '0px' : '5px')};
  &.active {
    background-color: ${(p) => p.theme.colors.secondary400};
  }
  :hover {
    background-color: ${(p) => lighten(0.02, p.theme.colors.secondary500)};
  }
  :active {
    background-color: ${(p) => darken(0.02, p.theme.colors.secondary500)};
  }
`;

export { ModalForm, ModalTitle, InviteNoneDMPUserBtn, TabButton };

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { destroyEvent, getEventList } from '@/api/event.api';
import PATH from '@/router/path';
import {
  CardTitle,
  CardWrapper,
  ContentWrapper,
  EventCounter,
  LinkButton,
  ModalMainWrapper,
  ConfirmWrapper,
  ConfirmTitle,
  DeleteBtns,
} from '../style';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import { Link } from 'react-router-dom';
import { Modal } from '@mui/material';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { Text } from '@/components/UI/Typography/Text';

// import { EventsFakeData } from '../fakeData';

interface DataRow {
  id: string;
  host_id: string;
  date: string;
  time: string;
  description: string;
  action: string;
}

export default function UpcomingEvents() {
  // @ts-ignore
  const { t } = useTranslation();
  const [data, setData] = useState<DataRow[]>();

  const [openDelete, setOpenDelete] = useState(false);
  const [rerender, setRerender] = useState<string>('');
  const [selectId, setSelectId] = useState<string>('');
  const { userData } = useAppSelector(getAuthSelector);

  const handleOpenDelete = (id: string) => {
    setSelectId(id);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const getEvent = async () => {
    try {
      const res = (await getEventList()).data;
      if (res.success) {
        const events = res.data.filter((data: EventFormFields) => {
          const currentTime = new Date();
          const eventTime = new Date(`${data.evt_startDate}`);

          return currentTime < eventTime;
        });

        const covertEvents: DataRow[] = events.map((evt: EventFormFields) => {
          const [date, time] = evt.evt_startDate ? evt.evt_startDate.split(' ') : ['', ''];

          return {
            id: evt.id,
            host_id: evt.evt_host_id,
            date: date,
            time: time,
            description: evt.evt_note,
            action: 'action',
          };
        });

        setData(covertEvents);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getEvent();
  }, [rerender]);

  const deleteHandler = async (id: number) => {
    try {
      await destroyEvent(id);
      handleDeleteClose();
      setRerender(id.toString());
    } catch (error) {}
  };

  const rawColumn = t('pages.home.upcomingEvent.table.columns', { returnObjects: true });
  const columns: TableColumn<DataRow>[] = [
    {
      name: rawColumn[0],
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: rawColumn[1],
      selector: (row) => row.time,
      sortable: true,
    },
    {
      name: rawColumn[2],
      selector: (row) => row.description,
      cell: (row) => {
        return (
          <Link to={`/event?id=${row.id}`}>
            <Text color={'blue'} fontSize={13}>
              {row.description}
            </Text>
          </Link>
        );
      },
    },
    {
      name: rawColumn[3],
      selector: (row) => row.action,
      cell: (row) => {
        return (
          <>
            {/* <Button style={{ marginRight: '10px' }} size={'sm'} onClick={() => console.log(row)}>
              <img src="/images/home/edit.svg" alt="" />
            </Button> */}
            {userData.id === row.host_id && (
              <Button
                size={'sm'}
                onClick={() => {
                  handleOpenDelete(row.id);
                }}
              >
                <img src="/images/home/delete.svg" alt="" />
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <CardWrapper>
      <CardTitle>
        {t('upcomingEvents')}
        <EventCounter>{data?.length}</EventCounter>
        <LinkButton>
          <Link to={PATH.event.index}>{t('buttons.showAll')}</Link>
        </LinkButton>
      </CardTitle>
      <ContentWrapper>
        {data ? (
          data.length === 0 ? (
            <span>{t('pages.home.upcomingEvent.noData')}</span>
          ) : (
            <DataTable columns={columns} data={data} />
          )
        ) : (
          <Loading />
        )}
      </ContentWrapper>

      <Modal
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalMainWrapper>
          <ConfirmWrapper>
            <ConfirmTitle>{t('pages.home.upcomingEvent.modal.title')}</ConfirmTitle>
            {t('pages.home.upcomingEvent.modal.content')}
          </ConfirmWrapper>
          <DeleteBtns>
            <Button
              variant={'primary'}
              size={'lg'}
              onClick={() => {
                deleteHandler(Number(selectId));
              }}
            >
              {t('buttons.delete')}
            </Button>
            <button onClick={handleDeleteClose}>{t('buttons.cancel')}</button>
          </DeleteBtns>
        </ModalMainWrapper>
      </Modal>
    </CardWrapper>
  );
}

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { generateLink, storeEvent, getExistDeal, getSingleEventList, updateEvent } from '@/api/event.api';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { Box, TextField } from '@mui/material';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { Flex } from '@/layout/Flex';
import { convertToSelectData } from '@/utils/convert';
import { FormWrapper, InputGroupWrapper, CopyIconWarpper } from './style';

const CreateMeeting = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { inputRegister } = useAuthValidation();
  const [meetingURL, setMeetingURL] = useState(' ');
  const [existOption, setExistOption] = useState<Array<string | number>>([]);
  const { handleSubmit, control, setValue } = useForm<EventFormFields>();

  let options: ExistDeal[] = [];
  const getExistOption = async () => {
    try {
      const res = (await getExistDeal()).data;
      if (res.success) {
        const option: string[] = [];
        options = res.data;
        options.forEach((item: ExistDeal) => {
          option.push(item.name);

          if (item.id === Number(id)) {
            setValue('evt_type', item.name);
          }
        });
        setExistOption(option);
      }
    } catch (error) {}
  };

  const URLGenerator = async () => {
    try {
      const res = (await generateLink()).data;

      if (res.success) {
        setMeetingURL(res.data.meetingID);
      }
    } catch (error) {}
  };

  const onSubmit = handleSubmit(async (data) => {
    const rawData = {
      ...data,
      evt_startDate: `${data.evt_startDate} ${data.evt_startTime}`,
      evt_endDate: `${data.evt_endDate} ${data.evt_endTime}`,
      vc_id: meetingURL,
    };
    try {
      const res = (await storeEvent(rawData)).data;
      if (res.success) {
        successHandler(t('success.upload'));
        navigate(-1);
      }
    } catch (error) {
      errorHandler(t('error.server'));
    }
  });

  const copyHandler = () => {
    // 複製網址
    navigator.clipboard.writeText('https://ebram-backend.oao.lol/join-meeting/'+meetingURL);
    successHandler(t('success.copied'));
  };

  useEffect(() => {
    getExistOption();
  }, []);

  return (
    <FormWrapper onSubmit={onSubmit}>
      <Flex flexDirection={'column'} gridGap={'45px'}>
        <FormSelect
          control={control}
          data={convertToSelectData(existOption)}
          inputLabel={'Existing Deal'}
          selectLabel={'Existing Deal'}
          name={'eventType'}
        />
        <Controller
          control={control}
          name={'evt_name'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              variant="standard"
              label="Add a title"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={inputRegister}
        />

        <InputGroupWrapper>
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <TextField
                  type="date"
                  variant="standard"
                  label={''}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              </div>
            )}
            name={'evt_startDate'}
            rules={inputRegister}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <TextField
                  type="time"
                  variant="standard"
                  label={''}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              </div>
            )}
            name={'evt_startTime'}
            rules={inputRegister}
          />
          <span>to</span>
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <TextField
                  type="date"
                  variant="standard"
                  label={''}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              </div>
            )}
            name={'evt_endDate'}
            rules={inputRegister}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <TextField
                  type="time"
                  variant="standard"
                  label={''}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              </div>
            )}
            name={'evt_endTime'}
            rules={inputRegister}
          />
          {meetingURL === ' ' && (
            <Button
              onClick={URLGenerator}
              style={{ borderRadius: 5, marginLeft: 'auto', padding: 0 }}
              size={'lg'}
              variant={'primary'}
              type="button"
            >
              Generate URL
            </Button>
          )}
          {meetingURL !== ' ' && (
            <Button
              style={{ borderRadius: 5, marginLeft: 'auto', padding: 0 }}
              size={'lg'}
              variant={'normal'}
              type="button"
            >
              Generate URL
            </Button>
          )}
        </InputGroupWrapper>

        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div style={{ position: 'relative' }}>
              <TextField
                fullWidth
                disabled
                variant="standard"
                label={'Meeting URL'}
                value={meetingURL}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
              <CopyIconWarpper>
                <Button type="button" onClick={() => copyHandler()}>
                  <img src="/images/event/copy.svg" alt="" />copy
                </Button>
              </CopyIconWarpper>
            </div>
          )}
          name={'vc_id'}
        />

        <div>Add a description</div>
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              variant="standard"
              label={''}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          name={'evt_note'}
        />
        <Box>
          <Button size={'lg'} variant={'primary'}>
            {'Create'}
          </Button>
        </Box>
      </Flex>
    </FormWrapper>
  );
};

export default CreateMeeting;

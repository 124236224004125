import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RegistrationWrapper, Wrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { theme } from '@/theme/Theme';
import { H5 } from '@/components/UI/Typography/Heading';
import { errorHandler } from '@/utils/toastHandler';
import usePermissionCheck from '@/hooks/usePermissionCheck';
import { useState } from 'react';

interface FormStepperProps {
  status: number;
  data: LEIRequestData;
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  checkLevel: () => boolean;
}

const Introduction = ({ status, data, checkLevel, setCurrentStep, setActiveStep }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [seatchParmas, setSearchParams] = useSearchParams();
  const { isMainAccount } = usePermissionCheck();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const nextHandler = () => {
    // 狀態0 = 草稿 可修改內容
    // 狀態2 = 退回 可修改內容
    // 狀態7 = 撤銷 需要重送submit 但不需重新填寫
    if (status === 0 || status === 2) {
      setCurrentStep('3');
      setActiveStep(2);
      navigate('/lei?step=3');
      return;
    }

    // 除了前面兩種狀態外的其他步驟, 進入最後一步驟
    if (status > 0 && status < 7) {
      setCurrentStep('5');
      setActiveStep(4);
      navigate('/lei?step=5');
      return;
    }

    // 上述狀態碼都不符合的話, 進入第二步驟
    setCurrentStep('2');
    setActiveStep(1);
    setSearchParams({ step: '2' });
  };

  const permissionCheck = () => {
    if (!isMainAccount) {
      errorHandler(t('error.permission'));
      return;
    }

    if (!checkLevel()) return;
    nextHandler();
  };

  return (
    <Wrapper>
      <Text fontSize={theme.fontSizes.lg} marginBottom={20}>
        {t('pages.eTrust.LEI.steps.step1.title')}
      </Text>
      <H5 marginBottom={50}>{t('pages.eTrust.LEI.steps.step1.intro')}</H5>
      <RegistrationWrapper>
        <div className="content">
          <Flex className="border" flexDirection={'column'}>
            <Box padding={24} textAlign={'center'}>
              <div>{t('pages.eTrust.LEI.steps.step1.registration.firstTime')}</div>
              <Button
                size={'lg'}
                type="button"
                variant={'primary'}
                marginRight={40}
                onClick={permissionCheck}
                style={{ borderRadius: 5, margin: '20px auto 0' }}
              >
                {t('buttons.newRegistration')}
              </Button>
            </Box>
            <Box padding={24} textAlign={'center'} borderTop={`1px solid ${theme.colors.gray300}`}>
              <div>{t('pages.eTrust.LEI.steps.step1.registration.existing')}</div>
              <Button
                size={'lg'}
                type="button"
                variant={'primary'}
                marginRight={40}
                onClick={permissionCheck}
                style={{ borderRadius: 5, margin: '20px auto 0' }}
              >
                {t('buttons.renewal')}
              </Button>
            </Box>
          </Flex>
          <Flex className="border">
            <Box width={'100%'} padding={'20px 30px'} textAlign={'center'}>
              <img width={'85px'} src="/images/badge/identification_check.svg" alt="" />
              <H5 marginTop={20} fontWeight={700} fontSize={theme.fontSizes.md} color={theme.colors.secondary500}>
                {t('pages.eTrust.LEI.steps.step1.badgeTitle')}
              </H5>
              <H5 marginTop={10} style={{ maxWidth: 305 }}>
                {t('pages.eTrust.LEI.steps.step1.badgeIntro')}
              </H5>
            </Box>
          </Flex>
        </div>
      </RegistrationWrapper>
    </Wrapper>
  );
};

export default Introduction;

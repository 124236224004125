import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import CurrentServices from './CurrentServices';
import ServicesList from './ServeiceList';
import InnerPageTitle from '@/components/UI/InnerPageTitle';

const Subscription = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState('CurrentServices');

  return (
    <PageContainer
      title={<InnerPageTitle link={'/profile/edit'} title={t('dashboard')} subtitle={t('subscription')} />}
    >
      {/* {
                currentPage === 'CurrentServices' && <CurrentServices setCurrentPage={setCurrentPage} />
            }
            {
                currentPage === 'ServicesList' && <ServicesList setCurrentPage={setCurrentPage} />
            } */}
      <ServicesList setCurrentPage={setCurrentPage} />
    </PageContainer>
  );
};

export default Subscription;

import { FC } from 'react';

import { Box, Flex } from '@/layout';

import { TabButton } from './styles';

interface TabsProps {
  tabs: {
    label: string;
    index: number;
    Component: FC<{ index: number }>;
  }[];
  selectedTab: number;
  onClick: (index: number) => void;
  rightComponent?: React.ReactNode;
  dealCounter?: {
    [key: string]: number;
  } | null;
  fullWidth?: boolean;
}

/**
 * Avalible Props
 * @param tab Array of object
 * @param selectedTab number
 * @param onClick Function to set the active tab
 * @param rightComponent Can add other components to right
 * @param dealCounter Object of deal counter, only use in Deal index page
 */
const Tabs = ({
  tabs = [],
  selectedTab = 0,
  onClick,
  rightComponent,
  dealCounter = null,
  fullWidth = false,
}: TabsProps) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab);
  return (
    <div style={{ width: fullWidth ? '100%' : 'unset' }}>
      <Flex justifyContent={'space-between'} alignItems={'center'} marginBottom={fullWidth ? '0' : '15px'}>
        <Box width={fullWidth ? '100%' : ''} display={fullWidth ? 'flex' : 'block'}>
          {tabs.map((tab, index) => (
            <TabButton
              className={selectedTab === tab.index ? 'active' : ''}
              onClick={() => onClick(tab.index)}
              key={tab.index}
              aria-selected={selectedTab === tab.index}
              aria-controls={`tabpanel-${tab.index}`}
              tabIndex={selectedTab === tab.index ? 0 : -1}
              id={`tab-btn-${tab.index}`}
              fullWidth={fullWidth}
            >
              {tab.label} {dealCounter && `(${Object.values(dealCounter)[index]})`}
            </TabButton>
          ))}
        </Box>
        {rightComponent && rightComponent}
      </Flex>
      <div role="tabpanel" aria-labelledby={`btn-${selectedTab}`} id={`tabpanel-${selectedTab}`}>
        {Panel && <Panel.Component index={selectedTab} />}
        {/* {tabs.map(tab => <tab.Component index={selectedTab} />)} */}
      </div>
    </div>
  );
};
export default Tabs;

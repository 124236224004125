import { Dispatch, SetStateAction } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import { Flex } from '@/layout/Flex';
import { Box } from '@/layout/Box';
import { Button } from '@/components/UI/Button';
import { Search } from '@/components/Icon';

import { getDealCompanyList } from '@/api/deal.api';

interface SearchHeaderProps {
  setCompanyList: Dispatch<SetStateAction<CompanyDetail[] | undefined>>;
}

const SearchHeader = ({ setCompanyList }: SearchHeaderProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<DealStep3MoreCompanyFormFields>();

  const onSubmit = handleSubmit(async (data) => {
    setCompanyList(undefined);
    try {
      const res = (await getDealCompanyList(data.keyword)).data;
      setCompanyList(res.data);
    } catch (error) {}
  });

  return (
    <Flex alignItems={'center'} marginBottom={'15px'} gridGap={'15px'}>
      <form onSubmit={onSubmit} style={{width: '100%'}}>
        <Flex gridGap={'5px'}>
          {/* <Box width={'350px'}>
            <FormSelect
              control={control}
              variant={'outlined'}
              size={'small'}
              data={[]}
              inputLabel={t('form.dealType.label')}
              selectLabel={t('form.dealType.label')}
              name={'dealType'}
            />
          </Box> */}
          <Box maxWidth={'650px'} width={'100%'}>
            <Controller
              control={control}
              name={'keyword'}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size={'small'}
                  value={value}
                  onChange={onChange}
                  label={t('form.keyword.label')}
                  autoComplete="off"
                />
              )}
            />
          </Box>
          <Search.SearchButton type={'submit'}>
            <img src={Search.SearchSVG} alt="searchSVG" />
          </Search.SearchButton>
        </Flex>
      </form>
      <Button size={'lg'} variant={'primary'}>
        {t('eDirectory')}
      </Button>
    </Flex>
  );
};

export default SearchHeader;

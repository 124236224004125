import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import FormWrapper from '@/components/FormStepper/Wrapper';
import { Flex } from '@/layout/Flex';

import useDealValidation from '@/hooks/requireSchema/useDealValidation';

import { dealType, categoryLang } from '@/data/select';
import useCountryDetail from '@/hooks/useCountryDetail';
import MultiSelect from '@/components/UI/Select/MultiSelect';
import { converToMultiLangSelectData, convertToSelectData } from '@/utils/convert';
import useCurrentLang from '@/hooks/useCurrentLang';

interface TypeInformationProps {
  handleNextStep: () => Promise<void>;
  setFormData: Dispatch<SetStateAction<DealStep1FormRequest>>;
  dealDetail?: DealDetail;
  formData: DealStep1FormRequest;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const TypeInformation = ({ formData, handleNextStep, setFormData, dealDetail, setIsLoading }: TypeInformationProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const lang = useCurrentLang();
  // currencyData, languages
  const currencyData = useCountryDetail('currency');
  const languages = useCountryDetail('languages');
  const [dealTypeOptions, setDealTypeOptions] = useState<SelectOptions>([]);

  const dealRoleAry = t('dealRoleArray', { returnObjects: true });
  const [dealRoleOptions, setDealRoleOptions] = useState<SelectOptions>(dealRoleAry[0]);

  // 切換買賣方名稱的開關
  const [changeOptionFeature] = useState<boolean>(true);

  const { control, handleSubmit, setValue, register, watch } = useForm<DealStep1FormFields>();

  const { dealTypeRegister, dealNameRegister, descriptionRegister, expiryDateRegister } = useDealValidation().step1();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    const rowData = {
      ...data,
      category: data.category.join(', '),
      languages: data.languages.join(', '),
      currency: data.currency.join(', '),
    };

    setFormData(rowData);
    setIsLoading(false);
    handleNextStep();
  });

  useEffect(() => {
    (async () => {
      const dealTypeOptionsRes = await dealType(lang);
      setDealTypeOptions(dealTypeOptionsRes);
    })();
  }, [lang]);

  // 監視語系變化
  useEffect(() => {
    const dealRoleAry = t('dealRoleArray', { returnObjects: true });
    setDealRoleOptions(dealRoleAry[0]);
  }, [lang]);

  useEffect(() => {
    const date = new Date();
    const defaultDate = date.setDate(date.getDate() + 3);
    const converDate = new Date(defaultDate);

    setValue('expiry_date', converDate.toJSON().slice(0, 10));

    if (!dealDetail && formData.name !== '') {
      setValue('deal_role_id', formData.deal_role_id);
      setValue('deal_type_id', formData.deal_type_id);
      setValue('description', formData.description);
      setValue('name', formData.name);
      setValue('expiry_date', formData.expiry_date.slice(0, 10));

      setValue('category', formData.category?.split(', '));
      setValue('languages', formData.languages?.split(', '));
      setValue('currency', formData.currency?.split(', '));
      setValue('category_other', formData.category === 'Other' ? formData.category_other : '');
      setValue('languages_other', formData.languages === 'Other' ? formData.languages_other : '');
      setValue('currency_other', formData.currency === 'Other' ? formData.currency_other : '');
    } else if (dealDetail) {
      setValue('deal_role_id', String(dealDetail.deal_role_id));
      setValue('deal_type_id', dealDetail.deal_type_id);
      setValue('description', dealDetail.description);
      setValue('name', dealDetail.name);
      setValue('expiry_date', dealDetail.expiry_date.slice(0, 10));

      setValue('category', dealDetail.category?.split(', '));
      setValue('languages', dealDetail.languages?.split(', '));
      setValue('currency', dealDetail.currency?.split(', '));
      setValue('category_other', dealDetail.category === 'Other' ? dealDetail.category_other : '');
      setValue('languages_other', dealDetail.languages === 'Other' ? dealDetail.languages_other : '');
      setValue('currency_other', dealDetail.currency === 'Other' ? dealDetail.currency_other : '');
    }
  }, [dealDetail, formData]);

  // 監視language的變化, 若使用者有選擇"other"則將language_other顯示出來並加入驗證
  const watchLanguage = watch('languages');
  useEffect(() => {
    if (watchLanguage && watchLanguage.includes('Other')) {
      register('languages_other', { required: t('required.feild') });
    }
  }, [watchLanguage]);

  const watchCategory = watch('category');
  useEffect(() => {
    if (watchCategory && watchCategory.includes('Other')) {
      register('category_other', { required: t('required.feild') });
    }
  }, [watchCategory]);

  const watchCurrency = watch('currency');
  useEffect(() => {
    if (watchCurrency && watchCurrency.includes('Other')) {
      register('currency_other', { required: t('required.feild') });
    }
  }, [watchCurrency]);

  const watchDealType = watch('deal_type_id');
  useEffect(() => {
    if (changeOptionFeature && watchDealType) {
      setDealRoleOptions(dealRoleAry[parseInt(watchDealType) - 1]);
    }
  }, [watchDealType]);

  return (
    <FormWrapper>
      <form onSubmit={onSubmit}>
        <Flex flexDirection={'column'} gridGap={'45px'}>
          {dealDetail && <input type="hidden" value={dealDetail.id} {...register('deal_id')} />}
          <FormSelect
            control={control}
            data={dealTypeOptions}
            inputLabel={t('form.dealType.label')}
            selectLabel={t('form.dealType.label')}
            name={'deal_type_id'}
            register={dealTypeRegister}
          />
          <FormSelect
            control={control}
            data={dealRoleOptions}
            inputLabel={t('form.dealRole.label')}
            selectLabel={t('form.dealRole.label')}
            name={'deal_role_id'}
            register={dealTypeRegister}
          />
          <Controller
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                label={t('form.dealName.label')}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            name={'name'}
            rules={dealNameRegister}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div style={{ width: '100%' }}>
                <TextField
                  type="date"
                  variant="standard"
                  label={t('form.expiryDate.label')}
                  value={value}
                  placeholder={'h'}
                  onChange={onChange}
                  error={!!error}
                  fullWidth
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              </div>
            )}
            name={'expiry_date'}
            rules={expiryDateRegister}
          />
          <MultiSelect
            control={control}
            data={converToMultiLangSelectData(categoryLang, lang)}
            inputLabel={t('form.natureOfBusiness.label')}
            selectLabel={t('form.natureOfBusiness.label')}
            name={'category'}
            register={{ required: t('required.input', { input: t('form.natureOfBusiness.label') }) }}
          />
          {watchCategory && watchCategory.includes('Other') && (
            <Controller
              control={control}
              name={'category_other'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.natureOfBusiness.other')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          )}

          <Controller
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                label={t('form.description.label')}
                value={value}
                onChange={onChange}
                multiline
                rows={4}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            name={'description'}
            rules={descriptionRegister}
          />

          <MultiSelect
            control={control}
            data={languages}
            inputLabel={t('form.languages.label')}
            selectLabel={t('form.languages.label')}
            name={'languages'}
            register={{ required: t('required.input', { input: t('form.languages.label') }) }}
          />

          {watchLanguage && watchLanguage.includes('Other') && (
            <Controller
              control={control}
              name={'languages_other'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.languages.other')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          )}

          <MultiSelect
            control={control}
            data={currencyData}
            inputLabel={t('form.currency.label')}
            selectLabel={t('form.currency.label')}
            name={'currency'}
            register={{ required: t('required.input', { input: t('form.currency.label') }) }}
          />
          {watchCurrency && watchCurrency.includes('Other') && (
            <Controller
              control={control}
              name={'currency_other'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.currency.other')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          )}

          <Box>
            <Button size={'lg'} variant={'primary'}>
              {t('buttons.next')}
            </Button>
          </Box>
        </Flex>
      </form>
    </FormWrapper>
  );
};

export default TypeInformation;

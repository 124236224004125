import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得列表
 */
export const getEventList = async () => {
  return axios.get('/api/event');
};

/**
 * ANCHOR 取得單筆資料
 */
export const getSingleEventList = async (id: number) => {
  return axios.get(`/api/event/${id}`);
};

/**
 * ANCHOR 查詢是否有存在的deal
 */
export const getExistDeal = async () => {
  return axios.get('/api/deal/index');
};

/**
 * ANCHOR 新增event
 */
export const storeEvent = async (data: EventFormFields) => {
  return axios.post('/api/event', data);
};

/**
 * ANCHOR 產生meeting link
 */
export const generateLink = async () => {
  return axios.get('/api/meeting/create-bbb');
};

/**
 * ANCHOR 取得meeting細節
 */
export const getMeetingDetail = async (meetingID: string) => {
  return axios.get(`/api/meeting/getMeetingInfo?meetingID=${meetingID}`);
};

/**
 * ANCHOR 更新event
 */
export const updateEvent = async (id: number, data: EventFormFields) => {
  return axios.put(`/api/event/${id}`, data);
};

/**
 * ANCHOR 刪除event
 */
export const destroyEvent = async (id: number) => {
  return axios.delete(`/api/event/${id}`);
};

/**
 * ANCHOR 建立正確會議連結
 */
export const joinMeeting = async(params: string) => {
  return axios.get(`/api/meeting/join-bbb?${params}`);
}

/**
 * ANCHOR 建立正確會議連結給未登入者
 */
export const joinMeetingForGuest = async(meeting_id: string) => {
  return axios.get(`/api/join-meeting/${meeting_id}`);
}

/**
 * ANCHOR 確認會議的狀態 (後端處理其餘事項)
 */
// export const checkMeetingIsRunning = async(meeting_id: string) => {
//   return axios.get(`/api/meeting/checkMeetingIsRunning?meetingID=${meeting_id}`);
// }
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { successHandler, errorHandler } from '@/utils/toastHandler';
import useCountryDetail from '@/hooks/useCountryDetail';
import { Paper, TextField } from '@mui/material';

import { InputWrapper, MultiInputWrapper, FormTitle } from '@/components/Container/AuthContainer/styles';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { Flex, LoadingPage } from '@/layout';
import { updateAccount } from '@/api/auth.api';
import { useAppDispatch, useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, update } from '@/redux/auth/auth.slice';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';

import { converToMultiLangSelectData, convertCountryCode } from '@/utils/convert';

import { countryDetail, title, country } from '@/data/select';
import useCurrentLang from '@/hooks/useCurrentLang';

const AccountEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    countryCodeRegister,
    mobileRegister,
    regionRegister,
    jobPositionRegister,
    companyNameRegister,
    giveNameRegister,
    familyNameRegister,
  } = useRegistrationValidation().companyInfo();
  const auth = useAppSelector(getAuthSelector);
  const lang = useCurrentLang();
  const dispatch = useAppDispatch();
  const { userData, accessToken } = auth;

  // 設置表單與驗證
  const { handleSubmit, control, watch, setValue, register } = useForm<Omit<UpdateAccountForm, 'role'>>();

  const { emailRegister } = useAuthValidation();

  // 取得國家資訊
  const getCountry = (code: string) => {
    const defaultCountry = country['en'].filter((country: CountryPorps) => country.countryCode === code);
    return {
      acc_timezone: defaultCountry[0].timezone,
    };
  };

  const getCountryCode = (countryName: string) => {
    const defaultCountry = country['en'].filter((countryDetail: CountryPorps) => countryDetail.name === countryName);
    return {
      countryCode: defaultCountry[0].countryCode,
    };
  };

  const changeCountryName = (countryCode: string) => {
    const correctCountry = country['en'].find((item) => item.countryCode === countryCode);
    return correctCountry ? correctCountry.name : '';
  };

  // 監視country的變化, 若有新值則將country的值同樣設定到acc_MobileCountryCode
  const watchCountry = watch('acc_country');
  const watchMobileCountry = watch('acc_MobileCountryCode');

  useEffect(() => {
    if (!watchCountry) return;
    const { countryCode } = getCountryCode(watchCountry);
    setValue('acc_MobileCountryCode', convertCountryCode(countryCode));
  }, [watchCountry]);

  useEffect(() => {
    if (watchCountry && watchCountry.includes('Other')) {
      register('acc_country_other', { required: t('required.input', {input: t('form.country.label')}) });
    }
  }, [watchCountry]);

  useEffect(() => {
    if (!watchMobileCountry) return;
    const newCountryName = changeCountryName(watchMobileCountry.split(' ')[0]);
    setValue('acc_country', newCountryName);
  }, [watchMobileCountry]);

  // 送出表單
  const onSubmit = handleSubmit(async (data) => {
    const countryCode = data.acc_MobileCountryCode.split(' ')[0];
    const { acc_timezone } = getCountry(countryCode);

    try {
      setIsLoading(true);
      const res = (
        await updateAccount({
          ...data,
          role: '1',
          acc_timezone,
          acc_countryCode: countryCode,
          acc_MobileCountryCode: countryCode,
        })
      ).data;
      if (res.success) {
        dispatch(update({ isLogin: true, accessToken, userData: res.data, registDone: true }));
        successHandler(t('success.update'));
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    }
  });

  // 進入時回填資料
  useEffect(() => {
    if (!userData.acc_coname) return;
    setValue('acc_coname', userData.acc_coname);
    setValue('acc_jobPosition', userData.acc_jobPosition);
    setValue('acc_title', userData.acc_title ? userData.acc_title : '');
    setValue('acc_firstName', userData.acc_firstName);
    setValue('acc_lastName', userData.acc_lastName);
    setValue('acc_country',  changeCountryName(userData.acc_countryCode));
    setValue('acc_country_other', userData.acc_country_other ? userData.acc_country_other : '');
    setValue('acc_MobileCountryCode', convertCountryCode(userData.acc_countryCode));
    setValue('acc_mobile', userData.acc_mobile);
  }, [userData]);

  return (
    <Paper style={{ width: '100%', maxWidth: '930px' }} square elevation={0}>
      <LoadingPage isLoading={isLoading} />
      <form onSubmit={onSubmit} style={{ maxWidth: '930px', padding: '30px 50px' }}>
        <FormTitle>{t('companyRepresentativeInformation')}</FormTitle>
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'acc_coname'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
            rules={companyNameRegister}
          />
        </InputWrapper>

        <MultiInputWrapper>
          <FormSelect
            control={control}
            data={converToMultiLangSelectData(title, lang)}
            inputLabel={t('form.title.label')}
            selectLabel={'acc_title'}
            name={'acc_title'}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_firstName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.giveName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginLeft: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={giveNameRegister}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_lastName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.familyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginLeft: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={familyNameRegister}
          />
        </MultiInputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'email'}
            defaultValue={userData.email}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.email.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                disabled
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={emailRegister}
          />
        </InputWrapper>

        <MultiInputWrapper>
          <Controller
            control={control}
            name={'acc_jobPosition'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.jobPosition.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={jobPositionRegister}
          />

          <FormSelect
            control={control}
            data={useCountryDetail('name')}
            inputLabel={t('form.region.label')}
            selectLabel={'Region'}
            name={'acc_country'}
            register={regionRegister}
          />
        </MultiInputWrapper>

        {watchCountry && watchCountry.includes('Other') && (
          <InputWrapper>
            <Controller
              control={control}
              name={'acc_country_other'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={'form.countryOther.label'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </InputWrapper>
        )}

        <MultiInputWrapper style={{ height: 'auto' }}>
          <div style={{ width: '200px', marginRight: '10px' }}>
            <FormSelect
              control={control}
              data={useCountryDetail('countryCode')}
              inputLabel={t('form.countryCode.label')}
              selectLabel={t('form.countryCode.label')}
              name={'acc_MobileCountryCode'}
              register={countryCodeRegister}
            />
          </div>
          <Controller
            control={control}
            name={'acc_mobile'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.mobile.label')}
                placeholder={t('form.mobile.placeholder')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={mobileRegister}
          />
        </MultiInputWrapper>

        <Flex style={{ marginTop: 50 }} gridGap={'15px'}>
          <Button size={'lg'} variant={'primary'} rounded={false} marginBottom={15}>
            {t('buttons.submit')}
          </Button>

          <Button
            onClick={() => navigate('/profile/edit')}
            type={'button'}
            size={'lg'}
            variant={'primary-invert'}
            rounded={false}
            marginBottom={15}
          >
            {t('buttons.back')}
          </Button>
        </Flex>
      </form>
    </Paper>
  );
};

export default AccountEdit;

// const roleData = [
//   {
//     value: 0,
//     text: 'Buyer',
//   },
//   {
//     value: 1,
//     text: 'Seller',
//   }
// ];

const roleData = [
  [
    {
      value: 0,
      text: 'Buyer',
    },
    {
      value: 1,
      text: 'Seller',
    }
  ],
  [
    {
      value: 0,
      text: 'Renter',
    },
    {
      value: 1,
      text: 'Landlord',
    }
  ],
  [
    {
      value: 0,
      text: 'Client',
    },
    {
      value: 1,
      text: 'Service Provider',
    }
  ],
  [
    {
      value: 0,
      text: 'Licensee',
    },
    {
      value: 1,
      text: 'Licensor',
    }
  ],
  [
    {
      value: 0,
      text: 'Employee',
    },
    {
      value: 1,
      text: 'Employer',
    }
  ],
  [
    {
      value: 0,
      text: 'Party A',
    },
    {
      value: 1,
      text: 'Party B',
    }
  ]
];

// const roleData = [
//   {
//     value: 0,
//     text: 'Buyer',
//   },
//   {
//     value: 1,
//     text: 'Seller',
//   },
//   {
//     value: 2,
//     text: 'Renter',
//   },
//   {
//     value: 3,
//     text: 'Landlord',
//   },
//   {
//     value: 4,
//     text: 'Client',
//   },
//   {
//     value: 5,
//     text: 'Service Provider',
//   },
//   {
//     value: 6,
//     text: 'Licensee',
//   },
//   {
//     value: 7,
//     text: 'Licensor',
//   },
//   {
//     value: 8,
//     text: 'Employee',
//   },
//   {
//     value: 9,
//     text: 'Employer',
//   },
//   {
//     value: 10,
//     text: 'Party A',
//   },
//   {
//     value: 11,
//     text: 'Party B',
//   },
// ];

export default roleData;

import { theme } from '@/theme/Theme';
import * as fileExtension from '@/data/fileExtension';

interface TypeColumnProps {
  text: string;
}

const TypeColumn = ({ text }: TypeColumnProps) => {
  const fontColor = (text: string) => {
    if (fileExtension.word.includes(text)) return theme.colors.primary300;
    if (fileExtension.pdf.includes(text)) return theme.colors.red;
    if (fileExtension.excel.includes(text)) return theme.colors.secondary500;
    return theme.colors.gray500;
  };

  return <span style={{ color: fontColor(text) }}>{text}</span>;
};

export default TypeColumn;

import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import Header from '@/layout/Header';
import Routers from '@/router';

import store, { persistor } from './redux/store';
import { axiosInterceptor } from './utils/axios';

function App() {
  axiosInterceptor(store);

  return (
    <Suspense fallback={<div>loading...</div>}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <div className="App">
            <ToastContainer autoClose={2000} />
            <Routers>
              <Header />
            </Routers>
          </div>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;

import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { Flex } from '@/layout';
import { Box } from '@mui/material';
import ChatRoomList from '@/components/Chat/ChatRoomList';
import ChattingWindow from '@/components/Chat/ChattingWindow';
import { getChatList } from '@/api/chat.api';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useSocket } from '@/hooks/useSocket';

const ChatRoom = () => {
  // @ts-ignore
  const {t} = useTranslation();

  const [currentId, setCurrentId] = useState(0);
  const [data, setData] = useState<ChatList[]>([]);
  const [chatRoomData, setChatRoomData] = useState<ChatList>();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  useSocket({
    type: 'JOIN_CHATLIST',
    callBack: (wsResponse: ChatList) => {
      if (data.length === 0) {
        getList();
      }
      updateList(wsResponse);
    },
  });

  const getList = useCallback(async () => {
    try {
      const res = (await getChatList()).data;
      setData(res.data);
      if (searchParams.get('room')) {
        setCurrentId(Number(searchParams.get('room')));
        const currentData = res.data.find((item) => item.id.toString() === searchParams.get('room'));
        setChatRoomData(currentData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [location.search]);

  useEffect(() => {
    getList();
  }, [getList]);

  // 監視searchParams的變化
  useEffect(() => {
    updateCurrentData();
  }, [searchParams]);

  const updateCurrentData = () => {
    if (searchParams.get('room')) {
      setCurrentId(Number(searchParams.get('room')));
      const currentData = data.find((item) => item.id.toString() === searchParams.get('room'));
      setChatRoomData(currentData);
    }
  };

  const updateList = (wsData: ChatList) => {
    const listData = getCurrentDataList();
    // 找出和data相同的聊天室id, 將其資料更新並移至第一筆
    const index = listData.findIndex((item) => item.id === wsData.last_message.chatroom_id);
    // console.log(listData, index);
    if (index !== -1) {
      if (userData.id !== wsData.last_message.user_id) {
        wsData.unread_count = listData[index].unread_count + wsData.unread_count;
      }
      listData[index] = wsData;
      listData.unshift(listData.splice(index, 1)[0]);
      setData(listData);
    }
  };

  const getCurrentDataList = () => {
    return [...data];
  };

  return (
    <PageContainer title={<InnerPageTitle link={'/home'} subtitle={t('info.chat')} />}>
      <Flex gridGap={'10px'}>
        <Box width={'400px'}>
          <ChatRoomList roomData={data} />
        </Box>
        <Box maxWidth={'930px'} width={'100%'}>
          {chatRoomData && <ChattingWindow id={currentId} chatRoomData={chatRoomData} />}
        </Box>
      </Flex>
    </PageContainer>
  );
};

export default ChatRoom;

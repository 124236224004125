import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得當前用戶評價
 */
export const getUserRating = async () => {
  return axios.get('/api/rating');
};

/**
 * ANCHOR 取得指定用戶評價
 */
export const getSpecifyUserRating = async (acc_id: number) => {
  return axios.get(`/api/rating/${acc_id}`);
};

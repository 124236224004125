import { rem } from 'polished';

const colors = {
  primary100: '#6D83C4',
  primary200: '#546FBA',
  primary300: '#3C5AB1',
  primary400: '#2346A7',
  primary500: '#0B319D',

  secondary100: '#C8E485',
  secondary200: '#BFE070',
  secondary300: '#ADD747',
  secondary400: '#A4D233',
  secondary500: '#7EB442',

  surface: '#eef1f8',

  light: '#ffffff',

  gray100: '#E9E9E9',
  gray200: '#BCBCBC',
  gray300: '#999999',
  gray400: '#616161',
  gray500: '#212121',

  success: '#51c81c',
  danger: '#ff5757',
  warning: '#fcb41d',
  info: '#328ef5',
  error: '#ff5757',

  orange: '#FF6D00',
  red: '#DC1010',
  purple: '#A47AE2',
  yellow: '#FFB802',
  lightPurple: '#8CA1E2',
  lightgreen: '#51DAC5'
};

const toRem = (px: number) => rem(`${px}px`);

export interface BreakpointsType {
  /** 576px */
  0: string;
  /** 768px */
  1: string;
  /** 992px */
  2: string;
  /** 1200px */
  3: string;
}

const breakpoints: BreakpointsType = [toRem(576), toRem(768), toRem(992), toRem(1200)];

export interface SpaceType {
  /** 0px */
  0: string;
  /** 4px */
  1: string;
  /** 8px */
  2: string;
  /** 16px */
  3: string;
  /** 32px */
  4: string;
  /** 64px */
  5: string;
  /** 128px */
  6: string;
  /** 256px */
  7: string;
}

const space: SpaceType = {
  0: toRem(0),
  1: toRem(4),
  2: toRem(8),
  3: toRem(16),
  4: toRem(32),
  5: toRem(64),
  6: toRem(128),
  7: toRem(256),
};

export interface PaddingsType {
  /** 8px */
  xs: string;
  /** 12px  */
  sm: string;
  /** 16px */
  md: string;
  /** 24px */
  lg: string;
}

const paddings: PaddingsType = {
  xs: rem('8px'),
  sm: rem('12px'),
  md: rem('16px'),
  lg: rem('24px'),
};

export interface HeightsType {
  /** 28px */
  sm: string;
  /** 30px */
  table: string;
  /** 36px */
  base: string;
  /** 44px */
  lg: string;
}

const heights: HeightsType = {
  sm: rem('28px'),
  table: rem('30px'),
  base: rem('36px'),
  lg: rem('44px'),
};

interface FontSizeType {
  /** 12px */
  xs: string;
  /** 14px */
  sm: string;
  /** 16px */
  base: string;
  /** 20px */
  md: string;
  /** 32px */
  lg: string;
  /** 60px */
  xl: string;
}

const fontSizes: FontSizeType = {
  xs: rem('12px'),
  sm: rem('17px'),
  base: rem('18px'),
  md: rem('20px'),
  lg: rem('34px'),
  xl: rem('56px'),
};

export interface RadiiType {
  /** 2px */
  sm: string;
  /** 5px */
  base: string;
  /** 8px */
  lg: string;
  /** 16px */
  xl: string;
}

// border-radius
const radii: RadiiType = {
  sm: rem('2px'),
  base: rem('5px'),
  lg: rem('8px'),
  xl: rem('16px'),
};

export interface BordersType {
  /** 1px solid */
  base: string;

  /** 2px solid */
  lg: string;

  /** 1px dashed */
  dashed: string;
}

const borders: BordersType = {
  base: '1px solid',
  lg: '2px solid',
  dashed: '1px dashed',
};

const transition = 'transition: all 200ms ease 0s';

export interface ShadowsType {
  /** 0 1px 3px 0 #e0e0e0 */
  sm: string;
  /** 0 2px 4px 0 #e0e0e0 */
  base: string;
  /** 0 2px 6px 0 #e0e0e0 */
  lg: string;
  /** 0 5px 10px 0 #e0e0e0 */
  xl: string;
}

const shadows: ShadowsType = {
  sm: '0 1px 3px 0 #C4CFDD',
  base: '0 2px 4px 0 #C4CFDD',
  lg: '0 2px 6px 0 #C4CFDD',
  xl: '0 5px 10px 0 #C4CFDD',
};

/**
 * outside W_01(內陰影01)
 */
const outsideShadowW01 = {
  background: '#ffffff',
  'box-shadow': '0px 0px 15px rgba(0, 0, 0, 0.07)',
  'border-radius': '5px',
};

/**
 * outside W_02(內陰影02)
 */
const outsideShadowW02 = {
  background: '#ffffff',
  'box-shadow': '0px 0px 15px rgba(0, 0, 0, 0.07)',
  'border-radius': '15px 15px 15px 0px',
};

/**
 * Table Input 外框
 */
const tableInputBorder = {
  'border-color': colors.primary300,
  'border-radius': radii.base,
  'border-style': 'solid',
  'border-width': '1px',
};

export const theme = {
  breakpoints,
  colors,
  space,
  paddings,
  heights,
  fontSizes,
  radii,
  borders,
  shadows,
  lineHeight: 1.5,
  transition,
  outsideShadowW01,
  outsideShadowW02,
  tableInputBorder,
};

export type ThemeType = typeof theme;

import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得列表
 */
export const getCompanyCreditReports = async () => {
  return axios.get('/api/creditReport/companyCreditReports');
};

/**
 * ANCHOR 發送自身creditReport請求
 */
export const applySelfCreditReport = async (data: Omit<CreditReportApplicationForm, 'companyName, queryType, companyId'>) => {
  return axios.post('/api/creditReport/applySelfCreditReport', data);
};

/**
 * ANCHOR 發送其他公司的creditReport請求
 */
export const applyCreditReport = async (data: CreditReportApplicationForm) => {
  return axios.post('/api/creditReport/applyCreditReport', data);
};

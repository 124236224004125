const categoryEn = [
  'Legal Services',
  'Film and television industry',
  'Agriculture',
  'Construction',
  'Education',
  'Electronics',
  'Energy',
  'Entertainment',
  'Financing and Insurance',
  'Healthcare',
  'Hospitality and Food Services',
  'Import/Export Trade and Wholesale',
  'Information Technology',
  'Machinery/Equipment',
  'Manufacturing',
  'Media and Communications',
  'Mining & Quarrying',
  'Pharmaceutical',
  'Professional and Business Services',
  'Real Estate',
  'Retail',
  'Social and Personal Services',
  'Textiles/Garments',
  'Transportation, Storage and Courier Services',
  'Utilities',
  'Waste Management',
  'Other',
];

export default categoryEn;

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import CryptoJS from 'crypto-js';

import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { Box } from '@/layout/Box';

import Link from '@/components/UI/Link';

import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';

import { forgotPassword, forgotPasswordVerification, resetPassword } from '@/api/auth.api';
import { AlertModal } from '@/components/UI/Modal';

interface FormStepperProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setUserMail: React.Dispatch<React.SetStateAction<string>>;
  userMail: string;
}

const FormStep1 = ({ setActiveStep, setUserMail, userMail }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { isDirty },
  } = useForm<{ email: string }>();

  const { emailRegister } = useAuthValidation();
  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = (await forgotPassword(data)).data;
      if (res.success) {
        setUserMail(data.email);
        setActiveStep(2);
      }
    } catch (error) {
      setError('email', {
        type: 'server',
        message: t('error.emailRegistered'),
      });
    }
  });

  useEffect(() => {
    // 進入時將使用者email值放入data
    setValue('email', userMail);
  }, [userMail]);

  return (
    <form onSubmit={onSubmit}>
      <Text marginBottom={'15px'}>{t('pages.auth.forgotPassword.content')}</Text>
      <InputWrapper>
        <Controller
          control={control}
          defaultValue=""
          name={'email'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('form.email.label')}
              placeholder={t('form.email.placeholder')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
              inputProps={{
                readOnly: true,
              }}
            />
          )}
          rules={emailRegister}
        />
      </InputWrapper>
      <Box>
        <Button size={'lg'} variant={'primary'} rounded={false} marginBottom={15}>
          Reset
        </Button>
      </Box>
    </form>
  );
};

interface Step2Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setResetToken: React.Dispatch<React.SetStateAction<string>>;
  userMail: string;
}

const FormStep2 = ({ setActiveStep, userMail, setResetToken }: Step2Props) => {
  // @ts-ignore
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty },
  } = useForm<{ verificationCode: string }>();

  const { verificationCode } = useAuthValidation();
  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = (await forgotPasswordVerification({ ...data, email: userMail })).data;
      if (res.success) {
        setResetToken(res.data.resetPasswordToken);
        setActiveStep(3);
      }
    } catch (error) {
      setError('verificationCode', {
        type: 'server',
        message: t('error.verification'),
      });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Text marginTop={'15px'}>{t('pages.auth.forgotPassword.illustrate.step2')}</Text>
      <InputWrapper>
        <Controller
          control={control}
          defaultValue=""
          name={'verificationCode'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('form.verificationCode.label')}
              placeholder={t('form.verificationCode.placeholder')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={verificationCode}
        />
      </InputWrapper>
      <Box>
        <Button size={'lg'} variant={'primary'} rounded={false} marginBottom={15} disabled={!isDirty}>
          {t('buttons.next')}
        </Button>
        <Text>
          {t('pages.auth.forgotPassword.prompt.step2.context')}{' '}
          <Link to={'/auth/signin'}>{t('buttons.resendCode')}</Link>
        </Text>
      </Box>
    </form>
  );
};

interface Step3Props {
  userMail: string;
  resetToken: string;
}

const FormStep3 = ({ userMail, resetToken }: Step3Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // @ts-ignore
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
  } = useForm<{ password: string; passwordConfirmation: string }>();

  const passwordRef = React.useRef('');
  const currentPassword = (passwordRef.current = watch('password', ''));

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = (
        await resetPassword({
          ...data,
          password: CryptoJS.MD5(data.password).toString(),
          email: userMail,
          resetPasswordToken: resetToken,
        })
      ).data;
      if (res.success) {
        setIsModalOpen(true);
      }
    } catch (error) {}
  });

  return (
    <>
      <AlertModal
        content={t('passwordResetDone')}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        userMail={userMail}
        type={'resetPW'}
      />
      <form onSubmit={onSubmit}>
        <Text marginBottom={'15px'}>{t('pages.auth.forgotPassword.illustrate.step3')}</Text>
        <InputWrapper>
          <Controller
            control={control}
            defaultValue=""
            name={'password'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.newPassword.label')}
                placeholder={t('form.newPassword.placeholder')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type={'password'}
                autoComplete="off"
              />
            )}
            rules={{
              required: t('required.newPassword'),
            }}
          />
          <Controller
            control={control}
            defaultValue=""
            name={'passwordConfirmation'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.reconfirmNewPassword.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type={'password'}
                autoComplete="off"
              />
            )}
            rules={{
              required: t('required.reconfirmNewPassword'),
              validate: (value) => value === currentPassword || 'The passwords do not match',
            }}
          />
        </InputWrapper>
        <Box>
          <Button size={'lg'} variant={'primary'} rounded={false} marginBottom={15} disabled={!isDirty}>
            {t('buttons.next')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export { FormStep1, FormStep2, FormStep3 };

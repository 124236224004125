import styled from 'styled-components';
import { lighten } from 'polished';

const DropzoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 25px;
  width: 100%;
  padding: 30px 0;
  border: 2px dashed ${(p) => p.theme.colors.gray300};
  border-radius: 15px;
  margin-bottom: 15px;
  transition-duration: 0.2s;

  &.drop-active {
    background-color: ${(p) => lighten(0.3, p.theme.colors.primary100)};
  }

  > p,
  div {
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    text-align: center;
    color: ${(p) => p.theme.colors.gray300};
  }
`;

export { DropzoneWrapper };

import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: 250px;
  margin-bottom: 20px;
  ${(p) => p.theme.outsideShadowW01}
`;

type StatusTagVariant = '' | 'inviting' | 'processing' | 'completed' | 'cancelled' | 'expired';

const statusTagVariant = ({ variant = '' }: { variant?: StatusTagVariant }) => {
  switch (variant) {
    case 'inviting':
      return css`
        background-color: ${(p) => p.theme.colors.yellow};
      `;
    case 'processing':
      return css`
        background-color: ${(p) => p.theme.colors.orange};
      `;
    case 'completed':
      return css`
        background-color: ${(p) => p.theme.colors.secondary500};
      `;
    case 'cancelled':
      return css`
        background-color: ${(p) => p.theme.colors.red};
      `;
    case 'expired':
      return css`
        background-color: ${(p) => p.theme.colors.red};
      `;
    default:
      return css`
        background-color: ${(p) => p.theme.colors.gray200};
      `;
  }
};

const StatusTag = styled.div<{ variant?: StatusTagVariant }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  color: ${(p) => p.theme.colors.light};
  border-radius: ${(p) => p.theme.radii.base};
  ${statusTagVariant}
`;

const ItemTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
`;

const ProgressStage = styled.div<{ complete?: boolean }>`
  position: relative;
  padding: 9px 25px;
  background-color: ${(p) => (p.complete ? p.theme.colors.secondary400 : p.theme.colors.gray100)};
  font-size: ${(p) => p.theme.fontSizes.md};
  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 3px;
    width: 35px;
    height: 100%;
    background-color: ${(p) => p.theme.colors.light};
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 85% 50%, 0 0);
  }
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    z-index: 1;
    width: 35px;
    height: 100%;
    background-color: ${(p) => p.theme.colors.gray100};
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 5% 100%, 90% 50%);
  }
`;

const Participant = styled.div`
  width: 100%;
  padding-left: 20px;

  .title {
    background: ${(p) => p.theme.colors.gray100};
    padding: 8px 20px;
    border-radius: 5px;
  }

  .name {
    padding: 10px;

    p {
      display: -webkit-box;
      white-space: nowrap;
      overflow: hidden;
      max-width: 240px;
      text-overflow: ellipsis;
      display: block;
    }
  }
`;

export { Wrapper, StatusTag, ItemTitle, ProgressStage, Participant };

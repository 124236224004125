import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CustomGetHWSign = () => {
  const { accessToken, spOrderNum, dealRole } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/sign/customGetHWSign.html?dealRole=${dealRole}&accessToken=${accessToken}&spOderNum=${spOrderNum}`);
  }, []);

  return <div>redirecting....</div>;
};

export default CustomGetHWSign;

import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Tabs from '@/components/UI/Tabs';
import PageContainer from '@/components/Container/PageContainer';
import { Participants, Other } from '@/components/ManageParticipants';
import DetailTitle from '@/components/DealDetail/Title';
import Loading from '@/components/UI/Loading';

import { getDealDetail, getInvitedParticipants } from '@/api/deal.api';

const DetailParticipantsPage = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();

  const tabLabel = t('pages.dealDetail.manageParticipants.tabs', { returnObjects: true });
  const initTabIndex = 1;

  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex);
  const [dealData, setDealData] = useState<DealListItem>();
  const [invitedParticipantList, setInvitedParticipantList] = useState<DealInvitedCompany[]>();

  const tabs: TabsType = [
    {
      label: tabLabel[0],
      index: initTabIndex,
      Component: () => <Participants dealId={id} />,
    },
    {
      label: tabLabel[1],
      index: 2,
      Component: () => (
        <Other invitedParticipantList={invitedParticipantList} setInvitedParticipantList={setInvitedParticipantList} />
      ),
    },
  ];

  const getInvitedList = async () => {
    if (!id) return;

    try {
      const res = (await getInvitedParticipants(id)).data;
      setInvitedParticipantList(res.data.company);
    } catch (error) {}
  };

  const initData = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getDealDetail(id)).data;
      const data: DealListItem = {
        id: res.data.id,
        tag: 'inviting',
        tradeName: res.data.name,
        count: {
          document: 0,
          participants: res.data.participants.length,
          events: 0,
        },
        participants: res.data.participants,
        dealInitiation: [res.data.progress === '0' ? false : true],
        negotiation: res.data.negotiation,
        deal_close: res.data.deal_close,
        contractExecution: [res.data.excution_file_complete],
        executions: res.data.executions,
        progress: res.data.progress,
        execution_file_complete: res.data.excution_file_complete,
        deal_type: res.data.deal_type.name,
        category: res.data.category,
        deal_type_id: Number(res.data.deal_type_id),
      };
      setDealData(data);
      getInvitedList();
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return dealData ? (
    <PageContainer title={<DetailTitle data={dealData} innerTitle={'Participants'} hideTag={true}/>}>
      <Tabs tabs={tabs} selectedTab={selectedTab} onClick={setSelectedTab} />
    </PageContainer>
  ) : (
    <Loading />
  );
};

export default DetailParticipantsPage;

import styled from 'styled-components';

const SearchIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  right: 0;
  top: 5px;
`;

const SearchBar = styled.form`
  display: flex;
  width: 100%;
  max-width: 600px;
  padding-bottom: 20px;
  position: relative;
`;

const ContentWrapper = styled.div`
  height: 185px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
  color: ${(p) => p.theme.colors.gray300};
`;

const CardWrapper = styled.div`
  ${(p) => p.theme.outsideShadowW02};
  padding: 25px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 1200px;
`;

export { CardWrapper, ContentWrapper, SearchIconWrapper, SearchBar };
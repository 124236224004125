import styled from 'styled-components';

const Tag = styled.div`
  padding: 2px 10px;
  background-color: ${(p) => p.theme.colors.primary500};
  color: ${(p) => p.theme.colors.light};
  border-radius: ${p=>p.theme.radii.base};
`;

export default Tag;

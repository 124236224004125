const en = ['Mr.', 'Ms.', 'Mrs.', 'Miss', 'Dr.', 'Prof.', 'Ir.'];
const sc = ['先生', '女士', '太太', '小姐', '博士', '教授', '工程师'];
const tc = ['先生', '女士', '太太', '小姐', '博士', '教授', '工程師'];

const title = {
  en,
  sc,
  tc,
};

export default title;

import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextField, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { InputWrapper, TipWrapper } from '@/components/Container/AuthContainer/styles';
import { CloudUpload, Delete, Download } from '@/components/Icon';
import { Text } from '@/components/UI/Typography/Text';
import { Flex, FormBox, LoadingPage } from '@/layout';

import { registrationSupplementalInfo, removeFile } from '@/api/auth.api';

import { InputFileWrapper, InputFileLabel } from '@/components/UI/Input/InputFile/styles';
import { theme } from '@/theme/Theme';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';
import { fileSizeValidator } from '@/utils/fileSizeValidator';
import {
  UserInfoBadge,
  SupplementalInfoBadge,
  IdentificationCheckBadge,
  ECertBadge,
  CreditReportBadge,
  AccountVerifiedBadge,
} from '@/components/UI/Badge';
import { convertLink } from '@/utils/convert';
import useResize from '@/hooks/useResize';
import { getTempData, removeTempData } from '@/utils/localStorageSave';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.fontSizes.base,
    textAlign: 'justify',
  },
}));

const SupplementalInfo = ({ nextHandler = () => {}, ButtonGroup, ReturnLink = '', userDetail }: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [brCertLink, setBrCertLink] = useState<string>('');
  const [isoCertLink, setIsoCertLink] = useState<string>('');
  const [eCertLink, setECertLink] = useState<string>('');

  const {
    reset,
    register,
    resetField,
    clearErrors,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<RegistrationSupplementalInfoForm>();

  const onSubmit = handleSubmit(async (data) => {
    // if (!checkBox.certificate) {
    //   setCheckBoxError('Please check the box to confirm that you have read and understood the terms and conditions');
    //   return;
    // } else {
    //   setCheckBoxError('');
    // }

    let postData = {};
    for (const [key, value] of Object.entries(data)) {
      if (!value) continue;
      postData = {
        ...postData,
        [key]: typeof value === 'string' ? value : value[0],
      };
    }

    try {
      setIsLoading(true);
      const res = (await registrationSupplementalInfo(postData as RegistrationSupplementalInfoRequest)).data;
      if (res.success) {
        removeTempData();
        nextHandler();
        successHandler(t('success.upload'));
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    if (!userDetail) {
      const tempData = getTempData();
      if (!tempData) return;
      setValue('acc_BR', tempData.acc_BR);
      return;
    }

    if (!userDetail.acc_BR) return;
    setValue('acc_BR', userDetail.acc_BR);
    setBrCertLink(userDetail.br_cert ? userDetail.br_cert.path : '');
    setIsoCertLink(userDetail.iso_cert ? userDetail.iso_cert.path : '');
    setECertLink(userDetail.e_cert ? userDetail.e_cert.path : '');
    if (userDetail.acc_LEI) setValue('acc_LEI', userDetail.acc_LEI);
  }, [setValue, userDetail]);

  // const [checkBoxError, setCheckBoxError] = useState<string>('');
  // const [checkBox, setCheckBox] = useState({
  //   certificate: false,
  // });

  // const { certificate } = checkBox;

  // const handleChange = (value: string, current: boolean) => {
  //   setCheckBox({ ...checkBox, [value]: !current });
  // };

  watch((data) => {
    if (data.br_cert && data.br_cert.length > 0 && !fileSizeValidator(data.br_cert[0], 1.5)) {
      clearErrors(['e_cert', 'credit_report', 'iso_cert']);
      resetField('br_cert');
      removeCurrentFile('br_cert');
      setError('br_cert', { type: 'manual', message: t('error.fileLimit') });
    }

    if (data.e_cert && data.e_cert.length > 0 && !fileSizeValidator(data.e_cert[0], 1.5)) {
      clearErrors(['br_cert', 'credit_report', 'iso_cert']);
      resetField('e_cert');
      removeCurrentFile('e_cert');
      setError('e_cert', { type: 'manual', message: t('error.fileLimit') });
    }

    if (data.credit_report && data.credit_report.length > 0 && !fileSizeValidator(data.credit_report[0], 1.5)) {
      clearErrors(['br_cert', 'e_cert', 'iso_cert']);
      resetField('credit_report');
      removeCurrentFile('credit_report');
      setError('credit_report', { type: 'manual', message: t('error.fileLimit') });
    }

    if (data.iso_cert && data.iso_cert.length > 0 && !fileSizeValidator(data.iso_cert[0], 1.5)) {
      clearErrors(['br_cert', 'e_cert', 'credit_report']);
      resetField('iso_cert');
      removeCurrentFile('iso_cert');
      setError('iso_cert', { type: 'manual', message: t('error.fileLimit') });
    }
  });

  const removeCurrentFile = async (type: string) => {
    const data = {
      fileType: type,
    };

    try {
      const res = (await removeFile(data)).data;
      if (res.success) {
        successHandler(t('success.removeFile'));
        if (type === 'br_cert') {
          setBrCertLink('');
          resetField('br_cert');
        } else if (type === 'iso_cert') {
          setIsoCertLink('');
          resetField('iso_cert');
        } else if (type === 'e_cert') {
          setECertLink('');
          resetField('e_cert');
        }
      }
    } catch (error: any) {
      errorHandler(error.response.data.data);
    } finally {
    }
  };

  return (
    <>
      <LoadingPage isLoading={isLoading} />
      <FormBox onSubmit={onSubmit} isTablet={isTablet}>
        <div>
          <Flex marginBottom={15} gridGap={'3px'}>
            <UserInfoBadge />
            <SupplementalInfoBadge />
            <IdentificationCheckBadge />
            <ECertBadge />
            <CreditReportBadge />
            <AccountVerifiedBadge />
          </Flex>
          <Text marginBottom={20}>{t('pages.auth.supplementalInformation.illustrate')}</Text>
        </div>
        <InputWrapper height={'65px'}>
          <Controller
            control={control}
            name={'acc_BR'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.businessRegistrationNumber.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
        </InputWrapper>

        <InputWrapper height={'65px'}>
          {isMobile && <Text color={theme.colors.gray400}>{t('info.BRCertificate')}</Text>}
          <InputFileWrapper>
            <InputFileLabel>
              {!isMobile && <Text color={theme.colors.gray400}>{t('info.BRCertificate')}</Text>}
              <Text
                margin={isMobile ? '0px' : '0 17px 0 20px'}
                color={!watch('br_cert')?.[0] ? theme.colors.gray300 : theme.colors.gray500}
              >
                {userDetail && brCertLink && !watch('br_cert')?.[0]
                  ? userDetail.br_cert.name
                  : watch('br_cert')?.[0]
                  ? watch('br_cert')[0].name
                  : t('info.chooseFile')}
              </Text>
              <input
                hidden
                accept="image/jpeg,image/png,application/pdf,image/x-eps"
                type="file"
                {...register('br_cert')}
              />
              {!watch('br_cert')?.[0] && <FormHelperText error>{errors.br_cert?.message}</FormHelperText>}
              <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
            </InputFileLabel>
            {userDetail && brCertLink && (
              <Download.FormDownload
                type="button"
                onClick={() => {
                  window.open(convertLink(brCertLink), '_blank');
                }}
              >
                <Download.DownloadSVG />
              </Download.FormDownload>
            )}
            {userDetail && brCertLink && (
              <Delete.FormDelete
                type="button"
                onClick={() => {
                  removeCurrentFile('br_cert');
                }}
              >
                <Delete.DeleteSVG />
              </Delete.FormDelete>
            )}
            {watch('br_cert')?.[0] && (
              <Delete.FormDelete
                type="button"
                onClick={() => {
                  resetField('br_cert');
                }}
              >
                <Delete.DeleteSVG />
              </Delete.FormDelete>
            )}
          </InputFileWrapper>
          <p>
            {t('uploadNotice')} JPG, PNG, PDF <span style={{ color: 'red' }}>{t('maxSize')} 1.5MB</span>
          </p>
        </InputWrapper>

        <InputWrapper height={'65px'}>
          {isMobile && <Text color={theme.colors.gray400}>{t('info.ISOCertification')}</Text>}
          <InputFileWrapper>
            <InputFileLabel>
              {!isMobile && <Text color={theme.colors.gray400}>{t('info.ISOCertification')}</Text>}
              <Text
                margin={isMobile ? '0' : '0 17px 0 20px'}
                color={!watch('iso_cert')?.[0] ? theme.colors.gray300 : theme.colors.gray500}
              >
                {userDetail && isoCertLink && !watch('iso_cert')?.[0]
                  ? userDetail.iso_cert.name
                  : watch('iso_cert')?.[0]
                  ? watch('iso_cert')[0].name
                  : t('info.chooseFile')}
              </Text>
              <input
                hidden
                accept="image/jpeg,image/png,application/pdf,image/x-eps"
                type="file"
                {...register('iso_cert')}
              />
              {!watch('iso_cert')?.[0] && <FormHelperText error>{errors.iso_cert?.message}</FormHelperText>}
              <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
            </InputFileLabel>
            {userDetail && isoCertLink && (
              <Download.FormDownload
                type="button"
                onClick={() => {
                  window.open(convertLink(isoCertLink), '_blank');
                }}
              >
                <Download.DownloadSVG />
              </Download.FormDownload>
            )}
            {userDetail && isoCertLink && (
              <Delete.FormDelete
                type="button"
                onClick={() => {
                  removeCurrentFile('iso_cert');
                }}
              >
                <Delete.DeleteSVG />
              </Delete.FormDelete>
            )}
            {watch('iso_cert')?.[0] && (
              <Delete.FormDelete
                type="button"
                onClick={() => {
                  resetField('iso_cert');
                }}
              >
                <Delete.DeleteSVG />
              </Delete.FormDelete>
            )}
          </InputFileWrapper>
          <p>
            {t('uploadNotice')} JPG, PNG, PDF <span style={{ color: 'red' }}>{t('maxSize')} 1.5MB</span>
          </p>
        </InputWrapper>

        <InputWrapper height={'65px'}>
          <Flex gridGap={15}>
            <Controller
              control={control}
              name={'acc_LEI'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('info.LEICode')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
            {!isMobile && (
              <CustomTooltip title={t('pages.auth.supplementalInformation.tips.lei')} placement="top">
                <TipWrapper isMobile={isMobile}>{t('buttons.whatThis')}</TipWrapper>
              </CustomTooltip>
            )}

            {/* <a href="https://uat.leihk.com/#/" target="_blank" rel="noreferrer">
              <Button size={'lg'} variant="primary-invert" type="button">
                Apply Now
              </Button>
            </a> */}
          </Flex>
        </InputWrapper>

        <InputWrapper height={'65px'}>
          {isMobile && <Text color={theme.colors.gray400}>{t('eCert')}</Text>}
          <Flex gridGap={15}>
            <InputFileWrapper>
              <InputFileLabel>
                {!isMobile && <Text color={theme.colors.gray400}>{t('eCert')}</Text>}
                <Text
                  margin={isMobile ? '0' : '0 17px 0 20px'}
                  color={!watch('e_cert')?.[0] ? theme.colors.gray300 : theme.colors.gray500}
                >
                  {userDetail && eCertLink && !watch('e_cert')?.[0]
                    ? userDetail.e_cert.name
                    : watch('e_cert')?.[0]
                    ? watch('e_cert')[0].name
                    : t('info.chooseFile')}
                </Text>
                <input hidden accept=".pdf,.zip" type="file" {...register('e_cert')} />
                {!watch('e_cert')?.[0] && <FormHelperText error>{errors.e_cert?.message}</FormHelperText>}
                <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
              </InputFileLabel>
              {userDetail && eCertLink && (
                <Download.FormDownload
                  type="button"
                  onClick={() => {
                    window.open(convertLink(eCertLink), '_blank');
                  }}
                >
                  <Download.DownloadSVG />
                </Download.FormDownload>
              )}
              {userDetail && eCertLink && (
                <Delete.FormDelete
                  type="button"
                  onClick={() => {
                    removeCurrentFile('e_cert');
                  }}
                >
                  <Delete.DeleteSVG />
                </Delete.FormDelete>
              )}
              {watch('e_cert')?.[0] && (
                <Delete.FormDelete
                  type="button"
                  onClick={() => {
                    resetField('e_cert');
                  }}
                >
                  <Delete.DeleteSVG />
                </Delete.FormDelete>
              )}
            </InputFileWrapper>
            {!isMobile && (
              <CustomTooltip title={t('pages.auth.supplementalInformation.tips.eCert')} placement="top">
                <TipWrapper isMobile={isMobile}>{t('buttons.whatThis')}</TipWrapper>
              </CustomTooltip>
            )}

            {/* <Button
              size={'lg'}
              variant="primary-invert"
              type='button'
            >
              <a href="e-Cert_(O_E_S)_N.pdf" target="_blank" rel="noreferrer">
              Apply Now
              </a>
            </Button> */}
          </Flex>

          <p>
            {t('uploadP12')} <span style={{ color: 'red' }}>{t('maxSize')} 1.5MB</span>
          </p>
        </InputWrapper>

        {ButtonGroup({...watch()})}
      </FormBox>
    </>
  );
};

export default SupplementalInfo;

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@/layout';
import { H3 } from '@/components/UI/Typography/Heading';
import { Button } from '@/components/UI/Button';

import { convertTiming } from '@/utils/convert';

import { CardWrapper, Description, TimeDifference, UnreadDot } from './style';
import { Link, useNavigate } from 'react-router-dom';
import { readSingleNotification, notificationDestroy } from '@/api/notifications.api';
import { useDispatch } from 'react-redux';
import { changeNotificationStatus, deleteNotification } from '@/redux/notification/notification.slice';
import { Delete } from '@/components/Icon';
import { theme } from '@/theme/Theme';
import { Checkbox } from '@mui/material';
import { successHandler } from '@/utils/toastHandler';

interface NotificationCards {
  id: number;
  title: string;
  desc: string;
  link: string;
  status: number;
  time: string;
}

interface NotificationProps {
  data: NotificationCards[];
  selectedAry: number[];
  showSelect: boolean;
  setSelectedAry?: React.Dispatch<React.SetStateAction<number[]>>;
}

const NotificationCard = ({ data, showSelect, selectedAry, setSelectedAry = () => {} }: NotificationProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const readNotification = async (id: number) => {
  //   if (item.status === 1) {
  //     await readSingleNotification(id);
  //     dispatch(changeNotificationStatus(id));
  //   }
  // };

  const readNotify = async (link: string, id: number) => {
    // 1.發出api通知後端已經讀取
    // 2.dispatch(changeNotificationStatus(id))調整redux的狀態
    // 3.跳轉頁面
    try {
      const res = (await readSingleNotification(id)).data;
    } catch (err) {
      console.log(err);
    }
    dispatch(changeNotificationStatus(id));
    navigate(link);
  };

  useEffect(() => {}, [data]);

  const addToSelectedAry = (index: number) => {
    if (selectedAry.includes(index)) {
      setSelectedAry(selectedAry.filter((item) => item !== index));
    } else {
      setSelectedAry([...selectedAry, index]);
    }
  };

  const deleteSingleNorification = async (id: number) => {
    try {
      const rawData = {
        ids: [id],
      };
      const res = (await notificationDestroy(rawData)).data;
      if (res.success) {
        dispatch(deleteNotification(rawData.ids));
        successHandler(t('success.delete'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CardWrapper>
      {data &&
        data.map((item) => (
          <Flex
            key={'notification' + item.id}
            gridGap={'20px'}
            width={'100%'}
            borderBottom={`1px solid ${theme.colors.gray100}`}
            paddingBottom={15}
            position={'relative'}
          >
            {/* 若selectedAry有出現index值 */}
            {showSelect && (
              <Checkbox
                sx={{ width: '80px', flexShrink: 0 }}
                onClick={() => addToSelectedAry(item.id)}
                checked={selectedAry.includes(item.id)}
              />
            )}
            <Box width={'100%'} maxWidth={'calc(100% - 165px)'}>
              <H3 fontWeight={'bold'} marginBottom={'8px'}>
                {item.title}
              </H3>
              <Description>{item.desc}</Description>
              <TimeDifference>{item.time}</TimeDifference>
            </Box>
            <Flex gridGap={'5px'} justifyContent={'flex-end'} alignItems={'flex-end'}>
              <Button type="button" onClick={() => readNotify(item.link, item.id)} variant="regular" size="sm">
                {t('buttons.showDetails')}
              </Button>
              <Delete.FormDelete type="button" onClick={() => deleteSingleNorification(item.id)}>
                <Delete.DeleteSVG fill={theme.colors.gray400} />
              </Delete.FormDelete>
            </Flex>

            {item.status === 1 && <UnreadDot />}
          </Flex>
        ))}
    </CardWrapper>
  );
};

export default NotificationCard;

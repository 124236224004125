import styled from 'styled-components';
import { box, BoxProps } from '@/layout/Box';

const InputWrapper = styled.div<BoxProps>`
  display: flex;
  position: relative;
  flex-flow: column;
  flex-grow: 1;
  justify-content: space-evenly;
  margin-bottom: 30px;
  ${box};
`;

const InputFileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
`;

const InputFileLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-right: 15;
  box-sizing: content-box;
`;

const Ul = styled.ul`
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 30px;

  li {
    margin-bottom: 5px;
  }
`;

export {
    InputWrapper,
    InputFileWrapper,
    InputFileLabel,
    Ul,
}
import { useTranslation } from 'react-i18next';
import { GreenLinkSpan } from './style';
import Plans from '@/components/Plans';

interface SetPageProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
}

const ServicesList = ({ setCurrentPage }: SetPageProps) => {
    // @ts-ignore
    const { t } = useTranslation();

    return (
        <>
            {/* <GreenLinkSpan onClick={() => setCurrentPage('CurrentServices')}>{t('buttons.back')}</GreenLinkSpan> */}
            <Plans />
        </>
    );
};

export default ServicesList;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

interface FileMenuProps {
  path?: string;
  items: DataTableMenuItems[];
  subItems?: DataTableMenuItems[];
  fileType: string;
}

export default function FileMenu({ path = '', items, subItems, fileType }: FileMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        ⋯
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={`m-${item}`}
            onClick={() => {
              item.onClick(path);
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        ))}
        {subItems && <Divider />}
        {subItems &&
          subItems.map((item) => (
            <MenuItem
              style={{ display: item.name === 'Invite to sign' && fileType !== 'pdf' ? 'none' : 'block' }}
              key={`sm-${item}`}
              sx={{ color: item.danger ? '#DC1010' : '' }}
              onClick={() => {
                item.onClick();
                handleClose();
              }}
            >
              {item.name}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

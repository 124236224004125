import styled from 'styled-components';

const ChatButton = styled.button`
  padding: 15px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  border-radius: 5px 5px 5px 0px;
  background: ${(p) => p.theme.colors.primary500};

  svg {
    transform: scale(1.5);
  }

  &.disabled {
    background: ${(p) => p.theme.colors.gray300};
  }

  &.active {
    background: ${(p) => p.theme.colors.orange};

    .messageCounter {
      dispaly: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 25px;
      height: 25px;
      top: -15px;
      left: -15px;
      background: ${(p) => p.theme.colors.red};
      color: ${(p) => p.theme.colors.light};
      border-radius: 50%;
      font-size: ${(p) => p.theme.fontSizes.base};
      line-height: 25px;
    }
  }
`;

export { ChatButton };

import styled from 'styled-components';
import { darken, lighten } from 'polished';

const TabButton = styled.button<{ fullWidth: boolean }>`
  flex: 1;
  display: inline-block;
  padding: 8px 15px;
  margin-right: ${(p)=> (p.fullWidth ? '0px' : '5px')};
  background-color: ${(p) => p.theme.colors.gray200};
  color: ${(p) => p.theme.colors.light};
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: ${(p)=> (p.fullWidth ? '0px' : '5px')};
  &.active {
    background-color: ${(p) => p.theme.colors.secondary400};
  }
  :hover {
    background-color: ${(p) => lighten(0.02, p.theme.colors.secondary500)};
  }
  :active {
    background-color: ${(p) => darken(0.02, p.theme.colors.secondary500)};
  }
`;

export { TabButton };

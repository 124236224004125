import { useTranslation } from 'react-i18next';
import { InputWrapper } from './styles';

import { Button } from '@/components/UI/Button';

interface ButtonGroupProps {
  Buttons: { title: string }[];
}

const FileUpload = ({ Buttons = [] }: ButtonGroupProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <InputWrapper>
      <label htmlFor="file">
        <input id="file" type="file" style={{ display: 'none' }} />
        Drag and drop files to upload
      </label>
      <div style={{ margin: '0 25px' }}>or</div>
      {Buttons.map((button) => (
        <Button variant={'primary'} style={{ marginRight: '20px' }} size={'md'}>
          {button.title}
          {/* t('key') */}
        </Button>
      ))}
    </InputWrapper>
  );
};
export default FileUpload;

import styled, { css } from 'styled-components';
import { Oval } from 'react-loading-icons';

const DarkBackground = styled.div<{ disappear: boolean }>`
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  ${(p) =>
    p.disappear &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

interface useLoadingPageProps {
  isLoading: boolean;
}

const LoadingPage = ({ isLoading }: useLoadingPageProps) => {
  return (
    <DarkBackground disappear={isLoading}>
      <Oval height={'3rem'} />
    </DarkBackground>
  );
};

export default LoadingPage;

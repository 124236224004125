import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  generateLink,
  storeEvent,
  getExistDeal,
  getSingleEventList,
  updateEvent,
  joinMeetingForGuest,
} from '@/api/event.api';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { Box, TextField } from '@mui/material';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { Flex } from '@/layout/Flex';
import { convertToSelectData } from '@/utils/convert';
import { FormWrapper, InputGroupWrapper, CopyIconWarpper } from './style';
import PageContainer from '@/components/Container/PageContainer';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '@/components/UI/Loading';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';
import { NeedUpgradePlanModal } from '@/components/DocLibrary/Modal';

const NewEvent = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { inputRegister } = useAuthValidation();
  const [meetingURL, setMeetingURL] = useState(' ');
  const navigate = useNavigate();
  const { userData } = useAppSelector(getAuthSelector);
  //   const [noticeAry, setNoticeAry] = useState<Notice[]>([]);
  const [dealData, setDealData] = useState<DealDetail[]>();
  const [existOption, setExistOption] = useState<Array<string | number>>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [vcId, setVcId] = useState<string>('');

  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dealId, setDealId] = useState<string | null>(null);
  const [guestId, setGuestId] = useState<string | null>(null);
  const [editId, setEditId] = useState<string | null>(null);
  const [showSelect, setShowSelect] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const inputProps = {
    step: 15,
    min: 30,
  };
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

  const { handleSubmit, control, setValue, watch } = useForm<EventFormFields>();

  const initData = useCallback(async () => {
    // 用來檢測該input是否為local格式, 若是則將其轉為指定格式
    const currentDateInput = watch('evt_startDate');
    const isLocalFormat = /^\d{2}\/\d{2}\/\d{4}$/.test(currentDateInput);

    const date = new Date().toLocaleDateString().split('/');

    let currentDate = '';

    if (isLocalFormat) {
      currentDate = `${parseInt(date[2]) < 10 ? '0' + date[2] : date[2]}-${
        parseInt(date[1]) < 10 ? '0' + date[1] : date[1]
      }-${date[0]}`;
    } else {
      currentDate = `${date[0]}-${parseInt(date[1]) < 10 ? '0' + date[1] : date[1]}-${
        parseInt(date[2]) < 10 ? '0' + date[2] : date[2]
      }`;
    }

    let hours = new Date().getHours();
    let minutes = new Date().getMinutes();

    // 判斷minutes是否為0或5的倍數, 若否則將minutes無條件進位到5的倍數, 若剛好為60則將hours加1
    if (minutes % 5 !== 0) {
      minutes = Math.ceil(minutes / 5) * 5;
      if (minutes === 60) {
        hours += 1;
        minutes = 0;
      }
    }

    const currentTime = `${hours < 10 ? '0' + hours : '' + hours}:${
      minutes === 0 ? '00' : minutes === 5 ? '05' : minutes
    }:00`;

    setValue('evt_startDate', currentDate);
    setValue('evt_startTime', currentTime);
    setValue('evt_endTime', '30');

    try {
      const res = (await getExistDeal()).data;
      if (res.success) {
        setDealData(res.data);
        let defaultOption = '';
        const option: string[] = [];
        res.data.forEach((item: ExistDeal) => {
          option.push(item.name);
          if (searchParams.get('deal') && item.id.toString() === searchParams.get('deal')) {
            defaultOption = item.name;
          }
        });

        setExistOption(option);
        if (searchParams.get('deal')) {
          setValue('evt_type', defaultOption);
          setShowSelect(false);
        }
        setIsLoading(false);
      }
    } catch (error) {}

    // 檢查是否有deal傳入
    if (searchParams.get('deal')) {
      setDealId(searchParams.get('deal'));
      setGuestId(searchParams.get('id'));
    }
  }, [location.search]);

  const getEvent = async () => {
    if (!searchParams.get('edit')) return;
    setEditId(searchParams.get('edit'));
    try {
      const res = (await getSingleEventList(Number(searchParams.get('edit')))).data;
      if (res.success) {
        setValue('evt_name', res.data.evt_name);
        setValue('evt_note', res.data.evt_note);
        const start = res.data.evt_startDate?.split(' ');
        if (start && start.length >= 2) {
          setValue('evt_startDate', start[0]);
          setValue('evt_startTime', start[1]);
        }
        setIsEdit(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    initData();
    getEvent();
  }, [initData]);

  const URLGenerator = async () => {
    if (!userData.plan.video_conference) {
      setShowUpgradeModal(true);
      return;
    }

    try {
      const res = (await generateLink()).data;

      if (res.success) {
        setVcId(res.data.meetingID);
        setMeetingURL(window.location.origin + '/event/joinMeeting?meetingID=' + res.data.meetingID);
      }
    } catch (error) {}
  };

  const onSubmit = handleSubmit(async (data) => {
    // 計算開會時長, end_time為預計開會時長, 計算方式為開始日期(gmt+8)加上預計開會時長
    const endTime = new Date(`${data.evt_startDate} ${data.evt_startTime}:00`);
    endTime.setMinutes(endTime.getMinutes() + Number(data.evt_endTime));
    // 將endTime轉為指定日期格式: YYYY-MM-DD HH:MM
    const endTimeString = `${endTime.getFullYear()}-${
      endTime.getMonth() + 1
    }-${endTime.getDate()} ${endTime.getHours()}:${endTime.getMinutes()}:00`;

    const rawData = {
      ...data,
      evt_name: data.evt_type ? data.evt_type + ': ' + data.evt_name : data.evt_name,
      evt_startDate: `${data.evt_startDate} ${data.evt_startTime}`,
      evt_endDate: endTimeString,
      vc_id: vcId,
      evt_dl_id: dealId ? dealId : '',
      evt_guest_id: guestId ? guestId : '',
      evt_host_id: userData.id,
    };

    try {
      if (!editId) {
        const res = (await storeEvent(rawData)).data;
        if (res.success) {
          successHandler(t('success.upload'));
        }
      } else {
        const res = (await updateEvent(Number(editId), rawData)).data;
        if (res.success) {
          successHandler(t('success.update'));
        }
      }
      navigate('/event');
    } catch (error) {
      errorHandler(t('error.server'));
    }
  });

  // 監視evt_type的變化, 若有變化則更新dealId和guestId
  // 要找出和userId不同的id
  watch((data) => {
    if (!dealData) return;
    if (data.evt_type) {
      dealData.forEach((item) => {
        if (item.name === data.evt_type) {
          setDealId(item.id.toString());
          item.participants.forEach((participant) => {
            if (participant.acc_id !== userData.id) {
              setGuestId(participant.acc_id.toString());
            }
          });
        }
      });
    }
  });

  const copyMeetingLink = async () => {
    // 若meetingURL為空則不執行
    if (meetingURL === ' ') return;

    // 針對safari瀏覽器的複製功能
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(meetingURL);
        successHandler(t('success.copied'));
      } catch (error) {}
      return;
    }

    // 針對其他瀏覽器的複製功能
    const textArea = document.createElement('textarea');
    textArea.value = meetingURL;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    successHandler(t('success.copied'));
  };

  return (
    <PageContainer title={t('pages.event.newEvent.title')}>
      {isLoading ? (
        <Loading />
      ) : (
        <FormWrapper onSubmit={onSubmit}>
          <NeedUpgradePlanModal showModal={showUpgradeModal} setShowModal={setShowUpgradeModal} />
          <Flex flexDirection={'column'} gridGap={'45px'}>
            {!showSelect && !isEdit && (
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <TextField
                      type="text"
                      variant="standard"
                      label={''}
                      value={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={!!error}
                      helperText={error ? error.message : null}
                      inputProps={{ readOnly: true }}
                      autoComplete="off"
                    />
                  </div>
                )}
                name={'evt_type'}
              />
            )}

            {showSelect && !isEdit && (
              <FormSelect
                control={control}
                data={convertToSelectData(existOption)}
                inputLabel={t('form.existingDeal.label')}
                selectLabel={t('form.existingDeal.label')}
                name={'evt_type'}
              />
            )}

            <Controller
              control={control}
              name={'evt_name'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  variant="standard"
                  label={t('form.addATitle.label')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={inputRegister}
            />

            <InputGroupWrapper>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <TextField
                      type="date"
                      variant="standard"
                      label={''}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete="off"
                    />
                  </div>
                )}
                name={'evt_startDate'}
                rules={inputRegister}
              />
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <TextField
                      type="time"
                      variant="standard"
                      label={''}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete="off"
                    />
                  </div>
                )}
                name={'evt_startTime'}
                rules={inputRegister}
              />
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Flex alignItems={'flex-end'}>
                    <TextField
                      type="number"
                      variant="standard"
                      label={''}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      inputProps={inputProps}
                      autoComplete="off"
                    />
                    {t('pages.event.newEvent.minutes')}
                  </Flex>
                )}
                name={'evt_endTime'}
                rules={inputRegister}
              />
              {meetingURL === ' ' && (
                <Button
                  onClick={URLGenerator}
                  style={{ borderRadius: 5, marginLeft: 'auto', padding: 0 }}
                  size={'lg'}
                  variant={'primary'}
                  type="button"
                >
                  {t('buttons.generateURL')}
                </Button>
              )}
              {meetingURL !== ' ' && (
                <Button
                  style={{ borderRadius: 5, marginLeft: 'auto', padding: 0 }}
                  size={'lg'}
                  variant={'normal'}
                  type="button"
                >
                  {t('buttons.generateURL')}
                </Button>
              )}
            </InputGroupWrapper>

            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div style={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    disabled
                    variant="standard"
                    label={t('form.meetingURL.label')}
                    value={meetingURL}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                  {meetingURL !== ' ' && (
                    <CopyIconWarpper>
                      <Button type="button" onClick={copyMeetingLink}>
                        <img src="/images/event/copy.svg" alt="" />
                      </Button>
                    </CopyIconWarpper>
                  )}
                </div>
              )}
              name={'vc_id'}
            />

            <div>{t('pages.event.newEvent.addADescription')}</div>
            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  variant="standard"
                  label={''}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              name={'evt_note'}
            />
            <Box>
              <Button size={'lg'} variant={'primary'}>
                {t('buttons.save')}
              </Button>
              <Button type={'button'} onClick={() => navigate('/event')} style={{ marginLeft: 30 }} size={'lg'}>
                {t('buttons.back')}
              </Button>
            </Box>
          </Flex>
        </FormWrapper>
      )}
    </PageContainer>
  );
};

export default NewEvent;

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  ContainerWrapper,
  FooterWrapper,
  Navbar,
  Anchor,
  MediaList,
  MediaLink,
  LeftWrapper,
  RightWrapper,
} from './style';
import useResize from '@/hooks/useResize';
import { Box, Flex } from '@/layout';
import { Mail, Phone, Pin } from '@/components/Icon';

const Footer = () => {

  return <DesktopFooter />;
};

const DesktopFooter = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const footerLinks = t('pages.footer', { returnObjects: true });

  const socialMedia = [
    // {
    //   icon: '/images/socialMedia/facebook.svg',
    //   link: 'https://www.facebook.com/eBRAM.ODR',
    // },
    {
      icon: '/images/socialMedia/linkedin.svg',
      link: 'https://www.linkedin.com/company/ebram-odr/',
    },
  ];

  const { handleSubmit, control } = useForm<{ key: string }>();

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
  });

  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toBottom = () => {
    window.scrollTo({ top: 100000, behavior: 'smooth' });
  };

  return (
    <ContainerWrapper>
      <FooterWrapper isTablet={isTablet} isMobile={isMobile}>
        <LeftWrapper isTablet={isTablet} isMobile={isMobile}>
          <div className="infoBox">
            <Box width={'24px'}>
              <Pin.PinSVG />
            </Box>
            <div>
            Room 403, 4/F, West Wing, Justice Place, 11 Ice House Street, Central, Hong Kong
            </div>
          </div>
          <div className="infoBox">
            <Mail.MailSVG />
            <a href="mailto:dealmaking@ebram.org">
            dealmaking@ebram.org
            </a>
          </div>
          <div className="infoBox">
            <Phone.PhoneSVG />
            <div>
            +852 3580 8280
            </div>
          </div>
        </LeftWrapper>
        <RightWrapper isTablet={isTablet} isMobile={isMobile}>
          <Navbar isTablet={isTablet} isMobile={isMobile}>
            {footerLinks.map((item) => (
              <Link onClick={item.link !== '/About#Contact' ? toTop : toBottom} key={item.name} to={item.link}>
                <Anchor>{item.name}</Anchor>
              </Link>
            ))}
            {/* <a target="_blank" rel="noreferrer" href="https://www.ebram.org/deal_making/user_guide/">
              <Anchor>{t('info.userGuide')}</Anchor>
            </a>
            <a target="_blank" href="/files/PICSforDeal-makingPortal.pdf">
              <Anchor>{t('info.privacyPolicy')}</Anchor>
            </a>
            <a target="_blank" href="/files/TermsAndConditionsOfServicesForDealMakingPortal.pdf">
              <Anchor>{t('info.termsOfUse')}</Anchor>
            </a> */}
          </Navbar>
          <MediaList isTablet={isTablet} isMobile={isMobile}>
            {socialMedia.map((item) => (
              <MediaLink key={item.link} target="_blank" href={item.link}>
                <img width={'100%'} src={item.icon} alt="icon" />
              </MediaLink>
            ))}
            {/* <div style={{ width: 250 }}>
            <SearchBar onSubmit={onSubmit}>
              <Controller
                control={control}
                defaultValue=""
                name={'key'}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    placeholder=""
                    variant="outlined"
                    value={value}
                    sx={{ background: '#FFF', borderRadius: '5px 0 0 5px' }}
                    onChange={onChange}
                    size="small"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <IconButton aria-label="search" style={{ padding: '0', margin: '0' }}>
                <SearchIconWrapper>
                  <img width={32} src="/images/yellowpage/search.svg" alt="" />
                </SearchIconWrapper>
              </IconButton>
            </SearchBar>
          </div> */}
          </MediaList>
          <div className="copyright">© 2024 eBRAM International Online Dispute Resolution Centre Limited. All Rights Reserved.</div>
        </RightWrapper>
      </FooterWrapper>
    </ContainerWrapper>
  );
};

export default Footer;

import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { TypographyProps, compose, typography } from 'styled-system';

type LinkProps = RouterLinkProps & TypographyProps;

const styledLink = compose(typography);

const createLink: React.FC<LinkProps> = ({ ...rest }) => {
  return <RouterLink {...rest} />;
};

const StyledLink = styled(createLink)`
  font-size: ${(p) => p.theme.fontSizes.md};
  :hover {
    color: ${(p) => p.theme.colors.secondary500};
  }
  ${styledLink}
`;

export default StyledLink;

import { axios } from '@/utils/axios';

export const getMeta = () => {
  return axios.get<Meta>('/api/frontData/meta');
}

export const getPosts = () => {
  return axios.get<BannerPost[]>('/api/frontData/post');
};

export const getFeatures = () => {
  return axios.get<BannerFeature[]>('/api/frontData/feature');
};

export const getFeatureDetail = (id: string) => {
  return axios.get<BannerFeature>(`/api/frontData/feature/${id}`);
};
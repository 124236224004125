import { useState } from 'react';
import { Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { InputFileWrapper, InputFileLabel } from '@/components/UI/Input/InputFile/styles';
import { CloudUpload } from '@/components/Icon';
import { ErrorMessage, Text } from '@/components/UI/Typography/Text';
import { LoadingPage } from '@/layout';

import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { registrationECert } from '@/api/auth.api';

import { theme } from '@/theme/Theme';

const ECert = ({ nextHandler = () => {}, ButtonGroup, ReturnLink = '', userDetail }: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { inputRegister } = useAuthValidation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<{
    e_cert: FileList;
  }>();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const res = (await registrationECert(data.e_cert[0])).data;
      if (res.success){
        nextHandler();
        successHandler(t('success.upload'))
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data)
    }
  });

  return (
    <Paper square elevation={0}>
      <LoadingPage isLoading={isLoading} />

      <form onSubmit={onSubmit} style={{ maxWidth: '930px', padding: '30px 50px' }}>
        <InputWrapper>
          <p>{t('eCertUpload')}</p>
        </InputWrapper>
        <InputWrapper height={'85px'}>
          <InputFileWrapper>
            <InputFileLabel>
              <Text color={theme.colors.gray400}>{t('eCert')}</Text>
              <Text margin={'0 17px 0 20px'} color={theme.colors.gray200}>
                {userDetail && userDetail.e_cert && !watch('e_cert')?.[0]
                  ? userDetail.e_cert.name
                  : watch('e_cert')?.[0]
                  ? watch('e_cert')[0].name
                  : t('info.chooseFile')}
              </Text>
              <input
                hidden
                accept=".pdf,.zip"
                type="file"
                {...register('e_cert', userDetail && userDetail.e_cert ? { required: false } : { ...inputRegister })}
              />
              <ErrorMessage>{errors.e_cert?.message}</ErrorMessage>
              <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
            </InputFileLabel>
          </InputFileWrapper>
        </InputWrapper>
        {ButtonGroup({})}
      </form>
    </Paper>
  );
};

export default ECert;

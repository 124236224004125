import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { theme } from '@/theme/Theme';
import { useTranslation } from 'react-i18next';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'unset',
    maxWidth: 'unset',
    fontSize: theme.fontSizes.base,
    whiteSpace: 'nowrap',
  },
}));

interface BadgeProps {
  width?: string | number;
  align?: string;
}

const Icon = styled.div`
  display: flex;
`;

const UserInfoBadge = ({ width = 24, align = 'center' }: BadgeProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <CustomTooltip title={t('badges.businessRegistration')} placement="top">
      <Icon style={{ width, alignItems: align }}>
        <img width={'100%'} src="/images/badge/user_info.svg" alt="" />
      </Icon>
    </CustomTooltip>
  );
};

const SupplementalInfoBadge = ({ width, align }: BadgeProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <CustomTooltip title={t('badges.ISOCerification')} placement="top">
      <Icon style={{ width, alignItems: align }}>
        <img width={'100%'} src="/images/badge/supplemental_info.svg" alt="" />
      </Icon>
    </CustomTooltip>
  );
};

const IdentificationCheckBadge = ({ width, align }: BadgeProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <CustomTooltip title={t('badges.LEICerification')} placement="top">
      <Icon style={{ width, alignItems: align }}>
        <img width={'100%'} src="/images/badge/identification_check.svg" alt="" />
      </Icon>
    </CustomTooltip>
  );
};

const ECertBadge = ({ width, align }: BadgeProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <CustomTooltip title={t('badges.eCert')} placement="top">
      <Icon style={{ width, alignItems: align }}>
        <img width={'100%'} src="/images/badge/ecert.svg" alt="" />
      </Icon>
    </CustomTooltip>
  );
};

const CreditReportBadge = ({ width, align }: BadgeProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <CustomTooltip title={t('badges.creditReport')} placement="top">
      <Icon style={{ width, alignItems: align }}>
        <img width={'100%'} src="/images/badge/credit_report.svg" alt="" />
      </Icon>
    </CustomTooltip>
  );
};

const AccountVerifiedBadge = ({ width, align }: BadgeProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <CustomTooltip title={t('badges.accountVerified')} placement="top">
      <Icon style={{ width, alignItems: align }}>
        <img width={'100%'} src="/images/badge/account_verified.svg" alt="" />
      </Icon>
    </CustomTooltip>
  );
}

export { UserInfoBadge, SupplementalInfoBadge, IdentificationCheckBadge, ECertBadge, CreditReportBadge, AccountVerifiedBadge};

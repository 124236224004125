import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/UI/Button';

import { DropzoneWrapper } from './styles';
import { errorHandler } from '@/utils/toastHandler';

export interface DropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void;
  onDrag?: () => void;
  onDragIn?: () => void;
  onDragOut?: () => void;
  onDrop?: () => void;
  onFilesDrop?: (files: File[]) => void;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  onUploadFile?: () => void;
  files: File[];
}

const DropZone = React.memo(
  ({
    onDragStateChange,
    onFilesDrop,
    onDrag,
    onDragIn,
    onDragOut,
    onDrop,
    setFiles,
    children,
    files,
  }: React.PropsWithChildren<DropZoneProps>) => {
    // @ts-ignore
    const { t } = useTranslation();
    const [isDragActive, setIsDragActive] = useState(false);
    const dropZoneRef = React.useRef<null | HTMLDivElement>(null);

    const mapFileListToArray = (files: FileList) => {
      const array = [];
      for (let i = 0; i < files.length; i++) {
        array.push(files.item(i));
      }
      return array;
    };

    const handleDragIn = React.useCallback(
      (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onDragIn?.();
        if (event.dataTransfer)
          if (event.dataTransfer.items && event.dataTransfer.items.length > 0) setIsDragActive(true);
      },
      [onDragIn]
    );

    const handleDragOut = React.useCallback(
      (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onDragOut?.();
        setIsDragActive(false);
      },
      [onDragOut]
    );

    const handleDrag = React.useCallback(
      (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onDrag?.();
        if (!isDragActive) setIsDragActive(true);
      },
      [isDragActive, onDrag]
    );

    const handleDrop = React.useCallback(
      (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false);
        onDrop?.();
        if (event.dataTransfer)
          if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const files = mapFileListToArray(event.dataTransfer.files) as File[];
            // console.log(files.map((file) => file.type));
            onFilesDrop?.(files);
            event.dataTransfer.clearData();
          }
      },
      [onDrop, onFilesDrop]
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      const files = e.target.files;
      const filesArray = Array.from(files);
      const invalidFiles = filesArray.filter((file) => {
        const pattern = /^[^\\/:*?"<>|\s]+$/;
        return !pattern.test(file.name);
      });
      if (invalidFiles.length > 0) {
        errorHandler(t('error.invalidFileName'));
        return;
      }

      setFiles(Object.values(e.target.files));
    };

    React.useEffect(() => {
      onDragStateChange?.(isDragActive);
    }, [isDragActive, setFiles]);

    React.useEffect(() => {}, [t]);

    React.useEffect(() => {
      // 檢查若files沒有東西，則清空input
      if (files.length === 0) {
        const input = document.getElementById('file-upload') as HTMLInputElement;
        input.value = '';
      }
    }, [files]);

    React.useEffect(() => {
      const tempZoneRef = dropZoneRef?.current;
      if (tempZoneRef) {
        tempZoneRef.addEventListener('dragenter', handleDragIn);
        tempZoneRef.addEventListener('dragleave', handleDragOut);
        tempZoneRef.addEventListener('dragover', handleDrag);
        tempZoneRef.addEventListener('drop', handleDrop);
      }

      return () => {
        tempZoneRef?.removeEventListener('dragenter', handleDragIn);
        tempZoneRef?.removeEventListener('dragleave', handleDragOut);
        tempZoneRef?.removeEventListener('dragover', handleDrag);
        tempZoneRef?.removeEventListener('drop', handleDrop);
      };
    }, [handleDrag, handleDragIn, handleDragOut, handleDrop]);

    return (
      <DropzoneWrapper className={isDragActive ? 'drop-active' : ''} ref={dropZoneRef}>
        <p>{t('dropFile')}</p>
        <p>{t('buttons.or')}</p>
        <input
          id="file-upload"
          type="file"
          hidden
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
          <Button variant={'primary'} special={'fake'}>
            {t('buttons.upload')}
          </Button>
        </label>
        {children}
      </DropzoneWrapper>
    );
  }
);

export default DropZone;

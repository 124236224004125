import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../styles';
import { Box } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { H5 } from '@/components/UI/Typography/Heading';
import { RotateArrow } from '@/components/Icon';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  status: number;
}

const RegistrationStatus = ({ status, setCurrentStep, setActiveStep }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [seatchParmas, setSearchParams] = useSearchParams();
  const statusArray = t('pages.eTrust.LEI.steps.step4.status', { returnObjects: true });

  const prevHandler = () => {
    setCurrentStep('3');
    setActiveStep(2);
    setSearchParams({ step: '3' });
  };

  const nextHandler = () => {
    navigate('/home');
  };

  return (
    <Wrapper>
      <Box marginBottom={100}>
        <H5 marginBottom={50}>
          {t('pages.eTrust.LEI.steps.step4.intro-top')}
          <br />
          {t('pages.eTrust.LEI.steps.step4.intro-bottom')}
        </H5>
        <div>
          <Text fontWeight={700} marginBottom={20}>
            {t('pages.eTrust.LEI.steps.step4.progress')}
          </Text>
          <Button size={'lg'} type="button" variant={'primary-invert'} rounded={false} marginRight={40}>
            <RotateArrow.RotateRightSVG />
            {statusArray[status]}
          </Button>
        </div>
      </Box>

      <Box style={{ paddingTop: 22 }}>
        <Button size={'lg'} type="button" variant={'primary'} rounded={false} marginRight={40} onClick={nextHandler}>
          {t('buttons.ok')}
        </Button>
        {/* <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
          {t('buttons.back')}
        </Button> */}
      </Box>
    </Wrapper>
  );
};

export default RegistrationStatus;

import { useEffect } from 'react';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { Modal } from '@mui/material';
import { ModalWrapper } from './styles';
import { Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  remain: string;
}

const StorageAlmostFullModal = ({ showModal, setShowModal, remain }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalWrapper>
        <Text color={theme.colors.primary500} fontWeight={700}>
          {t('pages.documentLibrary.modal.almostFull.title')}
        </Text>
        <Text textAlign={'center'} marginTop={50}>
          {t('pages.documentLibrary.modal.almostFull.content.top')}{' '}
          <Text color={theme.colors.danger} style={{ display: 'inline' }}>
            {remain}
          </Text>{' '}
          {t('pages.documentLibrary.modal.almostFull.content.bottom')}
        </Text>
        <Text textAlign={'center'} marginTop={20}>
          {t('pages.documentLibrary.modal.almostFull.plans.top')}{' '}
          <Text color={theme.colors.primary500} style={{ display: 'inline', textDecoration: 'underline' }}>
            {t('pages.documentLibrary.modal.almostFull.plans.middle')}
          </Text>{' '}
          {t('buttons.or')}{' '}
          <Text color={theme.colors.primary500} style={{ display: 'inline', textDecoration: 'underline' }}>
            {t('pages.documentLibrary.modal.almostFull.plans.bottom')}
          </Text>
        </Text>
        <Flex gridGap={10} paddingTop={50}>
          <Link to="/profile/subscriptionDetail">
            <Button size={'lg'} variant={'primary'}>
              {t('buttons.upgradePlan')}
            </Button>
          </Link>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

const StorageFullModal = ({ showModal, setShowModal }: Omit<ModalProps, 'remain'>) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalWrapper>
        <Text color={theme.colors.danger} fontWeight={700}>
          {t('pages.documentLibrary.modal.full.title')}
        </Text>
        <Text marginTop={50}>{t('pages.documentLibrary.modal.full.title')}</Text>
        <Text marginTop={20}>
          {t('pages.documentLibrary.modal.full.content.top')}{' '}
          <Text color={theme.colors.primary500} style={{ display: 'inline', textDecoration: 'underline' }}>
            {t('pages.documentLibrary.modal.full.content.middle')}
          </Text>{' '}
          {t('buttons.or')}
          <Text color={theme.colors.primary500} style={{ display: 'inline', textDecoration: 'underline' }}>
            {t('pages.documentLibrary.modal.full.content.bottom')}
          </Text>
        </Text>
        <Flex gridGap={10} paddingTop={50}>
          <Link to="/profile/subscriptionDetail">
            <Button size={'lg'} variant={'primary'}>
              {t('buttons.upgradePlan')}
            </Button>
          </Link>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

const NotEnoughQuotaModal = ({ showModal, setShowModal }: Omit<ModalProps, 'remain'>) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalWrapper>
        <Text color={theme.colors.danger} fontWeight={700}>
          {t('pages.documentLibrary.modal.notEnoughQuota.title')}
        </Text>
        <Text marginTop={50} textAlign={'center'}>
          {t('pages.documentLibrary.modal.notEnoughQuota.content')}
        </Text>
        <Flex gridGap={10} paddingTop={50}>
          <Link to="/profile/subscriptionDetail">
            <Button size={'lg'} variant={'primary'}>
              {t('buttons.upgradePlan')}
            </Button>
          </Link>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

const NeedUpgradePlanModal = ({ showModal, setShowModal }: Omit<ModalProps, 'remain'>) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalWrapper>
        <Text color={theme.colors.danger} fontWeight={700}>
          {t('pages.documentLibrary.modal.needUpgradePlan.title')}
        </Text>
        <Text marginTop={50} textAlign={'center'}>
          {t('pages.documentLibrary.modal.needUpgradePlan.content')}
        </Text>
        <Flex gridGap={10} paddingTop={50}>
          <Link to="/profile/subscriptionDetail">
            <Button size={'lg'} variant={'primary'}>
              {t('buttons.upgradePlan')}
            </Button>
          </Link>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

export { StorageFullModal, StorageAlmostFullModal, NotEnoughQuotaModal, NeedUpgradePlanModal };

import type { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useDealValidation = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const step1 = () => {
    const dealTypeRegister: RegisterOptions = {
      required: t('required.dealType'),
    };

    const dealNameRegister: RegisterOptions = {
      required: t('required.dealName'),
      pattern: {
        // 不接受 / \ : < > 等特殊字元
        value: /^[^/\\:<>]*$/,
        message: t('pattern.dealName'),
      }
    };

    const productTypeRegister: RegisterOptions = {
      required: t('required.productType'),
    };

    const descriptionRegister: RegisterOptions = {
      required: t('required.description'),
    };

    const expiryDateRegister: RegisterOptions = {
      required: t('required.expiryDate'),
    };

    return {
      dealTypeRegister,
      dealNameRegister,
      productTypeRegister,
      descriptionRegister,
      expiryDateRegister,
    };
  };

  const step2 = () => {
    const countryRegister: RegisterOptions = {
      required: t('required.country'),
    };

    const timezoneRegister: RegisterOptions = {
      required: t('required.timezone'),
    };

    const languagesRegister: RegisterOptions = {
      required: t('required.languages'),
    };

    const currencyRegister: RegisterOptions = {
      required: t('required.currency'),
    };

    return {
      countryRegister,
      timezoneRegister,
      languagesRegister,
      currencyRegister,
    };
  };

  const step5 = () => {
    const titleRegister: RegisterOptions = {
      required: t('required.title'),
    };

    const descriptionRegister: RegisterOptions = {
      required: t('required.description'),
    };

    return {
      titleRegister,
      descriptionRegister,
    };
  };

  return {
    step1,
    step2,
    step5,
  };
};

export default useDealValidation;

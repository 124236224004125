import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1325px;
  margin: 0 auto;
  margin-bottom: 100px;
  background-color: rgba(109, 131, 196, 0.06);
`;

const StepsWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  background: linear-gradient(90deg, #0f319d 0%, #4ebbc6 100%);
  border-radius: 60px 60px 60px 0px;
  padding: ${(p) => (p.isMobile ? '50px 0' : p.isTablet ? '80px 50px' : '80px 0')};
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.lg : p.theme.fontSizes.xl)};
  font-weight: 700;
  line-height: 70.31px;
  text-align: center;
  color: ${(p) => p.theme.colors.light};
  margin-bottom: 40px;
`;

const SubTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: ${(p) => p.theme.colors.light};
  margin-bottom: 80px;
`;

const StepList = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > div:nth-child(2) {
    border-left: 1px solid ${(p) => p.theme.colors.light};
    border-right: 1px solid ${(p) => p.theme.colors.light};
  }

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    > div:nth-child(2) {
      border-left: none;
      border-right: none;
    }
  }
`;

const StepCard = styled.div`
  width: 425px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;

  @media (max-width: 1300px) {
    width: 75%;
    padding: 25px 0;
  }
`;

const CardTitle = styled.div`
  position: relative;
  height: 117px;
  display: flex;
  align-items: center;

  > span {
    position: relative;
    font-family: 'Roboto';
    font-style: italic;
    font-size: 100px;
    font-weight: 900;
    line-height: 1;
    color: ${(p) => p.theme.colors.light};
    margin-bottom: 24px;
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 15px;
    background-color: ${(p) => p.theme.colors.secondary400};
    bottom: 22px;
    right: 0;
    z-index: 0;
    transform: translate(30%, -50%);
  }
`;

const CardLink = styled.div`
  font-family: 'Roboto';
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  line-height: 23.44px;
  text-align: center;
  color: ${(p) => p.theme.colors.secondary400};
  margin-bottom: 20px;
  text-decoration: underline;
`;

const CardSubTitle = styled.div`
  font-family: 'Roboto';
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  line-height: 23.44px;
  text-align: center;
  color: ${(p) => p.theme.colors.secondary400};
  margin-bottom: 20px;
`;

const CardText = styled.div`
  font-family: 'Roboto';
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: ${(p) => p.theme.colors.light};
  opacity: 0.8;
`;

export {
  ContainerWrapper,
  StepsWrapper,
  Title,
  SubTitle,
  StepList,
  StepCard,
  CardTitle,
  CardLink,
  CardSubTitle,
  CardText,
};

import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得列表
 */
export const getLEIOptionList = async () => {
  return axios.get('/api/LEI/getOptionList');
};

/**
 * ANCHOR 建立LEI
 */
export const createLEI = async (data: LEIformRequest) => {
  return axios.post('/api/LEI', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * ANCHOR 取得LEI
 */
export const getLEI = async () => {
  return axios.get('/api/LEI');
};

/**
 * ANCHOR 更新LEI
 */
export const updateLEI = async (data: LEIformRequest) => {
  return axios.post('/api/LEI/update', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * ANCHOR 撤銷LEI
 */
export const revokeLEI = async (data: any) => {
  return axios.post('/api/LEI/revoke', data);
};

/**
 * ANCHOR 提交LEI
 */
export const submitLEI = async () => {
  return axios.post('/api/LEI/submit');
};
// LEI更新方式: 1.revoke 2.update 3.submit, Update並不會改變LEI的撤回狀態, 需要另外呼叫submit將LEI改為提交狀態

/**
 * ANCHOR 刪除LEI
 */
export const deleteLEI = async (data: any) => {
  return axios.post('/api/LEI/delete', data);
};

/**
 * ANCHOR 搜尋LEI紀錄
 */
export const getLEIRecordSearch = async ({ query, per_page, page }: LEISearchQuery) => {
  return axios.get(`/api/LEI/recordSearch?page=${page}&per_page=${per_page}&q=${query}`);
};

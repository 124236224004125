import { useEffect, useState, useCallback } from 'react';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { useAppSelector } from '@/redux/store.hook';
import { deleteNotification, getNotificationSelector } from '@/redux/notification/notification.slice';
import NotificationCard from '@/components/Notification/Cards';
import Tabs from '@/components/UI/Tabs';
import { Controller, useForm } from 'react-hook-form';
import { Box, Flex } from '@/layout';
import { CardWrapper, ContentWrapper, SearchBar, SearchIconWrapper, ModalForm, ModalTitle } from './style';
import { Modal, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close, Search } from '@/components/Icon';
import { theme } from '@/theme/Theme';
import { Text } from '@/components/UI/Typography/Text';
import { notificationDestroy } from '@/api/notifications.api';
import { convertNotification } from '@/utils/convert';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { errorHandler, successHandler } from '@/utils/toastHandler';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@/components/UI/Button';

const Notifications = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const tabLabel = t('pages.home.notifications.tabs', { returnObjects: true });
  const navigate = useNavigate();
  const initTabIndex = 1;
  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex);
  const [notification, setNotification] = useState<NotificationCards[]>();
  const notifications = useAppSelector(getNotificationSelector);
  const dispatch = useDispatch();
  const { notify } = notifications;
  const { isLogin } = useAppSelector(getAuthSelector);
  const [multiSwitch, setMultiSwitch] = useState<boolean>(false);
  const [selectedAry, setSelectedAry] = useState<number[]>([]);
  const [selectAllSwitch, setSelectAllSwitch] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { watch, handleSubmit, control } = useForm<{ key: string }>();

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
  });

  const watchKey = watch('key');

  const filterData = (searchParms: string) => {
    if (searchParms === '') {
      initData();
      return;
    }
    if (!notify || !notification) return;

    const filteredData = notification.filter((n) => {
      return (
        n.title.toLocaleLowerCase().includes(searchParms.toLocaleLowerCase()) ||
        n.desc.toLocaleLowerCase().includes(searchParms.toLocaleLowerCase())
      );
    });
    setNotification(filteredData);
  };

  useEffect(() => {
    if (!isLogin) {
      errorHandler(t('error.login'));
      navigate('/auth/signin');
    }
    filterData(watchKey);
  }, [watchKey]);

  const tabs: TabsType = [
    {
      label: `${tabLabel[0]} (${notification ? notification.length : 0})`,
      index: initTabIndex,
      Component: () =>
        notification && notification.length !== 0 ? (
          <Flex width={'100%'} flexDirection={'column'} gridGap={'15px'}>
            <NotificationCard
              data={notification}
              showSelect={multiSwitch}
              selectedAry={selectedAry}
              setSelectedAry={setSelectedAry}
            />
          </Flex>
        ) : (
          <ContentWrapper>
            <span>{t('pages.home.notifications.noData')}</span>
          </ContentWrapper>
        ),
    },
    {
      label: `${tabLabel[1]} (0)`,
      index: 2,
      Component: () => (
        <ContentWrapper>
          <span>{t('pages.home.notifications.noData')}</span>
        </ContentWrapper>
      ),
    },
    {
      label: `${tabLabel[2]} (0)`,
      index: 3,
      Component: () => (
        <ContentWrapper>
          <span>{t('pages.home.notifications.noData')}</span>
        </ContentWrapper>
      ),
    },
  ];

  const initData = useCallback(() => {
    if (!notify) return;
    setNotification(convertNotification(notify));
  }, [notify]);

  useEffect(() => {
    initData();
  }, [initData]);

  const selectAll = () => {
    setSelectAllSwitch(true);
    setSelectedAry(notify?.map((n) => n.id) || []);
  };

  const unSelectAll = () => {
    setSelectAllSwitch(false);
    setSelectedAry([]);
  };

  const deleteSelected = async () => {
    if (!notify) return;
    // delete all
    try {
      const rawData = {
        ids: selectAllSwitch ? notify.map((n) => n.id) : selectedAry,
      };

      const res = (await notificationDestroy(rawData)).data;
      if (res.success) {
        dispatch(deleteNotification(rawData.ids));
        successHandler(t('success.delete'));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSelectAllSwitch(false);
      setSelectedAry([]);
      setShowModal(false);
    }
  };

  return (
    <PageContainer title={<InnerPageTitle link={'/home'} subtitle={t('notifications')} />}>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
      >
        <ModalForm onSubmit={onSubmit}>
          <Close.ButtonClose
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Close.CloseSVG />
          </Close.ButtonClose>
          <ModalTitle>{t('pages.home.notifications.modal.title')}</ModalTitle>

          <Box style={{ marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={deleteSelected} size={'lg'} variant={'primary'} rounded={false} marginRight={40}>
              {t('buttons.ok')}
            </Button>
            <Button
              size={'lg'}
              variant={'primary-invert'}
              color="primary"
              onClick={() => setShowModal(false)}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.cancel')}
            </Button>
          </Box>
        </ModalForm>
      </Modal>
      <CardWrapper>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <SearchBar onSubmit={onSubmit}>
            <Controller
              control={control}
              defaultValue=""
              name={'key'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div style={{ width: '100%' }}>
                  <TextField
                    placeholder=""
                    variant="outlined"
                    value={value}
                    sx={{ width: '100%', background: '#FFF', borderRadius: '5px 0 0 5px' }}
                    onChange={onChange}
                    size="small"
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                  <SearchIconWrapper>
                    <img width={32} src={Search.SearchSVG_B} alt="searchSVG" />
                  </SearchIconWrapper>
                </div>
              )}
            />
          </SearchBar>
          {!multiSwitch && (
            <Text
              onClick={() => setMultiSwitch(true)}
              color={theme.colors.secondary500}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {t('buttons.selectMultiple')}
            </Text>
          )}
          {multiSwitch && (
            <Flex gridGap={20}>
              {!selectAllSwitch ? (
                <Text
                  onClick={selectAll}
                  color={theme.colors.secondary500}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {t('buttons.selectAll')}
                </Text>
              ) : (
                <Text
                  onClick={unSelectAll}
                  color={theme.colors.secondary500}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {t('buttons.unSelectAll')}
                </Text>
              )}

              <Text
                onClick={() => setShowModal(true)}
                color={theme.colors.secondary500}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {t('buttons.deleteSelected')}
              </Text>
              <Text
                onClick={() => setMultiSwitch(false)}
                color={theme.colors.secondary500}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {t('buttons.cancel')}
              </Text>
            </Flex>
          )}
        </Flex>

        <Tabs tabs={tabs} selectedTab={selectedTab} onClick={setSelectedTab} />
      </CardWrapper>
    </PageContainer>
  );
};

export default Notifications;

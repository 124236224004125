import styled from 'styled-components';

const ImageDefault = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  background-color: ${(p) => p.theme.colors.gray200};
  cursor: pointer;
  &::before {
    content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
    width: 28px;
    height: 5px;
    background-color: ${(p) => p.theme.colors.gray400};
  }
	&::after {
    content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
    width: 5px;
    height: 28px;
    background-color: ${(p) => p.theme.colors.gray400};
  }
`;

export default ImageDefault;

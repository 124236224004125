import { useSearchParams } from 'react-router-dom';
import { ArrowRightSVG } from '@/components/Icon';
import { Button } from '@/components/UI/Button';
import { Box } from '@/layout';

interface TabsButtonProps {
  selectedTab?: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

const TabsButton = ({ selectedTab = 1, setSelectedTab }: TabsButtonProps) => {
  const [searchParmas, setSearchParams] = useSearchParams();

  const backHandler = () => {
    setSelectedTab(selectedTab - 1);
    setSearchParams({ step: '2', tab: (selectedTab - 1).toString() });
  };

  return (
    <Box style={{ paddingTop: 22 }}>
      <Button
        size={'md'}
        type="button"
        variant="primary"
        rounded={false}
        disabled={selectedTab === 1}
        marginRight={40}
        onClick={backHandler}
      >
        {'<'}
      </Button>
      <Button size={'md'} type="submit" variant="primary" marginRight={40}>
        {'>'}
      </Button>
    </Box>
  );
};

export default TabsButton;

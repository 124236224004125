import { useEffect } from 'react';
import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form';

interface FormSelectProps {
  control: Control<any>;
  data: { value: string | number; text: string | number }[];
  inputLabel: string;
  selectLabel: string;
  name: string;
  defaultValue?: string;
  register?: RegisterOptions;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  readOnly?: boolean;
  disabled?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormSelect = ({
  control,
  data,
  inputLabel,
  selectLabel,
  register,
  name,
  defaultValue = '',
  variant = 'standard',
  size = 'medium',
  readOnly = false,
  disabled = false,
}: FormSelectProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl disabled={disabled} fullWidth variant={variant} size={size}>
          <InputLabel error={!!error}>{inputLabel}</InputLabel>
          <Select
            label={selectLabel}
            onChange={onChange}
            value={value}
            inputProps={{ readOnly: readOnly }}
            MenuProps={MenuProps}
          >
            {data.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.text}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
      name={name}
      rules={register}
    />
  );
};

export default FormSelect;

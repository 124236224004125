import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1325px;
  margin: 0 auto;
  padding: 80px 0;
  position: relative;
`;

const StartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 68px;
  align-items: center;
  position: relative;
`;

const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  line-height: 70px;
  color: ${(p) => p.theme.colors.gray500};
`;

const CardList = styled.div`
  display: flex;
  gap: 19px;
`;

const Card = styled.div`
  width: 429px;
  height: 525px;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.light};
`;

const CardHero = styled.img`
  width: 100%;
  height: 282px;
  object-fit: cover;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 0px;
`;

const CardTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 15px;
`;

const CardText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 24px;
  color: ${(p) => p.theme.colors.gray400};

  >ul {
    list-style-type: disc;
    padding-left: 20px;
  }
`;

const CardButton = styled.button`
  width: 170px;
  height: 50px;
  margin-top: auto;
  margin-left: auto;
  background-color: ${(p) => p.theme.colors.primary500};
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 11px;
  color: ${(p) => p.theme.colors.light};
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  line-height: 23px;

  :hover {
    ::after {
      margin-left: 9px;
      margin-right: 0px;
    }
  }

  ::after {
    content: '';
    background-image: url('/images/index/right-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 32px;
    height: 32px;
    margin-left: 0px;
    margin-right: 9px;
    transition: 0.3s;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  padding: 151px 147px 230px 793px;
  position: relative;

  > img {
    position: absolute;
    left: -113px;
    top: 0;
    width: 836px;
    height: 500px;
  }
`;

const ChooseImage = styled.img`
  position: absolute;
  left: -113px;
  top: 0;
  width: 836px;
  height: 500px;
`;

const DescriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DescriptionText = styled.div`
  fnont-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 30px;
  color: ${(p) => p.theme.colors.gray500};
`;

export {
    ContainerWrapper,
    StartWrapper,
    Title,
    CardList,
    Card,
    CardHero,
    CardInfo,
    CardTitle,
    CardText,
    CardButton,
    DescriptionWrapper,
    ChooseImage,
    DescriptionInfo,
    DescriptionText
}
const categoryEn = [
  'Legal Services',
  'Film and television industry',
  'Construction',
  'Financing and Insurance',
  'Information Technology',
  'Manufacturing',
  'Professional and Business Services',
];

export default categoryEn;

import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得eCert狀態
 */
export const getUserCertOrder = async () => {
  return axios.get('/api/hkPostECert/getUserCertOrder');
};

/**
 * ANCHOR 發送eCert請求
 */
export const casCreateCertApplyOrder = async (data: ECertAllplicationRequest) => {

  return axios.post('/api/hkPostECert/casCreateCertApplyOrder', data);
};

/**
 * ANCHOR 查詢eCert
 */
export const casQueryCertApplyOrder = async (data: { orderId: string }) => {
  return axios.post('/api/hkPostECert/casQueryCertApplyOrder', data);
};

/**
 * ANCHOR 取得eCert所有的申請資料
 */
export const getHKCertApplys = async () => {
  return axios.get<{ success: Boolean; data: ECertApplys[] }>('/api/hkPostECert/getHKCertApplys');
};

/**
 * ANCHOR 送出指定id的eCert申請
 */
export const sendCertApplyOrder = async (data: { applyId: string }) => {
  return axios.post('/api/hkPostECert/sendCertApplyOrder', data);
};

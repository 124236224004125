import { useState, useEffect } from 'react';

import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, renewUserData } from '@/redux/auth/auth.slice';
import { getUserDetail } from '@/api/auth.api';
import { useDispatch } from 'react-redux';

interface useUserDetailProps {
  activeStep?: number;
}

const useUserDetail = ({ activeStep }: useUserDetailProps) => {
  const [userDetail, setUserDetail] = useState<MemberProfile>();
  const auth = useAppSelector(getAuthSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.accessToken === '') return;
    const getData = async () => {
      try {
        const res = (await getUserDetail()).data.data;
        dispatch(renewUserData(res));

        if (res.main_account_company_info) {
          if (res.main_account_company_info.isMainAccount) {
            setUserDetail(res);
          } else {
            const { mainAccountData } = res.main_account_company_info;
            if (typeof mainAccountData === 'string') return;
            const companyInfo = {
              acc_coname: mainAccountData.acc_coname,
              acc_logo: mainAccountData.acc_logo,
              acc_address: mainAccountData.acc_address,
              acc_desc: mainAccountData.acc_desc,
              com_email: mainAccountData.com_email,
              acc_tel: mainAccountData.acc_tel,
              acc_fax: mainAccountData.acc_fax,
              acc_website: mainAccountData.acc_website,
              acc_category: mainAccountData.acc_category,
              acc_languages: mainAccountData.acc_languages,
              acc_currency: mainAccountData.acc_currency,
              acc_countryCode: mainAccountData.acc_countryCode,
              acc_category_other: mainAccountData.acc_category_other,
              acc_languages_other: mainAccountData.acc_languages_other,
              acc_currency_other: mainAccountData.acc_currency_other,
            };
            setUserDetail({ ...res, ...companyInfo });
          }
        } else {
          setUserDetail(res);
        }
      } catch (error) {}
    };
    getData();
  }, [activeStep]);

  return userDetail;
};

export default useUserDetail;

import { useCallback, useEffect, useState } from 'react';
import { getUserRating, getSpecifyUserRating } from '@/api/rating.api';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { Flex } from '@/layout';
import { CardWrapper, SearchBar, SearchIconWrapper } from './style';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Search } from '@/components/Icon';
import Loading from '@/components/UI/Loading';
import Comments from '@/components/Rating/Comments';

interface RatingProps {
  user_id: number;
}

const Rating = ({ user_id }: RatingProps) => {
  const [data, setData] = useState<CommentResponse[]>();
  const [rawData, setRawData] = useState<CommentResponse[]>();
  const [loading, setLoading] = useState(false);
  const { watch, handleSubmit, control } = useForm<{ key: string }>();

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
  });

  const watchKey = watch('key');

  const filterData = (searchParms: string) => {
    if (!rawData) return;
    if (!searchParms) {
      setData(rawData);
      return;
    }

    const newData = rawData.filter((item) => item.deal_name.includes(searchParms));
    setData(newData);
  };

  const initData = useCallback(async () => {
    if (!user_id) return;
    setLoading(true);
    try {
      const res = (await getSpecifyUserRating(user_id)).data;
      if (res.success) {
        setData(res.data);
        setRawData(res.data);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    filterData(watchKey);
  }, [watchKey]);

  return (
    <CardWrapper>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <SearchBar onSubmit={onSubmit}>
          <Controller
            control={control}
            defaultValue=""
            name={'key'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div style={{ width: '100%' }}>
                <TextField
                  placeholder=""
                  variant="outlined"
                  value={value}
                  sx={{ width: '100%', background: '#FFF', borderRadius: '5px 0 0 5px' }}
                  onChange={onChange}
                  size="small"
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
                <SearchIconWrapper>
                  <img width={32} src={Search.SearchSVG_B} alt="searchSVG" />
                </SearchIconWrapper>
              </div>
            )}
          />
        </SearchBar>
      </Flex>
      {!loading && data && <Comments data={data} />}
      {loading && <Loading />}
    </CardWrapper>
  );
};

export default Rating;

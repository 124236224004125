import styled from 'styled-components';
import { BoxProps, box, Box } from '@/layout/Box';

const ModalWrapper = styled(Box)<BoxProps>`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 630px;
  align-items: center;
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px 15px 15px 0px;
  position: relative;
  top: 33%;
  ${box};
`;

export { ModalWrapper };

import { useState, useEffect, useCallback } from 'react';
import PageContainer from '@/components/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import SupplementalInfo from '@/components/Account/SupplementalInfo&LEI';
import { Box, Button as DefaultBtn } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../style';
import useUserDetail from '@/hooks/useUserDetail';
import AdSection from '@/pages/Registration/AdScetion';

const SupplementalInfoEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(2);
  const userDetail = useUserDetail({ activeStep: activeStep });

  const nextHandler = () => {
    navigate('/profile/edit');
  };

  const ButtonGroup = (watch: Object) => (
    <Box style={{ marginTop: 30 }}>
      <Button>{t('buttons.submit')}</Button>
      <DefaultBtn
        type="button"
        color="primary"
        onClick={() => navigate('/profile/edit')}
        style={{ fontSize: 20, color: '#0B319D', padding: '1px 40px', border: '1px solid #0B319D', marginLeft: 15 }}
      >
        {t('buttons.back')}
      </DefaultBtn>
    </Box>
  );

  return (
    <PageContainer title={t('supplementalInformation')}>
      <div style={{ display: 'flex' }}>
        <SupplementalInfo nextHandler={nextHandler} ButtonGroup={ButtonGroup} userDetail={userDetail} />
        {/* <AdSection /> */}
      </div>
    </PageContainer>
  );
};

export default SupplementalInfoEdit;

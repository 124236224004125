import { AddSVG } from './Add';
import { ArrowRightSVG } from './ArrowRight';
import { CancelSVG, CheckSVG, FormCheck } from './Check';
import { CompanySVG } from './Company';
import { ContractSVG } from './Contract';
import { CopySVG } from './Copy';
import { CloudUploadSVG, FormCloudUpload } from './CloudUpload';
import { MeetingSVG } from './Meeting';
import { SearchButton, SearchSVG, SearchSVG_G, SearchSVG_B } from './Search';
import { FormEdit, EditSVG } from './Edit';
import { FormDelete, DeleteSVG } from './Delete';
import { RemoveSVG } from './Remove';
import { PersonSVG } from './Person';
import { SignSVG } from './Sign';
import { LikeSVG } from './Like';
import { DownloadSVG, FormDownload } from './Download';
import { CircleSVG } from './Circle';
import { CloseSVG, ButtonClose } from './Close';
import { ConferencingSVG } from './Conferencing';
import { CartSVG } from './Cart';
import { VisibleSVG, VisibleOffSVG } from './Visible';
import { FileCheckSVG } from './FileCheck';
import { ErrorSVG } from './Error';
import { GradeSVG, GradeFillSVG } from './Grade';
import { ChatSVG, ChatWhiteSVG } from './Chat';
import { SendSVG } from './Send';
import { VCSVG } from './VideoConferencing';
import { PageNextSVG, PagePrevSVG } from './Pagination';
import { RotateRightSVG, RotateLeftSVG } from './RotateArrow';
import { SubSVG } from './Subscription';
import { MembersSVG } from './Members';
import { DealsSVG } from './Deals';
import { DocumentsSVG } from './Documents';
import { UserSVG } from './User';
import { BurgerSVG } from './Burger';
import { TranslateSVG } from './Translate';
import { MailSVG } from './Mail';
import { PhoneSVG } from './Phone';
import { PinSVG } from './Pin';

const Mail = {
  MailSVG,
};

const Phone = {
  PhoneSVG,
};

const Pin = {
  PinSVG,
};

const Translate = {
  TranslateSVG,
};

const Burger = {
  BurgerSVG,
};

const User = {
  UserSVG,
};

const Documents = {
  DocumentsSVG,
};

const Members = {
  MembersSVG,
};

const Sub = {
  SubSVG,
};

const Deals = {
  DealsSVG,
};

const RotateArrow = {
  RotateRightSVG,
  RotateLeftSVG,
};

const Page = {
  PageNextSVG,
  PagePrevSVG,
};

const VC = {
  VCSVG,
};

const Send = {
  SendSVG,
};

const Chat = {
  ChatSVG,
  ChatWhiteSVG,
};

const Grade = {
  GradeSVG,
  GradeFillSVG,
};

const Error = {
  ErrorSVG,
};

const FileCheck = {
  FileCheckSVG,
};

const Visible = {
  VisibleSVG,
  VisibleOffSVG,
};

const Cart = { CartSVG };

const Conferencing = { ConferencingSVG };

const Search = { SearchButton, SearchSVG, SearchSVG_G, SearchSVG_B };

const Close = {
  CloseSVG,
  ButtonClose,
};

const Edit = {
  FormEdit,
  EditSVG,
};

const Circle = {
  CircleSVG,
};

const Delete = { FormDelete, DeleteSVG };

const Add = { AddSVG };

const Remove = {
  RemoveSVG,
};

const Check = {
  CancelSVG,
  CheckSVG,
  FormCheck,
};

const Company = {
  CompanySVG,
};

const Contract = {
  ContractSVG,
};

const CloudUpload = {
  CloudUploadSVG,
  FormCloudUpload,
};

const Copy = {
  CopySVG,
};

const Download = {
  DownloadSVG,
  FormDownload,
};

const Person = {
  PersonSVG,
};

const Meeting = {
  MeetingSVG,
};

const Sign = {
  SignSVG,
};

const Like = {
  LikeSVG,
};

export {
  ArrowRightSVG,
  Check,
  Company,
  Copy,
  CloudUpload,
  Search,
  Edit,
  Delete,
  Add,
  Remove,
  Contract,
  Person,
  Meeting,
  Sign,
  Like,
  Download,
  Circle,
  Close,
  Conferencing,
  Cart,
  Visible,
  FileCheck,
  Error,
  Grade,
  Chat,
  Send,
  VC,
  Page,
  RotateArrow,
  Sub,
  Members,
  Deals,
  Documents,
  User,
  Burger,
  Translate,
  Mail,
  Phone,
  Pin,
};

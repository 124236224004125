import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../../styles';
import { Box, Flex, LoadingPage } from '@/layout';
import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { applySelfCreditReport } from '@/api/creditReport.api';
import { Button } from '@/components/UI/Button';
import { H5 } from '@/components/UI/Typography/Heading';
import { ConfirmModal, ErrorModal, PurchaseModal, RejectModal, TermsModal } from '../Modal';
import usePermissionCheck from '@/hooks/usePermissionCheck';
import { errorHandler } from '@/utils/toastHandler';
import { theme } from '@/theme/Theme';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useUserDetail from '@/hooks/useUserDetail';

interface FormStepperProps {
  nextHandler: () => void;
  prevHandler: () => void;
  setData: React.Dispatch<React.SetStateAction<CreditReportResponse | undefined>>;
}

const OwnCompany = ({ nextHandler, prevHandler, setData }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [agree, setAgree] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const { userData } = useAppSelector(getAuthSelector);
  const [count, setCount] = useState<number>(0);

  const { isMainAccount } = usePermissionCheck();

  const handleAgree = () => {
    setAgree(!agree);
  };

  const { setValue, handleSubmit, control } =
    useForm<Omit<CreditReportApplicationForm, 'companyName, queryType, companyId'>>();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    setShowConfirmModal(false);
    setShowPurchaseModal(false);
    setShowTermsModal(false);

    try {
      const res = (await applySelfCreditReport(data)).data;

      if (res.status) {
        setData(res.report);
        nextHandler();
      }
    } catch (error) {
      console.log(error);
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  });

  const modalHanlder = () => {
    if (!isMainAccount) {
      errorHandler(t('error.permission'));
      return;
    }

    if (userData.acc_country !== 'China') {
      setShowRejectModal(true);
      return;
    } else if (count === 0) {
      setShowPurchaseModal(true);
      return;
    } else {
      setShowTermsModal(true);
      return;
    }
  };

  useEffect(() => {
    if (!userData.acc_coname) return;
    setValue('name', userData.acc_coname);
    if (!isMainAccount) {
      errorHandler(t('error.permission'));
      prevHandler();
      return;
    }
    setCount(userData.user_feature.ESignatureDigitalCertTimes);
  }, [t]);

  const modalSwitch = () => {
    setShowTermsModal(false);
    setShowConfirmModal(true);
  };

  return (
    <form onSubmit={onSubmit}>
      <ConfirmModal count={count} onSubmit={onSubmit} showModal={showConfirmModal} setShowModal={setShowConfirmModal} />
      <RejectModal showModal={showRejectModal} setShowModal={setShowRejectModal} />
      <PurchaseModal showModal={showPurchaseModal} setShowModal={setShowPurchaseModal} />
      <ErrorModal showModal={showErrorModal} setShowModal={setShowErrorModal} />
      <TermsModal onSubmit={modalSwitch} showModal={showTermsModal} setShowModal={setShowTermsModal} />
      <LoadingPage isLoading={isLoading} />
      <Box>
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'name'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
            rules={{ required: t('required.input', { input: t('form.companyName.label') }) }}
          />
        </InputWrapper>

        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: '#999999',
                '&.Mui-checked': {
                  color: '#999999',
                },
              }}
              checked={agree}
              onChange={handleAgree}
            />
          }
          label={t('form.agreePlatformUseInfo.label')}
        />
      </Box>

      <Box marginTop={30}>
        <div>{t('pages.eTrust.creditReport.steps.step1.useCR')}</div>
        <H5 marginTop={15} color={theme.colors.primary500}>
          {t('pages.eTrust.creditReport.steps.step1.remainCR', {
            count,
          })}
        </H5>
      </Box>

      <Flex marginTop={50}>
        <Button
          size={'lg'}
          type="button"
          variant={'primary'}
          rounded={false}
          marginRight={40}
          disabled={!agree}
          onClick={modalHanlder}
        >
          {t('buttons.submit')}
        </Button>
        <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
          {t('buttons.back')}
        </Button>
      </Flex>
    </form>
  );
};

export default OwnCompany;

import { useEffect } from 'react';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

const usePermissionCheck = () => {
  const { userData } = useAppSelector(getAuthSelector);

  useEffect(() => {}, [userData]);

  return { isMainAccount: userData?.main_account_company_info?.isMainAccount };
};

export default usePermissionCheck;

import styled from 'styled-components';
import { BoxProps, box, Box } from '@/layout/Box';

const ModalWrapper = styled(Box)<BoxProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 98;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 500px;
  align-items: center;
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px 15px 15px 0px;
  ${box};
`;

export { ModalWrapper };

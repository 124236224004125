import { useState, useEffect } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { Box } from '@/layout';

interface StepLineProps {
  activeStep: number;
  stepline: string[];
  type: 'LEI' | 'CreditReport' | 'eCert' | 'LEISearch';
}

const Stepline = ({ activeStep, stepline, type }: StepLineProps) => {
  const [line, setLine] = useState(stepline);

  useEffect(() => setLine(stepline), [stepline]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      style={{ paddingRight: '20px', height: 'min-content', position: 'relative', minWidth: '200px' }}
    >
      {line.map((item: string) => (
        <Step key={item}>
          <StepLabel>{item}</StepLabel>
        </Step>
      ))}
      <Box display={'flex'} gridGap={20} flexDirection={'column'} position={'absolute'} bottom={-200}>
        {type === 'LEI' || type === 'LEISearch' ? (
          <img width="80" src="/images/eTrust/LEI.png" alt="" />
        ) : type === 'CreditReport' ? (
          <>
            {/* <img width="115" src="/images/eTrust/creditReport.png" alt="" />
            <img width="115" src="/images/eTrust/e-Cert-1.png" alt="" /> */}
          </>
        ) : (
          <>
            <img width="115" src="/images/eTrust/e-Cert-2.png" alt="" />
          </>
        )}
      </Box>
    </Stepper>
  );
};
export default Stepline;

import styled from 'styled-components';

const ModalForm = styled.form`
  position: absolute;
  top: 40%;
  left: calc(50% - 205px);
  width: 510px;
  background-color: #fff;
  padding: 25px;
  border-radius: 40px 40px 40px 0px;
`;

const ModalTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 15px;
`;

const ModalNotice = styled.div`
  font-size: ${(p) => p.theme.fontSizes.base};
  color: ${(p) => p.theme.colors.red};
  margin-bottom: 15px;
`;

export { ModalForm, ModalTitle, ModalNotice };

import { axios } from '@/utils/axios';

// api有問題暫時看不到資料, 待後端確認
/**
 * ANCHOR 取得列表
 */
export const getDealType = async () => {
  return axios.get<SelectData>('/api/dealType');
};

/**
 * ANCHOR 上傳
 */
export const storeDealType = async () => {
  return axios.post('/api/dealType');
};

/**
 * ANCHOR 更新
 */
export const updateDealType = async (id: number, data: []) => {
  return axios.put(`/api/dealType/${id}`, data);
};

/**
 * ANCHOR 刪除
 */
export const DestroyDealType = async (id: number) => {
  return axios.delete(`/api/dealType/${id}`);
};

/**
 * ANCHOR 取得檔案類型
 */
export const getDocTypeList = () => {
  return axios.get<{ data: string[] }>('/api/deal/execution/getDocTypeList');
};

/**
 * ANCHOR 取得多語系交易類型
 */
export const getI18nDealType = () => {
  return axios.get<{ data: MultiLangDealType }>('/api/dealType/i18n');
};

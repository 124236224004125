import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { TypographyProps, LayoutProps, SpaceProps, compose, typography, layout, space } from 'styled-system';

type LinkProps = RouterLinkProps & TypographyProps & LayoutProps & SpaceProps;

const styledLink = compose(typography, layout, space);

const createLink: React.FC<LinkProps> = ({ ...rest }) => {
  return <RouterLink {...rest} />;
};

const StyledLink = styled(createLink)`
  color: ${(p) => p.theme.colors.secondary500};
  text-decoration: underline;
  ${styledLink}
`;

export default StyledLink;

import { axios } from '@/utils/axios';

export const getFeatureList = () => {
  return axios.get('/api/feature');
};

export const getCartList = () => {
  return axios.get('/api/payment/cartList');
};

export const addToCart = (data: CartAPIData) => {
  return axios.post('/api/payment/addToCart', data);
};

export const removeFromCart = (data: CartAPIFeature) => {
  return axios.post('/api/payment/removeFromCart', data);
};

export const updateCart = (data: CartAPIData) => {
  return axios.post('/api/payment/updateCart', data);
};

export const cartClear = () => {
  return axios.post('/api/payment/clearCart');
};

export const checkout = () => {
  return axios.post('/api/payment/checkout');
};

export const getPlansList = () => {
  return axios.get('/api/plans');
};
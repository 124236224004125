import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import logo from './logo.jpg';
import useResize from '@/hooks/useResize';

const LogoWrapper = styled.div<{ isMobile: boolean; isTablet: boolean; registDone: boolean }>`
  img {
    height: ${(p) => (p.isMobile ? '26px' : p.isTablet ? '40px' : '70px')};
    position: ${(p) => (p.isTablet ? 'relative' : p.registDone ? 'absolute' : 'relative')};
    top: ${(p) => (p.isTablet ? '0' : p.registDone ? '30px' : '0')};
  }
`;

interface LogoProps {
  layoutController: boolean;
  registDone: boolean;
  setBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLogin: boolean;
}

const Logo = ({ isLogin, setUserOpen, setBurgerOpen, layoutController, registDone }: LogoProps) => {
  const { isTablet, isMobile } = useResize();
  const closeModalHanlder = () => {
    setUserOpen(false);
    setBurgerOpen(false);
  };

  return (
    <>
      {registDone ? (
        <RouterLink
          onClick={closeModalHanlder}
          to={isLogin ? '/home' : '/#'}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <LogoWrapper registDone={registDone} isMobile={isMobile} isTablet={isTablet}>
            <img src={logo} alt="logo" />
          </LogoWrapper>
        </RouterLink>
      ) : (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <LogoWrapper registDone={registDone} isMobile={isMobile} isTablet={isTablet}>
            <img src={logo} alt="logo" />
          </LogoWrapper>
        </div>
      )}
    </>
  );
};

export default Logo;

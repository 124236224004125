import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/UI/Button';
import { H2 } from '@/components/UI/Typography/Heading';
import { Box, Modal, Button as DefaultBtn } from '@mui/material';
import { TCTermsMarkdown, PICSTermsMarkdown } from '@/components/UI/Terms';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useResize from '@/hooks/useResize';
import { Flex } from '@/layout';
import PdfReader from '@/components/UI/PdfReader';
import { getPolicy } from '@/api/auth.api';

interface openState {
  infoCollect: boolean;
  terms: boolean;
}

interface ModalProps {
  open: openState;
  handleClose: (key: string) => void;
  handleChange: (key: string, current: boolean) => void;
}

const StyledH2 = styled(H2)<{ isMobile: boolean }>`
  font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.md : p.theme.fontSizes.lg)};
`;

export default function TermsModal({ open, handleClose, handleChange }: ModalProps) {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile } = useResize();
  const [personalInfo, setPersonalInfo] = useState(true);
  const [termsInfo, setTermsInfo] = useState(true);
  const [PICPdfUrl, setPICPdfUrl] = useState<string>('/files/PICSforDeal-makingPortal.pdf');
  const [TermsPdfUrl, setTermsPdfUrl] = useState<string>('/files/TermsAndConditionsOfServicesForDealMakingPortal.pdf');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const personalHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (isLoading) return;
    const { currentTarget } = e;

    if (currentTarget.scrollHeight - currentTarget.scrollTop <= currentTarget.clientHeight + 2) {
      setPersonalInfo(false);
    }
  };

  const termHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (isLoading) return;
    const { currentTarget } = e;

    if (currentTarget.scrollHeight - currentTarget.scrollTop <= currentTarget.clientHeight + 2) {
      setTermsInfo(false);
    }
  };

  const closeModal = (type: string, status: boolean) => {
    setIsLoading(true);
    handleChange(type, status);
  };

  const [TC, setTC] = useState('');
  const [PICS, setPICS] = useState('');

  const initData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = (await getPolicy()).data;
      if (res.success) {
        setPICPdfUrl(res.data[0].policy_file_url);
        setTermsPdfUrl(res.data[1].policy_file_url);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    initData();
    // fetch(TCTermsMarkdown)
    //   .then((res) => res.text())
    //   .then((md) => {
    //     setTC(md);
    //   });

    // fetch(PICSTermsMarkdown)
    //   .then((res) => res.text())
    //   .then((md) => {
    //     setPICS(md);
    //   });
  }, [initData]);

  return (
    <>
      <Modal
        open={open.infoCollect}
        onClose={() => handleClose('infoCollect')}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
        sx={{ display: 'flex' }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            maxWidth: '930px',
            backgroundColor: '#fff',
            padding: isMobile ? '15px 25px' : '30px 50px',
            borderRadius: '40px 40px 40px 0px',
          }}
        >
          <StyledH2 isMobile={isMobile}>{t('pages.auth.signUp.modal.terms.pic.title')}</StyledH2>
          <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
            {t('pages.auth.signUp.modal.terms.pic.content')}
          </span>
          <div
            className="content"
            style={{
              marginTop: '25px',
              height: '40vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: isMobile ? '16px' : '20px',
              color: '#616161',
              lineHeight: '30px',
            }}
            onScroll={personalHandler}
          >
            <PdfReader pdfUrl={PICPdfUrl} setIsLoading={setIsLoading} />
            {/* <ReactMarkdown children={PICS} remarkPlugins={[remarkGfm]} /> */}
          </div>
          <Flex
            marginTop={'28px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexDirection={isMobile ? 'column-reverse' : 'row'}
          >
            <Flex gridGap={isMobile ? 20 : 40} alignItems={'center'} flexDirection={isMobile ? 'column' : 'row'}>
              <Button
                width={isMobile ? '100%' : 'unset'}
                onClick={() => closeModal('infoCollect', personalInfo)}
                size={'lg'}
                variant={'primary'}
                rounded={false}
                disabled={personalInfo}
              >
                {t('buttons.agree')}
              </Button>
              <Button
                width={isMobile ? '100%' : 'unset'}
                color="primary"
                onClick={() => handleClose('infoCollect')}
                style={{ textDecoration: 'underline' }}
              >
                {t('buttons.back')}
              </Button>
            </Flex>
            <a target="_blank" rel="noreferrer" href={PICPdfUrl} style={{ alignSelf: isMobile ? 'flex-end' : 'unset' }}>
              <Button
                width={isMobile ? '100%' : 'unset'}
                color="primary"
                type="button"
                style={{ textDecoration: 'underline' }}
              >
                {t('buttons.download')}
              </Button>
            </a>
          </Flex>
        </div>
      </Modal>

      <Modal
        open={open.terms}
        onClose={() => handleClose('terms')}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
        sx={{ display: 'flex' }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            maxWidth: '930px',
            backgroundColor: '#fff',
            padding: isMobile ? '15px 25px' : '30px 50px',
            borderRadius: '40px 40px 40px 0px',
          }}
        >
          <StyledH2 isMobile={isMobile}>{t('pages.auth.signUp.modal.terms.t&c.title')}</StyledH2>
          <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
            {t('pages.auth.signUp.modal.terms.t&c.title')}
          </span>
          <div
            className="content"
            style={{
              marginTop: '25px',
              height: '40vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: isMobile ? '16px' : '20px',
              color: '#616161',
              lineHeight: '30px',
            }}
            onScroll={termHandler}
          >
            {/* <ReactMarkdown children={TC} remarkPlugins={[remarkGfm]} /> */}
            <PdfReader pdfUrl={TermsPdfUrl} setIsLoading={setIsLoading} />
          </div>
          <Flex
            marginTop={'28px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexDirection={isMobile ? 'column-reverse' : 'row'}
          >
            <Flex gridGap={isMobile ? 20 : 40} alignItems={'center'} flexDirection={isMobile ? 'column' : 'row'}>
              <Button
                width={isMobile ? '100%' : 'unset'}
                onClick={() => closeModal('terms', termsInfo)}
                size={'lg'}
                variant={'primary'}
                rounded={false}
                disabled={termsInfo}
              >
                {t('buttons.agree')}
              </Button>
              <Button
                width={isMobile ? '100%' : 'unset'}
                color="primary"
                onClick={() => handleClose('terms')}
                style={{ textDecoration: 'underline' }}
              >
                {t('buttons.back')}
              </Button>
            </Flex>
            <a
              target="_blank"
              rel="noreferrer"
              href={TermsPdfUrl}
              style={{ alignSelf: isMobile ? 'flex-end' : 'unset' }}
            >
              <Button
                width={isMobile ? '100%' : 'unset'}
                color="primary"
                type="button"
                style={{ textDecoration: 'underline' }}
              >
                {t('buttons.download')}
              </Button>
            </a>
          </Flex>
        </div>
      </Modal>
    </>
  );
}

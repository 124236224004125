import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RegisterForm from '@/components/Account/RegisterForm';
import PageContainer from '@/components/Container/PageContainer';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { errorHandler } from '@/utils/toastHandler';
import { useTranslation } from 'react-i18next';

const BeconeMember = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [activeStep, setActiveStep] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step'));
  const navigate = useNavigate();
  // 取得使用者資訊
  const { isLogin, userData } = useAppSelector(getAuthSelector);

  useEffect(() => {
    if (isLogin || userData.acc_coname) {
      errorHandler(t('error.server'));
      navigate('/home')
    }
  }, []);

  return (
    <PageContainer title={'Create Account'}>
      <RegisterForm
        setActiveStep={setActiveStep}
        setTitle={setTitle}
        setCurrentStep={setCurrentStep}
        type={'subAccount'}
      />
    </PageContainer>
  );
};

export default BeconeMember;

import styled from 'styled-components';
import { ColorProps, TypographyProps, SpaceProps, color, compose, typography, space } from 'styled-system';

export type TextProps = ColorProps & TypographyProps & SpaceProps;

export const text = compose(color, typography, space);

const Text = styled('p')<TextProps>`
  font-size: ${(p) => p.theme.fontSizes.base};
  ${text}
`;

const UnderlineText = styled.div<TextProps>`
  text-decoration: underline;
  color: ${(p) => p.theme.colors.secondary400};
  cursor: pointer;
  ${text}
`;

const LinkText = styled.span<TextProps>`
  text-decoration: underline;
  color: ${(p) => p.theme.colors.primary500};
  cursor: pointer;
  ${text}
`;

const ErrorMessage = styled.p<TextProps>`
  font-size: ${(p) => p.theme.fontSizes.xs};
  margin-top: ${(p) => p.theme.space[1]};
  color: #d32f2f;
`;

const Ul = styled.ul<TextProps>`
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 30px;
  ${text}

  li {
    margin-bottom: 5px;
  }
`;

const Title = styled.div<TextProps>`
  color: ${(p) => p.theme.colors.primary400};
  font-weight: bold;
  ${text}
`;

export { LinkText, Text, UnderlineText, ErrorMessage, Ul, Title };

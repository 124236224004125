const gradeGenerator = (grade: number) => {
  if (!grade) grade = 0;
  grade = Math.round(grade);
  const star = [];
  for (let i = 1; i <= grade; i++) {
    star.push(`/images/yellowpage/star_fill.svg`);
  }

  for (let i = 1; i <= 5 - grade; i++) {
    star.push(`/images/yellowpage/star.svg`);
  }

  return star;
};

export default gradeGenerator;

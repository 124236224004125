import styled from 'styled-components';

const InfoCol = styled.div`
  display: flex;
  label {
    width: 35%;
    color: ${(p) => p.theme.colors.gray200};
  }
  span {
    width: 65%;
  }
`;

const Hr = styled.div`
  width: 1px;
  border-left: 1px solid ${(p) => p.theme.colors.gray100};
  height: 100%;
`

export { InfoCol, Hr };

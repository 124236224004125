import { Link } from 'react-router-dom';
import {
  ContainerWrapper,
  StepsWrapper,
  Title,
  SubTitle,
  StepList,
  StepCard,
  CardTitle,
  CardLink,
  CardSubTitle,
  CardText,
} from './style';
import { useTranslation } from 'react-i18next';
import useResize from '@/hooks/useResize';

const Steps = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();

  const steps = t('pages.index.Steps.step', { returnObjects: true });

  return (
    <ContainerWrapper>
      <StepsWrapper isMobile={isMobile} isTablet={isTablet}>
        <Title isMobile={isMobile}>{t('pages.index.Steps.title')}</Title>
        <StepList>
          {steps.map((step: any, index: number) => (
            <StepCard key={'step' + index}>
              <CardTitle>
                <span>{index + 1}</span>
              </CardTitle>
              {step.link !== '' ? (
                <Link to={step.link}>
                  <CardLink>{step.title}</CardLink>
                </Link>
              ) : (
                <CardSubTitle>{step.title}</CardSubTitle>
              )}
              <CardText>
                {step.contextTop}
                {step.contextBottom !== '' && (
                  <>
                    <br />
                    {step.contextBottom}
                  </>
                )}
              </CardText>
            </StepCard>
          ))}
        </StepList>
      </StepsWrapper>
    </ContainerWrapper>
  );
};

export default Steps;

import { useMemo, useCallback, useEffect, useState } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';

import { Flex } from '@/layout';
import { CardContainer } from '@/layout/Card';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import DataTable from '@/components/DataTable';
import { ShowBadge } from '@/components/DataTable/ShowBadge';

import { getInvitedParticipants } from '@/api/deal.api';
import { convertToRole, convertToBadgeLevel, convertLink } from '@/utils/convert';

interface ParticipantsProps {
  dealId: string | undefined;
}

const Participants = ({ dealId }: ParticipantsProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const rawColumn = t('pages.dealDetail.manageParticipants.participantsTable.columns', { returnObjects: true });

  const [invitedParticipantList, setInvitedParticipantList] = useState<DealListParticipantsTable[]>();

  const columns: ColumnDef<DealListParticipantsTable>[] = useMemo(
    () => [
      {
        accessorKey: 'photo',
        header: rawColumn[0],
        cell: ({ row }) => <Avatar src={row.original.photo} />,
      },
      {
        accessorKey: 'name',
        header: rawColumn[1],
        sortable: true,
        cell: ({ row }) => (
          <Flex alignItems={'center'} gridGap={'10px'}>
            {row.original.name}{' '}
            <Button special="fake" size="sm" variant="primary">
              {convertToRole(Number(row.original.role), 0)}
            </Button>
          </Flex>
        ),
      },
      {
        accessorKey: 'badge',
        header: rawColumn[2],
        cell: ({row}) => {
          return (
            <ShowBadge level={row.original.badge} />
          )
        }
      },
      {
        accessorKey: 'action',
        header: rawColumn[3],
      },
    ],
    [rawColumn]
  );

  const initData = useCallback(async () => {
    if (!dealId) return;
    try {
      const res = (await getInvitedParticipants(dealId)).data;
      const companyData = res.data.company.map((company) => ({
        role: String(company.deal_role_id),
        photo: company.user.acc_logo,
        name: company.user.acc_coname,
        badge: convertToBadgeLevel(company.user.verification),
        action: '',
      }));
      setInvitedParticipantList(companyData);
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <CardContainer>
      {invitedParticipantList ? <DataTable columns={columns} data={invitedParticipantList} canSearch /> : <Loading />}
    </CardContainer>
  );
};

export default Participants;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '@/utils/toastHandler';

import { Box, Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import DetailTitle from '@/components/DealDetail/Title';
import DealInfo from '@/components/DealDetail/Info';
import DealInvitation from '@/components/NotificationDetails/Invitation';
import DealRelation from '@/components/DealDetail/Relation';
import PageContainer from '@/components/Container/PageContainer';
import ProgressItem from '@/components/DealList/DealListItem/ProgressItem';
import Loading from '@/components/UI/Loading';

import { cancelDeal, getDealDetail } from '@/api/deal.api';
import { convertToRole, convertToBadgeLevel } from '@/utils/convert';

import { getEventList } from '@/api/event.api';
import data from '@/pages/OnlineEdit/data';
import { dealTagStatusHandler } from '@/utils/dealTagStatusHandler';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { DealCancelModal } from '@/components/DealDetail/Modal/DealStatus';

const NotificationDetails = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;
  const [dealOwner, setDealOwner] = useState<boolean>(false);

  const [dealRowData, setDealRowData] = useState<DealDetail>();
  const [dealData, setDealData] = useState<DealListItem>();
  const [participantsData, setParticipantsData] = useState<DealRelation[]>();
  const [filesData, setFilesData] = useState<DealRelation[]>();
  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [haveGuest, setHaveGuest] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const initData = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getDealDetail(id)).data;

      // 檢測步驟是否正確
      if (res.data.progress.toString() !== '0') {
        navigate(`/deal/${res.data.id}`, { replace: true });
      }

      const data: DealListItem = {
        id: res.data.id,
        tag: dealTagStatusHandler(parseInt(res.data.progress)),
        tradeName: res.data.name,
        expiry_date: res.data.expiry_date,
        count: {
          document: 0,
          participants: res.data.participants.length,
          events: 0,
        },
        participants: res.data.participants,
        dealInitiation: [res.data.progress === '0' ? false : true],
        negotiation: res.data.negotiation,
        deal_close: res.data.deal_close,
        contractExecution: [res.data.excution_file_complete],
        executions: res.data.executions,
        progress: res.data.progress,
        execution_file_complete: res.data.excution_file_complete,
        deal_type: res.data.deal_type.name,
        category: res.data.category,
        deal_type_id: Number(res.data.deal_type_id),
      };

      const participants: DealRelation[] = res.data.participants.map((data) => {
        if (data.deal_role_id === res.data.owner_role_id) {
          setDealOwner(data.acc_id === userData.id ? true : false);
        }

        if (data.user.is_none_DMP_user) {
          setHaveGuest(true);
        }

        return {
          id: data.acc_id,
          name: data.user.acc_lastName + data.user.acc_firstName,
          tag: convertToRole(data.deal_role_id, 0),
          level: convertToBadgeLevel(data.user.verification),
          joined: data.joined,
        };
      });
      const files: DealRelation[] = res.data.documents.map((data) => ({
        id: data.acc_id,
        name: data.file.name,
      }));
      setParticipantsData(participants);
      setFilesData(files);
      setDealData(data);
      setDealRowData(res.data);
    } catch (error: any) {
      errorHandler(t('error.dealInit'));
      navigate('/home');
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (!dealData) return;

    const rowData = {
      deal_id: dealData.id,
      cancel: 1,
    };
    try {
      const res = (await cancelDeal(rowData)).data;
      navigate('/deal');
    } catch (error) {
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [initData]);

  const isExpired = useMemo(() => {
    if (!dealData) return false;
    if (!dealData.expiry_date) return false;
    const date = new Date(dealData.expiry_date);
    const now = new Date();
    return date < now;
  }, [dealData]);

  return dealData ? (
    <PageContainer title={<DetailTitle data={dealData} />}>
      <LoadingPage isLoading={isLoading} />
      <DealCancelModal showModal={showModal} setShowModal={setShowModal} dealData={dealData} />
      <Flex gridGap={'10px'} width={'100%'} marginBottom={'15px'}>
        {dealOwner && !isExpired && (
          <Button
            onClick={() => setShowModal(true)}
            variant={'danger-invert'}
            style={{ border: '1px solid #DC1010', backgroundColor: '#fff' }}
          >
            {t('buttons.cancelDeal')}
          </Button>
        )}
      </Flex>
      <Flex width={'100%'} gridGap={'15px'}>
        <Flex maxWidth={1200} width={'100%'} flexWrap={'wrap'} gridGap={'15px'}>
          <Flex overflow={'hidden'} width={'100%'}>
            {dealRowData ? (
              <DealInfo haveGuest={haveGuest} dealDetail={dealRowData} dealOwner={dealOwner} />
            ) : (
              <Loading />
            )}
          </Flex>

          <Flex overflow={'hidden'} backgroundColor={'#fff'} width={'100%'}></Flex>
        </Flex>
      </Flex>
    </PageContainer>
  ) : (
    <Loading />
  );
};

export default NotificationDetails;

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import PageContainer from '@/components/Container/PageContainer';
import { getLEIRecordSearch } from '@/api/lei.api';
import { Box, Flex, LoadingPage } from '@/layout';
import { errorHandler } from '@/utils/toastHandler';
import { TextField } from '@mui/material';
import Stepline from '@/components/ETrust/Stepline';
import { SearchIconWrapper, StatusCircle, Wrapper } from './style';
import Loading from '@/components/UI/Loading';
import DataTable, { TypeColumn } from '@/components/DataTable';
import { ColumnDef } from '@tanstack/react-table';
import { H2 } from '@/components/UI/Typography/Heading';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { Search } from '@/components/Icon';
import { NeedUpgradePlanModal } from '@/components/DocLibrary/Modal';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

interface LEISearchForm {
  query: string;
}

const LEISearch = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<Date>();
  const stepline = t('pages.eTrust.LEI.search.stepline', { returnObjects: true });
  const tabLabel = t('pages.eTrust.LEI.search.columns', { returnObjects: true });
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const { userData } = useAppSelector(getAuthSelector);

  const [data, setData] = useState<LEIRecords>();

  const columns: ColumnDef<RecordsData>[] = useMemo(
    () => [
      {
        accessorKey: 'country',
        header: tabLabel[0],
        cell: ({ row }) => {
          return (
            <Flex gridGap={'5px'} alignItems={'center'}>
              <ReactCountryFlag countryCode={row.original.country} svg />
              <TypeColumn text={row.original.country} />
            </Flex>
          );
        },
      },
      {
        accessorKey: 'entityStatus',
        header: tabLabel[1],
        cell: ({ row }) => {
          return (
            <Flex gridGap={'5px'} alignItems={'center'}>
              <StatusCircle status={row.original.status} />
              <TypeColumn text={row.original.status} />
            </Flex>
          );
        },
      },
      {
        accessorKey: 'legalName',
        header: tabLabel[2],
        cell: ({ row }) => <TypeColumn text={row.original.name} />,
      },
      {
        accessorKey: 'lei',
        header: tabLabel[3],
      },
      {
        accessorKey: 'regStatus',
        header: tabLabel[4],
        cell: ({ row }) => {
          return (
            <Flex gridGap={'5px'} alignItems={'center'}>
              <StatusCircle status={row.original.reg_status} />
              <TypeColumn text={row.original.reg_status} />
            </Flex>
          );
        },
      },
    ],
    [t, tabLabel]
  );

  const { handleSubmit, control, setValue } = useForm<LEISearchForm>();

  const onSubmit = handleSubmit(async (formData) => {
    setIsLoading(true);
    try {
      if (formData.query === '') {
        setIsLoading(false);
        return;
      }
      getData(formData);
    } catch (e) {
      console.log(e);
      errorHandler(t('error.server'));
    }
  });

  const getData = useCallback(
    async (formData: LEISearchForm) => {
      try {
        const queryData = {
          query: formData.query,
          per_page: perPage < 10 ? 10 : perPage,
          page: page < 1 ? 1 : page,
        };

        const res = (await getLEIRecordSearch(queryData)).data;
        setData(res.data);
        setCurrentTime(new Date());
        setSearchParams({ query: formData.query, page: page.toString(), per_page: perPage.toString() });
      } catch (e) {
        console.log(e);
        errorHandler(t('error.server'));
      } finally {
        setIsLoading(false);
      }
    },
    [perPage, page, t]
  );

  useEffect(() => {
    if (!searchParams.get('query')) return;
    getData({
      query: searchParams.get('query') ?? '',
    });
  }, [perPage, page]);

  useEffect(() => {
    if (searchParams.get('query')) {
      setValue('query', searchParams.get('query')!);
      onSubmit();
    }
  }, []);

  return (
    <PageContainer title={t('LEIEntityIdentifierSearch')}>
      <NeedUpgradePlanModal showModal={showUpgradeModal} setShowModal={setShowUpgradeModal} />
      <div style={{ display: 'flex' }}>
        <Stepline activeStep={activeStep} stepline={stepline} type={'LEISearch'} />
        <Wrapper onSubmit={onSubmit}>
          <Box marginBottom={40}>
            <H2 fontWeight={'bold'}>{t('form.searchLEIRecords.label')}</H2>
            <Controller
              control={control}
              defaultValue=""
              name={'query'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div style={{ width: '100%', position: 'relative' }}>
                  <TextField
                    placeholder={t('form.searchLEIRecords.placeholder')}
                    variant="outlined"
                    value={value}
                    sx={{ width: '100%', background: '#FFF', borderRadius: '5px 0 0 5px' }}
                    onChange={onChange}
                    size="small"
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                  />
                  <SearchIconWrapper type="submit">
                    <img width={32} src={Search.SearchSVG_B} alt="searchSVG" />
                  </SearchIconWrapper>
                </div>
              )}
            />
          </Box>
          {data && (
            <Flex alignItems={'flex-end'} gridGap={10} paddingBottom={20}>
              <H2 fontWeight={'bold'}>{t('pages.eTrust.LEI.search.showResults', { number: data.total })}</H2>
              <Text lineHeight={theme.fontSizes.lg} color={theme.colors.gray300}>
                {t('pages.eTrust.LEI.search.time', { time: currentTime?.toLocaleString() })}
              </Text>
            </Flex>
          )}
          {!isLoading && data && (
            <DataTable
              page={page}
              perPage={perPage}
              total={data.total}
              setPage={setPage}
              setPerPage={setPerPage}
              columns={columns}
              data={data.data}
            />
          )}
          {isLoading && <Loading />}
        </Wrapper>
      </div>
    </PageContainer>
  );
};

export default LEISearch;

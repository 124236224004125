import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { PolicyWrapper } from './style';
import { LinkText, Text } from '../Typography/Text';
import useResize from '@/hooks/useResize';
import { getCookie, setCookie, deleteCookie } from '@/utils/cookie';

const CookiePolicy = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet } = useResize();

  // 檢查是否有cookie的同意
  const cookiePolicy = getCookie('cookiePolicy');
  const cookiePolicyRef = useRef<HTMLDivElement>(null);

  // 設定cookie的同意與否，並設定半年後過期
  const setCookiePolicy = (status: boolean) => {
    setCookie('cookiePolicy', status ? 'true' : 'false', 180); // 180 days ~ 6 months
    if (!cookiePolicyRef) return;
    cookiePolicyRef.current!.classList.remove('active');
    // 給cookiePolicyRef.current在1秒後加上hidden class
    setTimeout(() => {
      if (cookiePolicyRef.current) {
        cookiePolicyRef.current.classList.add('hidden');
      }
    }, 1000);
  };

  // 確認使用者是否有點擊同意，若有則隱藏cookie policy, 若無則顯示
  useEffect(() => {
    if (cookiePolicyRef.current && cookiePolicy === 'true') {
      cookiePolicyRef.current.style.display = 'none';
    } else {
      // 給cookiePolicyRef.current在loading完後的2秒後加上active class
      setTimeout(() => {
        if (cookiePolicyRef.current) {
          cookiePolicyRef.current.classList.add('active');
        }
      }, 1500);
    }
  }, [cookiePolicy]);

  // 測試用按紐取消cookie同意
  const clearCookie = () => {
    deleteCookie('cookiePolicy');
  };

  return (
    <>
      <PolicyWrapper ref={cookiePolicyRef}>
        <Flex
          justifyContent={'space-between'}
          flexDirection={isTablet ? 'column' : 'row'}
          gridGap={isTablet ? '20px' : '0'}
        >
          <Flex alignItems={'center'} gridGap={20}>
            <Text>{t('cookiePolicy.content')}</Text>
            <a target="_blank" rel="noreferrer" href="/files/PICSforDeal-makingPortal.pdf">
              <Text>
                <LinkText>{t('cookiePolicy.link')}</LinkText>
              </Text>
            </a>
          </Flex>
          <Flex gridGap={10} justifyContent={isTablet ? 'center' : 'flex-start'}>
            <Button
              onClick={() => {
                setCookiePolicy(true);
              }}
              size={'lg'}
              variant={'primary'}
            >
              {t('buttons.accept')}
            </Button>
            <Button
              onClick={() => {
                setCookiePolicy(false);
              }}
              size={'lg'}
              variant={'blackoutline'}
            >
              {t('buttons.decline')}
            </Button>
          </Flex>
        </Flex>
      </PolicyWrapper>
      {/* <button onClick={clearCookie}>Clear Cookie</button> */}
    </>
  );
};

export default CookiePolicy;

import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkout, getCartList } from '@/api/cart.api';

import { FormWrapper, TotalWrapper } from '../style';
import { SectionTitle, InfoWrapper, OderWrapper } from './style';

import PageContainer from '@/components/Container/PageContainer';
import { Button } from '@/components/UI/Button';
import { LoadingPage } from '@/layout';
import { useAppDispatch, useAppSelector } from '@/redux/store.hook';
import { clearCart, getCartSelector } from '@/redux/cart/cart.slice';
import { Text } from '@/components/UI/Typography/Text';
import { errorHandler } from '@/utils/toastHandler';
import useCurrentLang from '@/hooks/useCurrentLang';

const Checkout = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const lang = useCurrentLang();
  const [isLoading, setIsLoading] = useState(false);
  const cart = useAppSelector(getCartSelector);
  const { userData, total } = cart;
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const [cartTotal, setCartTotal] = useState(0);

  //   進入此頁面時先檢查是否cart的items有值, 若無則導回首頁並顯示toast
  useEffect(() => {
    if (cart.products.length === 0) {
      navigate('/');
      errorHandler(t('error.cartEmpty'));
      return;
    }

    getCartListData();
  }, [cart]);

  //   暫用, 確認是否有資料, 若無則送出假資料
  const [cartList, setCartList] = useState<CartListResponse[]>();
  const getCartListData = useCallback(async () => {
    if (cartList !== undefined) return;
    try {
      const res = (await getCartList()).data;
      if (res.success) {
        if (res.data.length !== 0) {
          setCartList(res.data);
          const listData = res.data as CartListResponse[];
          const totalPrice = listData.reduce((acc, cur) => {
            return acc + cur.price * cur.quantity;
          }, 0);

          setCartTotal(totalPrice);
        }
      }
    } catch (error) {}
  }, []);

  //   submit時先檢查cartList有值才執行checkout
  // 若無則將chekcDetail的資料填入假資料
  const [checkDetail, setCheckDetail] = useState<CheckoutResponse>();

  // 若checkDetail更新則刷新畫面
  useEffect(() => {
    if (!checkDetail) return;
    formRef.current?.submit();
  }, [checkDetail]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    // 確認cartList有值才執行checkout
    if (cartList && cartList.length !== 0) {
      getCheckoutDetail();
      setIsLoading(false);
    } else {
      dispatch(clearCart());
      navigate('/');
      errorHandler(t('error.cartEmpty'));
    }
  };

  const getCheckoutDetail = async () => {
    try {
      const res = (await checkout()).data;
      setCheckDetail(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <PageContainer title={t('pricingPackagePreview')}>
      <LoadingPage isLoading={isLoading} />
      <div hidden>
        <form ref={formRef} action={process.env.REACT_APP_CART_URL} method="post">
          <div id="paymentDetailsSection" className="section">
            <input type="text" value={checkDetail ? checkDetail.access_key : ''} name="access_key" />
            <input type="text" value={checkDetail ? checkDetail.profile_id : ''} name="profile_id" />
            <input type="text" value={checkDetail ? checkDetail.transaction_uuid : ''} name="transaction_uuid" />
            <input type="text" value={checkDetail ? checkDetail.signed_field_names : ''} name="signed_field_names" />
            <input
              type="text"
              value={checkDetail ? checkDetail.unsigned_field_names : ''}
              name="unsigned_field_names"
            />
            <input type="text" value={checkDetail ? checkDetail.signed_date_time : ''} name="signed_date_time" />
            <input type="text" value={checkDetail ? checkDetail.locale : ''} name="locale" />
            <input type="text" value={checkDetail ? checkDetail.transaction_type : ''} name="transaction_type" />
            <input type="text" value={checkDetail ? checkDetail.reference_number : ''} name="reference_number" />
            <input type="number" value={checkDetail ? checkDetail.amount : ''} name="amount" />
            <input type="text" value={checkDetail ? checkDetail.currency : ''} name="currency" />
            <input type="text" value={checkDetail ? checkDetail.signature : ''} name="signature" />
          </div>
        </form>
      </div>
      <FormWrapper onSubmit={(e) => onSubmit(e)}>
        <InfoWrapper>
          <SectionTitle>{t('pages.cart.billingInfo.title')}</SectionTitle>
          <div className="info">
            <div className="title">{t('info.companyName')}</div>
            <div className="content">{userData.acc_coname}</div>
          </div>
          <div className="info">
            <div className="title">{t('info.region')}</div>
            <div className="content">{userData.acc_country}</div>
          </div>
          <div className="info">
            <div className="title">{t('info.businessRegistrationNumber')}</div>
            <div className="content">{userData.acc_BR}</div>
          </div>
          <div className="info">
            <div className="title">{t('info.companyAddress')}</div>
            <div className="content">{userData.acc_address?.split('@@_@@').join(' ')}</div>
          </div>
          <div className="info">
            <div className="title">{t('info.companyEmail')}</div>
            <div className="content">{userData.com_email}</div>
          </div>
          <div className="info">
            <div className="title">{t('info.giveName')}</div>
            <div className="content">{userData.acc_firstName}</div>
          </div>
          <div className="info">
            <div className="title">{t('info.familyName')}</div>
            <div className="content">{userData.acc_lastName}</div>
          </div>
        </InfoWrapper>
        {/* <InfoWrapper>
          <SectionTitle>Payment method</SectionTitle>
          <div className="info">
            <div className="title"></div>
            <div className="content"></div>
          </div>
        </InfoWrapper> */}
        <OderWrapper>
          <SectionTitle>{t('pages.cart.pricingPackagePreview.orderDetails')}</SectionTitle>
          <div className="item header">
            <div className="name">{t('pages.payment.row.orderItems')}</div>
            <div className="detail">
              <div></div>
              <div className="option">{t('pages.payment.row.option')}</div>
              <div className="totalPayment">{t('pages.payment.row.totalPayment')}</div>
            </div>
          </div>
          {cart.products.map((item, index) => (
            <div className="item" key={item.name + index}>
              <div className="name">{lang === 'sc' ? item.name_cn : lang === 'tc' ? item.name_zh : item.name}</div>
              <div className={item.priceAry.length !== 1 ? 'detail multi' : 'detail multi'}>
                {item.priceAry.length === 1 && (
                  <>
                    <div>
                      {item.price} {t('pages.payment.currency.HKD')}
                    </div>
                    <div>{item.quantity}</div>
                    <div>{item.price * item.quantity}</div>
                  </>
                )}
                {item.priceAry.length !== 1 && (
                  <>
                    <div>{item.quotaOptions[item.priceAry.findIndex((i) => i === item.price)].text}</div>
                    <div>{item.priceAry[item.priceAry.findIndex((i) => i === item.price)]}</div>
                  </>
                )}
              </div>
            </div>
          ))}
          {/* {cartTotal - total < 0 && (
            <div className="item">
              <div className="name">{t('pages.cart.feeWaiver')}</div>
              <div className="detail multi">
                <div></div>
                <div>{parseInt(cartTotal.toFixed(0)) - total}</div>
              </div>
            </div>
          )} */}
        </OderWrapper>
        <TotalWrapper>
          <span className="total">{t('total')}</span>
          {t('pages.payment.currency.HKD')}
          <span> {cartTotal.toFixed(0)}</span>
        </TotalWrapper>
        <Button size={'lg'} variant={'primary'} rounded={false} marginTop={30}>
          {t('buttons.confirmAndPay')}
        </Button>
      </FormWrapper>
    </PageContainer>
  );
};

export default Checkout;

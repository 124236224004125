import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Translate, GetLanguagePair } from '@/api/document.api';
import { successHandler } from '@/utils/toastHandler';
import { convertToSelectData } from '@/utils/convert';

import { Box } from '@/layout';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { Modal } from '@mui/material';
import { ModalForm, ModalTitle } from '../style';
import { Close } from '@/components/Icon';

interface TranscribeProps {
  document_id: string;
  show: boolean;
  setShowTranslate: (show: boolean) => void;
  getDocuments: () => void;
}

const TranslateModal = ({ document_id, show, setShowTranslate, getDocuments }: TranscribeProps) => {
  // @ts-ignore
  const {t} = useTranslation();
  
  const [rawData, setRawData] = useState<GetLanguagePairResponse>();
  const [toOptions, setToOptions] = useState<string[]>();
  const [fromOptions, setFromOptions] = useState<string[]>();
  const [languagePairId, setLanguagePairId] = useState('');

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<{ fromLanguage: string; toLanguage: string }>();

  const onSubmit = handleSubmit(async (data) => {
    setShowTranslate(false);
    try {
      const res = (
        await Translate({
          document_id: document_id,
          languagePairId: languagePairId,
          from: data.fromLanguage,
          to: data.toLanguage,
        })
      ).data;
      if (res.success) {
        getDocuments();
        setValue('fromLanguage', '');
        setValue('toLanguage', '');
        successHandler(t('success.upload'));
      }
    } catch (error: any) {}
  });

  const initData = useCallback(async () => {
    try {
      const res = (await GetLanguagePair()).data;
      if (res.success) {
        setRawData(res.data);
        setFromOptions(res.data.from);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  // 監視fromLanguage的變化，改變toLanguage的選項
  useEffect(() => {
    if (!rawData) return;
    if (watch('fromLanguage')) {
      const toLang = rawData.to[`${watch('fromLanguage')}`].map((item: any) => item.name);
      setValue('toLanguage', '');
      setLanguagePairId('');
      setToOptions(toLang);
    }
  }, [watch('fromLanguage')]);

  // 監測toLanguage的變化，改變languagePairId
  useEffect(() => {
    if (!rawData) return;
    if (watch('toLanguage')) {
      const languagePairId = rawData.to[`${watch('fromLanguage')}`].filter(
        (item: any) => item.name === watch('toLanguage')
      )[0].languagePairId;
      setLanguagePairId(languagePairId);
    }
  }, [watch('toLanguage')]);

  const setModalClose = () => {
    setShowTranslate(false);
    reset();
  };

  return (
    <Modal
      open={show}
      onClose={setModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm onSubmit={onSubmit}>
        <Close.ButtonClose onClick={setModalClose}>
          <Close.CloseSVG />
        </Close.ButtonClose>
        <ModalTitle>{t('pages.docLibrary.translateFile')}</ModalTitle>
        {/* <ModalNotice>Available translation(s): 3</ModalNotice> */}
        <FormSelect
          control={control}
          data={convertToSelectData(fromOptions ? fromOptions : [])}
          inputLabel={'From Language'}
          selectLabel={'From Language'}
          name={'fromLanguage'}
        />
        <Box marginTop={10}>
          <FormSelect
            control={control}
            data={convertToSelectData(toOptions ? toOptions : [])}
            inputLabel={'To Language'}
            selectLabel={'To Language'}
            name={'toLanguage'}
          />
        </Box>

        <Box style={{ marginTop: '28px', display: 'flex', alignItems: 'center' }}>
          <Button size={'lg'} variant={'primary'} rounded={false} marginRight={40} disabled={!isDirty}>
            {t('buttons.translate')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={setModalClose}
            style={{ textDecoration: 'underline', border: 'unset' }}
          >
            {t('buttons.back')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

export default TranslateModal;

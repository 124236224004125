import { useState, useEffect } from 'react';
import { useAppSelector } from '@/redux/store.hook';
import { useTranslation } from 'react-i18next';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useNavigate } from 'react-router-dom';
import useUserDetail from '@/hooks/useUserDetail';
import PageContainer from '@/components/Container/PageContainer';
import ECert from '@/components/Account/E-Cert';
import { Box, Button as DefaultBtn } from '@mui/material';
import { Button } from '../style';
import { CompanyInfoLine, PersonalInfoLine } from '@/components/RegistrationStepLine/StepLine';


const ECertEdit = () => {
    // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const auth = useAppSelector(getAuthSelector);
  const userDetail = useUserDetail({ activeStep: activeStep });

  useEffect(() => {
    if (auth.userData.role === 1) {
      setActiveStep(CompanyInfoLine.findIndex((item) => 'e-Cert' === item));
    } else {
      setActiveStep(PersonalInfoLine.findIndex((item) => 'e-Cert' === item));
    }
  }, []);

  const ButtonGroup = (watch: Object) => (
    <Box style={{ marginTop: 30 }}>
      <Button>{t('buttons.submit')}</Button>
      <DefaultBtn
        type="button"
        color="primary"
        onClick={() => navigate('/profile/edit')}
        style={{ fontSize: 20, color: '#0B319D', padding: '1px 40px', border: '1px solid #0B319D', marginLeft: 15 }}
      >
        {t('buttons.back')}
      </DefaultBtn>
    </Box>
  );

  return (
    <PageContainer title={'e-Cert'}>
      <div style={{ display: 'flex' }}>
        <ECert ButtonGroup={ButtonGroup} userDetail={userDetail} />
      </div>
    </PageContainer>
  );
};

export default ECertEdit;

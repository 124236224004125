import styled from 'styled-components';

const Wrapper = styled.form`
  width: 100%;
  max-width: 930px;
  padding: 30px 50px;
  border-radius: 15px;
  background: #fff;
`;

const PriceWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  background: linear-gradient(90deg, #0f319d 0%, #4ebbc6 100%);
  border-radius: 15px 15px 15px 0px;
  padding: 25px 25px 20px;
  margin-bottom: 80px;
`;

const RegistrationWrapper = styled.div`
  .title {
    margin-bottom: 20px;
    font-size: ${(p) => p.theme.fontSizes.md};
    font-weight: 700;
  }
  
  .content {
    display: flex;
    gap: 15px;

    .border {
      width: 100%;
      border-radius: 5px;
      border: 1px solid ${(p) => p.theme.colors.gray300};
    }
  }
`

const ReasonRadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 2px solid ${(p) => p.theme.colors.gray100};

  &:last-child {
    margin-bottom: 30px;
    border-bottom: 2px solid ${(p) => p.theme.colors.gray100};
  }

  .radio {
    width: 100%;
    max-width: 250px;
  }

  .intro {
    width: 100%;
    line-height: 1.5;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;

  .title {
    width: 240px;
    color: ${(p) => p.theme.colors.gray300};
  }

  a {
    color: ${(p) => p.theme.colors.primary500};
    text-decoration: underline;
  }

  .content {
    flex: 1 0 0;

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: ${(p) => p.theme.colors.primary500};
      padding-bottom: 10px;
      border-bottom: 2px solid ${(p) => p.theme.colors.gray100};
      margin-bottom: 10px;

      div {
        color: ${(p) => p.theme.colors.secondary500};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

const Hr = styled.hr`
  color: ${(p) => p.theme.colors.gray100};
  margin: 15px 0;
`;

const CartInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: rgba(233, 233, 233, 0.25);
  padding: 10px 15px;

  .item_detail {
    display: flex;
    align-items: center;
    gap: 20px;

    .selector {
      width: 60px;

      .MuiInputBase-root {
        margin-top: 0;
      }
    }
  }
`;

const TotalWrapper = styled.div`
  text-align: right;
  margin-top: 20px;
  font-size: ${(p) => p.theme.fontSizes.base};
  color: ${(p) => p.theme.colors.gray500};

  span {
    font-size: ${(p) => p.theme.fontSizes.md};
    font-weight: 700;
    color: ${(p) => p.theme.colors.red};
  }

  .total {
    display: inline-block;
    color: ${(p) => p.theme.colors.gray500};
    font-size: ${(p) => p.theme.fontSizes.base};
    margin-right: 55px;
  }
`;

export { RegistrationWrapper, Wrapper, PriceWrapper, ReasonRadioWrapper, Row, Hr, CartInfoItem, TotalWrapper };

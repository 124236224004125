import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/UI/Button';
import { Box } from '@/layout';
import { Modal } from '@mui/material';

import { ModalForm, ModalTitle, ModalNotice } from '../style';
import { successHandler } from '@/utils/toastHandler';
import { Close } from '@/components/Icon';
import { DestroyDocument } from '@/api/document.api';

interface TranscribeProps {
  document_id: string;
  show: boolean;
  setDeleteModal: (show: boolean) => void;
  getDocuments: () => void;
}

const DeleteConfirm = ({ document_id, show, setDeleteModal, getDocuments }: TranscribeProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const { handleSubmit } = useForm<{ id: string }>();

  const onSubmit = handleSubmit(async (data) => {
    if (!document_id) return;
    try {
      const res = (await DestroyDocument(document_id)).data;
      getDocuments();
      successHandler(t('success.delete'));
      setDeleteModal(false);
    } catch (error) {}
  });

  return (
    <Modal
      open={show}
      onClose={() => setDeleteModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm onSubmit={onSubmit}>
        <Close.ButtonClose
          onClick={() => {
            setDeleteModal(false);
          }}
        >
          <Close.CloseSVG />
        </Close.ButtonClose>
        <ModalTitle>{t('pages.docLibrary.modal.delete.title')}</ModalTitle>
        <ModalNotice>{t('pages.docLibrary.modal.delete.subTitle')}</ModalNotice>

        <Box style={{ marginTop: '28px', display: 'flex', alignItems: 'center' }}>
          <Button size={'lg'} variant={'danger'} rounded={false} marginRight={40}>
            {t('buttons.delete')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setDeleteModal(false)}
            style={{ textDecoration: 'underline' }}
          >
            {t('buttons.back')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

export default DeleteConfirm;

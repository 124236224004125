import styled from 'styled-components';

const ModalMainWrapper = styled.div`
  position: absolute;
  top: calc(50% - 178px);
  left: calc(50% - 250px);
  width: 500px;
  background: ${(p) => p.theme.colors.light};
  padding: 30px 50px;
  border-radius: 15px 15px 15px 0px;
  position: relative;

  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
`;

const Row = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;

  .copy {
    cursor: pointer;
  }
`;

const ActionBtns = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 40px;

  button {
    font-family: 'Roboto';
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    text-decoration-line: underline;
    color: ${(p) => p.theme.colors.secondary500};
    cursor: pointer;
  }
`;

const ConfirmWrapper = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 20px;
  text-align: center;
  color: ${(p) => p.theme.colors.gray500};
`;

const ConfirmTitle = styled.div`
  font-weight: 700;
  margin-bottom: 30px;
`;

const DeleteBtns = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;

  button:last-child {
    color: ${(p) => p.theme.colors.primary100};
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export {
    ModalMainWrapper,
    Row,
    ActionBtns,
    ConfirmWrapper,
    ConfirmTitle,
    DeleteBtns
}
import styled from 'styled-components';

const DownloadLink = styled.span`
  color: ${(p) => p.theme.colors.primary500};
  font-size: ${(p) => p.theme.fontSizes.base};
  text-decoration-line: underline;
  cursor: pointer;
`;

export { DownloadLink };

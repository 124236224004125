const translate = [
  {
    value: 'zh-CHS',
    text: 'Simplified Chinese',
  },
  {
    value: 'Chinese_Traditional',
    text: 'Traditional Chinese',
  },
  {
    value: 'English',
    text: 'English',
  },
  {
    value: 'ar',
    text: 'Arabic',
  },
  {
    value: 'ru',
    text: 'Russian',
  }
];

const transcribe = ['en-US', 'en-GB', 'en-HK', 'zh-CN', 'zh-HK'];

export { translate, transcribe };

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chat } from '@/components/Icon';
import { Text } from '@/components/UI/Typography/Text';
import { Avatar, ChatRoomItem, ChatWrapper } from '../style';
import { Box, Flex, LoadingPage } from '@/layout';
import { useForm } from 'react-hook-form';
import FormSearch from '@/components/UI/Input/FormSearch';
import { useSearchParams } from 'react-router-dom';
import { convertTiming } from '@/utils/convert';
import { useDispatch } from 'react-redux';
import { getAuthSelector, unreadCounterChange } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';

interface ChatRoomListProps {
  roomData: ChatList[];
}

const ChatRoomList = ({ roomData }: ChatRoomListProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState<ChatListData[]>([]);
  const { watch, handleSubmit, control } = useForm<{ search: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { userData } = useAppSelector(getAuthSelector);

  const onSubmit = handleSubmit(async (data) => {});

  // 監視data.search的變化
  const watchSearch = watch('search');

  const initData = useCallback(
    (watchSearch: string) => {
      let filterData: ChatList[] = [];
      if (watchSearch) {
        filterData = roomData.filter((item) => item.name.toLowerCase().includes(watchSearch.toLowerCase()));
      } else {
        filterData = roomData;
      }

      const newData: ChatListData[] = filterData.map((item) => ({
        id: item.id,
        deal_id: item.deal_id,
        name: item.name,
        isGroup: item.type_id === 0 ? false : true,
        lastMessageTime: item.last_message ? convertTiming(item.last_message.created_at) : '',
        membersNumber: item.participants_count,
        logo: item.logo,
        newMessageCount:
          searchParams.get('room') && searchParams.get('room') === item.id.toString() ? 0 : item.unread_count,
        lastMessage: item.last_message
          ? `${item.last_message.sender?.acc_firstName} ${item.last_message.sender?.acc_lastName}: ${item.last_message.message}`
          : '',
      }));

      let counter = 0;
      newData.forEach((item) => {
        counter += item.newMessageCount;
      });

      // 檢查若目前取得的counter總數與redux中的counter總數不同，則更新redux中的counter
      if (counter !== userData.unread_message_count) {
        dispatch(unreadCounterChange(counter));
      }
      setListData(newData);
      setIsLoading(false);
    },
    [roomData]
  );

  useEffect(() => {
    initData(watchSearch);
  }, [initData, watchSearch]);

  const changeRoom = (id: number, index: number) => {
    const newData = [...listData];
    dispatch(unreadCounterChange(userData.unread_message_count - newData[index].newMessageCount));
    newData[index].newMessageCount = 0;
    setListData(newData);

    if (id === parseInt(searchParams.get('room')!)) return;
    setIsLoading(true);
    setSearchParams({ room: id.toString() });
  };

  return (
    <ChatWrapper>
      <Text padding={'0 25px'} fontSize={20} fontWeight={'bold'}>
        <Flex gridGap={10}>
          <Chat.ChatSVG />
          <span>{t('info.chat')}</span>
        </Flex>
      </Text>
      <Box padding={'0 25px'} margin={'20px 0'}>
        <form onSubmit={onSubmit}>
          <FormSearch control={control} name={'search'} />
        </form>
      </Box>

      <div style={{ minHeight: 500, maxHeight: 680, overflow: 'auto' }}>
        {listData.map((item, index) => (
          <ChatRoomItem key={item.id + item.name} onClick={() => changeRoom(item.id, index)}>
            <div className="bar"></div>
            <div className="history">
              <Avatar>
                <img src={item.logo} alt="avatar" />
              </Avatar>
              <div className="content new">
                <Text fontSize={18} fontWeight={'bold'} marginBottom={10}>
                  {item.isGroup ? `${item.name} (${item.membersNumber})` : `${item.name}`}
                </Text>
                <Text color={'#616161'} fontSize={17} marginBottom={'5px'}>
                  {item.lastMessage}
                </Text>
                <Text color={'#999999'} fontSize={17}>
                  {item.lastMessageTime}
                </Text>
              </div>
              {item.newMessageCount > 0 && <div className="counter">{item.newMessageCount}</div>}
            </div>
          </ChatRoomItem>
        ))}

        <LoadingPage isLoading={isLoading} />
      </div>
    </ChatWrapper>
  );
};

export default ChatRoomList;

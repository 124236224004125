import styled from 'styled-components';

const ContentWrapper = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
`

const MainContent = styled.div`
  width: 75%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TableContainer = styled.div`
  padding: 30px 50px;
  background: ${(p) => p.theme.colors.light};
  border-radius: 15px;

  div {
    color: ${(p) => p.theme.colors.gray500};
    font-family: 'Roboto';
    font-size: ${(p) => p.theme.fontSizes.base} !important;
    line-height: 19px;
  }

  .rdt_TableHeadRow {
    div {
      color: ${(p) => p.theme.colors.gray300};
    }
  }
`;

export { ContentWrapper, MainContent, TableContainer };

import Plans from '@/components/Plans';
import Footer from '../Footer';
import { ContainerWrapper, Title } from './style';
import { useTranslation } from 'react-i18next';
import useResize from '@/hooks/useResize';

const Pricing = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile } = useResize();

  return (
    <>
      <ContainerWrapper>
        <Title isMobile={isMobile}>{t('pages.index.Pricing.title')}</Title>
        <Plans />
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default Pricing;

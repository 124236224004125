import { Id, toast } from 'react-toastify';

const autoClose = 6000;

const successHandler = (message: string) => {
  toast.success(message, { autoClose });
};

const errorHandler = (error: any) => {
  // 傳入的資料先檢測類型再決定如何回應
  if (typeof error === 'string') {
    toast.error(error, { autoClose });
  } else if (typeof error === 'object' && Array.isArray(error)) {
    error.forEach((e: string) => toast.error(e, { autoClose }));
  }
};

const progressingHandler = (message: string) => {
  const id = toast.loading(message, { autoClose });
  return id;
};

const doneHandler = (id: Id, type: 'info' | 'success' | 'warning' | 'error' | 'default', message: string) => {
  toast.update(id, { render: message, type: type, isLoading: false, autoClose });
};

export { successHandler, errorHandler, progressingHandler, doneHandler };

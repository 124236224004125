import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from '@/components/UI/Tabs';
import ParticipantsList from '@/components/Participants/ParticipantsList';
import MoreCompany from '@/components/Participants/MoreCompany';

import { getInvitedParticipants } from '@/api/deal.api';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { theme } from '@/theme/Theme';
import { InviteNoneDMPUserBtn } from './styles';
import InviteGuestModal from './Modal';

interface ParticipantsProps {
  handleNextStep: () => Promise<void>;
  handleBackStep: () => void;
  queryId: string | null;
}

const Participants = ({ handleNextStep, handleBackStep, queryId }: ParticipantsProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const tabLabel = t('pages.dealInitiation.formStepper.step3.tabs', { returnObjects: true });
  const initTabIndex = 1;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex);
  const [invitedParticipantList, setInvitedParticipantList] = useState<{
    company: DealInvitedCompany[];
    personal: DealInvitedPersonal[];
  }>();

  const initData = useCallback(async () => {
    if (!queryId) return;
    if (selectedTab !== 1 && selectedTab !== 2) return;
    try {
      const res = (await getInvitedParticipants(queryId)).data;
      setInvitedParticipantList(res.data);
    } catch (error) {}
  }, [queryId, selectedTab]);

  useEffect(() => {
    initData();
  }, [initData]);

  const tabs: TabsType = [
    {
      label: tabLabel[0],
      index: initTabIndex,
      Component: () => (
        <ParticipantsList
          queryId={queryId}
          setSelectedTab={setSelectedTab}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          invitedParticipantList={invitedParticipantList}
          setInvitedParticipantList={setInvitedParticipantList}
        />
      ),
    },
    {
      label: tabLabel[1],
      index: 2,
      Component: () => (
        <MoreCompany
          queryId={queryId}
          setSelectedTab={setSelectedTab}
          invitedParticipantList={invitedParticipantList}
          setInvitedParticipantList={setInvitedParticipantList}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs
        rightComponent={
          <InviteNoneDMPUserBtn onClick={() => setShowModal(true)}>
            {t('pages.dealInitiation.formStepper.step3.inviteWithoutAnAccount')}
          </InviteNoneDMPUserBtn>
        }
        selectedTab={selectedTab}
        onClick={setSelectedTab}
        tabs={tabs}
      />
      <InviteGuestModal handleNextStep={handleNextStep} showModal={showModal} setShowModal={setShowModal} deal_id={queryId} />
    </>
  );
};

export default Participants;

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';

const date = new Date();
const today = date.toISOString();

i18next
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    backend: { loadPath: `/locales/{{lng}}/{{ns}}.json?q=${today}` },
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      // useSuspense: false,
    },
  });

export default i18next;

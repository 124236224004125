import {
  ContainerWrapper,
  StartWrapper,
  Title,
  CardList,
  Card,
  CardHero,
  CardInfo,
  CardTitle,
  CardText,
  CardButton,
} from './style';

const ServicesBanner = () => {
  return (
    <ContainerWrapper>
      <StartWrapper>
        <Title>Services</Title>
        {/* <CardList>
          <Card>
            <CardHero src="/images/index/card1.jpg" />
            <CardInfo>
              <CardTitle>Know Your Partner</CardTitle>
              <CardText>
                <ul>
                  <li>Expedited Digital Cert. Application</li>
                  <li>Global Corporate Know Your Partner - Legal Entity Identifier (LEI)</li>
                  <li>Credit Check</li>
                </ul>
              </CardText>
            </CardInfo>
            <CardButton>Read More</CardButton>
          </Card>

          <Card>
            <CardHero src="/images/index/card2.jpg" />
            <CardInfo>
              <CardTitle>Contract Templates</CardTitle>
              <CardText>
              Basic contract templates for free downloads (e.g. NDA, sample sales of goods agreement, sample leases, etc.). Specific agreement templates offer to subscribers
              </CardText>
            </CardInfo>
            <CardButton>Read More</CardButton>
          </Card>

          <Card>
            <CardHero src="/images/index/card3.jpg" />
            <CardInfo>
              <CardTitle>Deal Flow Management</CardTitle>
              <CardText>
                <ul>
                  <li>All-In-One</li>
                  <li>Customised</li>
                  <li>Link with e-signature service and Video Conferencing system</li>
                </ul>
              </CardText>
            </CardInfo>

            <CardButton>Read More</CardButton>
          </Card>
        </CardList> */}
      </StartWrapper>
    </ContainerWrapper>
  );
};

export default ServicesBanner;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { disconnect } from '../notification/notification.slice';

type AuthState = {
  isLogin: boolean;
  accessToken?: string;
  refreshToken?: string;
  userData: MemberProfile | any;
  registDone: boolean;
};

const INITAIL_STATE: AuthState = { isLogin: false, accessToken: '', userData: {}, registDone: true };

const authSlice = createSlice({
  name: 'auth',
  initialState: INITAIL_STATE,
  reducers: {
    signIn: (_, action: PayloadAction<AuthState>) => {
      if (action.payload.userData.main_account_company_info) {
        if (action.payload.userData.main_account_company_info.isMainAccount) {
          return action.payload;
        } else {
          const { mainAccountData } = action.payload.userData.main_account_company_info;
          if (!mainAccountData && typeof mainAccountData === 'string') return action.payload;
          const companyInfo = {
            acc_coname: mainAccountData?.acc_coname,
            acc_logo: mainAccountData?.acc_logo ?? '/images/default_company_logo.jpg',
            acc_address: mainAccountData?.acc_address,
            acc_desc: mainAccountData?.acc_desc,
            com_email: mainAccountData?.com_email,
            acc_tel: mainAccountData?.acc_tel,
            acc_fax: mainAccountData?.acc_fax,
            acc_website: mainAccountData?.acc_website,
            acc_category: mainAccountData?.acc_category,
            acc_languages: mainAccountData?.acc_languages,
            acc_currency: mainAccountData?.acc_currency,
            acc_countryCode: mainAccountData?.acc_countryCode,
            acc_category_other: mainAccountData?.acc_category_other,
            acc_languages_other: mainAccountData?.acc_languages_other,
            acc_currency_other: mainAccountData?.acc_currency_other,
          };

          return { ...action.payload, userData: { ...action.payload.userData, ...companyInfo } };
        }
      } else {
        return action.payload;
      }
    },
    signOut: () => {
      disconnect();
      return INITAIL_STATE;
    },
    update: (state, action: PayloadAction<AuthState>) => {
      state.userData = action.payload.userData;
      state.registDone = action.payload.registDone;
    },
    updateRegistDone: (state, action: PayloadAction<boolean>) => {
      state.registDone = action.payload;
    },
    unreadCounterChange: (state, action: PayloadAction<number>) => {
      state.userData.unread_message_count = action.payload;
    },
    addUnreadCounter: (state) => {
      state.userData.unread_message_count++;
    },
    updateLang: (state, action: PayloadAction<string>) => {
      state.userData.lang = action.payload;
    },
    renewUserData: (state, action: PayloadAction<MemberProfile>) => {
      state.userData = action.payload;
    },
    switchLayout: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
  },
});

export const {
  signIn,
  signOut,
  update,
  updateRegistDone,
  unreadCounterChange,
  addUnreadCounter,
  updateLang,
  renewUserData,
  switchLayout,
} = authSlice.actions;

export const getAuthSelector = (store: RootState) => store.auth;

export default authSlice.reducer;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceWrapper, Card } from './style';
import { Text } from '@/components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import { theme } from '@/theme/Theme';
import { Box, CardContainer, Flex, LoadingPage } from '@/layout';
import { getPlansList } from '@/api/cart.api';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { plans } from '@/data/plans';
import useCurrentLang from '@/hooks/useCurrentLang';
import useResize from '@/hooks/useResize';
import { Check } from '@/components/Icon';
import { StorageBar } from '../UI/StorageBar';
import { getStorageSpace } from '@/api/auth.api';
import { convertToFileSize } from '@/utils/convert';
import { Button } from '../UI/Button';
import { CouponModal } from '../UI/Modal';

const Plans = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const plansName = t('pages.index.Pricing.plansName', { returnObjects: true });
  const addOnDetail = t('pages.index.Pricing.addOnDetail', { returnObjects: true });
  const [data, setData] = useState<PlansProps[]>();
  // const note = t('pages.index.Pricing.note', { returnObjects: true });
  const { isLogin, userData } = useAppSelector(getAuthSelector);
  const { isTablet, isMobile } = useResize();
  const [isExpiring, setIsExpiring] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const plansColumns = t('pages.index.Pricing.plansColumns', { returnObjects: true });
  const plansData = t('pages.index.Pricing.plansData', { returnObjects: true });
  const [showDocumentStorageAlmostFullModal, setShowDocumentStorageAlmostFullModal] = useState<boolean>(false);
  const [showDocumentStorageFullModal, setShowDocumentStorageFullModal] = useState<boolean>(false);
  const [storage, setStorage] = useState<string>('0');
  const [storageExpireData, setStorageExpireData] = useState<string>('');

  const getPlans = useCallback(async () => {
    setIsloading(true);
    try {
      const res = (await getPlansList()).data;
      if (res.success) {
        // 暫時改為僅判斷是否為pro方案
        // console.log(res.data);
        ///////////////////////////////////////////////////
        // const newData: Feature[] = [];
        // // 判斷使用者的國家, 若acc_countryCode為+86, 則留下name包含(PRC)的方案
        // if (res.data.length !== 0 && userData && userData.acc_countryCode === '+86') {
        //   newData.push(...res.data.filter((item: any) => item.name.includes('(PRC)')));
        // } else {
        //   newData.push(...res.data.filter((item: any) => !item.name.includes('(PRC)')));
        // }
        // plans[lang].forEach((plan, index) => {
        //   plan.recommend = false;
        //   const samePlan = newData.find(
        //     (item: Feature) => plan.includeId && plan.includeId.includes(parseInt(item.id))
        //   );
        //   if (samePlan) {
        //     plan.id = parseInt(samePlan.id);
        //     plan.discountPrice = samePlan.price;
        //     plan.plan_number = samePlan.plan_number ? samePlan.plan_number : 0;
        //   }
        //   if (!isLogin && index === 1) {
        //     plan.recommend = true;
        //   }
        // });
        // setData(plans[lang]);
        // if (!isLogin) return;
        // if (userData.plan.level !== null) {
        //   // 判斷使用者是否有購買方案
        //   const samePlan = plans[lang].find((item) => item.plan_number === userData.plan.level);
        //   if (samePlan) {
        //     samePlan.recommend = true;
        //   }
        // } else {
        //   plans[lang][0].recommend = true;
        // }
        // setData(plans[lang]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      checkStorage();
      setIsloading(false);
    }
  }, [t]);

  const checkStorage = async () => {
    try {
      const res = (await getStorageSpace()).data;
      if (res.success) {
        if (res.data.extra_expires_at) setStorageExpireData(convertDateFormat(res.data.extra_expires_at));
        // storage預設為10GB, 計算total前須先減去
        res.data.total -= 10 * 1024 * 1024 * 1024;
        if (res.data.total < 0) {
          res.data.total = 0;
          setStorage(res.data.total + 'GB');
          return;
        }
        // 計算剩餘空間並去除小數點
        const addOnStorageTotal = res.data.total / 1024 / 1024 / 1024;
        setStorage(addOnStorageTotal + 'GB');
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const convertDateFormat = (date: string) => {
    const d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}, ${d.getFullYear()}`;
  };

  useEffect(() => {
    // getPlans();
    if (!isLogin) {
      setIsloading(false);
      return;
    }

    checkStorage();

    if (userData.plan.expiration_date) {
      const expiryDate = new Date(userData.plan.expiration_date);
      // 確認過期日是否在一個月內
      const now = new Date();
      const diff = expiryDate.getTime() - now.getTime();
      const days = diff / (1000 * 60 * 60 * 24);
      if (days < 30) {
        setIsExpiring(true);
      }
    }
    setIsloading(false);
  }, [getPlans]);

  return (
    <>
      <LoadingPage isLoading={isLoading} />
      {/* <Subtitle>{t('pages.index.Pricing.subtitle')}</Subtitle> */}
      {/* <Text fontSize={20} marginTop={40}>
          {t('pages.index.Pricing.description')}
          <List>
            {steps.map((step: string, index: number) => (
              <li key={index}>{step}</li>
            ))}
          </List>
        </Text> */}
      {/* <span>*Excluded the LEI application</span> */}
      {/* <Text fontSize={20} textAlign={'center'}>
          Fees will apply for services such as LEI applications, credit checks, etc.
        </Text> */}
      <Box width={'100%'} overflowX={'auto'}>
        {isLogin && (
          <Flex width={'100%'} gridGap={10} marginBottom={30} padding={'0 10px'}>
            <Flex
              width={'430px'}
              justifyContent={'center'}
              flexDirection={'column'}
              gridGap={10}
              background={theme.colors.light}
              borderRadius={theme.radii.base}
              boxShadow={theme.shadows.base}
              padding={25}
            >
              <Flex
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent={'space-between'}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Text fontWeight={700}>{t('pages.index.Pricing.subscription')}</Text>
                <Text>
                  {userData.plan.level ? plansName[1] : plansName[0]}
                  {userData.plan.level && (
                    <>
                      ({t('pages.index.Pricing.dueBy')}: {convertDateFormat(userData.plan.expiration_date)})
                    </>
                  )}
                </Text>
              </Flex>
              <Flex
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent={'space-between'}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Text fontWeight={700}>{t('pages.index.Pricing.addonStorage')}</Text>
                <Text>
                  {storage} {storageExpireData !== '' && `(${t('pages.index.Pricing.dueBy')}: ${storageExpireData} )`}
                </Text>
              </Flex>
              <Flex alignItems={'flex-end'} justifyContent={'flex-end'}>
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  type="button"
                  style={{ cursor: 'pointer' }}
                >
                  <Text color={theme.colors.primary500} style={{ textDecoration: 'underline' }}>
                    {t('pages.index.Pricing.useCoupons')}
                  </Text>
                </button>
              </Flex>
            </Flex>
            <Box
              width={'70%'}
              background={theme.colors.light}
              borderRadius={theme.radii.base}
              boxShadow={theme.shadows.base}
              padding={25}
            >
              <StorageBar
                showStorageAlmostFullModal={showDocumentStorageAlmostFullModal}
                showStorageFullModal={showDocumentStorageFullModal}
                setShowStorageAlmostFullModal={setShowDocumentStorageAlmostFullModal}
                setShowStorageFullModal={setShowDocumentStorageFullModal}
              />
            </Box>
          </Flex>
        )}

        <Flex flexDirection={isTablet ? 'column' : 'row'}>
          <div>
            <PriceWrapper isTablet={isTablet}>
              {plansData &&
                plansData.features.map((plan, index) => (
                  <div className="wrapper" key={'wrapper' + index}>
                    <div className="noteWrapper features">
                      {plansColumns.features.map(
                        (column: { title: string; describe: string; textBlack: boolean }, index: number) => (
                          <div className="note" key={column.title + index}>
                            <div className="noteTitle">{column.title}</div>
                            {column.describe !== '' && (
                              <div className={column.textBlack ? 'noteContent textBlack' : 'noteContent'}>
                                {column.describe}
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    {
                      <Card isTablet={isTablet} key={plan.title} className={plan.title.trim()}>
                        <Link
                          to={
                            !isLogin
                              ? '/auth/signin'
                              : isExpiring
                              ? plan.planId
                              : userData.plan.level !== null
                              ? ''
                              : plan.planId
                          }
                        >
                          <div className="planName">
                            <div className="name">{plan.title}</div>
                            {plan.valued !== '' && (
                              <Flex className="valued">
                                <div>{plan.valued}</div>/ {t('info.year')}
                              </Flex>
                            )}
                            <div className={`subtitle ${plan.valued === '' ? 'mt-40px' : ''}`}>
                              {plan.subtitle !== '' && (
                                <div style={{ fontSize: theme.fontSizes.md }}>{plan.subtitle}</div>
                              )}
                              {plan.description}
                            </div>
                          </div>
                          <div className="info features">
                            <div className="item">
                              <Text fontSize={theme.fontSizes.lg}>{plan.storage}</Text>
                            </div>
                            <div className="item">
                              {plan.dealManagement ? (
                                <Check.CheckSVG width={22} height={22} fill={theme.colors.secondary500} />
                              ) : (
                                <Check.CancelSVG fill={theme.colors.red} />
                              )}
                            </div>
                            <div className="item">
                              {plan.eSignature ? (
                                <Check.CheckSVG width={22} height={22} fill={theme.colors.secondary500} />
                              ) : (
                                <Check.CancelSVG fill={theme.colors.red} />
                              )}
                            </div>
                            <div className="item">
                              {plan.vc ? (
                                <Check.CheckSVG width={22} height={22} fill={theme.colors.secondary500} />
                              ) : (
                                <Check.CancelSVG fill={theme.colors.red} />
                              )}
                            </div>
                            <div className="item">
                              {plan.translation ? (
                                <Check.CheckSVG width={22} height={22} fill={theme.colors.secondary500} />
                              ) : (
                                <Check.CancelSVG fill={theme.colors.red} />
                              )}
                            </div>
                            <div className="item">
                              {plan.contractTemplate ? (
                                <Check.CheckSVG width={22} height={22} fill={theme.colors.secondary500} />
                              ) : (
                                <Check.CancelSVG fill={theme.colors.red} />
                              )}
                            </div>
                          </div>
                        </Link>
                      </Card>
                    }
                  </div>
                ))}
            </PriceWrapper>
            <PriceWrapper isTablet={isTablet}>
              <Text textAlign={isTablet ? 'center' : 'left'} margin={'15px 0 10px'} fontWeight={700}>
                {t('pages.index.Pricing.discountTitle')}
              </Text>
            </PriceWrapper>
            <PriceWrapper isTablet={isTablet}>
              {plansData &&
                plansData.feeService.map((plan, index) => (
                  <div className="wrapper" key={'wrapper' + index}>
                    <div className="noteWrapper feeService">
                      {plansColumns.feeService.map(
                        (column: { title: string; describe: string; textBlack: boolean }, index: number) => (
                          <div className="note" key={column.title + index}>
                            <div className="noteTitle">{column.title}</div>
                            {column.describe !== '' && (
                              <div className={column.textBlack ? 'noteContent textBlack' : 'noteContent'}>
                                {column.describe}
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    {
                      <Card isTablet={isTablet} key={'feeService' + index}>
                        <div>
                          <div className="info feeService">
                            <div className="item">
                              {plan.crossBorder.unit === '' ? (
                                <Flex alignItems={'center'}>
                                  <Link
                                    to={
                                      !isLogin
                                        ? '/auth/signin'
                                        : userData.acc_countryCode === '+86'
                                        ? '/cart?item=12'
                                        : '/cart?item=11'
                                    }
                                  >
                                    <Button size={'lg'} variant={'regular'}>
                                      {plan.crossBorder?.price}
                                    </Button>
                                  </Link>
                                </Flex>
                              ) : (
                                <Flex alignItems={'center'}>
                                  HK$　
                                  <Text
                                    color={theme.colors.secondary400}
                                    fontSize={theme.fontSizes.lg}
                                    fontWeight={700}
                                  >
                                    {plan.crossBorder?.price}
                                  </Text>
                                  　/ {plan.crossBorder?.unit}
                                </Flex>
                              )}
                            </div>
                            <div className="item" key={index}>
                              {plan.hongKongPost.unit === '' ? (
                                <Flex alignItems={'center'}>
                                  <Link to={isLogin ? '/ecert?step=1' : '/auth/signin'}>
                                    <Button size={'lg'} variant={'regular'}>
                                      {plan.hongKongPost?.price}
                                    </Button>
                                  </Link>
                                </Flex>
                              ) : (
                                <Flex alignItems={'center'}>
                                  HK$　
                                  <Text
                                    color={theme.colors.secondary400}
                                    fontSize={theme.fontSizes.lg}
                                    fontWeight={700}
                                  >
                                    {plan.hongKongPost?.price}
                                  </Text>
                                  　/ {plan.hongKongPost?.unit}
                                </Flex>
                              )}
                            </div>
                            <div className="item" key={index}>
                              {plan.leiRegistration.unit === '' ? (
                                <Flex alignItems={'center'}>
                                  <Link to={isLogin ? '/lei?step=1' : '/auth/signin'}>
                                    <Button size={'lg'} variant={'regular'}>
                                      {plan.leiRegistration?.price}
                                    </Button>
                                  </Link>
                                </Flex>
                              ) : (
                                <Flex alignItems={'center'}>
                                  HK$　
                                  <Text
                                    color={theme.colors.secondary400}
                                    fontSize={theme.fontSizes.lg}
                                    fontWeight={700}
                                  >
                                    {plan.leiRegistration?.price}
                                  </Text>
                                  　/ {plan.leiRegistration?.unit}
                                </Flex>
                              )}
                            </div>
                            <div className="item" key={index}>
                              {plan.prcCreditReport.unit === 'Apply now' ? (
                                <Flex alignItems={'center'}>
                                  <Link to={isLogin ? '/creditReport?step=1' : '/auth/signin'}>
                                    <Button size={'lg'} variant={'regular'}>
                                      {plan.prcCreditReport.price[0].price}
                                    </Button>
                                  </Link>
                                </Flex>
                              ) : (
                                <>
                                  {plan.prcCreditReport?.price.map((item: any, index: number) => (
                                    <Flex width={'100%'} alignItems={'center'} justifyContent={'center'} key={index}>
                                      <Flex fontSize={theme.fontSizes.base} alignItems={'center'}>
                                        HK$　
                                        <Text
                                          color={theme.colors.secondary400}
                                          fontSize={theme.fontSizes.lg}
                                          fontWeight={700}
                                        >
                                          {item.price}
                                        </Text>
                                        　/ {item.unit}
                                      </Flex>
                                    </Flex>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Card>
                    }
                  </div>
                ))}
            </PriceWrapper>
          </div>
          <PriceWrapper isTablet={isTablet}>
            <Card isTablet={isTablet} className={'Add-on'}>
              <Link to={isLogin ? addOnDetail.planId : '/auth/signin'}>
                <div className="planName">
                  <div className="name">{addOnDetail.title}</div>
                </div>
                <div className="info">
                  <div className="item">
                    <Text marginBottom={15} fontSize={theme.fontSizes.lg} fontWeight={700}>
                      {addOnDetail.addStorage?.value}
                    </Text>
                    <Flex alignItems={'center'} justifyContent={'center'}>
                      HK$　
                      <Text
                        className={'green'}
                        color={theme.colors.secondary400}
                        fontSize={theme.fontSizes.lg}
                        fontWeight={700}
                      >
                        {addOnDetail.addStorage?.price}
                      </Text>
                      　/ {addOnDetail.addStorage?.unit}
                    </Flex>
                  </div>
                </div>
              </Link>
            </Card>
          </PriceWrapper>
        </Flex>
      </Box>
      <CouponModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default Plans;

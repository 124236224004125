import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { errorHandler, successHandler } from '@/utils/toastHandler';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';
import { findItem } from '@/utils/commodity';
import { getFeatureList, addToCart, getCartList, cartClear } from '@/api/cart.api';

import { TextField, FormControlLabel, Checkbox } from '@mui/material';

import PageContainer from '@/components/Container/PageContainer';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { InputWrapper, MultiInputWrapper } from '@/components/Container/AuthContainer/styles';
import { LoadingPage } from '@/layout';
import useCountryDetail from '@/hooks/useCountryDetail';
import { convertToSelectData } from '@/utils/convert';
import { useAppDispatch, useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { addItem, getCartSelector, clearCart } from '@/redux/cart/cart.slice'; 
import BillingInfo from '@/components/Cart/BillingInfo';
import { FormWrapper, CartInfoWrapper, Title, CartInfoItem, TotalWrapper } from './style';
import { theme } from '@/theme/Theme';
import useCurrentLang from '@/hooks/useCurrentLang';

interface ItemPorps {
  quantity: number;
  id: string;
  name: string;
  description: string;
  price: number;
  quota: string[];
}

const Cart = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CartForm>();
  const navigate = useNavigate();
  const lang = useCurrentLang();
  const [isLoading, setIsLoading] = useState(false);
  const { inputRegister, emailRegister } = useAuthValidation();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;
  const cart = useAppSelector(getCartSelector);
  const [featureList, setFeatureList] = useState<Feature[]>([]);
  const [productIndex, setProductIndex] = useState<boolean[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [submitController, setSubmitController] = useState<boolean>(true);
  // 透過react-router-dom取得網址的itemID, 並將其轉為陣列
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();

  const { regionRegister, companyNameRegister, giveNameRegister, familyNameRegister, businessRegister } =
    useRegistrationValidation().companyInfo();

  const onSubmit = handleSubmit(async (data) => {
    // 將itemData的quantity按index值存入itemAry對應的item物件中, 並將結果轉存為新的變數items
    const products = watch('item');

    // 透過products的name過濾featureList, 並確認是否有多於一個商品同名, 若有則對照quota數量相同的商品
    products.forEach((product) => {
      const filterItem = featureList.filter((i) => i.name === product.name);
      if (filterItem.length > 1) {
        const filterQuota = filterItem.filter((i) => i.quota.includes(product.quantity.toString()));
        if (filterQuota.length === 1) {
          product.id = filterQuota[0].id;
          product.quantity = 1;
          product.price = filterQuota[0].price;
        }
      }
    });

    const itemDataId = searchParams.get('item');
    const applyId = searchParams.get('certId');

    const cartData: CartState = {
      products,
      total: total,
      returnURL: location.state ? location.state.from : '/home',
      itemData: {
        id: itemDataId ? itemDataId : '',
        applyId: applyId ? applyId : '',
      },
      userData: {
        acc_coname: data.acc_coname,
        acc_country: data.acc_country,
        acc_address: data.acc_address?.split('@@_@@').join(' '),
        com_email: data.com_email,
        acc_firstName: data.acc_firstName,
        acc_lastName: data.acc_lastName,
        acc_BR: data.acc_BR ? data.acc_BR : '',
      },
    };

    // 送出資料到後端
    try {
      setIsLoading(true);
      const res = (
        await addToCart({ feature_id: parseInt(cartData.products[0].id), quantity: cartData.products[0].quantity })
      ).data;
      if (res.success) {
        // 送出用getCartList檢查是否有資料
        await getCartList();
        setIsLoading(false);
        dispatch(addItem(cartData));

        // 成功後導向結帳頁面
        navigate('/cart/checkout');
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    }
  });

  // 進入頁面時取得當前所有商品資料
  const initData = useCallback(async () => {
    if (!userData) return;
    setIsLoading(true);
    setValue('acc_country', userData.acc_country);
    setValue('acc_address', userData.acc_address?.split('@@_@@').join(' '));
    setValue('acc_coname', userData.acc_coname);
    setValue('com_email', userData.com_email);
    setValue('acc_firstName', userData.acc_firstName);
    setValue('acc_lastName', userData.acc_lastName);
    setValue('acc_BR', userData.acc_BR);

    try {
      await cartClear()
      const res: { success: boolean; data: Feature[] } = (await getFeatureList()).data;
      if (res.success) {
        setFeatureList(res.data);

        // 將取得到的itemID轉為陣列
        const itemID = searchParams.get('item')?.split(',');
        // 透過itemID取得商品資料
        setValue('item', findItem(res.data, itemID ? itemID : []));
        setProductIndex(new Array(productIndex.length).fill(false));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.get('item')) {
      navigate('/');
      errorHandler(t('error.cartEmpty'));
      return;
    }

    // 當前只有單品項結帳, 故進入此頁時先將購物車中的資料清空
    dispatch(clearCart());
    

    initData();
  }, [initData]);

  // const numberAry = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const getTotal = () => {
    let total = 0;
    productIndex.forEach((item, index) => {
      if (item) {
        const price =
          itemWatch[index].priceAry[
            itemWatch[index].quotaAry.findIndex((i) => i === itemWatch[index].quantity.toString())
          ];
        if (price) {
          total += price;
        } else {
          total += 0;
        }
      }
    });

    setTotal(total);
  };

  watch((data, { name, type }) => {
    getTotal();
  });

  const itemWatch = watch('item');

  useEffect(() => {
    getTotal();
  }, [productIndex]);

  useEffect(() => {
    if (productIndex.length === 0) return;
    if (!productIndex.includes(false)) {
      setSubmitController(false);
    } else {
      setSubmitController(true);
    }
  }, [productIndex]);

  return (
    <PageContainer title={t('cart')}>
      <LoadingPage isLoading={isLoading} />
      <FormWrapper onSubmit={onSubmit}>
        <CartInfoWrapper>
          <Title>{t('cartInfo')}</Title>
          <CartInfoItem className="header">
            <div className="order_items">{t('pages.payment.row.orderItems')}</div>
            <div className="item_detail">
              <div className="item_price"></div>
              <div className="selector">{t('pages.payment.row.option')}</div>
              <div className="item_total">{t('pages.payment.row.totalPayment')}</div>
            </div>
          </CartInfoItem>
          <div>
            {watch('item') &&
              watch('item').length !== 0 &&
              watch('item').map((item, index) => (
                <CartInfoItem key={item.name + item.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: '#999999',
                          '&.Mui-checked': {
                            color: `${theme.colors.secondary500}`,
                          },
                        }}
                        checked={productIndex[index] ? true : false}
                        onChange={() => {
                          productIndex[index] = !productIndex[index];
                          setProductIndex([...productIndex]);
                        }}
                        name={`itemsId${index}`}
                      />
                    }
                    label={
                      <div className="item_name">
                        {lang === 'sc' ? item.name_cn : lang === 'tc' ? item.name_zh : item.name}
                      </div>
                    }
                  />
                  <div className="item_detail">
                    <div className="item_price">{item.priceAry.length === 1 && `$${item.price}`}</div>
                    <div className="selector">
                      <FormSelect
                        control={control}
                        data={item.quotaOptions}
                        inputLabel={''}
                        selectLabel={''}
                        name={`item.${index}.quantity`}
                        size="small"
                      />
                    </div>

                    <div className="item_total">
                      {item.priceAry[item.quotaAry.findIndex((i) => i === item.quantity.toString())]
                        ? item.priceAry[item.quotaAry.findIndex((i) => i === item.quantity.toString())]
                        : 0}
                    </div>
                  </div>
                </CartInfoItem>
              ))}
          </div>
          <TotalWrapper>
            <span className="total">{t('total')}</span> {t('pages.payment.currency.HKD')}
            <span>{total}</span>
          </TotalWrapper>
        </CartInfoWrapper>
        <MultiInputWrapper>
          <Controller
            control={control}
            name={'acc_coname'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={companyNameRegister}
          />
          <FormSelect
            control={control}
            data={useCountryDetail('name')}
            inputLabel={t('form.region.label')}
            selectLabel={'Region'}
            name={'acc_country'}
            register={regionRegister}
          />
        </MultiInputWrapper>

        <InputWrapper>
          <Controller
            control={control}
            name={'acc_BR'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.businessRegistrationNumber.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={businessRegister}
          />
        </InputWrapper>

        <InputWrapper>
          <Controller
            control={control}
            name={'acc_address'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyAddress.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={inputRegister}
          />
        </InputWrapper>

        <InputWrapper>
          <Controller
            control={control}
            name={'com_email'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyEmail.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={emailRegister}
          />
        </InputWrapper>

        <MultiInputWrapper>
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_firstName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.giveName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={giveNameRegister}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_lastName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.familyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginLeft: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={familyNameRegister}
          />
        </MultiInputWrapper>
        <Button size={'lg'} variant={'primary'} rounded={false} marginRight={40} disabled={submitController}>
          {t('buttons.next')}
        </Button>
      </FormWrapper>
    </PageContainer>
  );
};

export default Cart;

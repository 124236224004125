import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import PageContainer from '@/components/Container/PageContainer';
import { Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import { Check } from '@/components/Icon';
import { useAppSelector } from '@/redux/store.hook';
import { getCartSelector } from '@/redux/cart/cart.slice';
import { theme } from '@/theme/Theme';
import useUserDetail from '@/hooks/useUserDetail';
import { useDispatch } from 'react-redux';
import { getAuthSelector, update } from '@/redux/auth/auth.slice';
import { sendCertApplyOrder } from '@/api/eCert.api';
import { submitLEI } from '@/api/lei.api';
import { errorHandler } from '@/utils/toastHandler';

const Text = styled.p`
  font-size: ${(p) => p.theme.fontSizes.md};
  color: ${(p) => p.theme.colors.gray300};
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 930px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.colors.light};
  border-radius: 15px;
`;

const CheckoutSuccess = ({ type }: { type: string }) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const cart = useAppSelector(getCartSelector);
  const auth = useAppSelector(getAuthSelector);
  const userDetail = useUserDetail({});
  const dispatch = useDispatch();

  // 進入此頁時, 更新user資料
  useEffect(() => {
    if (!auth.isLogin) return;
    if (!userDetail) return;
    dispatch(update({ ...auth, userData: userDetail }));
  }, [userDetail]);

  useEffect(() => {
    checkItem();
  }, []);

  const checkItem = async () => {
    if (type === 'cancel') return;
    // 若購物車的id為14, 代表eCert申請, 自動打出申請
    if (cart.itemData.id === '14') {
      try {
        setIsLoading(true);
        const res = (await sendCertApplyOrder({ applyId: cart.itemData.applyId })).data;
        if (res.success) {
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        // 設定10秒後關閉loading
        setTimeout(() => {
          setIsLoading(false);
        }, 10000);
      }
    }

    // 若購物車的id為15, 代表LEI申請, 自動打出申請
    if (cart.itemData.id === '15' && userDetail?.user_feature?.LEIApplicantTimes !== 0) {
      try {
        setIsLoading(true);
        const res = (await submitLEI()).data;
        if (res.success) {
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        // 設定10秒後關閉loading
        setTimeout(() => {
          setIsLoading(false);
        }, 10000);
      }
    }
  };

  return (
    <PageContainer title={type === 'success' ? t('paymentDone') : t('paymentCancel')}>
      <LoadingPage isLoading={isLoading} />

      <FormWrapper>
        <Flex flexDirection={'column'} gridGap={15} alignItems={'center'}>
          {type === 'success' ? (
            <Check.CheckSVG fill={theme.colors.success} width="96" height="96" />
          ) : (
            <Check.CancelSVG fill={theme.colors.danger} width="96" height="96" />
          )}
          <Text>{type === 'success' ? t('paymentSuccess') : t('paymentCancel')}</Text>
        </Flex>
        <Flex gridGap={20} justifyContent={'center'} marginTop={50}>
          <Link to={'/home'}>
            <Button size={'lg'} variant={'primary'} rounded={false} width={250}>
              {t('buttons.home')}
            </Button>
          </Link>
          <Link to={`${cart.returnURL}`}>
            <Button size={'lg'} variant={'primary'} rounded={false} width={250}>
              {t('buttons.backToPreviousStep')}
            </Button>
          </Link>
        </Flex>
      </FormWrapper>
    </PageContainer>
  );
};

export default CheckoutSuccess;

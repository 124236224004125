import styled from 'styled-components';

import { Box, box } from './Box';

const Card = styled(Box)`
  width: 100%;
  padding: 25px;
  ${(p) => p.theme.outsideShadowW02}
`;

const CardContainer = styled(Box)`
  width: 100%;
  padding: 30px 50px;
  border-radius: 15px;
  background-color: ${(p) => p.theme.colors.light};
  ${box}
`;

export { Card, CardContainer };

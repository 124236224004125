import { useEffect } from 'react';
import { Card, Total } from './style';
import { Link } from 'react-router-dom';
import gradeGenerator from '@/utils/gradeGenerator';
import { Flex } from '@/layout';
import { useTranslation } from 'react-i18next';

interface CommentsProps {
  data: CommentResponse[];
}

const Comments = ({ data }: CommentsProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <>
      <Total></Total>
      {data.map((item) => (
        <Card key={item.id}>
          <div className="dealName">{item.deal_name}</div>
          <div className="detail">
            <Flex>
              <div className="role">{item.rating_user_role === '0' ? 'Buyer' : 'Seller'}</div>
              <Link className="content" to={`/eDirectory/${item.acc_id}`}>
                {item.rating_user_name}
              </Link>
            </Flex>
            <Flex>
              <div className="title">{t('pages.home.comment.fraction')}</div>
              <div className="content">
                {gradeGenerator(item.score).map((item, index) => (
                  <img width="33px" key={index} src={item} alt="grade" />
                ))}
              </div>
            </Flex>
            <Flex>
              <div className="title">{t('pages.home.comment.date')}</div>
              <div className="content">{new Date(item.created_at).toLocaleDateString('zh-TW')}</div>
            </Flex>
            <Flex>
              <div className="title">{t('pages.home.comment.comment')}</div>
              <div className="content">{item.comment}</div>
            </Flex>
          </div>
        </Card>
      ))}
    </>
  );
};

export default Comments;

import styled from 'styled-components';

const Main = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  max-width: ${(p) => (p.isTablet ? '100%' : '1200px')};
  margin: auto;
  display: flex;
  gap: ${(p) => (p.isTablet ? '0' : '40px')};
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  justify-content: center;
`;

const InformationWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: ${(p) => (p.isTablet ? '100%' : '60%')};
  white-space: pre-wrap;
  background: ${(p) => p.theme.colors.light};
  ${(p) => p.theme.outsideShadowW02};
  border-radius: 15px 15px 15px 0;
  padding: 25px;
`;

const ProfileWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: ${(p) => (p.isTablet ? '100%' : '40%')};
  max-width: 460px;
  height: min-content;
  margin: 0 auto;
  background: ${(p) => p.theme.colors.light};
  ${(p) => p.theme.outsideShadowW02};
  border-radius: 15px 15px 15px 0;
  padding: 25px;
`;

const ImgBox = styled.div`
  width: 100%;
  height: 450px;
`;

const Desc = styled.p`
  margin: 50px 0;
  color: ${(p) => p.theme.colors.gray500};
  font-family: 'Roboto';
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 20px;
  text-align: justify;
`;

const ProductSerivce = styled.div`
  color: ${(p) => p.theme.colors.gray300};
  font-family: 'Roboto';
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 16px;
`;

const CompanyDetail = styled.div`
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid ${(p) => p.theme.colors.gray100};

  .logoBox {
    margin-bottom: 5px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .info {
    display: flex;
    margin-top: 15px;

    h4:first-child {
      width: 155px;
      flex-shrink: 0;
      color: ${(p) => p.theme.colors.gray300};
    }
    h4:last-child {
      flex: 1;
      color: ${(p) => p.theme.colors.gray500};

      span {
        display: block;
        margin-top: 10px;
        color: ${(p) => p.theme.colors.primary400};

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`;

const Hr = styled.hr`
  border: 1px solid ${(p) => p.theme.colors.gray100};
  margin: 15px 0;
`;

const DetailWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  gap: 10px;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.sm : p.theme.fontSizes.base)};

  .title {
    width: ${(p) => (p.isMobile ? '130px' : '200px')};
    color: ${(p) => p.theme.colors.gray300};
  }

  .info {
    flex: 1;
    color: ${(p) => p.theme.colors.gray500};
  }
`;

const InfoTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 700;
  color: ${(p) => p.theme.colors.primary500};
  margin-bottom: 15px;
`;

export {
  Main,
  InformationWrapper,
  ProfileWrapper,
  ImgBox,
  Desc,
  ProductSerivce,
  CompanyDetail,
  Hr,
  DetailWrapper,
  InfoTitle,
};

import styled from 'styled-components';

const FormWrapper = styled.form`
  width: 100%;
  max-width: 930px;
  padding: 30px 50px;
  margin-bottom: 100px;
  background: #ffffff;
  border-radius: 15px;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;

  > div input[type='date'] {
    width: 150px;
  }
`;
const CopyIconWarpper = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

export { FormWrapper, InputGroupWrapper, CopyIconWarpper };

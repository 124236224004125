import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormStep1, FormStep2 } from './FromStepper';
import AuthContainer from '@/components/Container/AuthContainer';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

const SignUpPage = () => {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLogin, userData } = useAppSelector(getAuthSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeStep, setActiveStep] = useState<number>(1);
  const [userMail, setUserMail] = useState<string>('');

  useEffect(() => {
    if (isLogin && userData.acc_coname) {
      navigate('/home');
    }

    if (searchParams.get('lang')) {
      // 驗證語系是否在支援的語系內, 若不在則預設為i18n的語系
      const lang = ['sc', 'tc', 'en'].includes(searchParams.get('lang') as string)
        ? searchParams.get('lang')
        : i18n.language;
      i18n.changeLanguage(lang!);
    }
  },[]);

  return (
    <AuthContainer title={activeStep === 2 ? t('multiFactorAuthentication') : t('registration')}>
      {activeStep === 1 && <FormStep1 setActiveStep={setActiveStep} setUserMail={setUserMail} />}
      {activeStep === 2 && <FormStep2 userMail={userMail} />}
    </AuthContainer>
  );
};

export default SignUpPage;

import type { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useAuthValidation = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const emailRegister: RegisterOptions = {
    required: t('required.email'),
    pattern: {
      value: /^[a-zA-Z0-9_-].+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]{2,}/,
      message: t('pattern.email'),
    },
    maxLength: {
      value: 100,
      message: t('required.maxLength', { input: t('form.email.label'), length: 100 }),
    },
  };

  const passwordRegister: RegisterOptions = {
    required: t('required.password'),
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[`~!@#$%^&*()_\-+=<>.?:/|;'"{},\[\]].*).{9,20}$/,
      message: t('pattern.password'),
    },
  };

  const verificationCode: RegisterOptions = {
    required: t('required.verificationCode'),
    pattern: {
      value: /^[a-zA-Z0-9_-]{6}$/,
      message: t('pattern.verificationCode'),
    },
  };

  const mobileRegister: RegisterOptions = {
    required: t('required.mobile'),
    pattern: {
      value: /^(09)[0-9]{8}$/,
      message: t('pattern.mobile'),
    },
  };

  const inputRegister: RegisterOptions = {
    required: t('required.feild'),
  };

  const tokenRegister: RegisterOptions = {
    required: t('required.token'),
  };

  return {
    emailRegister,
    passwordRegister,
    verificationCode,
    mobileRegister,
    inputRegister,
    tokenRegister,
  };
};

export default useAuthValidation;

import { Transcribe } from '@/api/document.api';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { transcribe } from '@/data/select';
import { Box } from '@/layout';
import { convertToSelectData } from '@/utils/convert';
import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ModalForm, ModalTitle, ModalNotice } from '../style';
import { progressingHandler, doneHandler } from '@/utils/toastHandler';
import { Close } from '@/components/Icon';

interface TranscribeProps {
  document_id: string;
  show: boolean;
  setShowTranscribe: (show: boolean) => void;
  getDocuments: () => void;
}

const TranscribeModal = ({ document_id, show, setShowTranscribe, getDocuments }: TranscribeProps) => {
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<{ locale: string }>();

  const onSubmit = handleSubmit(async (data) => {
    const toastId = progressingHandler('Transcribing...');
    setShowTranscribe(false);
    try {
      const res = (await Transcribe({ document_id: document_id, locale: data.locale })).data;
      if (res.success) {
        getDocuments();
        setValue('locale', '');
        doneHandler(toastId, 'success', 'Transcribing done!');
      }
    } catch (error: any) {
      doneHandler(toastId, 'error', 'Transcribing failed...');
    }
  });

  return (
    <Modal
      open={show}
      onClose={() => setShowTranscribe(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm onSubmit={onSubmit}>
        <Close.ButtonClose
          onClick={() => {
            setShowTranscribe(false);
          }}
        >
          <Close.CloseSVG />
        </Close.ButtonClose>
        <ModalTitle>Transcribe file</ModalTitle>
        <ModalNotice>Available translation(s): 3</ModalNotice>
        <FormSelect
          control={control}
          data={convertToSelectData(transcribe)}
          inputLabel={'Language'}
          selectLabel={'Language'}
          name={'locale'}
        />
        <Box style={{ marginTop: '28px', display: 'flex', alignItems: 'center' }}>
          <Button size={'lg'} variant={'primary'} rounded={false} marginRight={40} disabled={!isDirty}>
            Transcribe
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setShowTranscribe(false)}
            style={{ textDecoration: 'underline' }}
          >
            Back
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

export default TranscribeModal;

import { useEffect, useState } from 'react';
import { MobileStepper, Step, StepLabel, Stepper } from '@mui/material';
import { CompanyInfoLine, PersonalInfoLine } from '@/components/RegistrationStepLine/StepLine';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useResize from '@/hooks/useResize';
import styled from 'styled-components';

interface StepLineProps {
  activeStep: number;
  stepsLine?: string[];
}

const StyledMobileStepper = styled(MobileStepper)`
  > .MuiMobileStepper-dots {
    width: 100%;
    max-width: 228px;
    magin: 0 auto;
    gap: 40px;
    padding: 40px 0;

    .MuiMobileStepper-dot {
      width: 10px;
      height: 10px;
    }
  }
`;

const RegistrationStepLine = ({ stepsLine = [], activeStep }: StepLineProps) => {
  const auth = useAppSelector(getAuthSelector);
  const [line, setLine] = useState(stepsLine);
  const { isTablet } = useResize();

  useEffect(() => {
    if (stepsLine.length === 0) {
      if (auth.userData.role === 1) {
        setLine(CompanyInfoLine);
      } else {
        setLine(PersonalInfoLine);
      }
    } else {
      setLine(stepsLine);
    }
  }, [stepsLine]);

  return (
    <>
      {isTablet ? (
        // <StyledMobileStepper
        //   variant="dots"
        //   steps={line.length}
        //   position="static"
        //   activeStep={activeStep}
        //   sx={{ flexGrow: 1, background: 'transparent' }}
        //   nextButton={<div></div>}
        //   backButton={<div></div>}
        // />
        <Stepper
          activeStep={activeStep}
          orientation="horizontal"
          style={{ padding: '40px 0', margin: 'auto', width: '100%', maxWidth: 300, height: 'min-content' }}
        >
          {line.map((item: string) => (
            <Step key={item}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Stepper activeStep={activeStep} orientation="vertical" style={{ paddingRight: '20px', height: 'min-content' }}>
          {line.map((item: string) => (
            <Step key={item}>
              <StepLabel>{item}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </>
  );
};
export default RegistrationStepLine;

import styled from 'styled-components';


const Flex = styled.div`
  display: flex;
  gap: 28px;
`

const ContainerCalendar = styled.div`
  @media (max-width: 768px) {
    .fc-toolbar.fc-header-toolbar {
      display: flex;
      flex-direction: column;
    }
  }
`;

const SideBarList = styled.div`
  flex-shrink: 0;
  width: 250px;
  height: 800px;
  overflow-y: auto;

  > .search {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 15px;
    display: flex;
    position: relative;
    background: ${(p) => p.theme.colors.light};
    border: 1px solid ${(p) => p.theme.colors.primary100};
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
  }

  > .search input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
  }

  > .search button.icon {
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    cursor: pointer;
  }

  > .list {
    height: calc(100% - 46px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color: ${(p) => p.theme.colors.gray500};
    background: ${(p) => p.theme.colors.light};
  }

  > .list .title {
    padding: 15px 20px;
    font-size: ${(p) => p.theme.fontSizes.md};
    line-height: 23px;
  }
`

const EventCard = styled.div`
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.gray500};
  cursor: pointer;

  &:nth-child(3n+1) {
    > .head {
      background: ${(p) => p.theme.colors.secondary400};
    }

    > .event {
      background: rgba(164,210,51,.1);
    }
  }

  &:nth-child(3n+2) {
    > .head {
      background: ${(p) => p.theme.colors.purple};
    }

    > .event {
      background: rgba(164,122,226,.1);
    }
  }

  &:nth-child(3n+3) {
    > .head {
      background: ${(p) => p.theme.colors.orange};
    }

    > .event {
      background: rgba(255,109,0,.1);
    }
  }

  > .head {
    width: 5px;
  }

  > .event {
    width: 100%;
    padding: 10px;
  }

  > .event .event-name {
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
  }
  
  > .event .event-datetime {
    margin-top: 7px;
    display: flex;
    gap: 10px;
    font-size: ${(p) => p.theme.fontSizes.sm};
    line-height: 19px;
    color: ${(p) => p.theme.colors.gray400}
  }
`

export {Flex, ContainerCalendar, SideBarList, EventCard};
// 驗證檔案副檔名, 禁止陣列中的副檔名
const bannedExtensions = [
  'BAT',
  'BIN',
  'CMD',
  'COM',
  'CPL',
  'EXE',
  'GADGET',
  'INF1',
  'INS',
  'INX',
  'ISU',
  'JOB',
  'JSE',
  'LNK',
  'MSC',
  'MSI',
  'MSP',
  'MST',
  'PAF',
  'PIF',
  'PS1',
  'REG',
  'RGS',
  'SCR',
  'SCT',
  'SHB',
  'SHS',
  'U3P',
  'VB',
  'VBE',
  'VBS',
  'VBSCRIPT',
  'WS',
  'WSF',
  'WSH',
];

export const extensionValidator = (file: File | File[]): boolean => {
  // 如果是陣列, 則檢查陣列中的每個檔案, 有一個不符合就回傳 false
  if (Array.isArray(file)) {
    return file.every((f) => extensionValidator(f));
  }
  const extension = file.name.split('.').pop()?.toUpperCase();

  return !bannedExtensions.includes(extension as string);
};

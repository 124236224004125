import styled from 'styled-components';
import { FlexProps, flex } from 'styled-system';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  padding: 25px;
  ${(p) => p.theme.outsideShadowW02}
`;

const Photo = styled.img`
  width: 150px;
  height: 100%;
  flex-shrink: 0;
  object-fit: contain;
  object-position: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 371px;
  flex-shrink: 0;
  h2 {
    font-size: ${(p) => p.theme.fontSizes.lg};
    font-weight: 700;
    margin-bottom: 17px;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 7px;
    &.tip {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      margin-bottom: 0;
      color: ${(p) => p.theme.colors.gray400};
      -webkit-line-clamp: 1;
      vertical-align: bottom;
    }
  }
`;

const Detail = styled.div<FlexProps>`
  ${flex}
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: ${(p) => p.theme.fontSizes.base};
    font-weight: 400;
    color: ${(p) => p.theme.colors.gray400};
  }
`;

export { Wrapper, Photo, Content, Detail };

import styled from 'styled-components';

const ChatWrapper = styled.div`
  width: 100%;
  background: ${(p) => p.theme.colors.light};
  padding: 20px 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px 15px 15px 0px;
`;

const ChatRoomItem = styled.div`
  padding: 0 25px;
  cursor: pointer;
  position: relative;

  .bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 10px;
  }

  .history {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid ${(p) => p.theme.colors.gray100};

    .fakeImg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: ${(p) => p.theme.colors.primary500};
      flex-shrink: 0;
    }

    .content {
      width: calc(100% - 65px);

      &.new {
        width: calc(100% - 105px);
      }

      & + .counter {
        padding: 3px;
        border-radius: 50%;
        background: ${(p) => p.theme.colors.red};
        color: ${(p) => p.theme.colors.light};
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
      }

      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:hover {
    background: rgba(182, 219, 92, 0.3);

    .bar {
      background: ${(p) => p.theme.colors.secondary400};
    }
  }
`;

const ChatContent = styled.div`
  padding: 20px 0;
  margin: 15px 25px;
  border-top: 1px solid ${(p) => p.theme.colors.gray100};
  overflow: auto;
  overflow-x: hidden;
  height: 655px;
`;

const OppositeMassage = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;

  .message {
    width: 350px;
    background: rgba(109, 131, 196, 0.1);
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0;
  }
`;

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SelfMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  .message {
    width: 350px;
    background: ${(p) => p.theme.colors.primary500};
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0;
  }
`;

const SendBtn = styled.button`
  height: min-content;
  padding: 3px 5px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.primary500};
  cursor: pointer;
`;

const SelfMessageLink = styled.a`
  color: ${(p) => p.theme.colors.light};
  text-decoration: underline;

  &:hover {
    color: ${(p) => p.theme.colors.secondary200};
  }
`;

const OppositeMessageLink = styled.a`
  color: ${(p) => p.theme.colors.gray500};
  text-decoration: underline;

  &:hover {
    color: ${(p) => p.theme.colors.primary200};
  }
`;

export {
  ChatWrapper,
  ChatRoomItem,
  ChatContent,
  OppositeMassage,
  SelfMessage,
  Avatar,
  SendBtn,
  SelfMessageLink,
  OppositeMessageLink,
};

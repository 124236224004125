import { useMemo, useCallback, useEffect, useState } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import TranscribeModal from './Transcribe';
import { docTypes } from './allowedFileTypes';

import DataTable, { TypeColumn } from '@/components/DataTable';
import { CardContainer } from '@/layout/Card';
import { Flex, LoadingPage } from '@/layout';
import Loading from '@/components/UI/Loading';

import { convertToFileSize, convertToData, convertLink } from '@/utils/convert';
import { useNavigate } from 'react-router-dom';
import TranslateModal from './Translate';
import DeleteConfirm from './DeleteConfirm';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { NeedUpgradePlanModal } from '../Modal';
import { Delete, Download, Translate } from '@/components/Icon';
import { theme } from '@/theme/Theme';
import { Button } from '@/components/UI/Button';

type DataRow = {
  id: string;
  name: string;
  file_id: string;
  type: string;
  size: string;
  path: string;
  updated: string;
  action: string;
  added: boolean;
};
interface FilesProps {
  data: DocFile[];
  getDocuments: () => Promise<void>;
}

const DocumentList = ({ data, getDocuments }: FilesProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tabLabel = t('pages.dealDetail.manageFiles.myFolder.table.columns', { returnObjects: true });

  const [docLibrary, setDocLibrary] = useState<DataRow[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [currentDocumentId, setCurrentDocumentId] = useState<string>('');
  const [showTranscribe, setShowTranscribe] = useState<boolean>(false);
  const [showTranslate, setShowTranslate] = useState<boolean>(false);
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const { userData } = useAppSelector(getAuthSelector);

  const initData = useCallback(async (fileAry: DocFile[]) => {
    if (!fileAry) return;
    try {
      const doc: DataRow[] = fileAry.map(
        (data): DataRow => ({
          id: data.id,
          name: data.file.name,
          file_id: data.file_id,
          type: data.file.extension,
          path: data.file.path,
          size: convertToFileSize(Number(data.file.size)),
          updated: convertToData(data.file.updated_at),
          action: data.file.path,
          added: false,
        })
      );
      setDocLibrary(doc);
      setIsLoading(false);
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData(data);
  }, [initData, data]);

  const columns: ColumnDef<DataRow>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: tabLabel[0],
      },
      {
        accessorKey: 'type',
        header: tabLabel[1],
        cell: ({ row }) => <TypeColumn text={row.original.type} />,
      },
      {
        accessorKey: 'size',
        header: tabLabel[2],
      },
      {
        accessorKey: 'updated',
        header: tabLabel[3],
      },
      {
        accessorKey: 'action',
        header: tabLabel[4],
        cell: ({ row }) => {
          const { id, type, path } = row.original;
          //   let added = hasMatchingProps(docLibrary, fileList, 'id', 'document_library_id');
          return (
            <Flex gridGap={10}>
              {docTypes.includes(type) && (
                <Button
                  size="sm"
                  type="button"
                  onClick={async () => {
                    if (!id) return;
                    if (userData.plan.level === null) {
                      setShowUpgradeModal(true);
                      return;
                    }
                    setCurrentDocumentId(id);
                    setShowTranslate(true);
                  }}
                >
                  <Translate.TranslateSVG />
                </Button>
              )}
              <Button
                size="sm"
                type="button"
                onClick={() => {
                  if (!path) return;
                  const link = document.createElement('a');
                  link.setAttribute('download', '');
                  link.href = convertLink(path);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }}
              >
                <Download.DownloadSVG />
              </Button>
              <Button
                size="sm"
                type="button"
                onClick={() => {
                  if (!id) return;
                  setCurrentDocumentId(id);
                  setDeleteModal(true);
                }}
              >
                <Delete.DeleteSVG fill={theme.colors.primary500} />
              </Button>
            </Flex>
          );
        },
      },
    ],
    [t, tabLabel]
  );

  return (
    <CardContainer>
      <LoadingPage isLoading={isLoading} />
      <NeedUpgradePlanModal showModal={showUpgradeModal} setShowModal={setShowUpgradeModal} />
      <DeleteConfirm
        show={showDeleteModal}
        document_id={currentDocumentId}
        setDeleteModal={setDeleteModal}
        getDocuments={getDocuments}
      />
      <TranscribeModal
        show={showTranscribe}
        document_id={currentDocumentId}
        setShowTranscribe={setShowTranscribe}
        getDocuments={getDocuments}
      />
      <TranslateModal
        show={showTranslate}
        document_id={currentDocumentId}
        setShowTranslate={setShowTranslate}
        getDocuments={getDocuments}
      />
      {docLibrary ? <DataTable columns={columns} data={docLibrary} canSearch /> : <Loading />}
    </CardContainer>
  );
};

export default DocumentList;

import { useRef, useEffect, useState } from 'react';
import { init, getInstanceByDom } from 'echarts';
// import type { CSSProperties } from 'react';
// import type { EChartsOption, ECharts, SetOptionOpts } from 'echarts';
import * as echarts from 'echarts/core';
import { MapChart } from 'echarts/charts';
import { chinaMapConfig } from "./config";
import { geoJson } from "./geoJson";
import { geoJson_EN } from './geoJson_en';
import { geoJson_TC } from './geoJson_tc';
import { useTranslation } from 'react-i18next';
import useCurrentLang from '@/hooks/useCurrentLang';

const Map = ({title, changeSelected, provinceList}) => {
  const {t} = useTranslation();
  const lang = useCurrentLang();
  const provinceCheckboxList = t('mapOptions.province', {returnObjects: true});
  const ref = useRef(null);
  let mapInstance = null;

  const renderMap = () => {
    const renderedMapInstance = echarts.getInstanceByDom(ref.current);
    if (renderedMapInstance) {
      mapInstance = renderedMapInstance;
    } else {
      mapInstance = echarts.init(ref.current);
    }
    mapInstance.setOption(
      chinaMapConfig({ data: provinceCheckboxList.data, max: 1, min: 0, title: title, mapData: provinceList })
    );
    
    mapInstance.on('click', function (params) {
      changeSelected(params.name, provinceList);
    });
  };

  useEffect(() => {
    echarts.registerMap("china", { geoJSON: lang === 'en' ? geoJson_EN : lang === 'tc' ? geoJson_TC :geoJson });
    renderMap();
  }, [title, lang, t]);

  useEffect(()=>{
    const renderedMapInstance = echarts.getInstanceByDom(ref.current);

    // 過濾出有被selected = true的省份
    const selectedProvince = provinceList.filter((item) => item.selected === true);
    if(provinceList.length > 0) {
      // 先將所有省份的selected設為false
      renderedMapInstance.dispatchAction({
        type: 'unselect',
        name: provinceList.map((item) => item.text)
      });
      
      if(selectedProvince) {
        renderedMapInstance.dispatchAction({
          type: 'select',
          name: selectedProvince.map((item) => item.text)
        });
      }
    }
  },[provinceList])

  useEffect(() => {
    window.onresize = function () {
      mapInstance.resize();
    };
    return () => {
      mapInstance && mapInstance.dispose();
    };
  }, [lang, t]);

  return (
    <div style={{ width: "100%", height: "70vh" }} ref={ref}></div>
  );
};

export default Map;

import styled from 'styled-components';
import { theme } from '@/theme/Theme';

const NumberCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 180px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.colors.primary500};
  border-radius: 10px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  button {
    width: 100%;
    max-width: 40px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary500};
    padding: 12px 0;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: 700;

    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.primary500};
      &:hover {
        border-radius: 10px 0 0 10px;
        background-color: ${({ theme }) => theme.colors.primary500};
        color: ${({ theme }) => theme.colors.light};
      }
    }

    &:last-child {
      border-left: 1px solid ${({ theme }) => theme.colors.primary500};
      &:hover {
        border-radius: 0 10px 10px 0;
        background-color: ${({ theme }) => theme.colors.primary500};
        color: ${({ theme }) => theme.colors.light};
      }
    }
  }
`;

export { NumberCountWrapper };

import { useState, useEffect } from 'react';

import { getUserProgress } from '@/api/auth.api';

const useUserBadges = () => {
  const [progressStatus, setProgressStatus] = useState<BadgeGroup>({
    company: '0',
    supplemental: '0',
    person: '0',
    eCert: '0',
    creditReport: '0',
    identification: '0',
    accountVerified: '0',
  });

  useEffect(() => {
    const getProgress = async () => {
      const res = (await getUserProgress()).data;

      try {
        setProgressStatus(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProgress();
  }, []);

  return progressStatus;
};

export default useUserBadges;

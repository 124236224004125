import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import store, { RootState, AppDispatch } from '../store';
import { addUnreadCounter } from '../auth/auth.slice';

const initialState: NotificationEvents = {
  notify: [],
  connecting: false,
};

export const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNotification(state, action: PayloadAction<WSNotification[]>) {
      // 若已在登入狀態，則將notify清空並重新填入資料
      if (state.connecting) {
        state.notify = []
      }
      // 將type等於1和90的資料進行移除
      state.notify = action.payload.filter((item) => item.type !== '1' && item.type !== '90');
      state.connecting = true;
    },
    addNotification(state, action: PayloadAction<WSNotification>) {
      // 檢查是否有重複的資料
      if (action.payload.type === '1') {
        const messageNotifi = state.notify.filter((item) => item.type === '1');
        if (messageNotifi.length > 0 && messageNotifi[0].id === action.payload.id) {
          return;
        }
      }
      // 將新的資料放在陣列的最前面
      state.notify = [{ ...action.payload, status: 1 }, ...state.notify];
    },
    updateNotification(state, action: PayloadAction<number>) {
      state.notify = state.notify.filter((item) => item.id !== action.payload);
    },
    changeNotificationStatus(state, action: PayloadAction<number>) {
      // 找出對應id的資料，並將其status設為已讀(2)
      const index = state.notify.findIndex((item) => item.id === action.payload);
      state.notify[index].status = 2;
    },
    deleteNotification(state, action: PayloadAction<number[]>) {
      const newNotify = state.notify.filter((item) => !action.payload.includes(item.id));
      state.notify = newNotify;
    },
    disconnect(state) {
      state.connecting = false;
      state.notify = [];
    },
  },
});

export const {
  getNotification,
  addNotification,
  updateNotification,
  changeNotificationStatus,
  deleteNotification,
  disconnect,
} = notification.actions;

export const getNotificationSelector = (store: RootState) => store.notification;

export default notification.reducer;

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { SpaceProps, WidthProps } from 'styled-system';

import { ButtonSize, ButtonVariant, StyledButton, ButtonSpecial } from './styles';

export type ButtonProps = ComponentPropsWithoutRef<'button'> &
  SpaceProps &
  WidthProps & {
    size?: ButtonSize;
    special?: ButtonSpecial;
    variant?: ButtonVariant;
    rounded?: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
  };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, size = 'md', special = 'normal', icon, ...props },
  ref
) {
  return (
    <StyledButton ref={ref} size={size} special={special} hasIcon={Boolean(icon)} {...props}>
      {children}
      {icon}
    </StyledButton>
  );
});

export { Button };

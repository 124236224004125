import { useTranslation } from 'react-i18next';
import { PriceWrapper, Wrapper, BlockWrapper, RegistrationWrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Text, Ul } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormTitle } from '@/components/Container/AuthContainer/styles';
import { theme } from '@/theme/Theme';
import { H5 } from '@/components/UI/Typography/Heading';
import usePermissionCheck from '@/hooks/usePermissionCheck';
import { errorHandler } from '@/utils/toastHandler';
import { useEffect } from 'react';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  checkLevel: () => boolean;
}

const Introduction = ({ checkLevel, setCurrentStep, setActiveStep }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [seatchParmas, setSearchParams] = useSearchParams();
  const { isMainAccount } = usePermissionCheck();

  const toRenewal = () => {
    nextHandler('renewal');
  };

  const toNewApplication = () => {
    nextHandler('new');
  };

  const nextHandler = (type: 'renewal' | 'new') => {
    if (!checkLevel()) return;
    setCurrentStep('2');
    setActiveStep(1);
    setSearchParams({ step: '2', type: type });
  };

  useEffect(() => {}, [t]);

  return (
    <Wrapper>
      <Text fontSize={theme.fontSizes.lg} marginBottom={20}>
        {t('pages.eTrust.eCert.steps.step1.title')}
      </Text>
      <H5 marginBottom={50}>{t('pages.eTrust.eCert.steps.step1.intro')}</H5>
      <RegistrationWrapper>
        <div className="content">
          <Flex className="border" flexDirection={'column'}>
            <Box padding={24} textAlign={'center'}>
              <div>{t('pages.eTrust.eCert.steps.step1.registration.firstTime')}</div>
              <Button
                size={'lg'}
                type="button"
                variant={'primary'}
                marginRight={40}
                onClick={toNewApplication}
                style={{ borderRadius: 5, margin: '20px auto 0' }}
              >
                {t('buttons.newRegistration')}
              </Button>
            </Box>
            <Box padding={24} textAlign={'center'} borderTop={`1px solid ${theme.colors.gray300}`}>
              <div>{t('pages.eTrust.eCert.steps.step1.registration.existing')}</div>
              <Button
                size={'lg'}
                type="button"
                variant={'primary'}
                marginRight={40}
                onClick={toRenewal}
                style={{ borderRadius: 5, margin: '20px auto 0' }}
              >
                {t('buttons.renewal-eCert')}
              </Button>
            </Box>
          </Flex>
          <Flex className="border">
            <Box width={'100%'} padding={'20px 30px'} textAlign={'center'}>
              <img width={'85px'} src="/images/badge/ecert.svg" alt="" />
              <H5 marginTop={20} fontWeight={700} fontSize={theme.fontSizes.md} color={theme.colors.secondary500}>
                {t('pages.eTrust.eCert.steps.step1.badgeTitle')}
              </H5>
              <H5 marginTop={10} style={{ maxWidth: 305 }}>
                {t('pages.eTrust.eCert.steps.step1.badgeIntro')}
              </H5>
            </Box>
          </Flex>
        </div>
      </RegistrationWrapper>
    </Wrapper>
  );
};

export default Introduction;

import styled from 'styled-components';

const Wrapper = styled.form`
  width: 100%;
  max-width: 1230px;
  padding: 30px 50px;
  border-radius: 15px;
  background: #fff;
`;

const SearchIconWrapper = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  right: 0;
  top: 5px;
  cursor: pointer;
`;

const StatusCircle = styled.div<{status: string}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${p => p.status.toLowerCase() === 'active' || p.status.toLowerCase() === 'issued' ? p.theme.colors.lightgreen : p.theme.colors.danger};
`;

export {
  Wrapper,
  SearchIconWrapper,
  StatusCircle,
}
import { getDealType, getI18nDealType } from "@/api/type/dealType.api";

const dealType = async (lang: string) => {
	let options: SelectOptions = [];
  const currentLang = lang === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc';

  try {
    const res = (await getI18nDealType()).data;
    options = res.data[currentLang].map((item) => ({
      value: item.id,
      text: item.name,
    }));
  } catch (error) {}
  return options;
};

export default dealType;

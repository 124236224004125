import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ChatButton } from './styles';

import { Box, Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import DetailTitle from '@/components/DealDetail/Title';
import DealInfo from '@/components/DealDetail/Info';
import DealRelation from '@/components/DealDetail/Relation';
import PageContainer from '@/components/Container/PageContainer';
import ProgressItem from '@/components/DealList/DealListItem/ProgressItem';
import Loading from '@/components/UI/Loading';

import { getDealDetail, getExecutionList, InviteToSign, noneDMPUserInviteToSign } from '@/api/deal.api';
import { getDealChatroom } from '@/api/chat.api';
import { getEventList } from '@/api/event.api';
import { convertToRole, convertToBadgeLevel } from '@/utils/convert';
import generateExecutionDetail from '@/utils/executionGenerator';
import { dealTagStatusHandler } from '@/utils/dealTagStatusHandler';

import { Chat } from '@/components/Icon';
import { errorHandler, successHandler } from '@/utils/toastHandler';
import ExecutionEdit from '@/components/DealList/ExecutionEdit';
import Contract from '@/components/DealList/Contract';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, unreadCounterChange } from '@/redux/auth/auth.slice';
import VideoConferencingForm from '@/pages/Meeting/Form';
import SignList from '@/components/DealList/SignList';
import { useDispatch } from 'react-redux';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import {
  DealCancelModal,
  DealCancelRequestModal,
  DealChatModal,
  DealCompletionModal,
  DealCompletionRequestModal,
  DealRateModal,
} from '@/components/DealDetail/Modal/DealStatus';

const Detail = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;
  const dispatch = useDispatch();

  const [dealRowData, setDealRowData] = useState<DealDetail>();
  const [dealData, setDealData] = useState<DealListItem>();
  const [participantsData, setParticipantsData] = useState<DealRelation[]>();
  const [dealOwner, setDealOwner] = useState<boolean>(false);
  const [upcomingEventsData, setUpcomingEventsData] = useState<DealRelation[]>();
  const [filesData, setFilesData] = useState<DealRelation[]>();

  const [executionDetail, setExecutionDetail] = useState<string[]>([]);
  const [executionPass, setExecutionPass] = useState<boolean[]>([]);
  const [executionFiles, setExecutionFiles] = useState<ExecutionFile[]>([]);
  const [closePass, setClosePass] = useState<boolean[]>([]);

  const [showDealCancel, setShowDealCancel] = useState(false);
  const [showDealCancelRequest, setShowDealCancelRequest] = useState(false);
  const [showDealRate, setShowDealRate] = useState(false);
  const [showDealCompletion, setShowDealCompletion] = useState(false);
  const [showDealCompletionRequest, setShowDealCompletionRequest] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showNeedSignList, setNeedSignList] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [chatRoomData, setChatRoomData] = useState<ChatList>();
  const [isChinaFirst, setIsChinaFirst] = useState(false);
  const [haveGuest, setHaveGuest] = useState(false);

  const initData = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const res = (await getDealDetail(id)).data;

      // 檢測步驟是否正確
      if (res.data.progress.toString() === '0') {
        navigate(`/deal/${res.data.id}/details`, { replace: true });
      }
      const data: DealListItem = {
        id: res.data.id,
        tag: dealTagStatusHandler(parseInt(res.data.progress)),
        tradeName: res.data.name,
        count: {
          document: 0,
          participants: res.data.participants.length,
          events: 0,
        },
        participants: res.data.participants,
        dealInitiation: [res.data.progress === '0' ? false : true],
        negotiation: res.data.negotiation,
        deal_close: res.data.deal_close,
        contractExecution: [res.data.excution_file_complete],
        executions: res.data.executions,
        progress: res.data.progress,
        execution_file_complete: res.data.excution_file_complete,
        execution_file_complete2: res.data.excution_file_complete_2,
        deal_type: res.data.deal_type.name,
        category: res.data.category,
        rating_stage: res.data.rating_stage,
        cancel_trigger_id: res.data.cancel_trigger_id,
        deal_type_id: Number(res.data.deal_type_id),
      };

      const participants: DealRelation[] = res.data.participants.map((data) => {
        if (data.deal_role_id === res.data.owner_role_id) {
          setDealOwner(data.acc_id === userData.id ? true : false);
        }

        if (data.user.is_none_DMP_user) {
          setHaveGuest(true);
        }

        return {
          id: data.acc_id,
          name: data.user.acc_coname,
          tag: convertToRole(data.deal_role_id, 0),
          level: convertToBadgeLevel(data.user.verification),
          joined: data.joined,
          country: data.user.acc_country,
        };
      });

      // // 尋找參與者中是否有來自中國的參與者
      // res.data.participants.forEach((item) => {
      //   if (item.user.acc_country === 'China') {
      //     setIsChinaFirst(true);
      //   }
      // });

      // // 若isChinaFirst且有找到來自香港的參與者, 則繼續將setIsChinaFirst設為true, 反之則將其設為false
      // res.data.participants.forEach((item) => {
      //   if (item.user.acc_country === 'Hong Kong' && isChinaFirst) {
      //     setIsChinaFirst(true);
      //   } else {
      //     setIsChinaFirst(false);
      //   }
      // });

      const files: DealRelation[] = res.data.documents.map((data) => ({
        id: data.acc_id,
        name: data.file.name,
      }));

      const { executionAry, executionPassAry, closePassAry } = generateExecutionDetail(res.data.executions, 0);

      // if (res.data.acc_id.toString() === userData.id.toString()) {
      //   setIsAllow(true);
      // }

      setExecutionDetail(executionAry);
      setExecutionPass(executionPassAry);
      setClosePass(closePassAry);
      setParticipantsData(participants);
      setFilesData(files);
      setDealData(data);
      setDealRowData(res.data);

      //  若progress為99的話, 透過excution_file_complete和excution_file_complete2來判斷是甲方還是乙方
      if (res.data.progress === '99') {
        // 若為甲方(deal_owner)提出
        if (res.data.excution_file_complete && res.data.acc_id !== userData.id) {
          setShowDealCompletionRequest(true);
        }

        // 若為乙方提出
        if (res.data.excution_file_complete_2 && res.data.acc_id === userData.id) {
          setShowDealCompletionRequest(true);
        }
      }

      // 若取消deal的話, 透過cancel_trigger_id來是否為對方取消
      if (
        res.data.progress === '1' &&
        res.data.cancel_trigger_id &&
        userData.id.toString() !== res.data.cancel_trigger_id
      ) {
        setShowDealCancelRequest(true);
      }
    } catch (error) {
      console.log(error);
      errorHandler(t('error.dealInit'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getEvent = useCallback(async () => {
    try {
      const res = (await getEventList()).data;
      if (res.success) {
        const events = res.data.filter((data: EventFormFields) => {
          const currentTime = new Date();
          const eventTime = new Date(`${data.evt_startDate}`);

          return currentTime < eventTime;
        });

        const covertEvents: DealRelation[] = events.map((evt: EventFormFields) => ({
          name: evt.evt_name,
          tag: '',
          mark: true,
        }));

        setUpcomingEventsData(covertEvents);
      }
    } catch (error) {}
  }, []);

  const getExecutionFiles = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getExecutionList(id)).data;
      if (res.success) {
        setExecutionFiles(res.data);
      }
    } catch (error) {}
  }, []);

  const getDealChat = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getDealChatroom(id)).data;
      setChatRoomData(res.data);
    } catch (err) {}
  }, []);

  useEffect(() => {
    initData();
    getEvent();
    getExecutionFiles();
  }, []);

  // 每10秒更新一次getDealChat
  useEffect(() => {
    const interval = setInterval(() => {
      getDealChat();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const onComfirmComletion = async () => {
    if (!dealData) return;
    if (dealData.progress === '2' && dealData.rating_stage === '1') {
      setShowDealRate(true);
    } else if (dealData.progress === '1') {
      setShowDealCompletion(true);
    }
  };

  const sendInvite = async (documentId: string) => {
    if (!id) return false;
    try {
      setNeedSignList(false);
      if (haveGuest) {
        await noneDMPUserInviteToSign({ dealId: id, fileId: documentId });
      } else {
        await InviteToSign({ dealId: id, fileId: documentId });
      }
      setNeedSignList(true);
    } catch (error) {}
  };

  const directToEvent = () => {
    // 比對user的id找出對方的id
    const participant = participantsData?.find((item) => item.id !== userData.id);

    navigate(`/event/newEvent?deal=${id}&id=${participant?.id}`);
  };

  const showChatroomModal = () => {
    if (!chatRoomData) return;
    if (chatRoomData.unread_count > 0) {
      dispatch(unreadCounterChange(userData.unread_message_count - chatRoomData.unread_count));
    }
    setShowChat(true);
  };

  const getOppositeParticipantCompany = () => {
    if (!dealData) return '';
    const participant = dealData.participants.find((item) => item.acc_id !== userData.id);
    if (!participant) return '';
    return participant.company_name;
  };

  return dealData ? (
    <PageContainer title={<DetailTitle data={dealData} />}>
      <LoadingPage isLoading={isLoading} />

      {/* 取消Deal */}
      <DealCancelModal
        showModal={showDealCancel}
        setShowModal={setShowDealCancel}
        dealData={dealData}
        getOppositeParticipantCompany={getOppositeParticipantCompany}
      />
      <DealCancelRequestModal
        showModal={showDealCancelRequest}
        setShowModal={setShowDealCancelRequest}
        dealData={dealData}
        getOppositeParticipantCompany={getOppositeParticipantCompany}
      />

      {/* 評分 */}
      <DealRateModal
        showModal={showDealRate}
        setShowModal={setShowDealRate}
        dealData={dealData}
        dealRowData={dealRowData}
        setDealData={setDealData}
        setDealRowData={setDealRowData}
        initData={initData}
      />

      {/* 內頁 */}
      <Flex gridGap={'10px'} width={'100%'} marginBottom={'15px'}>
        {dealData.progress === '1' && (
          <Button type={'button'} onClick={() => directToEvent()} variant={'regular'}>
            {t('buttons.createMeeting')}
          </Button>
        )}

        {dealData.progress === '1' && userData.main_account_company_info.isMainAccount && (
          <Button
            type={'button'}
            onClick={() => setShowDealCancel(true)}
            variant={'danger-invert'}
            style={{ border: '1px solid #DC1010', backgroundColor: '#fff' }}
          >
            {t('buttons.cancelDeal')}
          </Button>
        )}
      </Flex>

      <Flex width={'100%'} gridGap={'15px'}>
        <Flex width={'100%'} maxWidth={1200} flexWrap={'wrap'} gridGap={'15px'}>
          <Flex overflow={'hidden'} width={'100%'}>
            {dealRowData ? <DealInfo haveGuest={haveGuest} dealDetail={dealRowData} /> : <Loading />}
          </Flex>

          <Flex backgroundColor={'#fff'} width={'100%'}></Flex>

          <SignList sendInvite={sendInvite} progress={dealData.progress} />

          {dealData.progress === '1' && showNeedSignList && (
            <Contract participantsData={participantsData} setNeedSignList={setNeedSignList} />
          )}

          {dealRowData && dealData.deal_close[0] && (
            <ExecutionEdit dealRowData={dealRowData} onComfirmComletion={onComfirmComletion} isAllow={true} />
          )}
        </Flex>
      </Flex>

      {/* 聊天室按鈕 */}
      {!chatRoomData && (
        <ChatButton className="disabled">
          <Chat.ChatWhiteSVG />
        </ChatButton>
      )}

      {chatRoomData && (
        <ChatButton
          className={chatRoomData && chatRoomData.unread_count > 0 ? 'active' : ''}
          onClick={() => showChatroomModal()}
        >
          {chatRoomData && chatRoomData.unread_count > 0 && (
            <div className="messageCounter">{chatRoomData.unread_count}</div>
          )}

          <Chat.ChatWhiteSVG />
        </ChatButton>
      )}

      {/* 聊天室 */}
      {chatRoomData && <DealChatModal showModal={showChat} setShowModal={setShowChat} chatRoomData={chatRoomData} />}

      {/* 完成交易 */}
      <DealCompletionModal
        showModal={showDealCompletion}
        setShowModal={setShowDealCompletion}
        dealData={dealData}
        dealRowData={dealRowData}
        setDealData={setDealData}
        setDealRowData={setDealRowData}
        initData={initData}
        getOppositeParticipantCompany={getOppositeParticipantCompany}
        haveGuest={haveGuest}
      />
      <DealCompletionRequestModal
        showModal={showDealCompletionRequest}
        setShowModal={setShowDealCompletionRequest}
        dealData={dealData}
        dealRowData={dealRowData}
        setDealData={setDealData}
        setDealRowData={setDealRowData}
        initData={initData}
        getOppositeParticipantCompany={getOppositeParticipantCompany}
        haveGuest={haveGuest}
      />
    </PageContainer>
  ) : (
    <Loading />
  );
};

export default Detail;

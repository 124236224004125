import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: 694px;
  padding: 30px 50px;
  margin-bottom: 100px;
  background: #ffffff;
  border-radius: 15px;
`;

export default Wrapper;

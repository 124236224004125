import styled from 'styled-components';

// const Wrapper = styled.div`
//   position: absolute;
//   top: 0;
//   z-index: 9;
//   width: 260px;
//   min-height: 100vh;
//   padding: 30px;
//   background-color: ${p => p.theme.colors.primary500};
//   > img {
//     margin: 0 auto;
//   }
// `;

const Wrapper = styled.div<{ isMobile: boolean }>`
  position: fixed;
  top: 0;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  width: ${(p) => (p.isMobile ? '100%' : '260px')};
  height: 100vh;
  min-height: 100%;
  overflow-y: auto;
  background: linear-gradient(180deg, #0f319d 51.56%, #4ebbc6 100%);
  color: ${(p) => p.theme.colors.light};

  > .MuiList-root > .css-bshv44-MuiButtonBase-root-MuiListItem-root {
    padding: 14px 35px;
  }
  > .MuiList-root > .css-bshv44-MuiButtonBase-root-MuiListItem-root:hover {
    background-color: ${(p) => p.theme.colors.primary300};
    position: relative;
  }
  > .MuiList-root > .css-bshv44-MuiButtonBase-root-MuiListItem-root:hover::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: ${(p) => p.theme.colors.secondary400};
  }
  > .MuiList-root.disabled {
    color: ${(p) => p.theme.colors.primary400};
  }

  > .MuiList-root .MuiDivider-light {
    border-color: ${(p) => p.theme.colors.light};
  }

  > .MuiList-root .item {
    display: block;
    padding: 20px 0;
    cursor: pointer;
    p {
      font-size: ${(p) => p.theme.fontSizes.base};
      text-align: center;
      color: ${(p) => p.theme.colors.light};
      &:hover {
        color: ${(p) => p.theme.colors.secondary400};
      }
    }
  }
`;

const CollapsWrapper = styled.div`
  > .MuiList-root > div {
    padding: 14px 60px;
  }

  > .MuiList-root > div:hover {
    background-color: ${(p) => p.theme.colors.primary300};
    position: relative;
  }

  > .MuiList-root > div:hover::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: ${(p) => p.theme.colors.secondary400};
  }
`;

const UnreadCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: ${(p) => p.theme.colors.light};
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.red};
  margin-left: 10px;
`;

export { Wrapper, CollapsWrapper, UnreadCounter };

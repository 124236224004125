import { IconWrapper, SearchInputWrapper } from './styles';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { Search } from '@/components/Icon';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface FormSearchProps {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  register?: RegisterOptions;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
}

const FormSearch = ({
  control,
  register,
  name,
  defaultValue = '',
  variant = 'outlined',
  size = 'small',
}: FormSearchProps) => {
  return (
    <SearchInputWrapper>
      <Controller
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl fullWidth variant={variant}>
            <TextField
              type="text"
              variant={variant}
              value={value}
              sx={{ background: '#FFF', borderRadius: '5px' }}
              onChange={onChange}
              error={!!error}
              placeholder="Search"
              size={size}
              autoComplete="off"
            />
            <IconWrapper>
              <img width={30} src={Search.SearchSVG_B} alt="searchSVG" />
            </IconWrapper>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
        name={name}
        rules={register}
      />
    </SearchInputWrapper>
  );
};

export default FormSearch;

import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  InputWrapper,
  MultiInputWrapper,
  FormTitle,
  InputFileWrapper,
  InputFileLabel,
} from '@/components/Container/AuthContainer/styles';
import { FormSelect } from '@/components/UI/Select';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Wrapper } from '../../styles';
import { ErrorMessage, Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { CloudUpload } from '@/components/Icon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCountryDetail from '@/hooks/useCountryDetail';
import TabsButton from '../../TabsButton';
import {
  convertLEIOptions,
  convertLegalFormCode,
  convertLegalFormLanguage,
  convertLocations,
  convertOptionCode,
  convertRAInformation,
} from '@/utils/convert';
import useLEIValidation from '@/hooks/requireSchema/useLEIValidation';
import { useTranslation } from 'react-i18next';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Flex } from '@/layout';

type LegalBusinessFormProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
} & TabFormRequire;

const LegalBusiness = ({
  setCurrentStep,
  setActiveStep,
  data,
  setData,
  userDetail,
  setSelectedTab,
  options,
}: LegalBusinessFormProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const [searchParmas, setSearchParams] = useSearchParams();
  const { legalForm, businessInfo } = useLEIValidation();
  const [showAnnualReturn, setShowAnnualReturn] = useState<boolean>(false);
  const [showLegalForm, setShowLegalForm] = useState<boolean>(true);
  const navigate = useNavigate();
  const [annualReturnFileName, setAnnualReturnFileName] = useState<string>('');
  const [certificateDocumentName, setCertificateDocumentName] = useState<string>('');
  const [corporationCopyFileName, setCorporationCopyFileName] = useState<string>('');

  const [showFormControl, setShowFormControl] = useState<{ [key: string]: boolean }>({
    businessRegistrationCertificateNumber: false,
    businessRegistrationOffice: false,
    certificateOfIncorporationCopy: false,
    businessRegistrationCertificate: false,
    businessRegistrationCertificateDocument: false,
    effectiveDateOfBusinessRegistrationCertificate: false,
    certificateOfIncorporationEffectiveDate: false,
    annualRetrun: false,
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
    setValue,
    register,
    setError,
    resetField,
    formState: { errors },
  } = useForm<LegalBusinessFormRequire>();

  useEffect(() => {
    // errors有改變時, 將畫面移到最上方
    if (Object.keys(errors).length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errors]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      setData({
        ...data,
        legalBusiness: {
          ...formData,
          legalFormCountry: data.address ? data.address.registerAddress.Country : '',
          annualReturn: watch('annualReturn')?.[0],
          colorScanOfFinancialInsitutionCertificationDocuments: watch(
            'colorScanOfFinancialInsitutionCertificationDocuments'
          )?.[0],
          businessRegistrationCertificateDocument: watch('businessRegistrationCertificateDocument')?.[0],
          certificateOfIncorporationCopy: watch('certificateOfIncorporationCopy')?.[0],
        },
      });

      // 若tab=3且entityLegalCategory為BRANCH且有填寫header, 則從第三步直接跳到預覽
      if (data.basicInformation?.entityLegalCategory === 'BRANCH' && data.basicInformation?.header) {
        setCurrentStep('3');
        setActiveStep(2);
        navigate('/lei?step=3');

        return;
      }

      setSelectedTab(4);
      setSearchParams({ step: '2', tab: '4' });
    } catch (error) {
      console.log(error);
    }
  });

  // const [financialInsititutionCode, setFinancialInsititutionCode] = useState<boolean>(false);

  // const handleFinancialInsititutionCode = () => {
  //   setFinancialInsititutionCode(!financialInsititutionCode);
  //   setValue('financialInsitutionCodeCheck', !financialInsititutionCode);

  //   if (!financialInsititutionCode) {
  //     register('financialInsitutionCode', { required: true });
  //   } else {
  //     register('financialInsitutionCode', { required: false });
  //   }
  // };

  useEffect(() => {
    if (!data.address) return;
    // 按照選擇的國家選擇顯示不同欄位
    const { registerAddress } = data.address;

    if (registerAddress.Country === 'CN') {
      // 中国，工商信息默认为：统一社会信用代码、登记机关、营业执照副本、营业执照生效日期；
      setShowFormControl({
        ...showFormControl,
        businessRegistrationOffice: true,
        businessRegistrationCertificateDocument: true,
        effectiveDateOfBusinessRegistrationCertificate: true,
      });
    } else if (registerAddress.Country === 'HK') {
      // 香港，工商信息默认为：公司注册证编号、登记机关、公司注册证副本、商业登记证、商业登记证副本、商业登记证生效日期、周年申报表副本；
      setShowFormControl({
        ...showFormControl,
        businessRegistrationCertificateNumber: true,
        businessRegistrationOffice: true,
        certificateOfIncorporationCopy: true,
        businessRegistrationCertificate: true,
        businessRegistrationCertificateDocument: true,
        effectiveDateOfBusinessRegistrationCertificate: true,
        annualRetrun: true,
      });
    } else if (registerAddress.Country === 'MO') {
      // 澳门，工商信息默认为：公司注册证编号、登记机关、公司注册证副本、商业登记证副本、商业登记证生效日期；
      setShowFormControl({
        ...showFormControl,
        businessRegistrationCertificateNumber: true,
        businessRegistrationOffice: true,
        certificateOfIncorporationCopy: true,
        businessRegistrationCertificateDocument: true,
        effectiveDateOfBusinessRegistrationCertificate: true,
      });
    }

    setValue('businessRegistrationCertificate', 'Report');
    setValue('effectiveDateOfBusinessRegistrationCertificate', new Date().toISOString().split('T')[0]);
    if (!data.legalBusiness) return;
    setValue('legalFormCountry', data.legalBusiness.legalFormCountry);
    setValue('legalFormLanguage', data.legalBusiness.legalFormLanguage);
    setValue('legalFormCode', data.legalBusiness.legalFormCode);
    setValue('registrationAuthorityCode', data.legalBusiness.registrationAuthorityCode);
    setValue('businessRegistrationCertificateNumber', data.legalBusiness.businessRegistrationCertificateNumber);
    setValue('businessRegistrationOffice', data.legalBusiness.businessRegistrationOffice);
    setValue(
      'effectiveDateOfBusinessRegistrationCertificate',
      data.legalBusiness.effectiveDateOfBusinessRegistrationCertificate
    );

    // setValue('financialInsitutionCode', data.legalBusiness.financialInsitutionCode);
    // setValue('financialInsitutionCodeCheck', data.legalBusiness.financialInsitutionCodeCheck);
    // setFinancialInsititutionCode(data.legalBusiness.financialInsitutionCodeCheck);
    // setValue('colorScanOfFinancialInsitutionCertificationDocuments', [
    //   data.legalBusiness.colorScanOfFinancialInsitutionCertificationDocuments,
    // ]);
    setValue('fileRA1Reason', data.legalBusiness.fileRA1Reason ? data.legalBusiness.fileRA1Reason : '');
    setValue('businessRegistrationCertificate', data.legalBusiness.businessRegistrationCertificate);
    setValue('otherLegalFormCode', data.legalBusiness.otherLegalFormCode);
    if (data.legalBusiness.annualReturn) {
      if (data.legalBusiness.annualReturn instanceof File) setValue('annualReturn', [data.legalBusiness.annualReturn]);
      else setAnnualReturnFileName(data.legalBusiness.annualReturn);
    }
    if (data.legalBusiness.businessRegistrationCertificateDocument)
      if (data.legalBusiness.businessRegistrationCertificateDocument instanceof File)
        setValue('businessRegistrationCertificateDocument', [
          data.legalBusiness.businessRegistrationCertificateDocument,
        ]);
      else setCertificateDocumentName(data.legalBusiness.businessRegistrationCertificateDocument);

    if (data.legalBusiness.certificateOfIncorporationCopy)
      if (data.legalBusiness.certificateOfIncorporationCopy instanceof File)
        setValue('certificateOfIncorporationCopy', [data.legalBusiness.certificateOfIncorporationCopy]);
      else setCorporationCopyFileName(data.legalBusiness.certificateOfIncorporationCopy);
  }, [searchParmas]);

  const watchRegisterationDate = watch('effectiveDateOfBusinessRegistrationCertificate');

  useEffect(() => {
    // 若註冊日期與當前日期相差超過1年, 則顯示annualReturn
    const today = new Date();
    const registerDate = new Date(watchRegisterationDate);
    const diffYear = today.getFullYear() - registerDate.getFullYear();

    if (diffYear > 1) {
      setShowAnnualReturn(true);
    } else {
      setShowAnnualReturn(false);
      register('annualReturn', {
        required: false,
      });
      resetField('annualReturn');
    }
  }, [watchRegisterationDate]);

  const [columnsName, setColumnsName] = useState({
    idNumber: t('LEIForm.businessInfo.certificateOfIncorporationNumber.label'),
    registerationOffice: '',
    copy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
    approveCopy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
    type: t('LEIForm.businessInfo.businessRegistrationCertificate.label') + ' *',
    date: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label') + ' *',
  });

  // 監視registrationAuthorityCode的變化, 若國家為HK時, 根據registrationAuthorityCode的值 表單會有對應的變化
  // 1. RA000388時, 公司註冊證編號必填, 登記機關必填, 公司註冊證副本必填, 周年申報表副本必填, 商業登記證生效日期必填, 商業登記證若為可提供則商業登記證副本必填, 若為無法提供則須輸入原因
  // 2. RA000389時, 商業登記證編號必填, 登記機關必填, 商業登記證副本必填, 周年申報表副本必填, 商業登記證生效日期必填, 公司註冊證若為可提供則公司註冊證附件副本必填, 若為無法提供則須輸入原因
  // 3. RA000390時, 牌照中央編號必填, 登記機關必填, 拍照副本附件必填, 周年申報表副本必填, 牌照生效日期必填, 公司註冊證若為可提供則公司註冊證附件副本必填, 若為無法提供則須輸入原因

  const watchRegistrationAuthorityCode = watch('registrationAuthorityCode');

  useEffect(() => {
    if (!watchRegistrationAuthorityCode) return;
    if (watchRegistrationAuthorityCode === 'RA000389') {
      setColumnsName({
        idNumber: t('LEIForm.businessInfo.businessRegistrationCertificateNumber.label'),
        registerationOffice: '',
        copy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
        approveCopy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
        type: t('LEIForm.businessInfo.certificateOfIncorporation.label') + ' *',
        date: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label') + ' *',
      });
    }
    if (watchRegistrationAuthorityCode === 'RA000388') {
      setColumnsName({
        idNumber: t('LEIForm.businessInfo.certificateOfIncorporationNumber.label'),
        registerationOffice: '',
        copy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
        approveCopy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
        type: t('LEIForm.businessInfo.businessRegistrationCertificate.label') + ' *',
        date: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label') + ' *',
      });
    }
    if (watchRegistrationAuthorityCode === 'RA000390') {
      setColumnsName({
        idNumber: t('LEIForm.businessInfo.CERefNo.label'),
        registerationOffice: '',
        copy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
        approveCopy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
        type: t('LEIForm.businessInfo.certificateOfIncorporation.label') + ' *',
        date: t('LEIForm.businessInfo.licenceEffectiveDate.label') + ' *',
      });
    }
  }, [watchRegistrationAuthorityCode]);

  useEffect(() => {
    // 若legalFormLanguage的data為空陣列時, 將legalFormCode的值設為8888並將showLegalForm設為false
    if (convertLegalFormLanguage(options?.LegalForm, data.address?.registerAddress.Country).length === 0) {
      setShowLegalForm(false);
      setValue('legalFormCode', '8888');
    }
  }, []);

  return (
    <Wrapper onSubmit={onSubmit}>
      <FormTitle>{t('info.legalForm')}</FormTitle>
      {showLegalForm && (
        <MultiInputWrapper gridGap={30}>
          {/* <FormSelect
            control={control}
            data={convertLocations(lang, options?.Country)}
            inputLabel={t('LEIForm.legalForm.legalFormCountry.label') + ' *'}
            selectLabel={t('LEIForm.legalForm.legalFormCountry.label') + ' *'}
            name={'legalFormCountry'}
            register={legalForm().legalFormCountry}
          /> */}
          <FormSelect
            control={control}
            data={convertLegalFormLanguage(options?.LegalForm, data.address?.registerAddress.Country)}
            inputLabel={t('LEIForm.legalForm.legalFormLanguage.label') + ' *'}
            selectLabel={t('LEIForm.legalForm.legalFormLanguage.label') + ' *'}
            name={'legalFormLanguage'}
            register={legalForm().legalFormLanguage}
          />
        </MultiInputWrapper>
      )}

      <InputWrapper style={{ height: 'auto', width: '100%' }}>
        {watch('legalFormLanguage') &&
        convertLegalFormCode(
          lang,
          options?.LegalForm,
          data.address?.registerAddress.Country,
          watch('legalFormLanguage')
        ).length !== 0 ? (
          <FormSelect
            control={control}
            data={convertLegalFormCode(
              lang,
              options?.LegalForm,
              data.address?.registerAddress.Country,
              watch('legalFormLanguage')
            )}
            inputLabel={t('LEIForm.legalForm.legalFormCode.label') + ' *'}
            selectLabel={t('LEIForm.legalForm.legalFormCode.label') + ' *'}
            name={'legalFormCode'}
            register={legalForm().legalFormCode}
          />
        ) : (
          <Controller
            control={control}
            name={'otherLegalFormCode'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.legalForm.otherEntityLegalForm.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={legalForm().legalFormCode}
          />
        )}
      </InputWrapper>

      <FormTitle>{t('info.businessInfo')}</FormTitle>
      <InputWrapper style={{ height: 'auto' }}>
        <FormSelect
          control={control}
          data={convertRAInformation(lang, options?.RAInformation, data.address?.registerAddress.Country)}
          inputLabel={t('LEIForm.businessInfo.registrationAuthorityCode.label') + ' *'}
          selectLabel={t('LEIForm.businessInfo.registrationAuthorityCode.label') + ' *'}
          name={'registrationAuthorityCode'}
          register={businessInfo().registrationAuthorityCode}
        />
      </InputWrapper>

      <InputWrapper style={{ height: 'auto' }}>
        <Controller
          control={control}
          name={'businessRegistrationCertificateNumber'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={columnsName.idNumber + ' *'}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={{
            required: t('required.input', {
              input: columnsName.idNumber,
            }),
          }}
        />
      </InputWrapper>

      {showFormControl.businessRegistrationOffice && (
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'businessRegistrationOffice'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.businessInfo.businessRegistrationOffice.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={businessInfo().businessRegistrationOffice}
          />
        </InputWrapper>
      )}

      {showFormControl.certificateOfIncorporationCopy && (
        <InputWrapper style={{ height: 'auto' }}>
          <InputFileWrapper>
            <InputFileLabel>
              <Text
                fontWeight={400}
                fontSize={theme.fontSizes.sm}
                color={
                  corporationCopyFileName !== ''
                    ? theme.colors.gray500
                    : !watch('certificateOfIncorporationCopy')?.[0]
                    ? theme.colors.gray300
                    : theme.colors.gray500
                }
              >
                {watch('certificateOfIncorporationCopy')?.[0]
                  ? watch('certificateOfIncorporationCopy')[0].name
                  : corporationCopyFileName !== ''
                  ? corporationCopyFileName
                  : columnsName.copy + ' *'}
              </Text>
              <input
                hidden
                accept=".pdf, .png, .gif, .jpg"
                type="file"
                {...register('certificateOfIncorporationCopy', {
                  required:
                    corporationCopyFileName !== ''
                      ? false
                      : watch('certificateOfIncorporationCopy')?.[0]
                      ? false
                      : t('required.input', {
                          input: columnsName.copy,
                        }),
                })}
              />
              {!watch('certificateOfIncorporationCopy')?.[0] && (
                <ErrorMessage>{errors.certificateOfIncorporationCopy?.message}</ErrorMessage>
              )}
              <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
            </InputFileLabel>
          </InputFileWrapper>
          <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
            JPG, GIF, PNG, {t('maxSize')} 2MB
          </Text>
        </InputWrapper>
      )}

      {showFormControl.businessRegistrationCertificate && (
        <InputWrapper style={{ height: 'auto' }}>
          <FormControl>
            <Controller
              control={control}
              name={'businessRegistrationCertificate'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Flex gridGap={'30px'} alignItems={'center'}>
                  <Text>{columnsName.type}：</Text>
                  <RadioGroup value={value} onChange={onChange}>
                    <Flex gridGap={'30px'} alignItems={'center'}>
                      <FormControlLabel
                        value="Report"
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: theme.colors.secondary500,
                              },
                            }}
                          />
                        }
                        label={t('LEIForm.businessInfo.reportRadio.report.label')}
                      />
                      <FormControlLabel
                        value="Not Report"
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: theme.colors.secondary500,
                              },
                            }}
                          />
                        }
                        label={t('LEIForm.businessInfo.reportRadio.notReport.label')}
                      />
                    </Flex>
                  </RadioGroup>
                </Flex>
              )}
            />
          </FormControl>
        </InputWrapper>
      )}

      {showFormControl.businessRegistrationCertificateDocument &&
        watch('businessRegistrationCertificate') === 'Report' && (
          <InputWrapper style={{ height: 'auto' }}>
            <InputFileWrapper>
              <InputFileLabel>
                <Text
                  fontWeight={400}
                  fontSize={theme.fontSizes.sm}
                  color={
                    certificateDocumentName !== ''
                      ? theme.colors.gray500
                      : !watch('businessRegistrationCertificateDocument')?.[0]
                      ? theme.colors.gray300
                      : theme.colors.gray500
                  }
                >
                  {watch('businessRegistrationCertificateDocument')?.[0]
                    ? watch('businessRegistrationCertificateDocument')[0].name
                    : certificateDocumentName !== ''
                    ? certificateDocumentName
                    : columnsName.approveCopy + ' *'}
                </Text>
                <input
                  hidden
                  accept=".pdf, .png, .gif, .jpg"
                  type="file"
                  {...register('businessRegistrationCertificateDocument', {
                    required:
                      certificateDocumentName !== ''
                        ? false
                        : watch('businessRegistrationCertificateDocument')?.[0]
                        ? false
                        : t('required.input', {
                            input: columnsName.approveCopy,
                          }),
                  })}
                />
                {!watch('businessRegistrationCertificateDocument')?.[0] && (
                  <ErrorMessage>{errors.businessRegistrationCertificateDocument?.message}</ErrorMessage>
                )}
                <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
              </InputFileLabel>
            </InputFileWrapper>
            <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
              JPG, GIF, PNG, {t('maxSize')} 2MB
            </Text>
          </InputWrapper>
        )}

      {showFormControl.businessRegistrationCertificateDocument &&
        watch('businessRegistrationCertificate') === 'Not Report' && (
          <InputWrapper style={{ height: 'auto' }}>
            <Controller
              control={control}
              name={'fileRA1Reason'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  variant="standard"
                  label={t('LEIForm.businessInfo.fileRA1Reason.label') + ' *'}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={{
                required: t('required.input', {
                  input: t('LEIForm.businessInfo.fileRA1Reason.label'),
                }),
              }}
            />
          </InputWrapper>
        )}

      {showFormControl.effectiveDateOfBusinessRegistrationCertificate && (
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'effectiveDateOfBusinessRegistrationCertificate'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="date"
                variant="standard"
                label={columnsName.date}
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={businessInfo().effectiveDateOfBusinessRegistrationCertificate}
          />
        </InputWrapper>
      )}

      {showFormControl.annualRetrun && showAnnualReturn && (
        <InputWrapper style={{ height: 'auto' }}>
          <InputFileWrapper>
            <InputFileLabel>
              <Text
                fontWeight={400}
                fontSize={theme.fontSizes.sm}
                color={
                  annualReturnFileName !== ''
                    ? theme.colors.gray500
                    : !watch('annualReturn')?.[0]
                    ? theme.colors.gray300
                    : theme.colors.gray500
                }
              >
                {watch('annualReturn')?.[0]
                  ? watch('annualReturn')[0].name
                  : annualReturnFileName !== ''
                  ? annualReturnFileName
                  : t('LEIForm.businessInfo.annualReturn.label') + ' *'}
              </Text>
              <input
                hidden
                accept=".pdf, .png, .gif, .jpg"
                type="file"
                {...register('annualReturn', {
                  required:
                    annualReturnFileName !== ''
                      ? false
                      : watch('annualReturn')?.[0]
                      ? false
                      : t('required.input', { input: t('LEIForm.businessInfo.annualReturn.label') }),
                })}
              />
              {!watch('annualReturn')?.[0] && <ErrorMessage>{errors.annualReturn?.message}</ErrorMessage>}
              <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
            </InputFileLabel>
          </InputFileWrapper>
          <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
            JPG, GIF, PNG, {t('maxSize')} 2MB
          </Text>
        </InputWrapper>
      )}

      {/* <FormTitle>{t('info.financialInstitutionCode')}</FormTitle>
      <InputWrapper style={{ height: 'auto' }}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: '#999999',
                '&.Mui-checked': {
                  color: '#999999',
                },
              }}
              onChange={handleFinancialInsititutionCode}
              name="financialInsititutionCode"
              checked={financialInsititutionCode}
            />
          }
          label={<Text>{t('LEIForm.financialInstitutionCode.financialInstitutionCode.placeholder')}</Text>}
        />
      </InputWrapper>
      <InputWrapper style={{ height: 'auto' }}>
        <Controller
          control={control}
          name={'financialInsitutionCode'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.financialInstitutionCode.financialInstitutionCode.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
              disabled={!financialInsititutionCode}
            />
          )}
          rules={businessInfo().financialInstitutionCode}
        />
      </InputWrapper>
      <InputWrapper style={{ height: 'auto' }}>
        <InputFileWrapper>
          <InputFileLabel>
            <Text
              fontWeight={400}
              fontSize={theme.fontSizes.sm}
              color={
                !watch('colorScanOfFinancialInsitutionCertificationDocuments')?.[0]
                  ? theme.colors.gray300
                  : theme.colors.gray500
              }
            >
              {watch('colorScanOfFinancialInsitutionCertificationDocuments')?.[0]
                ? watch('colorScanOfFinancialInsitutionCertificationDocuments')[0].name
                : t('LEIForm.financialInstitutionCode.colorScanOfFinancialInsitutionCertificationDocument.label')}
            </Text>
            <input
              hidden
              accept=".pdf, .png, .gif, .jpg"
              type="file"
              {...register('colorScanOfFinancialInsitutionCertificationDocuments')}
            />
            {!watch('colorScanOfFinancialInsitutionCertificationDocuments')?.[0] && (
              <ErrorMessage>{errors.colorScanOfFinancialInsitutionCertificationDocuments?.message}</ErrorMessage>
            )}
            <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
          </InputFileLabel>
        </InputFileWrapper>
        <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
          JPG, GIF, PNG, {t('maxSize')} 2MB
        </Text>
      </InputWrapper> */}

      <TabsButton selectedTab={3} setSelectedTab={setSelectedTab} />
    </Wrapper>
  );
};

export default LegalBusiness;

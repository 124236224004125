import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useResize from '@/hooks/useResize';

const Title = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  padding: ${(p) => (p.isMobile ? '0 20px 20px' : p.isTablet ? '20px 0' : '30px 0')};

  > .innerTitle { 
    font-family: 'Roboto';
    font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.md : p.theme.fontSizes.lg)};
    line-height: 38px;
    color: ${(p) => p.theme.colors.gray500};
    margin-top: ${(p) => (p.isMobile ? '10px' : '0')};

    &:nth-child(3) {
      margin: 0 5px;
    }

    &:last-child {
      font-weight: 700;
    }
  }
`;

const Button = styled.button` 
    width: 130px;
    padding: 10px 0;
    margin-right: 20px;
    background: ${(p) => p.theme.colors.primary500};
    border-radius: 5px;
    color: ${(p) => p.theme.colors.light};
    font-family: 'Roboto'
    font-size: ${(p) => p.theme.fontSizes.md};
    line-height: 19px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center
`;

interface InnerPageTitleProps {
  link: string;
  title?: string | React.ReactNode;
  subtitle: string | React.ReactNode;
}

// 要回上一頁請先用router-dom找出網址再用字串傳進來
const InnerPageTitle = ({ link, title = '', subtitle }: InnerPageTitleProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Title isMobile={isMobile} isTablet={isTablet}>
      {link === '' && (
        <Link to={location.key === 'default' ? '/' + location.pathname.split('/')[1] : ''} onClick={() => navigate(-1)}>
          <Button>
            <img src="/images/profile/back.svg" alt="" />
            {t('buttons.back')}
          </Button>
        </Link>
      )}
      {link !== '' && (
        <Link to={link}>
          <Button>
            <img src="/images/profile/back.svg" alt="" />
            {t('buttons.back')}
          </Button>
        </Link>
      )}
      <div className='innerTitle'>
        <span>{title}</span>
        {title !== '' && <span>/</span>}
        <span>{subtitle}</span>
      </div>
    </Title>
  );
};

export default InnerPageTitle;

import { useEffect } from 'react';
import useResize from '@/hooks/useResize';
import Steps from './Steps';
import Footer from '../Footer';
import { GoTop } from '../style';
import ServicesBanner from './ServiceBanner';

const Services = () => {
  const { isTablet, isMobile } = useResize();
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div>
      <ServicesBanner />
      <Steps />
      <Footer />
      <GoTop
        isTablet={isTablet}
        isMobile={isMobile}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        Top
      </GoTop>
    </div>
  );
};

export default Services;

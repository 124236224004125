import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { ModalForm } from './style';
import { Link, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { H2 } from '@/components/UI/Typography/Heading';
import MSTermsMarkdown from './MSTerms.md';
import Loading from '@/components/UI/Loading';
import Terms from './terms';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  onSubmit?: () => void;
  count?: number;
}

const ConfirmModal = ({ showModal, setShowModal, onSubmit = () => {}, count = 0 }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <Text marginBottom={50}>{t('pages.eTrust.creditReport.steps.step2.modal.buyCreditReport')}</Text>
        <Text color={theme.colors.primary500}>
          {t('pages.eTrust.creditReport.steps.step2.modal.creditCount', {
            current: count,
            remain: count === 0 ? count : count - 1,
          })}
        </Text>

        <Box
          style={{ marginTop: '50px', display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'center' }}
        >
          <Button size={'lg'} variant={'primary'} onClick={onSubmit}>
            {t('buttons.ok')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'underline' }}
          >
            {t('buttons.cancel')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

const RejectModal = ({ showModal, setShowModal }: Omit<ModalProps, 'onSubmit'>) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <Text>{t('pages.eTrust.creditReport.steps.step2.modal.reject')}</Text>

        <Box marginTop={50}>
          <Button size={'lg'} variant={'primary-invert'} onClick={() => setShowModal(false)}>
            {t('buttons.back')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

const PurchaseModal = ({ showModal, setShowModal }: Omit<ModalProps, 'onSubmit'>) => {
  // @ts-ignore
  const { t } = useTranslation();
  const location = useLocation();
  // 取得當前網址
  const currentUrl = useMemo(() => {
    return `${location.pathname}${location.search}`;
  }, [location]);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <Text color={theme.colors.danger}>
          {t('error.creditReport.top')}
          <br /> {t('error.creditReport.bottom')}
        </Text>

        <Box marginTop={50}>
          <Link to={'/cart?item=2,3,4'} state={{ from: currentUrl }}>
            <Button size={'lg'} variant={'primary'}>
              {t('buttons.order')}
            </Button>
          </Link>
        </Box>
      </ModalForm>
    </Modal>
  );
};

const ErrorModal = ({ showModal, setShowModal }: Omit<ModalProps, 'onSubmit'>) => {
  // @ts-ignore
  const { t } = useTranslation();

  useEffect(() => {}, [t]);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <Text>{t('pages.eTrust.creditReport.steps.step2.modal.error')}</Text>

        <Box marginTop={30}>
          <Button size={'lg'} variant={'primary-invert'} onClick={() => setShowModal(false)}>
            {t('buttons.ok')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

const TermsModal = ({ showModal, setShowModal, onSubmit = () => {} }: Omit<ModalProps, 'count'>) => {
  // @ts-ignore
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [personalInfo, setPersonalInfo] = useState(true);
  const personalHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { currentTarget } = e;

    // 計算若滾軸到接近底部100px時，則可以點擊同意按鈕
    const scrollHeight = currentTarget.scrollHeight;
    const scrollTop = currentTarget.scrollTop;
    const clientHeight = currentTarget.clientHeight;
    const isBottom = scrollHeight - scrollTop - clientHeight < 100;
    if(personalInfo && isBottom){
      setPersonalInfo(!isBottom);
    }
  };

  const [myFaceSign, setMyFaceSign] = useState('');

  useEffect(() => {
    // 延遲2秒後取得資料
    setTimeout(() => {
      getData();
    }, 2000);
  }, []);

  const getData = useCallback(async () => {
    try {
      const response = await fetch(MSTermsMarkdown);
      const data = await response.text();
      setMyFaceSign(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <div
        style={{
          position: 'absolute',
          top: '5%',
          left: 'calc(50% - 465px)',
          width: '100%',
          maxWidth: '930px',
          backgroundColor: '#fff',
          padding: '30px 50px',
          borderRadius: '40px 40px 40px 0px',
        }}
      >
        <H2>{t('pages.eTrust.creditReport.steps.step2.modal.myfaceSign')}</H2>
        <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
          {t('pages.auth.signUp.modal.terms.pic.content')}
        </span>
        <div
          className="content"
          style={{
            marginTop: '25px',
            height: '40vh',
            overflowY: 'auto',
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '20px',
            color: '#616161',
            lineHeight: '30px',
          }}
          onScroll={personalHandler}
        >
          {/* {isLoading && <Loading />}
          {!isLoading && <ReactMarkdown children={myFaceSign} remarkPlugins={[remarkGfm]} />} */}
          <Terms />
        </div>
        <Box style={{ marginTop: '28px', display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={onSubmit}
            size={'lg'}
            variant={'primary'}
            rounded={false}
            marginRight={40}
            disabled={personalInfo}
          >
            {t('buttons.agree')}
          </Button>
          <Button color="primary" onClick={() => setShowModal(false)} style={{ textDecoration: 'underline' }}>
            {t('buttons.back')}
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export { ConfirmModal, RejectModal, PurchaseModal, ErrorModal, TermsModal };

import styled from 'styled-components';

const Row = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  gap: ${p=>p.isMobile ? '5px' : '0'};
  margin-bottom: ${p=>p.isMobile ? '15px' : '10px'}};

  .title {
    white-space: nowrap;
    width: 240px;
    color: ${(p) => p.theme.colors.gray300};
  }

  a {
    color: ${(p) => p.theme.colors.primary500};
    text-decoration: underline;
  }

  .content {
    flex: 1 0 0;

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: ${(p) => p.theme.colors.primary500};
      padding-bottom: 10px;
      border-bottom: 2px solid ${(p) => p.theme.colors.gray100};
      margin-bottom: 10px;

      div {
        color: ${(p) => p.theme.colors.secondary500};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

const Hr = styled.hr`
  color: ${(p) => p.theme.colors.gray300};
  margin: 15px 0;
`;

const LabelWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.gray500};

  span.term {
    text-decoration-line: underline;
    color: ${(p) => p.theme.colors.secondary500};
  }
`;

export { Row, Hr, LabelWrapper };

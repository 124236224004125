import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import { uploadDataTableIds } from './dataTable.utils';

export type DataTableState = {
  [key in string]: {
    selectedRowIds: { [rowKey in string]: boolean };
    selectedDataIds: string[];
  };
};

const INITIAL_STATE: DataTableState = {};

const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedId: (
      state,
      action: PayloadAction<{ selectedDataIds: string[]; selectedRowIds: string[]; key: string }>
    ) => uploadDataTableIds(state, action.payload),
  },
});

export const { setSelectedId } = dataTableSlice.actions;

export const getDataTableSelectedIdsSelector = (store: RootState) => store.dataTable;

export default dataTableSlice.reducer;

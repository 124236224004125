import styled from 'styled-components';

const Button = styled.button<{ status?: string }>`
  min-width: ${(p) => (p.status ? '215px' : '130px')};
  padding: 10px 15px;
  text-align: center;
  background: ${(p) =>
    p.status === 'applying'
      ? p.theme.colors.orange
      : p.status === 'danger'
      ? p.theme.colors.red
      : p.status === 'success'
      ? p.theme.colors.secondary400
      : p.theme.colors.primary500};
  border-radius: 5px;
  color: ${(p) => p.theme.colors.light};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;

const MainContent = styled.div<{ isTablet: boolean }>`
  width: ${(p) => (p.isTablet ? '100%' : 'calc(100% - 300px)')};
  max-width: ${(p) => (p.isTablet ? 'unset' : '1200px')};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
`;

const Row = styled.div<{ isTablet: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  align-items: center;
  background: ${(p) => p.theme.colors.light};
  border-radius: 15px 15px 15px 0;
  ${(p) => p.theme.outsideShadowW02};
  padding: 30px 20px;
`;

const IconChecked = styled.div<{ isTablet: boolean }>`
  position: relative;
  margin-right: ${(p) => (p.isTablet ? '0' : '25px')}};

  > .checkIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: ${(p) => p.theme.colors.light};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.checking {
    .checkIcon {
      display: none;
    }
  }
`;

const ButtonGroup = styled.span<{ isTablet: boolean }>`
  width: ${(p) => (p.isTablet ? '100%' : 'auto')};
  display: flex;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  gap: ${(p) => (p.isTablet ? '10px' : '20px')}};
  align-items: center;
  margin-left: ${(p) => (p.isTablet ? '0' : 'auto')};
  margin-top: ${(p) => (p.isTablet ? '15px' : '0')};

  > a {
    width:  ${(p) => (p.isTablet ? '100%' : 'auto')};

    > button {
      width: 100%;
    }
  }
`;

const Status = styled.span`
  width: 200px;
  padding: 10px 0;
  text-align: center;
  color: ${(p) => p.theme.colors.light};
  font-family: 'Roboto';
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 19px;
  border-radius: 5px;

  &.applying {
    background: ${(p) => p.theme.colors.orange};
  }

  &.success {
    background: ${(p) => p.theme.colors.secondary500};
  }

  &.failed {
    background: ${(p) => p.theme.colors.red};
  }
`;

const SupplementallInfoList = styled.div<{ isTablet: boolean }>`
  background: ${(p) => p.theme.colors.light};
  ${(p) => p.theme.outsideShadowW02};
  border-radius: 15px 15px 15px 0;
  padding: 30px 20px;

  .item {
    display: flex;
    flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
    align-items: center;
    padding: 20px 0;
    border-bottom: 2px solid ${(p) => p.theme.colors.gray100};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

export { Button, ContentWrapper, MainContent, List, Row, IconChecked, ButtonGroup, Status, SupplementallInfoList };

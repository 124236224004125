import { productService, category } from '@/data/select';

const categoryGenerator = (categoryString: string | null, servicesString: string | null) => {
  // 先判斷傳入是否為null, 後分別拆除字串帶來的id轉為array進行forEach
  if (!categoryString) return '';
  const categoryAry = categoryString.trim().split(',');
  let res: string[] = [];

  categoryAry.forEach((item) => {
    if (Number.isNaN(parseInt(item))) {
      res.push(item);
    } else {
      res.push(category[parseInt(item)]);
    }
  });

  if (!servicesString) return res.filter((item) => item).join(', ');
  const serviceAry = servicesString.split(' ');
  serviceAry.forEach((item) => res.push(productService[parseInt(item)]));
  return res.filter((item) => item).join(', ');
};

export default categoryGenerator;

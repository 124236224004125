import { useEffect } from 'react';
import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form';

interface FormSelectProps {
  control: Control<any>;
  data: { value: string | number; text: string | number }[];
  inputLabel: string;
  selectLabel: string;
  name: string;
  register?: RegisterOptions;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  readOnly?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({
  control,
  data,
  inputLabel,
  selectLabel,
  register,
  name,
  variant = 'standard',
  size = 'medium',
  readOnly = false,
}: FormSelectProps) => {
  const findValueText = (value: string | number) => {
    const item = data.find((i) => i.value === value);
    return item ? item.text : '';
  };

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        if (!value) {
          value = [];
        }
        return (
          <FormControl fullWidth variant={variant} size={size}>
            <InputLabel sx={{ zIndex: 0 }} error={!!error}>
              {inputLabel}
            </InputLabel>
            <Select
              multiple={true}
              label={selectLabel}
              onChange={onChange}
              value={value}
              inputProps={{ readOnly: readOnly }}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                // 將selected的值打散後，透過findValueText找到對應的text，並用join組合成字串
                return (selected as string[]).map((s) => findValueText(s)).join(', ');
              }}
            >
              {data.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  <Checkbox checked={value.indexOf(i.value) > -1} />
                  <ListItemText primary={i.text} />
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
      name={name}
      rules={register}
    />
  );
};

export default MultiSelect;

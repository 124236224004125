import { useState, useEffect, useCallback } from 'react';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { useTranslation } from 'react-i18next';

import { ModalWrapper, ModalNotice, ModalForm, ModalTitle, ResolutionBtn, Triangle } from './styles';
import { Box, Flex, LoadingPage } from '@/layout';
import { Title } from '@/pages/Subscription/style';
import { addNewItem, uploadExecutionFile, verifyDocument } from '@/api/deal.api';
import { Controller, useForm } from 'react-hook-form';
import { Modal, TextField } from '@mui/material';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';

import { InputFileLabel, InputFileWrapper } from '@/components/UI/Input/InputFile/styles';
import { Check, Close, CloudUpload, Error } from '@/components/Icon';
import { FormSelect } from '@/components/UI/Select';
import Loading from '@/components/UI/Loading';
import docType from '@/data/select/docType';
import { fileSizeValidator } from '@/utils/fileSizeValidator';
import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { theme } from '@/theme/Theme';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ExecutionRequest {
  file: FileList;
  doc_type: string;
  other: string;
}

interface AddNewItemModalProps {
  upDateData: (data: ExecutionFile[]) => void;
  id?: string;
}

export const AddNewItemModal = ({ showModal, setShowModal, id, upDateData }: ModalProps & AddNewItemModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [docTypeOptions, setDocTypeOptions] = useState<any[]>([]);

  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<ExecutionRequest>();

  const { inputRegister } = useAuthValidation();

  const onSubmit = handleSubmit(async (data) => {
    if (!id) return;
    setIsLoading(true);
    const rawData: AddNewItemRequest = {
      file: data.file?.[0],
      doc_type: data.doc_type === 'Others' ? data.other : data.doc_type,
    };
    try {
      const res = (await addNewItem(rawData, id)).data;
      if (res.success) {
        upDateData(res.data);
        successHandler(t('success.upload'));
      }
    } catch (error: any) {
      errorHandler(error.response.data.data);
    }
    setIsLoading(false);
    reset();
    setShowModal(false);
  });

  const getDocType = useCallback(async () => {
    const docTypes = await docType();
    setDocTypeOptions(docTypes);
  }, []);

  useEffect(() => {
    getDocType();
  }, [getDocType]);

  // 監視檔案大小
  const watchFile = watch('file');
  useEffect(() => {
    if (watchFile && watchFile.length > 0 && !fileSizeValidator(watchFile[0], 1.5)) {
      reset();
      errorHandler(t('error.fileLimit'));
    }
  }, [watchFile]);

  const watchType = watch('doc_type');
  useEffect(() => {
    if (watchType && watchType.includes('Others')) {
      register('other', { required: 'this is required.' });
    }
  }, [watchType]);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalWrapper>
        <Close.ButtonClose
          onClick={() => {
            setShowModal(false);
          }}
        >
          <Close.CloseSVG />
        </Close.ButtonClose>
        <Title style={{ marginRight: 'auto', marginBottom: 30 }}>Add New Item</Title>

        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
              <InputFileWrapper>
                <InputFileLabel>
                  <Text>{!watch('file')?.[0] ? 'Please select an item' : watch('file')[0].name}</Text>
                  <input hidden type="file" {...register('file')} />
                  <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
                </InputFileLabel>
              </InputFileWrapper>

              <FormSelect
                control={control}
                data={docTypeOptions}
                inputLabel={'Item Type'}
                selectLabel={'doc_type'}
                name={'doc_type'}
                register={inputRegister}
              />

              {watchType && watchType.includes('Other') && (
                <InputWrapper>
                  <Controller
                    control={control}
                    name={'other'}
                    defaultValue={'Enter the item type'}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label=""
                        variant="standard"
                        value={value}
                        sx={{ width: '100%', marginTop: '15px' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                      />
                    )}
                  />
                </InputWrapper>
              )}
            </Flex>

            <Flex gridGap={'20px'} paddingTop={100}>
              <Button size={'lg'} variant={'primary'} onClick={onSubmit}>
                {t('buttons.save')}
              </Button>
              <Button
                size={'lg'}
                variant={'primary-invert'}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t('buttons.cancel')}
              </Button>
            </Flex>
          </>
        )}
      </ModalWrapper>
    </Modal>
  );
};

interface FileUploadModalProps {
  upDateData: (data: ExecutionFile[]) => void;
  id?: string;
  executionId: string;
}

interface ExecutionRequest {
  file: FileList;
}

export const FileUploadModal = ({
  showModal,
  setShowModal,
  id,
  upDateData,
  executionId,
}: ModalProps & FileUploadModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, register, watch, reset } = useForm<ExecutionRequest>();

  const onSubmit = handleSubmit(async (data) => {
    if (!id) return;
    setIsLoading(true);
    const rawData: ExecutionFileRequest = {
      file: data.file?.[0],
      executionId,
    };
    try {
      const res = (await uploadExecutionFile(rawData, id)).data;
      if (res.success) {
        upDateData(res.data);
        successHandler(t('success.upload'));
      }
    } catch (error: any) {
      errorHandler(error.response.data.data);
    }
    setIsLoading(false);
    reset();
    setShowModal(false);
  });

  // 監視檔案大小
  const watchFile = watch('file');
  useEffect(() => {
    if (watchFile && watchFile.length > 0 && !fileSizeValidator(watchFile[0], 1.5)) {
      reset({ file: undefined });
      errorHandler(t('error.fileLimit'));
    }
  }, [watchFile]);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalWrapper>
        <Close.ButtonClose
          onClick={() => {
            setShowModal(false);
          }}
        >
          <Close.CloseSVG />
        </Close.ButtonClose>
        <Title style={{ marginRight: 'auto', marginBottom: 30 }}>Upload File</Title>

        {isLoading && <Loading />}

        {!isLoading && (
          <>
            <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
              <InputFileWrapper>
                <InputFileLabel>
                  <Text>{!watch('file')?.[0] ? 'Please select an file' : watch('file')[0].name}</Text>
                  <input hidden type="file" {...register('file')} />
                  <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
                </InputFileLabel>
              </InputFileWrapper>
            </Flex>

            <Flex gridGap={'20px'} paddingTop={100}>
              <Button size={'lg'} variant={'primary'} onClick={onSubmit}>
                {t('buttons.save')}
              </Button>
              <Button
                size={'lg'}
                variant={'primary-invert'}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t('buttons.cancel')}
              </Button>
            </Flex>
          </>
        )}
      </ModalWrapper>
    </Modal>
  );
};

interface VerifyFileModalProps {
  setVerifyResult: React.Dispatch<React.SetStateAction<boolean>>;
  setShowVerifyResult: React.Dispatch<React.SetStateAction<boolean>>;
  file_id: string;
  deal_id: string;
}

export const VerifyFileModal = ({
  showModal,
  setShowModal,
  setShowVerifyResult,
  setVerifyResult,
  file_id,
  deal_id,
}: ModalProps & VerifyFileModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm<{ file: FileList }>();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      const reqData = {
        file: data.file[0],
        file_id,
        deal_id,
      };
      const res = (await verifyDocument(reqData)).data;
      setVerifyResult(res.data);
    } catch (error: any) {
      errorHandler(error.response.data.data);
    } finally {
      setIsLoading(false);
      setShowModal(false);
      setShowVerifyResult(true);
    }
  });

  const watchFile = watch('file');

  useEffect(() => {
    // if (watchFile && watchFile.length > 0 && !fileSizeValidator(watchFile[0], 1.5)) {
    //   reset({ file: undefined });
    //   errorHandler(t('error.fileLimit'));
    // }
  }, [watchFile]);

  // 關閉此組件時重設表單
  useEffect(() => {
    reset();
  }, [showModal]);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      {isLoading ? (
        <LoadingPage isLoading={isLoading} />
      ) : (
        <ModalForm onSubmit={onSubmit}>
          <Close.ButtonClose
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Close.CloseSVG />
          </Close.ButtonClose>
          <ModalTitle>{t('pages.dealDetail.execution.edit.modal.verifyFile.title')}</ModalTitle>
          <ModalNotice>{t('pages.dealDetail.execution.edit.modal.verifyFile.content')}</ModalNotice>
          <InputFileWrapper>
            <InputFileLabel>
              <Text
                color={watchFile !== undefined && watchFile.length > 0 ? theme.colors.gray500 : theme.colors.gray200}
              >
                {watchFile !== undefined && watchFile.length > 0
                  ? watch('file')[0].name
                  : t('form.verifyDocument.placeholder')}
              </Text>
              <input hidden type="file" {...register('file')} />
              <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
            </InputFileLabel>
          </InputFileWrapper>
          <Box style={{ marginTop: '100px', display: 'flex', alignItems: 'center' }}>
            <Button size={'lg'} variant={'primary'} rounded={false} marginRight={40} disabled={!isDirty}>
              {t('buttons.upload')}
            </Button>
            <Button
              size={'lg'}
              variant={'primary-invert'}
              color="primary"
              onClick={() => setShowModal(false)}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.cancel')}
            </Button>
          </Box>
        </ModalForm>
      )}
    </Modal>
  );
};

interface VerifyResultModalProps {
  verifyResult: boolean;
}

export const VerifyResultModal = ({ showModal, setShowModal, verifyResult }: ModalProps & VerifyResultModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <Flex>
          <ModalTitle>{t('pages.dealDetail.execution.edit.modal.verifyResult.title')}</ModalTitle>
          <Close.ButtonClose
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Close.CloseSVG />
          </Close.ButtonClose>
        </Flex>
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          {verifyResult ? (
            <>
              <Check.CheckSVG width={60} height={60} fill={'#A4D233'} />
              <Text marginTop={'5px'}>{t('pages.dealDetail.execution.edit.modal.verifyResult.success')}</Text>
            </>
          ) : (
            <>
              <Error.ErrorSVG width={60} height={60} fill={'#DC1010'} />
              <Text marginTop={'5px'}>{t('pages.dealDetail.execution.edit.modal.verifyResult.failed')}</Text>
            </>
          )}
        </div>
      </ModalForm>
    </Modal>
  );
};

export const ResolutionModal = ({ showModal, setShowModal }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <Close.ButtonClose
          onClick={() => {
            setShowModal(false);
          }}
        >
          <Close.CloseSVG />
        </Close.ButtonClose>
        <ModalTitle>{t('pages.dealDetail.execution.edit.modal.disputes.title')}</ModalTitle>

        <Box
          style={{
            marginTop: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <ResolutionBtn type={'button'} variant={'white'}>
            {t('pages.dealDetail.execution.edit.modal.disputes.arises')}
          </ResolutionBtn>

          <Triangle />

          <a
            style={{ display: 'block', width: '100%' }}
            target="_blank"
            rel="noreferrer"
            href="https://www.ebram.org/online_mediation.html"
          >
            <ResolutionBtn type={'button'} variant={'primary'}>
              {t('pages.dealDetail.execution.edit.modal.disputes.steps.step1')}
            </ResolutionBtn>
          </a>

          <Triangle />

          <Text textAlign={'center'}>{t('pages.dealDetail.execution.edit.modal.disputes.stillNoResolved')}</Text>
          <a
            style={{ display: 'block', width: '100%' }}
            target="_blank"
            rel="noreferrer"
            href="https://www.ebram.org/online_arbitration.html"
          >
            <ResolutionBtn type={'button'} variant={'primary'}>
              {t('pages.dealDetail.execution.edit.modal.disputes.steps.step2')}
            </ResolutionBtn>
          </a>
        </Box>
      </ModalForm>
    </Modal>
  );
};

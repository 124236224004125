import { getOrderDetail } from '@/api/payment.api';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { Box, Flex, LoadingPage } from '@/layout';
import { errorHandler } from '@/utils/toastHandler';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentWrapper, MainContent } from '../style';
import { AccountSideMenu } from '@/components/SideMenu/AccountSideMenu';
import { theme } from '@/theme/Theme';
import { Text, Title } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import useCurrentLang from '@/hooks/useCurrentLang';

const PaymentDetail = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<OrdersListResponse>();

  const initData = useCallback(async () => {
    if (!id) return;
    const res = (await getOrderDetail(id)).data;
    if (res.success) {
      setData(res.data);
    }
  }, []);

  useEffect(() => {
    if (!id) {
      navigate('/payment');
      errorHandler('error.paymentNotFound');
    }
    initData();
  }, [initData]);

  return (
    <PageContainer title={<InnerPageTitle link={'/payment'} title={t('buttons.home')} subtitle={t('payment')} />}>
      <ContentWrapper>
        <MainContent>
          {data ? (
            <Box borderRadius={15} background={'#fff'} padding={'30px 50px'}>
              <Flex flexDirection={'column'} gridGap={10}>
                <Flex>
                  <Box minWidth={240}>
                    <Text color={theme.colors.gray300}>{t('pages.payment.detail.state')}</Text>
                  </Box>
                  <Text>
                    {data.status === 'ACCEPT' && t('pages.payment.detail.complete')}
                    {data.status === 'CANCEL' && t('pages.payment.detail.cancel')}
                    {data.status === null && t('pages.payment.detail.cancel')}
                  </Text>
                </Flex>
                <Flex>
                  <Box minWidth={240}>
                    <Text color={theme.colors.gray300}>{t('pages.payment.detail.time')}</Text>
                  </Box>
                  <Text>{new Date(data.signed_date_time).toLocaleString()}</Text>
                </Flex>
                <Flex>
                  <Box minWidth={240}>
                    <Text color={theme.colors.gray300}>{t('pages.payment.detail.transactionID')}</Text>
                  </Box>
                  <Text>{data.reference_number}</Text>
                </Flex>
                {/* <Flex>
                  <Box minWidth={240}>
                    <Text color={theme.colors.gray300}>{t('pages.payment.detail.paymentMethod')}</Text>
                  </Box>
                  <Text>Complete</Text>
                </Flex> */}
                <Flex>
                  <Box minWidth={240}>
                    <Text color={theme.colors.gray300}>{t('pages.payment.detail.cardNumber')}</Text>
                  </Box>
                  <Text>{data.req_card_number}</Text>
                </Flex>
              </Flex>
              <Box maxWidth={830} paddingTop={10}>
                <Flex padding={'15px 0'} borderTop={`2px solid ${theme.colors.gray100}`}>
                  <Title fontSize={theme.fontSizes.base}>{t('pages.payment.detail.orderDetails')}</Title>
                </Flex>
                {data.details.map((item, index) => (
                  <Flex
                    padding={'15px 0'}
                    borderTop={`2px solid ${theme.colors.gray100}`}
                    justifyContent={'space-between'}
                  >
                    <Text fontSize={theme.fontSizes.base}>
                      {item.feature_old_data && lang === 'en' && JSON.parse(item.feature_old_data).name}
                      {item.feature_old_data && lang === 'sc' && JSON.parse(item.feature_old_data).name_cn}
                      {item.feature_old_data && lang === 'tc' && JSON.parse(item.feature_old_data).name_zh}
                    </Text>
                    <Flex fontSize={theme.fontSizes.base}>
                      <Title color={theme.colors.danger}>{item.feature_price}</Title>
                      {t('pages.payment.currency.HKD')}
                    </Flex>
                  </Flex>
                ))}

                {/* <Flex padding={'15px 0'} borderTop={`2px solid ${theme.colors.gray100}`}>
                <Text fontSize={theme.fontSizes.base}></Text>
              </Flex> */}

                <Flex
                  padding={'15px 0'}
                  borderTop={`2px solid ${theme.colors.gray100}`}
                  justifyContent={'space-between'}
                >
                  <div></div>
                  <Flex>
                    <Title fontSize={theme.fontSizes.base} color={theme.colors.gray500} marginRight={30}>
                      {t('pages.payment.detail.total')}
                    </Title>
                    <Flex fontSize={theme.fontSizes.base}>
                      <Title color={theme.colors.danger}>{data.amount}</Title>
                      {t('pages.payment.currency.HKD')}
                    </Flex>
                  </Flex>
                </Flex>
              </Box>

              <Flex gridGap={15}>
                <Button size={'lg'} variant={'primary-invert'} rounded={false} onClick={() => navigate(-1)}>
                  {t('buttons.back')}
                </Button>
              </Flex>
            </Box>
          ) : (
            <Loading />
          )}
        </MainContent>
        <AccountSideMenu />
      </ContentWrapper>
    </PageContainer>
  );
};

export default PaymentDetail;

import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import Link from '@/components/UI/Link';
import { Box } from '@/layout/Box';
import { successHandler } from '@/utils/toastHandler';

import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { sendEmailVerificationCode, resendEmailVerificationCode, registerVerification } from '@/api/auth.api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signIn } from '@/redux/auth/auth.slice';
import { Flex } from '@/layout';
import { theme } from '@/theme/Theme';
import useResize from '@/hooks/useResize';
import { VerifyModal } from '@/components/UI/Modal';

interface FormStepperProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setUserMail: React.Dispatch<React.SetStateAction<string>>;
}

const FormStep1 = ({ setActiveStep, setUserMail }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile } = useResize();
  const [showModal, setShowModal] = useState(false);
  const [failedCounter, setFailedCounter] = useState(0);
  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty },
  } = useForm<{ email: string }>();

  const { emailRegister } = useAuthValidation();
  const onSubmit = handleSubmit(async (data) => {
    try {
      // 送出驗證信
      const res = (await sendEmailVerificationCode(data)).data;
      if (res.success) {
        setUserMail(data.email);
        setActiveStep(2);
      }
    } catch (error) {
      setError('email', {
        type: 'server',
        message: t('error.emailRegisted'),
      });
    }
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setShowModal(true);
      }}
    >
      <VerifyModal
        formSubmit={onSubmit}
        type={'sentMail'}
        failedCounter={failedCounter}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Text marginTop={'15px'}>{t('pages.auth.signUp.illustrate.step1')}</Text>
      <InputWrapper>
        <Controller
          control={control}
          defaultValue=""
          name={'email'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('form.email.label')}
              placeholder={t('form.email.placeholder')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={emailRegister}
        />
      </InputWrapper>
      <Box>
        <Button
          size={'lg'}
          width={isMobile ? '100%' : 'unset'}
          variant={'primary'}
          rounded={false}
          marginBottom={15}
          disabled={!isDirty}
          type={'button'}
          onClick={() => setShowModal(true)}
        >
          {t('buttons.next')}
        </Button>
        <Text>
          {t('pages.auth.signUp.prompt.step1.context')} <Link to={'/auth/signin'}>{t('buttons.signIn')}</Link>
        </Text>
      </Box>
    </form>
  );
};

interface userMailProps {
  userMail: string;
}

const FormStep2 = ({ userMail }: userMailProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstEnter, setFirstEnter] = useState(true);
  const [buttonController, setButtonController] = useState(true);
  const [resendCounter, setResendCounter] = useState(60);
  const [attemptCounter, setAttemptCounter] = useState(0);

  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty },
  } = useForm<{ verificationCode: string }>();
  const { verificationCode } = useAuthValidation();

  const sendNewCode = async () => {
    setButtonController(true);
    await resendEmailVerificationCode({ email: userMail });
    successHandler(t('success.resendCode'));

    // 設定計時器
    const timer = setInterval(() => {
      setResendCounter((prev) => prev - 1);
    }, 1000);
  };

  // 監視timer, 當resendCounter為0時, 將buttonController設為false並重新設定resendCounter回60
  useEffect(() => {
    if (firstEnter) {
      // 設定計時器, 每秒讓resendCounter - 1直到0時, 將buttonController設為false並重新設定resendCounter回60
      const timer = setInterval(() => {
        setResendCounter((prev) => prev - 1);
        if (resendCounter === 0) {
          clearInterval(timer);
        }
      }, 1000);
      setFirstEnter(false);
    }

    if (resendCounter === 0) {
      setButtonController(false);
      setResendCounter(60);
    }
  }, [resendCounter]);

  const onSubmit = handleSubmit(async (formData) => {
    const data: RegisterVerificationRequest = {
      ...formData,
      email: userMail,
    };

    try {
      const res = (await registerVerification(data)).data;
      if (res.success) {
        dispatch(
          signIn({
            isLogin: true,
            accessToken: '',
            registDone: false,
            userData: { email: userMail, verificationCode: data.verificationCode },
          })
        );
        navigate('/registration?step=1');
      }
    } catch (error) {
      setAttemptCounter((prev) => prev + 1);
      setError('verificationCode', {
        type: 'server',
        message: t('error.verification'),
      });
    }
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Text marginTop={'15px'}>
          {t('pages.auth.signUp.illustrate.step2-prev')}
          <span style={{ color: theme.colors.primary500 }}> {userMail}</span>. <br />{' '}
          {t('pages.auth.signUp.illustrate.step2-last')}
        </Text>
        <InputWrapper>
          <Controller
            control={control}
            defaultValue=""
            name={'verificationCode'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={`${t('form.verificationCode.label')} ${
                  attemptCounter > 0 ? `(${t('attempt')} ${attemptCounter})` : ''
                }`}
                placeholder={t('form.verificationCode.placeholder')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={verificationCode}
          />
        </InputWrapper>
        <Flex gridGap={'15px'} flexDirection={isMobile ? 'column' : 'row'}>
          <Button size={'lg'} variant={'primary'} rounded={false} disabled={!isDirty || attemptCounter > 4}>
            {t('buttons.verify')}
          </Button>
          {attemptCounter < 5 && !buttonController && (
            <Button type={'button'} onClick={sendNewCode} size={'lg'} variant={'primary'} rounded={false}>
              {t('buttons.resendCode')}
            </Button>
          )}
          {buttonController && (
            <Button type={'button'} size={'lg'} variant={'normal'} rounded={false}>
              {`${t('pages.auth.signUp.prompt.step2.wait')} ${resendCounter}${t(
                'pages.auth.signUp.prompt.step2.resend'
              )}`}
            </Button>
          )}
        </Flex>
      </form>
    </>
  );
};

export { FormStep1, FormStep2 };

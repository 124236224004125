import PageContainer from '@/components/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import Form from './Form';

const AccountEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <PageContainer title={t('accountInformation')}>
      <div style={{ display: 'flex', width: '100%' }}>
        <Form />
      </div>
    </PageContainer>
  );
};

export default AccountEdit;

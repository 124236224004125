import React from 'react';

import { FileName } from './styles';

export interface FileListProps {
  files: File[];
}

const UploadFileList = React.memo(({ files }: React.PropsWithChildren<FileListProps>) => {
  return (
    <ul>
      {files.map((file: File) => (
        <li key={`${file.name}_${file.lastModified}`}>
          <FileName>{file.name}</FileName> <FileName>({Math.round(file.size / 1000)}kb)</FileName>
        </li>
      ))}
    </ul>
  );
});

export default UploadFileList;

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { ModalWrapper } from './styles';
import { Check, Close } from '@/components/Icon';
import Loading from '../../Loading';
import { Flex } from '@/layout';
import { Title } from '@/pages/Subscription/style';
import { Modal, TextField } from '@mui/material';

import { couponCheck, couponUse } from '@/api/coupon.api';
import useUserDetail from '@/hooks/useUserDetail';
import { theme } from '@/theme/Theme';

interface CouponModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CouponFormRequest {
  code: string;
}

const CouponModal = ({ showModal, setShowModal }: CouponModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<boolean | null>(false);
  const [resStatus, setResStatus] = useState<boolean | null>(false);

  const { handleSubmit, reset, control } = useForm<CouponFormRequest>();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      const rawData = {
        code: data.code,
      };
      const res = (await couponCheck(rawData)).data;
      if (res.success) {
        await couponUse(rawData);
        setResStatus(true);
      }
    } catch (error: any) {
      setResStatus(false);
    }
    setIsLoading(false);
    reset();
    setResponse(true);
    // setShowModal(false);
  });

  // 關閉時將setResponse設為false
  const handleClose = () => {
    reset();
    setShowModal(false);
    setResponse(false);
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalWrapper>
        <Close.ButtonClose onClick={handleClose}>
          <Close.CloseSVG />
        </Close.ButtonClose>

        {!response && (
          <Title style={{ marginRight: 'auto', marginBottom: 30 }}>{t('pages.index.Pricing.coupons')}</Title>
        )}

        {isLoading && <Loading />}
        {!isLoading && !response && (
          <>
            <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
              <InputWrapper>
                <Controller
                  control={control}
                  name={'code'}
                  defaultValue={''}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label=""
                      variant="standard"
                      value={value}
                      sx={{ width: '100%', marginTop: '15px' }}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete="off"
                    />
                  )}
                />
              </InputWrapper>
            </Flex>

            <Flex gridGap={'20px'} paddingTop={30}>
              <Button size={'lg'} variant={'primary'} onClick={onSubmit}>
                {t('buttons.ok')}
              </Button>
              <Button
                size={'lg'}
                variant={'primary-invert'}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t('buttons.cancel')}
              </Button>
            </Flex>
          </>
        )}
        {response && resStatus && (
          <Flex flexDirection={'column'} alignItems={'center'} gridGap={15}>
            <UpdateUserDetail />
            <Check.CheckSVG width="60px" height="60px" fill={theme.colors.secondary500} />
            <Text>{t('success.coupon')}</Text>
          </Flex>
        )}

        {response && !resStatus && (
          <Flex flexDirection={'column'} alignItems={'center'} gridGap={15}>
            <Check.CancelSVG width="60px" height="60px" fill={theme.colors.red} />
            <Text>{t('error.coupon')}</Text>
          </Flex>
        )}
      </ModalWrapper>
    </Modal>
  );
};

const UpdateUserDetail = () => {
  const userDetai = useUserDetail({});
  return <></>;
};

export default CouponModal;

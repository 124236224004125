import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PageContainer from '@/components/Container/PageContainer';
import { Flex } from '@/layout';
import { Check } from '@/components/Icon';
import { useAppSelector } from '@/redux/store.hook';
import { theme } from '@/theme/Theme';
import { useDispatch } from 'react-redux';
import { getAuthSelector, switchLayout } from '@/redux/auth/auth.slice';

const Text = styled.p`
  font-size: ${(p) => p.theme.fontSizes.md};
  color: ${(p) => p.theme.colors.gray300};
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 930px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.colors.light};
  border-radius: 15px;
`;

const GuestSign = ({ type }: { type: string }) => {
  // @ts-ignore
  const { t } = useTranslation();

  const auth = useAppSelector(getAuthSelector);
  const dispatch = useDispatch();

  // 進入此頁時, 更新user資料
  useEffect(() => {
    if (auth.isLogin) return;
    dispatch(switchLayout(true));
  }, []);

  return (
    <PageContainer title={type === 'success' ? t('signDone') : t('signCancel')}>
      <FormWrapper>
        <Flex flexDirection={'column'} gridGap={15} alignItems={'center'}>
          {type === 'success' ? (
            <Check.CheckSVG fill={theme.colors.success} width="96" height="96" />
          ) : (
            <Check.CancelSVG fill={theme.colors.danger} width="96" height="96" />
          )}
          <Text>{type === 'success' ? t('signSuccess') : t('signCancel')}</Text>
        </Flex>
      </FormWrapper>
    </PageContainer>
  );
};

export default GuestSign;

import { lighten, opacify } from 'polished';
import styled from 'styled-components';

const StyledTable = styled.div`
  width: 100%;
  input,
  select {
    ${(p) => p.theme.tableInputBorder}
  }
  table {
    width: 100%;
    margin-bottom: ${(p) => p.theme.space[3]};
    border-collapse: collapse;
    tbody {
      tr {
        border-bottom: 2px solid ${(p) => p.theme.colors.gray200};
        &:nth-child(odd) {
          background-color: ${(p) => opacify(0.5, p.theme.colors.gray100)};
        }
        &:nth-child(even) {
          background-color: ${(p) => p.theme.colors.light};
        }
        &.selected {
          background-color: ${(p) => lighten(0.25, p.theme.colors.secondary200)};
        }
      }
    }
    th {
      text-align: left;
      color: ${(p) => p.theme.colors.gray300};
      border-bottom: 2px solid ${(p) => p.theme.colors.gray200};
      div {
        position: relative;
        &.pointer {
          cursor: pointer;
          user-select: none;
        }
        .sort-icon {
          position: absolute;
          right: 0;
          width: 20px;
          height: 20px;
          &:before,
          &:after,
          .asc,
          .desc {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 0;
            width: 0;
            border: 5px solid transparent;
          }
          &:before {
            border-bottom-color: ${(p) => p.theme.colors.gray100};
            margin-top: -8px;
          }
          &:after {
            border-top-color: ${(p) => p.theme.colors.gray100};
            margin-top: 6px;
          }
          .asc {
            margin-top: -8px;
            border-bottom-color: #000;
          }
          .desc {
            margin-top: 6px;
            border-top-color: #000;
            z-index: 1;
          }
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .total {
    font-size: ${(p) => p.theme.fontSizes.sm};
    color: ${(p) => p.theme.colors.gray200};
  }
  .pages {
    display: flex;
    gap: 40px;
    .display {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .change-page {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        width: 40px;
        height: 32px;
        background-color: ${(p) => p.theme.colors.gray100};
        border-radius: ${(p) => p.theme.radii.base};
        :hover:enabled {
          background-color: ${(p) => p.theme.colors.primary400};
          color: ${(p) => p.theme.colors.light};
          cursor: pointer;
        }
        :active:enabled {
          background-color: ${(p) => p.theme.colors.primary500};
        }
      }
      span {
        display: flex;
        gap: 2px;
        align-items: center;
      }
      input {
        width: 50px;
        padding: 3px 5px;
      }
    }
  }
`;

export { StyledTable, Pagination };

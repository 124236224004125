import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
  padding: 100px 0;
  min-height: 65vh;
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: ${(p) => (p.isMobile ? '30px' : '80px')}};
  text-align: center;
`;

const LogoPic = styled.img`
  width: 100%;
  max-width: 330px;
  object-fit: contain;
  object-position: center;
`;

export { ContainerWrapper, Title, LogoPic };

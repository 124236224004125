import PageContainer from '@/components/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import ContractTemplatesList from '@/components/DealList/SignList/ContractTemplatesList';

const ContractExamples = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <PageContainer title={t('contractExample')}>
     <ContractTemplatesList />
    </PageContainer>
  );
};

export default ContractExamples;

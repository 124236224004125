import styled from 'styled-components';

const GoTop = styled.div<{ isTablet: boolean, isMobile: boolean }>`
  position: fixed;
  bottom: ${(p) => (p.isMobile ? '50px' : p.isTablet ? '60px' : '130px')};
  right: ${(p) => (p.isMobile ? '15px' : p.isTablet ? '40px' : '150px')};
  width: 80px;
  height: 80px;
  background-color: ${(p) => p.theme.colors.secondary400};
  border-radius: 15px 15px 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  z-index: 1;
  padding-top: 43px;
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  line-height: 23px;
  color: ${(p) => p.theme.colors.light};

  :hover {
    filter: brightness(1.1);
  }

  ::after {
    content: '';
    background-image: url('/images/index/up-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export { GoTop };

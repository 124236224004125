const TranslateFakeData = [
    {
        name: 'Lorenzo di Bonaventura',
        folder: 'RFQ',
        type: 'pdf',
        size: '2 MB',
        updated: '2022/07/31 16:14:08',
        action: 'action'
    }
];

export { TranslateFakeData };

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Introduction from '@/components/ETrust/CreditReport/Introduction';
import Information from '@/components/ETrust/CreditReport/Information';
import RegistrationForm from '@/components/ETrust/CreditReport/RegistrationForm';
import Stepline from '@/components/ETrust/Stepline';
import useUserDetail from '@/hooks/useUserDetail';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

const CreditReport = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const userDetail = useUserDetail({});
  const { isLogin, userData } = useAppSelector(getAuthSelector);
  const [activeStep, setActiveStep] = useState<number>(0);
  const stepline = t('pages.eTrust.creditReport.stepline', { returnObjects: true });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step'));

  const [data, setData] = useState<CreditReportResponse>();

  useEffect(() => {
    if (!searchParams.get('step')) {
      setCurrentStep('1');
      navigate('/creditReport?step=1', { replace: true });
      setActiveStep(0);
    }

    if (!isLogin) {
      navigate('/auth/signin', { replace: true });
      return;
    }

    if (!currentStep || parseInt(currentStep) === 0) {
      setCurrentStep('1');
      navigate('/creditReport?step=1');
      setActiveStep(0);
    } else {
      const paramsStep = parseInt(currentStep);
      setActiveStep(paramsStep - 1);
    }
  }, []);

  return (
    <PageContainer title={t('pages.eTrust.creditReport.title')}>
      <div style={{ display: 'flex' }}>
        <Stepline activeStep={activeStep} stepline={stepline} type={'CreditReport'} />
        {activeStep === 0 && (
          <Introduction setData={setData} setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
        )}
        {activeStep === 1 && (
          <RegistrationForm setData={setData} setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
        )}
        {activeStep === 2 && <Information data={data} setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />}
      </div>
    </PageContainer>
  );
};

export default CreditReport;

import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageContainer from '@/components/Container/PageContainer';
import { getMeetingDetail, joinMeeting, joinMeetingForGuest } from '@/api/event.api';

import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { errorHandler } from '@/utils/toastHandler';
import { useTranslation } from 'react-i18next';

const JoinMeeting = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAppSelector(getAuthSelector);

  useEffect(() => {
    getDeatil();
  }, []);

  const getDeatil = async () => {
    const meetingID = searchParams.get('meetingID');

    if (!meetingID) {
      errorHandler(t('error.server'));
      navigate('/home');
    }

    if (auth.isLogin) {
      getHostLink(meetingID!);
    } else {
      getGuestLink(meetingID!);
    }
  };

  const getGuestLink = async (meetingID: string) => {
    try {
      const res = (await joinMeetingForGuest(meetingID)).data;
      window.location.href = res.data;
    } catch (err) {
      errorHandler(t('error.server'));
      window.location.href = '/';
    }
  };

  const getHostLink = async (meetingID: string) => {
    try {
      const reqData = `meetingID=${meetingID}`;
      const res = (await joinMeeting(reqData)).data;
      window.location.href = res.data;
    } catch (error) {
      errorHandler(t('error.server'));
      navigate('/home');
    }
  };

  return <PageContainer children={<></>} title={'Redirecting...'}></PageContainer>;
};

export default JoinMeeting;

import React from 'react';
import styled from 'styled-components';

import { H1 } from '@/components/UI/Typography/Heading';
import { Flex } from '@/layout';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useResize from '@/hooks/useResize';

const Wrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  min-height: calc(100vh - 70px);
  padding: ${(p) => (p.isMobile ? '20px 15px' : p.isTablet ? '20px 60px' : '20px 60px 60px 300px')};
  background: #f5f5f5;
  overflow-y: auto;

  .pageContainerTitle {
    font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.lg : p.theme.fontSizes.xl)};
    font-weight: 700;
    text-align: ${(p) => (p.isTablet ? 'center' : 'left')};
  }
`;

const YellowPageWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  padding: ${(p) => (p.isMobile ? '0' : '0 60px')};
  background: #f5f5f5;
  overflow-y: auto;
  margin: auto;
`;

interface PageContainerProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

const PageContainer = ({ title, children }: PageContainerProps) => {
  const auth = useAppSelector(getAuthSelector);
  const { isMobile, isTablet } = useResize();

  if (title === 'e-Directory' && !auth.isLogin) return <YellowPageWrapper isMobile={isMobile} isTablet={isTablet}>{children}</YellowPageWrapper>;

  return (
    <Wrapper isMobile={isMobile} isTablet={isTablet}>
      {title !== 'e-Directory' && (
        <Flex flexDirection={'column'} justifyContent={'flex-end'} gridGap={'10px'}>
          {React.isValidElement(title) ? title : <div className="pageContainerTitle">{title}</div>}
          {!isTablet && <hr style={{ marginBottom: '30px' }} />}
        </Flex>
      )}
      {children}
    </Wrapper>
  );
};

export default PageContainer;

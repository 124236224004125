import { FormControl, FormHelperText, TextField } from '@mui/material';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { NumberCountWrapper } from './styles';
import { Button } from '@/components/UI/Button';
import { theme } from '@/theme/Theme';

interface NumberCounterProps {
  control: Control<any>;
  name: string;
  register?: RegisterOptions;
  variant?: 'outlined' | 'standard' | 'filled';
  add?: () => void;
  minus?: () => void;
}

const FormSearch = ({
  control,
  register,
  name,
  variant = 'outlined',
  add = () => {},
  minus = () => {},
}: NumberCounterProps) => {
  return (
    <NumberCountWrapper>
      <button type="button" onMouseDown={minus}>
        -
      </button>
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl>
            <TextField
              type="text"
              variant={variant}
              value={value}
              sx={{ width: '100px' }}
              onChange={onChange}
              onKeyDown={onChange}
              error={!!error}
              placeholder="0-100"
              autoComplete="off"
              size="small"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 100 }}
            />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
        name={name}
        rules={register}
      />
      <button type="button" onMouseDown={add}>
        +
      </button>
    </NumberCountWrapper>
  );
};

export default FormSearch;

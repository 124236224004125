import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import Rating from '@/components/Rating';
import { useLocation } from 'react-router-dom';

const CompanyComments = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const location = useLocation();
  const [companyId, setCompanyId] = useState<string>();

  useEffect(() => {
    if (location.pathname.replace('/eDirectory/', '') !== '0') {
      setCompanyId(location.pathname.replace('/eDirectory/', ''));
    }
  }, []);

  return (
    <PageContainer title={<InnerPageTitle link={''} subtitle={'Comments'} />}>
      {companyId && <Rating user_id={parseInt(companyId)} />}
    </PageContainer>
  );
};

export default CompanyComments;

import { useCallback, useEffect, useState } from 'react';
import { Link, UNSAFE_DataRouterStateContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useUserBadges from '@/hooks/useUserBadges';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import {
  Button,
  ContentWrapper,
  MainContent,
  List,
  Row,
  IconChecked,
  ButtonGroup,
  Status,
  SupplementallInfoList,
} from '../style';
import { AccountSideMenu } from '@/components/SideMenu/AccountSideMenu';
import { Box } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { getLEI } from '@/api/lei.api';
import useResize from '@/hooks/useResize';

interface InfoStatus {
  data: {
    status: number;
  };
}

const ProfileEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const { userData } = useAppSelector(getAuthSelector);
  const [role, setRole] = useState(1);
  const [infoStatus, setInfoStatus] = useState({
    lei: -99,
    identification: -99,
    eCert: -99,
    creditReport: -99,
  });
  const { company, supplemental, person, eCert, creditReport, identification }: BadgeGroup = useUserBadges();

  const [featureControl] = useState(true);

  const checkLEI = useCallback(async () => {
    try {
      const res: InfoStatus = (await getLEI()).data;
      setInfoStatus({ ...infoStatus, lei: res.data.status });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    checkLEI();
  }, [checkLEI]);

  // useEffect(() => {
  //   if (auth.userData) {
  //     setRole(auth.userData.role);
  //   }
  // }, [auth.userData]);

  const checkMarkHandler = (statusCode: string) => {
    // 審核中
    if (statusCode === '1') {
      return <Status className="applying">{t('badges.status.applying')}</Status>;
    }

    // 審核成功
    if (statusCode === '2') {
      return <Status className="success">{t('badges.status.successfulApplication')}</Status>;
    }

    // 審核失敗
    if (statusCode === '3') {
      return <Status className="failed">{t('badges.status.applicationFailed')}</Status>;
    }
  };

  const statusChecker = (statusCode: number) => {
    if (statusCode === -99) return;
    if (statusCode === 1) {
      return <Button status={'applying'}>{t('badges.status.applying')}</Button>;
    }
    if (statusCode === 2) {
      return <Button status={'success'}>{t('badges.status.successfulApplication')}</Button>;
    }
    if (statusCode === 3) {
      return <Button status={'danger'}>{t('badges.status.applicationFailed')}</Button>;
    }
  };

  return (
    <PageContainer title={<InnerPageTitle link={'/home'} title={t('dashboard')} subtitle={t('editProfile')} />}>
      <ContentWrapper>
        <MainContent isTablet={isTablet}>
          {!featureControl && (
            <List>
              <Row isTablet={isTablet}>
                {
                  <>
                    <span>{t('accountInformation')}</span>
                    <ButtonGroup isTablet={isTablet}>
                      <Link to={'/profile/accountEdit'}>
                        <Button>{t('buttons.edit')}</Button>
                      </Link>
                    </ButtonGroup>
                  </>
                }
              </Row>

              <Row isTablet={isTablet}>
                {role ? (
                  <>
                    <IconChecked isTablet={isTablet} className={company !== '1' ? 'checking' : ''}>
                      <Box>
                        <img width={'56px'} src="/images/profile/company.svg" alt="" />
                      </Box>
                      <div className="checkIcon">
                        <img src="/images/profile/check.svg" alt="" />
                      </div>
                    </IconChecked>
                    <span>{t('companyInformation')}</span>
                    <ButtonGroup isTablet={isTablet}>
                      <Link to={'/profile/companyinfo'}>
                        <Button>{t('buttons.edit')}</Button>
                      </Link>
                    </ButtonGroup>
                  </>
                ) : (
                  <>
                    <IconChecked isTablet={isTablet} className={person !== '1' ? 'checking' : ''}>
                      <Box>
                        <img width={'56px'} src="/images/profile/company.svg" alt="" />
                      </Box>
                      <div className="checkIcon">
                        <img src="/images/profile/check.svg" alt="" />
                      </div>
                    </IconChecked>
                    <span>{t('personalInfo')}</span>
                    <ButtonGroup isTablet={isTablet}>
                      <Link to={'/profile/personalinfo'}>
                        <Button>{t('buttons.edit')}</Button>
                      </Link>
                    </ButtonGroup>
                  </>
                )}
              </Row>

              {role === 1 && (
                <Row isTablet={isTablet}>
                  <IconChecked isTablet={isTablet} className={supplemental !== '2' ? 'checking' : ''}>
                    <Box>
                      <img width={'56px'} src="/images/profile/supplementalInfo.svg" alt="" />
                    </Box>
                    <div className="checkIcon">
                      <img src="/images/profile/check.svg" alt="" />
                    </div>
                  </IconChecked>

                  <span>{t('supplementalInformation')}</span>
                  <ButtonGroup isTablet={isTablet}>
                    {/* <span>Legal Entity Identifier Code</span>
                    {checkMarkHandler(supplemental)} */}
                    <Link to={'/profile/supplementalInfo'}>
                      <Button>{t('buttons.fillIn')}</Button>
                    </Link>
                  </ButtonGroup>
                </Row>
              )}

              {/* <Row isTablet={isTablet}>
                <IconChecked isTablet={isTablet} className={eCert !== '2' ? 'checking' : ''}>
                  <ECertBadge align="center" width="56px" />
                  <div className="checkIcon">
                    <img src="/images/profile/check.svg" alt="" />
                  </div>
                </IconChecked>
  
                <span>eCert</span>
                <ButtonGroup isTablet={isTablet}>
                  <span>eCert</span>
                  {checkMarkHandler(eCert)}
                  <Link to={'/profile/eCert'}>
                    <Button>{t('buttons.fillIn')}</Button>
                  </Link>
                </ButtonGroup>
              </Row> */}

              {/* {role === 1 && (
                <Row isTablet={isTablet}>
                  <IconChecked isTablet={isTablet} className={creditReport !== '2' ? 'checking' : ''}>
                    <CreditReportBadge align="center" width="56px" />
                    <div className="checkIcon">
                      <img src="/images/profile/check.svg" alt="" />
                    </div>
                  </IconChecked>
                  <span>Credit Report</span>
                  <ButtonGroup isTablet={isTablet}>
                    <span>Credit Report</span>
                    {checkMarkHandler(creditReport)}
                    <Link to={'/profile/creditReport'}>
                      <Button>{t('buttons.fillIn')}</Button>
                    </Link>
                  </ButtonGroup>
                </Row>
              )} */}
            </List>
          )}
          {featureControl && (
            <List>
              <Row isTablet={isTablet}>
                <IconChecked isTablet={isTablet} className={company !== '1' ? 'checking' : ''}>
                  <Box>
                    <img width={'56px'} src="/images/badge/user_info.svg" alt="" />
                  </Box>
                  <div className="checkIcon">
                    <img src="/images/profile/check.svg" alt="" />
                  </div>
                </IconChecked>
                <Text fontWeight={700}>{t('companyInfo&AccountInfo')}</Text>
                <ButtonGroup isTablet={isTablet}>
                  <Link to={'/profile/companyinfo'}>
                    <Button>{t('companyInformation')}</Button>
                  </Link>
                  <Link to={'/profile/accountEdit'}>
                    <Button>{t('accountInformation')}</Button>
                  </Link>
                </ButtonGroup>
              </Row>

              <SupplementallInfoList isTablet={isTablet}>
                <div className="item">
                  <Text fontWeight={700}>{t('supplementalInformation')}</Text>
                  <ButtonGroup isTablet={isTablet}>
                    <Link to={'/profile/supplementalInfo'}>
                      <Button>{t('buttons.fillIn')}</Button>
                    </Link>
                  </ButtonGroup>
                </div>
                <div className="item">
                  <IconChecked isTablet={isTablet} className={infoStatus.lei === 2 ? '' : 'checking'}>
                    <Box>
                      <img width={'56px'} src="/images/badge/identification_check.svg" alt="" />
                    </Box>
                    <div className="checkIcon">
                      <img src="/images/profile/check.svg" alt="" />
                    </div>
                  </IconChecked>
                  <Text fontWeight={700}>{t('LEI')}</Text>
                  <ButtonGroup isTablet={isTablet}>
                    <Link to={'/lei?step=1'}>
                      <Button>{t('buttons.fillIn')}</Button>
                    </Link>
                  </ButtonGroup>
                </div>
                {/* <div className="item">
                  <IconChecked isTablet={isTablet} className={company !== '1' ? 'checking' : ''}>
                    <Box>
                      <img width={'56px'} src="/images/badge/identification_check.svg" alt="" />
                    </Box>
                    <div className="checkIcon">
                      <img src="/images/profile/check.svg" alt="" />
                    </div>
                  </IconChecked>
                  <Text fontWeight={700}>{t('identificationCheck')}</Text>
                  <ButtonGroup isTablet={isTablet}>
                    <Link to={'/profile/edit'}>
                      <Button>{t('buttons.edit')}</Button>
                    </Link>
                  </ButtonGroup>
                </div> */}
                {/* <div className="item">
                  <IconChecked isTablet={isTablet} className={'checking'}>
                    <Box>
                      <img width={'56px'} src="/images/badge/ecert.svg" alt="" />
                    </Box>
                    <div className="checkIcon">
                      <img src="/images/profile/check.svg" alt="" />
                    </div>
                  </IconChecked>
                  <Text fontWeight={700}>{t('eCert')}</Text>
                  <ButtonGroup isTablet={isTablet}>
                    <Button status={'danger'}>Application failed</Button>
                    <Link to={'/profile/edit'}>
                      <Button>{t('buttons.fillIn')}</Button>
                    </Link>
                  </ButtonGroup>
                </div> */}
                <div className="item">
                  <IconChecked isTablet={isTablet} className={'checking'}>
                    <Box>
                      <img width={'56px'} src="/images/badge/credit_report.svg" alt="" />
                    </Box>
                    <div className="checkIcon">
                      <img src="/images/profile/check.svg" alt="" />
                    </div>
                  </IconChecked>
                  <Text fontWeight={700}>{t('creditReport')}</Text>
                  <ButtonGroup isTablet={isTablet}>
                    {/* <Button status={'success'}>Successful application</Button> */}
                    <Link to={'/creditReport?step=1'}>
                      <Button>{t('buttons.fillIn')}</Button>
                    </Link>
                  </ButtonGroup>
                </div>
              </SupplementallInfoList>

              {/* <Row isTablet={isTablet}>
                <IconChecked isTablet={isTablet} className={'checking'}>
                  <Box>
                    <img width={'56px'} src="/images/profile/product.svg" alt="" />
                  </Box>
                </IconChecked>
                <Text fontWeight={700}>{t('myProduct')}</Text>
                <ButtonGroup isTablet={isTablet}>
                  <Link to={'/profile/edit'}>
                    <Button>{t('buttons.edit')}</Button>
                  </Link>
                </ButtonGroup>
              </Row> */}
            </List>
          )}
        </MainContent>
        {!isMobile && <AccountSideMenu />}
      </ContentWrapper>
    </PageContainer>
  );
};

export default ProfileEdit;

import type { DataTableState } from './dataTable.slice';

const uploadDataTableIds = (dataTableItem: DataTableState, newSelected: { selectedDataIds: string[]; selectedRowIds: string[]; key: string }) => {
  const key = newSelected.key;
  const selectedDataIds = newSelected.selectedDataIds;
  let selectedRowIds: {} = {};
  for (let index = 0; index < newSelected.selectedRowIds.length; index++) {
    const element = newSelected.selectedRowIds[index];
    selectedRowIds = {
      ...selectedRowIds,
      [element]: true,
    };
  }
  return { ...dataTableItem, [key]: { selectedRowIds, selectedDataIds } };
};

export { uploadDataTableIds };

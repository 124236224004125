import type { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useRegistrationValidation = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const companyInfo = () => {
    const regionRegister: RegisterOptions = {
      required: t('required.region'),
    };

    const countryCodeRegister: RegisterOptions = {
      required: t('required.countryCode'),
    };

    const mobileRegister: RegisterOptions = {
      minLength: {
        value: 6,
        message: t('pattern.mobile'),
      },
      required: t('required.mobile'),
    };

    const categoryRegister: RegisterOptions = {
      required: t('required.category'),
    };

    const serviceRegister: RegisterOptions = {
      required: t('required.service'), 
    };

    const titleRegister: RegisterOptions = {
      required: t('required.title'),
    };

    const jobPositionRegister: RegisterOptions = {
      required: t('required.jobPosition'),
    };

    const businessRegister: RegisterOptions = {
      required: t('required.businessRegistrationNumber'),
    };

    const companyNameRegister: RegisterOptions = {
      required: t('required.companyName'),
    };

    const giveNameRegister: RegisterOptions = {
      required: t('required.giveName'),
    };

    const familyNameRegister: RegisterOptions = {
      required: t('required.familyName'),
    };

    return {
      regionRegister,
      countryCodeRegister,
      mobileRegister,
      categoryRegister,
      serviceRegister,
      titleRegister,
      jobPositionRegister,
      businessRegister,
      companyNameRegister,
      giveNameRegister,
      familyNameRegister,
    };
  };

  return {
    companyInfo,
  };
};

export default useRegistrationValidation;

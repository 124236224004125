import { useMemo, useCallback, useState, useEffect } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { successHandler } from '@/utils/toastHandler';

import { CardContainer } from '@/layout/Card';
import { Box, Flex, LoadingPage } from '@/layout';
import DataTable, { Tag, TypeColumn, FileMenu } from '@/components/DataTable';
import { Sign } from '@/components/Icon';
import { ArrowTooltip } from '@/components/UI/Tooltip';
import { Button } from '@/components/UI/Button';
import { DropZone, UploadFileList } from '@/components/UI/FileDropzone';
import Loading from '@/components/UI/Loading';

import { addFileToDeal, getDealFileList, deleteFileFromDeal, InviteToSign, getDealDetail } from '@/api/deal.api';
import { convertLink, convertToData, convertToFileSize } from '@/utils/convert';
import PATH from '@/router/path';

import { theme } from '@/theme/Theme';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { Text } from '@/components/UI/Typography/Text';

type DataRow = {
  id: string;
  acc_id: string;
  file_id: string;
  isOfficialDoc: boolean;
  name: string;
  type: string;
  path: string;
  size: string;
  updated: string;
  sign: {
    need: boolean;
    isSign: boolean;
    signStatus: number;
    needSign: number;
    firstSigner: number;
  };
  action: string;
  prcSigning: { name: string; sign: boolean }[];
  hkSigning: { name: string; sign: boolean }[];
  hash: string;
};

interface FilesProps {
  id?: string;
}

const Files = ({ id }: FilesProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  const rawColumn = t('pages.dealDetail.manageFiles.files.table.columns', { returnObjects: true });

  const [isDropActive, setIsDropActive] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileList, setFileList] = useState<DataRow[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [participantsId, setParticipantsId] = useState<string>('');

  const menuItems: DataTableMenuItems[] = useMemo(
    () => [
      {
        name: t('buttons.download'),
        onClick: (path) => {
          if (!path) return;

          const link = document.createElement('a');
          link.setAttribute('download', '');
          link.href = convertLink(path);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
      },
      // {
      //   name: t('buttons.edit'),
      //   onClick: () => {
      //     window.open('https://ebram-backend.oao.lol/test_editor')
      //   },
      // },
      // {
      //   name: t('buttons.translate'),
      //   onClick: () => {

      //   },
      // },
    ],
    [t]
  );

  const subMenuItems = useCallback(
    ({
      danger,
      documentId,
      signStatus,
    }: {
      danger: boolean;
      documentId: string;
      signStatus: number;
    }): DataTableMenuItems[] => [
      {
        name:
          signStatus === 0
            ? t('buttons.inviteToSign')
            : signStatus === 1
            ? t('buttons.inviteToSign')
            : signStatus === 2
            ? t('buttons.startupSign')
            : t('buttons.cancelSign'),
        danger: danger,
        onClick: async () => {
          if (danger || !id) return;
          setIsLoading(true);
          if (signStatus === 0) {
            try {
              const res = (await InviteToSign({ dealId: id, fileId: documentId })).data;
              if (res.success) {
                // 更新此文件的簽署狀態
                const fileListRes = (await getDealFileList(id)).data;
                updateDocLibrary(fileListRes.data);
                successHandler(t('success.invite'));
              }
            } catch (error) {}
          }
          if (signStatus === 2) {
            navigate(PATH.deal.detail.contractSigning?.replace(':id', id as string).replace(':fileId', documentId));
          }

          setIsLoading(false);
        },
      },
      {
        name: t('buttons.delete'),
        onClick: async () => {
          if (!id) return;
          setIsLoading(true);
          try {
            const res = (await deleteFileFromDeal({ deal_id: id, deal_document_id: documentId })).data;
            const fileListRes = (await getDealFileList(id)).data;
            updateDocLibrary(fileListRes.data);
            successHandler(t('success.delete'));
          } catch (error) {}
          setIsLoading(false);
        },
      },
    ],
    [id, navigate, t]
  );

  const columns: ColumnDef<DataRow>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: rawColumn[0],
        sortable: true,
        cell: ({ row }) => (
          <Flex alignItems={'center'} gridGap={'5px'}>
            {row.original.sign.need && (
              <ArrowTooltip title={row.original.sign.isSign ? '' : t('buttons.pending')} placement={'top'}>
                <Sign.SignSVG fill={row.original.sign.isSign ? theme.colors.secondary500 : theme.colors.danger} />
              </ArrowTooltip>
            )}
            <div>
              {row.original.name}
              {row.original.hash && (
                <Text fontSize={theme.fontSizes.sm} color={theme.colors.gray300}>
                  {row.original.hash && row.original.hash.slice(0, 7) + '...' + row.original.hash.slice(-7)}
                </Text>
              )}
            </div>
            {row.original.isOfficialDoc && <Tag>{t('buttons.officialDoc')}</Tag>}
          </Flex>
        ),
      },
      {
        accessorKey: 'type',
        header: rawColumn[2],
        cell: ({ row }) => <TypeColumn text={row.original.type} />,
      },
      {
        accessorKey: 'size',
        header: rawColumn[3],
      },
      {
        accessorKey: 'updated',
        header: rawColumn[4],
      },
      {
        accessorKey: 'sign',
        header: '',
        cell: ({ row }) =>
          row.original.type === 'pdf' ? (
            row.original.sign.signStatus === 1 ? (
              <Button size={'table'} variant={'yellow'} special={'fake'}>
                {t('buttons.inviting')}
              </Button>
            ) : row.original.sign.signStatus === 2 ? (
              <Link to={`/deal/${id}/contractSigning/${row.original.id}`}>
                <Button size={'table'} variant={'orange'} special={'fake'}>
                  {t('buttons.sign')}
                </Button>
              </Link>
            ) : row.original.sign.signStatus === 3 ? (
              <Button size={'table'} variant={'orange'} special={'fake'}>
                {t('buttons.signed')}
              </Button>
            ) : (
              <></>
            )
          ) : (
            <></>
          ),
      },
      {
        accessorKey: 'action',
        header: rawColumn[6],
        cell: ({ row }) => {
          return (
            <FileMenu
              path={row.original.path}
              fileType={row.original.type}
              items={menuItems}
              subItems={subMenuItems({
                danger: row.original.sign.isSign,
                documentId: row.original.id,
                signStatus: row.original.sign.signStatus,
              })}
            />
          );
        },
      },
    ],
    [menuItems, rawColumn, subMenuItems, t]
  );

  const updateDocLibrary = (resData: DocLibrary[]) => {
    const doc: DataRow[] = resData.map(
      (data): DataRow => ({
        id: data.id,
        acc_id: data.acc_id,
        file_id: data.file_id,
        isOfficialDoc: false,
        name: data.file.name,
        type: data.file.extension,
        path: data.file.path,
        size: convertToFileSize(Number(data.file.size)),
        updated: convertToData(data.file.updated_at),
        sign: {
          need: true,
          isSign: data.signStatus === 3 ? true : false,
          signStatus: data.signStatus,
          needSign: data.needSign,
          firstSigner: data.firstSigner,
        },
        action: '',
        prcSigning: [{ name: '', sign: false }],
        hkSigning: [{ name: '', sign: false }],
        hash: data.hash,
      })
    );
    setFileList(doc);
  };

  const onDragStateChange = useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);

  const onFilesDrop = useCallback((files: File[]) => {
    setFiles(files);
  }, []);

  const onUploadFile = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const res = (await addFileToDeal({ deal_id: id, files: files })).data;
      const fileListRes = (await getDealFileList(id)).data;
      updateDocLibrary(fileListRes.data);
      successHandler(t('success.upload'));
      setFiles([]);
    } catch (error) {}
    setIsLoading(false);
  };

  const initData = useCallback(async () => {
    if (!id) return;
    getDetail();
    try {
      const fileListRes = (await getDealFileList(id)).data;
      updateDocLibrary(fileListRes.data);
    } catch (error) {}
  }, []);

  const getDetail = async () => {
    if (!id) return;
    try {
      const res = (await getDealDetail(id)).data;
      res.data.participants.forEach((item: DealParticipants) => {
        if (item.acc_id !== auth.userData.id) {
          setParticipantsId(item.acc_id);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <Box width={'100%'}>
      <LoadingPage isLoading={isLoading} />
      <DropZone files={files} onDragStateChange={onDragStateChange} onFilesDrop={onFilesDrop} setFiles={setFiles} />
      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} gridGap={'15px'} marginBottom={'20px'}>
        {files.length === 0 ? (
          <p>{t('noFileToUpload')}</p>
        ) : (
          <p>
            {t('filesReadyToUpload')}: {files.length}
          </p>
        )}
        <UploadFileList files={files} />
        {files.length > 0 && (
          <Button size="table" variant={'danger'} onClick={onUploadFile}>
            {t('buttons.upload')}
          </Button>
        )}
      </Flex>
      {fileList ? <DataTable columns={columns} data={fileList} canSearch /> : <Loading />}
    </Box>
  );
};

export default Files;

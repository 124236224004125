import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { BoldText } from './style';

const Terms = () => {
  return (
    <div>
      <BoldText>1. INTERPRETATION</BoldText>
      <br />
      <BoldText>1.1 Definitions:</BoldText>
      <BoldText>Authorised Signatories:</BoldText> Persons specified as “Service Registration Authoriser”, “Authorised Key
      Service Request Persons”, or “Authorised Key Technical Persons” as subsequently duly authorised by the Registered
      Client.
      <br />
      <BoldText>Business Day:</BoldText> a day other than a Saturday, Sunday or public holiday in the Hong Kong Special
      Administrative Region of the People’s Republic of China.
      <br />
      <BoldText>Charges:</BoldText> the charges payable by the Registered Client for the supply of the Services, as set out in
      the Contract Details and Schedule 1.
      <br />
      <BoldText>Conditions:</BoldText> these terms and conditions as set out in Clause 1 (Interpretation) to Clause 10 (Law and
      Jurisdiction) (inclusive).
      <br />
      <BoldText>Contract:</BoldText> the CERTIZEN MyFaceSign Services Agreement between the Registered Client and Certizen
      Limited for the supply of the Services by MyFaceSign, these Conditions and the Schedules.
      <br />
      <BoldText>Client Materials:</BoldText> all materials, information or data supplied by the Registered Client to MyFaceSign.
      Client Materials IPR: all Intellectual Property Rights subsisting in Client Materials.
      <br />
      <BoldText>Deliverables:</BoldText> all documents, products and materials developed by MyFaceSign, its agents,
      subcontractors, personnel or licensors as part of or in relation to the Services in any form, including without
      limitation computer programs, data, reports, manuals and specifications (including drafts).
      <br />
      <BoldText>Government Bureaus and Authorities:</BoldText> any government bodies whom MyFaceSign may contact and obtain
      information from, directly or indirectly, in the process of providing the Services.
      <br />
      <BoldText>Intellectual Property Rights:</BoldText> patents, rights to inventions, copyright and related rights, trade
      marks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair
      competition, rights in designs, rights in computer software, database rights, rights to use, and protect the
      confidentiality of, confidential information (including know-how and trade secrets) and all other intellectual
      property rights, in each case whether registered or unregistered and including all applications and rights to
      apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all
      similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part
      of the world.
      <br />
      <BoldText>Intended Recipients:</BoldText> persons authorised by the Registered Client to use the Services.
      <br />
      <BoldText>MyFaceSign IPRs:</BoldText> all Intellectual Property Rights subsisting in the information held by MyFaceSign
      concerning any client or the business of MyFaceSign and in the Deliverables excluding (i) any Client Materials
      incorporated in them and (ii) Client Materials IPR.
      <br />
      <BoldText>MyFaceSign Platform:</BoldText> an electronic platform maintained by MyFaceSign for the purpose of processing
      the Registered Client’s Service Requests.
      <br />
      <BoldText>Personal Data:</BoldText> information which relates to a living individual and can be used to identify that
      individual.
      <br />
      <BoldText>Process:</BoldText> in the context of Personal Data means any operation or set of operations which are performed
      on personal data, whether or not by automated means, such as collection, recording, organisation, structuring,
      storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or
      otherwise making available, alignment or combination, restriction, erasure or destruction.
      <br />
      <BoldText>Schedule 1:</BoldText> means appendix II attached to the CERTIZEN MyFaceSign Services Agreement.
      <br />
      <BoldText>Service Hours:</BoldText> the period between 9:30 am and 5:30 pm on each and every Business Day, excluding such
      days as MyFaceSign may specify from time to time.
      <br />
      <BoldText>Service Request:</BoldText> a request submitted by the Registered Client to MyFaceSign for the latter to provide
      the Services particularised therein.
      <br />
      <BoldText>Services:</BoldText> the services, including without limitation any Deliverables, to be provided by MyFaceSign
      pursuant to the Contract, as described in Schedule 1.
      <br />
      <BoldText>Services Start Date:</BoldText> the day on which MyFaceSign is to start provision of the Services, as set out in
      the Contract Details.
      <br />
      <br />
      <BoldText>1.2 Interpretation:</BoldText>
      <br />
      <BoldText>(a)</BoldText> A reference to a statute or statutory provision is a reference to it as amended, extended or
      re-enacted from time to time. A reference to a statute or statutory provision includes any subordinate legislation
      made from time to time under that statute or statutory provision.
      <br />
      <BoldText>(b)</BoldText> Any words following the terms <BoldText>including, include, in particular, for example</BoldText> or any
      similar expression shall be construed as illustrative and shall not limit the sense of the words, description,
      definition, phrase or term preceding those terms.
      <br />
      <BoldText>(c)</BoldText> A reference to writing or written includes fax and email.
      <br />
      <BoldText>(d)</BoldText> Any captialised terms not defined in these Conditions shall have the meaning set forth in the
      CERTIZEN MyFaceSign Services Agreement between the Registered Client and Certizen Limited.
      <br />
      <br />
      <BoldText>2. COMMENCEMENT AND TERM</BoldText>
      <br />
      The Contract shall commence on the Effective Date and shall continue, unless terminated earlier in
      accordance with its terms.
      <br />
      <br />
      <BoldText>3. SUPPLY OF SERVICES</BoldText>
      <br />
      <br />
      <BoldText>3.1</BoldText> MyFaceSign shall supply the Services to the Registered Client from the Services Start Date in
      accordance with the Contract.
      <br />
      <br />
      <BoldText>3.2</BoldText> MyFaceSign shall have no obligation to supply the Services or any part thereof until and unless
      MyFaceSign receives a Service Request from the Registered Client.
      <br />
      <br />
      <BoldText>3.3</BoldText> In supplying the Services, MyFaceSign shall:
      <br />
      <BoldText>(a)</BoldText> perform the Services in a timely, competent, professional and diligent manner;
      <br />
      <BoldText>(b)</BoldText> perform the Services in accordance with the service description set out in the Contract
      (including Schedule 1);
      <br />
      <BoldText>(c)</BoldText> comply with all applicable laws, statutes and regulations from time to time in force provided
      that MyFaceSign shall not be liable under the Contract if, as a result of such compliance, it is in breach of any
      of its obligations under the Contract.
      <br />
      <br />
      <BoldText>3.4</BoldText> Unless otherwise specified in Schedule 1 for any part of the Services, MyFaceSign shall retain
      all (i) systematic audit logs and (i) full records of financial transactions related to the Services, including
      but not limited to accounting and billing references, for 7 (seven) years from the time of the receipt of the
      Service Request. MyFaceSign agreed to provide the Registered Client with a monthly financial log and the remaining
      balance of the Charges by email to the Registered Client within 7 Business Days after the end of each calender
      month during the term of the Contract. In any event, the Registered Client shall have access to the transaction
      history relating to its use of the Services from time to time and obtain copies thereof during the term of the
      Contract and 7 (seven) years after the termination of the Contract.
      <br />
      <br />
      <BoldText>3.5</BoldText> MyFaceSign shall provide help desk services during Service Hours to assist the Registered Client
      in submitting Service Requests and to answer general enquiries relating to the Services.
      <br />
      <br />
      <BoldText>3.6</BoldText> MyFaceSign shall notify the Registered Client within a reasonable time after the processing of
      the Service Request has been completed.
      <br />
      <br />
      <BoldText>3.7</BoldText> MyFaceSign shall have the right, though not an obligation, to remove from the MyFaceSign Platform
      any content that, in MyFaceSign’s sole discretion, violates MyFaceSign’s policies, procedures, guidelines or
      terms.
      <br />
      <br />
      <BoldText>3.8</BoldText> MyFaceSign shall make the MyFaceSign Platform accessible 24 hours every day. MyFaceSign may from
      time to time by no less than 5 Business Days’ advance notice specify different available times for different parts
      of the Services. In the event an urgent suspension of the MyFaceSign Platform is required due to security
      incidents, MyFaceSign shall notify the Registered Client as soon as possible without delay and provide a written
      report to the Register Client accounting for such security incidents and solutions.
      <br />
      <br />
      <BoldText>3.9</BoldText> MyFaceSign undertakes to establish and maintain administrative, technical and procedural
      safeguards to protect the information received from the Registered Client and/or Intended Recipients and/or
      Government Bureaus and Authorities from (i) unauthorized access; (ii) accidental or intentional disclosure to
      unauthorized personnel and (iii) unauthorized Processing, including without limitation, unauthorized modification,
      disclosure, reproduction and erasure.
      <br />
      <br />
      <BoldText>3.10</BoldText> MyFaceSign undertakes to comply with all applicable data privacy and security laws and
      regulations in the course of providing its Services via the MyFaceSign Platform.
      <br />
      <br />
      <BoldText>3.11</BoldText> MyFaceSign acknowledges that MyFaceSign shall have access to Personal Data during the provision
      of Services or Deliverables. If any Personal Data is made available or accessible to MyFaceSign, MyFaceSign shall
      only Process such data for the purposes set forth in this Contract and the applicable data protection laws.
      MyFaceSign undertakes to comply with the directions or instructions of the data subjects of such Personal Data
      (which may be communicated to MyFaceSign via the Registered Client) in an efficient and responsive manner.
      <br />
      <br />
      <BoldText>3.12</BoldText> MyFaceSign shall comply fully with applicable data protection laws relating to such Personal
      Data that MyFaceSign receives or has access to under this Contract or in connection with the Registered Client and
      Intended Recipients’ use of the Services or MyFaceSign Platform. MyFaceSign shall not use, disclose, or transfer
      such personal data except as necessary to perform under this Contract or as authorised by this Contract or in
      accordance with applicable law.
      <br />
      <br />
      <BoldText>3.13</BoldText> MyFaceSign shall report any data breach incident to the Registered Client as soon as possible
      and provide all necessary and prompt assistance to the Registered Client as may be reasonably required by the
      Registered Client in resolving such data breach incident at its own costs.
      <br />
      <br />
      <BoldText>4. THE REGISTERED CLIENT’S OBLIGATIONS</BoldText>
      <br />
      <BoldText>4.1</BoldText> The Registered Client shall:
      <br />
      <BoldText>(a)</BoldText> co-operate with MyFaceSign in all matters relating to the Services provided they are lawful and
      reasonable;
      <br />
      <BoldText>(b)</BoldText> comply with the instructions, guidelines, notices and operational procedures issued
      by MyFaceSign from time to time regarding the submission of Service Requests to the relevant Government Bureaus
      and Authorities, including but not limited to:
      <br />
      <BoldText>　　(i)</BoldText> instructions on the submission of Service Requests, information flows and procedures which meet
      the specific requirements of the relevant Government Bureaus and Authorities; and
      <br />
      <BoldText>　　(ii)</BoldText> the standards which MyFaceSign may request for transmission;
      <br />
      <BoldText>(c)</BoldText> provide, in a timely manner, such information as MyFaceSign may require, and ensure that it is
      accurate and complete in all material respects;
      <br />
      <BoldText>(d)</BoldText> accept and read, in a timely manner, the service notices issued by MyFaceSign to the Registered
      Client;
      <br />
      <BoldText>(e)</BoldText> resubmit the Service Request if so required under the service notice;
      <br />
      <BoldText>(f)</BoldText> approve additional fees for submitting a new Service Request if so required under the service
      notice, provided that such additional fees have been pre-agreed by the Registered Client.
      <br />
      <br />
      <BoldText>4.2</BoldText> The Registered Client shall provide at its own cost the necessary and appropriate facilities to
      meet the minimum configuration environment suggested by MyFaceSign from time to time and ensure that such hardware
      and software configurations are secured and protected for transmission of data to the MyFaceSign Platform. The
      minimum configuration environment includes but is not limited to computer hardware, system software, system
      configuration, communication device and facilities to access the internet and/or a telecommunications line from a
      public telecommunications provider.
      <br />
      <br />
      <BoldText>4.3</BoldText> The Registered Client shall use a log-on code and/or associated password to access the Services
      and change the password associated with the log-on code on a regular basis.
      <br />
      <br />
      <BoldText>4.4</BoldText> The Registered Client shall be responsible for the security and confidentiality of the password
      and its account. MyFaceSign shall not be liable for any loss or damage resulting from the Registered Client’s
      failure to maintain the security of its account.
      <br />
      <br />
      <BoldText>4.5</BoldText> The Registered Client shall forthwith notify MyFaceSign of any unauthorized use of, or access to,
      the Services through the Registered Client’s account.
      <br />
      <br />
      <BoldText>4.6</BoldText> The Registered Client shall be bound by the actions of the Authorized Signatories and assume all
      civil or criminal liabilities of and incidental to the use of the Services by the Authorized Signatories.
      <br />
      <br />
      <BoldText>4.7</BoldText> The Registered Client shall discharge any legal obligations which the relevant Government Bureaus
      and Authorities may require the Registered Client to discharge from time to time in relation to the use of the
      Services.
      <br />
      <br />
      <BoldText>4.8</BoldText> The Registered Client acknowledges that: Save and except otherwise specified by MyFaceSign, for
      the purpose of discharging any relevant legal obligations, the timestamp attached to the Service Requests or
      receipts by the MyFaceSign Platform, in the absence of manifest error or fraud, shall be regarded as the time when
      such Service Requests are received by the respective recipients or the time when the receipts are issued by the
      relevant Government Bureaus and Authorities (as the case may be).
      <br />
      <br />
      <BoldText>4.9</BoldText> The Registered Client shall reserve sufficient time for MyFaceSign to process the Service Request
      and MyFaceSign shall not be liable for any late submission of Service Requests.
      <br />
      <br />
      <BoldText>4.10</BoldText> The Registered Client shall advise MyFaceSign immediately of any changes in the information
      provided on the Registration Form and of any changes of personal data and other information which the Registered
      Client has provided to MyFaceSign related to the use of the Services.
      <br />
      <br />
      <BoldText>4.11</BoldText> The Registered Client agrees to the transmission of the information provided by the Registered
      Client and Intended Recipient to the relevant Government Bureaus and Authorities by electronic means (i) solely
      for identity validation and/or (ii) as necessary to perform under this Contract and/or (iii) as authorised by this
      Contract and/or (iv) in accordance with applicable law.
      <br />
      <br />
      <BoldText>4.12</BoldText> In respect of any personal data or other information that the Registered Client has provided or
      is required to provide to the relevant Government Bureaus and Authorities incidental to the use of the Services,
      the Registered Client agrees to the release of such information by the relevant Government Bureaus and Authorities
      to MyFaceSign.
      <br />
      <br />
      <BoldText>4.13</BoldText> The Register Client:
      <br />
      <BoldText>(a)</BoldText> acknowledge that if the information is submitted to the relevant Government Bureaus and
      Authorities, processing thereof will be subject to the rules and conditions of the relevant Government Bureaus and
      Authorities; and
      <br />
      <BoldText>(b)</BoldText> shall assume liabilities for any administrative sanctions resulting from the Registered Client’s
      failure to comply with the relevant rules and conditions.
      <br />
      <br />
      <BoldText>4.14</BoldText> If MyFaceSign’s performance of its obligations under the Contract is prevented or delayed by any
      act or omission of the Registered Client, its agents, subcontractors, consultants or employees, including but not
      limited to the failure to respond timely to any service notice from MyFaceSign, MyFaceSign shall:
      <br />
      <BoldText>(a)</BoldText> not be liable for any costs, charges or losses sustained or incurred by the Registered Client
      that arise directly or indirectly from such prevention or delay;
      <br />
      <BoldText>(b)</BoldText> be entitled to payment of the Charges despite any such prevention or delay; and
      <br />
      <BoldText>(c)</BoldText> be entitled to recover any additional costs, charges or losses MyFaceSign sustains or incurs that
      arise directly or indirectly from such prevention or delay.
      <br />
      <br />
      <BoldText>5. INTELLECTUAL PROPERTY</BoldText>
      <br />
      <BoldText>5.1</BoldText> MyFaceSign and its licensors shall retain ownership of all MyFaceSign IPRs. The Registered Client
      and its licensors shall retain ownership of all Intellectual Property Rights in the Client Materials. Unless
      expressly permitted in the Contract, MyFaceSign shall not use any Client Marterials IPR for any purposes unless
      with the Registered Client’s prior written consent.
      <br />
      <br />
      <BoldText>5.2</BoldText> The Registered Client shall not publish or use any MyFaceSign IPRs, including but not limited to
      MyFaceSign's name, logos and trademark without the prior written consent of MyFaceSign or unless otherwise
      permitted by these Conditions.
      <br />
      <br />
      <BoldText>5.3</BoldText> MyFaceSign grants the Registered Client, or shall procure the direct grant to the Registered
      Client of, a fully paid-up, worldwide, non-exclusive, royalty-free, nontransferrable licence to copy MyFaceSign
      IPRs for the purpose of receiving and using the Services and the Deliverables in the Registered Client’s business during
      the term of the Contract.
      <br />
      <br />
      <BoldText>5.4</BoldText> The Registered Client grants MyFaceSign a fully paid-up, worldwide, non-exclusive, royalty-free,
      nontransferable licence to copy and modify the Client Materials for the term of the Contract for the sole purpose
      of providing the Services to the Registered Client in accordance with the Contract.
      <br />
      <br />
      <BoldText>6. CHARGES AND PAYMENT</BoldText>
      <br />
      <BoldText>6.1</BoldText> In consideration for the provision of the Services, the Registered Client shall pay MyFaceSign
      the Charges in accordance with the terms of the Quotation in Schedule 7.
      <br />
      <br />
      <BoldText>6.2</BoldText> Upon signing of the Contract, the Registered Client may, if applicable:
      <br />
      <BoldText>(a)</BoldText> Lodge a non-interest bearing, non-refundable deposit with MyFaceSign; or
      <br />
      <BoldText>(b)</BoldText> Pay the Charges for the first applicable period.
      <br />
      <br />
      <BoldText>6.3</BoldText> If the Registered Client fails to make any payment due to MyFaceSign under the Contract by the
      due date for such payment, then, without limiting MyFaceSign’s remedies under Clause 8 (Termination):
      <br />
      <BoldText>(a)</BoldText> MyFaceSign may debit the Registered Client’s deposit and credit the Registered Client’s operating
      account with MyFaceSign with the amount due.
      <br />
      <BoldText>(b)</BoldText> MyFaceSign will only provide Services until sufficient payment has been made.
      <br />
      <br />
      <BoldText>6.4</BoldText> All amounts due under the Contract from the Registered Client to MyFaceSign shall be paid in full
      without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as
      required by law).
      <br />
      <br />
      <BoldText>7. LIMITATION OF LIABILITY AND INDEMNITY</BoldText>
      <br />
      <BoldText>7.1</BoldText> The restrictions on liability in this <BoldText>Clause 7</BoldText> apply to every liability arising under or in
      connection with the Contract including but not limited to liability in contract, tort (including negligence),
      misrepresentation, restitution or otherwise.
      <br />
      <br />
      <BoldText>7.2</BoldText> MyFaceSign shall have no liability for:
      <br />
      <BoldText>(a)</BoldText> loss of business or profits however arising incurred by the Registered Client, its business
      partners or third parties;
      <br />
      <BoldText>(b)</BoldText> loss incurred by the Registered Client, its business partners or third parties arising out of the
      failure on the part of the Registered Client to keep copies of data submitted to MyFaceSign;
      <br />
      <BoldText>(c)</BoldText> loss incurred by the Registered Client, its business partners or third parties arising out of the
      negligence or default on the part of the Registered Client, its staff, agents or third parties;
      <br />
      <BoldText>(d)</BoldText> loss incurred by the Registered Client, its business partners or third parties arising out of the
      failure of the Registered Client to follow MyFaceSign’s instructions or recommendations; or
      <br />
      <BoldText>(e)</BoldText> any indirect or consequential loss.
      <br />
      <br />
      <BoldText>7.3</BoldText> Each Party shall fully indemnify the other Party against all costs, claims, demands, actions and
      liabilities arising directly or indirectly from a breach of the Contract or any other default, negligence, acts or
      omissions on the part of the Registered Client.
      <br />
      <br />
      <BoldText>8. TERMINATION</BoldText>
      <br />
      <BoldText>8.1</BoldText> Without affecting any other right or remedy available to it, MyFaceSign shall have the absolute
      discretion to terminate the Contract with immediate effect without notice if:
      <br />
      <BoldText>(a)</BoldText> the Registered Client fails to pay any amount due under the Contract within one month from the
      date on which MyFaceSign issues a statement to the Registered Client for the payment of the said amount, unless
      any such amount is disputed by the Registered Client, in which case, the parties shall try to resolve the dispute
      by good faith negotiation first. If the parties failed to reach a consensus on any such disputed sum, Clause 10
      shall apply;
      <br />
      <BoldText>(b)</BoldText> the Registered Client uses the Services or any part thereof for any unlawful activity that may
      constitute an offence or may violate MyFaceSign’s security rules, including gaining unauthorized access to data
      transmitted which may constitute an offence under the Telecommunication Ordinance Cap. 106;
      <br />
      <BoldText>(c)</BoldText> the Registered Client commits a breach of any term of the Contract or any other agreements
      between MyFaceSign and the Registered Client; or
      <br />
      <BoldText>(d)</BoldText> if MyFaceSign commits a breach of its oblgiations under this Contract and such breach remains
      uncured for 5 Business Days from the date of receipt of such notice by MyFaceSign.
      <br />
      <br />
      <BoldText>8.2</BoldText> Without affecting any other right or remedy available to it, either party may terminate the
      Contract with immediate effect by giving 30 (thirty) days’ notice in advance to the other party
      <br />
      <br />
      <BoldText>8.3</BoldText> On termination of the Contract for whatever reason:
      <br />
      <BoldText>(a)</BoldText> MyFaceSign shall have the right to permanently delete all data and information in relation to the
      Registered Client except those to be retained in accordance with the Contract. MyFaceSign shall notify and remind
      the Registered Client in writing to back-up such data and information at least 10 Business Days before deleting
      such data and information permanently.
      <br />
      <BoldText>(b)</BoldText> any provision of the Contract that expressly or by implication is intended to come into or
      continue in force on or after the termination of the Contract shall remain in full force and effect; and
      <br />
      <BoldText>(c)</BoldText> termination of the Contract shall not affect any of the rights, remedies, obligations or
      liabilities of the parties that have accrued up to the date of termination, including the right to claim damages
      in respect of any breach of the Contract which existed at or before the date of termination.
      <br />
      <br />
      <BoldText>9. GENERAL</BoldText>
      <br />
      <BoldText>9.1 Force majeure.</BoldText> MyFaceSign shall not be in breach of the Contract nor liable for delay in
      performing, or failure to perform, any of its obligations under the Contract if such delay or failure results from
      events, circumstances or causes beyond its reasonable control, including, without limitation, an act of nature, an
      act of civil or military authority, an act of terrorism, strike or other labour disturbance, flood, fire,
      explosion, epidemic, other hostilities, unavailability, interruption or delay of third-party telecommunications or
      services, the failure of third- party software, or the failure of the Internet.
      <br />
      <br />
      <BoldText>9.2 Assignment and other dealings.</BoldText>
      <br /> Neither party shall disclose, assign, transfer, charge, subcontract, declare a trust over nor deal in any
      other manner with any or all of its rights and obligations under the Contract without the other party’s prior
      written consent.
      <br />
      <br />
      <BoldText>9.3 Confidentiality and personal data privacy.</BoldText>
      <br />
      <BoldText>(a)</BoldText> Each party undertakes that it shall not at any time disclose to any person any confidential
      information concerning the business, affairs, customers, clients or suppliers of the other party, or any personal
      data submitted by the other party, except as permitted by Clause 9.3. This Clause 9.3 shall survive the
      completion, expiry or termination of the Contract.
      <br />
      <BoldText>(b)</BoldText> Each party may disclose such personal data or confidential information:
      <br />
      <BoldText>　　(i)</BoldText> to its employees, officers, representatives, subcontractors or advisers who need to know such
      information for the purposes of carrying out the party’s obligations under the Contract. Each party shall ensure
      that its employees, officers, representatives, subcontractors or advisers to whom it discloses the other party’s
      confidential information comply with this Clause 9.3; and
      <br />
      <BoldText>　　(ii)</BoldText> as may be required by law, a court of competent jurisdiction or any governmental or regulatory
      authority.
      <br />
      <BoldText>　　(iii)</BoldText> with the written consent of the other party.
      <br />
      <br />
      <BoldText>(c)</BoldText> MyFaceSign may disclose such personal data or confidential information strictly on a need-to-know
      basis:
      <br />
      <BoldText>　　(i)</BoldText> to any person owing a duty of confidentiality to MyFaceSign, including any subsidiaries or
      associated companies which have undertaken to keep such information confidential solely for the purposes of
      carrying out its obligations under the Contract;
      <br />
      <BoldText>　　(ii)</BoldText> to the extent permitted by applicable data protection law, to credit reference agencies and, in
      the event of default, debt collection agencies; or
      <br />
      <BoldText>　　(iii)</BoldText> to any actual or proposed assignee of MyFaceSign or transferee of MyFaceSign's rights or
      obligations under the Contract.
      <br />
      <BoldText>(d)</BoldText> MyFaceSign shall comply with the Personal Data (Privacy) Ordinance, Cap. 486, Hong Kong Laws in
      the course of providing its Services and shall ensure that the MyFaceSign Platform meets all (i) the applicable
      data privacy and security law requirements and (ii) other standards, certifications or qualifications as may be
      required by the Registered Client from time to time.
      <br />
      <BoldText>(e)</BoldText> The Registered Client shall have the right to request access to and correction of any personal
      data held by MyFaceSign. Such requests should be in writing and addressed to the Privacy Compliance Officer of
      MyFaceSign, MyFaceSign may charge a reasonable fee for handling such requests.
      <br />
      <br />
      <BoldText>9.4 Entire agreement.</BoldText>
      <br />
      <BoldText>(a)</BoldText> The Contract constitutes the entire agreement between the parties and supersedes and extinguishes
      all previous agreements, promises, assurances, warranties, representations and understandings between them,
      whether written or oral, relating to its subject matter.
      <br />
      <BoldText>(b)</BoldText> Each party acknowledges that in entering into the Contract it does not rely on and shall have no
      remedies in respect of any statement, representation, assurance or warranty (whether made innocently or
      negligently) that is not set out in the Contract. Each party agrees that it shall have no claim for innocent or
      negligent misrepresentation based on any statement in the Contract.
      <br />
      <br />
      <BoldText>9.5 Variation.</BoldText> Any variation of the terms of the Contract shall be effective only if made by the
      parties in writing.
      <br />
      <br />
      <BoldText>9.6 Waiver.</BoldText>
      <br />
      <BoldText>(a)</BoldText> A waiver on the part of MyFaceSign of any right or remedy under the Contract or by law is only
      effective if given in writing and shall not be deemed a waiver of any subsequent right or remedy.
      <br />
      <BoldText>(b)</BoldText> A failure or delay by a party to exercise any right or remedy provided under the Contract or by
      law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any
      further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy
      provided under the Contract or by law shall prevent or restrict the further exercise of that or any other right or
      remedy.
      <br />
      <br />
      <BoldText>9.7 Severance.</BoldText> If any provision or part-provision of the Contract is or becomes invalid, illegal or
      unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and
      enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed
      deleted. Any modification to or deletion of a provision or part-provision under this Clause 9.7 shall not affect
      the validity and enforceability of the rest of the Contract.
      <br />
      <BoldText>9.8 Notices.</BoldText>
      <br />
      <BoldText>(a)</BoldText> Any notice given to a party under or in connection with the Contract shall be in writing and
      shall be:
      <br />
      <BoldText>　　(i)</BoldText> delivered by pre-paid post to its registered office (if a company) or its principal place of
      business (in any other case); or
      <br />
      <BoldText>　　(ii)</BoldText> sent by digitally signed email to the latest known email address of the receiving party.
      <br />
      <BoldText>(b)</BoldText> Any notice shall be deemed to have been given on the date it is delivered.
      <br />
      <BoldText>(c)</BoldText> This Clause 9.8 does not apply to the service of any proceedings or other documents in any legal
      action or, where applicable, any arbitration or other method of dispute resolution.
      <br />
      <br />
      <BoldText>9.9 Third-party rights.</BoldText>
      <br />
      <BoldText>(a)</BoldText> The Parties hereby declare that nothing in this Agreement confers or purports to confer on any
      third party any benefit or any right to enforce any term of this Agreement pursuant to the Contracts (Rights of
      Third Parties) Ordinance (Cap. 623).
      <br />
      <br />
      <BoldText>10. LAW AND JURISDICTION</BoldText>
      <br />
      <BoldText>10.1</BoldText> Without prejudice to any remedies available to the parties, the Registered Client and MyFaceSign
      shall attempt in good faith to resolve through negotiation any dispute in relation to the Services and/or the
      Contract.
      <br />
      <br />
      <BoldText>10.2</BoldText> In the event of a dispute between the Registered Client and the Intended Recipient(s) or other
      third parties in respect of the Service Request sent and received, the Service Request log kept by MyFaceSign may
      be used as proof of the information sent or received by the Registered Client and/or the said Intended Recipient
      or third parties.
      <br />
      <br />
      <BoldText>10.3</BoldText> The Contract, and any dispute or claim (including noncontractual disputes or claims) arising out
      of or in connection with it or its subject matter or formation, shall be governed by, and construed in accordance
      with the law of the Hong Kong Special Administration Region.
      <br />
      <br />
      <BoldText>10.4</BoldText> Any dispute or claim arising out of or in connection with the Contract shall be settled in
      accordance with the Arbitration Rules of the Hong Kong Mediation and Arbitration Centre. The number of arbitrators
      shall be one. The arbitration language shall be English.
    </div>
  );
};

export default Terms;

import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSingleEventList,
  destroyEvent,
  joinMeeting,
} from '@/api/event.api';
import { errorHandler, successHandler } from '@/utils/toastHandler';

import { Modal } from '@mui/material';
import { H2, H4 } from '@/components/UI/Typography/Heading';
import { Button } from '@/components/UI/Button';
import { ModalMainWrapper, Row, ActionBtns, ConfirmWrapper, ConfirmTitle, DeleteBtns } from './style';
import { Close } from '@/components/Icon';
import { useNavigate } from 'react-router-dom';
import Loading from '@/components/UI/Loading';
import { Text } from '@/components/UI/Typography/Text';

interface ModalProps {
  open: boolean;
  id: number;
  handleClose: () => void;
  handleDeleteData: (id: number) => void;
}

const EventModal = ({ open, id, handleClose, handleDeleteData }: ModalProps) => {
  // @ts-ignore
  const {t} = useTranslation();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<EventFormFields>({
    id: 0,
    evt_type: '',
    evt_name: '',
    evt_startDate: '',
    evt_startTime: '',
    evt_endDate: '',
    evt_endTime: '',
    evt_host_id: '',
    evt_guest_id: '',
    vc_id: '',
    evt_note: '',
    deal: {
      id: 0,
      name: '',
      deal_type: {
        id: 0,
        name: '',
      },
    },
    vc: {
      evt_id: 0,
      id: 0,
      meetingID: '',
      moderatorPW: '',
      attendeePW: '',
    },
  });

  const [openDelete, setOpenDelete] = useState(false);

  const [checkTime, setCheckTime] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const checkCurrentTime = () => {
    if (!data) return;
    // 比對時間, 若在會議開始前的10分鐘, 將checkTime設為true
    const now = new Date();
    const start = new Date(data.evt_startDate);
    const diff = start.getTime() - now.getTime();
    if (diff < 600000) {
      setCheckTime(true);
      return true;
    } else {
      setCheckTime(false);
      return false;
    }
  };

  const getEvent = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = (await getSingleEventList(id)).data;
      if (res.success) {
        setData(res.data);
        setIsLoading(false);
      }
    } catch (error) {}
  }, [id]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const deleteHandler = async (id: number) => {
    try {
      await destroyEvent(id);
      handleDeleteData(id);
      handleClose();
      handleDeleteClose();
    } catch (error) {}
  };

  const openMeeting = async () => {
    if (checkCurrentTime()) {
      try {
        const reqData = `meetingID=${data.vc.meetingID}`;
        const res = (await joinMeeting(reqData)).data;

        window.open(res.data, '_blank');
      } catch (err) {
        errorHandler(t('error.server'));
      }
    } else {
      errorHandler(t('error.joinMeeting'));
    }
  };

  const shareToGuest = async () => {
    if (checkCurrentTime()) {
      navigator.clipboard.writeText(window.location.origin + '/event/joinMeeting?meetingID=' + data.vc.meetingID);
      successHandler(t('success.copyMeetingLink'));
    } else {
      errorHandler(t('error.joinMeeting'));
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalMainWrapper>
          <Close.ButtonClose onClick={handleClose}>
            <Close.CloseSVG />
          </Close.ButtonClose>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <H2>{data?.evt_name}</H2>
              <H4 style={{ color: '#999999', marginBottom: 10 }}>{data?.evt_startDate}</H4>

              <Row>
                <div className="icon">
                  <img src="/images/event/modal/handshake.svg" alt="chat" />
                </div>
                <H4>{data?.deal ? data.deal.name : '-'}</H4>
              </Row>

              <Row>
                <div className="icon">
                  <img src="/images/event/modal/interpreter.svg" alt="chat" />
                </div>
                {data?.vc && (
                  <Button type={'button'} onClick={openMeeting} padding={0}>
                    <H4>
                      <Text color={'blue'}>{t('buttons.joinMeeting')}</Text>
                    </H4>
                  </Button>
                )}
              </Row>

              <Row>
                <div className="icon">
                  <img src="/images/event/modal/chat.svg" alt="chat" />
                </div>
                <H4>{data?.evt_note}</H4>
              </Row>
              <ActionBtns>
                <button
                  onClick={() => {
                    navigate(`/event/newEvent?edit=${data.id}`);
                  }}
                >
                  {t('buttons.edit')}
                </button>
                <button
                  onClick={() => {
                    handleOpenDelete();
                  }}
                >
                  {t('buttons.delete')}
                </button>
                {data?.vc && <button onClick={shareToGuest}>{t('buttons.share')}</button>}
              </ActionBtns>
            </>
          )}
        </ModalMainWrapper>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalMainWrapper>
          <ConfirmWrapper>
            <ConfirmTitle>{t('pages.event.modal.title')}</ConfirmTitle>
            {t('pages.event.modal.content')}
          </ConfirmWrapper>
          <DeleteBtns>
            <Button
              variant={'primary'}
              size={'lg'}
              onClick={() => {
                deleteHandler(data.id);
              }}
            >
              {t('buttons.delete')}
            </Button>
            <button onClick={handleDeleteClose}>{t('buttons.cancel')}</button>
          </DeleteBtns>
        </ModalMainWrapper>
      </Modal>
    </>
  );
};

export default EventModal;

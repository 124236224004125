import styled from 'styled-components';

const TopWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  font-family: 'Roboto';

  .title {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    span {
      font-weight: 700;
      font-size: ${(p) => p.theme.fontSizes.base};
      line-height: 19px;
      color: ${(p) => p.theme.colors.gray500};
    }
  }

  .link {
    position: absolute;
    right: 0;
    top: 5px;
    a {
      color: ${(p) => p.theme.colors.primary500};
      text-decoration: underline;
    }
  }

  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background: #e9e9e9;
  border-radius: 5px;
  margin-top: 10px;

  > div {
    height: 100%;
    background: ${(p) => p.theme.colors.secondary500};
    border-radius: 5px;
  }
`;

export { TopWrapper, ProgressBar };

import { Box, Flex } from '@/layout';
import { Modal, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ModalForm } from '../styles';
import { Button } from '@/components/UI/Button';
import { useTranslation } from 'react-i18next';
import { cancelToSign } from '@/api/deal.api';
import { Close } from '@/components/Icon';
import { Text } from '@/components/UI/Typography/Text';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  fileId: number;
  dealId: string;
  initData: () => Promise<void>;
}

const CancelToSignModal = ({ initData, fileId, dealId, showModal, setShowModal }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<{ fileId: number; dealId: string; cancelSignReason: string }>();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = (await cancelToSign({ ...data, fileId, dealId })).data;
      if (res.success) {
        setShowModal(false);
        initData();
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalForm onSubmit={onSubmit}>
        <Flex marginBottom={30}>
          <Text fontWeight={700}>{t('info.cancelToSign')}</Text>
          <Close.ButtonClose
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Close.CloseSVG />
          </Close.ButtonClose>
        </Flex>
        <Controller
          control={control}
          name={'cancelSignReason'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('form.reason.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
        />

        <Flex marginTop={'50px'} gridGap={30} alignItems={'center'} justifyContent={'center'}>
          <Button type={'submit'} size={'lg'} variant={'primary'}>
            {t('buttons.ok')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'underline' }}
          >
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </ModalForm>
    </Modal>
  );
};

export default CancelToSignModal;

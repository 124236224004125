import { useTranslation } from 'react-i18next';
import { ContainerWrapper, Image, AboutWrapper, Description, Title } from './style';
import useResize from '@/hooks/useResize';

const About = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();

  return (
    <>
      <Title isTablet={isTablet}>{t('pages.index.About.title')}</Title>
      <ContainerWrapper isTablet={isTablet} isMobile={isMobile} >
        <Image isTablet={isTablet} src={'/images/index/about.jpg'} />
        <AboutWrapper isTablet={isTablet} isMobile={isMobile}>
          <Description>
            {t('pages.index.About.description')}
            <br />
            <br />
            {t('pages.index.About.description_bottom')}
          </Description>
        </AboutWrapper>
      </ContainerWrapper>
    </>
  );
};

export default About;

import { useMemo, useEffect, useCallback, useState } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import DataTable, { TypeColumn } from '@/components/DataTable';
import { CardContainer } from '@/layout/Card';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { Like } from '@/components/Icon';
import Loading from '@/components/UI/Loading';

import { getDocTemplate } from '@/api/deal.api';
import { convertLink, convertToFileSize } from '@/utils/convert';

import { theme } from '@/theme/Theme';

type DataRow = {
  recommend?: boolean;
  name: string;
  type: string;
  size: string;
  action: string;
};
interface FilesProps {
  id?: string;
}

const Other = ({ id = '0' }: FilesProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const tabLabel = t('pages.dealDetail.manageFiles.other.table.columns', { returnObjects: true });

  const [docTemplate, setDocTemplate] = useState<DataRow[]>();

  const initData = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getDocTemplate(id)).data;
      const other: DataRow[] = res.data.other.map((data) => ({
        id: data.id,
        name: data.file.name,
        type: data.file.extension,
        size: convertToFileSize(Number(data.file.size)),
        action: data.file.path,
      }));
      const recommend: DataRow[] = res.data.recommend.map((data) => ({
        id: data.id,
        recommend: true,
        name: data.file.name,
        type: data.file.extension,
        size: convertToFileSize(Number(data.file.size)),
        action: data.file.path,
      }));
      setDocTemplate(other.concat(recommend));
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  const columns: ColumnDef<DataRow>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: tabLabel[0],
        cell: ({ row }) => (
          <Flex alignItems={'center'} gridGap={'10px'}>
            {row.original.recommend && <Like.LikeSVG fill={theme.colors.orange} />} {row.original.name}
          </Flex>
        ),
      },
      {
        accessorKey: 'type',
        header: tabLabel[2],
        cell: ({ row }) => <TypeColumn text={row.original.type} />,
      },
      {
        accessorKey: 'size',
        header: tabLabel[3],
      },
      {
        accessorKey: 'action',
        header: tabLabel[4],
        cell: ({ row }) => (
          <Flex gridGap={'10px'}>
            <Button
              size={'table'}
              variant={'primary'}
              onClick={() => {
                window.open(convertLink(row.original.action));
              }}
            >
              {t('buttons.download')}
            </Button>
          </Flex>
        ),
      },
    ],
    [t, tabLabel]
  );

  return (
    <Box>
      {docTemplate ? <DataTable columns={columns} data={docTemplate} canSearch /> : <Loading />}
    </Box>
  );
};

export default Other;

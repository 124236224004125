import styled from 'styled-components';

const ModalForm = styled.div`
  position: absolute;
  top: 40%;
  left: calc(50% - 300px);
  width: 600px;
  background-color: #fff;
  padding: 50px;
  border-radius: 20px 20px 20px 0px;
  text-align: center;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export { ModalForm, BoldText };

import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageContainer from '@/components/Container/PageContainer';
import CreateMeeting from './createMeeting';
import DetailTitle from '@/components/DealDetail/Title';
import Loading from '@/components/UI/Loading';

import { getDealDetail } from '@/api/deal.api';

const DealMeeting = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();

  const [dealData, setDealData] = useState<DealListItem>();

  const initData = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getDealDetail(id)).data;
      const data: DealListItem = {
        id: res.data.id,
        tag: 'inviting',
        tradeName: res.data.name,
        count: {
          document: 0,
          participants: res.data.participants.length,
          events: 0,
        },
        participants: res.data.participants,
        dealInitiation: [res.data.progress === '0' ? false : true],
        negotiation: res.data.negotiation,
        deal_close: res.data.deal_close,
        contractExecution: [res.data.excution_file_complete],
        executions: res.data.executions,
        progress: res.data.progress,
        execution_file_complete: res.data.excution_file_complete,
        deal_type: res.data.deal_type.name,
        category: res.data.category,
        deal_type_id: Number(res.data.deal_type_id),
      };
      setDealData(data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return dealData ? (
    <PageContainer title={<DetailTitle data={dealData} innerTitle={'Create Meeting'} hideTag={true}/>}>
      <CreateMeeting />
    </PageContainer>
  ) : (
    <Loading />
  );
};

export default DealMeeting;

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button } from '@/components/UI/Button';
import { TranslateFakeData } from './fakeData';

import { useForm } from 'react-hook-form';

// 暫用
import useDealValidation from '@/hooks/requireSchema/useDealValidation';
import { FormSelect } from '@/components/UI/Select';

type DataRow = {
  name: string;
  folder: string;
  type: string;
  size: string;
  updated: string;
  action: string;
};

const TableTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 10px;
`;

const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const fakeData = [
  { value: 'English', text: 'English' },
  { value: '簡中', text: '簡中' },
  { value: '繁中', text: '繁中' },
  { value: '日本語', text: '日本語' },
];

const TranslationComplete = () => {
    // @ts-ignore
  const { t } = useTranslation();
  const rawColumn = t('pages.translate.table.columns', { returnObjects: true });
  const columns: TableColumn<DataRow>[] = [
    {
      name: rawColumn[0],
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: rawColumn[1],
      selector: (row) => row.folder,
      sortable: true,
    },
    {
      name: rawColumn[2],
      selector: (row) => row.type,
    },
    {
      name: rawColumn[3],
      selector: (row) => row.size,
    },
    {
      name: rawColumn[4],
      selector: (row) => row.updated,
    },
    {
      name: rawColumn[5],
      selector: (row) => row.action,
      cell: (row) => {
        return (
          <>
            <Button variant={'primary'} style={{ marginRight: '10px' }} size={'table'} onClick={() => console.log(row)}>
              Download
            </Button>
            <Button variant={'primary'} size={'table'} onClick={() => console.log(row)}>
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  const { control, handleSubmit } = useForm<{ language: string }>();

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
  });

  const { dealTypeRegister } = useDealValidation().step1();

  return (
    <div style={{ marginBottom: 30 }}>
      <div>
        <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '45px' }}>
          <SelectorWrapper>
            <div style={{ marginRight: 15 }}>Select the language to translate.</div>
            <div style={{ width: 350 }}>
              <FormSelect
                control={control}
                variant={'outlined'}
                size={'small'}
                data={fakeData}
                inputLabel={'language'}
                selectLabel={'language'}
                name={'language'}
              />
            </div>
            <Button size={'md'} variant={'primary'} style={{ marginLeft: 10 }}>
              Translate
            </Button>
          </SelectorWrapper>
        </form>
      </div>
      <TableTitle>Translation complete</TableTitle>
      <DataTable columns={columns} data={TranslateFakeData} />
    </div>
  );
};

export default TranslationComplete;

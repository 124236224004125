import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate, useLocation } from 'react-router-dom';

import { LoadingPage, Box } from '@/layout';
import TypeInformation from './TypeInformation';
import Participants from './Participants';
import DocTemplate from './DocTemplate';

import { getDealDetail } from '@/api/deal.api';
import PATH from '@/router/path';
import PreviewInformation from './PreviewInformation';
import { dealType } from '@/data/select';

const FormStepper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const queryStep = params.get('step');
  const queryId = params.get('id');

  // @ts-ignore
  const { t } = useTranslation();
  const methods = useForm<DealFormFields>({
    defaultValues: {},
  });

  const [activeStep, setActiveStep] = useState(queryStep ? Number(queryStep) : 1);
  const [newDealId, setNewDealId] = useState<string>();
  const [dealDetail, setDealDetail] = useState<DealDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<DealStep1FormRequest>({
    deal_id: undefined,
    deal_role_id: '',
    deal_type_id: '',
    name: '',
    description: '',
    expiry_date: '',
    category: '',
    languages: '',
    currency: '',
    category_other: '',
    languages_other: '',
    currency_other: '',
  });

  // 檢查若formData的name為''且step在第二步, 則step返回第一步
  useEffect(() => {
    if (activeStep === 2 && formData.name === '') {
      setActiveStep(1);
    }
  }, [activeStep]);

  // 每次步驟切換進行回到最頂層
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  const steps = t('pages.dealInitiation.steps', { returnObjects: true });

  const incrementQueryValue = (name: string, value: number) => {
    const searchParams = new URLSearchParams(location.search);
    const queryValue = parseInt(searchParams.get(name) || '0', 10);
    searchParams.set(name, (queryValue + value).toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }

  const handleNextStep = async () => {
    incrementQueryValue('step', 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    getDetail();
  };

  const handleBackStep = () => {
    incrementQueryValue('step', -1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    getDetail();
  };

  const completedSteps = useCallback(() => Object.keys(steps).length, [steps]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <TypeInformation
            handleNextStep={handleNextStep}
            dealDetail={dealDetail}
            setIsLoading={setIsLoading}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 1:
        return (
          <PreviewInformation
            setNewDealId={setNewDealId}
            setIsLoading={setIsLoading}
            formData={formData}
            handleNextStep={handleNextStep}
            handleBackStep={handleBackStep}
          />
        );
      case 2:
        return <Participants handleNextStep={handleNextStep} handleBackStep={handleBackStep} queryId={queryId} />;
      case 3:
        navigate(`/deal`);
    }
  };

  const getDetail = useCallback(async () => {
    if (!queryId) return;
    try {
      const res = (await getDealDetail(queryId)).data;
      setDealDetail(res.data);
    } catch (error) {
      navigate(PATH.deal.index);
    }
  }, [queryId]);

  useEffect(() => {
    if (!newDealId) return;
    const query: { [key: string]: string } = {
      id: newDealId,
    };
    const searchParams = new URLSearchParams(location.search);
    for (const [key, value] of Object.entries(query)) {
      searchParams.set(key, value);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }, [newDealId]);

  useEffect(() => {
    if (!queryId) return;
    getDetail();
  }, [getDetail, queryId]);

  return (
    <Box display={'flex'}>
      <LoadingPage isLoading={isLoading} />
      {/* Stepper 是從 0 開始 所以 activeStep 必須 -1 */}
      <Stepper activeStep={activeStep - 1} orientation="vertical" sx={{ paddingRight: '70px', maxHeight: '213px', width: '275px', flexShrink: '0' }}>
        {steps.map((s) => (
          <Step key={s.label}>
            <StepLabel>{s.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormProvider {...methods}>
        <Box width={'100%'}>{getStepContent(activeStep - 1)}</Box>
      </FormProvider>
    </Box>
  );
};

export default FormStepper;

import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import Tabs from '@/components/UI/Tabs';
import PageContainer from '@/components/Container/PageContainer';
import DetailTitle from '@/components/DealDetail/Title';
import { Files, MyFolder } from '@/components/ManageFiles';
import Loading from '@/components/UI/Loading';

import Other from '@/components/ManageFiles/Other';

import { getDealDetail } from '@/api/deal.api';

const DetailFilesPage = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();

  const tabLabel = t('pages.dealDetail.manageFiles.tabs', { returnObjects: true });
  const initTabIndex = new URLSearchParams(search).get('tab');

  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex ? parseInt(initTabIndex) : 1);
  const [dealData, setDealData] = useState<DealListItem>();

  const tabs: TabsType = [
    {
      label: `${tabLabel[0]}`,
      index: 1,
      Component: () => <Files id={id} />,
    },
    {
      label: `${tabLabel[1]}`,
      index: 2,
      Component: () => <Other id={id} />,
    },
    // {
    //   label: tabLabel[2],
    //   index: 3,
    //   Component: () => <MyFolder id={id} />,
    // },
  ];

  const initData = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getDealDetail(id)).data;
      const data: DealListItem = {
        id: res.data.id,
        tag: 'inviting',
        tradeName: res.data.name,
        count: {
          document: 0,
          participants: res.data.participants.length,
          events: 0,
        },
        participants: res.data.participants,
        dealInitiation: [res.data.progress === '0' ? false : true],
        negotiation: res.data.negotiation,
        deal_close: res.data.deal_close,
        contractExecution: [res.data.excution_file_complete],
        executions: res.data.executions,
        progress: res.data.progress,
        execution_file_complete: res.data.excution_file_complete,
        deal_type: res.data.deal_type.name,
        category: res.data.category,
        deal_type_id: Number(res.data.deal_type_id),
      };
      setDealData(data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return dealData ? (
    <PageContainer title={<DetailTitle data={dealData} innerTitle={'Files'} hideTag={true}/>}>
      <Tabs tabs={tabs} selectedTab={selectedTab} onClick={setSelectedTab} />
    </PageContainer>
  ) : (
    <Loading />
  );
};

export default DetailFilesPage;

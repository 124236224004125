export const saveTempData = (obj: Object) => {
  localStorage.setItem('tempData', JSON.stringify(obj));
};

export const getTempData = () => {
  const tempData = localStorage.getItem('tempData');
  if (tempData === 'undefined') return null;
  // 確認取得的資料是否為空物件或null, 若是則回傳null, 否則回傳轉換後的資料
  return !tempData ? null : Object.keys(JSON.parse(tempData)).length !== 0 ? JSON.parse(tempData) : null;
};

export const removeTempData = () => {
  localStorage.removeItem('tempData');
};

import { axios } from '@/utils/axios';
import { isArrayOfType } from '@/utils/check';

/**
 * ANCHOR 會員登入
 */
export const loginMember = async (data: MemberSignInFormRequest) => {
  return axios.post<MemberSignInStep1Response>('/api/auth/login', data);
};

/**
 * ANCHOR 會員登入二階段驗證
 */
export const loginToken = async (data: MemberSignInTokenFormRequest) => {
  return axios.post<MemberSignInResponse>('/api/auth/verifyCode', data);
};

/**
 * ANCHOR 檢查Email是否重複
 */
export const checkEmailUnique = async (data: { email: string }) => {
  return axios.post<CheckEmailUniqueResponse>('/api/registration/checkEmailUnique', data);
};

/**
 * ANCHOR 送出Email驗證信
 */
export const sendEmailVerificationCode = async (data: { email: string }) => {
  return axios.post('/api/registration/register', data);
};

/**
 * ANCHOR 重新寄送Email驗證信
 */
export const resendEmailVerificationCode = async (data: { email: string }) => {
  return axios.post('/api/registration/resendVerificationCode', data);
};

/**
 * ANCHOR 驗證Email及驗證碼
 */
export const registerVerification = async (data: RegisterVerificationRequest) => {
  return axios.post('/api/registration/registerVerification', data);
};

/**
 * ANCHOR 刷新token
 */
export const refreshToken = async (token: string) => {
  return axios.post('/api/auth/refresh', token);
};

/**
 * ANCHOR 取得用戶資料
 */
export const getUserDetail = async () => {
  return await axios.get<{ data: MemberProfile }>('/api/account/userDetail');
};

/**
 * ANCHOR 取得用戶產品資料
 */
export const getUserDetailWithProduct = async () => {
  return await axios.get<{ data: { products: MemberProfileProduct[] } }>('/api/account/userDetailWithProd');
};

/**
 * ANCHOR 取得用戶資料驗證
 */
export const getUserProgress = async () => {
  return await axios.get('/api/account/verificationProgress');
};

/**
 * ANCHOR 會員登出
 */
export const logout = async (data: { token: string }) => {
  return axios.post('/api/auth/logout', data);
};

/**
 * ANCHOR 忘記密碼-寄驗證信
 */
export const forgotPassword = async (data: { email: string }) => {
  return axios.post('/api/account/forgotPassword', data);
};

/**
 * ANCHOR 忘記密碼-送出驗證碼
 */
export const forgotPasswordVerification = async (data: { email: string; verificationCode: string }) => {
  return axios.post('/api/account/forgotPasswordVerification', data);
};

/**
 * ANCHOR 忘記密碼-重設密碼
 */
export const resetPassword = async (data: ResetPasswordResponse) => {
  return axios.post('/api/account/resetPassword', data);
};

/**
 * ANCHOR 黃頁列表資料
 */
export const getYellowPageSearch = async (queryString: string) => {
  return axios.get(`/api/yellowPage${queryString}`);
};

/**
 * ANCHOR 黃頁內頁資料
 */
export const getYellowPageDetail = async (data: { id: string }) => {
  return axios.get(`/api/yellowPage/${data.id}`);
};

/**
 * ANCHOR 註冊第一步 FinishRegistAccount
 */
export const registrationAccount = async (data: RegistrationAccountRequest) => {
  return axios.post<RegistrationAccountResponse>('/api/registration/finishAccountRegister', data);
};

/**
 * ANCHOR 註冊第一步 UpdateRegistAccount
 */
export const updateAccount = async (data: UpdateAccountRequest) => {
  return axios.post<{ success: boolean; data: MemberProfile }>('/api/registration/updateAccountRegister', data);
};

/**
 * ANCHOR 註冊第一步 套用現有公司資料
 */
export const registerCompanyInfoWithParentCompany = async (data: { parent_company_id: string }) => {
  return axios.post('/api/account/registerCompanyInfoWithParentCompany', data);
};

/**
 * ANCHOR 註冊第二步 Company
 */
export const registrationCompany = async (data: RegistrationCompanyRequest) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      if (isArrayOfType(value, 'number')) {
        formData.append(key, value.join(','));
      } else {
        // 將字串陣列放入formData
        value.forEach((v, index) => {
          formData.append(`${key}[${index}]`, v);
        });
      }
    } else if (value) {
      formData.append(key, value);
    }
  }

  return axios.post('/api/account/registerCompanyInfo', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const registrationPersonalInfo = async (data: RegistrationPersonalRequest) => {
  const postData = { ...data, TC_Promotion: '1' };

  const formData = new FormData();
  for (const [key, value] of Object.entries(postData)) {
    if (Array.isArray(value)) {
      if (isArrayOfType(value, 'number')) {
        formData.append(key, value.join(','));
      } else if (value.length > 0) {
        const products = value as RegistrationCompanyRequestProduct[];
        products.forEach((product, index) => {
          for (const [pKey, pValue] of Object.entries(product)) {
            if (pValue) formData.append(`${key}[${index}][${pKey}]`, pValue);
          }
        });
      }
    } else if (value) {
      formData.append(key, value);
    }
  }

  return axios.post('/api/account/updatePersonalInfo', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const registrationSupplementalInfo = async (data: RegistrationSupplementalInfoRequest) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (value) formData.append(key, value);
  }
  return axios.post('/api/account/registerSupplementalInfo', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const registrationECert = async (data: File) => {
  const formData = new FormData();
  if (data) formData.append('e_cert', data);
  return axios.post('/api/account/updateECert', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const registrationPRC = async (data: RegistrationPRCRequest) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (value) formData.append(key, value);
  }
  return axios.post('/api/account/updatePRC', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

// 確認資料無誤後送出
export const registerPreview = async (data: { TC_Promotion: string }) => {
  return axios.post('/api/account/registerPreview', data);
};

// 刪除上傳的檔案
export const removeFile = async (data: { fileType: string }) => {
  return axios.post('/api/account/removeFile', data);
};

// 切換使用者語系
export const switchLang = async (data: { lang: string }) => {
  return axios.post('/api/account/switchLanguage', data);
};

// 取得子帳號列表
export const getSubAccountList = async () => {
  return axios.get('/api/account/getSubAccountList');
};

// 寄出成為子帳號的邀請信
export const addSubAccountSendMails = async (data: { emails: string }) => {
  return axios.post('/api/account/addSubAccountSendMails', data);
};

// 完成子帳號的註冊
export const finishSubAccount = async (data: RegistrationAccountRequest) => {
  return axios.post('/api/account/finishSubAccount', data);
};

// 編輯子帳號
export const editSubAccount = async (id: string, data: Omit<UpdateAccountRequest, 'role'>) => {
  return axios.post(`/api/account/editSubAccount/${id}`, data);
};

// 取得子帳號資料
export const getSubAccountDetail = async (id: string) => {
  return axios.get(`/api/account/getSubAccountInfo/${id}`);
};

// 確認檔案容量
export const getStorageSpace = async () => {
  return axios.get('/api/account/getStorageSpace');
};

// 取得該帳號的子帳號數量
export const getMembers = async () => {
  return axios.get('/api/account/members');
};

// 取得使用者條款文件
export const getPolicy = async () => {
  return axios.get('/api/policy/getPolicy');
};
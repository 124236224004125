import { useTranslation } from 'react-i18next';

import { FormStep1, FormStep2, FormStep3 } from './FromStepper';
import AuthContainer from '@/components/Container/AuthContainer';
import { useState } from 'react';

const ForgotPasswordPage = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(1);
  const [userMail, setUserMail] = useState<string>('');
  const [resetToken, setResetToken] = useState<string>('');

  return (
    <AuthContainer
      title={activeStep === 3 ? t('setNewPassword') : t('forgotPassword')}
    >
      {activeStep === 1 && <FormStep1 setActiveStep={setActiveStep} setUserMail={setUserMail} userMail={userMail} />}
      {activeStep === 2 && <FormStep2 setActiveStep={setActiveStep} setResetToken={setResetToken} userMail={userMail} />}
      {activeStep === 3 && <FormStep3 userMail={userMail} resetToken={resetToken} />}
    </AuthContainer>
  );
};

export default ForgotPasswordPage;

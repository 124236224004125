import styled from 'styled-components';

const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 0;
    border: 2px dashed ${(p) => p.theme.colors.gray300};
    border-radius: 15px;
    margin-bottom: 15px;

    > label,div {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: ${(p) => p.theme.fontSizes.base};
        line-height: 19px;
        text-align: center;
        color: ${(p) => p.theme.colors.gray300};
    }
`

export { InputWrapper };

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormStep1, FormStep2, FormStep3 } from './FormStepper';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { Paper } from '@mui/material';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

const ResetPassword = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(1);
  const [userMail, setUserMail] = useState<string>('');
  const [resetToken, setResetToken] = useState<string>('');
  const { userData } = useAppSelector(getAuthSelector);

  useEffect(() => {
    setUserMail(userData.email);
  }, []);

  return (
    <PageContainer title={<InnerPageTitle link={'/profile/edit'} title={t('dashboard')} subtitle={t('resetPassword')} />}>
      <Paper style={{ width: '100%', maxWidth: '930px', padding: '30px 20px' }} square elevation={0}>
        {activeStep === 1 && <FormStep1 setActiveStep={setActiveStep} setUserMail={setUserMail} userMail={userMail} />}
        {activeStep === 2 && (
          <FormStep2 setActiveStep={setActiveStep} setResetToken={setResetToken} userMail={userMail} />
        )}
        {activeStep === 3 && <FormStep3 userMail={userMail} resetToken={resetToken} />}
      </Paper>
    </PageContainer>
  );
};

export default ResetPassword;

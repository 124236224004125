import { useEffect, useState } from 'react';
import PageContainer from '@/components/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import Calender from './Calendar';

const Event = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <PageContainer title={t('event')}>
      <Calender />
    </PageContainer>
  );
};

export default Event;

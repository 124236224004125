// const CompanyInfoLine = [
//     'Account',
//     'Company Info',
//     'Personal Info',
//     'Supplemental info&LEI',
//     'Identification check',
//     'e-Cert',
//     'Credit report',
//     'Subscription plan'
// ]

const CompanyInfoLine = [
    'Create Account',
    'Company Information',
    'Supplemental Information',
    'Preview Application Information',
    'Completed'
]


const PersonalInfoLine = [
    'Account',
    'Personal Information',
    'Identification check',
    'e-Cert',
    'Subscription plan'
]

// const RegistrationTitle = [
//     'Create Account',
//     'Company Info',
//     'Personal Info',
//     'Supplemental info&LEI',
//     'Identification check',
//     'e-Cert',
//     'Credit report',
//     'Subscription plan'
// ]

const RegistrationTitle = [
    'Create Account',
    'Company Information',
    'Supplemental Information',
    'Preview Application Information',
    'Completed'
]

export { CompanyInfoLine, PersonalInfoLine, RegistrationTitle };
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import RouterLink from './Link';

import { useAppSelector, useAppDispatch } from '@/redux/store.hook';
import { signOut, getAuthSelector } from '@/redux/auth/auth.slice';
import { clearCart } from '@/redux/cart/cart.slice';
import { useNavigate } from 'react-router-dom';
import { disconnect } from '@/redux/notification/notification.slice';
import useResize from '@/hooks/useResize';
import { Burger, User } from '@/components/Icon';
import { theme } from '@/theme/Theme';
import { Flex } from '../Flex';
import LanguageSelector from '@/components/UI/LanguageSelector';

const NavbarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

const NavbarLinks = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: ${(p) => p.theme.fontSizes.md};
  white-space: nowrap;

  @media (max-width: 1600px) {
    gap: 15px;
  }
`;

const NavbarLink = styled.li`
  position: relative;
  button {
    color: ${(p) => p.theme.colors.gray500};
    :hover {
      color: ${(p) => p.theme.colors.secondary500};
    }
  }
`;

const NavbarDropdownWrapper = styled(Menu)`
  padding: 20px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  border: none;
`;

const NavbarDropdownLink = styled(MenuItem)`
  :hover {
    color: ${(p) => p.theme.colors.secondary500};
  }
`;

const TrialButton = styled.button`
  padding: 10px 30px;
  background: ${(p) => p.theme.colors.primary500};
  border-radius: 5px;
  color: ${(p) => p.theme.colors.light};
  font-weight: bold;
  cursor: pointer;
`;

const RegisterButton = styled.button`
  padding: 10px 30px;
  background: ${(p) => p.theme.colors.light};
  border-radius: 5px;
  color: ${(p) => p.theme.colors.gray500};
  border: 1px solid ${(p) => p.theme.colors.gray500};
  font-weight: bold;
  cursor: pointer;
`;

const I18nSelect = styled.select`
  padding: 5px 0;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: ${(p) => p.theme.fontSizes.sm};
  border-color: ${(p) => p.theme.colors.primary100};
  border-width: 2px;
  border-radius: 5px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

interface NavProps {
  layoutController: boolean;
  registDone: boolean;
  burgerOpen: boolean;
  userOpen: boolean;
  isLogin: boolean;
  accessToken?: string;
  setBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar = ({
  isLogin,
  accessToken,
  userOpen,
  setUserOpen,
  burgerOpen,
  setBurgerOpen,
  layoutController,
  registDone,
}: NavProps) => {
  const { isTablet } = useResize();
  const [showRWD, setShowRWD] = useState(true);
  
  return (
    <>
      {showRWD ? (
        isTablet ? (
          <MobileNavbar
            burgerOpen={burgerOpen}
            setBurgerOpen={setBurgerOpen}
            layoutController={layoutController}
            registDone={registDone}
            userOpen={userOpen}
            isLogin={isLogin}
            accessToken={accessToken}
            setUserOpen={setUserOpen}
          />
        ) : (
          <PCNavbar
            isLogin={isLogin}
            accessToken={accessToken}
            layoutController={layoutController}
            registDone={registDone}
          />
        )
      ) : (
        <PCNavbar
          isLogin={isLogin}
          accessToken={accessToken}
          layoutController={layoutController}
          registDone={registDone}
        />
      )}
    </>
  );
};

const MobileNavbar = ({
  isLogin,
  accessToken,
  userOpen,
  setUserOpen,
  burgerOpen,
  setBurgerOpen,
  layoutController,
  registDone,
}: NavProps) => {
  const { isMobile } = useResize();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  return (
    <Flex gridGap={isMobile ? 10 : 15}>
      {isLogin && userData.acc_firstName && (
        <button id="basic-button" onClick={() => setUserOpen(!userOpen)}>
          <User.UserSVG
            width={isMobile ? 24 : 48}
            height={isMobile ? 24 : 48}
            fill={userOpen ? theme.colors.secondary500 : theme.colors.primary500}
          />
        </button>
      )}

      <button id="basic-button" onClick={() => setBurgerOpen(!burgerOpen)}>
        <Burger.BurgerSVG
          width={isMobile ? 24 : 48}
          height={isMobile ? 24 : 48}
          fill={burgerOpen ? theme.colors.secondary500 : theme.colors.primary500}
        />
      </button>
    </Flex>
  );
};

const PCNavbar = ({
  layoutController,
  registDone,
  isLogin,
  accessToken,
}: Omit<NavProps, 'setBurgerOpen' | 'burgerOpen' | 'setUserOpen' | 'userOpen'>) => {
  const auth = useAppSelector(getAuthSelector);
  // @ts-ignore
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const memberLinks = t('pages.navbar.memberLinks', { returnObjects: true });
  const barlinks = t('pages.navbar.links', { returnObjects: true });

  const [anchorEl, setAnchorEl] = useState<null | { [x: number]: HTMLElement }>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userSignout = () => {
    dispatch(disconnect());
    dispatch(signOut());
    dispatch(clearCart());

    // 一秒後再跳轉
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toBottom = () => {
    window.scrollTo({ top: 100000, behavior: 'smooth' });
  };

  return (
    <div>
      <LanguageSelector theme="dark" />
      <NavbarWrapper>
        {registDone && (
          <NavbarLinks>
            {layoutController ? (
              <>
                <NavbarLink style={{ color: '#0B319D' }}>
                  {auth.userData && auth.userData.acc_firstName
                    ? `${auth.userData.acc_firstName} ${auth.userData.acc_lastName}`
                    : auth.userData?.email}
                </NavbarLink>
                {memberLinks.map((link, key) => (
                  <NavbarLink key={`m_${link.name}`}>
                    <button
                      type="button"
                      onClick={(e) => handleClick(e, key)}
                      style={{ cursor: 'pointer', fontWeight: 700 }}
                    >
                      {link.name}
                    </button>
                    <NavbarDropdownWrapper
                      anchorEl={anchorEl && anchorEl[key]}
                      open={anchorEl ? Boolean(anchorEl[key]) : false}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      PaperProps={{
                        style: {
                          transform: 'translateX(0) translateY(20px)',
                        },
                      }}
                    >
                      {link.children.map((cLink, cKey) => (
                        <NavbarDropdownLink
                          key={`mc_${cLink.name}`}
                          onClick={() => {
                            if (memberLinks[key].children[cKey].url === '/#') {
                              handleClose();
                              userSignout();
                            } else {
                              handleClose();
                              navigate(auth.isLogin && accessToken !== '' ? cLink.url : '/');
                            }
                          }}
                        >
                          {cLink.name}
                        </NavbarDropdownLink>
                      ))}
                    </NavbarDropdownWrapper>
                  </NavbarLink>
                ))}
                {/* <Link to="/cart">
                  <img width="32px" src={Cart.CartSVG} alt="" />
                </Link> */}
              </>
            ) : (
              <>
                {barlinks.map((link) => (
                  <NavbarLink key={`l_${link.name}`}>
                    <RouterLink onClick={link.url !== '/About#Contact' ? toTop : toBottom} to={link.url}>
                      {link.name}
                    </RouterLink>
                  </NavbarLink>
                ))}
                {!auth.isLogin && (
                  <>
                    <RouterLink to={auth.isLogin ? '/home' : '/auth/signin'}>
                      <TrialButton>{t('buttons.signIn')}</TrialButton>
                    </RouterLink>

                    <RouterLink to={auth.isLogin ? '/home' : '/auth/signup'}>
                      <RegisterButton>{t('buttons.register')}</RegisterButton>
                    </RouterLink>
                  </>
                )}
              </>
            )}
          </NavbarLinks>
        )}
      </NavbarWrapper>
    </div>
  );
};

export default Navbar;

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button } from '@/components/UI/Button';
import { TranslateFakeData } from './fakeData';
import FileUpload from '@/components/UI/FileUpload';

type DataRow = {
    name: string;
    folder: string;
    type: string;
    size: string;
    updated: string;
    action: string;
};

const TableTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: ${(p) => p.theme.fontSizes.md};
    line-height: 23px;
    color: ${(p) => p.theme.colors.gray500};
    margin-bottom: 10px;
`

const Description = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    color: ${(p) => p.theme.colors.gray500};
    margin-bottom: 15px;
`

const UploadedFile = () => {
    // @ts-ignore
    const { t } = useTranslation();
    const rawColumn = t('pages.translate.table.columns', { returnObjects: true });
    const columns: TableColumn<DataRow>[] = [
        {
            name: rawColumn[0],
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: rawColumn[1],
            selector: (row) => row.folder,
            sortable: true,
        },
        {
            name: rawColumn[2],
            selector: (row) => row.type
        },
        {
            name: rawColumn[3],
            selector: (row) => row.size
        },
        {
            name: rawColumn[4],
            selector: (row) => row.updated
        },
        {
            name: rawColumn[5],
            selector: (row) => row.action,
            cell: (row) => {
                return (
                    <Button variant={'primary'} size={'table'} onClick={() => console.log(row)}>
                        {row.action}
                    </Button>
                );
            },
        },
    ];

    const buttonGroup = [
        {
            title: 'Brrowse'
        },
        {
            title: 'My Folder'
        }
    ]

    return (
        <div style={{ marginBottom: 30 }}>
            <Description>
                Select the file to translate from Computer or My Folder.
            </Description>
            <FileUpload Buttons={buttonGroup} />
            <TableTitle>
                Uploaded file
            </TableTitle>
            <DataTable
                columns={columns}
                data={TranslateFakeData}
            />
        </div>

    );
};

export default UploadedFile;

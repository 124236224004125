import styled from 'styled-components';
import { BoxProps, box, Box } from '@/layout/Box';

const Backdrop = styled.div`
  position: fixed;
  z-index: 97;
  width: 100%;
  height: 100vh;
  background: #212121;
  opacity: 0.7;
`;

const ModalWrapper = styled(Box)<BoxProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 98;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 500px;
  align-items: center;
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px 15px 15px 0px;
  ${box};
`;

const DownloadLink = styled.span`
  color: ${(p) => p.theme.colors.primary500};
  font-size: ${(p) => p.theme.fontSizes.base};
  text-decoration-line: underline;
  cursor: pointer;
`;

const ModalForm = styled.form`
  position: absolute;
  top: 35%;
  left: calc(50% - 205px);
  width: 510px;
  background-color: #fff;
  padding: 25px;
  border-radius: 40px 40px 40px 0px;
  min-height: 230px
`;

const ModalTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 10px;
`;

const ModalNotice = styled.div`
  font-size: ${(p) => p.theme.fontSizes.sm};
  color: ${(p) => p.theme.colors.gray300};
  margin-bottom: 60px;
`;

export type ResolutionBtnProps = {
  variant: 'primary' | 'white';
};

const ResolutionBtn = styled.button<ResolutionBtnProps>`
  display: block;
  width: 100%;
  max-width: 400px;
  height: 70px;
  padding: 25px 0;
  font-size: ${(p) => p.theme.fontSizes.md};
  text-align: center;
  background: ${(p) => (p.variant === 'primary' ? p.theme.colors.primary500 : p.theme.colors.light)};
  color: ${(p) => (p.variant === 'primary' ? p.theme.colors.light : p.theme.colors.gray500)};
  border: 1px solid ${(p) => p.theme.colors.primary500};
  border-radius: 5px;
  cursor: ${(p) => (p.variant === 'primary' ? 'pointer' : 'default')};
  font-weight: 700;
  margin: auto;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 14px solid ${(p) => p.theme.colors.primary500};
`;

export { ModalWrapper, Backdrop, DownloadLink, ModalForm, ModalTitle, ModalNotice, ResolutionBtn, Triangle };

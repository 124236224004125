import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getMeta } from '@/api/frontData.api';

const SEO = () => {
   const keywords = '';
  const link = 'https://dealmaking.ebram.org';
  const imgLink = '%PUBLIC_URL%/images/logo.png';
  const author = 'eBRAM';
  const copyright = 'eBRAM';
  const itemType = 'WebSite';
  let structuredJSON = null;

  const [meta, setMeta] = useState<Meta>({
    id: 0,
    title: 'eBRAM',
    description:
      'Launched in 2023, Deal-Making Portal (DMP) is a legal technology to secure online transactions and streamline contract management.',
  });

  const initData = async () => {
    try {
      const meta = (await getMeta()).data;
      setMeta(meta);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Helmet>
      <html lang="en" itemScope itemType={`http://schema.org/${itemType}`} />
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="copyright" content={copyright} />
      <meta name="thumbnail" content={imgLink} />
      <meta name="theme-color" content="#ffffff" />

      <meta itemProp="name" content={meta.title} />
      <meta itemProp="url" content={link} />
      <meta itemProp="description" content={meta.description} />
      <meta itemProp="about" content={meta.description} />
      <meta itemProp="abstract" content={meta.description} />
      <meta itemProp="image" content={imgLink} />
      <meta itemProp="keywords" content={keywords} />
      <meta itemProp="author" content={author} />
      <meta itemProp="copyrightHolder" content={copyright} />

      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:url" content={link} />
      <meta property="og:site_name" content={meta.title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imgLink} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="126" />

      <link rel="author" href={link} />
      <link rel="publisher" href={link} />

      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:card" content={imgLink} />
      <meta name="twitter:image:src" content={imgLink} />

      <script type="application/ld+json">{structuredJSON}</script>
    </Helmet>
  );
};

export default SEO;

import styled from 'styled-components';

const LanguageSelect = styled.div<{ dark: boolean }>`
  display: flex;

  span {
    display: inline-block;
    padding: 0 12px;
    font-size: ${(p) => p.theme.fontSizes.base};
    color: ${(p) => (p.dark ? p.theme.colors.gray500 : p.theme.colors.light)}};
    font-weight: 700;
    border-left: 1px solid ${(p) => p.theme.colors.gray200};
    cursor: pointer;
  }
`;

const Language = styled.div<{ dark: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.dark ? 'flex-end' : 'flex-start')};
  margin-bottom: 30px;
  padding: ${(p) => (p.dark ? '0' : '14px 35px')};
`;

export { LanguageSelect, Language };

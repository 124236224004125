const countrySc = [
  { name: '中国', countryCode: '+86', timezone: 'GMT+8', currency: '人民币', language: '普通话' },
  { name: '香港', countryCode: '+852', timezone: 'GMT+8', currency: '港元', language: '粤语' },
  { name: '澳门', countryCode: '+853', timezone: 'GMT+8', currency: '澳门巴塔卡', language: '普通话' },
  { name: '阿富汗', countryCode: '+93', timezone: 'GMT+4:30', currency: '阿富汗尼', language: '普什图语' },
  { name: '奥兰羣岛', countryCode: '+358', timezone: 'GMT+3', currency: '欧元', language: '瑞典文' },
  { name: '阿尔巴尼亚', countryCode: '+355', timezone: 'GMT+2', currency: '列克', language: '阿尔巴尼亚语' },
  { name: '美属萨摩亚', countryCode: '+1684', timezone: 'GMT-11', currency: '美元', language: '英文' },
  { name: '安道尔', countryCode: '+376', timezone: 'GMT+2', currency: '欧元', language: '加泰罗尼亚' },
  { name: '安哥拉', countryCode: '+244', timezone: 'GMT+1', currency: '安哥拉匡撒', language: '葡萄牙文' },
  { name: '安圭拉', countryCode: '+1264', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '安提瓜和巴布达', countryCode: '+1268', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '阿根廷', countryCode: '+54', timezone: 'GMT-3', currency: '阿根廷披索', language: '西班牙文' },
  { name: '亚美尼亚', countryCode: '+374', timezone: 'GMT+4', currency: '德拉姆', language: '亚美尼亚文' },
  { name: '阿鲁巴', countryCode: '+297', timezone: 'GMT-4', currency: '阿鲁巴·弗洛林', language: '荷兰语' },
  { name: '澳大利亚', countryCode: '+61', timezone: 'GMT+8', currency: '澳元', language: '英文' },
  { name: '奥地利', countryCode: '+43', timezone: 'GMT+2', currency: '欧元', language: '德语' },
  { name: '阿塞拜疆', countryCode: '+994', timezone: 'GMT+4', currency: '阿塞拜疆马纳特', language: '阿塞拜疆' },
  { name: '巴哈马', countryCode: '+1242', timezone: 'GMT-4', currency: '巴哈马元', language: '英文' },
  { name: '巴林', countryCode: '+973', timezone: 'GMT+3', currency: '巴林第纳尔', language: '阿拉伯文' },
  { name: '孟加拉国国', countryCode: '+880', timezone: 'GMT+6', currency: '孟加拉国塔卡', language: '孟加拉国语' },
  { name: '巴巴多斯', countryCode: '+1246', timezone: 'GMT-4', currency: '巴巴多斯元', language: '英文' },
  { name: '白俄罗斯', countryCode: '+375', timezone: 'GMT+3', currency: '白俄罗斯卢布', language: '俄文' },
  { name: '比利时', countryCode: '+32', timezone: 'GMT+2', currency: '欧元', language: '荷兰语' },
  { name: '伯利兹', countryCode: '+501', timezone: 'GMT-6', currency: '伯利兹元', language: '英文' },
  { name: '贝宁', countryCode: '+229', timezone: 'GMT+1', currency: '西非法郎', language: '法文' },
  { name: '百慕大', countryCode: '+1441', timezone: 'GMT-3', currency: '百慕大元', language: '英文' },
  { name: '不丹', countryCode: '+975', timezone: 'GMT+6', currency: '努尔特鲁姆', language: '宗喀语' },
  {
    name: '玻利维亚，普鲁林国家',
    countryCode: '+591',
    timezone: 'GMT-4',
    currency: '玻利维亚玻利维亚',
    language: '西班牙文',
  },
  {
    name: '波斯尼亚－黑塞哥维那',
    countryCode: '+387',
    timezone: 'GMT+2',
    currency: '波斯尼亚－黑塞哥维那可兑换马克',
    language: '波斯尼亚语',
  },
  { name: '博茨瓦纳', countryCode: '+267', timezone: 'GMT+2', currency: '博茨瓦纳普拉', language: '英文' },
  { name: '巴西', countryCode: '+55', timezone: 'GMT-3', currency: '巴西雷亚尔', language: '葡萄牙文' },
  { name: '英属印度洋领土', countryCode: '+246', timezone: 'GMT+6', currency: '美元', language: '英文' },
  { name: '文莱达鲁萨兰国', countryCode: '+673', timezone: 'GMT+8', currency: '文莱元', language: '马来语' },
  { name: '保加利亚', countryCode: '+359', timezone: 'GMT+3', currency: '保加利亚列夫', language: '保加利亚语' },
  { name: '布基纳法索', countryCode: '+226', timezone: 'GMT+0', currency: '西非非洲金融共同体法郎', language: '法文' },
  { name: '布隆迪', countryCode: '+257', timezone: 'GMT+2', currency: '布隆迪法郎', language: '法文' },
  { name: '柬埔寨', countryCode: '+855', timezone: 'GMT+7', currency: '柬埔寨里尔', language: '高棉语' },
  { name: '喀麦隆', countryCode: '+237', timezone: 'GMT+1', currency: '中非金融共同体法郎', language: '英文' },
  { name: '加拿大', countryCode: '+1', timezone: 'GMT-8', currency: '加拿大元', language: '英文' },
  { name: '佛得角', countryCode: '+238', timezone: 'GMT-1', currency: '埃斯库多角', language: '葡萄牙文' },
  { name: '开曼羣岛', countryCode: '+345', timezone: 'GMT-5', currency: '开曼羣岛元', language: '英文' },
  { name: '中非共和国', countryCode: '+236', timezone: 'GMT+1', currency: '中非金融共同体法郎', language: '法文' },
  { name: '乍得', countryCode: '+235', timezone: 'GMT+1', currency: '中非金融共同体法郎', language: '法文' },
  { name: '智利', countryCode: '+56', timezone: 'GMT-4', currency: '智利披索', language: '西班牙文' },
  { name: '圣诞岛', countryCode: '+61', timezone: 'GMT+7', currency: '澳元', language: '英文' },
  { name: '科科斯（基林）群岛', countryCode: '+61', timezone: 'GMT+6:30', currency: '澳元', language: '英文' },
  { name: '哥伦比亚', countryCode: '+57', timezone: 'GMT-5', currency: '哥伦比亚披索', language: '西班牙文' },
  { name: '科摩罗', countryCode: '+269', timezone: 'GMT+3', currency: '科摩罗法郎', language: '科摩罗' },
  { name: '刚果', countryCode: '+242', timezone: 'GMT+1', currency: '中非金融共同体法郎', language: '法文' },
  { name: '刚果民主共和国', countryCode: '+243', timezone: 'GMT+1', currency: '刚果法郎', language: '法文' },
  { name: '库克群岛', countryCode: '+682', timezone: 'GMT-10', currency: '新西兰元', language: '英文' },
  { name: '哥斯达黎加', countryCode: '+506', timezone: 'GMT-6', currency: '哥斯达黎加科朗', language: '西班牙文' },
  { name: '科特迪瓦', countryCode: '+225', timezone: 'GMT+0', currency: '西非非洲金融共同体法郎', language: '法文' },
  { name: '克罗地亚', countryCode: '+385', timezone: 'GMT+1', currency: '克罗地亚库纳', language: '克罗地亚语' },
  { name: '古巴', countryCode: '+53', timezone: 'GMT-5', currency: '古巴披索', language: '西班牙文' },
  { name: '塞浦路斯', countryCode: '+357', timezone: 'GMT+2', currency: '欧元', language: '土耳其语' },
  { name: '捷克共和国', countryCode: '+420', timezone: 'GMT+1', currency: '捷克克罗讷', language: '捷克文' },
  { name: '丹麦', countryCode: '+45', timezone: 'GMT+1', currency: '丹麦克朗', language: '丹麦文' },
  { name: '吉布提', countryCode: '+253', timezone: 'GMT+3', currency: '吉布提法郎', language: '法文' },
  { name: '多米尼克', countryCode: '+1767', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '多米尼加共和国', countryCode: '+1849', timezone: 'GMT-4', currency: '多米尼加披索', language: '西班牙文' },
  { name: '厄瓜多尔', countryCode: '+593', timezone: 'GMT-5', currency: '美元', language: '西班牙文' },
  { name: '埃及', countryCode: '+20', timezone: 'GMT+2', currency: '埃及镑', language: '阿拉伯文' },
  { name: '萨尔瓦多', countryCode: '+503', timezone: 'GMT-6', currency: '美元', language: '西班牙文' },
  { name: '赤道几内亚', countryCode: '+240', timezone: 'GMT+1', currency: '中非金融共同体法郎', language: '西班牙文' },
  { name: '厄立特里亚', countryCode: '+291', timezone: 'GMT+3', currency: '厄立特里亚纳克法', language: '英文' },
  { name: '爱沙尼亚', countryCode: '+372', timezone: 'GMT+2', currency: '欧元', language: '爱沙尼亚语' },
  { name: '埃塞俄比亚', countryCode: '+251', timezone: 'GMT+3', currency: '埃塞俄比亚比尔', language: '阿姆哈拉文' },
  {
    name: '福克兰羣岛（马尔维纳斯）',
    countryCode: '+500',
    timezone: 'GMT-3',
    currency: '福克兰羣岛镑',
    language: '英文',
  },
  { name: '法罗群岛', countryCode: '+298', timezone: 'GMT+0', currency: '丹麦克朗', language: '法罗塞语' },
  { name: '斐济', countryCode: '+679', timezone: 'GMT+12', currency: '斐济元', language: '英文' },
  { name: '芬兰', countryCode: '+358', timezone: 'GMT+2', currency: '欧元', language: '芬兰语' },
  { name: '法国', countryCode: '+33', timezone: 'GMT+1', currency: '欧元', language: '法文' },
  { name: '法属圭亚那', countryCode: '+594', timezone: 'GMT-3', currency: '欧元', language: '法文' },
  { name: '法属波利尼西亚', countryCode: '+689', timezone: 'GMT-10', currency: '太平洋法郎', language: '法文' },
  { name: '加蓬', countryCode: '+241', timezone: 'GMT+1', currency: '中非金融共同体法郎', language: '法文' },
  { name: '冈比亚', countryCode: '+220', timezone: 'GMT+0', currency: '冈比亚达拉西', language: '英文' },
  { name: '格鲁吉亚', countryCode: '+995', timezone: 'GMT+4', currency: '格鲁吉亚拉里', language: '格鲁吉亚语' },
  { name: '德国', countryCode: '+49', timezone: 'GMT+1', currency: '欧元', language: '德语' },
  { name: '加纳', countryCode: '+233', timezone: 'GMT+0', currency: '加纳塞地', language: '英文' },
  { name: '直布罗陀', countryCode: '+350', timezone: 'GMT+1', currency: '直布罗陀镑', language: '英文' },
  { name: '希腊', countryCode: '+30', timezone: 'GMT+2', currency: '欧元', language: '希腊语' },
  { name: '格陵兰', countryCode: '+299', timezone: 'GMT-3', currency: '丹麦克朗', language: '丹麦文' },
  { name: '格林纳达', countryCode: '+1473', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '瓜德罗普岛', countryCode: '+590', timezone: 'GMT-4', currency: '欧元', language: '法文' },
  { name: '关岛', countryCode: '+1671', timezone: 'GMT+10', currency: '美元', language: '英文' },
  { name: '危地马拉', countryCode: '+502', timezone: 'GMT-6', currency: '危地马拉格查尔', language: '西班牙文' },
  { name: '根西岛', countryCode: '+44', timezone: 'GMT+0', currency: '英镑', language: '英文' },
  { name: '几内亚', countryCode: '+224', timezone: 'GMT+0', currency: '几内亚法郎', language: '法文' },
  {
    name: '几内亚比绍',
    countryCode: '+245',
    timezone: 'GMT+0',
    currency: '西非非洲金融共同体法郎',
    language: '葡萄牙文',
  },
  { name: '圭亚那', countryCode: '+595', timezone: 'GMT-4', currency: '圭亚那元', language: '英文' },
  { name: '海地', countryCode: '+509', timezone: 'GMT-5', currency: '海地古尔德', language: '法文' },
  { name: '教廷（梵蒂冈城国）', countryCode: '+379', timezone: 'GMT+1', currency: '欧元', language: '拉丁' },
  { name: '洪都拉斯', countryCode: '+504', timezone: 'GMT-6', currency: '洪都拉斯兰皮拉', language: '西班牙文' },
  { name: '匈牙利', countryCode: '+36', timezone: 'GMT+1', currency: '匈牙利福林', language: '匈牙利文' },
  { name: '冰岛', countryCode: '+354', timezone: 'GMT+0', currency: '冰岛克罗讷', language: '冰岛文' },
  { name: '印度', countryCode: '+91', timezone: 'GMT+5:30', currency: '印度卢比', language: '印度语' },
  { name: '印度尼西亚', countryCode: '+62', timezone: 'GMT+9', currency: '印度尼西亚卢比', language: '印度尼西亚文' },
  {
    name: '伊朗、波斯湾伊斯兰共和国',
    countryCode: '+98',
    timezone: 'GMT+3:30',
    currency: '伊朗里亚尔',
    language: '波斯语',
  },
  { name: '伊拉克', countryCode: '+964', timezone: 'GMT+3', currency: '伊拉克第纳尔', language: '阿拉伯文' },
  { name: '爱尔兰', countryCode: '+353', timezone: 'GMT+0', currency: '欧元', language: '英文' },
  { name: '马恩岛', countryCode: '+44', timezone: 'GMT+0', currency: '英镑', language: '英文' },
  { name: '以色列', countryCode: '+972', timezone: 'GMT+2', currency: '以色列新谢克尔', language: '希伯来文' },
  { name: '意大利', countryCode: '+39', timezone: 'GMT+1', currency: '欧元', language: '意大利文' },
  { name: '牙买加', countryCode: '+1876', timezone: 'GMT-5', currency: '牙买加元', language: '英文' },
  { name: '日本', countryCode: '+81', timezone: 'GMT+9', currency: '日圆', language: '日文' },
  { name: '泽西岛', countryCode: '+44', timezone: 'GMT+0', currency: '英镑', language: '英文' },
  { name: '约旦', countryCode: '+962', timezone: 'GMT+2', currency: '约旦第纳尔', language: '阿拉伯文' },
  { name: '哈萨克斯坦斯坦', countryCode: '+77', timezone: 'GMT+6', currency: '哈萨克斯坦坚戈', language: '俄文' },
  { name: '肯尼亚', countryCode: '+254', timezone: 'GMT+3', currency: '肯尼亚先令', language: '斯瓦希里语' },
  { name: '基里巴斯', countryCode: '+686', timezone: 'GMT+12', currency: '澳元', language: '英文' },
  { name: '朝鲜民主主义人民共和国', countryCode: '+850', timezone: 'GMT+9', currency: '朝鲜圆', language: '韩文' },
  { name: '大韩民国', countryCode: '+82', timezone: 'GMT+9', currency: '韩圆', language: '韩文' },
  { name: '科威特', countryCode: '+965', timezone: 'GMT+3', currency: '科威特第纳尔', language: '阿拉伯文' },
  { name: '吉尔吉斯斯坦', countryCode: '+996', timezone: 'GMT+6', currency: '吉尔吉斯索姆', language: '吉尔吉斯语' },
  { name: '寮国', countryCode: '+856', timezone: 'GMT+7', currency: '老挝基普', language: '老挝' },
  { name: '拉脱维亚', countryCode: '+371', timezone: 'GMT+2', currency: '欧元', language: '拉脱维亚文' },
  { name: '黎巴嫩', countryCode: '+961', timezone: 'GMT+2', currency: '黎巴嫩镑', language: '阿拉伯文' },
  { name: '莱索托', countryCode: '+266', timezone: 'GMT+2', currency: '莱索托洛蒂', language: '莱索托语' },
  { name: '利比里亚', countryCode: '+231', timezone: 'GMT+0', currency: '利比里亚元', language: '英文' },
  {
    name: '阿拉伯利比亚民众国',
    countryCode: '+218',
    timezone: 'GMT+2',
    currency: '利比亚第纳尔',
    language: '阿拉伯文',
  },
  { name: '列支敦士登', countryCode: '+423', timezone: 'GMT+1', currency: '瑞士法郎', language: '德语' },
  { name: '立陶宛', countryCode: '+370', timezone: 'GMT+2', currency: '欧元', language: '立陶宛语' },
  { name: '卢森堡', countryCode: '+352', timezone: 'GMT+1', currency: '欧元', language: '卢森堡文' },
  { name: '马其顿', countryCode: '+389', timezone: 'GMT+1', currency: '马其顿代纳尔', language: '马其顿语' },
  {
    name: '马达加斯加',
    countryCode: '+261',
    timezone: 'GMT+3',
    currency: '马达加斯加阿里亚里',
    language: '马达加斯加',
  },
  { name: '马拉维', countryCode: '+265', timezone: 'GMT+2', currency: '马拉维安夸查', language: '奇切瓦' },
  { name: '马来西亚', countryCode: '+60', timezone: 'GMT+8', currency: '马来西亚林吉特', language: '马来语' },
  { name: '马尔代夫', countryCode: '+960', timezone: 'GMT+5', currency: '马尔代夫拉菲亚', language: '迪维希语' },
  { name: '马里', countryCode: '+223', timezone: 'GMT+0', currency: '西非非洲金融共同体法郎', language: '法文' },
  { name: '马耳他', countryCode: '+356', timezone: 'GMT+1', currency: '欧元', language: '马耳他语' },
  { name: '马绍尔羣岛', countryCode: '+692', timezone: 'GMT+12', currency: '美元', language: '马绍尔语' },
  { name: '马丁尼克', countryCode: '+596', timezone: 'GMT-4', currency: '欧元', language: '法文' },
  { name: '毛里塔尼亚', countryCode: '+222', timezone: 'GMT+0', currency: '毛里塔尼亚·乌吉亚', language: '阿拉伯文' },
  { name: '毛里求斯', countryCode: '+230', timezone: 'GMT+4', currency: '毛里求斯卢比', language: '英文' },
  { name: '马约特岛岛', countryCode: '+262', timezone: 'GMT+3', currency: '欧元', language: '法文' },
  { name: '墨西哥', countryCode: '+52', timezone: 'GMT-7', currency: '墨西哥披索', language: '西班牙文' },
  { name: '密克罗尼西亚联邦', countryCode: '+691', timezone: 'GMT+11', currency: '美元', language: '英文' },
  { name: '摩尔多瓦', countryCode: '+373', timezone: 'GMT+2', currency: '摩尔多瓦列伊', language: '摩尔多瓦' },
  { name: '摩纳哥', countryCode: '+377', timezone: 'GMT+1', currency: '欧元', language: '法文' },
  { name: '蒙古', countryCode: '+976', timezone: 'GMT+8', currency: '蒙古图格里克', language: '蒙古文' },
  { name: '蒙特内哥罗', countryCode: '+382', timezone: 'GMT+1', currency: '欧元', language: '黑山' },
  { name: '蒙特塞拉特', countryCode: '+1664', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '摩洛哥', countryCode: '+212', timezone: 'GMT+0', currency: '摩洛哥迪拉姆', language: '阿拉伯文' },
  { name: '莫桑比克', countryCode: '+258', timezone: 'GMT+2', currency: '莫桑比克梅蒂克', language: '葡萄牙文' },
  { name: '缅甸', countryCode: '+95', timezone: 'GMT+6:30', currency: '缅元', language: '缅甸语' },
  { name: '纳米比亚', countryCode: '+264', timezone: 'GMT+1', currency: '纳米比亚元', language: '英文' },
  { name: '瑙鲁', countryCode: '+674', timezone: 'GMT+12', currency: '澳元', language: '瑙鲁' },
  { name: '尼泊尔', countryCode: '+977', timezone: 'GMT+5:45', currency: '尼泊尔卢比', language: '尼泊尔语' },
  { name: '荷兰', countryCode: '+31', timezone: 'GMT+1', currency: '欧元', language: '荷兰语' },
  { name: '荷属安的列斯羣岛', countryCode: '+599', timezone: 'GMT-4', currency: '荷属安地列斯盾', language: '荷兰语' },
  { name: '新喀里多尼亚', countryCode: '+687', timezone: 'GMT+11', currency: '太平洋法郎', language: '法文' },
  { name: '新西兰', countryCode: '+64', timezone: 'GMT+12', currency: '新西兰元', language: '英文' },
  { name: '尼加拉瓜', countryCode: '+505', timezone: 'GMT-6', currency: '尼加拉瓜科尔多瓦', language: '西班牙文' },
  { name: '尼日尔', countryCode: '+227', timezone: 'GMT+1', currency: '西非非洲金融共同体法郎', language: '法文' },
  { name: '尼日利亚', countryCode: '+234', timezone: 'GMT+1', currency: '尼日利亚奈拉', language: '英文' },
  { name: '纽埃', countryCode: '+683', timezone: 'GMT-11', currency: '新西兰元', language: '纽埃' },
  { name: '诺福克岛', countryCode: '+672', timezone: 'GMT+11', currency: '澳元', language: '英文' },
  { name: '北马里亚纳羣岛', countryCode: '+1670', timezone: 'GMT+10', currency: '美元', language: '英文' },
  { name: '挪威', countryCode: '+47', timezone: 'GMT+1', currency: '挪威克朗', language: '挪威文' },
  { name: '阿曼', countryCode: '+968', timezone: 'GMT+4', currency: '阿曼里亚尔', language: '阿拉伯文' },
  { name: '巴基斯坦', countryCode: '+92', timezone: 'GMT+5', currency: '巴基斯坦卢比', language: '乌尔都语' },
  { name: '帕劳', countryCode: '+680', timezone: 'GMT+9', currency: '美元', language: '帕劳' },
  { name: '被占领巴勒斯坦领土', countryCode: '+970', timezone: 'GMT+2', currency: '欧元', language: '阿拉伯文' },
  { name: '巴拿马', countryCode: '+507', timezone: 'GMT-5', currency: '美元', language: '西班牙文' },
  { name: '巴布亚新几内亚', countryCode: '+675', timezone: 'GMT+10', currency: '巴布亚新几内亚基纳', language: '英文' },
  { name: '巴拉圭', countryCode: '+595', timezone: 'GMT-4', currency: '巴拉圭瓜拉尼', language: '西班牙文' },
  { name: '秘鲁', countryCode: '+51', timezone: 'GMT-5', currency: '秘鲁太阳报', language: '西班牙文' },
  { name: '菲律宾', countryCode: '+63', timezone: 'GMT+8', currency: '菲律宾披索', language: '菲律宾语' },
  { name: '皮特凯恩', countryCode: '+872', timezone: 'GMT-8', currency: '新西兰元', language: '英文' },
  { name: '波兰', countryCode: '+48', timezone: 'GMT+1', currency: '波兰兹罗提', language: '波兰文' },
  { name: '葡萄牙', countryCode: '+351', timezone: 'GMT+0', currency: '欧元', language: '葡萄牙文' },
  { name: '波多黎各', countryCode: '+1939', timezone: 'GMT-4', currency: '美元', language: '西班牙文' },
  { name: '卡塔尔', countryCode: '+974', timezone: 'GMT+3', currency: '卡塔里里里亚尔', language: '阿拉伯文' },
  { name: '罗马尼亚', countryCode: '+40', timezone: 'GMT+2', currency: '罗马尼亚列伊', language: '罗马尼亚文' },
  { name: '俄罗斯', countryCode: '+7', timezone: 'GMT+3', currency: '俄罗斯卢布', language: '俄文' },
  { name: '卢旺达', countryCode: '+250', timezone: 'GMT+2', currency: '卢旺达法郎', language: '基尼亚卢旺达语' },
  { name: '留尼汪', countryCode: '+262', timezone: 'GMT+4', currency: '欧元', language: '法文' },
  { name: '圣巴塞勒米', countryCode: '+590', timezone: 'GMT-4', currency: '欧元', language: '法文' },
  {
    name: '圣赫勒拿、阿森松岛和特里斯坦达库尼亚岛',
    countryCode: '+290',
    timezone: 'GMT+0',
    currency: '圣赫勒拿镑',
    language: '英文',
  },
  { name: '圣基茨和尼维斯', countryCode: '+1869', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '圣卢西亚', countryCode: '+1758', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '圣马丁', countryCode: '+590', timezone: 'GMT-4', currency: '欧元', language: '英文' },
  { name: '圣皮埃尔和密克隆', countryCode: '+508', timezone: 'GMT-3', currency: '欧元', language: '法文' },
  { name: '圣文森特和格林纳丁斯', countryCode: '+1784', timezone: 'GMT-4', currency: '汤加勒比元', language: '英文' },
  { name: '萨摩亚', countryCode: '+685', timezone: 'GMT+13', currency: '萨摩亚·塔拉', language: '英文' },
  { name: '圣马力诺', countryCode: '+378', timezone: 'GMT+1', currency: '欧元', language: '意大利文' },
  {
    name: '圣多美和普林西比',
    countryCode: '+239',
    timezone: 'GMT+0',
    currency: '圣多美普林西比多布拉',
    language: '葡萄牙文',
  },
  { name: '沙特阿拉伯', countryCode: '+966', timezone: 'GMT+3', currency: '沙乌地里亚尔', language: '阿拉伯文' },
  { name: '塞内加尔', countryCode: '+221', timezone: 'GMT+0', currency: '太平洋法郎', language: '法文' },
  { name: '塞尔维亚', countryCode: '+381', timezone: 'GMT+1', currency: '塞尔维亚第纳尔', language: '塞尔维亚语' },
  { name: '塞舌尔', countryCode: '+248', timezone: 'GMT+4', currency: '塞舌尔卢比', language: '塞舌尔克里奥尔语' },
  { name: '塞拉利昂', countryCode: '+232', timezone: 'GMT+0', currency: '塞拉利昂', language: '英文' },
  { name: '新加坡', countryCode: '+65', timezone: 'GMT+8', currency: '新加坡元', language: '英文' },
  { name: '斯洛伐克', countryCode: '+421', timezone: 'GMT+1', currency: '欧元', language: '斯洛伐克语' },
  { name: '斯洛文尼亚', countryCode: '+386', timezone: 'GMT+1', currency: '欧元', language: '斯洛文尼亚语' },
  { name: '所罗门羣岛', countryCode: '+677', timezone: 'GMT+11', currency: '所罗门羣岛元', language: '英文' },
  { name: '索马里', countryCode: '+252', timezone: 'GMT+3', currency: '索马里先令', language: '阿拉伯文' },
  { name: '南非', countryCode: '+27', timezone: 'GMT+2', currency: '南非兰特', language: '南非荷兰语' },
  { name: '南苏丹', countryCode: '+211', timezone: 'GMT+3', currency: '南苏丹镑', language: '英文' },
  { name: '南乔治亚岛和南桑威奇岛', countryCode: '+500', timezone: 'GMT-2', currency: '英镑', language: '英文' },
  { name: '西班牙', countryCode: '+34', timezone: 'GMT+1', currency: '欧元', language: '西班牙文' },
  { name: '斯里兰卡', countryCode: '+94', timezone: 'GMT+5:30', currency: '斯里兰卡卢比', language: '僧伽罗语' },
  { name: '苏丹', countryCode: '+249', timezone: 'GMT+2', currency: '苏丹镑', language: '阿拉伯文' },
  { name: '苏里南', countryCode: '+597', timezone: 'GMT-3', currency: '苏里南元', language: '荷兰语' },
  { name: '斯瓦尔巴德和扬马延', countryCode: '+47', timezone: 'GMT+1', currency: '挪威克朗', language: '挪威文' },
  { name: '斯威士兰', countryCode: '+268', timezone: 'GMT+2', currency: '史瓦帝尼里兰吉尼', language: '斯威士兰' },
  { name: '瑞典', countryCode: '+46', timezone: 'GMT+1', currency: '瑞典克朗', language: '瑞典文' },
  { name: '瑞士', countryCode: '+41', timezone: 'GMT+1', currency: '瑞士法郎', language: '罗曼斯' },
  { name: '阿拉伯叙利亚共和国', countryCode: '+963', timezone: 'GMT+2', currency: '叙利亚镑', language: '阿拉伯文' },
  { name: '台湾', countryCode: '+886', timezone: 'GMT+8', currency: '新台币', language: '普通话' },
  { name: '塔吉克斯坦', countryCode: '+992', timezone: 'GMT+5', currency: '塔吉克索莫尼', language: '俄文' },
  {
    name: '坦桑尼亚联合共和国',
    countryCode: '+255',
    timezone: 'GMT+3',
    currency: '坦桑尼亚先令',
    language: '斯瓦希里语',
  },
  { name: '泰国', countryCode: '+66', timezone: 'GMT+7', currency: '泰铢', language: '泰文' },
  { name: '东帝汶', countryCode: '+670', timezone: 'GMT+9', currency: '美元', language: '提顿语' },
  { name: '多哥', countryCode: '+228', timezone: 'GMT+0', currency: '西非非洲金融共同体法郎', language: '法文' },
  { name: '托克劳', countryCode: '+690', timezone: 'GMT+13', currency: '新西兰元', language: '托克劳文' },
  { name: '汤加', countryCode: '+676', timezone: 'GMT+13', currency: '潘加', language: '同安' },
  {
    name: '特立尼达和多巴哥',
    countryCode: '+1868',
    timezone: 'GMT-4',
    currency: '特立尼达和多巴哥元',
    language: '英文',
  },
  { name: '突尼斯', countryCode: '+216', timezone: 'GMT+1', currency: '突尼斯第纳尔', language: '阿拉伯文' },
  { name: '土耳其', countryCode: '+90', timezone: 'GMT+3', currency: '土耳其里拉', language: '土耳其语' },
  { name: '土库曼斯坦', countryCode: '+993', timezone: 'GMT+5', currency: '土库曼斯坦马纳特', language: '土库曼' },
  { name: '特克斯和凯科斯羣岛', countryCode: '+1649', timezone: 'GMT-5', currency: '美元', language: '英文' },
  { name: '图瓦卢', countryCode: '+688', timezone: 'GMT+12', currency: '图瓦卢元', language: '图瓦卢语' },
  { name: '乌干达', countryCode: '+256', timezone: 'GMT+3', currency: '乌干达先令', language: '斯瓦希里语' },
  { name: '乌克兰', countryCode: '+380', timezone: 'GMT+2', currency: '乌克兰赫里夫尼亚', language: '乌克兰文' },
  { name: '阿拉伯联合酋长国', countryCode: '+971', timezone: 'GMT+4', currency: '阿联迪拉姆', language: '阿拉伯文' },
  { name: '英国', countryCode: '+44', timezone: 'GMT+0', currency: '英镑', language: '英文' },
  { name: '美国', countryCode: '+1', timezone: 'GMT-10', currency: '美元', language: '英文' },
  { name: '乌拉圭', countryCode: '+598', timezone: 'GMT-3', currency: '乌拉圭披索', language: '西班牙文' },
  {
    name: '乌兹别克斯坦',
    countryCode: '+998',
    timezone: 'GMT+5',
    currency: '乌兹别克斯坦索姆',
    language: '乌兹别克语',
  },
  { name: '瓦努阿图', countryCode: '+678', timezone: 'GMT+11', currency: '瓦努阿图瓦图', language: '比斯拉马语' },
  {
    name: '委内瑞拉，玻利瓦尔委内瑞拉共和国',
    countryCode: '+58',
    timezone: 'GMT-4',
    currency: '委内瑞拉玻利瓦尔',
    language: '西班牙文',
  },
  { name: '越南', countryCode: '+84', timezone: 'GMT+7', currency: '越南盾', language: '越南文' },
  { name: '英属维尔京群岛', countryCode: '+1284', timezone: 'GMT-4', currency: '美元', language: '英文' },
  { name: '美属维京群岛', countryCode: '+1340', timezone: 'GMT-4', currency: '美元', language: '英文' },
  { name: '瓦利斯和富图纳', countryCode: '+681', timezone: 'GMT+12', currency: '太平洋法郎', language: '法文' },
  { name: '也门', countryCode: '+967', timezone: 'GMT+3', currency: '也门里亚尔', language: '阿拉伯文' },
  { name: '赞比亚', countryCode: '+260', timezone: 'GMT+2', currency: '赞比亚克瓦查', language: '英文' },
  { name: '津巴布韦', countryCode: '+263', timezone: 'GMT+2', currency: '津巴布韦元', language: '英文' },
  { name: '其他', countryCode: '-', timezone: 'GMT+0', currency: '美元', language: '英文' },
];

export default countrySc;

import { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { useAppDispatch, useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, signOut } from '@/redux/auth/auth.slice';
import useResize from '@/hooks/useResize';
import { Divider, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { disconnect } from '@/redux/notification/notification.slice';
import { clearCart } from '@/redux/cart/cart.slice';
import { Flex } from '../Flex';

interface UserbarProps {
  setUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Userbar = ({ setUserOpen }: UserbarProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const { size, isMobile, isTablet } = useResize();
  const auth = useAppSelector(getAuthSelector);
  const dispatch = useAppDispatch();
  const { isLogin, userData } = auth;
  const [topSpace, setTopSpace] = useState(80);
  const navigate = useNavigate();

  const memberLinks = t('pages.navbar.memberLinks', { returnObjects: true });

  // 建立滾軸偵測, 超過150px就將topSpace設為60px
  window.addEventListener('scroll', () => {
    if (isTablet) {
      setTopSpace(80);
      return;
    }
    if (window.scrollY > 150) {
      setTopSpace(32);
    } else {
      setTopSpace(160);
    }
  });

  useEffect(() => {
    if (isTablet) {
      setTopSpace(80);
    } else {
      setTopSpace(160);
    }
  }, [size, isTablet]);

  const userSignout = () => {
    dispatch(disconnect());
    dispatch(signOut());
    dispatch(clearCart());

    // 一秒後再跳轉
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  return (
    <Wrapper style={{ paddingTop: topSpace }} isMobile={isMobile}>
      <List>
        <Flex flexDirection={'column'} gridGap={15} padding={'15px 30px'}>
          <Text color={theme.colors.secondary500}>{isLogin && userData?.acc_coname}</Text>
          <Text color={theme.colors.light}>{isLogin && `/${userData?.acc_lastName} ${userData?.acc_firstName}`}</Text>
        </Flex>
        <Divider variant="middle" light={true} />
        {memberLinks[0].children.map((page) => (
          <>
            <div
              className="item"
              onClick={() => {
                setUserOpen(false);
                if (page.url === '/#') {
                  userSignout();
                } else {
                  navigate(page.url);
                }
              }}
            >
              <p>{page.name}</p>
            </div>
            <Divider variant="middle" light={true} />
          </>
        ))}
      </List>
    </Wrapper>
  );
};

export default Userbar;

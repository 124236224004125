import Echo from 'laravel-echo';
import * as Pusher from 'pusher-js';
import configs from '@/configs';

declare global {
  interface Window {
    Pusher: any;
    Echo: any;
  }
}

class SocketConnection {
  private static instance: SocketConnection | null = null;
  private echo: Echo | null;

  private constructor(private token: string) {
    this.echo = null;
    this.createSocketConnection();
  }

  public static getInstance(token: string): SocketConnection {
    if (!SocketConnection.instance) {
      SocketConnection.instance = new SocketConnection(token);
    }

    return SocketConnection.instance;
  }

  private createSocketConnection() {
    window.Pusher = Pusher;
    window.Echo = Echo;

    this.echo = new Echo({
      broadcaster: 'pusher',
      key: configs.REACT_APP_WS_KEY,
      wsPath: '/websocket',
      wsHost: window.location.hostname,
      wsPort: configs.REACT_APP_WS_PORT,
      wssPort: configs.REACT_APP_WS_PORT,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      cluster: 'mt1',
      auth: {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      },
      authEndpoint: configs.REACT_APP_WS_END_POINT,
    });
  }

  public getEchoInstance(): Echo {
    if (!this.echo) {
      throw new Error('Echo instance not found');
    }

    return this.echo;
  }
}



export default SocketConnection;

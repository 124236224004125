import { useState, useEffect } from 'react';
import { Wrapper } from '../styles';
import { Text, Title } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { useSearchParams } from 'react-router-dom';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { useTranslation } from 'react-i18next';
import useCurrentLang from '@/hooks/useCurrentLang';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  checkLevel: () => boolean;
}

const Information = ({ checkLevel, setCurrentStep, setActiveStep }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();

  const [searchParmas, setSearchParams] = useSearchParams();
  const [linkDetail, setLinkDetail] = useState({
    link: 'https://www.ecert.gov.hk/product/ecert/apply/img/e-Cert_(O)_RN.pdf',
    text: t('buttons.eCertApplicationForm'),
  });
  const [title, setTitle] = useState<string>(t('pages.eTrust.eCert.steps.step2.title'));

  const prevHandler = () => {
    setCurrentStep('1');
    setActiveStep(0);
    setSearchParams({ step: '1' });
  };

  const nextHandler = () => {
    const type = searchParmas.get('type');
    setCurrentStep('3');
    setActiveStep(2);
    setSearchParams({ step: '3', type: type ? type : 'new' });
  };

  useEffect(() => {
    const type = searchParmas.get('type');
    if (type && type === 'renewal') {
      setTitle(t('pages.eTrust.eCert.steps.step2.titleRenewal'));
    } else if (type && type === 'new') {
      setTitle(t('pages.eTrust.eCert.steps.step2.title'));
    }
  }, [t, searchParmas]);

  useEffect(() => {
    if (!checkLevel()) return;
    const type = searchParmas.get('type');
    setLinkDetail({
      ...linkDetail,
      link: 'https://www.hongkongpost.gov.hk/product/ecert/apply/img/e-Cert_(O_E_S)_N.pdf',
    });

    if (type && type === 'renewal') {
      if (lang !== 'en') {
        setLinkDetail({
          text: t('buttons.eCertRenewalForm'),
          link: 'https://www.ecert.gov.hk/product/ecert/apply/img/e-Cert_(O)_RN_c.pdf',
        });
        return;
      }

      setLinkDetail({
        text: t('buttons.eCertRenewalForm'),
        link: 'https://www.ecert.gov.hk/product/ecert/apply/img/e-Cert_(O)_RN.pdf',
      });
    }
  }, [t, searchParmas]);

  return (
    <Wrapper>
      <Flex flexDirection={'column'} gridGap={30}>
        <Title color={theme.colors.gray500}>{title}</Title>

        <Text>{t('pages.eTrust.eCert.steps.step2.intro')}</Text>

        <Box maxWidth={600}>
          <a target="_blank" rel="noreferrer" href={linkDetail.link}>
            <Button size={'lg'} type="button" variant={'primary'} rounded={false}>
              {linkDetail.text}
            </Button>
          </a>
        </Box>

        <Text>{t('pages.eTrust.eCert.steps.step2.applicationWorkflow')}</Text>
      </Flex>

      <Box marginTop={100}>
        <Button size={'lg'} type="button" variant={'primary'} rounded={false} marginRight={40} onClick={nextHandler}>
          {t('buttons.next')}
        </Button>
        <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
          {t('buttons.back')}
        </Button>
      </Box>
    </Wrapper>
  );
};

export default Information;

import styled from 'styled-components';

const ProgressStage = styled.div<{ complete?: boolean }>`
  position: relative;
  padding: 9px 25px;
  margin: 0;
  height: 41px;
  width: 100%;
  background-color: ${(p) => (p.complete ? p.theme.colors.secondary400 : p.theme.colors.gray100)};
  font-size: ${(p) => p.theme.fontSizes.md};
  ::before,
  ::after {
    content: '';
    position: absolute;
    right: 14px;
    background-color: ${(p) => (p.complete ? p.theme.colors.secondary400 : p.theme.colors.gray100)};
    box-shadow: -5px 0px 0px 0px ${(p) => p.theme.colors.light};
  }
  ::before {
    height: 50%;
    width: 100%;
    top: 0;
    transform: skew(55deg);
  }
  ::after {
    height: 50%;
    width: 100%;
    top: 50%;
    transform: skew(-55deg);
  }
  span {
    position: absolute;
    z-index: 1;
  }

  &.cancelled {
    background-color: ${(p) => (p.theme.colors.red)};
    color: ${(p) => (p.theme.colors.light)};

    ::before,
    ::after {
      background-color: ${(p) => (p.theme.colors.red)};
    }
  }

  &.progressing {
    background-color: ${(p) => (p.theme.colors.yellow)};
    color: ${(p) => (p.theme.colors.light)};

    ::before,
    ::after {
      background-color: ${(p) => (p.theme.colors.yellow)};
    }
  }
`;

export { ProgressStage };

// background-color: ${(p) => (p.complete ? p.theme.colors.secondary400 : p.theme.colors.gray100)};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormTitle, TipWrapper } from '@/components/Container/AuthContainer/styles';
import { Hr, Wrapper } from '../styles';
import { Box, Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import { createLEI, updateLEI, submitLEI } from '@/api/lei.api';
import { errorHandler, successHandler } from '@/utils/toastHandler';
import {
  InitBasicInformation,
  InitDirectParent,
  InitLegalAddress,
  InitLegalForm,
  InitLegalOfficeAddress,
  InitLegalOtherAddress,
  InitLegalOtherOfficeAddress,
  InitSupportingMaterials,
  InitUltimateParent,
} from './formData';
import useResize from '@/hooks/useResize';
import { TCTermsMarkdown } from '@/components/UI/Terms';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { H2 } from '@/components/UI/Typography/Heading';
import { Modal } from '@mui/material';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { FormDataToRawData } from './convertLEIFormData';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  data: LEIRequestData;
  LEIOptions: LEIResponse;
  checkLevel: () => boolean;
  status: number;
}

const Preview = ({ status, checkLevel, data, setCurrentStep, setActiveStep, LEIOptions }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const { userData } = useAppSelector(getAuthSelector);
  const [seatchParmas, setSearchParams] = useSearchParams();
  // const [basicInformation, setBasicInformation] = useState<RowProp[]>([]);
  // const [legalAddress, setLegalAddress] = useState<RowProp[]>([]);
  // const [officeAddress, setOfficeAddress] = useState<RowProp[]>([]);
  // const [legalForm, setLegalForm] = useState<RowProp[]>([]);
  // const [supportingMaterials, setSupportingMaterials] = useState<RowProp[]>([]);
  // const [directParent, setDirectParent] = useState<RowProp[]>([]);
  // const [ultimateParent, setUltimateParent] = useState<RowProp[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noReasonshipReasons, setNoReasonshipReasons] = useState<NoRelationshipReasonOptions[]>([]);
  const [showTCModal, setShowTCModal] = useState(false);
  const [TC, setTC] = useState('');
  const [termsInfo, setTermsInfo] = useState(true);
  const location = useLocation();

  const { handleSubmit } = useForm<{}>();

  const onSubmit = handleSubmit(async (formData) => {
    setIsLoading(true);
    setShowTCModal(false);
    try {
      const LEIFormData: CreateLEI = FormDataToRawData(
        data,
        noReasonshipReasons
      );

      if (!LEIFormData) return;
      // status = -99代表此使用者未送過create, 需打createLEI api
      // status = 0代表此使用者送過create, 但未送過submit, 需打updateLEI api
      // status = 1代表此使用者送過create, 且送過submit, 不需打api
      // status = 2代表此使用者送過create, 且送過submit, 但數據不正確, 需打updateLEI api
      // status = 7代表已撤銷此LEI, 需打createLEI api重送資料
      if (status === -99) {
        const res = (await createLEI(LEIFormData.LEIRecord)).data;
        if (res.success) {
          // 送出後檢查是否有簽名次數, 若無則導向至/cart?item=15
          if (userData.user_feature.LEIApplicantTimes <= 0) {
            const currentUrl = `${location.pathname}${location.search}`;
            navigate('/cart?item=15', { state: { from: currentUrl } });
            return false;
          } else {
            successHandler(t('success.submit'));
            await submitLEI();
            nextHandler();
          }
        }

        return;
      }

      const res = (await updateLEI(LEIFormData.LEIRecord)).data;
      if (res.success) {
        // 送出後檢查是否有簽名次數, 若無則導向至/cart?item=15
        if (userData.user_feature.LEIApplicantTimes <= 0) {
          const currentUrl = `${location.pathname}${location.search}`;
          navigate('/cart?item=15', { state: { from: currentUrl } });
          return false;
        } else {
          successHandler(t('success.submit'));
          await submitLEI();
          nextHandler();
        }
      }
    } catch (error: any) {
      console.log(error);
      errorHandler(error.response.data.data);
    } finally {
      setIsLoading(false);
    }
  });

  const prevHandler = () => {
    setCurrentStep('2');
    setActiveStep(1);
    setSearchParams({ step: '2', tab: '5' });
  };

  const nextHandler = () => {
    setCurrentStep('5');
    setActiveStep(4);
    setSearchParams({ step: '5' });
  };

  const gotoTabHandler = (tab: string) => {
    setCurrentStep('2');
    setActiveStep(1);
    setSearchParams({ step: '2', tab });
  };

  useEffect(() => {
    if (!checkLevel()) return;
    if (!LEIOptions.NoRelationshipReason) return;
    const filterReasons = LEIOptions.NoRelationshipReason.filter((reason) => reason.textEn !== '');
    setNoReasonshipReasons(filterReasons);
    fetch(TCTermsMarkdown)
      .then((res) => res.text())
      .then((md) => {
        setTC(md);
      });
  }, [LEIOptions, t]);

  const termHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { currentTarget } = e;

    if (currentTarget.scrollHeight - currentTarget.scrollTop <= currentTarget.clientHeight + 2) {
      setTermsInfo(false);
    }
  };

  return (
    <Flex flexDirection={'column'} gridGap={15}>
      <LoadingPage isLoading={isLoading} />
      <Modal
        open={showTCModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: 'flex' }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            maxWidth: '930px',
            backgroundColor: '#fff',
            padding: isMobile ? '15px 25px' : '30px 50px',
            borderRadius: '40px 40px 40px 0px',
          }}
        >
          <H2>{t('pages.auth.signUp.modal.terms.t&c.title')}</H2>
          <span style={{ font: 'Roboto 400 16px', lineHeight: '30px', color: 'red' }}>
            {t('pages.auth.signUp.modal.terms.t&c.title')}
          </span>
          <div
            className="content"
            style={{
              marginTop: '25px',
              height: '40vh',
              overflowY: 'auto',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: isMobile ? '16px' : '20px',
              color: '#616161',
              lineHeight: '30px',
            }}
            onScroll={termHandler}
          >
            <ReactMarkdown children={TC} remarkPlugins={[remarkGfm]} />
          </div>
          <Flex
            gridGap={isMobile ? 20 : 40}
            marginTop={'28px'}
            alignItems={'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Button
              width={isMobile ? '100%' : 'unset'}
              onClick={onSubmit}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              disabled={termsInfo}
            >
              {t('buttons.agree')}
            </Button>
            <Button
              width={isMobile ? '100%' : 'unset'}
              color="primary"
              onClick={() => setShowTCModal(false)}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.back')}
            </Button>
          </Flex>
        </div>
      </Modal>
      <Wrapper>
        <FormTitle>
          <Flex justifyContent={'space-between'}>
            <span>{t('pages.eTrust.LEI.steps.step3.basicInfo')}</span>
            <TipWrapper isMobile={isMobile} onClick={() => gotoTabHandler('1')}>
              {t('info.goToPage')}
            </TipWrapper>
          </Flex>
        </FormTitle>
        <InitBasicInformation data={data} options={LEIOptions} />
        <Hr />

        <FormTitle>{t('info.registerAddress')}</FormTitle>
        <InitLegalAddress data={data} options={LEIOptions} />
        <Hr />

        {data.address?.otherRegisterAddressAvaliavle && (
          <>
            <FormTitle>{t('info.otherRegisterAddress')}</FormTitle>
            <InitLegalOtherAddress data={data} options={LEIOptions} />
            <Hr />
          </>
        )}

        <FormTitle>{t('info.officeAddress')}</FormTitle>
        <InitLegalOfficeAddress data={data} options={LEIOptions} />
        <Hr />

        {data.address?.otherOfficeAddressAvaliavle && (
          <>
            <FormTitle>{t('info.otherOfficeAddress')}</FormTitle>
            <InitLegalOtherOfficeAddress data={data} options={LEIOptions} />
            <Hr />
          </>
        )}

        <FormTitle>{t('pages.eTrust.LEI.steps.step3.legalForm')}</FormTitle>
        <InitLegalForm data={data} options={LEIOptions} />
        <Hr />

        <FormTitle>{t('pages.eTrust.LEI.steps.step3.supportingMaterials')}</FormTitle>
        <InitSupportingMaterials data={data} options={LEIOptions} />
      </Wrapper>

      {data.basicInformation?.entityLegalCategory !== 'BRANCH' && (
        <Wrapper>
          <FormTitle>
            <Flex justifyContent={'space-between'}>
              <span>{t('pages.eTrust.LEI.steps.step3.directParentCompany')}</span>
              <TipWrapper isMobile={isMobile} onClick={() => gotoTabHandler('4')}>
                {t('info.goToPage')}
              </TipWrapper>
            </Flex>
          </FormTitle>
          <InitDirectParent data={data} options={LEIOptions} />
        </Wrapper>
      )}

      {data.basicInformation?.entityLegalCategory !== 'BRANCH' ? (
        <Wrapper>
          <FormTitle>
            <Flex justifyContent={'space-between'}>
              <span>{t('pages.eTrust.LEI.steps.step3.ultimateParentCompany')}</span>
              <TipWrapper isMobile={isMobile} onClick={() => gotoTabHandler('5')}>
                {t('info.goToPage')}
              </TipWrapper>
            </Flex>
          </FormTitle>
          <InitUltimateParent data={data} options={LEIOptions} />

          <Box style={{ paddingTop: 50 }}>
            <Button
              size={'lg'}
              type="button"
              onClick={() => setShowTCModal(true)}
              variant={'primary'}
              rounded={false}
              marginRight={40}
            >
              {t('buttons.submit')}
            </Button>
            <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
              {t('buttons.back')}
            </Button>
          </Box>
        </Wrapper>
      ) : (
        <Wrapper>
          <Box>
            <Button
              size={'lg'}
              type="button"
              onClick={() => setShowTCModal(true)}
              variant={'primary'}
              rounded={false}
              marginRight={40}
            >
              {t('buttons.submit')}
            </Button>
            <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
              {t('buttons.back')}
            </Button>
          </Box>
        </Wrapper>
      )}
    </Flex>
  );
};

export default Preview;

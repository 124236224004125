import { useMemo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { ColumnDef } from '@tanstack/react-table';
import { successHandler } from '@/utils/toastHandler';
import { DownloadLink } from './styles';
import { theme } from '@/theme/Theme';

import { checkExecutionFile, deleteExecutionFile, getExecutionList } from '@/api/deal.api';
import Loading from '@/components/UI/Loading';
import { Card, Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import DataTable from '@/components/DataTable';
import { Check, Circle, CloudUpload, Delete, Download } from '@/components/Icon';
import { Text } from '@/components/UI/Typography/Text';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import { StatusTag } from '../DealListItem/styles';
import { convertLink } from '@/utils/convert';
import {
  AddNewItemModal,
  FileUploadModal,
  VerifyFileModal,
  VerifyResultModal,
  ResolutionModal,
} from '@/components/DealDetail/Modal/BlockChain';

type DataRow = {
  id: string;
  file_id: string;
  extension: string;
  check: boolean;
  upload: boolean;
  delete: boolean;
  accept: boolean;
  item: string;
  from: string;
  uploadDate: string | null;
  acceptedDate: string | null;
  action?: {
    path: string;
  };
  hash: string | null;
  acceptUser: string;
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'unset',
    maxWidth: 'unset',
    fontSize: theme.fontSizes.base,
    whiteSpace: 'nowrap',
  },
}));

const ExecutionEdit = ({
  onComfirmComletion,
  isAllow,
  dealRowData,
}: {
  onComfirmComletion: () => void;
  isAllow: boolean;
  dealRowData: DealDetail;
}) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddNewItem, setShowAddNewItem] = useState<boolean>(false);
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
  const [showVerifyFile, setShowVerifyFile] = useState<boolean>(false);
  const [showVerifyResult, setShowVerifyResult] = useState<boolean>(false);
  const [verifyResult, setVerifyResult] = useState<boolean>(false);
  const [showResolution, setShowResolution] = useState<boolean>(false);

  const [executionId, setExecutionId] = useState<string>('');
  const [verifyFileId, setVerifyFileId] = useState<string>('0');
  const [data, setData] = useState<DataRow[]>([]);
  const [dealData, setDealData] = useState<DealListItem>();
  const [filesData, setFilesData] = useState<DealRelation[]>();

  const [rated, setRated] = useState<boolean>(false);

  const getFileList = async (id: string) => {
    if (!id) return;
    setIsLoading(true);
    try {
      const res = (await getExecutionList(id)).data;
      upDateData(res.data);
    } catch (error) {}
    setIsLoading(false);
  };

  const onDeleteFile = async (execution_id: string) => {
    if (!id) return;
    setIsLoading(true);
    const data = {
      execution_id,
    };
    try {
      const res = (await deleteExecutionFile(data, id)).data;
      upDateData(res.data);
      successHandler(t('success.delete'));
    } catch (error) {}
    setIsLoading(false);
  };

  const onCheck = async (execution_id: string) => {
    if (!id) return;
    setIsLoading(true);
    const data = {
      execution_id,
    };
    try {
      const res = (await checkExecutionFile(data, id)).data;
      upDateData(res.data);
      successHandler(t('success.check'));
    } catch (error) {}
    setIsLoading(false);
  };

  const upDateData = (data: ExecutionFile[]) => {
    const convertData = data.map((item) => ({
      id: item.id.toString(),
      file_id: item.file_id ? item.file_id.toString() : '0',
      extension: item.doc_type,
      check: item.status.check,
      accept: item.status.accept,
      upload: item.status.upload,
      delete: item.status.delete,
      item: item.name,
      from: item.user.acc_firstName + ' ' + item.user.acc_lastName,
      hash: item.hash,
      uploadDate: item.upload_date,
      acceptedDate: item.accepted_at ? item.accepted_at.slice(0, 10) : null,
      acceptUser: item.accept_user ? item.accept_user.name : '',
      action: item.file
        ? {
            path: item.file.path,
          }
        : undefined,
    }));

    setData(convertData);
  };

  const initData = useCallback(async () => {
    if (!id) return;
    const data: DealListItem = {
      id: dealRowData.id,
      tag: 'inviting',
      tradeName: dealRowData.name,
      count: {
        document: 0,
        participants: dealRowData.participants.length,
        events: 0,
      },
      participants: dealRowData.participants,
      dealInitiation: [dealRowData.progress === '0' ? false : true],
      negotiation: dealRowData.negotiation,
      deal_close: dealRowData.deal_close,
      contractExecution: [dealRowData.excution_file_complete],
      executions: dealRowData.executions,
      progress: dealRowData.progress,
      execution_file_complete: dealRowData.excution_file_complete,
      deal_type: dealRowData.deal_type.name,
      category: dealRowData.category,
      rating_stage: dealRowData.rating_stage,
      deal_type_id: Number(dealRowData.deal_type_id),
    };
    const files: DealRelation[] = dealRowData.documents.map((data) => ({
      id: data.acc_id,
      name: data.file.name,
    }));
    setFilesData(files);
    setDealData(data);
    getFileList(id);
  }, [dealRowData]);

  const sendConfirm = async () => {
    if (!id) return;
    onComfirmComletion();
  };

  useEffect(() => {
    initData();
  }, [initData]);

  const copyText = (text: string | null) => {
    if (!text) return;
    // 檢測是否支援複製到剪貼簿
    if (!navigator.clipboard) {
      // 不支援則使用textarea
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      document.body.removeChild(textArea);
      successHandler(t('success.copied'));
      return;
    }

    // 寫入剪貼簿
    navigator.clipboard.writeText(text);
    successHandler(t('success.copied'));
  };

  const rawColumn = t('pages.dealDetail.execution.edit.table.columns', { returnObjects: true });

  const columns: ColumnDef<DataRow>[] = useMemo(
    () => [
      {
        accessorKey: 'Status',
        header: rawColumn[0],
        cell: ({ row }) => (
          <div style={{ marginLeft: 15 }}>
            {row.original.acceptedDate ? <Check.CheckSVG fill={theme.colors.secondary500} /> : <Circle.CircleSVG />}
          </div>
        ),
        enableSorting: false,
      },
      {
        accessorKey: 'File Name',
        header: rawColumn[1],
        cell: ({ row }) =>
          row.original.action ? (
            <Flex gridGap={10} alignItems={'center'}>
              <div>
                <DownloadLink
                  onClick={() => {
                    if (row.original.action) window.open(convertLink(row.original.action.path));
                  }}
                >
                  {row.original.item}
                </DownloadLink>
                <Flex gridGap={'5px'}>
                  <CustomTooltip title={row.original.hash} placement="top">
                    <Text
                      onClick={() => copyText(row.original.hash)}
                      style={{ cursor: 'pointer' }}
                      fontSize={theme.fontSizes.sm}
                      color={theme.colors.gray300}
                    >
                      {t('info.blockchainFingerprint')}:
                      {row.original.hash && row.original.hash.slice(0, 7) + '...' + row.original.hash.slice(-7)}
                    </Text>
                  </CustomTooltip>
                  <Text
                    onClick={() => {
                      setVerifyFileId(row.original.file_id);
                      setShowVerifyFile(true);
                    }}
                    color={theme.colors.secondary500}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    {t('buttons.verifyFile')}
                  </Text>
                </Flex>
              </div>
              {row.original.hash && (
                <Flex gridGap={'10px'}>
                  {/* <CustomTooltip title={'Copy hash to verify its existence in Blockchain'} placement="top">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (!row.original.hash) return;
                        successHandler(t('success.copied'));
                        navigator.clipboard.writeText(row.original.hash);
                      }}
                    >
                      <Flex alignItems={'center'}>
                        <Copy.CopySVG fill={theme.colors.primary400} />
                        copy
                      </Flex>
                    </div>
                  </CustomTooltip> */}
                  {/* <CustomTooltip title={'Click to verify document originality'} placement="top">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (!row.original.hash) return;
                        setShowVerifyFile(true);
                      }}
                    >
                      <FileCheck.FileCheckSVG fill={theme.colors.primary400} />
                    </div>
                  </CustomTooltip> */}
                </Flex>
              )}
            </Flex>
          ) : (
            row.original.item
          ),
        enableSorting: false,
      },
      {
        accessorKey: 'Item Type',
        header: rawColumn[2],
        cell: ({ row }) => row.original.extension,
        enableSorting: false,
      },
      {
        accessorKey: 'Uploaded By',
        header: rawColumn[3],
        cell: ({ row }) => row.original.from,
        enableSorting: false,
      },
      {
        accessorKey: 'Uploaded Date',
        header: rawColumn[4],
        cell: ({ row }) => row.original.uploadDate?.replace(/-/g, '-'),
        enableSorting: false,
      },
      {
        accessorKey: 'Accepted User',
        header: rawColumn[5],
        cell: ({ row }) => row.original.acceptUser,
        enableSorting: false,
      },
      {
        accessorKey: 'Accepted Date',
        header: rawColumn[6],
        cell: ({ row }) => row.original.acceptedDate?.replace(/-/g, '-'),
        enableSorting: false,
      },
      {
        accessorKey: 'action',
        header: rawColumn[6],
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Flex gridGap={'10px'}>
              {row.original.check && (
                <CustomTooltip title={t('info.confirmDocument')} placement="top">
                  <Check.FormCheck
                    onClick={() => {
                      onCheck(row.original.id);
                    }}
                  >
                    <Check.CheckSVG fill={theme.colors.secondary400} />
                  </Check.FormCheck>
                </CustomTooltip>
              )}
              {row.original.action && (
                <Download.FormDownload
                  onClick={() => {
                    if (row.original.action) window.open(convertLink(row.original.action.path));
                  }}
                >
                  <Download.DownloadSVG />
                </Download.FormDownload>
              )}
              {row.original.upload && !row.original.uploadDate && (
                <CustomTooltip title={t('info.downloadFile')} placement="top">
                  <CloudUpload.FormCloudUpload
                    onClick={() => {
                      setExecutionId(row.original.id);
                      setShowFileUpload(true);
                    }}
                  >
                    <CloudUpload.CloudUploadSVG />
                  </CloudUpload.FormCloudUpload>
                </CustomTooltip>
              )}

              {row.original.delete && (
                <Delete.FormDelete
                  onClick={() => {
                    onDeleteFile(row.original.id);
                  }}
                >
                  <Delete.DeleteSVG />
                </Delete.FormDelete>
              )}
            </Flex>
          );
        },
      },
    ],
    [t]
  );

  return (
    <Card>
      {dealData ? (
        <>
          <LoadingPage isLoading={isLoading} />
          <AddNewItemModal
            showModal={showAddNewItem}
            setShowModal={setShowAddNewItem}
            upDateData={upDateData}
            id={id}
          />
          <FileUploadModal
            showModal={showFileUpload}
            setShowModal={setShowFileUpload}
            upDateData={upDateData}
            id={id}
            executionId={executionId}
          />
          <VerifyFileModal
            showModal={showVerifyFile}
            setShowModal={setShowVerifyFile}
            setShowVerifyResult={setShowVerifyResult}
            setVerifyResult={setVerifyResult}
            file_id={verifyFileId}
            deal_id={id ? id : '0'}
          />
          <VerifyResultModal
            showModal={showVerifyResult}
            setShowModal={setShowVerifyResult}
            verifyResult={verifyResult}
          />
          <ResolutionModal showModal={showResolution} setShowModal={setShowResolution} />

          <Flex gridGap={'10px'}>
            <Text fontWeight={900} marginBottom={10}>
              {t('pages.dealDetail.execution.edit.title')}
            </Text>
            {dealData.progress === '99' && <StatusTag variant={'processing'}>{t('buttons.confirming')}</StatusTag>}
            {dealData.progress === '2' && <StatusTag variant={'completed'}>{t('buttons.completed')}</StatusTag>}
          </Flex>
          <Text marginBottom={20}>{t('pages.dealDetail.execution.edit.upload')}</Text>
          {dealData.progress === '1' && (
            <Flex gridGap={'10px'} width={'100%'} marginBottom={'15px'}>
              <Button
                variant={'primary'}
                onClick={() => {
                  setShowAddNewItem(true);
                }}
              >
                {t('buttons.addNewItem')}
              </Button>
            </Flex>
          )}
          <DataTable columns={columns} data={data} />
          <Flex marginTop={40} justifyContent={'space-between'} alignItems={'center'}>
            <button style={{ cursor: 'pointer' }} onClick={() => setShowResolution(true)}>
              <Text
                fontSize={theme.fontSizes.md}
                color={theme.colors.primary500}
                style={{ textDecoration: 'underline' }}
              >
                {t('pages.dealDetail.execution.edit.disputes')}
              </Text>
            </button>

            {dealData.progress === '1' && data.every((item) => item.acceptedDate) && !rated && (
              <Button
                size={'lg'}
                variant="primary-invert"
                onClick={() => {
                  sendConfirm();
                }}
              >
                {t('buttons.confirmCompletion')}
              </Button>
            )}
            {dealData.progress === '2' && dealData.rating_stage === '1' && (
              <Button
                size={'lg'}
                variant="orange"
                onClick={() => {
                  sendConfirm();
                }}
              >
                {t('buttons.comment')}
              </Button>
            )}
          </Flex>
        </>
      ) : (
        <Loading />
      )}
    </Card>
  );
};

export default ExecutionEdit;

import { useState, useEffect, useCallback } from 'react';
import Echo from 'laravel-echo';
import SocketConnection from '@/service/socketService';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

function listen(socket: Echo, channel: string, event: string, callBack: (payload: any) => void) {
  console.log('connect to channel: ' + channel);
  socket.private(channel).listen(event, (payload: any) => {
    callBack(payload);
  });

  return function cleanUp() {
    console.log('disconnect to channel: ' + channel);
    socket.leaveChannel(`private-${channel}`);
  };
}

type Options = {
  type: 'JOIN_CHAT' | 'CONNECT_NOTICE' | 'JOIN_CHATLIST';
  callBack: (payload: any) => void;
  roomId?: number;
};

export const useSocket = ({ type, callBack, roomId }: Options) => {
  const auth = useAppSelector(getAuthSelector);
  const { accessToken, userData, isLogin } = auth;
  const [socket, setSocket] = useState<Echo | null>(null);

  const connectSocket = useCallback(
    (socketInstance: Echo | null) => {
      let socketManager: SocketConnection | null = null;

      // 若使用者登出則中斷連線
      if (!isLogin && socketInstance) {
        console.log('disconnect...');
        socketInstance.disconnect();
        setSocket(null);
        return;
      }

      if (!accessToken) return;
      socketManager = SocketConnection.getInstance(accessToken);

      if (!socketInstance) {
        console.log('connecting...');
        setSocket(socketManager!.getEchoInstance() as Echo);
      }

      if (isLogin && socketInstance) {
        const socketConnect = socketInstance.connector.socket;
        if (socketConnect) {
          socketConnect.onclose = handleSocketClose;
        }
      }

      if (!socketInstance) return;
      switch (type) {
        case 'CONNECT_NOTICE': {
          return listen(socketInstance, `App.User.${userData.id}`, '.NotificationEvent', callBack);
        }
        case 'JOIN_CHAT': {
          if (!roomId) return;
          return listen(socketInstance, `Chatroom.${roomId}`, '.MessageEvent', callBack);
        }
        case 'JOIN_CHATLIST': {
          return listen(socketInstance, `User.${userData.id}.Chatrooms`, '.ChatroomEvent', callBack);
        }
      }
    },
    [isLogin, socket, type]
  );

  const handleSocketClose = () => {
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    const reconnectInterval = 3000; // 3秒
    if (reconnectAttempts < maxReconnectAttempts) {
      reconnectAttempts++;
      setTimeout(connectSocket, reconnectInterval);
    } else {
      console.log('無法建立WebSocket連線');
    }
  };

  useEffect(() => {
    connectSocket(socket);
  }, [connectSocket]);
};

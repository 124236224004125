import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageContainer from '@/components/Container/PageContainer';
import Introduction from '@/components/ETrust/LEI/Introduction';
import Information from '@/components/ETrust/LEI/Information';
import Preview from '@/components/ETrust/LEI/Preview';
import useUserDetail from '@/hooks/useUserDetail';
import { getLEI, getLEIOptionList } from '@/api/lei.api';
import RegistrationStatus from '@/components/ETrust/LEI/RegistrationStatus';
import Stepline from '@/components/ETrust/Stepline';
import { Box, LoadingPage } from '@/layout';
import { errorHandler } from '@/utils/toastHandler';
import { NeedUpgradePlanModal, NotEnoughQuotaModal } from '@/components/DocLibrary/Modal';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { RawDataToFormData } from '@/components/ETrust/LEI/Preview/convertLEIFormData';
import usePermissionCheck from '@/hooks/usePermissionCheck';

const LEI = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState<number>(0);
  const stepline = t('pages.eTrust.LEI.stepline', { returnObjects: true });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step'));
  const [LEIOptions, setLEIOptions] = useState<LEIResponse>({} as LEIResponse);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(-99);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showNotEnoughQuota, setShowNotEnoughQuota] = useState<boolean>(false);
  const { isMainAccount } = usePermissionCheck();
  const { userData } = useAppSelector(getAuthSelector);
  // 此頁不會進行使用者資料的更新, 故activeStep填固定值避免更新
  const userDetail = useUserDetail({});

  const [data, setData] = useState<LEIRequestData>({
    basicInformation: null,
    address: null,
    legalBusiness: null,
    directParent: null,
    ultimateParent: null,
  });

  const initData = useCallback(async () => {
    setIsLoading(true);
    if (LEIOptions.EntityLegalCategory) return;
    try {
      const res = (await getLEIOptionList()).data;
      if (res.success) {
        setLEIOptions(res.data);
        checkProgress(res.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const checkProgress = useCallback(async (options: LEIResponse) => {
    setIsLoading(true);
    try {
      const res: { data: LEIOriginalData } = (await getLEI()).data;
      // 若status大於等於0, 則先進行表單資料回填, can_submit
      if (res.data.status !== -99) {
        setStatus(res.data.status);
        const storeData = RawDataToFormData(res.data, options);
        setData(storeData);
        if (res.data.status === 0) return;
        if (res.data.status === 2) return;
        if (res.data.status === 7) return;
        navigate('/lei?step=5', { replace: true });
        setCurrentStep('5');
        setActiveStep(4);
        return;
      }
    } catch (error) {
      console.log(error);
      errorHandler(t('error.server'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.get('step')) {
      navigate('/lei?step=1', { replace: true });
      setCurrentStep('1');
      setActiveStep(0);
      return;
    }

    if (!currentStep || parseInt(currentStep) === 0) {
      setCurrentStep('1');
      navigate('/lei?step=1', { replace: true });
      setActiveStep(0);
    } else {
      const paramsStep = parseInt(currentStep);
      setActiveStep(paramsStep - 1);
    }
  }, [searchParams.get('step')]);

  useEffect(() => {
    initData();
  }, [initData]);

  const checkLevel = () => {
    // if (userData.plan.level <= 1) {
    //   setShowUpgradeModal(true);
    //   navigate('/lei?step=1', { replace: true });
    //   setCurrentStep('1');
    //   setActiveStep(0);
    //   return false;
    // }
    if (!isMainAccount) {
      setCurrentStep('1');
      navigate('/lei?step=1', { replace: true });
      setActiveStep(0);
      errorHandler(t('error.permission'));
    }
    return isMainAccount;
  };

  useEffect(() => {}, [t]);

  return (
    <PageContainer title={t('pages.eTrust.LEI.title')}>
      <LoadingPage isLoading={isLoading} />
      <NeedUpgradePlanModal showModal={showUpgradeModal} setShowModal={setShowUpgradeModal} />
      <NotEnoughQuotaModal showModal={showNotEnoughQuota} setShowModal={setShowNotEnoughQuota} />
      <div style={{ display: 'flex' }}>
        <Stepline activeStep={activeStep} stepline={stepline} type={'LEI'} />
        {activeStep === 0 && (
          <Introduction
            data={data}
            status={status}
            checkLevel={checkLevel}
            setActiveStep={setActiveStep}
            setCurrentStep={setCurrentStep}
          />
        )}
        {activeStep === 1 && (
          <Information
            status={status}
            data={data}
            checkLevel={checkLevel}
            setData={setData}
            userDetail={userDetail}
            setActiveStep={setActiveStep}
            setCurrentStep={setCurrentStep}
            LEIOptions={LEIOptions}
          />
        )}
        {activeStep === 2 && (
          <Preview
            status={status}
            data={data}
            checkLevel={checkLevel}
            setActiveStep={setActiveStep}
            setCurrentStep={setCurrentStep}
            LEIOptions={LEIOptions}
          />
        )}
        {activeStep === 4 && (
          <RegistrationStatus status={status} setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
        )}
      </div>
    </PageContainer>
  );
};

export default LEI;

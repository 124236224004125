import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useResize from '@/hooks/useResize';
import About from './About';
import Contact from './Contact';
import { GoTop } from '../style';
import Footer from '../Footer';
import StrategicPartners from '../StrategicPartners';

const AboutUs = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();

  useEffect(() => {
    // 取得網址的#後面的字串
    const hash = window.location.hash;
    // 如果有hash值，就滾動到該元素
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div>
      <About />
      <StrategicPartners />
      <Contact />
      {/* <Message /> */}
      <Footer />
      <GoTop
        isTablet={isTablet}
        isMobile={isMobile}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        {t('top')}
      </GoTop>
    </div>
  );
};

export default AboutUs;

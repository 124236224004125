import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';

import { StatusTag } from '@/components/DealList/DealListItem/styles';
import { theme } from '@/theme/Theme';

import fakeData from '@/components/ManageFiles/Files/fakeData';

interface DetailTitleProps {
  data: DealListItem;
  innerTitle?: string;
  fileId?: string;
  hideTag?: boolean;
  redir?: string;
}

const DetailTitle = ({ data, innerTitle, fileId, hideTag = false, redir = '' }: DetailTitleProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tagTitle, setTagTitle] = useState('');
  const contractData = fileId && fakeData.find((data) => data.id === fileId);
  const [expiredDeal, setExpiredDeal] = useState(false);

  const status: { [key in typeof data.tag]: string } = {
    inviting: t('statusTags.inviting'),
    processing: t('statusTags.processing'),
    completed: t('statusTags.completed'),
    cancelled: t('statusTags.cancelled'),
  };

  const checkExpired = (date: string | undefined, progress: string) => {
    if (progress === '1') {
      setTagTitle(status['processing']);
      return;
    }
    if (progress === '2') {
      setTagTitle(status['completed']);
      return;
    }
    if (progress === '3') {
      setTagTitle(status['cancelled']);
      return;
    }
    if (progress === '99') {
      setTagTitle(status['processing']);
      return;
    }
    if (!date) {
      setTagTitle(status['inviting']);
      return;
    }
    const now = new Date();
    const expired = new Date(date);
    if (now > expired) {
      setExpiredDeal(true);
      setTagTitle(t('statusTags.expired'));
    } else {
      setTagTitle(status['inviting']);
    }
  };

  const initData = useCallback(() => {
    checkExpired(data.expiry_date, data.progress);
  }, [data, t]);

  useEffect(() => initData(), [initData]);

  return (
    <Flex alignItems={'center'} gridGap={'12px'}>
      {!hideTag && <StatusTag variant={expiredDeal ? 'cancelled' : data.tag}>{tagTitle}</StatusTag>}
      {innerTitle && (
        <Button variant={'primary'} onClick={() => navigate(`/deal/${data.id}${redir}`)}>
          {t('buttons.back')}
        </Button>
      )}
      <Text fontSize={theme.fontSizes.lg} style={{ whiteSpace: 'nowrap' }}>
        {t('deal')}
      </Text>
      <Text fontSize={theme.fontSizes.lg}>/</Text>
      <Text fontSize={theme.fontSizes.lg} fontWeight={innerTitle ? '0' : '900'}>
        {data.tradeName}
      </Text>
      {innerTitle && (
        <>
          <Text fontSize={theme.fontSizes.lg}>/</Text>
          <Text fontSize={theme.fontSizes.lg} fontWeight={'700'}>
            {innerTitle} {contractData && fileId && `(${t('buttons.name')}: ${contractData.name})`}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default DetailTitle;

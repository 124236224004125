import styled from 'styled-components';

const SearchbarMask = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
`;

const Searchbar = styled.div`
  width: 100%;
  max-height: 200px;
  padding: 15px 0;
  border: 1px solid ${(p) => p.theme.colors.gray300};

  position: absolute;
  top: 100%;
  z-index: 100;
  background-color: white;
  overflow-y: auto;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.colors.gray100};
    }

    img {
      height: 30px;
    }
  }
`;

export { SearchbarMask, Searchbar };

// const items = [
//   {
//     id: '1',
//     name: 'e-Signature (eBRAM)',
//     description: 'per check',
//     price: 100,
//   },
//   {
//     id: '2',
//     name: 'Standard Plan',
//     description: 'per year',
//     price: 2800,
//   },
//   {
//     id: '3',
//     name: 'Premium Plan',
//     description: 'per year',
//     price: 5000,
//   },
//   {
//     id: '4',
//     name: 'Deal Contributor',
//     description: 'per year',
//     price: 5000,
//   },
// ];

// 傳入陣列，回傳items中的對應id的物件並多入quantity的欄位
const findItem = (items: Feature[], array: string[]) => {
  const result = array.map((id) => {
    const item = items.find((item) => item.id.toString() === id);
    // 找不到對應的商品的話, 透過errorHandler發出錯誤並導回首頁
    if (!item) {
      throw new Error('找不到對應的商品');
    }
    return {
      ...item,
      quantity: Number(item.quota),
      quotaAry: [item.quota],
      priceAry: [item.price],
      quotaOptions: [] as { value: string; text: string }[],
    };
  });

  // 檢查陣列中每個商品的name是否有重複出現多次在陣列中, 若有則將重複名稱的quota加入最早出現的quotaAry
  result.forEach((item: Feature, index: number, array: Feature[]) => {
    array.forEach((repeatItem) => {
      if (repeatItem.id !== item.id && repeatItem.name === item.name) {
        array[index].quotaAry.push(repeatItem.quota);
        array[index].priceAry.push(repeatItem.price);
      }
    });
  });

  // 刪除重複名稱的商品
  const uniqueResult = result.filter((item, index, array) => {
    return array.findIndex((item2) => item.name === item2.name) === index;
  });

  // 轉換quotaAry的資料型態成陣列物件供select使用
  uniqueResult.forEach((item) => {
    if (item.quotaAry.length === 1) {
      item.quotaOptions = item.quotaAry.map((quota) => ({ value: quota, text: quota }));
    } else {
      item.quotaOptions = item.quotaAry.map((quota, index) => ({
        value: quota,
        text: `${quota} / $${item.priceAry[index]}`,
      }));
    }
  });

  return uniqueResult;

  // return result;
};

export { findItem };

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import Theme from '@/theme';
import 'react-toastify/dist/ReactToastify.css';

import './i18n';
import GlobalStyle from '@/assets/style/GlobalStyle';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Theme>
      <App />
    </Theme>
  </React.StrictMode>
);

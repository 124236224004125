import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@/components/UI/Button';
import FormWrapper from '@/components/FormStepper/Wrapper';
import { Flex } from '@/layout/Flex';

import { dealInitiationStep1, dealInitiationStep4 } from '@/api/deal.api';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { dealType } from '@/data/select';
import Loading from '@/components/UI/Loading';
import { errorHandler, successHandler } from '@/utils/toastHandler';
import { convertCategory, convertToCurrentLangData } from '@/utils/convert';
import useCurrentLang from '@/hooks/useCurrentLang';

interface PreviewInformationProps {
  handleNextStep: () => Promise<void>;
  setNewDealId: Dispatch<SetStateAction<string | undefined>>;
  formData: DealStep1FormRequest;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleBackStep: () => void;
}

const PreviewInformation = ({
  handleNextStep,
  handleBackStep,
  setNewDealId,
  formData,
  setIsLoading,
}: PreviewInformationProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const [previewData, setpreviewData] = useState<DealStep2Preview>();
  const [dealTypeData, setDealTypeData] = useState<SelectOptions>([]);
  const dealRoleArray = t('dealRoleArray', { returnObjects: true });

  const { handleSubmit } = useForm<DealStep1FormFields>();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    const rawData = {
      ...formData,
    };

    try {
      const res = (await dealInitiationStep1(rawData)).data;

      setNewDealId(res.data.id);
      successHandler(t('success.dealInit'));
      handleNextStep();
    } catch (error) {
      errorHandler(t('error.dealInit'));
    } finally {
      setIsLoading(false);
    }
  });

  const formDataConver = useCallback(async () => {
    if (!formData.name) {
      handleBackStep();
      return;
    }

    const dealTypeAry = await dealType(lang);

    setDealTypeData(dealTypeAry);

    const convertData = {
      deal_role_id:
        formData.deal_role_id.toString() === '0'
          ? dealRoleArray[Number(formData.deal_type_id) - 1][0].text
          : formData.deal_role_id.toString() === '1'
          ? dealRoleArray[Number(formData.deal_type_id) - 1][1].text
          : t('partner'),
      deal_type_id: dealTypeAry[parseInt(formData.deal_type_id) - 1].text.toString(),
      name: formData.name,
      description: formData.description,
      expiry_date: formData.expiry_date,
      category: formData.category_other ? `${convertCategory(formData.category, lang)}(${formData.category_other})` : convertCategory(formData.category, lang),
      languages: formData.languages_other
        ? `${convertToCurrentLangData(formData.languages, 'language', lang)} (${formData.languages_other})`
        : convertToCurrentLangData(formData.languages, 'language', lang),
      currency: formData.currency_other
        ? `${convertToCurrentLangData(formData.currency, 'currency', lang)} (${formData.currency_other})`
        : convertToCurrentLangData(formData.currency, 'currency', lang),
    };
    setpreviewData(convertData);
  }, [formData, lang]);

  useEffect(() => {
    formDataConver();
  }, [formDataConver]);

  const rowTitle = t('pages.dealInitiation.formStepper.step2.columns', { returnObjects: true });

  return (
    <FormWrapper>
      <form onSubmit={onSubmit}>
        <Flex flexDirection={'column'} gridGap={20}>
          {!previewData && <Loading />}
          {previewData &&
            Object.values(previewData).map((item, index) => (
              <Flex key={index + item} width={'100%'}>
                <Text fontSize={'16'} color={theme.colors.gray300} style={{ width: 240 }}>
                  {rowTitle[index]}
                </Text>
                <Text style={{ flex: 1 }}>{item}</Text>
              </Flex>
            ))}
          <Flex gridGap={15} marginTop={50}>
            <Button size={'lg'} variant={'primary'}>
              {t('buttons.next')}
            </Button>
            <Button size={'lg'} variant={'primary-invert'} onClick={handleBackStep}>
              {t('buttons.back')}
            </Button>
          </Flex>
        </Flex>
      </form>
    </FormWrapper>
  );
};

export default PreviewInformation;

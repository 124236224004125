import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ContainerWrapper,
  Info,
  ImageWrapper,
  SigninButton,
  BackgroundWrapper,
  SwiperBtn,
  SwiperCard,
  SwiperPaginationWrapper,
} from './style';
import useCurrentLang from '@/hooks/useCurrentLang';
import useResize from '@/hooks/useResize';
import { getPosts, getFeatures } from '@/api/frontData.api';
import PATH from '@/router/path';
import { sify } from 'chinese-conv/dist';
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { H2 } from '@/components/UI/Typography/Heading';
import { Box } from '@/layout';

const Banner = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const { isTablet, isMobile } = useResize();
  const [posts, setPosts] = useState<(BannerFeature | BannerPost)[]>([]);

  const initData = async () => {
    try {
      const posts = (await getPosts()).data;
      const features = (await getFeatures()).data;

      const filterFeatures = features.filter((feature) => feature.show === 1);

      // 按照weight排序, 若weight相同則按照created_at排序
      const sortedPosts = [...posts, ...filterFeatures].sort((a, b) => {
        if (a.weight === b.weight) {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        }
        return b.weight - a.weight;
      });

      setPosts(sortedPosts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initData();
  }, [lang]);

  return (
    <BackgroundWrapper isTablet={isTablet} isMobile={isMobile}>
      <ContainerWrapper isTablet={isTablet} isMobile={isMobile}>
        <Info lang={lang} isTablet={isTablet} isMobile={isMobile}>
          <div>{t('pages.index.Banner.title')}</div>
          <div>{t('pages.index.Banner.description')}</div>
          <div>
            <Link to="/auth/signin">{t('buttons.signIn')}</Link>
            <Link to="/auth/signup">
              <SigninButton>{t('buttons.register')}</SigninButton>
            </Link>
          </div>
        </Info>
        <SwiperPaginationWrapper isTablet={isTablet} isMobile={isMobile}>
          <div className="swiper-pagination" style={{ position: 'unset' }}></div>
        </SwiperPaginationWrapper>
        <ImageWrapper isTablet={isTablet} isMobile={isMobile}>
          <SwiperBtn className="prevBtn" isTablet={isTablet} isMobile={isMobile}>
            <img src="/images/index/arrow_back.svg" alt="" />
          </SwiperBtn>
          <div className="swiper-container">
            <Swiper
              centeredSlides={true}
              grabCursor={true}
              modules={[Navigation, Pagination]}
              initialSlide={0}
              spaceBetween={0}
              slidesPerView={1}
              navigation={{
                prevEl: '.prevBtn',
                nextEl: '.nextBtn',
              }}
              pagination={{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
                bulletActiveClass: 'swiper-pagination-bullet-active',
              }}
              onSwiper={(swiper) => {}}
              onSlideChange={() => {}}
            >
              {posts.map((post, index) => (
                <SwiperSlide key={index}>
                  <SwiperCard isTablet={isTablet} isMobile={isMobile}>
                    <div className="pic">
                      <img src={post.img_url} alt="" />
                    </div>
                    <div className="content">
                      <H2>{lang === 'en' ? post.title_en : lang === 'tc' ? post.title : sify(post.title)}</H2>
                      <p>
                        {lang === 'en'
                          ? post.description_en
                          : lang === 'tc'
                          ? post.description
                          : sify(post.description)}
                      </p>
                      {(post as BannerFeature).info ? (
                        <Link to={PATH.features.detail.replace(':id', post.id.toString())}>
                          {t('buttons.howtoUse')}
                        </Link>
                      ) : (
                        <Box paddingTop={'23px'} />
                      )}
                    </div>
                  </SwiperCard>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <SwiperBtn className="nextBtn" isTablet={isTablet} isMobile={isMobile}>
            <img src="/images/index/arrow_forward.svg" alt="" />
          </SwiperBtn>
        </ImageWrapper>
      </ContainerWrapper>
    </BackgroundWrapper>
  );
};

export default Banner;

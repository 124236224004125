export const dealTagStatusHandler = (progress: number) => {
  // 透過switch來決定顯示的標籤
  //   0 = inviting, 1 = processing, 2 = completed, 3 = cancelled
  switch (progress) {
    case 0:
      return 'inviting';
    case 1:
      return 'processing';
    case 2:
      return 'completed';
    case 3:
      return 'cancelled';
    case 99:
      return 'processing';
    default:
      return 'inviting';
  }
};

interface DealStatusCounter {
  id: string;
  tag: 'inviting' | 'processing' | 'completed' | 'cancelled';
  tradeName: string;
  count: {
    document?: number;
    participants: number;
    events: number;
  };
  expiry_date?: string | undefined;
  contractExecution: boolean[];
}

export const dealStatusCounter = (deal: DealStatusCounter[]) => {
  // 判斷所有陣列的狀態, 並返回陣列
  const status = deal.map((item) => item.tag);
  // 透過reduce來計算各個狀態的數量
  const counter = status.reduce(
    (acc, cur) => {
      acc[cur] = acc[cur] + 1;
      return acc;
    },
    { all: deal.length, inviting: 0, processing: 0, completed: 0, cancelled: 0 }
  );
  return counter;
};

const filenameValidator = (file: File) => {
  // 取得檔案名稱
  const filename = file.name;
  return filename;

  // 建立正則表達式, 驗證是否檔名只包含英文字母、數字、減號（-）和.）
  // const pattern = /^[a-zA-Z0-9\-\.]+$/;
  // let modifiedFileName = '';

  // if (!pattern.test(filename)) {
  //   // 將副檔名部分分割開來, 只取檔名部分進行重組
  //   // const filenameParts = filename.split('.');
  //   // const newFileName = filenameParts[0]?.replace(/[^a-zA-Z0-9\-]/g, '-'); // 使用replace()方法將非法字符替換為減號（-）
  //   // modifiedFileName = `${newFileName}.${filenameParts[filenameParts.length - 1]}`;
  //   // console.log(modifiedFileName);
  //   return filename;
  // } else {
  //   return filename;
  // }
};

export default filenameValidator;

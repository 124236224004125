const countryTc = [
  { name: '中國', countryCode: '+86', timezone: 'GMT+8', currency: '人民幣', language: '普通話' },
  { name: '香港', countryCode: '+852', timezone: 'GMT+8', currency: '港元', language: '粵語' },
  { name: '澳門', countryCode: '+853', timezone: 'GMT+8', currency: '澳門巴塔卡', language: '普通話' },
  { name: '阿富汗', countryCode: '+93', timezone: 'GMT+4:30', currency: '阿富汗尼', language: '普什圖語' },
  { name: '奧蘭羣島', countryCode: '+358', timezone: 'GMT+3', currency: '歐元', language: '瑞典文' },
  { name: '阿爾巴尼亞', countryCode: '+355', timezone: 'GMT+2', currency: '列克', language: '阿爾巴尼亞語' },
  { name: '美屬薩摩亞', countryCode: '+1684', timezone: 'GMT-11', currency: '美元', language: '英文' },
  { name: '安道爾', countryCode: '+376', timezone: 'GMT+2', currency: '歐元', language: '加泰羅尼亞' },
  { name: '安哥拉', countryCode: '+244', timezone: 'GMT+1', currency: '安哥拉匡撒', language: '葡萄牙文' },
  { name: '安圭拉', countryCode: '+1264', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '安提瓜和巴布達', countryCode: '+1268', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '阿根廷', countryCode: '+54', timezone: 'GMT-3', currency: '阿根廷披索', language: '西班牙文' },
  { name: '亞美尼亞', countryCode: '+374', timezone: 'GMT+4', currency: '德拉姆', language: '亞美尼亞文' },
  { name: '阿魯巴', countryCode: '+297', timezone: 'GMT-4', currency: '阿魯巴·弗洛林', language: '荷蘭語' },
  { name: '澳大利亞', countryCode: '+61', timezone: 'GMT+8', currency: '澳元', language: '英文' },
  { name: '奧地利', countryCode: '+43', timezone: 'GMT+2', currency: '歐元', language: '德語' },
  { name: '阿塞拜疆', countryCode: '+994', timezone: 'GMT+4', currency: '阿塞拜疆馬納特', language: '阿塞拜疆' },
  { name: '巴哈馬', countryCode: '+1242', timezone: 'GMT-4', currency: '巴哈馬元', language: '英文' },
  { name: '巴林', countryCode: '+973', timezone: 'GMT+3', currency: '巴林第納爾', language: '阿拉伯文' },
  { name: '孟加拉國', countryCode: '+880', timezone: 'GMT+6', currency: '孟加拉塔卡', language: '孟加拉語' },
  { name: '巴巴多斯', countryCode: '+1246', timezone: 'GMT-4', currency: '巴巴多斯元', language: '英文' },
  { name: '白俄羅斯', countryCode: '+375', timezone: 'GMT+3', currency: '白俄羅斯盧布', language: '俄文' },
  { name: '比利時', countryCode: '+32', timezone: 'GMT+2', currency: '歐元', language: '荷蘭語' },
  { name: '伯利茲', countryCode: '+501', timezone: 'GMT-6', currency: '伯利茲元', language: '英文' },
  { name: '貝寧', countryCode: '+229', timezone: 'GMT+1', currency: '西非法郎', language: '法文' },
  { name: '百慕大', countryCode: '+1441', timezone: 'GMT-3', currency: '百慕大元', language: '英文' },
  { name: '不丹', countryCode: '+975', timezone: 'GMT+6', currency: '努爾特魯姆', language: '宗喀語' },
  {
    name: '玻利維亞，普魯林國家',
    countryCode: '+591',
    timezone: 'GMT-4',
    currency: '玻利維亞玻利維亞',
    language: '西班牙文',
  },
  {
    name: '波斯尼亞－黑塞哥維那',
    countryCode: '+387',
    timezone: 'GMT+2',
    currency: '波斯尼亞－黑塞哥維那可兌換馬克',
    language: '波斯尼亞語',
  },
  { name: '博茨瓦納', countryCode: '+267', timezone: 'GMT+2', currency: '波札那普拉', language: '英文' },
  { name: '巴西', countryCode: '+55', timezone: 'GMT-3', currency: '巴西雷亞爾', language: '葡萄牙文' },
  { name: '英屬印度洋領土', countryCode: '+246', timezone: 'GMT+6', currency: '美元', language: '英文' },
  { name: '文萊達魯薩蘭國', countryCode: '+673', timezone: 'GMT+8', currency: '文萊元', language: '馬來語' },
  { name: '保加利亞', countryCode: '+359', timezone: 'GMT+3', currency: '保加利亞列夫', language: '保加利亞語' },
  { name: '布基納法索', countryCode: '+226', timezone: 'GMT+0', currency: '西非非洲金融共同體法郎', language: '法文' },
  { name: '布隆迪', countryCode: '+257', timezone: 'GMT+2', currency: '布隆迪法郎', language: '法文' },
  { name: '柬埔寨', countryCode: '+855', timezone: 'GMT+7', currency: '柬埔寨里爾', language: '高棉語' },
  { name: '喀麥隆', countryCode: '+237', timezone: 'GMT+1', currency: '中非金融共同體法郎', language: '英文' },
  { name: '加拿大', countryCode: '+1', timezone: 'GMT-8', currency: '加拿大元', language: '英文' },
  { name: '佛得角', countryCode: '+238', timezone: 'GMT-1', currency: '埃斯庫多角', language: '葡萄牙文' },
  { name: '開曼羣島', countryCode: '+345', timezone: 'GMT-5', currency: '開曼羣島元', language: '英文' },
  { name: '中非共和國', countryCode: '+236', timezone: 'GMT+1', currency: '中非金融共同體法郎', language: '法文' },
  { name: '乍得', countryCode: '+235', timezone: 'GMT+1', currency: '中非金融共同體法郎', language: '法文' },
  { name: '智利', countryCode: '+56', timezone: 'GMT-4', currency: '智利披索', language: '西班牙文' },
  { name: '聖誕島', countryCode: '+61', timezone: 'GMT+7', currency: '澳元', language: '英文' },
  { name: '科科斯（基林）群島', countryCode: '+61', timezone: 'GMT+6:30', currency: '澳元', language: '英文' },
  { name: '哥倫比亞', countryCode: '+57', timezone: 'GMT-5', currency: '哥倫比亞披索', language: '西班牙文' },
  { name: '科摩羅', countryCode: '+269', timezone: 'GMT+3', currency: '科摩羅法郎', language: '科摩羅' },
  { name: '剛果', countryCode: '+242', timezone: 'GMT+1', currency: '中非金融共同體法郎', language: '法文' },
  { name: '剛果民主共和國', countryCode: '+243', timezone: 'GMT+1', currency: '剛果法郎', language: '法文' },
  { name: '庫克群島', countryCode: '+682', timezone: 'GMT-10', currency: '新西蘭元', language: '英文' },
  { name: '哥斯達黎加', countryCode: '+506', timezone: 'GMT-6', currency: '哥斯大黎加科朗', language: '西班牙文' },
  { name: '科特迪瓦', countryCode: '+225', timezone: 'GMT+0', currency: '西非非洲金融共同體法郎', language: '法文' },
  { name: '克羅地亞', countryCode: '+385', timezone: 'GMT+1', currency: '克羅埃西亞庫納', language: '克羅地亞語' },
  { name: '古巴', countryCode: '+53', timezone: 'GMT-5', currency: '古巴披索', language: '西班牙文' },
  { name: '塞浦路斯', countryCode: '+357', timezone: 'GMT+2', currency: '歐元', language: '土耳其語' },
  { name: '捷克共和國', countryCode: '+420', timezone: 'GMT+1', currency: '捷克克羅納', language: '捷克文' },
  { name: '丹麥', countryCode: '+45', timezone: 'GMT+1', currency: '丹麥克朗', language: '丹麥文' },
  { name: '吉布提', countryCode: '+253', timezone: 'GMT+3', currency: '吉布提法郎', language: '法文' },
  { name: '多米尼克', countryCode: '+1767', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '多米尼加共和國', countryCode: '+1849', timezone: 'GMT-4', currency: '多明尼加披索', language: '西班牙文' },
  { name: '厄瓜多爾', countryCode: '+593', timezone: 'GMT-5', currency: '美元', language: '西班牙文' },
  { name: '埃及', countryCode: '+20', timezone: 'GMT+2', currency: '埃及鎊', language: '阿拉伯文' },
  { name: '薩爾瓦多', countryCode: '+503', timezone: 'GMT-6', currency: '美元', language: '西班牙文' },
  { name: '赤道幾內亞', countryCode: '+240', timezone: 'GMT+1', currency: '中非金融共同體法郎', language: '西班牙文' },
  { name: '厄立特里亞', countryCode: '+291', timezone: 'GMT+3', currency: '厄立特里亞納克法', language: '英文' },
  { name: '愛沙尼亞', countryCode: '+372', timezone: 'GMT+2', currency: '歐元', language: '愛沙尼亞語' },
  { name: '埃塞俄比亞', countryCode: '+251', timezone: 'GMT+3', currency: '埃塞俄比亞比爾', language: '阿姆哈拉文' },
  {
    name: '福克蘭羣島（馬爾維納斯）',
    countryCode: '+500',
    timezone: 'GMT-3',
    currency: '福克蘭羣島鎊',
    language: '英文',
  },
  { name: '法羅群島', countryCode: '+298', timezone: 'GMT+0', currency: '丹麥克朗', language: '法羅塞語' },
  { name: '斐濟', countryCode: '+679', timezone: 'GMT+12', currency: '斐濟元', language: '英文' },
  { name: '芬蘭', countryCode: '+358', timezone: 'GMT+2', currency: '歐元', language: '芬蘭語' },
  { name: '法國', countryCode: '+33', timezone: 'GMT+1', currency: '歐元', language: '法文' },
  { name: '法屬圭亞那', countryCode: '+594', timezone: 'GMT-3', currency: '歐元', language: '法文' },
  { name: '法屬玻里尼西亞', countryCode: '+689', timezone: 'GMT-10', currency: '太平洋法郎', language: '法文' },
  { name: '加蓬', countryCode: '+241', timezone: 'GMT+1', currency: '中非金融共同體法郎', language: '法文' },
  { name: '岡比亞', countryCode: '+220', timezone: 'GMT+0', currency: '岡比亞達拉西', language: '英文' },
  { name: '格魯吉亞', countryCode: '+995', timezone: 'GMT+4', currency: '格魯吉亞拉里', language: '格魯吉亞語' },
  { name: '德國', countryCode: '+49', timezone: 'GMT+1', currency: '歐元', language: '德語' },
  { name: '加納', countryCode: '+233', timezone: 'GMT+0', currency: '加納塞地', language: '英文' },
  { name: '直布羅陀', countryCode: '+350', timezone: 'GMT+1', currency: '直布羅陀鎊', language: '英文' },
  { name: '希臘', countryCode: '+30', timezone: 'GMT+2', currency: '歐元', language: '希臘語' },
  { name: '格陵蘭', countryCode: '+299', timezone: 'GMT-3', currency: '丹麥克朗', language: '丹麥文' },
  { name: '格林納達', countryCode: '+1473', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '瓜德羅普島', countryCode: '+590', timezone: 'GMT-4', currency: '歐元', language: '法文' },
  { name: '關島', countryCode: '+1671', timezone: 'GMT+10', currency: '美元', language: '英文' },
  { name: '危地馬拉', countryCode: '+502', timezone: 'GMT-6', currency: '危地馬拉格查爾', language: '西班牙文' },
  { name: '根西島', countryCode: '+44', timezone: 'GMT+0', currency: '英鎊', language: '英文' },
  { name: '幾內亞', countryCode: '+224', timezone: 'GMT+0', currency: '幾內亞法郎', language: '法文' },
  {
    name: '幾內亞比紹',
    countryCode: '+245',
    timezone: 'GMT+0',
    currency: '西非非洲金融共同體法郎',
    language: '葡萄牙文',
  },
  { name: '圭亞那', countryCode: '+595', timezone: 'GMT-4', currency: '圭亞那元', language: '英文' },
  { name: '海地', countryCode: '+509', timezone: 'GMT-5', currency: '海地古爾德', language: '法文' },
  { name: '教廷（梵蒂岡城國）', countryCode: '+379', timezone: 'GMT+1', currency: '歐元', language: '拉丁' },
  { name: '洪都拉斯', countryCode: '+504', timezone: 'GMT-6', currency: '洪都拉斯蘭皮拉', language: '西班牙文' },
  { name: '匈牙利', countryCode: '+36', timezone: 'GMT+1', currency: '匈牙利福林', language: '匈牙利文' },
  { name: '冰島', countryCode: '+354', timezone: 'GMT+0', currency: '冰島克羅納', language: '冰島文' },
  { name: '印度', countryCode: '+91', timezone: 'GMT+5:30', currency: '印度盧比', language: '印度語' },
  { name: '印度尼西亞', countryCode: '+62', timezone: 'GMT+9', currency: '印尼盧比', language: '印尼文' },
  {
    name: '伊朗、波斯灣伊斯蘭共和國',
    countryCode: '+98',
    timezone: 'GMT+3:30',
    currency: '伊朗里亞爾',
    language: '波斯語',
  },
  { name: '伊拉克', countryCode: '+964', timezone: 'GMT+3', currency: '伊拉克第納爾', language: '阿拉伯文' },
  { name: '愛爾蘭', countryCode: '+353', timezone: 'GMT+0', currency: '歐元', language: '英文' },
  { name: '馬恩島', countryCode: '+44', timezone: 'GMT+0', currency: '英鎊', language: '英文' },
  { name: '以色列', countryCode: '+972', timezone: 'GMT+2', currency: '以色列新謝克爾', language: '希伯來文' },
  { name: '意大利', countryCode: '+39', timezone: 'GMT+1', currency: '歐元', language: '義大利文' },
  { name: '牙買加', countryCode: '+1876', timezone: 'GMT-5', currency: '牙買加元', language: '英文' },
  { name: '日本', countryCode: '+81', timezone: 'GMT+9', currency: '日圓', language: '日文' },
  { name: '澤西島', countryCode: '+44', timezone: 'GMT+0', currency: '英鎊', language: '英文' },
  { name: '約旦', countryCode: '+962', timezone: 'GMT+2', currency: '約旦第納爾', language: '阿拉伯文' },
  { name: '哈薩克斯坦', countryCode: '+77', timezone: 'GMT+6', currency: '哈薩克堅戈', language: '俄文' },
  { name: '肯尼亞', countryCode: '+254', timezone: 'GMT+3', currency: '肯亞先令', language: '斯瓦希里語' },
  { name: '基里巴斯', countryCode: '+686', timezone: 'GMT+12', currency: '澳元', language: '英文' },
  { name: '朝鮮民主主義人民共和國', countryCode: '+850', timezone: 'GMT+9', currency: '朝鮮圓', language: '韓文' },
  { name: '大韓民國', countryCode: '+82', timezone: 'GMT+9', currency: '韓圓', language: '韓文' },
  { name: '科威特', countryCode: '+965', timezone: 'GMT+3', currency: '科威特第納爾', language: '阿拉伯文' },
  { name: '吉爾吉斯斯坦', countryCode: '+996', timezone: 'GMT+6', currency: '吉爾吉斯索姆', language: '吉爾吉斯語' },
  { name: '寮國', countryCode: '+856', timezone: 'GMT+7', currency: '老撾基普', language: '老撾' },
  { name: '拉脫維亞', countryCode: '+371', timezone: 'GMT+2', currency: '歐元', language: '拉脫維亞文' },
  { name: '黎巴嫩', countryCode: '+961', timezone: 'GMT+2', currency: '黎巴嫩鎊', language: '阿拉伯文' },
  { name: '萊索托', countryCode: '+266', timezone: 'GMT+2', currency: '萊索托洛蒂', language: '賴索托語' },
  { name: '利比里亞', countryCode: '+231', timezone: 'GMT+0', currency: '利比里亞元', language: '英文' },
  {
    name: '阿拉伯利比亞民衆國',
    countryCode: '+218',
    timezone: 'GMT+2',
    currency: '利比亞第納爾',
    language: '阿拉伯文',
  },
  { name: '列支敦士登', countryCode: '+423', timezone: 'GMT+1', currency: '瑞士法郎', language: '德語' },
  { name: '立陶宛', countryCode: '+370', timezone: 'GMT+2', currency: '歐元', language: '立陶宛語' },
  { name: '盧森堡', countryCode: '+352', timezone: 'GMT+1', currency: '歐元', language: '盧森堡文' },
  { name: '馬其頓', countryCode: '+389', timezone: 'GMT+1', currency: '馬其頓代納爾', language: '馬其頓語' },
  {
    name: '馬達加斯加',
    countryCode: '+261',
    timezone: 'GMT+3',
    currency: '馬達加斯加阿里亞里',
    language: '馬達加斯加',
  },
  { name: '馬拉維', countryCode: '+265', timezone: 'GMT+2', currency: '馬拉威安誇查', language: '奇切瓦' },
  { name: '馬來西亞', countryCode: '+60', timezone: 'GMT+8', currency: '馬來西亞林吉特', language: '馬來語' },
  { name: '馬爾代夫', countryCode: '+960', timezone: 'GMT+5', currency: '馬爾代夫拉菲亞', language: '迪維希語' },
  { name: '馬裏', countryCode: '+223', timezone: 'GMT+0', currency: '西非非洲金融共同體法郎', language: '法文' },
  { name: '馬耳他', countryCode: '+356', timezone: 'GMT+1', currency: '歐元', language: '馬爾他語' },
  { name: '馬紹爾羣島', countryCode: '+692', timezone: 'GMT+12', currency: '美元', language: '馬紹爾語' },
  { name: '馬丁尼克', countryCode: '+596', timezone: 'GMT-4', currency: '歐元', language: '法文' },
  { name: '毛里塔尼亞', countryCode: '+222', timezone: 'GMT+0', currency: '毛里塔尼亞·烏吉亞', language: '阿拉伯文' },
  { name: '毛里求斯', countryCode: '+230', timezone: 'GMT+4', currency: '毛里求斯盧比', language: '英文' },
  { name: '馬約特島', countryCode: '+262', timezone: 'GMT+3', currency: '歐元', language: '法文' },
  { name: '墨西哥', countryCode: '+52', timezone: 'GMT-7', currency: '墨西哥披索', language: '西班牙文' },
  { name: '密克羅尼西亞聯邦', countryCode: '+691', timezone: 'GMT+11', currency: '美元', language: '英文' },
  { name: '摩爾多瓦', countryCode: '+373', timezone: 'GMT+2', currency: '摩爾多瓦列伊', language: '摩爾多瓦' },
  { name: '摩納哥', countryCode: '+377', timezone: 'GMT+1', currency: '歐元', language: '法文' },
  { name: '蒙古', countryCode: '+976', timezone: 'GMT+8', currency: '蒙古圖格里克', language: '蒙古文' },
  { name: '蒙特內哥羅', countryCode: '+382', timezone: 'GMT+1', currency: '歐元', language: '黑山' },
  { name: '蒙特塞拉特', countryCode: '+1664', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '摩洛哥', countryCode: '+212', timezone: 'GMT+0', currency: '摩洛哥迪拉姆', language: '阿拉伯文' },
  { name: '莫桑比克', countryCode: '+258', timezone: 'GMT+2', currency: '莫桑比克梅蒂克', language: '葡萄牙文' },
  { name: '緬甸', countryCode: '+95', timezone: 'GMT+6:30', currency: '緬元', language: '緬甸語' },
  { name: '納米比亞', countryCode: '+264', timezone: 'GMT+1', currency: '納米比亞元', language: '英文' },
  { name: '瑙魯', countryCode: '+674', timezone: 'GMT+12', currency: '澳元', language: '瑙魯' },
  { name: '尼泊爾', countryCode: '+977', timezone: 'GMT+5:45', currency: '尼泊爾盧比', language: '尼泊爾語' },
  { name: '荷蘭', countryCode: '+31', timezone: 'GMT+1', currency: '歐元', language: '荷蘭語' },
  { name: '荷屬安的列斯羣島', countryCode: '+599', timezone: 'GMT-4', currency: '荷屬安地列斯盾', language: '荷蘭語' },
  { name: '新喀里多尼亞', countryCode: '+687', timezone: 'GMT+11', currency: '太平洋法郎', language: '法文' },
  { name: '新西蘭', countryCode: '+64', timezone: 'GMT+12', currency: '新西蘭元', language: '英文' },
  { name: '尼加拉瓜', countryCode: '+505', timezone: 'GMT-6', currency: '尼加拉瓜科爾多瓦', language: '西班牙文' },
  { name: '尼日爾', countryCode: '+227', timezone: 'GMT+1', currency: '西非非洲金融共同體法郎', language: '法文' },
  { name: '尼日利亞', countryCode: '+234', timezone: 'GMT+1', currency: '尼日利亞奈拉', language: '英文' },
  { name: '紐埃', countryCode: '+683', timezone: 'GMT-11', currency: '新西蘭元', language: '紐埃' },
  { name: '諾福克島', countryCode: '+672', timezone: 'GMT+11', currency: '澳元', language: '英文' },
  { name: '北馬里亞納羣島', countryCode: '+1670', timezone: 'GMT+10', currency: '美元', language: '英文' },
  { name: '挪威', countryCode: '+47', timezone: 'GMT+1', currency: '挪威克朗', language: '挪威文' },
  { name: '阿曼', countryCode: '+968', timezone: 'GMT+4', currency: '阿曼里亞爾', language: '阿拉伯文' },
  { name: '巴基斯坦', countryCode: '+92', timezone: 'GMT+5', currency: '巴基斯坦盧比', language: '烏爾都語' },
  { name: '帛琉', countryCode: '+680', timezone: 'GMT+9', currency: '美元', language: '帕勞' },
  { name: '被佔領巴勒斯坦領土', countryCode: '+970', timezone: 'GMT+2', currency: '歐元', language: '阿拉伯文' },
  { name: '巴拿馬', countryCode: '+507', timezone: 'GMT-5', currency: '美元', language: '西班牙文' },
  { name: '巴布亞新幾內亞', countryCode: '+675', timezone: 'GMT+10', currency: '巴布亞紐幾內亞基納', language: '英文' },
  { name: '巴拉圭', countryCode: '+595', timezone: 'GMT-4', currency: '巴拉圭瓜拉尼', language: '西班牙文' },
  { name: '祕魯', countryCode: '+51', timezone: 'GMT-5', currency: '祕魯太陽報', language: '西班牙文' },
  { name: '菲律賓', countryCode: '+63', timezone: 'GMT+8', currency: '菲律賓披索', language: '菲律賓語' },
  { name: '皮特凱恩', countryCode: '+872', timezone: 'GMT-8', currency: '新西蘭元', language: '英文' },
  { name: '波蘭', countryCode: '+48', timezone: 'GMT+1', currency: '波蘭茲羅提', language: '波蘭文' },
  { name: '葡萄牙', countryCode: '+351', timezone: 'GMT+0', currency: '歐元', language: '葡萄牙文' },
  { name: '波多黎各', countryCode: '+1939', timezone: 'GMT-4', currency: '美元', language: '西班牙文' },
  { name: '卡塔爾', countryCode: '+974', timezone: 'GMT+3', currency: '卡塔里里里亞爾', language: '阿拉伯文' },
  { name: '羅馬尼亞', countryCode: '+40', timezone: 'GMT+2', currency: '羅馬尼亞列伊', language: '羅馬尼亞文' },
  { name: '俄羅斯', countryCode: '+7', timezone: 'GMT+3', currency: '俄羅斯盧布', language: '俄文' },
  { name: '盧旺達', countryCode: '+250', timezone: 'GMT+2', currency: '盧旺達法郎', language: '基尼亞盧旺達語' },
  { name: '留尼旺', countryCode: '+262', timezone: 'GMT+4', currency: '歐元', language: '法文' },
  { name: '聖巴塞勒米', countryCode: '+590', timezone: 'GMT-4', currency: '歐元', language: '法文' },
  {
    name: '聖赫勒拿、阿森松島和特里斯坦達庫尼亞島',
    countryCode: '+290',
    timezone: 'GMT+0',
    currency: '聖赫勒拿鎊',
    language: '英文',
  },
  { name: '聖基茨和尼維斯', countryCode: '+1869', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '聖盧西亞', countryCode: '+1758', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '聖馬丁', countryCode: '+590', timezone: 'GMT-4', currency: '歐元', language: '英文' },
  { name: '聖皮埃爾和密克隆', countryCode: '+508', timezone: 'GMT-3', currency: '歐元', language: '法文' },
  { name: '聖文森特和格林納丁斯', countryCode: '+1784', timezone: 'GMT-4', currency: '東加勒比元', language: '英文' },
  { name: '薩摩亞', countryCode: '+685', timezone: 'GMT+13', currency: '薩摩亞·塔拉', language: '英文' },
  { name: '聖馬利諾', countryCode: '+378', timezone: 'GMT+1', currency: '歐元', language: '義大利文' },
  {
    name: '聖多美和普林西比',
    countryCode: '+239',
    timezone: 'GMT+0',
    currency: '聖多美普林西比多布拉',
    language: '葡萄牙文',
  },
  { name: '沙特阿拉伯', countryCode: '+966', timezone: 'GMT+3', currency: '沙烏地里亞爾', language: '阿拉伯文' },
  { name: '塞內加爾', countryCode: '+221', timezone: 'GMT+0', currency: '太平洋法郎', language: '法文' },
  { name: '塞爾維亞', countryCode: '+381', timezone: 'GMT+1', currency: '塞爾維亞第納爾', language: '塞爾維亞語' },
  { name: '塞舌爾', countryCode: '+248', timezone: 'GMT+4', currency: '塞舌爾盧比', language: '塞舌爾克里奧爾語' },
  { name: '塞拉利昂', countryCode: '+232', timezone: 'GMT+0', currency: '塞拉利昂', language: '英文' },
  { name: '新加坡', countryCode: '+65', timezone: 'GMT+8', currency: '新加坡元', language: '英文' },
  { name: '斯洛伐克', countryCode: '+421', timezone: 'GMT+1', currency: '歐元', language: '斯洛伐克語' },
  { name: '斯洛文尼亞', countryCode: '+386', timezone: 'GMT+1', currency: '歐元', language: '斯洛文尼亞語' },
  { name: '所羅門羣島', countryCode: '+677', timezone: 'GMT+11', currency: '所羅門羣島元', language: '英文' },
  { name: '索馬里', countryCode: '+252', timezone: 'GMT+3', currency: '索馬里先令', language: '阿拉伯文' },
  { name: '南非', countryCode: '+27', timezone: 'GMT+2', currency: '南非蘭特', language: '南非荷蘭語' },
  { name: '南蘇丹', countryCode: '+211', timezone: 'GMT+3', currency: '南蘇丹鎊', language: '英文' },
  { name: '南喬治亞島和南桑威奇島', countryCode: '+500', timezone: 'GMT-2', currency: '英鎊', language: '英文' },
  { name: '西班牙', countryCode: '+34', timezone: 'GMT+1', currency: '歐元', language: '西班牙文' },
  { name: '斯里蘭卡', countryCode: '+94', timezone: 'GMT+5:30', currency: '斯里蘭卡盧比', language: '僧伽羅語' },
  { name: '蘇丹', countryCode: '+249', timezone: 'GMT+2', currency: '蘇丹鎊', language: '阿拉伯文' },
  { name: '蘇里南', countryCode: '+597', timezone: 'GMT-3', currency: '蘇里南元', language: '荷蘭語' },
  { name: '斯瓦爾巴德和揚馬延', countryCode: '+47', timezone: 'GMT+1', currency: '挪威克朗', language: '挪威文' },
  { name: '史瓦濟蘭', countryCode: '+268', timezone: 'GMT+2', currency: '史瓦帝尼里蘭吉尼', language: '斯威士蘭' },
  { name: '瑞典', countryCode: '+46', timezone: 'GMT+1', currency: '瑞典克朗', language: '瑞典文' },
  { name: '瑞士', countryCode: '+41', timezone: 'GMT+1', currency: '瑞士法郎', language: '羅曼斯' },
  { name: '阿拉伯敘利亞共和國', countryCode: '+963', timezone: 'GMT+2', currency: '敘利亞鎊', language: '阿拉伯文' },
  { name: '台灣', countryCode: '+886', timezone: 'GMT+8', currency: '新臺幣', language: '普通話' },
  { name: '塔吉克斯坦', countryCode: '+992', timezone: 'GMT+5', currency: '塔吉克索莫尼', language: '俄文' },
  {
    name: '坦桑尼亞聯合共和國',
    countryCode: '+255',
    timezone: 'GMT+3',
    currency: '坦桑尼亞先令',
    language: '斯瓦希里語',
  },
  { name: '泰國', countryCode: '+66', timezone: 'GMT+7', currency: '泰銖', language: '泰文' },
  { name: '東帝汶', countryCode: '+670', timezone: 'GMT+9', currency: '美元', language: '提頓語' },
  { name: '多哥', countryCode: '+228', timezone: 'GMT+0', currency: '西非非洲金融共同體法郎', language: '法文' },
  { name: '托克勞', countryCode: '+690', timezone: 'GMT+13', currency: '新西蘭元', language: '托克勞文' },
  { name: '湯加', countryCode: '+676', timezone: 'GMT+13', currency: '潘加', language: '同安' },
  {
    name: '特立尼達和多巴哥',
    countryCode: '+1868',
    timezone: 'GMT-4',
    currency: '特立尼達和多巴哥元',
    language: '英文',
  },
  { name: '突尼斯', countryCode: '+216', timezone: 'GMT+1', currency: '突尼斯第納爾', language: '阿拉伯文' },
  { name: '土耳其', countryCode: '+90', timezone: 'GMT+3', currency: '土耳其里拉', language: '土耳其語' },
  { name: '土庫曼斯坦', countryCode: '+993', timezone: 'GMT+5', currency: '土庫曼斯坦馬納特', language: '土庫曼' },
  { name: '特克斯和凱科斯羣島', countryCode: '+1649', timezone: 'GMT-5', currency: '美元', language: '英文' },
  { name: '圖瓦盧', countryCode: '+688', timezone: 'GMT+12', currency: '圖瓦盧元', language: '圖瓦盧語' },
  { name: '烏干達', countryCode: '+256', timezone: 'GMT+3', currency: '烏干達先令', language: '斯瓦希里語' },
  { name: '烏克蘭', countryCode: '+380', timezone: 'GMT+2', currency: '烏克蘭赫裏夫尼亞', language: '烏克蘭文' },
  { name: '阿拉伯聯合酋長國', countryCode: '+971', timezone: 'GMT+4', currency: '阿聯迪拉姆', language: '阿拉伯文' },
  { name: '英國', countryCode: '+44', timezone: 'GMT+0', currency: '英鎊', language: '英文' },
  { name: '美國', countryCode: '+1', timezone: 'GMT-10', currency: '美元', language: '英文' },
  { name: '烏拉圭', countryCode: '+598', timezone: 'GMT-3', currency: '烏拉圭披索', language: '西班牙文' },
  {
    name: '烏茲別克斯坦',
    countryCode: '+998',
    timezone: 'GMT+5',
    currency: '烏茲別克斯坦索姆',
    language: '烏茲別克語',
  },
  { name: '瓦努阿圖', countryCode: '+678', timezone: 'GMT+11', currency: '瓦努阿圖瓦圖', language: '比斯拉馬語' },
  {
    name: '委內瑞拉，玻利瓦爾委內瑞拉共和國',
    countryCode: '+58',
    timezone: 'GMT-4',
    currency: '委內瑞拉玻利瓦爾',
    language: '西班牙文',
  },
  { name: '越南', countryCode: '+84', timezone: 'GMT+7', currency: '越南盾', language: '越南文' },
  { name: '英屬維京群島', countryCode: '+1284', timezone: 'GMT-4', currency: '美元', language: '英文' },
  { name: '美屬維京群島', countryCode: '+1340', timezone: 'GMT-4', currency: '美元', language: '英文' },
  { name: '瓦利斯和富圖納', countryCode: '+681', timezone: 'GMT+12', currency: '太平洋法郎', language: '法文' },
  { name: '也門', countryCode: '+967', timezone: 'GMT+3', currency: '也門里亞爾', language: '阿拉伯文' },
  { name: '贊比亞', countryCode: '+260', timezone: 'GMT+2', currency: '贊比亞克瓦查', language: '英文' },
  { name: '津巴布韋', countryCode: '+263', timezone: 'GMT+2', currency: '津巴布韋元', language: '英文' },
  { name: '其他', countryCode: '-', timezone: 'GMT+0', currency: '美元', language: '英文' },
];

export default countryTc;
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { getEventList } from '@/api/event.api';

import { EventCard, Flex, SideBarList } from './styles';
import EventModal from './EventModal';
import './style.css';
import Loading from '@/components/UI/Loading';
import useCurrentLang from '@/hooks/useCurrentLang';

interface IEventCalendar {
  id: string;
  title: string;
  end: Date;
  start: Date;
  color: string;
}

const Calendar = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  // const [view, setView] = useState('dayGridMonth');
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState<EventFormFields[]>();
  const [eventId, setEventId] = useState<number>(0);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [locale, setLacale] = useState('en');
  const [calendar, setCalendar] = useState<IEventCalendar[]>([]);

  const colorAry = ['rgba(167,216,49,.8)', 'rgba(164,122,226,.8)', 'rgba(255,109,0,.8)'];

  const [open, setOpen] = useState(false);

  const handleOpen = (index: number) => {
    setEventId(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const viewHandler = () => {
  //   if (view === 'dayGridMonth') {
  //     setView('dayGridWeek');
  //   } else {
  //     setView('dayGridMonth');
  //   }
  // };

  const customButtons = {
    addEvent: {
      text: t('buttons.addNewMeeting'),
      click: function () {
        // 跳轉至NewEvent頁面
        navigate('/event/newEvent');
      },
    },
  };

  const getData = useCallback(async () => {
    const res = (await getEventList()).data;
    setLoading(true);

    try {
      if (res.success) {
        setData(res.data);
        setOriginData(res.data);
        const eventCalender: IEventCalendar[] = [];
        res.data.forEach((item: EventFormFields, index: number) => {
          eventCalender.push({
            id: item.id.toString(),
            title: item.evt_name,
            end: new Date(item.evt_endDate),
            start: new Date(item.evt_startDate),
            color: colorAry[index % 3],
          });
        });

        setCalendar(eventCalender);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [data, calendar]);

  const handleDeleteData = (id: number) => {
    if (!data) return;
    const newData = data.filter((item: EventFormFields) => item.id !== id);
    const newCalendar = calendar.filter((item: IEventCalendar) => item.id.toString() !== id.toString());
    setData(newData);
    setCalendar(newCalendar)
  };

  useEffect(() => {
    if (lang === 'en') {
      setLacale('en-gb');
    } else if (lang === 'tc') {
      setLacale('zh-tw');
    } else if (lang === 'sc') {
      setLacale('zh-cn');
    } else {
      setLacale('en-gb');
    }
  }, [lang]);

  useEffect(() => {
    getData();
    if (searchParams.get('id')) {
      handleOpen(Number(searchParams.get('id')));
    }
  }, []);

  const clickEvent = (info: any) => {
    handleOpen(info.event.id);
  };

  // 當搜尋框輸入值時，從originData篩選出符合條件的標題放入data
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newData = originData.filter((item: EventFormFields) => item.evt_name.includes(value));
    setData(newData);
  };

  return (
    <Flex>
      <EventModal handleClose={handleClose} open={open} id={eventId} handleDeleteData={handleDeleteData} />

      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        locale={locale}
        customButtons={customButtons}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'title addEvent',
          center: '',
          right: 'prev,today,next dayGridMonth,timeGridWeek',
        }}
        events={calendar}
        longPressDelay={1000}
        eventLongPressDelay={1000}
        selectLongPressDelay={1000}
        selectable={true}
        dayMaxEvents={true}
        height="800px"
        eventClick={clickEvent}
      />

      <SideBarList>
        <div className="search">
          <input
            onChange={(e) => {
              searchHandler(e);
            }}
            placeholder={t('buttons.search')}
            type="text"
          />
          <button type="button" className="icon">
            <img src="/images/event/search.svg" alt="" />
          </button>
        </div>
        <div className="list">
          <div className="title">{t('pages.event.list')}</div>
          <div>
            {loading && <Loading />}
            {!loading &&
              data &&
              data.map((event: EventFormFields, index: number) => (
                <EventCard
                  key={event.evt_name + index + event.id}
                  onClick={() => {
                    handleOpen(event.id);
                  }}
                  className="theme-g"
                >
                  <div className="head"></div>
                  <div className="event">
                    <div className="event-name">{event.evt_name}</div>
                    <div className="event-datetime" style={{ textAlign: 'center' }}>
                      <span className="event-date">{event.evt_startDate}</span>
                      <span>-</span>
                      <span className="event-time">{event.evt_endDate}</span>
                    </div>
                  </div>
                </EventCard>
              ))}
          </div>
        </div>
      </SideBarList>
    </Flex>
  );
};

export default Calendar;

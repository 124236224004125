import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, LanguageSelect } from './style';
import { useAppDispatch, useAppSelector } from '@/redux/store.hook';
import useCurrentLang from '@/hooks/useCurrentLang';
import { changeLang } from '@/redux/lang/lang.slice';
import { switchLang } from '@/api/auth.api';
import { getUserNotifications } from '@/api/notifications.api';
import { getNotification } from '@/redux/notification/notification.slice';
import { getAuthSelector, updateLang } from '@/redux/auth/auth.slice';

interface SelectorProps {
  theme: 'light' | 'dark';
}

const LanguageSelector = ({ theme }: SelectorProps) => {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  const lang = useCurrentLang();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(getAuthSelector);
  const langOptions = t('langOptions', { returnObjects: true });

  const changeLanguage = async (lan: string) => {
    dispatch(changeLang(lan));
    i18n.changeLanguage(lan);
    if (auth.userData.email && auth.registDone) {
      await switchLang({ lang: lan });
      const notificationRes = (await getUserNotifications()).data;
      dispatch(getNotification(notificationRes.data));
      dispatch(updateLang(lan));
    }
  };

  useEffect(() => {
    // 抓出預設的語系, 並設定到i18n
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <Language dark={theme === 'dark' ? true : false}>
        <LanguageSelect dark={theme === 'dark' ? true : false}>
          {langOptions.map((option: { value: string; text: string }) => (
            <span key={option.value} onClick={() => changeLanguage(option.value)}>
              {option.text}
            </span>
          ))}
        </LanguageSelect>
    </Language>
  );
};

export default LanguageSelector;

import { useMemo, useCallback, useState, useEffect } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { errorHandler, successHandler } from '@/utils/toastHandler';

import { Card, CardContainer } from '@/layout/Card';
import { Box, Flex, LoadingPage } from '@/layout';
import DataTable, { Tag, TypeColumn, FileMenu } from '@/components/DataTable';
import { Delete, Sign } from '@/components/Icon';
import { ArrowTooltip } from '@/components/UI/Tooltip';
import { Button } from '@/components/UI/Button';
import { DropZone, UploadFileList } from '@/components/UI/FileDropzone';
import Loading from '@/components/UI/Loading';

import { addFileToDeal, getDealFileList, deleteFileFromDeal, InviteToSign, getDealDetail } from '@/api/deal.api';
import { convertLink, convertToData, convertToFileSize } from '@/utils/convert';
import PATH from '@/router/path';

import { theme } from '@/theme/Theme';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { Text } from '@/components/UI/Typography/Text';
import { fileSizeValidator } from '@/utils/fileSizeValidator';
import { extensionValidator } from '@/utils/extensionValidator';
import { getStorageSpace } from '@/api/auth.api';
import { StorageAlmostFullModal, StorageFullModal } from '@/components/DocLibrary/Modal';
import { VerifyFileModal, VerifyResultModal } from '@/components/DealDetail/Modal/BlockChain';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'unset',
    maxWidth: 'unset',
    fontSize: theme.fontSizes.base,
    whiteSpace: 'nowrap',
  },
}));

type DataRow = {
  id: string;
  acc_id: string;
  file_id: string;
  isOfficialDoc: boolean;
  name: string;
  type: string;
  path: string;
  size: string;
  updated: string;
  sign: {
    need: boolean;
    isSign: boolean;
    signStatus: number;
    needSign: number;
    firstSigner: number;
    signed: number;
  };
  action: string;
  prcSigning: { name: string; sign: boolean }[];
  hkSigning: { name: string; sign: boolean }[];
  hash: string;
};

interface FilesProps {
  id?: string;
  sendInvite: (id: string) => void;
  progress: string;
}

const CurrentFilesList = ({ id, sendInvite, progress }: FilesProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  const rawColumn = t('pages.dealDetail.manageFiles.files.table.columns', { returnObjects: true });
  const tips = t('pages.dealDetail.manageFiles.tips', { returnObjects: true });

  const [isDropActive, setIsDropActive] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileList, setFileList] = useState<DataRow[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [participantsId, setParticipantsId] = useState<string>('');
  const [showDocumentStorageAlmostFullModal, setShowDocumentStorageAlmostFullModal] = useState<boolean>(false);
  const [showDocumentStorageFullModal, setShowDocumentStorageFullModal] = useState<boolean>(false);
  const [remain, setRemain] = useState<number>(0);

  const [showVerifyFile, setShowVerifyFile] = useState<boolean>(false);
  const [showVerifyResult, setShowVerifyResult] = useState<boolean>(false);
  const [verifyResult, setVerifyResult] = useState<boolean>(false);
  const [verifyFileId, setVerifyFileId] = useState<string>('0');

  const fileDelete = async (document_id: string) => {
    if (!id) return;
    setIsLoading(true);
    try {
      const res = (await deleteFileFromDeal({ deal_id: id, deal_document_id: document_id })).data;
      const fileListRes = (await getDealFileList(id)).data;
      updateDocLibrary(fileListRes.data);
      successHandler(t('success.delete'));
    } catch (error) {}
    setIsLoading(false);
  };

  const onIvite = async (documentId: string) => {
    if (!id) return;

    setIsLoading(true);
    const res = await sendInvite(documentId);
    try {
      const fileListRes = (await getDealFileList(id)).data;
      updateDocLibrary(fileListRes.data);
      successHandler(t('success.signInvite'));
    } catch (error) {}
    setIsLoading(false);
  };

  const menuItems: DataTableMenuItems[] = useMemo(
    () => [
      {
        name: t('buttons.download'),
        onClick: (path) => {
          if (!path) return;
          const link = document.createElement('a');
          link.setAttribute('download', '');
          link.href = convertLink(path);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
      },
    ],
    [t]
  );

  const subMenuItems = useCallback(
    ({
      danger,
      documentId,
      signStatus,
    }: {
      danger: boolean;
      documentId: string;
      signStatus: number;
    }): DataTableMenuItems[] => [
      {
        name:
          signStatus === 0
            ? t('buttons.inviteToSign')
            : signStatus === 1
            ? t('buttons.inviteToSign')
            : signStatus === 2
            ? t('buttons.startupSign')
            : t('buttons.cancelSign'),
        danger: danger,
        onClick: async () => {
          if (danger || !id) return;
          setIsLoading(true);
          if (signStatus === 0) {
            try {
              const res = (await InviteToSign({ dealId: id, fileId: documentId })).data;
              if (res.success) {
                // 更新此文件的簽署狀態
                const fileListRes = (await getDealFileList(id)).data;
                updateDocLibrary(fileListRes.data);
                successHandler(t('success.invite'));
              }
            } catch (error) {}
          }
          if (signStatus === 2) {
            navigate(PATH.deal.detail.contractSigning?.replace(':id', id as string).replace(':fileId', documentId));
          }

          setIsLoading(false);
        },
      },
      {
        name: t('buttons.delete'),
        onClick: async () => {
          if (!id) return;
          setIsLoading(true);
          try {
            const res = (await deleteFileFromDeal({ deal_id: id, deal_document_id: documentId })).data;
            const fileListRes = (await getDealFileList(id)).data;
            updateDocLibrary(fileListRes.data);
            successHandler(t('success.delete'));
          } catch (error) {}
          setIsLoading(false);
        },
      },
    ],
    [id, navigate, t]
  );

  const copyText = (text: string) => {
    // 檢測是否支援複製到剪貼簿
    if (!navigator.clipboard) {
      // 不支援則使用textarea
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      document.body.removeChild(textArea);
      successHandler(t('success.copied'));
      return;
    }

    // 寫入剪貼簿
    navigator.clipboard.writeText(text);
    successHandler(t('success.copied'));
  };

  const columns: ColumnDef<DataRow>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: rawColumn[0],
        sortable: true,
        cell: ({ row }) => (
          <Flex alignItems={'center'} gridGap={'5px'}>
            {row.original.sign.need && (
              <ArrowTooltip title={row.original.sign.isSign ? '' : t('buttons.pending')} placement={'top'}>
                <Sign.SignSVG fill={row.original.sign.isSign ? theme.colors.secondary500 : theme.colors.danger} />
              </ArrowTooltip>
            )}
            <div>
              {row.original.name}
              {row.original.hash && (
                <Flex gridGap={'5px'}>
                  <CustomTooltip title={row.original.hash} placement="top">
                    <Text
                      onClick={() => copyText(row.original.hash)}
                      style={{ cursor: 'pointer' }}
                      fontSize={theme.fontSizes.sm}
                      color={theme.colors.gray300}
                    >
                      {t('info.blockchainFingerprint')}:{' '}
                      {row.original.hash && row.original.hash.slice(0, 7) + '...' + row.original.hash.slice(-7)}
                    </Text>
                  </CustomTooltip>
                  <Text
                    onClick={() => {
                      setVerifyFileId(row.original.file_id);
                      setShowVerifyFile(true);
                    }}
                    color={theme.colors.secondary500}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    {t('buttons.verifyFile')}
                  </Text>
                </Flex>
              )}
            </div>
            {row.original.isOfficialDoc && <Tag>{t('buttons.officialDoc')}</Tag>}
          </Flex>
        ),
      },
      {
        accessorKey: 'type',
        header: rawColumn[2],
        cell: ({ row }) => <TypeColumn text={row.original.type} />,
      },
      {
        accessorKey: 'size',
        header: rawColumn[3],
      },
      {
        accessorKey: 'updated',
        header: rawColumn[4],
        cell: ({ row }) => <TypeColumn text={row.original.updated} />,
      },
      {
        accessorKey: 'sign',
        header: rawColumn[6],
        cell: ({ row }) =>
          row.original.type === 'pdf' ? (
            row.original.sign.signed !== 1 &&
            row.original.sign.signStatus === 0 &&
            userData.main_account_id.toString() === row.original.acc_id.toString() ? (
              <Button size={'table'} variant={'orange'} onClick={() => onIvite(row.original.id)}>
                {t('buttons.inviteToSign')}
              </Button>
            ) : row.original.sign.signStatus === 1 ? (
              <Button size={'table'} variant={'yellow'} special={'fake'}>
                {t('buttons.inviting')}
              </Button>
            ) : row.original.sign.signStatus === 3 ? (
              <Button size={'table'} variant={'regular'} special={'fake'}>
                {t('buttons.signed')}
              </Button>
            ) : (
              <></>
            )
          ) : (
            <></>
          ),
        sortable: false,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => {
          return (
            <Flex alignItems={'center'} gridGap={10}>
              <Button
                size="table"
                variant={'primary'}
                onClick={() => {
                  if (!row.original.path) return;
                  const link = document.createElement('a');
                  link.setAttribute('download', '');
                  link.href = convertLink(row.original.path);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }}
              >
                {t('buttons.download')}
              </Button>
              {row.original.sign.signed === 0 &&
                row.original.sign.signStatus === 0 &&
                progress !== '2' &&
                progress !== '3' && (
                  <Delete.FormDelete
                    onClick={async () => {
                      fileDelete(row.original.id);
                    }}
                  >
                    <Delete.DeleteSVG />
                  </Delete.FormDelete>
                )}
            </Flex>

            // <FileMenu
            //   path={row.original.path}
            //   fileType={row.original.type}
            //   items={menuItems}
            //   subItems={subMenuItems({
            //     danger: row.original.sign.isSign,
            //     documentId: row.original.id,
            //     signStatus: row.original.sign.signStatus,
            //   })}
            // />
          );
        },
        sortable: false,
      },
    ],
    [menuItems, rawColumn, subMenuItems, t]
  );

  const updateDocLibrary = (resData: DocLibrary[]) => {
    const doc: DataRow[] = resData.map(
      (data): DataRow => ({
        id: data.id,
        acc_id: data.acc_id,
        file_id: data.file_id,
        isOfficialDoc: false,
        name: data.file.name,
        type: data.file.extension,
        path: data.file.path,
        size: convertToFileSize(Number(data.file.size)),
        updated: convertToData(data.file.updated_at),
        sign: {
          need: true,
          isSign: data.signStatus === 3 ? true : false,
          signStatus: data.signStatus,
          needSign: data.needSign,
          firstSigner: data.firstSigner,
          signed: data.signed,
        },
        action: '',
        prcSigning: [{ name: '', sign: false }],
        hkSigning: [{ name: '', sign: false }],
        hash: data.hash,
      })
    );

    setFileList(doc);
  };

  const onDragStateChange = useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);

  const onFilesDrop = useCallback((files: File[]) => {
    setFiles(files);
  }, []);

  const onUploadFile = async () => {
    if (!id) return;
    setIsLoading(true);

    if (!fileSizeValidator(files[0], 1.5)) {
      errorHandler(t('error.fileLimit'));
      setIsLoading(false);
      return;
    }

    try {
      if (!extensionValidator(files)) {
        errorHandler(t('error.extension'));
        return;
      }
      const res = (await addFileToDeal({ deal_id: id, files: files })).data;
      const fileListRes = (await getDealFileList(id)).data;
      updateDocLibrary(fileListRes.data);
      successHandler(t('success.upload'));
      setFiles([] as File[]);
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 413) {
        setShowDocumentStorageFullModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const checkStorage = useCallback(async () => {
    try {
      const res = (await getStorageSpace()).data;
      if (res.success) {
        // 檢查是否快滿了, 條件為 90%
        if (res.data.used / res.data.total > 0.9) {
          setShowDocumentStorageAlmostFullModal(true);
          // 轉換成MB
          setRemain(Math.round((res.data.total - res.data.used) / 1024 / 1024));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const initData = useCallback(async () => {
    if (!id) return;
    getDetail();
    try {
      const fileListRes = (await getDealFileList(id)).data;
      updateDocLibrary(fileListRes.data);
      checkStorage();
    } catch (error) {}
  }, []);

  const getDetail = async () => {
    if (!id) return;
    try {
      const res = (await getDealDetail(id)).data;
      res.data.participants.forEach((item: DealParticipants) => {
        if (item.acc_id !== auth.userData.id) {
          setParticipantsId(item.acc_id);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!fileList) {
      initData();
    }
  }, [initData]);

  // 監視files, 若有變動則上傳
  useEffect(() => {
    if (files.length > 0) {
      onUploadFile();
    }
  }, [files]);

  return (
    <Card>
      <VerifyFileModal
        showModal={showVerifyFile}
        setShowModal={setShowVerifyFile}
        setShowVerifyResult={setShowVerifyResult}
        setVerifyResult={setVerifyResult}
        file_id={verifyFileId}
        deal_id={id ? id : '0'}
      />
      <VerifyResultModal showModal={showVerifyResult} setShowModal={setShowVerifyResult} verifyResult={verifyResult} />
      <StorageAlmostFullModal
        showModal={showDocumentStorageAlmostFullModal}
        setShowModal={setShowDocumentStorageAlmostFullModal}
        remain={convertToFileSize(remain)}
      />
      <StorageFullModal showModal={showDocumentStorageFullModal} setShowModal={setShowDocumentStorageFullModal} />
      <Text fontWeight={900} marginBottom={10}>
        {t('pages.dealDetail.manageFiles.title')}
      </Text>
      <ul style={{ listStyleType: 'disc', marginBlockStart: '1em', marginInlineStart: '2em', marginBottom: '30px' }}>
        {tips.map((tip, index) => (
          <li key={`tip-${index}`}>
            <Text>{tip}</Text>
          </li>
        ))}
      </ul>
      <Box width={'100%'}>
        <LoadingPage isLoading={isLoading} />
        {progress === '1' && (
          <DropZone files={files} onDragStateChange={onDragStateChange} onFilesDrop={onFilesDrop} setFiles={setFiles} />
        )}
        {/* <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} gridGap={'15px'} marginBottom={'20px'}>
        {files.length === 0 ? <p>No files to upload</p> : <p>Files ready to upload: {files.length}</p>}
        <UploadFileList files={files} />
        {files.length > 0 && (
          <Button size="table" variant={'danger'} onClick={()=>onUploadFile()}>
            {t('buttons.upload')}
          </Button>
        )}
      </Flex> */}
        {fileList ? <DataTable columns={columns} data={fileList} canSearch /> : <Loading />}
      </Box>
    </Card>
  );
};

export default CurrentFilesList;

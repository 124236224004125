import styled from 'styled-components';

const InputFileWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: flex-end;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
`;

const InputFileLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-right: 15;
  box-sizing: content-box;
`;

export { InputFileWrapper, InputFileLabel };

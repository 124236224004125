import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useUserDetail from '@/hooks/useUserDetail';
import {
  InputWrapper,
  MultiInputWrapper,
  FormTitle,
  InputFileWrapper,
  InputFileLabel,
} from '@/components/Container/AuthContainer/styles';
import { FormSelect } from '@/components/UI/Select';
import { TextField } from '@mui/material';
import { Wrapper } from '../../styles';
import { ErrorMessage, Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { CloudUpload } from '@/components/Icon';
import { convertLEIOptions, convertLocations, convertToLEICountryValue } from '@/utils/convert';
import { useSearchParams } from 'react-router-dom';
import TabsButton from '../../TabsButton';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';
import { useTranslation } from 'react-i18next';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import useLEIValidation from '@/hooks/requireSchema/useLEIValidation';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Box, Flex } from '@/layout';
import { NumberCount } from '@/components/UI/Input';

const BasicInformation = ({ data, setData, userDetail, setSelectedTab, options }: TabFormRequire) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();

  const [searchParmas, setSearchParams] = useSearchParams();
  const { countryCodeRegister } = useRegistrationValidation().companyInfo();
  const { emailRegister } = useAuthValidation();
  const { basicInfo } = useLEIValidation();
  const [fileContact, setFileContactName] = useState<string>('');
  const [fileCorporation, setFileCorporationName] = useState<string>('');

  const legalNameLanguageOptions = t('pages.eTrust.LEI.steps.step2.legalNameLanguage', { returnObjects: true });
  const countryCodeOptions = [
    {
      value: '+86',
      text: '+86',
    },
    {
      value: '+852',
      text: '+852',
    },
    {
      value: '+853',
      text: '+853',
    },
  ];

  const {
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
    setValue,
    register,
    formState: { errors },
  } = useForm<BasicInformationFormRquire>();

  const onSubmit = handleSubmit(async (formData) => {
    const newData = {
      ...data,
      basicInformation: {
        ...formData,
        corporationIdentificationDocument: watch('corporationIdentificationDocument')?.[0],
        contactIdentificationDocument: watch('contactIdentificationDocument')?.[0],
      },
    };
    setData(newData);
    setSelectedTab(2);
    setSearchParams({ step: '2', tab: '2' });
  });

  useEffect(() => {
    // errors有改變時, 將畫面移到最上方
    if (Object.keys(errors).length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    setValue('entityCreationDate', new Date().toISOString().split('T')[0]);
    if (!userDetail) return;
    setValue('legalName', userDetail.acc_coname);
    setValue('email', userDetail.com_email);
    setValue('legalNameLanguage', 'zh');
    setValue('transliteratedOtherNameLanguage', 'en');

    setValue('contactName', `${userDetail.acc_lastName} ${userDetail.acc_firstName}`);
    setValue('contactPhone', `${userDetail.acc_mobile}`);
    setValue('contactMail', userDetail.email);
    setValue('contactPosition', userDetail.acc_jobPosition);
    setValue('contactFix', `${userDetail.acc_tel}`);
    setValue('country', convertToLEICountryValue(userDetail.acc_country));
    setValue('usePurpCode', options.LEIPurpose[4].code);

    if (!data.basicInformation) return;
    // 若data.basicInformation有值則直接將值填入整個表單
    const { basicInformation } = data;
    setValue('country', basicInformation.country);
    setValue('legalNameLanguage', basicInformation.legalNameLanguage);
    setValue('legalName', basicInformation.legalName);
    setValue('transliteratedOtherNameLanguage', basicInformation.transliteratedOtherNameLanguage);
    setValue('transliteratedOtherName', basicInformation.transliteratedOtherName);
    setValue('otherLegalNameLanguage', basicInformation.otherLegalNameLanguage);
    setValue('otherLegalName', basicInformation.otherLegalName);
    setValue('formerName1Lang', basicInformation.formerName1Lang);
    setValue('formerName1', basicInformation.formerName1);
    setValue('formerName2Lang', basicInformation.formerName2Lang);
    setValue('formerName2', basicInformation.formerName2);
    setValue('formerName3Lang', basicInformation.formerName3Lang);
    setValue('formerName3', basicInformation.formerName3);
    setValue('header', basicInformation.header);
    setValue('entityLegalCategory', basicInformation.entityLegalCategory);
    setValue('email', basicInformation.email);
    setValue('entityCreationDate', basicInformation.entityCreationDate);
    setValue('usePurpose', basicInformation.usePurpose);
    setValue('corporation', basicInformation.corporation);
    setValue('contactName', basicInformation.contactName);
    setValue('contactPhone', basicInformation.contactPhone);
    setValue('contactMail', basicInformation.contactMail);
    setValue('contactDepartment', basicInformation.contactDepartment);
    setValue('contactPosition', basicInformation.contactPosition);
    setValue('contactFix', basicInformation.contactFix);
    setValue('contactPhoneCountryCode', basicInformation.contactPhoneCountryCode);
    setValue('contactFixCountryCode', basicInformation.contactFixCountryCode);
    setValue('contactDocumentCategory', basicInformation.contactDocumentCategory);
    setValue('contactDocumentNumber', basicInformation.contactDocumentNumber);
    if (basicInformation.contactIdentificationDocument)
      if (typeof basicInformation.contactIdentificationDocument === 'string') {
        setFileContactName(basicInformation.contactIdentificationDocument);
      } else {
        setValue('contactIdentificationDocument', [basicInformation.contactIdentificationDocument]);
      }
    if (basicInformation.corporationIdentificationDocument) {
      if (typeof basicInformation.corporationIdentificationDocument === 'string') {
        setFileCorporationName(basicInformation.corporationIdentificationDocument);
      } else {
        setValue('corporationIdentificationDocument', [basicInformation.corporationIdentificationDocument]);
      }
    }
  }, [searchParmas]);

  const watchEntityLegalCategory = watch('entityLegalCategory');

  useEffect(() => {
    // 若初次填寫則不啟用
    if (!dirtyFields.entityLegalCategory) return;

    // 若entityLegalCategory的值為"BRANCH"時, 將header設為必填
    if (watchEntityLegalCategory === 'BRANCH') {
      register('header', basicInfo({}).headerLEI);
    } else {
      register('header', { required: false });
      setValue('header', '');
    }
  }, [watchEntityLegalCategory]);

  // const watchLegalUseCategory = watch('legalUseCategory');

  // useEffect(() => {
  //   // 若初次填寫則不啟用
  //   // 將選擇的legalUseCategory對應語言的text設定到usePurpose
  //   if (!dirtyFields.legalUseCategory) return;
  //   convertLEIOptions(lang, options.LEIPurpose).find((item) => {
  //     if (item.value === watchLegalUseCategory) {
  //       setValue('usePurpose', item.text);
  //     }
  //   });
  // }, [watchLegalUseCategory]);
  return (
    <Wrapper onSubmit={onSubmit}>
      <FormTitle>{t('info.basicInfo')}</FormTitle>
      <InputWrapper style={{ height: 'auto' }}>
        <FormSelect
          control={control}
          data={convertLocations(lang, options.Country)}
          inputLabel={t('LEIForm.basicInfo.country.label') + ' *'}
          selectLabel={t('LEIForm.basicInfo.country.label') + ' *'}
          name={'country'}
          register={countryCodeRegister}
        />
      </InputWrapper>
      <InputWrapper style={{ height: 'auto' }}>
        <Controller
          control={control}
          name={'legalName'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.basicInfo.legalName.label') + ' *'}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={basicInfo({ legalNameLang: watch('legalNameLanguage') }).legalName}
        />
      </InputWrapper>
      
      <InputWrapper style={{ height: 'auto' }}>
        <Controller
          control={control}
          name={'transliteratedOtherName'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.basicInfo.transliteratedName.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
        />
      </InputWrapper>

      <InputWrapper style={{ height: 'auto' }}>
        <FormSelect
          control={control}
          data={convertLEIOptions(lang, options.EntityLegalCategory)}
          inputLabel={t('LEIForm.basicInfo.entityLegalCategory.label') + ' *'}
          selectLabel={t('LEIForm.basicInfo.entityLegalCategory.label') + ' *'}
          name={'entityLegalCategory'}
          register={basicInfo({}).entityLegalCategory}
        />
      </InputWrapper>

      {watchEntityLegalCategory === 'BRANCH' && (
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'header'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.basicInfo.headerQuarterLEI.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
        </InputWrapper>
      )}

      <MultiInputWrapper gridGap={30}>
        <Controller
          control={control}
          name={'email'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.basicInfo.email.label') + ' *'}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={emailRegister}
        />
        <Controller
          control={control}
          name={'entityCreationDate'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              type="date"
              variant="standard"
              label={t('LEIForm.basicInfo.entityCreationDate.label') + ' *'}
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={basicInfo({}).entityCreationDate}
        />
      </MultiInputWrapper>

      <InputWrapper style={{ height: 'auto' }}>
        <Controller
          control={control}
          name={'usePurpose'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.basicInfo.usePurpose.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
        />
      </InputWrapper>

      <InputWrapper style={{ height: 'auto' }}>
        <Controller
          control={control}
          name={'corporation'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.basicInfo.corporation.label') + ' *'}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={basicInfo({}).corporation}
        />
      </InputWrapper>
      <InputWrapper style={{ height: 'auto' }}>
        <InputFileWrapper>
          <InputFileLabel>
            <Text
              fontWeight={400}
              fontSize={theme.fontSizes.sm}
              color={
                fileCorporation !== ''
                  ? theme.colors.gray500
                  : !watch('corporationIdentificationDocument')?.[0]
                  ? theme.colors.gray300
                  : theme.colors.gray500
              }
            >
              {watch('corporationIdentificationDocument')?.[0]
                ? watch('corporationIdentificationDocument')[0].name
                : fileCorporation !== ''
                ? fileCorporation
                : t('LEIForm.basicInfo.corporationIdentificationDocument.label')}
            </Text>
            <input
              hidden
              accept=".pdf, .png, .gif, .jpg"
              type="file"
              {...register('corporationIdentificationDocument')}
            />
            {!watch('corporationIdentificationDocument')?.[0] && (
              <ErrorMessage>{errors.corporationIdentificationDocument?.message}</ErrorMessage>
            )}
            <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
          </InputFileLabel>
        </InputFileWrapper>
        <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
          JPG, GIF, PNG, {t('maxSize')} 2MB
        </Text>
      </InputWrapper>

      <FormTitle>{t('info.contactInfo')}</FormTitle>
      <MultiInputWrapper gridGap={30}>
        <Controller
          control={control}
          name={'contactName'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.contactInfo.contactName.label') + ' *'}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={basicInfo({}).contactName}
        />
        <MultiInputWrapper width={'100%'} gridGap={'10px'} marginBottom={0}>
          <Box width={100}>
            <FormSelect
              control={control}
              defaultValue={countryCodeOptions[0].value}
              data={countryCodeOptions}
              inputLabel={''}
              selectLabel={''}
              name={'contactPhoneCountryCode'}
              register={basicInfo({}).countryCode}
            />
          </Box>
          <Controller
            control={control}
            name={'contactPhone'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.contactInfo.contactPhone.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={basicInfo({}).contactPhone}
          />
        </MultiInputWrapper>
      </MultiInputWrapper>
      <MultiInputWrapper gridGap={30}>
        <Controller
          control={control}
          name={'contactMail'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.contactInfo.contactMail.label') + ' *'}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={basicInfo({}).contactMail}
        />
        <Controller
          control={control}
          name={'contactDepartment'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.contactInfo.contactDepartment.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
        />
      </MultiInputWrapper>
      <MultiInputWrapper gridGap={30}>
        <Controller
          control={control}
          name={'contactPosition'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.contactInfo.contactPosition.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
        />
        <MultiInputWrapper width={'100%'} gridGap={'10px'} marginBottom={0}>
          <Box width={100}>
            <FormSelect
              control={control}
              defaultValue={countryCodeOptions[0].value}
              data={countryCodeOptions}
              inputLabel={''}
              selectLabel={''}
              name={'contactFixCountryCode'}
              register={basicInfo({}).countryCode}
            />
          </Box>
          <Controller
            control={control}
            name={'contactFix'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.contactInfo.contactFix.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={basicInfo({}).contactFix}
          />
        </MultiInputWrapper>
      </MultiInputWrapper>
      <MultiInputWrapper gridGap={30}>
        <FormSelect
          control={control}
          data={convertLEIOptions(lang, options.IDType)}
          inputLabel={t('LEIForm.contactInfo.contactDocumentCategory.label')}
          selectLabel={t('LEIForm.contactInfo.contactDocumentCategory.label')}
          name={'contactDocumentCategory'}
        />
        <Controller
          control={control}
          name={'contactDocumentNumber'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('LEIForm.contactInfo.contactDocumentNumber.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
        />
      </MultiInputWrapper>
      <InputWrapper style={{ height: 'auto' }}>
        <InputFileWrapper>
          <InputFileLabel>
            <Text
              fontWeight={400}
              fontSize={theme.fontSizes.sm}
              color={
                fileContact !== ''
                  ? theme.colors.gray500
                  : !watch('contactIdentificationDocument')?.[0]
                  ? theme.colors.gray300
                  : theme.colors.gray500
              }
            >
              {watch('contactIdentificationDocument')?.[0]
                ? watch('contactIdentificationDocument')[0].name
                : fileContact !== ''
                ? fileContact
                : t('LEIForm.contactInfo.contactIdentificationDocument.label')}
            </Text>
            <input hidden accept=" .png, .gif, .jpg" type="file" {...register('contactIdentificationDocument')} />
            {!watch('contactIdentificationDocument')?.[0] && (
              <ErrorMessage>{errors.contactIdentificationDocument?.message}</ErrorMessage>
            )}
            <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
          </InputFileLabel>
        </InputFileWrapper>
        <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
          JPG, GIF, PNG, {t('maxSize')} 2MB
        </Text>
      </InputWrapper>

      <TabsButton selectedTab={1} setSelectedTab={setSelectedTab} />
    </Wrapper>
  );
};

export default BasicInformation;

import styled from 'styled-components';

const Total = styled.div`
  font-size: ${(p) => p.theme.fontSizes.base};
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 30px;
`;

const Card = styled.div`
  font-size: ${(p) => p.theme.fontSizes.base};
  border-top: 1px solid ${(p) => p.theme.colors.gray100};
  padding: 15px 0;

  .dealName {
    color: ${(p) => p.theme.colors.gray500};
    font-weight: 700;
    margin-bottom: 15px;
  }

  .detail {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .role {
      color: ${(p) => p.theme.colors.primary500};
      font-weight: 700;
      width: 100px;
    }

    .title {
      color: ${(p) => p.theme.colors.gray300};
      width: 100px;
    }

    .content {
      color: ${(p) => p.theme.colors.gray500};
    }

    a.content {
      color: ${(p) => p.theme.colors.primary500};
      text-decoration: underline;
    }
  }
`;

export { Total, Card };

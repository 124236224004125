import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

type AuthState = {
  defaultLang: string;
};

const INITAIL_STATE: AuthState = { defaultLang: 'en' };

const lang = createSlice({
  name: 'auth',
  initialState: INITAIL_STATE,
  reducers: {
    changeLang: (state, action: PayloadAction<string>) => {
      state.defaultLang = action.payload;
    }
  },
});

export const { changeLang } = lang.actions;

export const getLangSelector = (store: RootState) => store.lang.defaultLang;

export default lang.reducer;

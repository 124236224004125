import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { ContentWrapper } from '../style';
import { AccountSideMenu } from '@/components/SideMenu/AccountSideMenu';
import Rating from '@/components/Rating';

const UserComments = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const auth = useAppSelector(getAuthSelector);

  return (
    <PageContainer title={<InnerPageTitle link={'/profile/edit'} subtitle={t('comments')} />}>
      <ContentWrapper>
        <Rating user_id={auth.userData.id} />
        <AccountSideMenu />
      </ContentWrapper>
    </PageContainer>
  );
};

export default UserComments;

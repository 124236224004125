import styled from 'styled-components';

const Notice = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 15px 15px 15px 0px;
  ${(p) => p.theme.outsideShadowW02};
  background: #ffdbdb;

  > .text {
    display: inline-block;
    margin-left: ${(p) => (p.isTablet ? '0' : '20px')};
  }

  > a {
    width: ${(p) => (p.isTablet ? '100%' : 'unset')};
    margin-left: ${(p) => (p.isTablet ? '0' : 'auto')};

    > button {
      width: ${(p) => (p.isTablet ? '100%' : '150px')};
      padding: 10px 0;
      text-align: center;
      background: ${(p) => p.theme.colors.primary500};
      border-radius: 5px;
      color: ${(p) => p.theme.colors.light};
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: ${(p) => p.theme.fontSizes.base};
      line-height: 19px;
      text-decoration: none;
    }
  }
`;

const Icons = styled.span`
  display: flex;
  gap: 3px;
`;

const HomeWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  gap: 10px;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
`;

const CardWrapper = styled.div`
  ${(p) => p.theme.outsideShadowW02};
  padding: 25px;
  width: 100%;
  max-width: 50%;
`;

const CardTitle = styled.span`
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 15px;

  > img {
    margin-right: 10px;
  }
`;

const EventCounter = styled.span`
  display: inline-block;
  background: ${(p) => p.theme.colors.primary300};
  border-radius: 5px;
  padding: 3px;
  margin-left: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 19px;
  text-align: center;
  color: ${(p) => p.theme.colors.light};
`;

const LinkButton = styled.button`
  margin-left: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 19px;
  text-decoration-line: underline;
  color: ${(p) => p.theme.colors.secondary500};
  cursor: pointer;

  > a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    text-decoration-line: underline;
    color: ${(p) => p.theme.colors.secondary500};
  }
`;

const CatalogWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 1215px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px 0;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
`;

const IntroWrapper = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 25px;
  color: ${(p) => p.theme.colors.gray400};
  margin-bottom: 9px;
  text-align: justify;
`;

const ContentWrapper = styled.div`
  height: 185px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
  color: ${(p) => p.theme.colors.gray300};
`;

const ModalMainWrapper = styled.div`
  position: absolute;
  top: calc(50% - 178px);
  left: calc(50% - 250px);
  width: 500px;
  background: ${(p) => p.theme.colors.light};
  padding: 30px 50px;
  border-radius: 15px 15px 15px 0px;
  position: relative;

  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
`;

const NotificationModalWrapper = styled.div`
  position: absolute;
  top: 97px;
  left: calc(50% - 325px);
  width: 650px;
  background: ${(p) => p.theme.colors.light};
  padding: 30px 50px;
  border-radius: 15px 15px 15px 0px;
  position: relative;

  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
`;

const Row = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;

  .copy {
    cursor: pointer;
  }
`;

const ActionBtns = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 40px;

  button {
    font-family: 'Roboto';
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    text-decoration-line: underline;
    color: ${(p) => p.theme.colors.secondary500};
    cursor: pointer;
  }
`;

const ConfirmWrapper = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 20px;
  text-align: center;
  color: ${(p) => p.theme.colors.gray500};
`;

const ConfirmTitle = styled.div`
  font-weight: 700;
  margin-bottom: 30px;
`;

const DeleteBtns = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;

  button:last-child {
    color: ${(p) => p.theme.colors.primary100};
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const ExpiryDateNotification = styled.div`
  position: relative;
  left: -40px;
  width: 100%;
  padding: 20px;
  background: ${(p) => p.theme.colors.danger};
`;

export {
  HomeWrapper,
  CardTitle,
  CardWrapper,
  EventCounter,
  LinkButton,
  CatalogWrapper,
  IntroWrapper,
  ContentWrapper,
  Notice,
  Icons,
  ModalMainWrapper,
  Row,
  ActionBtns,
  ConfirmWrapper,
  ConfirmTitle,
  DeleteBtns,
  NotificationModalWrapper,
  ExpiryDateNotification,
};

import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getQrcodeUrl,
  startSign,
  getaccessToken,
  signFile,
  uploadP12,
  startESign,
  checklsCanSign,
} from '@/api/deal.api';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { createSearchParams, Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { Controller, useForm } from 'react-hook-form';

import { Flex, CardContainer, Box } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import step1SVG from './img/1.scanBarcode.svg';
import step2SVG from './img/2.shootingIdCard.svg';
import step3SVG from './img/3.photographingTheFace.svg';
import Loading from '@/components/UI/Loading';
import { FormHelperText, Modal, TextField } from '@mui/material';
import styled from 'styled-components';
import { InputFileLabel, InputFileWrapper, InputWrapper, Ul } from './styles';
import { CloudUpload } from '@/components/Icon';
import { theme } from '@/theme/Theme';
import { errorHandler } from '@/utils/toastHandler';

const ModalMainWrapper = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 200px);
  width: 500px;
  background: ${(p) => p.theme.colors.light};
  padding: 30px 50px;
  border-radius: 15px 15px 15px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  p {
    color: ${(p) => p.theme.colors.red};
    text-align: center;
  }
`;

interface ContractSignProps {
  timesHandler: () => void;
  fileId: string;
  dealRole: string;
  eSignTimes: number;
  hasChina: boolean;
  CNuser: boolean;
}

interface faceSign {
  dealId: string;
  fileId: string;
  dealRole: string;
}

const ContractSign = ({
  CNuser,
  timesHandler,
  fileId,
  dealRole,
  eSignTimes = 1,
  hasChina = false,
}: ContractSignProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id } = useParams();
  const scanSteps = t('pages.dealDetail.manageFiles.files.contractSigning.scanSteps', { returnObjects: true });
  const location = useLocation();
  const { userData } = useAppSelector(getAuthSelector);
  const [showRedirButton, setShowRedirButton] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const [faceSignControl] = useState(true);

  // 取得當前網址
  const currentUrl = useMemo(() => {
    const searchParams = createSearchParams(location.search);
    return `${location.pathname}${searchParams.toString()}`;
  }, [location]);

  const [url, setUrl] = useState('');
  const [eSign, setESign] = useState(eSignTimes);
  const [isLoading, setIsLoading] = useState(true);

  const [QRCodeControl, setQRCodeControl] = useState(true);
  const faceSignTips = t('pages.dealDetail.manageFiles.files.contractSigning.p12.china.tips', {
    returnObjects: true,
  });
  const p12Tips = t('pages.dealDetail.manageFiles.files.contractSigning.p12.hongkong.tips', {
    returnObjects: true,
  });

  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { isDirty },
  } = useForm<P12FormFields>();
  const watchP12 = watch('P12');

  const onSubmit = handleSubmit(async (data) => {
    if (!id) return;

    setIsLoading(true);

    const ReqData: P12Request = {
      dealId: id,
      fileId,
      dealRole,
      P12: data.P12.length !== 0 && data.P12[0],
      P12Password: data.P12Password,
    };

    try {
      const res = (await uploadP12(ReqData)).data;
      // console.log(res);
      if (res.success) {
        // 直接透過windows轉址到.html檔案
        // 取得當前網域名稱
        const domain = window.location.origin;
        window.location.href = `${domain}/sign/customGetHWSign.html?accessToken=${res.data.accessToken}&dealRole=${res.data.dealRole}&spOrderNum=${res.data.spOrderNum}&dealRole=${res.data.dealRole}&dealId=${res.data.dealId}`;
      } else {
        errorHandler(res.message);
      }
    } catch (error: any) {
      errorHandler(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  // 監視eSignTimes次數, 若有更新則刷新eSign
  useEffect(() => {
    if (!hasChina) return;
    setESign(eSignTimes);
    if (eSignTimes === 0) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [eSignTimes]);

  const startSignData = async (data: faceSign) => {
    if (faceSignControl) {
      if (hasChina) {
        // QR放入對應的資料讓網頁轉跳
        const { dealId, fileId, dealRole } = data;
        if (CNuser) {
          // 取得當前網域
          const domain = window.location.origin;

          setUrl(`${domain}/sign/uploadPhoto.html?dealId=${dealId}&fileId=${fileId}&dealRole=${dealRole}`);
        }

        setIsLoading(false);
      } else {
        try {
          const res = (await startESign(data)).data;
          setUrl(res);
          setIsLoading(false);
        } catch (error) {}
      }
    } else {
      try {
        const res = (await startESign(data)).data;
        setUrl(res);
        setIsLoading(false);
      } catch (error) {}
    }
  };

  const checkCanSign = async (data: { dealId: string; fileId: string }) => {
    try {
      const res = (await checklsCanSign(data)).data;
      if (res.success) {
        setShowRedirButton(true);
        setRedirectUrl(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const dealId = id ? id : '0';
    const data = {
      dealId,
      fileId,
      dealRole,
    };
    startSignData(data);

    // 設定longpulling每隔10秒去檢查是否有做完身分驗證
    const interval = setInterval(() => {
      if (faceSignControl) {
        if (hasChina) {
          const data = {
            dealId,
            fileId,
          };
          checkCanSign(data);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const handleModalClose = () => {};

  const handleQRCodeShow = () => {
    setQRCodeControl(false);
    timesHandler();
  };

  return isLoading ? (
    <Loading />
  ) : (
    <CardContainer padding={'50px 115px'}>
      <Text textAlign={'center'} marginBottom={'30px'}>
        {!hasChina && t('pages.dealDetail.manageFiles.files.contractSigning.agreement')}

        {hasChina && !CNuser && t('pages.dealDetail.manageFiles.files.contractSigning.p12.hongkong.title')}

        {hasChina && CNuser && t('pages.dealDetail.manageFiles.files.contractSigning.p12.china.title')}
      </Text>

      {hasChina && !CNuser && (
        <Text textAlign={'center'}>{t('pages.dealDetail.manageFiles.files.contractSigning.p12.hongkong.intro')}</Text>
      )}

      {hasChina && CNuser && (
        <>
          <Text marginBottom={'10px'}>{t('pages.dealDetail.manageFiles.files.contractSigning.p12.china.intro')}</Text>
          <Ul>
            {faceSignTips.map((tip, index) => {
              return (
                <li key={tip + index}>
                  <Text>{tip}</Text>
                </li>
              );
            })}
          </Ul>
        </>
      )}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalMainWrapper>
          <Text>
            {t('info.noMoreEsignature')}
            <br /> {t('info.pay')}
          </Text>
          <Link to={`/cart?item=${CNuser ? '12' : '11'}`} state={{ from: currentUrl }}>
            <Button variant={'primary'} size={'lg'}>
              {t('buttons.pay')}
            </Button>
          </Link>
        </ModalMainWrapper>
      </Modal>
      <Flex justifyContent={'space-evenly'} marginBottom={'50px'}>
        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          {eSignTimes !== 0 && hasChina && !CNuser && url === '' && (
            <form onSubmit={onSubmit} style={{ width: '500px', padding: '30px 50px' }}>
              <InputWrapper height={'65px'}>
                <InputFileWrapper>
                  <InputFileLabel>
                    <Text color={theme.colors.gray500}>{t('form.eCertFile.label')}</Text>
                    <Text margin={'0 17px 0 20px'} color={theme.colors.gray200}>
                      {watchP12 !== undefined && watchP12.length > 0 ? watch('P12')[0].name : ''}
                    </Text>
                    <input hidden type="file" {...register('P12')} />
                    <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
                  </InputFileLabel>
                </InputFileWrapper>
              </InputWrapper>
              <Controller
                control={control}
                defaultValue=""
                name={'P12Password'}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Flex>
                    <Text color={theme.colors.gray500}>{t('form.eCertPin.label')}</Text>
                    <TextField
                      label={''}
                      placeholder={t('form.eCertPin.placeholder')}
                      variant="standard"
                      value={value}
                      sx={{ flex: 1, paddingLeft: '10px' }}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type={'password'}
                      autoComplete="off"
                    />
                  </Flex>
                )}
                rules={{
                  required: t('required.input', { input: t('form.eCertPin.label') }),
                  maxLength: {
                    value: 16,
                    message: t('required.length', { input: t('form.eCertPin.label'), length: 16 }),
                  },
                  minLength: {
                    value: 16,
                    message: t('required.length', { input: t('form.eCertPin.label'), length: 16 }),
                  },
                }}
              />
              <Flex paddingTop={40} flexDirection={'column'} gridGap={'8px'}>
                <Text fontSize={theme.fontSizes.sm} color={theme.colors.gray500}>
                  {t('pages.dealDetail.manageFiles.files.contractSigning.p12.hongkong.remarks')}
                </Text>
                {p12Tips.map((tip, index) => {
                  return (
                    <Text key={tip + index} fontSize={theme.fontSizes.sm} color={theme.colors.gray500}>
                      {index + 1}. {tip}
                    </Text>
                  );
                })}
              </Flex>
              <Button size={'lg'} variant={'primary'} marginTop={30} disabled={!isDirty}>
                {t('buttons.signWithECert')}
              </Button>
            </form>
          )}
          {url === 'error' && (
            <div
              style={{
                width: 256,
                height: 256,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: 'red',
              }}
            >
              {t('error.qrCode')}
              <br />
              {t('error.tryAgain')}
            </div>
          )}

          {eSignTimes !== 0 && faceSignControl && CNuser && url !== '' && url !== 'error' && QRCodeControl && (
            <div>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={url}
                viewBox={`0 0 256 256`}
              />
            </div>
          )}

          {/* {!faceSignControl && url !== '' && url !== 'error' && QRCodeControl && (
            <a href={url} rel="noreferrer" onClick={handleQRCodeShow}>
              <Button size={'lg'} variant={'primary'} rounded={false} marginBottom={15}>
                {t('buttons.startSign')}
              </Button>
            </a>
          )} */}

          {faceSignControl && !CNuser && url !== '' && url !== 'error' && QRCodeControl && (
            <a href={url} rel="noreferrer" onClick={handleQRCodeShow}>
              <Button size={'lg'} variant={'primary'} rounded={false} marginBottom={15}>
                {t('buttons.startSign')}
              </Button>
            </a>
          )}

          {faceSignControl && showRedirButton && CNuser && url !== '' && url !== 'error' && QRCodeControl && (
            <Text textAlign={'center'} marginTop={30}>
              {t('pages.dealDetail.manageFiles.files.contractSigning.p12.china.redirect')}
              <a href={redirectUrl}>
                <Text color={theme.colors.primary500} style={{ cursor: 'pointer', display: 'inline' }}>
                  {t('buttons.startSign')}
                </Text>
              </a>
            </Text>
          )}

          {/* faceSign測試 */}

          {/* {!QRCodeControl && (
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={url}
                viewBox={`0 0 256 256`}
              />
            )} */}

          {/* 暫時隱藏 */}
          {/* <Text textAlign={'center'} fontSize={'16px'} color={'#DC1010'} marginTop={'20px'}>
              e-signatures left: {eSign}
            </Text> */}

          {/* <Text textAlign={'center'} fontSize={'16px'} color={'#DC1010'} marginTop={'10px'}>
              if QR code didn't show up, please wait for 30 sec to refresh the page
            </Text> */}
        </Flex>

        {/* <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          {url === '' && (
            <div
              style={{
                width: 256,
                height: 256,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              loading...
            </div>
          )}
          {url === 'error' && (
            <div
              style={{
                width: 256,
                height: 256,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: 'red',
              }}
            >
              failed to get QR code link
              <br />
              please try it later
            </div>
          )}
          {url !== '' && url !== 'error' && (
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={url}
              viewBox={`0 0 256 256`}
            />
          )}
          {t('dealDetail.manageFiles.files.contractSigning.aseanRegion.name')}
        </Flex> */}
      </Flex>
    </CardContainer>
  );
};

export default ContractSign;

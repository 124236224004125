import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabWrapper, Wrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import OwnCompany from './OwnCompany';
import OtherCompanies from './OtherCompanies';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<CreditReportResponse | undefined>>;
}

const RegistrationForm = ({ setCurrentStep, setActiveStep, setData }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [seatchParmas, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<boolean>(false);

  const prevHandler = () => {
    setCurrentStep('1');
    setActiveStep(0);
    setSearchParams({ step: '1' });
  };

  const nextHandler = () => {
    setCurrentStep('3');
    setActiveStep(2);
    setSearchParams({ step: '3' });
  };

  const tabHanlder = () => {
    setTab(!tab);
  };

  return (
    <TabWrapper>
      <Flex gridGap={15} marginBottom={30}>
        <Button size={'lg'} type="button" variant={tab ? 'regular' : 'normal-invert'} onClick={tabHanlder}>
          {t('pages.eTrust.creditReport.steps.step2.tabs.ownCompany')}
        </Button>
        <Button size={'lg'} type="button" variant={tab ? 'normal-invert' : 'regular'} onClick={tabHanlder}>
          {t('pages.eTrust.creditReport.steps.step2.tabs.otherCompanies')}
        </Button>
      </Flex>

      {tab ? (
        <OwnCompany nextHandler={nextHandler} prevHandler={prevHandler} setData={setData} />
      ) : (
        <OtherCompanies nextHandler={nextHandler} prevHandler={prevHandler} setData={setData} />
      )}
    </TabWrapper>
  );
};

export default RegistrationForm;

const generateExecutionDetail = (array: ExecutionFile[], currentLength: number) => {
  const executionAry: string[] = [];
  const executionPassAry: boolean[] = [];
  let closePassAry: boolean[] = [];

  if (currentLength > 4 || currentLength === 0) {
    array.forEach((item, i) => {
      if (i < 4) {
        executionAry.push(item.name);
        executionPassAry.push(checkAccept(item.accepted_at));
      }
    });
  } else {
    array.forEach((item, i) => {
      executionAry.push(item.name);
      executionPassAry.push(checkAccept(item.accepted_at));
    });
  }

  if (executionAry.length !== 0 && executionPassAry.every((s) => s)) {
    closePassAry = [true, true, true];
  } else {
    closePassAry = [false, false, false];
  }

  return { executionAry, executionPassAry, closePassAry };
};

const checkAccept = (accept: string | undefined) => {
  if (!accept) return false;
  return true;
};

export default generateExecutionDetail;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';
import { inviteNoneDMPUser } from '@/api/deal.api';
import useCountryDetail from '@/hooks/useCountryDetail';

import { Modal, TextField } from '@mui/material';
import { ModalForm } from '../styles';
import { Flex, LoadingPage } from '@/layout';
import { Close } from '@/components/Icon';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { FormSelect } from '@/components/UI/Select';
import { errorHandler, successHandler } from '@/utils/toastHandler';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deal_id: string | null;
  handleNextStep: () => Promise<void>;
}

const InviteGuestModal = ({ handleNextStep, showModal, setShowModal, deal_id }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { regionRegister } = useRegistrationValidation().companyInfo();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const langOptions = t('invitationLanguages', { returnObjects: true });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<inviteNoneDMPUserRequest>();

  const onSubmit = handleSubmit(async (data) => {
    if (!deal_id) return;
    setIsLoading(true);
    try {
      const rawData = { ...data, deal_id };
      const res = (await inviteNoneDMPUser(rawData)).data;
      if (res.success) {
        successHandler(t('success.invite'));
        setShowModal(false);
        handleNextStep();
      }
    } catch (error: any) {
      if (typeof error.response.data.data !== 'string') {
        errorHandler(t('error.server'));
        return;
      }

      // 判斷error回傳的data文字來顯示不同的錯誤訊息
      if (error.response.data.data === 'This email is already registered') {
        errorHandler(t('error.accountExits'));
      } else if (error.response.data.data === 'Deal not found') {
        errorHandler(t('error.dealNotFound'));
      } else if (error.response.data.data === 'validator error') {
        errorHandler(t('error.validatorError'));
      } else {
        errorHandler(t('error.server'));
      }
    } finally {
      reset();
      setIsLoading(false);
    }
  });

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalForm onSubmit={onSubmit}>
        <LoadingPage isLoading={isLoading} />
        <Flex marginBottom={30}>
          <Text fontWeight={700}>{t('pages.dealInitiation.formStepper.step3.inviteWithoutAnAccount')}</Text>
          <Close.ButtonClose
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Close.CloseSVG />
          </Close.ButtonClose>
        </Flex>
        <InputWrapper>
          <Controller
            control={control}
            name={'email'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.email.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <FormSelect
            control={control}
            data={useCountryDetail('name')}
            inputLabel={t('form.region.label')}
            selectLabel={'Region'}
            name={'location'}
            register={regionRegister}
          />
        </InputWrapper>
        <InputWrapper>
          <FormSelect
            control={control}
            data={langOptions}
            inputLabel={t('form.invitationLetterLang.label')}
            selectLabel={'Preferred Language'}
            name={'preferred_language'}
            register={regionRegister}
          />
        </InputWrapper>
        <Flex marginTop={'50px'} gridGap={30} alignItems={'center'} justifyContent={'center'}>
          <Button type={'submit'} size={'lg'} variant={'primary'}>
            {t('buttons.ok')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'underline' }}
          >
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </ModalForm>
    </Modal>
  );
};

export default InviteGuestModal;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@/layout';
import { Check } from '@/components/Icon';
import StyledLink from '@/components/UI/Link';

import { ProgressStage } from './styles';
import { theme } from '@/theme/Theme';
import { Link } from 'react-router-dom';

interface ProgressItemProps {
  itemName: string;
  name: string;
  isOwner: boolean;
  detail?: string[];
  pass?: boolean[];
  id?: string;
  previousItem?: boolean[];
  cancelled?: boolean;
  dealStatus?: string;
  expiredDeal?: boolean;
}

const ProgressItem = ({
  itemName,
  name,
  isOwner,
  previousItem = [],
  detail,
  pass,
  id = '',
  cancelled = false,
  dealStatus,
  expiredDeal,
}: ProgressItemProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const [complete, setComplete] = useState(false);
  const [progress, setProgress] = useState(false);
  const [checkProgress, setCheckProgress] = useState(false);

  const initData = useCallback(() => {
    if (complete) return;
    if (typeof detail !== 'object') return;
    if (typeof pass !== 'object') return;
    // 條件1: 若此條件尚未全部完成, 此步驟同樣為progress
    // 條件2: 前個item全數完成, 此步驟則判斷為progress
    // 條件3: 若此步驟為Manage invitations, 則判斷為progress

    if (dealStatus && dealStatus === '0') {
      setProgress(true);
      return;
    }

    if (cancelled) return;

    if (pass.some((p) => !p) && checkProgress) {
      setProgress(true);
    } else if (itemName === 'step1') {
      setProgress(true);
    }
  }, []);

  useEffect(() => {
    if (detail && detail.length !== 0 && pass?.every((p) => p)) {
      setComplete(true);
    }

    if (previousItem.length !== 0 && previousItem.every((p) => p)) {
      setCheckProgress(true);
    }

    initData();
  }, [initData]);

  return (
    <Box width={'100%'}>
      <ProgressStage
        className={`${cancelled ? 'cancelled' : ''}${progress && !complete && !cancelled ? 'progressing' : ''}`}
        complete={complete}
      >
        <span>{name}</span>
      </ProgressStage>
      <Flex padding={'20px 25px'} flexDirection={'column'} gridGap={'10px'}>
        {detail &&
          detail.map((s, i) => (
            <Flex gridGap={'5px'} alignItems={'center'} key={`d-${s}`}>
              <Check.CheckSVG fill={pass && pass[i] ? theme.colors.secondary400 : theme.colors.gray100} />
              {/* 
                step1共有四種狀態:
                1. 未接受邀請 - 邀請方 (連結)
                2. 未接受邀請 - 受邀方 (連結)
                3. 接受邀請 (純文字)
                4. 未接受邀請且邀請過期 (純文字)
              */}

              {/* 1 */}
              {itemName === 'step1' && dealStatus === '0' && !expiredDeal && isOwner && (
                <Link to={`/deal/initiation?step=3&id=${id}`} style={{ color: '#0B319D', textDecoration: 'underline' }}>
                  {s}
                </Link>
              )}

              {/* 2 */}
              {itemName === 'step1' && dealStatus === '0' && !expiredDeal && !isOwner && (
                <Link to={`/deal/${id}`} style={{ color: '#0B319D', textDecoration: 'underline' }}>
                  {s}
                </Link>
              )}

              {/* 3 */}
              {itemName === 'step1' && dealStatus !== '0' && s}

              {/* 4 */}
              {itemName === 'step1' && dealStatus === '0' && expiredDeal && s}

              {/* 除Initiation之外的三個狀態欄的顯示 */}
              {itemName !== 'step1' && s}
            </Flex>
          ))}
      </Flex>
    </Box>
  );
};

export default ProgressItem;

import styled from 'styled-components';

const SearchIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  right: 0;
  top: 5px;
`;

const SearchBar = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding-bottom: 20px;
  position: relative;
  gap: 10px;
`;

const ContentWrapper = styled.div`
  height: 185px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
  color: ${(p) => p.theme.colors.gray300};
`;

const CardWrapper = styled.div`
  ${(p) => p.theme.outsideShadowW02};
  padding: 25px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 1200px;
`;

const ModalForm = styled.div`
  position: absolute;
  top: 40%;
  left: calc(50% - 300px);
  width: 600px;
  background-color: #fff;
  padding: 50px;
  border-radius: 20px 20px 20px 0px;
`;

const ModalTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 15px;
  text-align: center;
`;

const PermissionWrapper = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.gray200};

  &:last-child {
    border-bottom: 1px solid ${(p) => p.theme.colors.gray200};
  }
`;

const ModalNotice = styled.div`
  font-size: ${(p) => p.theme.fontSizes.base};
  color: ${(p) => p.theme.colors.red};
  margin-bottom: 15px;
`;

const CompanyPic = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.secondary500};
`;

export {
  PermissionWrapper,
  CompanyPic,
  CardWrapper,
  ContentWrapper,
  SearchIconWrapper,
  SearchBar,
  ModalForm,
  ModalTitle,
  ModalNotice,
};

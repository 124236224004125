import { useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import BasicInformation from './BasicInformation';
import Tabs from '@/components/UI/Tabs';
import Address from './Address';
import LegalnBusiness from './Legal&Business';
import DirectParent from './DirectParent';
import UltimateParent from './UltimateParent';
import { getLEIOptionList } from '@/api/lei.api';
import Loading from '@/components/UI/Loading';
import LEIresponse from '../LEIresponse';
import { errorHandler } from '@/utils/toastHandler';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<LEIRequestData>>;
  userDetail?: MemberProfile;
  data: LEIRequestData;
  LEIOptions: LEIResponse;
  checkLevel: () => boolean;
  status: number;
}

const Information = ({
  status,
  checkLevel,
  setCurrentStep,
  setActiveStep,
  data,
  setData,
  userDetail,
  LEIOptions,
}: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { search } = useLocation();

  const navigate = useNavigate();
  const [searchParmas, setSearchParams] = useSearchParams();
  const initTabIndex = new URLSearchParams(search).get('tab');
  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex ? parseInt(initTabIndex) : 1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const tabLabel = t('pages.eTrust.LEI.steps.step2.tabs', { returnObjects: true });

  const tabs: TabsType = useMemo(
    () => [
      {
        label: `${tabLabel[0]}`,
        index: 1,
        Component: () => (
          <BasicInformation
            data={data}
            options={LEIOptions}
            setData={setData}
            userDetail={userDetail}
            setSelectedTab={setSelectedTab}
          />
        ),
      },
      {
        label: `${tabLabel[1]}`,
        index: 2,
        Component: () => (
          <Address
            data={data}
            options={LEIOptions}
            setData={setData}
            userDetail={userDetail}
            setSelectedTab={setSelectedTab}
          />
        ),
      },
      {
        label: tabLabel[2],
        index: 3,
        Component: () => (
          <LegalnBusiness
            data={data}
            options={LEIOptions}
            setData={setData}
            userDetail={userDetail}
            setSelectedTab={setSelectedTab}
            setCurrentStep={setCurrentStep}
            setActiveStep={setActiveStep}
          />
        ),
      },
      {
        label: `${tabLabel[3]}`,
        index: 4,
        Component: () => (
          <DirectParent
            data={data}
            options={LEIOptions}
            setData={setData}
            userDetail={userDetail}
            setSelectedTab={setSelectedTab}
          />
        ),
      },
      {
        label: tabLabel[4],
        index: 5,
        Component: () => (
          <UltimateParent
            data={data}
            options={LEIOptions}
            setData={setData}
            userDetail={userDetail}
            setSelectedTab={setSelectedTab}
            setCurrentStep={setCurrentStep}
            setActiveStep={setActiveStep}
          />
        ),
      },
    ],
    [data, LEIOptions, t]
  );

  const prevHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // 若selectedTab在1, 往回則退到第一步
    if (selectedTab === 1) {
      setCurrentStep('1');
      setActiveStep(0);
      navigate('/lei?step=1');

      return;
    }
    // 若非為1, 則selectedTab - 1
    setSelectedTab(selectedTab - 1);
    setSearchParams({ step: '2', tab: (selectedTab - 1).toString() });
  };

  const nextHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // 若selectedTab在最後一個, 往下則進到第三步
    if (selectedTab === tabLabel.length) {
      setCurrentStep('3');
      setActiveStep(2);
      navigate('/lei?step=3');

      return;
    }

    // 若非為最後一個, 則selectedTab + 1
    setSelectedTab(selectedTab + 1);
    setSearchParams({ step: '2', tab: (selectedTab - 1).toString() });
  };

  useEffect(() => {
    if (!checkLevel()) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!initTabIndex || parseInt(initTabIndex) === 0) {
      if (selectedTab !== 5) {
        navigate('/lei?step=2&tab=1', { replace: true });
        setSelectedTab(1);
      }
    } else {
      const paramsStep = parseInt(initTabIndex);
      setSelectedTab(paramsStep);
    }

    // 檢查是否為子帳號, 若為子帳號則不可進行LEI申請
    if (!userDetail?.main_account_company_info.isMainAccount) {
      prevHandler();
      return;
    }

    // 檢查進度, 若該使用者有按F5重新整理頁面導致資料丟失, 則重新導向到tab第一頁
    checkProgress();

    if (!LEIOptions.EntityLegalCategory) {
      errorHandler(t('error.server'));
      prevHandler();
      return;
    }
    setIsLoading(false);
  }, [searchParmas, LEIOptions]);

  const checkProgress = () => {
    const { basicInformation, address, legalBusiness, directParent } = data;
    if (status > 0 && status < 7) {
      if (status === 2) {
        navigate('/lei?step=3');
        return;
      }
      navigate('/lei?step=2?tab=5');
      return;
    }

    if (selectedTab === 2 && !basicInformation) {
      navigate('/lei?step=2?tab=1');
      return;
    }

    if (selectedTab === 3 && !address) {
      navigate('/lei?step=2?tab=1');
      return;
    }

    if (data.basicInformation?.entityLegalCategory !== 'BRANCH' && selectedTab === 4 && !legalBusiness) {
      navigate('/lei?step=2?tab=1');
      return;
    }

    if (data.basicInformation?.entityLegalCategory !== 'BRANCH' && selectedTab === 5 && !directParent) {
      navigate('/lei?step=2?tab=1');
      return;
    }
  };

  return (
    <div style={{ width: '100%', maxWidth: '930px' }}>
      {!isLoading ? <Tabs fullWidth={true} tabs={tabs} selectedTab={selectedTab} onClick={() => {}} /> : <Loading />}

      {selectedTab === 1 && (
        <Box style={{ paddingTop: 22 }}>
          <Button
            size={'lg'}
            type="button"
            variant={'primary'}
            rounded={false}
            marginRight={40}
            disabled={true}
            onClick={nextHandler}
          >
            {t('buttons.next')}
          </Button>
          <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
            {t('buttons.back')}
          </Button>
        </Box>
      )}
    </div>
  );
};

export default Information;

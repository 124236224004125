import styled from 'styled-components';
import { box, BoxProps } from '@/layout/Box';

const Wrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 984px;
  margin: ${(p) => (p.isMobile ? '30px auto' : p.isTablet ? '80px auto' : '150px auto')};
`;

const Background = styled.div`
  width: 872px;
  height: 550px;
  border-radius: 60px 60px 60px 0px;
  background-image: url('/images/authBG.jpg');
`;

const Content = styled.div<{ isTablet: boolean }>`
  position: absolute;
  right: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 412px;
  padding: 30px;
  background-color: white;
  border-radius: 40px 40px 40px 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  > form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const InputWrapper = styled.div<BoxProps>`
  display: flex;
  position: relative;
  flex-flow: column;
  flex-grow: 1;
  justify-content: space-evenly;
  margin-bottom: 30px;
  ${box};
`;

const InputFileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
`;

const InputFileLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-right: 15;
  box-sizing: content-box;
`;

const MultiInputWrapper = styled.div<BoxProps & { isMobile?: boolean }>`
  display: flex;
  flex-flow: ${(p) => (p.isMobile && p.isMobile === true ? 'column' : 'row')};
  flex-grow: 1;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 30px;
  ${box};
`;

const SearchBarInputsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  justify-content: space-between;
  height: 100%;
`;

const FormTitle = styled.div<BoxProps>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 20px;
  color: ${(p) => p.theme.colors.primary500};
  margin-bottom: 15px;
  ${box};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  transform: scale(0.8);
  cursor: pointer;
`;

const TipWrapper = styled.div<{ isMobile: boolean }>`
  min-width: ${(p) => (p.isMobile ? 'unset' : '100px')}};
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 20px;
  color: ${(p) => p.theme.colors.secondary500};
  text-decoration: underline;
  cursor: pointer;
`;

const Title = styled.h1<{ isMobile: boolean }>`
  font-size: ${(p) => (p.isMobile ? p.theme.fontSizes.lg : p.theme.fontSizes.xl)};
  text-align: ${(p) => (p.isMobile ? 'center' : 'left')};
`;

export {
  InputFileWrapper,
  InputFileLabel,
  Wrapper,
  Background,
  Content,
  InputWrapper,
  MultiInputWrapper,
  SearchBarInputsWrapper,
  FormTitle,
  IconWrapper,
  TipWrapper,
  Title,
};

import { useTranslation } from 'react-i18next';

import { Button } from '@/components/UI/Button';

interface JoinedStatusProps {
  joined: string;
}

export const JoinedStatus = ({ joined }: JoinedStatusProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <>
      {joined === '0' && (
        <Button variant="normal" special="fake">
          {t('buttons.inviting')}
        </Button>
      )}
      {joined === '1' && (
        <Button variant="regular" special="fake">
          {t('buttons.accepted')}
        </Button>
      )}
      {joined === '2' && (
        <Button variant="danger" special="fake">
          {t('buttons.rejected')}
        </Button>
      )}
    </>
  );
};

import React, { useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import Avatar from '@mui/material/Avatar';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { Box } from '@/layout/Box';
import { Flex } from '@/layout/Flex';
import { FormHeader } from '@/components/UI/Typography/Heading';
import { Text, UnderlineText } from '@/components/UI/Typography/Text';
import FormWrapper from '@/components/FormStepper/Wrapper';
import { Button } from '@/components/UI/Button';
import DataTable from '@/components/DataTable';
import { Delete } from '@/components/Icon';
import { ShowBadge } from '@/components/DataTable/ShowBadge';
import { LoadingPage } from '@/layout';
import { InvitedCompany } from './InvitedCompany';
import { JoinedStatus } from './JoinedStatus';

import { theme } from '@/theme/Theme';

import { removeParticipant, dealInitiationStep3 } from '@/api/deal.api';
import { convertLink } from '@/utils/convert';

type DataRow = {
  id: string;
  photo: string;
  name: string;
  badge: string;
  email: string;
  languages: string;
  jobPosition: string;
  action: string;
};

interface ParticipantsListProps {
  queryId: string | null;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  handleNextStep: () => Promise<void>;
  handleBackStep: () => void;
  invitedParticipantList?: {
    company: DealInvitedCompany[];
    personal: DealInvitedPersonal[];
  };
  setInvitedParticipantList: Dispatch<
    SetStateAction<
      | {
          company: DealInvitedCompany[];
          personal: DealInvitedPersonal[];
        }
      | undefined
    >
  >;
}

const ParticipantsList = ({
  queryId,
  setSelectedTab,
  handleNextStep,
  handleBackStep,
  invitedParticipantList,
  setInvitedParticipantList,
}: ParticipantsListProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const rawColumn = t('pages.dealInitiation.formStepper.step3.moreParticipants.table.columns', { returnObjects: true });
  const [personalList, setPersonalList] = useState<DataRow[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!invitedParticipantList) return;
    checkParticipants();
    const data: DataRow[] = invitedParticipantList.personal.map(
      (d): DataRow => ({
        id: d.acc_id,
        photo: d.user.acc_logo,
        name: `${d.user.acc_firstName || ''} ${d.user.acc_lastName || ''}`,
        badge: Object.values(d.user.verification)
          .reduce((acc, cur) => acc + Number(cur === true), 0)
          .toString(),
        email: d.user.email,
        languages: d.user.acc_languages,
        jobPosition: d.user.acc_jobPosition,
        action: d.joined,
      })
    );
    setPersonalList(data);
  }, [invitedParticipantList]);

  const columns: ColumnDef<DataRow>[] = useMemo(
    () => [
      {
        accessorKey: 'photo',
        header: rawColumn[0],
        id: 'photo',
        cell: ({ row }) => <Avatar src={row.original.photo} />,
        width: '100px',
      },
      {
        accessorKey: 'name',
        header: rawColumn[1],
        sortable: true,
      },
      {
        accessorKey: 'badge',
        header: rawColumn[2],
        cell: ({ row }) => <ShowBadge level={row.original.badge} />,
      },
      {
        accessorKey: 'email',
        header: rawColumn[3],
        sortable: true,
      },
      {
        accessorKey: 'languages',
        header: rawColumn[4],
        sortable: true,
      },
      {
        accessorKey: 'jobPosition',
        header: rawColumn[5],
        sortable: true,
      },
      {
        accessorKey: 'action',
        header: rawColumn[6],
        cell: ({ row }) => {
          return (
            <Flex alignItems={'center'} gridGap={'15px'}>
              <JoinedStatus joined={row.original.action} />
              <Delete.DeleteSVG
                fill={theme.colors.primary500}
                width={30}
                cursor={'pointer'}
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    const res = (await removeParticipant({ acc_id: row.original.id, deal_id: queryId as string })).data;
                    setInvitedParticipantList(res.data);
                  } catch (error) {}
                  setIsLoading(false);
                }}
              />
            </Flex>
          );
        },
      },
    ],
    [rawColumn]
  );

  const [buttonStatus, setButtonStatus] = useState(false);

  const checkParticipants = async () => {
    if (!queryId) return;

    // 調整為若有邀請則可先進入下一步進行檔案上傳
    if (invitedParticipantList?.company.length !== 0) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }

    // try {
    //   const res = (await dealInitiationStep3({ deal_id: queryId })).data;
    //   setButtonStatus(true);
    // } catch (error) {
    // setButtonStatus(false);
    // const err = error as unknown as any;
    // toast.error(err.response.data.data);
    // }
  };

  return (
    <FormWrapper>
      <LoadingPage isLoading={isLoading} />
      <Flex flexDirection={'column'} minHeight={'694px'}>
        <FormHeader>{t('pages.dealInitiation.formStepper.step3.acceptedList.title')}</FormHeader>
        <Box marginTop={20} marginBottom={30}>
          {/* <Text marginBottom={10} fontWeight={700} fontSize={theme.fontSizes.md}>
            {t('dealInitiation.formStepper.step3.acceptedList.company.name')}
          </Text> */}
          <InvitedCompany
            invitedParticipantList={invitedParticipantList}
            setInvitedParticipantList={setInvitedParticipantList}
            setIsLoading={setIsLoading}
            setSelectedTab={setSelectedTab}
          />
        </Box>
        {/* <Box marginBottom={'20px'}>
          <Text marginBottom={10} fontWeight={700} fontSize={theme.fontSizes.md}>
            {t('dealInitiation.formStepper.step3.acceptedList.participants.name')}
          </Text>
          {personalList && personalList.length !== 0 ? (
            <DataTable columns={columns} data={personalList} />
          ) : (
            <Flex>
              <Text color={theme.colors.gray300} fontSize={theme.fontSizes.md} marginRight={20}>
                {t('dealInitiation.formStepper.step3.acceptedList.participants.noData')}
              </Text>
              <UnderlineText fontSize={theme.fontSizes.md} onClick={() => setSelectedTab(3)}>
                Go
              </UnderlineText>
            </Flex>
          )}
        </Box> */}

        <Box marginTop={'auto'}>
          {buttonStatus && (
            <Button size={'lg'} variant={'primary'} onClick={handleNextStep}>
              {t('buttons.next')}
            </Button>
          )}
          {!buttonStatus && (
            <Button size={'lg'} variant={'normal'}>
              {t('buttons.next')}
            </Button>
          )}
          {/* <Button size={'lg'} onClick={handleBackStep}>
            {t('buttons.back')}
          </Button> */}
        </Box>
      </Flex>
    </FormWrapper>
  );
};

export default ParticipantsList;

import styled from 'styled-components';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Description = styled.p`
  margin-bottom: 5px;
  width: 100%;
  color: ${(p) => p.theme.colors.gray400};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TimeDifference = styled.p`
  color: ${(p) => p.theme.colors.gray300};
`;

const UnreadDot = styled.div`
  position: absolute;
  top: 0;
  right: 5.5px;
  width: 8px;
  height: 8px;
  background: ${(p) => p.theme.colors.orange};
  border-radius: 50%;
`;

const OrangeTitle = styled.span`
  color: ${(p) => p.theme.colors.orange};

`

export { CardWrapper, Description, TimeDifference, UnreadDot, OrangeTitle };

import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Tabs from '@/components/UI/Tabs';
import PageContainer from '@/components/Container/PageContainer';
import DealListItem from '@/components/DealList/DealListItem';
import { Button } from '@/components/UI/Button';
import { Add } from '@/components/Icon';
import Loading from '@/components/UI/Loading';

import { getDealList } from '@/api/deal.api';
import PATH from '@/router/path';
import { dealTagStatusHandler, dealStatusCounter } from '@/utils/dealTagStatusHandler';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

// import fakeData from '@/components/DealList/DealListItem/fakeData';

const Deal = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tabLabel = t('pages.deal.list.tabs', { returnObjects: true });
  const initTabIndex = 1;
  const { userData } = useAppSelector(getAuthSelector);

  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex);
  const [dealList, setDealList] = useState<DealListItem[]>();
  const [dealCounter, setDealCounter] = useState({
    all: 0,
    inviting: 0,
    processing: 0,
    completed: 0,
    cancelled: 0,
  });

  // 監測selectedTab的變化, 若有變化則重新分類lists的資料
  const lists = useMemo(() => {
    if (!dealList) return <Loading />;
    // 檢查是否有過期的交易
    const list = dealList.map((item) => {
      if (item.progress === '0') {
        if (!item.expiry_date) return { ...item };
        const now = new Date();
        const expired = new Date(item.expiry_date);
        if (now > expired) {
          item.tag = 'cancelled';
        } else {
          item.tag = 'inviting';
        }
      }
      return { ...item };
    });

    setDealCounter(dealStatusCounter(list));
    switch (selectedTab) {
      case 1:
        return list.map((item) => <DealListItem data={item} key={`dealItem-${item.id}`} />);
      case 2:
        return list
          .filter((item) => item.tag === 'inviting')
          .map((item) => <DealListItem data={item} key={`dealItem-${item.id}`} />);
      case 3:
        return list
          .filter((item) => item.tag === 'processing')
          .map((item) => <DealListItem data={item} key={`dealItem-${item.id}`} />);
      case 4:
        return list
          .filter((item) => item.tag === 'completed')
          .map((item) => <DealListItem data={item} key={`dealItem-${item.id}`} />);
      case 5:
        return list
          .filter((item) => item.tag === 'cancelled')
          .map((item) => <DealListItem data={item} key={`dealItem-${item.id}`} />);
      default:
        return list.map((item) => <DealListItem data={item} key={`dealItem-${item.id}`} />);
    }
  }, [dealList, selectedTab]);

  const tabs: TabsType = [
    {
      label: tabLabel[0],
      index: initTabIndex,
      Component: () => lists,
    },
    {
      label: tabLabel[1],
      index: 2,
      Component: () => lists,
    },
    {
      label: tabLabel[2],
      index: 3,
      Component: () => lists,
    },
    {
      label: tabLabel[3],
      index: 4,
      Component: () => lists,
    },
    {
      label: tabLabel[4],
      index: 5,
      Component: () => lists,
    },
  ];

  const initData = useCallback(async () => {
    try {
      const res = (await getDealList()).data;
      const lists: DealListItem[] = res.data.map(
        (data): DealListItem => ({
          id: data.id,
          tag: dealTagStatusHandler(parseInt(data.progress)),
          tradeName: data.name,
          count: {
            participants: data.participants.length,
            events: 0,
          },
          participants: data.participants,
          dealInitiation: [data.progress === '0' ? false : true],
          negotiation: data.negotiation,
          deal_close: data.deal_close,
          contractExecution: data.progress === '99' ? [true, false] : data.progress === '2' ? [true, true] : [false, false],
          executions: data.executions,
          progress: data.progress,
          execution_file_complete: data.excution_file_complete,
          deal_type: '',
          category: data.category,
          expiry_date: data.expiry_date,
          acc_id: data.acc_id,
          deal_type_id: Number(data.deal_type_id),
        })
      );
      setDealList(lists);
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <PageContainer title={t('deal')}>
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        dealCounter={dealCounter}
        onClick={setSelectedTab}
        rightComponent={
          <Button
            variant={'regular'}
            icon={<Add.AddSVG fill={'#fff'} />}
            onClick={() => navigate(`${PATH.deal.initiation}?step=1`)}
          >
            {t('buttons.addNewDeal')}
          </Button>
        }
      />
    </PageContainer>
  );
};

export default Deal;

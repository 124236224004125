import { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  FormHelperText,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import {
  InputWrapper,
  MultiInputWrapper,
  InputFileWrapper,
  InputFileLabel,
} from '@/components/Container/AuthContainer/styles';
import { FormSelect } from '@/components/UI/Select';
import { ErrorMessage, Text } from '@/components/UI/Typography/Text';
import { Flex, LoadingPage } from '@/layout';

import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { convertLink, convertToSelectData } from '@/utils/convert';
import { registrationPersonalInfo } from '@/api/auth.api';
import useCountryDetail from '@/hooks/useCountryDetail';

import { timezoneData, title } from '@/data/select';
import { H4, H5 } from '@/components/UI/Typography/Heading';
import { ImageDefault } from '@/components/UI/Input';
import { CloudUpload } from '@/components/Icon';
import { theme } from '@/theme/Theme';

const PersonalInfo = ({ nextHandler = () => {}, ButtonGroup, ReturnLink = '', userDetail }: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RegistrationPersonalInfoForm>();

  const { inputRegister } = useAuthValidation();

  const updatedAccLogo = watch('acc_logo')?.length !== 0;

  const onSubmit = handleSubmit(async (data) => {
    const reqData: RegistrationPersonalRequest = {
      ...data,
      acc_logo: data.acc_logo?.[0],
    };

    try {
      setIsLoading(true);
      const res = (await registrationPersonalInfo(reqData)).data;
      if (res.success) {
        nextHandler();
        successHandler(t('success.upload'));
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    }
  });

  useEffect(() => {
    if (!userDetail) return;
    setValue('acc_languages', userDetail.acc_languages);
    setValue('acc_timezone', userDetail.acc_timezone);
    setValue('acc_currency', userDetail.acc_currency);
    if (!userDetail.acc_CoRep) return;
    setValue('acc_CoRep', userDetail.acc_CoRep);
    setValue('acc_firstName', userDetail.acc_firstName);
    setValue('acc_jobPosition', userDetail.acc_jobPosition);
    setValue('acc_lastName', userDetail.acc_lastName);
    setValue('acc_title', userDetail.acc_title ? userDetail.acc_title : '');
  }, [userDetail, setValue]);

  return (
    <Paper square elevation={0}>
      <LoadingPage isLoading={isLoading} />
      <form onSubmit={onSubmit} style={{ maxWidth: '930px', padding: '30px 50px' }}>
        {!userData.role && (
          <Box>
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              hidden
              {...register('acc_logo', {
                required: userDetail && userDetail.acc_logo ? false : t('required.input', { input: t('info.companyLogo') }),
              })}
            />
            <label htmlFor="icon-button-file" style={{ display: 'flex', width: 'fit-content', cursor: 'pointer' }}>
              <Box marginRight={'20px'}>
                <H4>Avatar *</H4>
                <H5>JPG、GIF、PNG</H5>
              </Box>
              <Flex flexDirection={'column'} gridGap={'10px'}>
                {userDetail && userDetail.acc_logo && !updatedAccLogo ? (
                  <img height={100} src={userDetail.acc_logo} alt={'acc_logo'} />
                ) : updatedAccLogo ? (
                  <img
                    height={100}
                    src={watch('acc_logo') ? URL.createObjectURL(watch('acc_logo')?.[0] as Blob) : '/images/default_company_logo.jpg'}
                    alt={'acc_logo'}
                  />
                ) : (
                  <ImageDefault />
                )}
                <ErrorMessage marginBottom={20}>{errors.acc_logo?.message}</ErrorMessage>
              </Flex>
            </label>
          </Box>
        )}

        <MultiInputWrapper>
          {/* <FormSelect
            control={control}
            data={convertToSelectData(title)}
            inputLabel={'Title'}
            selectLabel={'acc_title'}
            name={'acc_title'}
            register={inputRegister}
          /> */}
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_firstName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="First Name*"
                variant="standard"
                value={value}
                sx={{ width: '100%', marginLeft: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={inputRegister}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_lastName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Last Name"
                variant="standard"
                value={value}
                sx={{ width: '100%', marginLeft: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
        </MultiInputWrapper>

        <MultiInputWrapper>
          <div style={{ marginRight: '15px', width: '100%' }}>
            <FormSelect
              control={control}
              data={convertToSelectData(timezoneData)}
              inputLabel={'Timezone'}
              selectLabel={'acc_timezone'}
              name={'acc_timezone'}
              register={inputRegister}
            />
          </div>
          <div style={{ marginRight: '15px', width: '100%' }}>
            <FormSelect
              control={control}
              data={useCountryDetail('language')}
              inputLabel={'Languages'}
              selectLabel={'acc_languages'}
              name={'acc_languages'}
              register={inputRegister}
            />
          </div>

          {/* <FormSelect
            control={control}
            data={convertToSelectData(currencyData)}
            inputLabel={'Currency'}
            selectLabel={'acc_currency'}
            name={'acc_currency'}
            register={inputRegister}
          /> */}
        </MultiInputWrapper>

        <InputWrapper>
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_jobPosition'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Job Position"
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={inputRegister}
          />
        </InputWrapper>

        <Controller
          control={control}
          name={'acc_CoRep'}
          rules={inputRegister}
          render={({ field, fieldState: { error } }) => (
            <FormControl style={{ flexDirection: 'row', alignItems: 'center' }}>
              Company representative *
              <RadioGroup row style={{ paddingLeft: '30px' }} {...field} value={field.value || ''}>
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
              <ErrorMessage>{error?.message}</ErrorMessage>
            </FormControl>
          )}
        />

        {ButtonGroup(watch)}
      </form>
    </Paper>
  );
};

export default PersonalInfo;

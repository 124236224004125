import { useTranslation } from 'react-i18next';
import { CardTitle, CardWrapper, CatalogWrapper, IntroWrapper } from '../style';
import useResize from '@/hooks/useResize';

export default function Catalog() {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile } = useResize();
  const data = t('pages.home.cataloge', { returnObjects: true });

  return (
    <CatalogWrapper isMobile={isMobile}>
      {data.map((item: any, index: number) => (
        <CardWrapper key={index} style={{ flexBasis: 'calc(50% - 7.5px)', padding: '25px 20px' }}>
          <CardTitle>
            <img src={`/images/home${item.icon}`} alt="" /> {item.name}
          </CardTitle>
          <IntroWrapper>{item.text}</IntroWrapper>
        </CardWrapper>
      ))}
    </CatalogWrapper>
  );
}

import { axios } from '@/utils/axios';

export const getChatList = () => {
  return axios.get<{ data: ChatList[] }>('/api/chatrooms');
};

export const getChatDetail = (id: number) => {
  return axios.get<{ data: ChatMessage[] }>(`/api/chatrooms/${id}`);
};

export const sendMessageToChatroom = (id: number, data: { message: string }) => {
  return axios.post(`/api/chatrooms/${id}`, data);
};

export const generateChatToUser = (data: { user_id: number }) => {
  return axios.post(`/api/chatrooms/generate`, data);
};

export const getDealChatroom = (id: string) => {
  return axios.get<{ data: ChatList }>(`/api/chatrooms/getDealChatroom/${id}`);
};
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { Box, Paper } from '@mui/material';

import PageContainer from '@/components/Container/PageContainer';
import RegistrationStepLine from '@/components/RegistrationStepLine';
import CreditReport from '@/components/Account/CreditReport';
import ECert from '@/components/Account/E-Cert';
import SupplementalInfo from '@/components/Account/SupplementalInfo&LEI';
import CompanyInfo from '@/components/Account/CompanyInfo';
import PersonalInfo from '@/components/Account/PersonalInfo';
import IdentificationCheck from '@/components/Account/IdentificationCheck';
import SubscriptionPlan from '@/components/Account/SubscriptionPlan';
import { Button } from '@/components/UI/Button';
import RegisterForm from '@/components/Account/RegisterForm';

import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useUserDetail from '@/hooks/useUserDetail';
import PreviewApplicationInfo from '@/components/Account/PreviewApplicationInfo';
import Completed from '@/components/Account/Completed';
import AdSection from './AdScetion';
import UpdateAccount from '@/components/Account/UpdateAccount';
import { Flex } from '@/layout';
import useResize from '@/hooks/useResize';
import { removeTempData, saveTempData } from '@/utils/localStorageSave';
import { successHandler } from '@/utils/toastHandler';

const Registration = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const stepLine = t('pages.auth.registrationStepLine', { returnObjects: true });
  const [stepsLine, setStepsLine] = useState<string[]>(stepLine);
  const auth = useAppSelector(getAuthSelector);

  // 確認步驟用
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step'));
  const [activeStep, setActiveStep] = useState<number>(0);
  const [previewing, setPreviewing] = useState<boolean>(false);
  const navigate = useNavigate();
  const userDetail = useUserDetail({ activeStep: activeStep });
  const [readOnly, setReadOnly] = useState<boolean>(false);

  // 每次步驟切換進行回到最頂層
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  // 更改標題 控制顯示的表單
  const [title, setTitle] = useState('');

  // 確認若切換語系則一同切換步驟條的語系
  useEffect(() => {
    setStepsLine(stepLine);
    setTitle(stepLine[activeStep]);
  }, [t]);

  // 進入時確認是否有帶query和確認query是否在正確範圍內
  // setTitle控制畫面
  // setActive控制步驟條, 需判斷使用者role來取得下一步或上一步的標題
  // setSearch控制網址紀錄步驟
  useEffect(() => {
    if (!currentStep || parseInt(currentStep) > stepLine.length || parseInt(currentStep) === 0) {
      setCurrentStep('1');
      navigate('/registration?step=1');
      setActiveStep(0);
      setTitle(stepLine[0]);

      if (auth.userData.role !== null && auth.userData.role === 0) {
        setStepsLine(stepLine);
      } else {
        setStepsLine(stepLine);
      }
    } else {
      const paramsStep = parseInt(currentStep);

      if (auth.userData.role !== null && auth.userData.role === 0) {
        setActiveStep(paramsStep - 1);
        setTitle(stepLine[paramsStep - 1]);
        setStepsLine(stepLine);
      } else {
        setActiveStep(paramsStep - 1);
        setTitle(stepLine[paramsStep - 1]);
      }
    }
  }, []);

  // 使用者role(type為數字, 預設應為1)
  const role = 1;

  // 往前的方法: 拿title去判斷陣列前一個索引值在Registration中的哪
  const prevHandler = () => {
    const index = stepLine.findIndex((item) => item === title);
    const prevIndex = index - 1;
    const prevStep = index;

    setActiveStep(prevIndex);
    setSearchParams(
      {
        step: prevStep.toString(),
      },
      { replace: true }
    );

    if (prevIndex === 0) {
      setTitle(stepLine[prevIndex]);
    } else {
      if (role) {
        setTitle(stepLine[prevIndex]);
      } else {
        setTitle(stepLine[prevIndex]);
      }
    }
  };

  const nextHandler = () => {
    const index = stepLine.findIndex((item) => item === title);
    const nextIndex = index + 1;
    const nextStep = index + 2;

    if (nextStep === 4) {
      setPreviewing(true);
    }

    setActiveStep(nextIndex);
    setTitle(stepLine[nextIndex]);
    setSearchParams(
      {
        step: nextStep.toString(),
      },
      { replace: true }
    );
  };

  const backToPrevious = (page: number) => {
    setActiveStep(page);
    setSearchParams(
      {
        step: (page + 1).toString(),
      },
      { replace: true }
    );
    setTitle(stepLine[page]);
  };

  // 各頁面要帶入的按鈕樣式
  const ButtonGroup = (watch: Object) => {
    return (
      <Flex paddingTop={22} gridGap={isMobile ? '15px' : '40px'} flexDirection={isMobile ? 'column' : 'row'}>
        <Button width={isMobile ? '100%' : 'unset'} size={'lg'} variant={'primary'} rounded={false}>
          {t('buttons.next')}
        </Button>
        {activeStep !== 1 && (
          <Button
            width={isMobile ? '100%' : 'unset'}
            size={'lg'}
            variant="primary-invert"
            onClick={(e) => {
              e.preventDefault();
              removeTempData();
              prevHandler();
            }}
          >
            {t('buttons.back')}
          </Button>
        )}

        <Button
          type={'button'}
          size={'lg'}
          variant={'primary'}
          rounded={false}
          width={isMobile ? '100%' : 'unset'}
          onClick={() => {
            saveTempData(watch);
            successHandler(t('success.saved'));
          }}
        >
          {t('buttons.save')}
        </Button>

        {/* <Button
        size={'lg'}
        onClick={(e) => {
          e.preventDefault();
          nextHandler();
        }}
      >
        Skip
      </Button> */}
      </Flex>
    );
  };

  const PreviewdButtonGroup = (watch: Object) => {
    
    return (
      <Flex paddingTop={22} gridGap={isMobile ? '15px' : '40px'} flexDirection={isMobile ? 'column' : 'row'}>
      <Button width={isMobile ? '100%' : 'unset'} size={'lg'} variant={'primary'} rounded={false}>
        {t('buttons.submit')}
      </Button>
      {activeStep !== 1 && (
        <Button
          width={isMobile ? '100%' : 'unset'}
          size={'lg'}
          variant="primary-invert"
          onClick={(e) => {
            e.preventDefault();
            removeTempData();
            prevHandler();
          }}
        >
          {t('buttons.back')}
        </Button>
      )}
    </Flex>
    )
  };

  const CompletedButtonGroup = (watch: Object) => (
    <Box style={{ textAlign: 'center' }}>
      <Link to={'/home'} style={{ color: '#6D83C4', textDecoration: 'underline' }}>
        {t('buttons.backToDashboard')}
      </Link>
    </Box>
  );

  return (
    <PageContainer title={title}>
      <Flex flexDirection={isTablet ? 'column' : 'row'}>
        <RegistrationStepLine activeStep={activeStep} stepsLine={stepsLine} />

        {/* 初次登入時確認該頁中必填資料並沒有被填入時顯示此頁 */}
        {activeStep === 0 && !previewing && (
          <RegisterForm
            setActiveStep={setActiveStep}
            setTitle={setTitle}
            setCurrentStep={setCurrentStep}
            type={'regist'}
            setReadOnly={setReadOnly}
          />
        )}

        {/* 再次進入時, 頁中必填資料已被填過則顯示此頁 */}
        {activeStep === 0 && previewing && (
          <UpdateAccount setActiveStep={setActiveStep} setTitle={setTitle} setCurrentStep={setCurrentStep} />
        )}

        {activeStep === 1 && (
          <CompanyInfo
            readOnly={readOnly}
            nextHandler={nextHandler}
            ButtonGroup={ButtonGroup}
            userDetail={userDetail}
          />
        )}

        {/* {title === 'Personal Info' && (
          <PersonalInfo nextHandler={nextHandler} ButtonGroup={ButtonGroup} userDetail={userDetail} />
        )} */}

        {/* {title === 'Identification check' && (
          <IdentificationCheck nextHandler={nextHandler} ButtonGroup={IdentificationButtonGroup} />
        )} */}

        {activeStep === 2 && (
          <SupplementalInfo nextHandler={nextHandler} ButtonGroup={ButtonGroup} userDetail={userDetail} />
        )}

        {activeStep === 3 && (
          <PreviewApplicationInfo
            backToPrevious={backToPrevious}
            nextHandler={nextHandler}
            ButtonGroup={PreviewdButtonGroup}
            userDetail={userDetail}
          />
        )}

        {activeStep === 4 && <Completed ButtonGroup={CompletedButtonGroup} userDetail={userDetail} />}

        {/* {title === 'e-Cert' && <ECert nextHandler={nextHandler} ButtonGroup={ButtonGroup} userDetail={userDetail} />} */}

        {/* {title === 'Credit report' && (
          <CreditReport nextHandler={nextHandler} ButtonGroup={ButtonGroup} userDetail={userDetail} />
        )} */}

        {/* {title === 'Subscription plan' && <SubscriptionPlan ButtonGroup={SubscriptionButtonGroup} />} */}
      </Flex>
    </PageContainer>
  );
};

export default Registration;

import PageContainer from '@/components/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import CompanyInfo from '@/components/Account/CompanyInfo';
import { Box, Button as DefaultBtn } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../style';
import useUserDetail from '@/hooks/useUserDetail';
import usePermissionCheck from '@/hooks/usePermissionCheck';

const CompanyInfoEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userDetail = useUserDetail({});
  const { isMainAccount } = usePermissionCheck();

  const ButtonGroup = (watch: Object) => (
    <Box style={{ marginTop: 30 }}>
      {isMainAccount && <Button>{t('buttons.submit')}</Button>}
      <DefaultBtn
        type="button"
        color="primary"
        onClick={() => navigate('/profile/edit')}
        style={{ fontSize: 20, color: '#0B319D', padding: '1px 40px', border: '1px solid #0B319D', marginLeft: 15 }}
      >
        {t('buttons.back')}
      </DefaultBtn>
    </Box>
  );

  return (
    <PageContainer title={t('companyInformation')}>
      <div style={{ display: 'flex' }}>
        <CompanyInfo ButtonGroup={ButtonGroup} userDetail={userDetail} />
      </div>
    </PageContainer>
  );
};

export default CompanyInfoEdit;

import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import type { Store } from '@/redux/store';
import { signIn, signOut } from '@/redux/auth/auth.slice';
import { disconnect } from '@/redux/notification/notification.slice';
import { refreshToken } from '@/api/auth.api';

export const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const axiosInterceptor = (store: Store) => {
  const requestBefore = (config: AxiosRequestConfig): AxiosRequestConfig => {
    // Make sure headers is always defined
    config.headers = config.headers || {};

    if (store.getState().auth.isLogin && store.getState().auth.userData && config.headers) {
      const accessToken = `Bearer ${store.getState().auth.accessToken}`;
      config.headers.Authorization = accessToken;
    }

    return config;
  };

  const responseError = async (error: AxiosInterceptorError): Promise<AxiosError> => {
    const originRequest = error.config;
    if (originRequest._retry === undefined) {
      originRequest._retry = false;
    }
    const isStatus401 = error.response.status === 401;
    const isStatus500 = error.response.status === 500;

    if (isStatus500 && !originRequest._retry) {
    }

    if (isStatus401 && !originRequest._retry) {
      const token = store.getState().auth.accessToken;
      if (token) {
        // 刷新令牌的逻辑
        try {
          // 发送刷新令牌请求，获取新的令牌
          const res = await refreshToken(token);
          // 更新令牌
          store.dispatch(signIn({ ...store.getState().auth, isLogin: true, accessToken: res.data.data.access_token }));
          originRequest.headers['Authorization'] = `Bearer ${res.data.data.accessToken}`;

          // 重新发送原始请求
          return axios(originRequest);
        } catch (refreshError) {
          // 刷新令牌失败，根据需求处理
          console.error('刷新令牌失败', refreshError);
          // 清除令牌、登出等处理逻辑
          store.dispatch(signOut());
          store.dispatch(disconnect());
          // 一秒後再跳轉
          setTimeout(() => {
            const currentPath = window.location.pathname;
            window.location.href = '/auth/signin?path=' + currentPath;
          }, 500);
        }
      } else {
        store.dispatch(signOut());
        store.dispatch(disconnect());

        // 檢當前路徑若為/auth/signin則不跳轉
        setTimeout(() => {
          const currentPath = window.location.pathname;
          if (currentPath !== '/auth/signin') {
            window.location.href = '/auth/signin';
          }
        }, 500);
      }
    }
    // if (isStatus401 && !originRequest._retry) {
    //   store.dispatch(signIn({ userData: {}, isLogin: false, accessToken: '' }));
    //   store.dispatch(disconnect());

    //   // 檢當前路徑若為/auth/signin則不跳轉
    //   const currentPath = window.location.pathname;
    //   if (currentPath !== '/auth/signin') {
    //     window.location.replace('/auth/signin?path=' + currentPath);
    //   }
    // }
    return Promise.reject(error);
  };
  axios.interceptors.request.use(requestBefore as any);
  axios.interceptors.response.use(undefined, responseError);
};

export async function axiosFetch<T>(axiosConfig: AxiosConfig) {
  const response: AxiosResponse<T> = {
    res: {
      success: false,
      message: '',
      data: {},
    },
  };

  const { axiosInstance, method, url, requestConfig = {} } = axiosConfig;
  try {
    const res = await axiosInstance[method.toLowerCase()](url, requestConfig.data);
    response.res = res.data;
  } catch (err: any) {
    console.log('err: ', err.response);
    response.res = err.response.data;
  } finally {
    return response;
  }
}

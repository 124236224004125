import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UploadBankCard = () => {
  const { dealRole, dealDocumentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/sign/uploadBankCard.html?dealRole=${dealRole}&dealDocumentId=${dealDocumentId}`);
  }, []);

  return <div>redirecting....</div>;
};

export default UploadBankCard;

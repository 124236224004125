import styled from 'styled-components';

const SearchInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const IconWrapper = styled.button`
  position: absolute;
  right: 15px;
  top: 5px;
  cursor: pointer;
  opacity: 0.7;
  
  &:hover {
    opacity: 1;
  }
`;

export { SearchInputWrapper, IconWrapper };

import styled from 'styled-components';

const SideWrapper = styled.aside`
  width: 50%;
  max-width: 375px;
  min-width: 260px;
  background: ${(p) => p.theme.colors.light};
  border-radius: 15px 15px 15px 0px;
  ${(p) => p.theme.outsideShadowW02};
  height: fit-content;
`;

const AccountInfo = styled.div`
  text-align: center;
  padding: 25px;

  > .avatar {
    width: 80%;
    margin: 0 auto;
    display: flex;
    background: ${(p) => p.theme.colors.secondary300};

    img {
      width: 100%;
    }
  }
`;

const BadgeGroup = styled.div`
  display: flex;
  gap: 10px;
  margin: 30px 0 15px;
`;

const StarsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-top: 1px solid ${(p) => p.theme.colors.gray100};
  border-bottom: 1px solid ${(p) => p.theme.colors.gray100};
  margin-bottom: 30px;

  span {
    color: ${(p) => p.theme.colors.gray400};
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 100%;
  }
`;

const Hr = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.gray100};
  margin: 15px 0;
`;

const ComapnyInfoWrapper = styled.div<{ isTablet: boolean }>`
  .col {
    display: flex;
    text-align: left;
    margin-bottom: 10px;

    .title {
      width: ${(p) => (p.isTablet ? '50%' : '120px')};
      color: ${(p) => p.theme.colors.gray400};
    }

    .content {
      flex: 1;
      color: ${(p) => p.theme.colors.gray500};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MenuList = styled.div`
  > a h4 {
    border-top: 1px solid ${(p) => p.theme.colors.gray100};
    padding: 15px 0 12px;
    text-align: center;
    color: ${(p) => p.theme.colors.gray300};

    &:hover {
      background: ${(p) => p.theme.colors.gray100};
      color: ${(p) => p.theme.colors.secondary500};
    }
  }
`;

export { Hr, ComapnyInfoWrapper, StarsGroup, SideWrapper, AccountInfo, BadgeGroup, MenuList };

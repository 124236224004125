import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import Tabs from '@/components/UI/Tabs';

import { Card } from '@/layout';
import CurrentFilesList from './CurrentFilesList';
import ContractTemplatesList from './ContractTemplatesList';
import { Text } from '@/components/UI/Typography/Text';

interface DetailFilesPageProps {
  sendInvite: (id: string) => void;
  progress: string;
}

const DetailFilesPage = ({ sendInvite, progress }: DetailFilesPageProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();

  const tabLabel = t('pages.dealDetail.manageFiles.tabs', { returnObjects: true });
  const tips = t('pages.dealDetail.manageFiles.tips', { returnObjects: true });
  const initTabIndex = new URLSearchParams(search).get('tab');

  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex ? parseInt(initTabIndex) : 1);

  const tabs: TabsType = useMemo(() => [
    {
      label: `${tabLabel[0]}`,
      index: 1,
      Component: () => <CurrentFilesList id={id} sendInvite={sendInvite} progress={progress} />,
    },
    {
      label: `${tabLabel[1]}`,
      index: 2,
      Component: () => <ContractTemplatesList id={id} />,
    },
    // {
    //   label: tabLabel[2],
    //   index: 3,
    //   Component: () => <MyFolder id={id} />,
    // },
  ], [t]);

  return <Tabs fullWidth={true} tabs={tabs} selectedTab={selectedTab} onClick={setSelectedTab} />;
};

export default DetailFilesPage;

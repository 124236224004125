import { SpinningCircles } from 'react-loading-icons';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/layout';
import { Text } from '../Typography/Text';

import { theme } from '@/theme/Theme';

const Loading = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gridGap={'10px'} height={'300px'}>
      <SpinningCircles hanging={'2rem'} stroke={theme.colors.secondary500} fill={theme.colors.secondary200} />
      <Text fontSize={theme.fontSizes.md}>{t('loading')}</Text>
    </Flex>
  );
};

export default Loading;

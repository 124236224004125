import styled from 'styled-components';

const StyledCheckoutBox = styled.label`
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  font-size: 22px;
  user-select: none;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    :checked ~ .checkmark {
      background-color: ${(p) => p.theme.colors.secondary500};
    }
    :checked ~ .checkmark:after {
      display: block;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 50%;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid ${(p) => p.theme.colors.gray300};
    :after {
      content: '';
      position: absolute;
      display: none;
    }
    :after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  :hover input ~ .checkmark {
    background-color: #ccc;
  }
`;

export { StyledCheckoutBox };

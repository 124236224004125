import { useEffect, useMemo, useCallback, useState } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';

import DataTable from '@/components/DataTable';
import { CardContainer } from '@/layout/Card';
import { getDealCompanyList, inviteCompany } from '@/api/deal.api';
import { convertLink, convertToBadgeLevel } from '@/utils/convert';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import { useParams } from 'react-router-dom';
import { errorHandler, successHandler } from '@/utils/toastHandler';

interface OtherProps {
  invitedParticipantList?: DealInvitedCompany[];
  setInvitedParticipantList: React.Dispatch<React.SetStateAction<DealInvitedCompany[] | undefined>>;
}

const Other = ({ invitedParticipantList, setInvitedParticipantList }: OtherProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();
  const [companyList, setCompanyList] = useState<DealListOtherParticipantsTable[]>([]);

  const rawColumn = t('pages.dealDetail.manageParticipants.otherTable.columns', { returnObjects: true });
  const columns: ColumnDef<DealListOtherParticipantsTable>[] = useMemo(
    () => [
      {
        accessorKey: 'photo',
        header: rawColumn[0],
        cell: ({ row }) => <Avatar src={row.original.photo} />,
      },
      {
        accessorKey: 'name',
        header: rawColumn[1],
        sortable: true,
        cell: ({ row }) => <div>{row.original.name}</div>,
      },
      {
        accessorKey: 'badge',
        header: rawColumn[2],
        cell: ({ row }) => <div>{row.original.badge}</div>,
      },
      {
        accessorKey: 'email',
        header: rawColumn[3],
        cell: ({ row }) => <div>{row.original.email}</div>,
      },
      {
        accessorKey: 'languages',
        header: rawColumn[4],
        cell: ({ row }) => <div>{row.original.languages}</div>,
      },
      {
        accessorKey: 'jobPosition',
        header: rawColumn[5],
        cell: ({ row }) => <div>{row.original.jobPosition}</div>,
      },
      {
        accessorKey: 'action',
        header: rawColumn[6],
        cell: ({ row }) => {
          const invited = invitedParticipantList
            ? invitedParticipantList.some((company) => company.acc_id.toString() === row.original.id)
            : false;
          return (
            <Button
              variant={invited ? 'normal' : 'primary'}
              size={'table'}
              disabled={invited}
              onClick={async () => {
                if (!id) return;
                try {
                  const res = (await inviteCompany({ acc_id: row.original.id, deal_id: id })).data;
                  setInvitedParticipantList(res.data.company);
                  successHandler(t('success.invite'));
                } catch (error: any) {
                  errorHandler(error.response.data.data);
                }
              }}
            >
              {invited ? t('buttons.inviting') : t('buttons.invite')}
            </Button>
          );
        },
      },
    ],
    [rawColumn]
  );

  const getCompanyList = useCallback(async () => {
    try {
      const res = (await getDealCompanyList()).data;

      const data: DealListOtherParticipantsTable[] = res.data.map((item) => ({
        id: item.id.toString(),
        photo: item.acc_logo,
        name: item.name,
        badge: convertToBadgeLevel(item.verification),
        email: item.email,
        languages: item.acc_languages,
        jobPosition: item.acc_jobPosition,
        action: '',
      }));
      setCompanyList(data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getCompanyList();
  }, []);

  return (
    <CardContainer>
      {companyList.length !== 0 ? <DataTable columns={columns} data={companyList} canSearch /> : <Loading />}
    </CardContainer>
  );
};

export default Other;

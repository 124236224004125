import { getDocTypeList } from "@/api/type/dealType.api";

const docType = async () => {
	let options: SelectOptions = [];
  try {
    const res = (await getDocTypeList()).data;
    options = res.data.map((item) => ({
      value: item,
      text: item,
    }));
  } catch (error) {}
  return options;
};

export default docType;

import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.gray400,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.gray400,
  },
}));

export default ArrowTooltip;

export const chinaMapConfig = (configData) => {
  const { data, max, min, title, mapData } = configData;


  const mapDataList = mapData.map((item, index) => (
    {
      name: item.value,
      type: 'map',
      mapType: 'china',
      coordinateSystem: 'geo',
      geoIndex: index,
      // 地图,可以是数组，多个
      label: {
        show: true, //显示省市名称
        position: [1, 100], // 相对的百分比
        fontSize: 14,
        offset: [0,0],
        align: 'left',
      },
      itemStyle: {
        areaColor: '#fff', // 地图图形颜色
      },
      roam: false,
      selectedMode: 'multiple', // 选中模式，multiple多选，single单选
      map: 'china',
      zoom: 1.7, // 当前视角的缩放比例
      scaleLimit: {
        max: 2,
        min: 1, // 设置默认缩放效果
      },
      top: '30%', // 距离顶部距离
      select: {
        label: {
          color: '#fff',
          backgroundColor: '#A4D233'
        },
        itemStyle: {
          color: '#A4D233',
        }
      },
      emphasis: {
        label: {
          color: '#fff',
          backgroundColor: '#A4D233'
        },
        itemStyle: {
          areaColor: '#A4D233',
        }
      } 
    }
  ))

  return {
    title: {
      // 标题组件
      text: title,
      left: 'center',
      textStyle: {
        fontSize: 32,
        color: '#fff',
      },
    },
    silent: false,
    visualMap: {
      show: false
    },
    series: mapDataList
  };
};

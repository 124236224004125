const categorySc = [
  "法律服務",
  "影視行業",
  "建築工程",
  "金融及保險",
  "資訊科技",
  "製造業",
  "專業或商業服務",
];

export default categorySc;

import { useTranslation } from 'react-i18next';
import MemberAccountEdit from '@/components/Account/MemberAccountEdit';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';

const MembersEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <PageContainer
      title={<InnerPageTitle link={'/profile/addNewMembers'} title={t('buttons.home')} subtitle={t('pages.home.addNewMembers.membersEdit')} />}
    >
      <MemberAccountEdit />
    </PageContainer>
  );
};

export default MembersEdit;

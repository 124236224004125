import { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import { ChartsWrapper, DealsWrapper, Hr, PlanWrapper, Title } from './style';
import { Box, Flex } from '@/layout';
import { Deals, Documents, Members, Sub } from '@/components/Icon';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { Button } from '@/components/UI/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDealStatistical } from '@/api/deal.api';
import { errorHandler } from '@/utils/toastHandler';
import Loading from '@/components/UI/Loading';
import { getMembers, getStorageSpace } from '@/api/auth.api';
import { convertToFileSize } from '@/utils/convert';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import useCurrentLang from '@/hooks/useCurrentLang';
import { planFeatures } from './planFeatures';
import useUserDetail from '@/hooks/useUserDetail';
import useResize from '@/hooks/useResize';
import { Check } from '@/components/Icon';

interface DealsProps {
  total: number;
  progress: number;
  completed: number;
  cancelled: number;
}

const DealsChart = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [data, setData] = useState<DealsProps>();
  const { isMobile, isTablet } = useResize();

  const initData = useCallback(async () => {
    try {
      const res = (await getDealStatistical()).data;
      if (res.success) {
        setData(res.data);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <Box width={isTablet ? '100%' : 350}>
      <ChartsWrapper isMobile={isMobile}>
        {!data && <Loading />}
        {data && (
          <div>
            <Title>
              <Deals.DealsSVG />
              <div>{t('pages.home.dashboard.deals.title')}</div>
            </Title>
            <DealsWrapper>
              <div className="number">{data.total}</div>
              <div className="unit">{t('pages.home.dashboard.deals.total')}</div>
            </DealsWrapper>
            <Hr borderColor={theme.colors.gray100} margin={15} />
            <Flex gridGap={30} justifyContent={'center'}>
              <DealsWrapper>
                <div className="number">{data.progress}</div>
                <div className="unit">{t('pages.home.dashboard.deals.progress')}</div>
              </DealsWrapper>
              <DealsWrapper>
                <div className="number">{data.completed}</div>
                <div className="unit">{t('pages.home.dashboard.deals.completed')}</div>
              </DealsWrapper>
              <DealsWrapper>
                <div className="number">{data.cancelled}</div>
                <div className="unit">{t('pages.home.dashboard.deals.cancelled')}</div>
              </DealsWrapper>
            </Flex>
          </div>
        )}
      </ChartsWrapper>
    </Box>
  );
};

const MembersChart = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [data, setData] = useState<{ members: number }>();
  const { userData } = useAppSelector(getAuthSelector);
  const { isMobile, isTablet } = useResize();

  const initData = useCallback(async () => {
    try {
      const res = (await getMembers()).data;
      if (res.success) {
        setData(res.data);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <Box width={isTablet ? '100%' : 350}>
      <ChartsWrapper isMobile={isMobile}>
        {!data && <Loading />}
        {data && (
          <div>
            <Title>
              <Members.MembersSVG />
              <div>{t('pages.home.dashboard.members.title')}</div>
            </Title>
            <DealsWrapper>
              <div className="number">{data.members}</div>
              <div className="unit">{t('pages.home.dashboard.members.people')}</div>
            </DealsWrapper>
            {/* {userData.main_account_company_info.isMainAccount && (
              <Flex gridGap={'10px'} marginTop={30}>
                <Link to={'/profile/addNewMembers'} style={{ width: '100%', display: 'flex' }}>
                  <Button size={'half'} variant={'primary'}>
                    {t('buttons.add')}
                  </Button>
                </Link>
                <Link to={'/profile/addNewMembers'} style={{ width: '100%', display: 'flex' }}>
                  <Button size={'half'} variant={'primary-invert'}>
                    {t('buttons.management')}
                  </Button>
                </Link>
              </Flex>
            )} */}
          </div>
        )}
      </ChartsWrapper>
    </Box>
  );
};

interface PlanFeaturesProps {
  title: string;
  features: {
    name: string;
    times: string;
    key: string;
    check: boolean;
    allowIcon: boolean;
  }[];
}

const SubscriptionChart = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const { userData } = useAppSelector(getAuthSelector);
  const [data, setData] = useState<PlanFeaturesProps>();
  const { isMobile, isTablet } = useResize();

  const userDetail = useUserDetail({});

  const initData = () => {
    // 檢查userData.plan.level是否為null, 若為0則抓plan[lang][0]的資料
    if (!userData) return;

    if (userData.plan.level === null) {
      setData(planFeatures[lang][0]);
      return;
    }

    setData(checkFeaturesTimes(planFeatures[lang][userData.plan.level + 1]));
  };

  const checkFeaturesTimes = (plan: PlanFeaturesProps) => {
    // 檢查是否有需要確認次數的項目
    plan.features.forEach((item) => {
      if (item.check) {
        // interface UserFeature {
        //   [key: string]: any;
        // }
        // if (!userData.user_feature) return;
        const userFeatureTimes: UserFeature = userData.user_feature;
        // item.times = userFeatureTimes[item.key].toString();
        if (!userFeatureTimes) return;
        if (userData.acc_countryCode === '+86') {
          item.times = userFeatureTimes['ESignaturePRCTimes'].toString();
        }

        if (userData.acc_countryCode !== '+86') {
          item.times = userFeatureTimes['ESignatureHKTimes'].toString();
        }
      }
    });
    return plan;
  };

  useEffect(() => {
    initData();
  }, [userDetail, t]);

  return (
    <Box width={isTablet ? '100%' : 415}>
      <ChartsWrapper isMobile={isMobile}>
        {!data && <Loading />}
        {data && (
          <div>
            <Title>
              <Flex alignItems={'center'}>
                <Sub.SubSVG />
                {t('pages.home.dashboard.subscription.title')}
              </Flex>
              <Link to={'/profile/subscriptionDetail'} className="plan">
                {data.title}
              </Link>
            </Title>
            {data.features.map((item, index) => (
              <PlanWrapper key={index}>
                <div className="item">
                  <div className="name">
                    {item.name}
                    {item.key !== '' && isNaN(parseInt(item.times)) && (
                      <Text color={theme.colors.gray500} fontSize={theme.fontSizes.sm}>
                        {item.key}
                      </Text>
                    )}
                  </div>
                  <div className="times">
                    {item.check ? (
                      item.times
                    ) : item.allowIcon ? (
                      <Check.CheckSVG width={22} height={22} fill={theme.colors.secondary500} />
                    ) : (
                      <Check.CancelSVG fill={theme.colors.red} />
                    )}
                  </div>
                </div>
              </PlanWrapper>
            ))}
            <Text marginTop={'15px'} color={theme.colors.secondary500} textAlign={'center'}>
              {t('info.validUntil')}：{' '}
              {userData.plan.expiration_date ? new Date(userData.plan.expiration_date).toLocaleDateString() : '-'}
            </Text>

            <Flex gridGap={'10px'} marginTop={'10px'}>
              <Link to={'/profile/subscriptionDetail'} style={{ width: '100%', display: 'flex' }}>
                <Button size={'half'} variant={'primary-invert'}>
                  {t('buttons.morePricingPackage')}
                </Button>
              </Link>
            </Flex>
          </div>
        )}
      </ChartsWrapper>
    </Box>
  );
};

const DocumentsChart = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [data, setData] = useState<{ total: number; remaining: number; used: number }>();
  const ref = useRef(null);
  let mapInstance: echarts.ECharts | null = null;
  const { isMobile, isTablet } = useResize();

  const initData = async () => {
    try {
      const res = (await getStorageSpace()).data;
      if (res.success) {
        setData(res.data);
      }
    } catch (error) {}
  };

  const renderChart = (remaining: number, used: number) => {
    if (!ref.current) return;
    const renderedMapInstance = echarts.getInstanceByDom(ref.current);
    if (renderedMapInstance) {
      mapInstance = renderedMapInstance;
    } else {
      mapInstance = echarts.init(ref.current);
    }
    mapInstance.setOption({
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      color: [`${theme.colors.secondary500}`, `${theme.colors.primary400}`],
      zoom: 3,
      series: [
        {
          startAngle: 15,
          type: 'pie',
          radius: '60%',
          label: {
            show: true,
            formatter(param: { value: string; name: string }) {
              if (parseInt(param.value) / 1024 > 1) return (parseInt(param.value) / 1024).toFixed(2) + 'GB';
              return param.value + 'MB';
            },
          },
          data: [
            {
              value: converToMB(remaining).toFixed(2),
              name: t('pages.home.dashboard.documents.remaining'),
            },
            { value: converToMB(used).toFixed(2), name: t('pages.home.dashboard.documents.used') },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    });
  };

  const converToMB = (value: number) => {
    return Math.round(value / 1024 / 1024);
  };

  useEffect(() => {
    initData();
  }, [t]);

  useEffect(() => {
    if (data) {
      renderChart(data.remaining, data.used);
    }
  }, [data]);

  return (
    <Box width={isTablet ? '100%' : 422.5}>
      <ChartsWrapper isMobile={isMobile}>
        {!data && <Loading />}
        {data && (
          <div>
            <Title>
              <Documents.DocumentsSVG />
              <div>{t('pages.home.dashboard.documents.title')}</div>
            </Title>
            <Text marginTop={20} fontSize={theme.fontSizes.base}>
              {t('pages.home.dashboard.documents.storage')}: {convertToFileSize(data.used)} /{' '}
              {convertToFileSize(data.total)}
            </Text>
            <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center' }} ref={ref}></div>
          </div>
        )}
        {/* <Flex gridGap={'10px'}>
          <Link to={'#'} style={{ width: '100%', display: 'flex' }}>
            <Button size={'half'} variant={'primary-invert'}>
              Get more storage
            </Button>
          </Link>
        </Flex> */}
      </ChartsWrapper>
    </Box>
  );
};

export { DealsChart, MembersChart, SubscriptionChart, DocumentsChart };

import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得列表
 */
export const getOrderList = async () => {
  return axios.get<{ data: OrdersListResponse[] }>(`/api/orders`);
};

/**
 * ANCHOR 取得列表
 */
export const getOrderDetail = async (id: string) => {
  return axios.get(`/api/orders/${id}`);
};

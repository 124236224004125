import { axios } from '@/utils/axios';

// 確認是否有可用的優惠券
export const couponCheck = (data: { code: string }) => {
  return axios.post('/api/coupon/check', data);
};

// 送出優惠券
export const couponUse = (data: { code: string }) => {
  return axios.post('/api/coupon/use', data);
};

// 取得優惠券歷史
export const couponHistory = () => {
  return axios.get('/api/coupon/history');
};

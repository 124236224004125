import styled from 'styled-components';

const Title = styled.div<{ isTablet: boolean }>`
  padding-top: ${(p) => (p.isTablet ? '30px' : '100px')};
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  line-height: 70.31px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 68px;
`;

const ContainerWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  max-width: 1491px;
  padding: ${(p) => (p.isMobile ? '0 0 80px' : p.isTablet ? '0 20px 100px' : '0 0 100px 400px')}};
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

const Image = styled.img<{ isTablet: boolean }>`
  position: ${(p) => (p.isTablet ? 'relative' : 'absolute')};
  ${(p) =>
    !p.isTablet
      ? `
    top: calc(50% - 75px);
    left: 0;
    transform: translateY(-50%);
    `
      : `
    margin-bottom: 20px;
  `}
  width: 100%;
  max-width: 600px;
  border-radius: 40px 40px 40px 0px;
  object-fit: cover;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const AboutWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  background-color: #ddeef5;
  padding: ${(p) => (p.isMobile ? '40px 20px' : p.isTablet ? '60px 30px' : '65px 96px 65px 270px')};
  border-radius: 40px 40px 40px 0px;
`;

const Description = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 40px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 50px;
`;

const More = styled.button`
  width: 170px;
  height: 50px;
  background-color: ${(p) => p.theme.colors.primary500};
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 11px;
  color: ${(p) => p.theme.colors.light};
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  line-height: 23px;
  cursor: pointer;

  :hover {
    ::after {
      margin-left: 9px;
      margin-right: 0px;
    }
  }

  ::after {
    content: '';
    background-image: url('/images/index/right-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 32px;
    height: 32px;
    margin-left: 0px;
    margin-right: 9px;
    transition: 0.3s;
  }
`;

export { ContainerWrapper, Image, AboutWrapper, Description, More, Title };

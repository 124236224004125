import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContainer from '@/components/Container/AuthContainer';
import { Step1, Step2 } from './FormStepper';

const SingInPage = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [steps, setSteps] = useState(1);
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    token: '',
  });

  return (
    <AuthContainer title={t('signIn')}>
      {steps === 1 && <Step1 setSteps={setSteps} setLoginData={setLoginData} />}
      {steps === 2 && <Step2 loginData={loginData} />}
    </AuthContainer>
  );
};

export default SingInPage;

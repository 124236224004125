import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import UploadedFile from './UploadedFile';
import TranslationComplete from './TranslationComplete';

const Translate = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <PageContainer title={t('translate')}>
      <div style={{ position: 'absolute', top: 0, left: 300, zIndex: 2, opacity: 0.8 }}>
        <img src="/images/comingSoon/comingSoon.jpg" alt="" />
      </div>
      <div style={{ background: '#ffffff', padding: '30px 40px', borderRadius: 15 }}>
        <UploadedFile />
        <TranslationComplete />
      </div>
    </PageContainer>
  );
};

export default Translate;

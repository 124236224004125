import styled from 'styled-components';
import { darken, lighten } from 'polished';

import SearchSVG from './search.svg';

import SearchSVG_G from './search-g.svg';

import SearchSVG_B from './search-black.svg';

const SearchButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: ${(p) => p.theme.colors.secondary400};
  cursor: pointer;
  :hover {
    background-color: ${(p) => lighten(0.03, p.theme.colors.secondary400)};
  }
  :active {
    background-color: ${(p) => darken(0.03, p.theme.colors.secondary400)};
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

export { SearchButton, SearchSVG, SearchSVG_G, SearchSVG_B };

import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { useNavigate } from 'react-router-dom';
import { signIn } from '@/redux/auth/auth.slice';
import { useDispatch } from 'react-redux';

import { Backdrop, ModalWrapper } from './styles';

const canUseDom = () => Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);

const portalElement = document.getElementById('overlays');

interface AlertModalProps {
  content: string;
  closeText?: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userMail: string;
  type: string;
}

const AlertModal = ({type, content, closeText = 'OK', isModalOpen, setIsModalOpen, userMail }: AlertModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => {
    setIsModalOpen(false);
    if(type === 'signup'){
      dispatch(signIn({ isLogin: true, accessToken: '', userData: {email: userMail}, registDone: false }));
      navigate('/registration?step=1');
    }else if(type === 'resetPW'){
      dispatch(signIn({ isLogin: false, accessToken: '', userData: {}, registDone: true }));
      navigate('/auth/signin');      
    }
  };

  if (!canUseDom) return <></>;

  return (
    <>
      {portalElement && isModalOpen && (
        <>
          {ReactDOM.createPortal(<Backdrop onClick={onClose} />, portalElement)}{' '}
          {ReactDOM.createPortal(
            <ModalWrapper>
              <Text>{content}</Text>
              <Button marginTop={'30px'} size={'lg'} variant={'primary'} onClick={onClose}>
                {closeText}
              </Button>
            </ModalWrapper>,
            portalElement
          )}
        </>
      )}
    </>
  );
};

export default AlertModal;

import styled from 'styled-components';
import { ReactComponent as CloseSVG } from './close.svg';

const ButtonClose = styled.button`
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
`;

export { CloseSVG, ButtonClose };

import { useTranslation } from 'react-i18next';
import { RegistrationWrapper, Ul, Wrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { H5 } from '@/components/UI/Typography/Heading';
import { theme } from '@/theme/Theme';
import usePermissionCheck from '@/hooks/usePermissionCheck';
import { errorHandler } from '@/utils/toastHandler';
import { useEffect } from 'react';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<CreditReportResponse | undefined>>;
}

const Introduction = ({ setCurrentStep, setActiveStep, setData }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [seatchParmas, setSearchParams] = useSearchParams();
  const tips = t('pages.eTrust.creditReport.steps.step1.tips', { returnObjects: true });
  const { isMainAccount } = usePermissionCheck();

  const prevHandler = () => {
    navigate('/home');
  };

  const nextHandler = () => {
    if (!isMainAccount) {
      errorHandler(t('error.permission'));
      return;
    }
    setCurrentStep('2');
    setActiveStep(1);
    setSearchParams({ step: '2' });
  };

  useEffect(() => {}, [t]);

  return (
    <Wrapper>
      <Text fontSize={theme.fontSizes.lg} marginBottom={20}>
        {t('pages.eTrust.creditReport.steps.step1.title')}
      </Text>
      <H5 marginBottom={50}>
        {t('pages.eTrust.creditReport.steps.step1.intro-top')}
        <br />
        <br />
        {t('pages.eTrust.creditReport.steps.step1.intro-bottom')}
        <Ul>
          {tips.map((tip) => (
            <li key={tip}>{tip}</li>
          ))}
        </Ul>
      </H5>
      <RegistrationWrapper>
        <div className="content">
          <Flex width={'100%'} flexDirection={'column'} justifyContent={'flex-end'}>
            <Flex>
              <Button
                size={'lg'}
                type="button"
                variant={'primary'}
                rounded={false}
                marginRight={40}
                onClick={nextHandler}
              >
                {t('buttons.next')}
              </Button>
              <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
                {t('buttons.cancel')}
              </Button>
            </Flex>
          </Flex>
          <Flex className="border">
            <Box width={'100%'} padding={'20px 10px'} textAlign={'center'}>
              <img width={'85px'} src="/images/badge/credit_report.svg" alt="" />
              <H5 marginTop={20} fontWeight={700} fontSize={theme.fontSizes.md} color={theme.colors.secondary500}>
                {t('pages.eTrust.creditReport.steps.step1.badgeTitle')}
              </H5>
              <H5 marginTop={10}>{t('pages.eTrust.creditReport.steps.step1.badgeIntro')}</H5>
            </Box>
          </Flex>
        </div>
      </RegistrationWrapper>
    </Wrapper>
  );
};

export default Introduction;

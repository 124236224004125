import styled, { css } from 'styled-components';
import { SpaceProps, compose, space } from 'styled-system';

import { TextProps, text } from './Text';
import { theme } from '@/theme/Theme';

export type HeadingBaseProps = SpaceProps & TextProps;

const heading = compose(text, space);

const headingStyles = css`
  margin: 0;
  color: ${(p) => p.theme.colors.gray500};
  line-height: 1.5;
  ${heading};
`;

const H1 = styled.h1<HeadingBaseProps>`
  font-size: ${theme.fontSizes.xl};
  font-weight: 700;
  ${headingStyles};
`;

const H2 = styled.h2<HeadingBaseProps>`
  font-size: ${theme.fontSizes.lg};
  font-weight: 400;
  ${headingStyles};
`;

const H3 = styled.h3<HeadingBaseProps>`
  font-size: ${theme.fontSizes.md};
  font-weight: 400;
  ${headingStyles};
`;

const H4 = styled.h4<HeadingBaseProps>`
  font-size: ${theme.fontSizes.base};
  font-weight: 400;
  ${headingStyles};
`;

const H5 = styled.h5<HeadingBaseProps>`
  font-size: ${theme.fontSizes.sm};
  font-weight: 400;
  ${headingStyles};
`;

const FormHeader = styled.h2<HeadingBaseProps>`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${theme.colors.primary500};
  ${heading};
`;

const TableHeader = styled.h3<HeadingBaseProps>`
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
`;

export { H1, H2, H3, H4, H5, FormHeader, TableHeader };

import styled from 'styled-components';

const Title = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.colors.gray100};
  text-align: center;
  padding: 10px 0;
`;

const SignButton = styled.button`
  width: 100%;
  max-width: 180px;
  height: 100px;
  display: flex;
  text-align: center;
  border-radius: 5px;

  &.signed {
    background-color: ${(p) => p.theme.colors.secondary400};
    color: ${(p) => p.theme.colors.light};
  }

  &.waiting {
    background-color: ${(p) => p.theme.colors.gray300};
    color: ${(p) => p.theme.colors.light};
  }

  &.signing {
    background-color: ${(p) => p.theme.colors.yellow};
    color: ${(p) => p.theme.colors.gray500};
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.colors.warning};
    }
  }

  &.cancelled {
    background-color: ${(p) => p.theme.colors.gray100};
    color: ${(p) => p.theme.colors.gray500};
  }

  div {
    margin: auto;
  }
`;

const CompanyName = styled.div`
  font-size: ${(p) => p.theme.fontSizes.sm};
  color: ${(p) => p.theme.colors.gray500};
  width: 100%;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ModalForm = styled.form`
  position: absolute;
  top: 25%;
  left: calc(50% - 205px);
  width: 510px;
  background-color: #fff;
  padding: 25px;
  border-radius: 40px 40px 40px 0px;
`;

const ModalTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  text-align: center;
  margin-bottom: 15px;
`;

export { Title, SignButton, CompanyName, ModalForm, ModalTitle};

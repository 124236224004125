import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  InputWrapper,
  MultiInputWrapper,
  FormTitle,
  InputFileWrapper,
  InputFileLabel,
} from '@/components/Container/AuthContainer/styles';
import { FormSelect } from '@/components/UI/Select';
import { Checkbox, FormControl, FormControlLabel, TextField, Radio } from '@mui/material';
import { ReasonRadioWrapper, Wrapper } from '../../styles';
import { Box, Flex } from '@/layout';
import { ErrorMessage, Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { CloudUpload } from '@/components/Icon';
import TabsButton from '../../TabsButton';
import {
  convertLEIOptions,
  convertLocations,
  convertLegalFormCode,
  convertLegalFormLanguage,
  convertChinaCity,
  convertChinaArea,
  convertRAInformation,
} from '@/utils/convert';
import useLEIValidation from '@/hooks/requireSchema/useLEIValidation';
import useCurrentLang from '@/hooks/useCurrentLang';
import LEICountryData from '../LEICountryData';
import { NumberCount } from '@/components/UI/Input';

const DirectParent = ({ data, setData, userDetail, setSelectedTab, options }: TabFormRequire) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const [searchParmas, setSearchParams] = useSearchParams();
  const [provide, setProvide] = useState<boolean>(true);
  const [LEICode, setLEICode] = useState<boolean>(false);
  const [reasonRadio, setReasonRadio] = useState<number>(0);
  const { basicInfo, addressForm, legalForm, businessInfo, relationshipForm } = useLEIValidation();
  const [noReasonshipReasons, setNoReasonshipReasons] = useState<NoRelationshipReasonOptions[]>([]);
  const legalNameLanguageOptions = t('pages.eTrust.LEI.steps.step2.legalNameLanguage', { returnObjects: true });
  // 只取convertLEIOptions(lang, options?.Language)的第一和第二個資料
  const currentAllowLanguage = convertLEIOptions(lang, options?.Language).slice(0, 2);
  const [showLegalForm, setShowLegalForm] = useState<boolean>(true);
  const [showFormControl, setShowFormControl] = useState<{ [key: string]: boolean }>({
    businessRegistrationCertificateNumber: false,
    businessRegistrationOffice: false,
    certificateOfIncorporationCopy: false,
    businessRegistrationCertificate: false,
    businessRegistrationCertificateDocument: false,
    effectiveDateOfBusinessRegistrationCertificate: false,
    certificateOfIncorporationEffectiveDate: false,
    annualRetrun: false,
  });
  const [columnsName, setColumnsName] = useState({
    idNumber: t('LEIForm.businessInfo.certificateOfIncorporationNumber.label'),
    registerationOffice: '',
    copy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
    approveCopy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
    type: t('LEIForm.businessInfo.businessRegistrationCertificate.label') + ' *',
    date: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label') + ' *',
  });

  // 檔案名稱給重新編輯用
  const [IDFileName, setIDFileName] = useState<string>('');
  const [certificateDocumentName, setCertificateDocumentName] = useState<string>('');
  const [corporationCopyFileName, setCorporationCopyFileName] = useState<string>('');

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    setValue,
    register,
    setError,
    formState: { errors, dirtyFields },
  } = useForm<DirectParentFormRequire>();

  const [otherRegisterAvaliavle, setOtherRegisterAvaliavle] = useState<boolean>(false);
  const [sameAsRegisterAddress, setSameAsRegisterAddress] = useState<boolean>(false);
  const [otherOfficeAddressAvaliavle, setOtherOfficeAddressAvaliavle] = useState<boolean>(false);
  const [registerAddressLock, setRegisterAddressLock] = useState<boolean>(true);
  const [officeAddressLock, setOfficeAddressLock] = useState<boolean>(true);
  const [otherRegisterAddressLock, setOtherRegisterAddressLock] = useState<boolean>(true);
  const [otherOfficeAddressLock, setOtherOfficeAddressLock] = useState<boolean>(true);

  register('otherOfficeAddress', { disabled: false });
  register('otherRegisterAddress', { disabled: false });

  useEffect(() => {
    if (otherOfficeAddressAvaliavle) {
      register('otherOfficeAddress.Language', addressForm().language);
      register('otherOfficeAddress.PostCode', addressForm().postCode);
      register('otherOfficeAddress.Country', addressForm().country);
      register('otherOfficeAddress.Province', addressForm().province);
      register('otherOfficeAddress.City', addressForm().city);
      register('otherOfficeAddress.Address', addressForm().address);
    } else {
      register('otherOfficeAddress.Language', { required: false });
      register('otherOfficeAddress.PostCode', { required: false });
      register('otherOfficeAddress.Country', { required: false });
      register('otherOfficeAddress.Province', { required: false });
      register('otherOfficeAddress.City', { required: false });
      register('otherOfficeAddress.Address', { required: false });
      resetField('otherOfficeAddress');
    }
  }, [otherOfficeAddressAvaliavle]);

  useEffect(() => {
    if (otherRegisterAvaliavle) {
      register('otherRegisterAddress.Language', addressForm().language);
      register('otherRegisterAddress.PostCode', addressForm().postCode);
      register('otherRegisterAddress.Country', addressForm().country);
      register('otherRegisterAddress.Province', addressForm().province);
      register('otherRegisterAddress.City', addressForm().city);
      register('otherRegisterAddress.Address', addressForm().address);
    } else {
      register('otherRegisterAddress.Language', { required: false });
      register('otherRegisterAddress.PostCode', { required: false });
      register('otherRegisterAddress.Country', { required: false });
      register('otherRegisterAddress.Province', { required: false });
      register('otherRegisterAddress.City', { required: false });
      register('otherRegisterAddress.Address', { required: false });
      resetField('otherRegisterAddress');
    }
  }, [otherRegisterAvaliavle]);

  const registerCountry = watch('registerAddress.Country');
  const officeCountry = watch('officeAddress.Country');
  const otherRegisterCountry = watch('otherRegisterAddress.Country');
  const otherOfficeCountry = watch('otherOfficeAddress.Country');

  // 監測如果Country是China的話, Province和City都要變成必填, 否則不必填
  useEffect(() => {
    if (Object.keys(dirtyFields).length === 0) return;

    const registerAddressData = watch('registerAddress');
    const watchEntityLegalCategory = watch('entityLegalCategory');

    if (watchEntityLegalCategory !== 'BRANCH') {
      setValue('legalFormCountry', registerAddressData.Country);
      if (otherRegisterAvaliavle) {
        setValue('otherRegisterAddress.Country', registerAddressData.Country);
      }
    }

    if (registerCountry === 'HK') {
      setValue(
        'registerAddress.Province',
        LEICountryData.HK[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'registerAddress.City',
        LEICountryData.HK[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'registerAddress.PostCode',
        LEICountryData.HK[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setRegisterAddressLock(true);
      return;
    }

    if (registerCountry === 'MO') {
      setValue(
        'registerAddress.Province',
        LEICountryData.MO[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'registerAddress.City',
        LEICountryData.MO[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'registerAddress.PostCode',
        LEICountryData.MO[registerAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setRegisterAddressLock(true);
      return;
    }

    setRegisterAddressLock(false);
    setValue('registerAddress.Province', '');
    setValue('registerAddress.City', '');
  }, [registerCountry]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length === 0) return;

    const officeAddressData = watch('officeAddress');
    const watchEntityLegalCategory = watch('entityLegalCategory');

    if (watchEntityLegalCategory === 'BRANCH') {
      setValue('legalFormCountry', officeAddressData.Country);
    }

    if (sameAsRegisterAddress) return;

    if (otherOfficeAddressAvaliavle) {
      setValue('otherOfficeAddress.Country', officeAddressData.Country);
    }

    if (officeCountry === 'HK') {
      setValue(
        'officeAddress.Province',
        LEICountryData.HK[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'officeAddress.City',
        LEICountryData.HK[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'officeAddress.PostCode',
        LEICountryData.HK[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setOfficeAddressLock(true);
      return;
    }

    if (officeCountry === 'MO') {
      setValue(
        'officeAddress.Province',
        LEICountryData.MO[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].Province
      );
      setValue(
        'officeAddress.City',
        LEICountryData.MO[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].City
      );
      setValue(
        'officeAddress.PostCode',
        LEICountryData.MO[officeAddressData.Language === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc'].PostCode
      );
      setOfficeAddressLock(true);
      return;
    }

    setOfficeAddressLock(false);

    setValue('officeAddress.Province', '');
    setValue('officeAddress.City', '');
  }, [officeCountry]);

  useEffect(() => {
    if (!otherRegisterAvaliavle) return;
    const registerAddressData = watch('registerAddress');
    if (!registerAddressData) return;
    if (registerAddressData.Country === 'HK' || registerAddressData.Country === 'MO') {
      setValue('otherRegisterAddress.PostCode', registerAddressData.PostCode);
      setValue(
        'otherRegisterAddress.Province',
        LEICountryData[registerAddressData.Country][registerAddressData.Language === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherRegisterAddress.City',
        LEICountryData[registerAddressData.Country][registerAddressData.Language === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }

    setValue('otherRegisterAddress.Province', registerAddressData.Province);
    setValue('otherRegisterAddress.City', registerAddressData.City);
  }, [otherRegisterCountry]);

  useEffect(() => {
    if (!otherOfficeAddressAvaliavle) return;

    const officeAddressData = watch('officeAddress');
    if (!officeAddressData) return;
    if (officeAddressData.Country === 'HK' || officeAddressData.Country === 'MO') {
      setValue('otherOfficeAddress.PostCode', officeAddressData.PostCode);
      setValue(
        'otherOfficeAddress.Province',
        LEICountryData[officeAddressData.Country][officeAddressData.Language === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherOfficeAddress.City',
        LEICountryData[officeAddressData.Country][officeAddressData.Language === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }
    setValue('otherOfficeAddress.Province', officeAddressData.Province);
    setValue('otherOfficeAddress.City', officeAddressData.City);
  }, [otherOfficeCountry]);

  const handleOtherRegisterAvaliavle = () => {
    setOtherRegisterAvaliavle(!otherRegisterAvaliavle);
    setValue('otherRegisterAddressAvaliavle', !otherRegisterAvaliavle);
    if (!otherRegisterAvaliavle) {
      const registerAddressData = watch('registerAddress');
      setValue('otherRegisterAddress.Language', registerAddressData.Language === 'en' ? 'zh' : 'en');
      setValue('otherRegisterAddress.Country', registerAddressData.Country);
      setValue('otherRegisterAddress.PostCode', registerAddressData.PostCode);
    } else {
      resetField('otherRegisterAddress.Language');
      resetField('otherRegisterAddress.PostCode');
      resetField('otherRegisterAddress.Country');
      resetField('otherRegisterAddress.Province');
      resetField('otherRegisterAddress.City');
      resetField('otherRegisterAddress.Address');
    }
  };

  const handleSameAsRegisterAddress = () => {
    setSameAsRegisterAddress(!sameAsRegisterAddress);
    setValue('sameAddress', !sameAsRegisterAddress);
  };

  const handleOtherOfficeAddressAvaliavle = () => {
    setOtherOfficeAddressAvaliavle(!otherOfficeAddressAvaliavle);
    setValue('otherOfficeAddressAvaliavle', !otherOfficeAddressAvaliavle);
    if (!otherOfficeAddressAvaliavle) {
      const officeAddressData = watch('officeAddress');
      setValue('otherOfficeAddress.Language', officeAddressData.Language === 'en' ? 'zh' : 'en');
      setValue('otherOfficeAddress.Country', officeAddressData.Country);
      setValue('otherOfficeAddress.PostCode', officeAddressData.PostCode);
    } else {
      resetField('otherOfficeAddress.Language');
      resetField('otherOfficeAddress.PostCode');
      resetField('otherOfficeAddress.Country');
      resetField('otherOfficeAddress.Province');
      resetField('otherOfficeAddress.City');
      resetField('otherOfficeAddress.Address');
    }
  };

  const watchRegisterLang = watch('registerAddress.Language');
  const watchOfficeLang = watch('officeAddress.Language');

  useEffect(() => {
    const registerAddressData = watch('registerAddress');

    if (!registerAddressData) return;
    if (registerAddressData.Country === 'HK' || registerAddressData.Country === 'MO') {
      setValue('registerAddress.PostCode', registerAddressData.PostCode);
      setValue(
        'registerAddress.Province',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'en' ? 'en' : 'sc'].Province
      );
      setValue(
        'registerAddress.City',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'en' ? 'en' : 'sc'].City
      );

      if (!otherRegisterAvaliavle) return;
      setValue('otherRegisterAddress.Language', watchRegisterLang === 'en' ? 'zh' : 'en');
      setValue('otherRegisterAddress.PostCode', registerAddressData.PostCode);
      setValue(
        'otherRegisterAddress.Province',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherRegisterAddress.City',
        LEICountryData[registerAddressData.Country][watchRegisterLang === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }
  }, [watchRegisterLang]);

  useEffect(() => {
    const officeAddressData = watch('officeAddress');
    if (!officeAddressData) return;
    if (officeAddressData.Country === 'HK' || officeAddressData.Country === 'MO') {
      setValue('officeAddress.PostCode', officeAddressData.PostCode);
      setValue(
        'officeAddress.Province',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'en' ? 'en' : 'sc'].Province
      );
      setValue(
        'officeAddress.City',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'en' ? 'en' : 'sc'].City
      );

      if (!otherOfficeAddressAvaliavle) return;
      setValue('otherOfficeAddress.Language', watchOfficeLang === 'en' ? 'zh' : 'en');
      setValue('otherOfficeAddress.PostCode', officeAddressData.PostCode);
      setValue(
        'otherOfficeAddress.Province',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'zh' ? 'en' : 'sc'].Province
      );
      setValue(
        'otherOfficeAddress.City',
        LEICountryData[officeAddressData.Country][watchOfficeLang === 'zh' ? 'en' : 'sc'].City
      );
      return;
    }
  }, [watchOfficeLang]);

  // 監測sameAsRegisterAddress
  useEffect(() => {
    if (sameAsRegisterAddress) {
      const address = watch('registerAddress');
      if (!address) return;
      setValue('officeAddress.Address', address.Address);
      setValue('officeAddress.City', address.City);
      setValue('officeAddress.Country', address.Country);
      setValue('officeAddress.Language', address.Language);
      setValue('officeAddress.PostCode', address.PostCode);
      setValue('officeAddress.Province', address.Province);
    }
  }, [sameAsRegisterAddress]);

  const onSubmit = handleSubmit(async (formData) => {
    setData({
      ...data,
      directParent: {
        ...formData,
        provideDirectParentCompanyInfo: provide,
        certificateOfIncorporationCopy: watch('certificateOfIncorporationCopy')?.[0],
        corporationIdentificationDocument: watch('corporationIdentificationDocument')?.[0],
        cerifiedDocuments: watch('cerifiedDocuments').length !== 0 ? watch('cerifiedDocuments')?.[0] : certificateDocumentName,
        reason: provide ? '' : reasonRadio.toString(),
      },
    });
    setSelectedTab(5);
    setSearchParams({ step: '2', tab: '5' });
  });

  const handleProvideChange = () => {
    setProvide(!provide);
    setValue('provideDirectParentCompanyInfo', !provide);
    setReasonRadio(0);
  };

  const handleLEICodeChange = () => {
    setLEICode(!LEICode);
    setValue('haveLEICode', !LEICode);
  };

  const handleRadioChange = (index: number) => {
    setReasonRadio(index);
    setValue('reason', index.toString());
  };

  useEffect(() => {
    // errors有改變時, 將畫面移到最上方
    if (Object.keys(errors).length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (!provide) {
      setLEICode(false);
      setValue('haveLEICode', false);
    } else {
      setLEICode(true);
      setValue('haveLEICode', true);
    }
  }, [provide]);

  useEffect(() => {
    const filterReasons = options?.NoRelationshipReason.filter((reason) => reason.textEn !== '');
    setNoReasonshipReasons(filterReasons);

    const today = new Date().toISOString().split('T')[0];
    setValue('entityCreationDate', today);
    setValue('effectiveDateOfBusinessRegistrationCertificate', today);
    setValue('relationShipStartDate', today);
    setValue('relationShipEndDate', today);
    setValue('accountStartTime', today);
    setValue('accountEndTime', today);
    setValue('docStartTime', today);
    setValue('docEndTime', today);

    setValue('directHoldingPercent', 0);

    const { directParent } = data;
    if (!directParent) return;
    setValue('legalNameLanguage', directParent.legalNameLanguage);
    setValue('legalName', directParent.legalName);
    setValue('otherLegalNameLanguage', directParent.otherLegalNameLanguage);
    setValue('otherLegalName', directParent.otherLegalName);
    setValue('entityLegalCategory', directParent.entityLegalCategory);
    setValue('corporation', directParent.corporation);
    setValue('registerAddress', directParent.registerAddress);
    setValue('otherRegisterAddressAvaliavle', directParent.otherRegisterAddressAvaliavle);
    if (directParent.otherRegisterAddressAvaliavle) {
      setValue('otherRegisterAddress.Language', directParent.otherRegisterAddress.Language);
      setValue('otherRegisterAddress.PostCode', directParent.otherRegisterAddress.PostCode);
      setValue('otherRegisterAddress.Country', directParent.otherRegisterAddress.Country);
      setValue('otherRegisterAddress.Province', directParent.otherRegisterAddress.Province);
      setValue('otherRegisterAddress.City', directParent.otherRegisterAddress.City);
      setValue('otherRegisterAddress.Address', directParent.otherRegisterAddress.Address);
    }
    setValue('officeAddress', directParent.officeAddress);
    setSameAsRegisterAddress(directParent.sameAddress);
    setValue('sameAddress', directParent.sameAddress);
    if (directParent.otherOfficeAddressAvaliavle) {
      setValue('otherOfficeAddressAvaliavle', directParent.otherOfficeAddressAvaliavle);
      setValue('otherOfficeAddress.Language', directParent.otherOfficeAddress.Language);
      setValue('otherOfficeAddress.PostCode', directParent.otherOfficeAddress.PostCode);
      setValue('otherOfficeAddress.Country', directParent.otherOfficeAddress.Country);
      setValue('otherOfficeAddress.Province', directParent.otherOfficeAddress.Province);
      setValue('otherOfficeAddress.City', directParent.otherOfficeAddress.City);
      setValue('otherOfficeAddress.Address', directParent.otherOfficeAddress.Address);
    }
    setValue('legalFormCountry', directParent.legalFormCountry);
    setValue('legalFormLanguage', directParent.legalFormLanguage);
    setValue('legalFormCode', directParent.legalFormCode);
    setValue('otherLegalFormCode', directParent.otherLegalFormCode);
    setValue('registrationAuthorityCode', directParent.registrationAuthorityCode);
    setValue('businessRegistrationCertificateNumber', directParent.businessRegistrationCertificateNumber);
    setValue('businessRegistrationOffice', directParent.businessRegistrationOffice);
    setValue('entityCreationDate', directParent.entityCreationDate);
    setValue(
      'effectiveDateOfBusinessRegistrationCertificate',
      directParent.effectiveDateOfBusinessRegistrationCertificate
    );
    setValue('relationShipStartDate', directParent.relationShipStartDate);
    setValue('relationShipEndDate', directParent.relationShipEndDate);
    setValue('accountStartTime', directParent.accountStartTime);
    setValue('accountEndTime', directParent.accountEndTime);
    setValue('docStartTime', directParent.docStartTime);
    setValue('docEndTime', directParent.docEndTime);
    setValue('quantitativeAttributes', directParent.quantitativeAttributes);
    setValue('haveLEICode', directParent.haveLEICode);
    setValue('reason', directParent.reason);
    setValue('provideDirectParentCompanyInfo', directParent.provideDirectParentCompanyInfo);
    setValue('directParentLeiCode', directParent.directParentLeiCode);
    setValue('checkFileType', directParent.checkFileType);
    setValue('criterionType', directParent.criterionType);
    setReasonRadio(parseInt(directParent.reason));
    setLEICode(directParent.haveLEICode);
    setOtherOfficeAddressAvaliavle(directParent.otherOfficeAddressAvaliavle);
    setOtherRegisterAvaliavle(directParent.otherRegisterAddressAvaliavle);
    setProvide(directParent.provideDirectParentCompanyInfo);
    setValue('directHoldingPercent', directParent.directHoldingPercent);
    if (directParent.certificateOfIncorporationCopy)
      if (directParent.certificateOfIncorporationCopy instanceof File)
        setValue('certificateOfIncorporationCopy', [directParent.certificateOfIncorporationCopy]);
      else setCorporationCopyFileName(directParent.certificateOfIncorporationCopy);
    if (directParent.corporationIdentificationDocument)
      if (directParent.corporationIdentificationDocument instanceof File)
        setValue('corporationIdentificationDocument', [directParent.corporationIdentificationDocument]);
      else setIDFileName(directParent.corporationIdentificationDocument);
    if (directParent.cerifiedDocuments)
      if (directParent.cerifiedDocuments instanceof File)
        setValue('cerifiedDocuments', [directParent.cerifiedDocuments]);
      else setCertificateDocumentName(directParent.cerifiedDocuments);
  }, [searchParmas]);

  const registerAddress = watch('registerAddress');
  // 若legalFormLanguage的data為空陣列時, 將legalFormCode的值設為8888並將showLegalForm設為false
  // 當address有改變時, 對應調整businessInfo的資料顯示
  useEffect(() => {
    if (!registerAddress) return;

    if (convertLegalFormLanguage(options?.LegalForm, registerAddress.Country).length === 0) {
      setShowLegalForm(false);
      setValue('legalFormCode', '8888');
    } else {
      setShowLegalForm(true);
      // setValue('legalFormCode', '');
      // setValue('otherLegalFormCode', '');
    }

    if (registerAddress.Country === 'CN') {
      // 中国，工商信息默认为：统一社会信用代码、登记机关、营业执照副本、营业执照生效日期；
      setShowFormControl({
        ...showFormControl,
        businessRegistrationOffice: true,
        businessRegistrationCertificateDocument: true,
        effectiveDateOfBusinessRegistrationCertificate: true,
      });
    } else if (registerAddress.Country === 'HK') {
      // 香港，工商信息默认为：公司注册证编号、登记机关、公司注册证副本、商业登记证、商业登记证副本、商业登记证生效日期、周年申报表副本；
      setShowFormControl({
        ...showFormControl,
        businessRegistrationCertificateNumber: true,
        businessRegistrationOffice: true,
        certificateOfIncorporationCopy: true,
        businessRegistrationCertificate: true,
        businessRegistrationCertificateDocument: true,
        effectiveDateOfBusinessRegistrationCertificate: true,
        annualRetrun: true,
      });
    } else if (registerAddress.Country === 'MO') {
      // 澳门，工商信息默认为：公司注册证编号、登记机关、公司注册证副本、商业登记证副本、商业登记证生效日期；
      setShowFormControl({
        ...showFormControl,
        businessRegistrationCertificateNumber: true,
        businessRegistrationOffice: true,
        certificateOfIncorporationCopy: true,
        businessRegistrationCertificateDocument: true,
        effectiveDateOfBusinessRegistrationCertificate: true,
      });
    }
  }, [registerAddress, registerAddress?.Country]);

  // 監視registrationAuthorityCode的變化, 若國家為HK時, 根據registrationAuthorityCode的值 表單會有對應的變化
  // 1. RA000388時, 公司註冊證編號必填, 登記機關必填, 公司註冊證副本必填, 周年申報表副本必填, 商業登記證生效日期必填, 商業登記證若為可提供則商業登記證副本必填, 若為無法提供則須輸入原因
  // 2. RA000389時, 商業登記證編號必填, 登記機關必填, 商業登記證副本必填, 周年申報表副本必填, 商業登記證生效日期必填, 公司註冊證若為可提供則公司註冊證附件副本必填, 若為無法提供則須輸入原因
  // 3. RA000390時, 牌照中央編號必填, 登記機關必填, 拍照副本附件必填, 周年申報表副本必填, 牌照生效日期必填, 公司註冊證若為可提供則公司註冊證附件副本必填, 若為無法提供則須輸入原因
  const watchRegistrationAuthorityCode = watch('registrationAuthorityCode');

  useEffect(() => {
    if (!watchRegistrationAuthorityCode) return;
    if (watchRegistrationAuthorityCode === 'RA000389') {
      setColumnsName({
        idNumber: t('LEIForm.businessInfo.businessRegistrationCertificateNumber.label'),
        registerationOffice: '',
        copy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
        approveCopy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
        type: t('LEIForm.businessInfo.certificateOfIncorporation.label') + ' *',
        date: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label') + ' *',
      });
    }
    if (watchRegistrationAuthorityCode === 'RA000388') {
      setColumnsName({
        idNumber: t('LEIForm.businessInfo.certificateOfIncorporationNumber.label'),
        registerationOffice: '',
        copy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
        approveCopy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
        type: t('LEIForm.businessInfo.businessRegistrationCertificate.label') + ' *',
        date: t('LEIForm.businessInfo.effectiveDateOfBusinessRegistrationCertificate.label') + ' *',
      });
    }
    if (watchRegistrationAuthorityCode === 'RA000390') {
      setColumnsName({
        idNumber: t('LEIForm.businessInfo.CERefNo.label'),
        registerationOffice: '',
        copy: t('LEIForm.businessInfo.businessRegistrationCertificateDocument.label'),
        approveCopy: t('LEIForm.businessInfo.certificateOfIncorporationCopy.label'),
        type: t('LEIForm.businessInfo.certificateOfIncorporation.label') + ' *',
        date: t('LEIForm.businessInfo.licenceEffectiveDate.label') + ' *',
      });
    }
  }, [watchRegistrationAuthorityCode]);

  // ratio
  const watchHoldingPercent = watch('directHoldingPercent');
  const calcNumber = () => {
    function add() {
      if (watchHoldingPercent < 100) setValue('directHoldingPercent', watchHoldingPercent + 1);
    }

    function minus() {
      if (watchHoldingPercent > 0) setValue('directHoldingPercent', watchHoldingPercent - 1);
    }

    return { add, minus };
  };

  useEffect(() => {
    // 若轉換後為NaN則將其設為0
    if (isNaN(watchHoldingPercent)) setValue('directHoldingPercent', 0);
    // 若填入非數字則將其轉為數字
    if (typeof watchHoldingPercent === 'string') {
      setValue('directHoldingPercent', parseInt(watchHoldingPercent));
    }
    // 若填入負數則將其設為0
    if (watchHoldingPercent < 0) setValue('directHoldingPercent', 0);
    // 若超過100則將其設為100
    if (watchHoldingPercent > 100) setValue('directHoldingPercent', 100);
  }, [watchHoldingPercent]);

  return (
    <Wrapper onSubmit={onSubmit}>
      <FormTitle>{t('info.directParentCompany')}</FormTitle>
      <Flex gridGap={20} alignItems={'center'}>
        <Text>{t('info.provideDirect')}</Text>
        <Box>
          <FormControlLabel
            control={<Radio color="success" checked={provide} onChange={handleProvideChange} name="provide-buttons" />}
            label={t('buttons.canProvide')}
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Radio color="success" checked={!provide} onChange={handleProvideChange} name="provide-buttons" />}
            label={t('buttons.notProvide')}
            labelPlacement="end"
          />
        </Box>
      </Flex>

      {provide && (
        <Flex gridGap={20} alignItems={'center'} marginBottom={30}>
          <Text>{t('info.haveLEICode')}</Text>
          <Box>
            <FormControlLabel
              control={
                <Radio color="success" checked={LEICode} onChange={handleLEICodeChange} name="LEICode-buttons" />
              }
              label={t('buttons.yes')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Radio color="success" checked={!LEICode} onChange={handleLEICodeChange} name="LEICode-buttons" />
              }
              label={t('buttons.no')}
              labelPlacement="end"
            />
          </Box>
        </Flex>
      )}

      {!provide && (
        <Box marginBottom={30}>
          <Text marginBottom={10}>{t('LEIForm.parent.reason.label') + ' *'}:</Text>
          {noReasonshipReasons?.map((reason, index) => (
            <ReasonRadioWrapper key={reason.code}>
              <div className="radio">
                <FormControlLabel
                  control={
                    <Radio
                      color="success"
                      checked={reasonRadio === index}
                      onChange={() => handleRadioChange(index)}
                      name="reason-buttons"
                    />
                  }
                  label={reason.textOri}
                  labelPlacement="end"
                />
              </div>
              <div className="intro">
                <Text>{lang === 'sc' ? reason.textZh : reason.textTw}</Text>
                <Text>{reason.textEn}</Text>
              </div>
            </ReasonRadioWrapper>
          ))}
        </Box>
      )}

      {provide && LEICode && (
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'directParentLeiCode'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('LEIForm.parent.directParentLeiCode.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={relationshipForm().parentLEICode}
          />
        </InputWrapper>
      )}

      {provide && !LEICode && (
        <>
          <FormTitle>
            {t('info.directParentCompany')} {t('info.basicInfo')}
          </FormTitle>
          <MultiInputWrapper gridGap={30}>
            <div style={{ width: '150px' }}>
              <FormSelect
                control={control}
                data={legalNameLanguageOptions}
                inputLabel={t('form.pleaseSelect.label')}
                selectLabel={t('form.pleaseSelect.label')}
                name={'legalNameLanguage'}
                register={basicInfo({}).legalNameLanguage}
              />
            </div>
            <Controller
              control={control}
              name={'legalName'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('LEIForm.basicInfo.legalName.label') + ' *'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={basicInfo({ legalNameLang: watch('legalNameLanguage') }).legalName}
            />
          </MultiInputWrapper>
          <MultiInputWrapper gridGap={30}>
            <div style={{ width: '150px' }}>
              <FormSelect
                control={control}
                data={legalNameLanguageOptions}
                inputLabel={t('form.pleaseSelect.label')}
                selectLabel={t('form.pleaseSelect.label')}
                name={'otherLegalNameLanguage'}
              />
            </div>
            <Controller
              control={control}
              name={'otherLegalName'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('LEIForm.basicInfo.otherLegalName.label')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </MultiInputWrapper>

          <MultiInputWrapper gridGap={30}>
            <FormSelect
              control={control}
              data={convertLEIOptions(lang, options?.EntityLegalCategory)}
              inputLabel={t('LEIForm.basicInfo.entityLegalCategory.label')}
              selectLabel={t('LEIForm.basicInfo.entityLegalCategory.label')}
              name={'entityLegalCategory'}
              register={basicInfo({}).entityLegalCategory}
            />
            <Controller
              control={control}
              name={'corporation'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('LEIForm.basicInfo.corporation.label') + ' *'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={basicInfo({}).corporation}
            />
          </MultiInputWrapper>

          {watch('entityLegalCategory') === 'BRANCH' && (
            <InputWrapper style={{ height: 'auto' }}>
              <Controller
                control={control}
                name={'directHeadLEI'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.basicInfo.headerQuarterLEI.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                )}
              />
            </InputWrapper>
          )}

          {/* <InputWrapper style={{ height: 'auto' }}>
            <InputFileWrapper>
              <InputFileLabel>
                <Text
                  fontWeight={400}
                  fontSize={theme.fontSizes.sm}
                  color={!watch('corporationIdentificationDocument')?.[0] ? theme.colors.gray300 : theme.colors.gray500}
                >
                  {watch('corporationIdentificationDocument')?.[0]
                    ? watch('corporationIdentificationDocument')[0].name
                    : t('LEIForm.basicInfo.corporationIdentificationDocument.label')}
                </Text>
                <input
                  hidden
                  accept=".pdf, .png, .gif, .jpg"
                  type="file"
                  {...register('corporationIdentificationDocument')}
                />
                {!watch('corporationIdentificationDocument')?.[0] && (
                  <ErrorMessage>{errors.corporationIdentificationDocument?.message}</ErrorMessage>
                )}
                <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
              </InputFileLabel>
            </InputFileWrapper>
            <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
              JPG, GIF, PNG, {t('maxSize')} 2MB
            </Text>
          </InputWrapper> */}

          <FormTitle>{t('info.registerAddress')}</FormTitle>
          <Flex gridGap={30}>
            <Flex width={'100%'} flexDirection={'column'} gridGap={10}></Flex>
            <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#999999',
                      '&.Mui-checked': {
                        color: theme.colors.secondary500,
                      },
                    }}
                    onChange={handleOtherRegisterAvaliavle}
                    name="otherRegisterAddressAvaliavle"
                    checked={otherRegisterAvaliavle}
                  />
                }
                label={
                  <Text>
                    {watch('registerAddress.Language') && watch('registerAddress.Language') === 'zh'
                      ? t('LEIForm.Address.registerAvalable.label')
                      : t('LEIForm.Address.registerAvalableZH.label')}
                  </Text>
                }
              />
            </Flex>
          </Flex>
          <Flex gridGap={30}>
            <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
              <FormSelect
                control={control}
                data={currentAllowLanguage}
                inputLabel={t('LEIForm.Address.language.label') + ' *'}
                selectLabel={t('LEIForm.Address.language.label') + ' *'}
                name={'registerAddress.Language'}
                register={addressForm().language}
              />
              <FormSelect
                control={control}
                data={[
                  convertLocations(lang, options?.Country)[0],
                  convertLocations(lang, options?.Country)[1],
                  convertLocations(lang, options?.Country)[2],
                ]}
                inputLabel={t('LEIForm.Address.country.label') + ' *'}
                selectLabel={t('LEIForm.Address.country.label') + ' *'}
                name={'registerAddress.Country'}
                register={addressForm().country}
              />
              {registerCountry === 'CN' && (
                <>
                  <FormSelect
                    control={control}
                    data={convertChinaArea(lang, options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.province.label') + ' *'}
                    selectLabel={t('LEIForm.Address.province.label') + ' *'}
                    name={'registerAddress.Province'}
                    register={addressForm().province}
                  />
                  <FormSelect
                    control={control}
                    data={convertChinaCity(lang, watch('registerAddress.Province'), options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.city.label') + ' *'}
                    selectLabel={t('LEIForm.Address.city.label') + ' *'}
                    name={'registerAddress.City'}
                    register={addressForm().city}
                  />
                </>
              )}
              {registerCountry !== 'CN' && (
                <>
                  <Controller
                    control={control}
                    name={'registerAddress.Province'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.province.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        inputProps={{
                          readOnly: registerAddressLock,
                        }}
                      />
                    )}
                    rules={addressForm().province}
                  />
                  <Controller
                    control={control}
                    name={'registerAddress.City'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.city.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        inputProps={{
                          readOnly: registerAddressLock,
                        }}
                      />
                    )}
                    rules={addressForm().city}
                  />
                </>
              )}
              <Controller
                control={control}
                name={'registerAddress.Address'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.address.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                )}
                rules={addressForm().address}
              />
              <Controller
                control={control}
                name={'registerAddress.PostCode'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.postCode.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: registerAddressLock,
                    }}
                  />
                )}
                rules={addressForm().postCode}
              />
            </Flex>
            <Flex width={'100%'} flexDirection={'column'} gridGap={10} opacity={!otherRegisterAvaliavle ? '.5' : '1'}>
              <FormSelect
                control={control}
                data={currentAllowLanguage}
                inputLabel={t('LEIForm.Address.language.label') + ' *'}
                selectLabel={t('LEIForm.Address.language.label') + ' *'}
                name={'otherRegisterAddress.Language'}
                disabled={true}
              />
              <FormSelect
                control={control}
                data={convertLocations(lang, options?.Country)}
                inputLabel={t('LEIForm.Address.country.label') + ' *'}
                selectLabel={t('LEIForm.Address.country.label') + ' *'}
                name={'otherRegisterAddress.Country'}
                disabled={true}
              />
              {otherRegisterCountry === 'CN' && (
                <>
                  <FormSelect
                    control={control}
                    data={convertChinaArea(lang, options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.province.label') + ' *'}
                    selectLabel={t('LEIForm.Address.province.label') + ' *'}
                    name={'otherRegisterAddress.Province'}
                    readOnly={otherRegisterAddressLock}
                  />
                  <FormSelect
                    control={control}
                    data={convertChinaCity(lang, watch('otherRegisterAddress.Province'), options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.city.label') + ' *'}
                    selectLabel={t('LEIForm.Address.city.label') + ' *'}
                    name={'otherRegisterAddress.City'}
                    readOnly={otherRegisterAddressLock}
                  />
                </>
              )}
              {otherRegisterCountry !== 'CN' && (
                <>
                  <Controller
                    control={control}
                    name={'otherRegisterAddress.Province'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.province.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        disabled={!otherRegisterAvaliavle}
                        inputProps={{
                          readOnly: otherRegisterAddressLock,
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={'otherRegisterAddress.City'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.city.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        disabled={!otherRegisterAvaliavle}
                        inputProps={{
                          readOnly: otherRegisterAddressLock,
                        }}
                      />
                    )}
                  />
                </>
              )}
              <Controller
                control={control}
                name={'otherRegisterAddress.Address'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.address.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={!otherRegisterAvaliavle}
                  />
                )}
              />
              <Controller
                control={control}
                name={'otherRegisterAddress.PostCode'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.postCode.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={true}
                  />
                )}
              />
            </Flex>
          </Flex>

          <FormTitle marginTop={'25px'}>{t('info.officeAddress')}</FormTitle>
          <Flex gridGap={30}>
            <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#999999',
                      '&.Mui-checked': {
                        color: theme.colors.secondary500,
                      },
                    }}
                    onChange={handleSameAsRegisterAddress}
                    name="sameAsRegisterAddress"
                    checked={sameAsRegisterAddress}
                  />
                }
                label={<Text>{t('LEIForm.Address.sameAsRegister.label')}</Text>}
              />
            </Flex>
            <Flex width={'100%'} flexDirection={'column'} gridGap={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#999999',
                      '&.Mui-checked': {
                        color: theme.colors.secondary500,
                      },
                    }}
                    onChange={handleOtherOfficeAddressAvaliavle}
                    name="otherOfficeAddressAvaliavle"
                    checked={otherOfficeAddressAvaliavle}
                  />
                }
                label={
                  <Text>
                    {watch('officeAddress.Language') && watch('officeAddress.Language') === 'zh'
                      ? t('LEIForm.Address.officeRegistrationAvalable.label')
                      : t('LEIForm.Address.officeRegistrationAvalableZH.label')}
                  </Text>
                }
              />
            </Flex>
          </Flex>
          <Flex gridGap={30}>
            <Flex width={'100%'} flexDirection={'column'} gridGap={10} opacity={sameAsRegisterAddress ? '.5' : '1'}>
              <FormSelect
                control={control}
                data={currentAllowLanguage}
                inputLabel={t('LEIForm.Address.language.label') + ' *'}
                selectLabel={t('LEIForm.Address.language.label') + ' *'}
                name={'officeAddress.Language'}
                readOnly={sameAsRegisterAddress}
                register={addressForm().language}
              />
              <FormSelect
                control={control}
                data={convertLocations(lang, options?.Country)}
                inputLabel={t('LEIForm.Address.country.label') + ' *'}
                selectLabel={t('LEIForm.Address.country.label') + ' *'}
                name={'officeAddress.Country'}
                readOnly={sameAsRegisterAddress}
                register={addressForm().country}
              />
              {officeCountry === 'CN' && (
                <>
                  <FormSelect
                    control={control}
                    data={convertChinaArea(lang, options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.province.label') + ' *'}
                    selectLabel={t('LEIForm.Address.province.label') + ' *'}
                    name={'officeAddress.Province'}
                    readOnly={sameAsRegisterAddress}
                    register={addressForm().province}
                  />
                  <FormSelect
                    control={control}
                    data={convertChinaCity(lang, watch('officeAddress.Province'), options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.city.label') + ' *'}
                    selectLabel={t('LEIForm.Address.city.label') + ' *'}
                    name={'officeAddress.City'}
                    readOnly={sameAsRegisterAddress}
                    register={addressForm().city}
                  />
                </>
              )}
              {officeCountry !== 'CN' && (
                <>
                  <Controller
                    control={control}
                    name={'officeAddress.Province'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.province.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        inputProps={{
                          readOnly: officeAddressLock,
                        }}
                      />
                    )}
                    rules={addressForm().province}
                  />
                  <Controller
                    control={control}
                    name={'officeAddress.City'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.city.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        inputProps={{
                          readOnly: officeAddressLock,
                        }}
                      />
                    )}
                    rules={addressForm().city}
                  />
                </>
              )}
              <Controller
                control={control}
                name={'officeAddress.Address'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.address.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={sameAsRegisterAddress}
                  />
                )}
                rules={addressForm().address}
              />
              <Controller
                control={control}
                name={'officeAddress.PostCode'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.postCode.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={sameAsRegisterAddress}
                    inputProps={{
                      readOnly: officeAddressLock,
                    }}
                  />
                )}
                rules={addressForm().postCode}
              />
            </Flex>
            <Flex
              width={'100%'}
              flexDirection={'column'}
              gridGap={10}
              opacity={!otherOfficeAddressAvaliavle ? '.5' : '1'}
            >
              <FormSelect
                control={control}
                data={currentAllowLanguage}
                inputLabel={t('LEIForm.Address.language.label') + ' *'}
                selectLabel={t('LEIForm.Address.language.label') + ' *'}
                name={'otherOfficeAddress.Language'}
                disabled={true}
              />
              <FormSelect
                control={control}
                data={convertLocations(lang, options?.Country)}
                inputLabel={t('LEIForm.Address.country.label') + ' *'}
                selectLabel={t('LEIForm.Address.country.label') + ' *'}
                name={'otherOfficeAddress.Country'}
                disabled={true}
              />
              {otherOfficeCountry === 'CN' && (
                <>
                  <FormSelect
                    control={control}
                    data={convertChinaArea(lang, options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.province.label') + ' *'}
                    selectLabel={t('LEIForm.Address.province.label') + ' *'}
                    name={'otherOfficeAddress.Province'}
                    readOnly={otherOfficeAddressLock}
                  />
                  <FormSelect
                    control={control}
                    data={convertChinaCity(lang, watch('otherOfficeAddress.Province'), options?.ChinaArea)}
                    inputLabel={t('LEIForm.Address.city.label') + ' *'}
                    selectLabel={t('LEIForm.Address.city.label') + ' *'}
                    name={'otherOfficeAddress.City'}
                    readOnly={otherOfficeAddressLock}
                  />
                </>
              )}
              {otherOfficeCountry !== 'CN' && (
                <>
                  <Controller
                    control={control}
                    name={'otherOfficeAddress.Province'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.province.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        disabled={!otherOfficeAddressAvaliavle}
                        inputProps={{
                          readOnly: otherOfficeAddressLock,
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={'otherOfficeAddress.City'}
                    defaultValue={''}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label={t('LEIForm.Address.city.label') + ' *'}
                        variant="standard"
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoComplete="off"
                        disabled={!otherOfficeAddressAvaliavle}
                        inputProps={{
                          readOnly: otherOfficeAddressLock,
                        }}
                      />
                    )}
                  />
                </>
              )}
              <Controller
                control={control}
                name={'otherOfficeAddress.Address'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.address.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={!otherOfficeAddressAvaliavle}
                  />
                )}
              />
              <Controller
                control={control}
                name={'otherOfficeAddress.PostCode'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.Address.postCode.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    disabled={true}
                  />
                )}
              />
            </Flex>
          </Flex>

          <FormTitle marginTop={'25px'}>{t('info.legalForm')}</FormTitle>
          {showLegalForm && (
            <MultiInputWrapper gridGap={30}>
              {/* <FormSelect
            control={control}
            data={convertLocations(lang, options?.Country)}
            inputLabel={t('LEIForm.legalForm.legalFormCountry.label') + ' *'}
            selectLabel={t('LEIForm.legalForm.legalFormCountry.label') + ' *'}
            name={'legalFormCountry'}
            register={legalForm().legalFormCountry}
          /> */}
              <FormSelect
                control={control}
                data={convertLegalFormLanguage(options?.LegalForm, registerAddress?.Country)}
                inputLabel={t('LEIForm.legalForm.legalFormLanguage.label') + ' *'}
                selectLabel={t('LEIForm.legalForm.legalFormLanguage.label') + ' *'}
                name={'legalFormLanguage'}
                register={legalForm().legalFormLanguage}
              />
            </MultiInputWrapper>
          )}

          <InputWrapper style={{ height: 'auto', width: '100%' }}>
            {watch('legalFormLanguage') &&
            convertLegalFormCode(lang, options?.LegalForm, registerAddress?.Country, watch('legalFormLanguage'))
              .length !== 0 ? (
              <FormSelect
                control={control}
                data={convertLegalFormCode(
                  lang,
                  options?.LegalForm,
                  registerAddress?.Country,
                  watch('legalFormLanguage')
                )}
                inputLabel={t('LEIForm.legalForm.legalFormCode.label') + ' *'}
                selectLabel={t('LEIForm.legalForm.legalFormCode.label') + ' *'}
                name={'legalFormCode'}
                register={legalForm().legalFormCode}
              />
            ) : (
              <Controller
                control={control}
                name={'otherLegalFormCode'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.legalForm.otherEntityLegalForm.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                )}
                rules={legalForm().legalFormCode}
              />
            )}
          </InputWrapper>

          <FormTitle>{t('info.businessInfo')}</FormTitle>
          <InputWrapper style={{ height: 'auto' }}>
            <FormSelect
              control={control}
              data={convertRAInformation(lang, options?.RAInformation, registerAddress?.Country)}
              inputLabel={t('LEIForm.businessInfo.registrationAuthorityCode.label') + ' *'}
              selectLabel={t('LEIForm.businessInfo.registrationAuthorityCode.label') + ' *'}
              name={'registrationAuthorityCode'}
              register={businessInfo().registrationAuthorityCode}
            />
          </InputWrapper>
          <MultiInputWrapper gridGap={30}>
            <Controller
              control={control}
              name={'businessRegistrationCertificateNumber'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={columnsName.idNumber + ' *'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={{
                required: t('required.input', {
                  input: columnsName.idNumber,
                }),
              }}
            />
            {showFormControl.businessRegistrationOffice && (
              <Controller
                control={control}
                name={'businessRegistrationOffice'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('LEIForm.businessInfo.businessRegistrationOffice.label') + ' *'}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                )}
                rules={businessInfo().businessRegistrationOffice}
              />
            )}
          </MultiInputWrapper>

          <MultiInputWrapper gridGap={30}>
            <Controller
              control={control}
              name={'entityCreationDate'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  variant="standard"
                  label={t('LEIForm.basicInfo.entityCreationDate.label')}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
            {showFormControl.effectiveDateOfBusinessRegistrationCertificate && (
              <Controller
                control={control}
                name={'effectiveDateOfBusinessRegistrationCertificate'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    type="date"
                    variant="standard"
                    label={columnsName.date}
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                )}
                rules={businessInfo().effectiveDateOfBusinessRegistrationCertificate}
              />
            )}
          </MultiInputWrapper>

          {showFormControl.certificateOfIncorporationCopy && (
            <InputWrapper style={{ height: 'auto' }}>
              <InputFileWrapper>
                <InputFileLabel>
                  <Text
                    fontWeight={400}
                    fontSize={theme.fontSizes.sm}
                    color={
                      corporationCopyFileName !== ''
                        ? theme.colors.gray500
                        : !watch('certificateOfIncorporationCopy')?.[0]
                        ? theme.colors.gray300
                        : theme.colors.gray500
                    }
                  >
                    {watch('certificateOfIncorporationCopy')?.[0]
                      ? watch('certificateOfIncorporationCopy')[0].name
                      : corporationCopyFileName !== ''
                      ? corporationCopyFileName
                      : columnsName.copy + ' *'}
                  </Text>
                  <input
                    hidden
                    accept=".pdf, .png, .gif, .jpg"
                    type="file"
                    {...register('certificateOfIncorporationCopy', {
                      required: corporationCopyFileName
                        ? false
                        : watch('certificateOfIncorporationCopy')?.[0]
                        ? false
                        : t('required.input', {
                            input: columnsName.copy,
                          }),
                    })}
                  />
                  {!watch('certificateOfIncorporationCopy')?.[0] && (
                    <ErrorMessage>{errors.certificateOfIncorporationCopy?.message}</ErrorMessage>
                  )}
                  <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
                </InputFileLabel>
              </InputFileWrapper>
              <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
                JPG, GIF, PNG, {t('maxSize')} 2MB
              </Text>
            </InputWrapper>
          )}
        </>
      )}

      {provide && (
        <>
          <FormTitle>{t('info.relationshipWithDirectParentCompany')}</FormTitle>
          <MultiInputWrapper gridGap={30}>
            <FormSelect
              control={control}
              data={convertLEIOptions(lang, options?.CheckFileType)}
              inputLabel={t('LEIForm.parent.checkFileType.label') + ' *'}
              selectLabel={t('LEIForm.parent.checkFileType.label') + ' *'}
              name={'checkFileType'}
              register={relationshipForm().checkFileType}
            />
            <FormSelect
              control={control}
              data={convertLEIOptions(lang, options?.CriterionType)}
              inputLabel={t('LEIForm.parent.criterionType.label') + ' *'}
              selectLabel={t('LEIForm.parent.criterionType.label') + ' *'}
              name={'criterionType'}
              register={relationshipForm().criterionType}
            />
          </MultiInputWrapper>
          <MultiInputWrapper gridGap={30}>
            <Controller
              control={control}
              name={'relationShipStartDate'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  variant="standard"
                  label={t('LEIForm.parent.relationShipStartDate.label') + ' *'}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={relationshipForm().relationShipStartDate}
            />
            <Controller
              control={control}
              name={'relationShipEndDate'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  variant="standard"
                  label={t('LEIForm.parent.relationShipEndDate.label')}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </MultiInputWrapper>
          <MultiInputWrapper gridGap={30}>
            <Controller
              control={control}
              name={'accountStartTime'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  variant="standard"
                  label={t('LEIForm.parent.accountStartTime.label') + ' *'}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={relationshipForm().accountStartTime}
            />
            <Controller
              control={control}
              name={'accountEndTime'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  variant="standard"
                  label={t('LEIForm.parent.accountEndTime.label') + ' *'}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={relationshipForm().accountEndTime}
            />
          </MultiInputWrapper>
          <MultiInputWrapper gridGap={30}>
            <Controller
              control={control}
              name={'docStartTime'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  variant="standard"
                  label={t('LEIForm.parent.docStartTime.label')}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
            <Controller
              control={control}
              name={'docEndTime'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  variant="standard"
                  label={t('LEIForm.parent.docEndTime.label') + ' *'}
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={relationshipForm().docEndTime}
            />
          </MultiInputWrapper>
          <InputWrapper>
            <Flex alignItems={'center'}>
              <Text>{t('LEIForm.parent.holdingPercent.label')}：</Text>
              <NumberCount
                control={control}
                name={'directHoldingPercent'}
                add={calcNumber().add}
                minus={calcNumber().minus}
              />
              <Box width={460}>
                <Text>{t('LEIForm.parent.holdingPercent.placeholder')}</Text>
              </Box>
            </Flex>
          </InputWrapper>
          {/* <InputWrapper style={{ height: 'auto' }}>
            <Controller
              control={control}
              name={'quantitativeAttributes'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('LEIForm.parent.quantitativeAttributes.label')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={relationshipForm().quantitativeAttributes}
            />
          </InputWrapper> */}
          <InputWrapper style={{ height: 'auto' }}>
            <InputFileWrapper>
              <InputFileLabel>
                <Text
                  fontWeight={400}
                  fontSize={theme.fontSizes.sm}
                  color={
                    certificateDocumentName !== ''
                      ? theme.colors.gray500
                      : !watch('cerifiedDocuments')?.[0]
                      ? theme.colors.gray300
                      : theme.colors.gray500
                  }
                >
                  {watch('cerifiedDocuments')?.[0]
                    ? watch('cerifiedDocuments')[0].name
                    : certificateDocumentName !== ''
                    ? certificateDocumentName
                    : t('LEIForm.parent.cerifiedDocuments.label') + ' *'}
                </Text>
                <input
                  hidden
                  accept=".pdf, .png, .gif, .jpg"
                  type="file"
                  {...register('cerifiedDocuments', {
                    required:
                      certificateDocumentName !== ''
                        ? false
                        : watch('cerifiedDocuments')?.[0]
                        ? false
                        : t('required.input', { input: t('LEIForm.parent.cerifiedDocuments.label') }),
                  })}
                />
                {!watch('cerifiedDocuments')?.[0] && <ErrorMessage>{errors.cerifiedDocuments?.message}</ErrorMessage>}
                <CloudUpload.CloudUploadSVG style={{ marginLeft: 'auto' }} />
              </InputFileLabel>
            </InputFileWrapper>
            <Text marginTop={10} fontWeight={400} color={theme.colors.gray300}>
              JPG, GIF, PNG, {t('maxSize')} 2MB
            </Text>
          </InputWrapper>
          {/* <InputWrapper style={{ height: 'auto' }}>
            <Controller
              control={control}
              name={'fileCheckUrl'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('LEIForm.parent.fileCheckUrl.label') + ' *'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={relationshipForm().fileCheckURL}
            />
          </InputWrapper> */}
        </>
      )}

      <TabsButton selectedTab={4} setSelectedTab={setSelectedTab} />
    </Wrapper>
  );
};

export default DirectParent;

import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useCurrentLang = () => {
  // @ts-ignore
  const { i18n } = useTranslation();
  const { userData } = useAppSelector(getAuthSelector);
  const [lang, setLang] = useState<string>(userData.acc_firstName ? userData.lang : i18n.language);

  useEffect(() => {
    if (userData.acc_firstName) {
      setLang(userData.lang);
    } else {
      setLang(i18n.language);
    }
  }, [userData, i18n.language]);
  return lang;
};

export default useCurrentLang;

import { useTranslation } from 'react-i18next';

import { Flex } from '@/layout';
import Company from '@/components/Company';
import { Button } from '@/components/UI/Button';
import { Text, UnderlineText } from '@/components/UI/Typography/Text';
import { JoinedStatus } from '../JoinedStatus';

import { removeParticipant } from '@/api/deal.api';

import { theme } from '@/theme/Theme';

interface InvitedCompanyProps {
  invitedParticipantList?: {
    company: DealInvitedCompany[];
    personal: DealInvitedPersonal[];
  };
  setInvitedParticipantList: React.Dispatch<
    React.SetStateAction<
      | {
          company: DealInvitedCompany[];
          personal: DealInvitedPersonal[];
        }
      | undefined
    >
  >;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvitedCompany = ({
  invitedParticipantList,
  setInvitedParticipantList,
  setIsLoading,
  setSelectedTab,
}: InvitedCompanyProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <>
      {invitedParticipantList && invitedParticipantList.company.length !== 0 ? (
        <Flex flexDirection={'column'} gridGap={'15px'}>
          {invitedParticipantList?.company.map((data) => (
            <Company
              key={`company-${data.id}`}
              data={data.user}
              children={
                <Flex gridGap={'15px'}>
                  <JoinedStatus joined={data.joined} />
                  <Button
                    color={theme.colors.danger}
                    onClick={async () => {
                      setIsLoading(true);
                      try {
                        const res = (await removeParticipant({ acc_id: data.acc_id, deal_id: data.deal_id })).data;
                        setInvitedParticipantList(res.data);
                      } catch (error) {}
                      setIsLoading(false);
                    }}
                  >
                    {t('buttons.delete')}
                  </Button>
                </Flex>
              }
            />
          ))}
        </Flex>
      ) : (
        <Flex>
          <Text color={theme.colors.gray300} fontSize={theme.fontSizes.md} marginRight={20}>
            {t('pages.dealInitiation.formStepper.step3.acceptedList.company.noData')}
          </Text>
          <UnderlineText fontSize={theme.fontSizes.md} onClick={() => setSelectedTab(2)}>
            {t('buttons.go')}
          </UnderlineText>
        </Flex>
      )}
    </>
  );
};

import { Box, Flex } from '@/layout';
import { useTranslation } from 'react-i18next';
import { ContainerWrapper, LogoPic, Title } from './style';
import useResize from '@/hooks/useResize';

const StrategicPartners = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();

  return (
    <ContainerWrapper>
      <Title isMobile={isMobile}>{t('pages.index.StrageticPartners.title')}</Title>
      <Box>
        <Flex
          flexDirection={isTablet ? 'column' : 'row'}
          alignItems={'center'}
          justifyContent={'center'}
          flexWrap={'wrap'}
        >
          <Flex flexDirection={isTablet ? 'column' : 'row'} alignItems={'center'}>
            <LogoPic src="/images/logos/AAIL.png" alt="AAIL" />
            <LogoPic src="/images/logos/HKBA.png" alt="HKBA" />
            <LogoPic src="/images/logos/LSHK.png" alt="LSHK" />
          </Flex>
          <Flex flexDirection={isTablet ? 'column' : 'row'} alignItems={'center'}>
            <LogoPic src="/images/logos/ACAOL.png" alt="ACAOL" />
            <LogoPic src="/images/logos/CLS.png" alt="CLS" />
            <LogoPic src="/images/logos/LSCM.png" alt="LSCM" />
            <LogoPic src="/images/logos/CGCC-HK.png" alt="CGCC-HK" />
          </Flex>
          <Flex flexDirection={isTablet ? 'column' : 'row'} alignItems={'center'}>
            <LogoPic src="/images/logos/HKSTP.png" alt="HKSTP" />
            <LogoPic src="/images/logos/HKTDC.png" alt="HKTDC" />
            <LogoPic src="/images/logos/HKCPS.png" alt="HKCPS" />
            <LogoPic src="/images/logos/HKCPA.png" alt="HKCPA" />
          </Flex>
          <Flex flexDirection={isTablet ? 'column' : 'row'} alignItems={'center'}>
            <LogoPic src="/images/logos/CTA.png" alt="CTA" />
            <LogoPic src="/images/logos/SCAACPA.png" alt="SCAACPA" />
            <LogoPic src="/images/logos/Cyberport.png" alt="Cyberport" />
            <LogoPic src="/images/logos/Certizen.png" alt="Certizen" />
          </Flex>
        </Flex>
      </Box>

      <Box marginTop={isTablet ? 80 : 160}>
        <Title isMobile={isMobile}>{t('pages.index.StrageticPartners.supportingOrganisation')}</Title>
        <Flex justifyContent={'center'} flexWrap={'wrap'}>
          <img width={330} src="/images/logos/HKGCC.png" alt="HKGCC" />
        </Flex>
      </Box>
    </ContainerWrapper>
  );
};

export default StrategicPartners;

import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, TextField } from '@mui/material';
import { ModalForm, ModalTitle } from './styles';
import { Close, Grade } from '@/components/Icon';
import { Box, Flex, LoadingPage } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { errorHandler, successHandler } from '@/utils/toastHandler';
import { cancelDeal, completedDealExecution, ratingToDeal, requestConfirmCompletion } from '@/api/deal.api';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { Controller, useForm } from 'react-hook-form';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import ChattingWindow from '@/components/Chat/ChattingWindow';

interface ModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  dealData: DealListItem;
  getOppositeParticipantCompany?: () => string;
  haveGuest?: boolean;
}

export const DealCancelModal = ({
  showModal,
  setShowModal,
  dealData,
  getOppositeParticipantCompany = () => '',
}: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (!dealData) return;

    const rowData = {
      deal_id: dealData.id,
      cancel: 1,
    };
    try {
      await cancelDeal(rowData);

      if (dealData.deal_close[0]) {
        successHandler(t('success.dealCancelRequest'));
      } else {
        successHandler(t('success.dealCanceled'));
        navigate('/deal', { replace: true });
      }
    } catch (error) {
      console.log(error);
      errorHandler(t('error.dealCancelRequest'));
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm onSubmit={(e) => onSubmit(e)}>
        <LoadingPage isLoading={isLoading} />
        <Close.ButtonClose
          onClick={() => {
            setShowModal(false);
          }}
        >
          <Close.CloseSVG />
        </Close.ButtonClose>
        <Box margin={'auto'} textAlign={'center'}>
          {dealData.deal_close[0] && (
            <>
              <Text>{t('pages.dealDetail.modal.cancelRequest.titleTop')}</Text>
              <Text color={'#0B319D'} fontWeight={'bold'}>
                {getOppositeParticipantCompany()}
              </Text>
              <Text>{t('pages.dealDetail.modal.cancelRequest.titleBottom')}</Text>
              <hr style={{ margin: '30px 0', borderColor: '#E9E9E9' }} />
            </>
          )}
          <Text>{t('pages.dealDetail.modal.cancelRequest.description')}</Text>
          <Box style={{ marginTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              onClick={() => setShowModal(false)}
              type={'button'}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              marginRight={40}
            >
              {t('buttons.no')}
            </Button>
            <Button size={'lg'} type={'submit'} variant={'danger'} color="primary">
              {t('buttons.cancelDeal')}
            </Button>
          </Box>
        </Box>
      </ModalForm>
    </Modal>
  );
};

export const DealCancelRequestModal = ({ showModal, setShowModal, dealData }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  const getOppositeParticipantCompany = () => {
    if (!dealData) return '';
    const participant = dealData.participants.find((item) => item.acc_id !== userData.id);
    if (!participant) return '';
    return participant.company_name;
  };

  const onCancelDeal = async (cancel: number) => {
    setIsLoading(true);
    if (!dealData) return;

    const rowData = {
      deal_id: dealData.id,
      cancel,
    };
    try {
      await cancelDeal(rowData);

      if (cancel === 1) {
        successHandler(t('success.dealCanceled'));
        navigate('/deal', { replace: true });
      } else {
        successHandler(t('success.dealReject'));
      }
    } catch (error) {
      console.log(error);
      errorHandler(t('error.dealCancelRequest'));
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <LoadingPage isLoading={isLoading} />
        <Box margin={'auto'} textAlign={'center'}>
          <Text color={'#0B319D'} fontWeight={'bold'}>
            {getOppositeParticipantCompany()}
          </Text>
          <Text>{t('pages.dealDetail.modal.receiveRequest.titleTop')}</Text>
          <Text>{t('pages.dealDetail.modal.receiveRequest.titleBottom')}</Text>
          <Box style={{ marginTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              onClick={() => onCancelDeal(1)}
              type={'button'}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              marginRight={40}
            >
              {t('buttons.ok')}
            </Button>
            <Button
              size={'lg'}
              variant={'primary-invert'}
              type={'button'}
              color="primary"
              onClick={() => onCancelDeal(0)}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.cancel')}
            </Button>
          </Box>
        </Box>
      </ModalForm>
    </Modal>
  );
};

interface RateModalProps {
  dealRowData: DealDetail | undefined;
  setDealData: Dispatch<SetStateAction<DealListItem | undefined>>;
  setDealRowData: Dispatch<SetStateAction<DealDetail | undefined>>;
  initData: () => Promise<void>;
}

export const DealRateModal = ({
  showModal,
  setShowModal,
  dealData,
  dealRowData,
  setDealData,
  setDealRowData,
  initData,
}: ModalProps & RateModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState(5);

  const { handleSubmit, control } = useForm<{ comment: string }>();

  const { inputRegister } = useAuthValidation();

  const gradeGenerate = () => {
    const gradeAry: JSX.Element[] = [];
    for (let i = 0; i < 5; i++) {
      if (i <= grade) {
        gradeAry.push(
          <Grade.GradeFillSVG
            key={i}
            onClick={() => {
              setGrade(i);
            }}
            style={{ cursor: 'pointer' }}
            fill={'#FFC107'}
          />
        );
      } else {
        gradeAry.push(
          <Grade.GradeSVG
            key={i}
            onClick={() => {
              setGrade(i);
            }}
            style={{ cursor: 'pointer' }}
            fill={'#BCBCBC'}
          />
        );
      }
    }
    return gradeAry;
  };

  const onRateSubmit = handleSubmit(async (data) => {
    if (!dealData) return;
    if (!dealRowData) return;
    setIsLoading(true);

    const rowData = {
      ...data,
      deal_id: dealData.id,
      score: grade,
    };
    try {
      const res = (await ratingToDeal(rowData)).data;
      if (res.success) {
        if (!id) return;
        initData();
        try {
          const res = (await completedDealExecution(id)).data;
          setDealData({ ...dealData, progress: '2', rating_stage: '2' });
          setDealRowData({ ...dealRowData, progress: '2', rating_stage: '2' });
          successHandler(t('success.rating'));
        } catch (error) {
          console.log(error);
          errorHandler(t('error.rating'));
        } finally {
          setIsLoading(false);
          setShowModal(false);
        }
      }
    } catch (error) {}
  });
  return (
    <Modal
      open={showModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm onSubmit={onRateSubmit}>
        <LoadingPage isLoading={isLoading} />

        <ModalTitle>{t('pages.dealDetail.modal.rate.title')}</ModalTitle>
        <Text color={'#999999'}>{t('pages.dealDetail.modal.rate.description')}</Text>
        <hr style={{ margin: '15px 0', borderColor: '#E9E9E9' }} />
        {/* 評分用 */}
        <Text>{t('pages.dealDetail.modal.rate.formTitle')}：</Text>
        <Flex gridGap={15}>
          {gradeGenerate().map((item) => {
            return item;
          })}
        </Flex>
        <Box style={{ padding: '15px 0' }}>
          <Controller
            control={control}
            name={'comment'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.comment.label')}
                variant="outlined"
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                rows={4}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={inputRegister}
          />
        </Box>
        <hr style={{ margin: '15px 0', borderColor: '#E9E9E9' }} />
        <Box style={{ marginTop: '50px', display: 'flex', alignItems: 'center' }}>
          <Button type={'submit'} size={'lg'} variant={'primary'} rounded={false} marginRight={40}>
            {t('buttons.rate')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'underline' }}
          >
            {t('buttons.back')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

export const DealCompletionModal = ({
  showModal,
  setShowModal,
  dealData,
  dealRowData,
  setDealData,
  setDealRowData,
  initData,
  getOppositeParticipantCompany = () => '',
  haveGuest,
}: ModalProps & RateModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const submitRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (!dealData) return;
    if (!dealRowData) return;

    // 針對非平台用戶進行直接結束的動作
    if (haveGuest) {
      try {
        const data = { isAccept: true };
        await requestConfirmCompletion(dealData.id, data);
        if (!dealRowData) return;
        setDealRowData({ ...dealRowData, progress: '2', rating_stage: '1' });
        setDealData({ ...dealData, progress: '2', rating_stage: '1' });
        successHandler(t('success.confirm'));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setShowModal(false);
      }
    } else {
      try {
        const res = (await requestConfirmCompletion(dealData.id)).data;
        setDealData({ ...dealData, progress: '99', rating_stage: '1' });
        setDealRowData({ ...dealRowData, progress: '99', rating_stage: '1' });
        successHandler(t('success.confirmCompletion'));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setShowModal(false);
      }
    }
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm onSubmit={(e) => submitRequest(e)}>
        <LoadingPage isLoading={isLoading} />

        {!haveGuest && (
          <Box maxWidth={'500px'} width={'100%'} height={'100%'} margin={'auto'} textAlign={'center'}>
            <Text>{t('pages.dealDetail.modal.completionRequest.titleTop')}</Text>
            <Text color={'#0B319D'} fontWeight={'bold'}>
              {getOppositeParticipantCompany()}
            </Text>
            <Text>{t('pages.dealDetail.modal.completionRequest.titleBottom')}</Text>
            <hr style={{ margin: '30px 0', borderColor: '#E9E9E9' }} />
            <Text>{t('pages.dealDetail.modal.completionRequest.description')}</Text>
            <Box style={{ marginTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button type={'submit'} size={'lg'} variant={'primary'} rounded={false} marginRight={40}>
                {t('buttons.ok')}
              </Button>
              <Button
                size={'lg'}
                variant={'primary-invert'}
                color="primary"
                onClick={() => setShowModal(false)}
                style={{ textDecoration: 'underline' }}
              >
                {t('buttons.cancel')}
              </Button>
            </Box>
          </Box>
        )}

        {haveGuest && (
          <Box maxWidth={'500px'} width={'100%'} height={'100%'} margin={'auto'} textAlign={'center'}>
            <Text>
              {t('pages.dealDetail.modal.noneDMPuserCompletion.title', { email: getOppositeParticipantCompany() })}
            </Text>
            <Text>{t('pages.dealDetail.modal.noneDMPuserCompletion.content')}</Text>
            <Box style={{ marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button type={'submit'} size={'lg'} variant={'primary'} rounded={false} marginRight={40}>
                {t('buttons.ok')}
              </Button>
              <Button
                size={'lg'}
                variant={'primary-invert'}
                color="primary"
                onClick={() => setShowModal(false)}
                style={{ textDecoration: 'underline' }}
              >
                {t('buttons.cancel')}
              </Button>
            </Box>
          </Box>
        )}
      </ModalForm>
    </Modal>
  );
};

export const DealCompletionRequestModal = ({
  showModal,
  setShowModal,
  dealData,
  dealRowData,
  setDealData,
  setDealRowData,
  initData,
  getOppositeParticipantCompany = () => '',
  haveGuest,
}: ModalProps & RateModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const completionConfirm = async (isAccept: boolean) => {
    if (!dealData) return;
    const data = {
      isAccept,
    };
    try {
      await requestConfirmCompletion(dealData.id, data);
      if (isAccept) {
        if (!dealRowData) return;
        setDealRowData({ ...dealRowData, progress: '2', rating_stage: '1' });
        setDealData({ ...dealData, progress: '2', rating_stage: '1' });
        successHandler(t('success.confirm'));
      } else {
        if (!dealRowData) return;
        setDealRowData({ ...dealRowData, progress: '1', rating_stage: '1' });
        setDealData({ ...dealData, progress: '1', rating_stage: '1' });
        successHandler(t('success.reject'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <LoadingPage isLoading={isLoading} />
        <Box maxWidth={'500px'} width={'100%'} height={'100%'} margin={'auto'} textAlign={'center'}>
          <Text color={'#0B319D'} fontWeight={'bold'}>
            {getOppositeParticipantCompany()}
          </Text>
          <Text>{t('pages.dealDetail.modal.receiveCompletionRequest.titleTop')}</Text>
          <Text>{t('pages.dealDetail.modal.receiveCompletionRequest.titleBottom')}</Text>
          <hr style={{ margin: '30px 0', borderColor: '#E9E9E9' }} />
          <Text>{t('pages.dealDetail.modal.receiveCompletionRequest.description')}</Text>
          <Box style={{ marginTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              type={'button'}
              onClick={() => completionConfirm(true)}
              size={'lg'}
              variant={'primary'}
              rounded={false}
              marginRight={40}
            >
              {t('buttons.ok')}
            </Button>
            <Button
              type={'button'}
              size={'lg'}
              variant={'primary-invert'}
              color="primary"
              onClick={() => completionConfirm(false)}
              style={{ textDecoration: 'underline' }}
            >
              {t('buttons.cancel')}??
            </Button>
          </Box>
        </Box>
      </ModalForm>
    </Modal>
  );
};

interface DealChatModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  chatRoomData: ChatList;
}

export const DealChatModal = ({ showModal, setShowModal, chatRoomData }: DealChatModalProps) => {
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <Box maxWidth={'930px'} width={'100%'} height={'100%'} display={'flex'} margin={'auto'} alignItems={'center'}>
        <ChattingWindow id={chatRoomData.id} chatRoomData={chatRoomData} />
      </Box>
    </Modal>
  );
};

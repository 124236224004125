import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得通知列表
 */
export const getUserNotifications = async () => {
  return axios.get(`/api/notifications`);
};

export const readSingleNotification = async (id: number) => {
  return axios.get(`/api/notifications/${id}/read`);
};

export const readAllNotifications = async () => {
  return axios.get(`/api/notifications/readAll`);
};

// 刪除通知, data要已陣列形式傳入
export const notificationDestroy = async (data: { ids: number[] }) => {
  return axios.post(`/api/notifications/destroy`, data);
};
import styled from 'styled-components';
import * as system from 'styled-system';

const ChartsWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: ${(p) => (p.isMobile ? '100%' : 'auto')};
  background-color: ${(p) => p.theme.colors.light};
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.shadows.base};
  padding: 20px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 24px;

  .plan {
    color: ${(p) => p.theme.colors.secondary500};
    font-size: ${(p) => p.theme.fontSizes.sm};
  }
`;

const DealsWrapper = styled.div`
  text-align: center;

  .number {
    font-weight: 700;
    font-size: ${(p) => p.theme.fontSizes.lg};
  }

  .unit {
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.sm};
    color: ${(p) => p.theme.colors.gray400};
  }
`;

const PlanWrapper = styled.div`
  width: 100%;
  text-wrap: balance;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: ${(p) => p.theme.fontSizes.base};

  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid ${(p) => p.theme.colors.gray100};

    .name {
      max-width: 75%;
    }

    .times {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const hr = system.compose(system.space, system.border);

const Hr = styled('hr')<system.SpaceProps & system.BorderProps>(
  {
    borderTop: `1px solid #000`,
  },
  hr
);

export { ChartsWrapper, Title, Hr, DealsWrapper, PlanWrapper };

import { H1 } from '@/components/UI/Typography/Heading';

import { Background, Content, Title, Wrapper } from './styles';
import useResize from '@/hooks/useResize';

interface AuthContainerProps {
  title: string;
  children: React.ReactNode;
}

const AuthContainer = ({ title, children }: AuthContainerProps) => {
  const {isTablet, isMobile} = useResize();

  return (
    <Wrapper isTablet={isTablet} isMobile={isMobile}>
      <Background />
      <Content isTablet={isTablet}>
        <Title isMobile={isMobile}>{title}</Title>
        {children}
      </Content>
    </Wrapper>
  );
};

export default AuthContainer;

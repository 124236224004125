import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1320px;
  margin: 0 auto 50px;
`;

const ContactWrapper = styled.div`
  width: 100%;
  max-width: 1090px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  line-height: 70.31px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 30px;
  text-align: center;
`;

const Card = styled.div`
  width 350px;
  height: 100px;
  border: 2px solid ${(p) => p.theme.colors.primary100};
  border-radius: 15px 15px 15px 0px;
  padding: 15px 39px 15px 30px;
  display: flex;
  gap: 30px;
  align-items: center;
`;

const CardIcon = styled.img`
  width: 48px;
  height: 48px;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  line-height: 23px;
  color: ${(p) => p.theme.colors.primary500};
`;

const CardDescription = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 18.75px;
  color: ${(p) => p.theme.colors.gray400};

  a,
  span {
    color: ${(p) => p.theme.colors.gray400};
  }
`;

export { ContainerWrapper, Title, ContactWrapper, Card, CardTitle, CardIcon, CardInfo, CardDescription };

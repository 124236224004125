import styled from 'styled-components';
import { darken, lighten } from 'polished';

import { ReactComponent as EditSVG } from './edit.svg';

const FormEdit = styled.button`
  width: 24px;
  height: 24px;
  cursor: pointer;
  path {
    fill: ${(p) => p.theme.colors.primary500};
  }
  :hover {
    path {
      fill: ${(p) => lighten(0.03, p.theme.colors.primary400)};
    }
  }
  :active {
    path {
      fill: ${(p) => darken(0.03, p.theme.colors.primary400)};
    }
  }
`;

export { FormEdit, EditSVG };

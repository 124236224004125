import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Conferencing, Copy } from '@/components/Icon';
import { Button } from '@/components/UI/Button';
import { Flex } from '@/layout';
import { H4 } from '@/components/UI/Typography/Heading';

import { generateLink } from '@/api/event.api';
import { errorHandler, successHandler } from '@/utils/toastHandler';
import { Text } from '@/components/UI/Typography/Text';

const Wrapper = styled.div`
  width: 100%;
  padding: 140px 0;
  max-width: 930px;
  background: ${(p) => p.theme.colors.light};
  border-radius: 15px 15px 0 0;
`;

const LinkWrapper = styled.div`
  width: 425px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(p) => p.theme.colors.gray100};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const VideoConferencingForm = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [link, setLink] = useState('');

  const handleGenerateLink = async () => {
    try {
      const res = (await generateLink()).data;
      setLink('https://ebram-backend.oao.lol/join-meeting/' + res.data.meetingID);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyLink = () => {
    if (!link) return errorHandler('Please generate meeting link first');
    navigator.clipboard.writeText(link);
    successHandler(t('success.copied'));
  };

  return (
    <Wrapper>
      <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <Conferencing.ConferencingSVG />
        {link ? (
          <Text textAlign={'center'} marginBottom={24}>
            You can copy this link and send it to the meeting invitee.<br/>Be sure to save the link for future use.
          </Text>
        ) : (
          <Button onClick={handleGenerateLink} size={'lg'} variant={'primary-invert'} rounded={false} marginBottom={24}>
            Create Meeting Link
          </Button>
        )}
        <LinkWrapper onClick={handleCopyLink}>
          <H4 style={{ width: 'calc(100% - 24px)', wordBreak: 'break-all' }}>{link}</H4>
          <Copy.CopySVG fill={'#3C5AB1'} />
        </LinkWrapper>
      </Flex>
    </Wrapper>
  );
};
export default VideoConferencingForm;

import { useTranslation } from 'react-i18next';
import { ContainerWrapper, Title, ContactWrapper, Card, CardTitle, CardIcon, CardInfo, CardDescription } from './style';

const Contact = () => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <ContainerWrapper>
      <Title id="Contact">{t('pages.index.Contact.title')}</Title>
      <ContactWrapper>
        <Card>
          <CardIcon src="/images/index/call.svg" />
          <CardInfo>
            <CardTitle>{t('pages.index.Contact.generalEnquiry')}</CardTitle>
            <CardDescription>(+852) 3580 8280</CardDescription>
          </CardInfo>
        </Card>
        <Card>
          <CardIcon src="/images/index/call.svg" />
          <CardInfo>
            <CardTitle>{t('pages.index.Contact.technicalSupport')}</CardTitle>
            <CardDescription>(+852) 3580 8279</CardDescription>
          </CardInfo>
        </Card>
        <Card>
          <CardIcon src="/images/index/mail.svg" />
          <CardInfo>
            <CardTitle>{t('pages.index.Contact.email')}</CardTitle>
            <CardDescription>
              <a href="mailto:dealmaking@ebram.org">
                <span>dealmaking@ebram.org</span>
              </a>
            </CardDescription>
          </CardInfo>
        </Card>
      </ContactWrapper>
    </ContainerWrapper>
  );
};

export default Contact;

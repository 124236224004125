import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import VideoConferencingForm from './Form';

const VideoConferencing = () => {
    // @ts-ignore
    const { t } = useTranslation();

  return (
    <PageContainer title={t('meeting')}>
      <VideoConferencingForm />
    </PageContainer>
  );
};
export default VideoConferencing;

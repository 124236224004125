import styled from 'styled-components';

const SectionTitle = styled.div`
  color: ${(p) => p.theme.colors.primary400};
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 700;
  margin-bottom: 15px;
`;

const InfoWrapper = styled.div`
  padding-bottom: 5px;
  border-bottom: 1px solid ${(p) => p.theme.colors.gray100};

  .info {
    display: flex;
    font-size: ${(p) => p.theme.fontSizes.base};
    margin-bottom: 10px;

    .title {
      flex-basis: 260px;
      color: ${(p) => p.theme.colors.gray300};
    }

    .content {
      flex: 1;
      color: ${(p) => p.theme.colors.gray500};
    }
  }
`;

const OderWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
  border-bottom: 2px solid ${(p) => p.theme.colors.gray200};

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-top: 2px solid ${(p) => p.theme.colors.gray200};
    font-size: ${(p) => p.theme.fontSizes.base};
    color: ${(p) => p.theme.colors.gray500};

    .name {
    }
    .detail {
      display: flex;
      gap: 50px;

      &.multi {
        gap: 35px;
      }
    }

    &:nth-child(3) {
      border-top: none;
    }

    &.header {
      height: 65px;
      padding: 0;
      border: 2px solid ${(p) => p.theme.colors.gray500};
      
      * {
        display: flex;
        align-items: center;
      }
      
      .name {
        height: 100%;
        padding: 0 10px;
      }

      .detail {
        height: 100%;
      
        .option {
          height: 100%;
          border-left: 2px solid ${(p) => p.theme.colors.gray500};
          padding-left: 10px;
        }
      
        .totalPayment {
          width: 75px;
          height: 100%;
          border-left: 2px solid ${(p) => p.theme.colors.gray500};
          word-break: keep-all;
        }
      }
    }
  }
`;

export { SectionTitle, InfoWrapper, OderWrapper };

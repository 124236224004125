import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authSlice from './auth/auth.slice';
import cartSlice from './cart/cart.slice';
import dataTableSlice from './dataTable/dataTable.slice';
import notificationSlice from './notification/notification.slice';
import langSlice from './lang/lang.slice';

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: '',
};

const cartPersistConfig = {
  key: 'cart',
  storage,
  keyPrefix: '',
};

const notificationPersistConfig = {
  key: 'notification',
  storage,
  keyPrefix: '',
}

const langPersistConfig = {
  key: 'lang',
  storage,
  keyPrefix: '',
}


const rootReducer = combineReducers({
  auth: persistReducer(userPersistConfig, authSlice),
  cart: persistReducer(cartPersistConfig, cartSlice),
  dataTable: dataTableSlice,
  notification: persistReducer(notificationPersistConfig, notificationSlice),
  lang: persistReducer(langPersistConfig, langSlice),
});

export default rootReducer;

const countryEn = [
  {
    name: 'China',
    countryCode: '+86',
    timezone: 'GMT+8',
    currency: 'Chinese Yuan',
    language: 'Mandarin',
  },
  {
    name: 'Hong Kong',
    countryCode: '+852',
    timezone: 'GMT+8',
    currency: 'Hong Kong Dollar',
    language: 'Cantonese',
  },
  {
    name: 'Macao',
    countryCode: '+853',
    timezone: 'GMT+8',
    currency: 'Macanese Pataca',
    language: 'Mandarin',
  },
  {
    name: 'Afghanistan',
    countryCode: '+93',
    timezone: 'GMT+4:30',
    currency: 'Afghan Afghani',
    language: 'Pashto',
  },
  {
    name: 'Aland Islands',
    countryCode: '+358',
    timezone: 'GMT+3',
    currency: 'Euro',
    language: 'Swedish',
  },
  {
    name: 'Albania',
    countryCode: '+355',
    timezone: 'GMT+2',
    currency: 'Albanian Lek',
    language: 'Albanian',
  },
  {
    name: 'American Samoa',
    countryCode: '+1684',
    timezone: 'GMT-11',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Andorra',
    countryCode: '+376',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Catalan',
  },
  {
    name: 'Angola',
    countryCode: '+244',
    timezone: 'GMT+1',
    currency: 'Angolan Kwanza',
    language: 'Portuguese',
  },
  {
    name: 'Anguilla',
    countryCode: '+1264',
    timezone: 'GMT-4',
    currency: 'East Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Antigua and Barbuda',
    countryCode: '+1268',
    timezone: 'GMT-4',
    currency: 'East Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Argentina',
    countryCode: '+54',
    timezone: 'GMT-3',
    currency: 'Argentine Peso',
    language: 'Spanish',
  },
  {
    name: 'Armenia',
    countryCode: '+374',
    timezone: 'GMT+4',
    currency: 'Armenian Dram',
    language: 'Armenian',
  },
  {
    name: 'Aruba',
    countryCode: '+297',
    timezone: 'GMT-4',
    currency: 'Aruban Florin',
    language: 'Dutch',
  },
  {
    name: 'Australia',
    countryCode: '+61',
    timezone: 'GMT+8',
    currency: 'Australian Dollar',
    language: 'English',
  },
  {
    name: 'Austria',
    countryCode: '+43',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'German',
  },
  {
    name: 'Azerbaijan',
    countryCode: '+994',
    timezone: 'GMT+4',
    currency: 'Azerbaijani Manat',
    language: 'Azerbaijani',
  },
  {
    name: 'Bahamas',
    countryCode: '+1242',
    timezone: 'GMT-4',
    currency: 'Bahamian Dollar',
    language: 'English',
  },
  {
    name: 'Bahrain',
    countryCode: '+973',
    timezone: 'GMT+3',
    currency: 'Bahraini Dinar',
    language: 'Arabic',
  },
  {
    name: 'Bangladesh',
    countryCode: '+880',
    timezone: 'GMT+6',
    currency: 'Bangladeshi Taka',
    language: 'Bengali',
  },
  {
    name: 'Barbados',
    countryCode: '+1246',
    timezone: 'GMT-4',
    currency: 'Barbadian Dollar',
    language: 'English',
  },
  {
    name: 'Belarus',
    countryCode: '+375',
    timezone: 'GMT+3',
    currency: 'Belarusian Ruble',
    language: 'Russian',
  },
  {
    name: 'Belgium',
    countryCode: '+32',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Dutch',
  },
  {
    name: 'Belize',
    countryCode: '+501',
    timezone: 'GMT-6',
    currency: 'Belize Dollar',
    language: 'English',
  },
  {
    name: 'Benin',
    countryCode: '+229',
    timezone: 'GMT+1',
    currency: 'West African CFA Franc',
    language: 'French',
  },
  {
    name: 'Bermuda',
    countryCode: '+1441',
    timezone: 'GMT-3',
    currency: 'Bermudian Dollar',
    language: 'English',
  },
  {
    name: 'Bhutan',
    countryCode: '+975',
    timezone: 'GMT+6',
    currency: 'Bhutanese Ngultrum',
    language: 'Dzongkha',
  },
  {
    name: 'Bolivia, Plurinational State of',
    countryCode: '+591',
    timezone: 'GMT-4',
    currency: 'Bolivian Boliviano',
    language: 'Spanish',
  },
  {
    name: 'Bosnia and Herzegovina',
    countryCode: '+387',
    timezone: 'GMT+2',
    currency: 'Bosnia-Herzegovina Convertible Mark',
    language: 'Bosnian',
  },
  {
    name: 'Botswana',
    countryCode: '+267',
    timezone: 'GMT+2',
    currency: 'Botswana Pula',
    language: 'English',
  },
  {
    name: 'Brazil',
    countryCode: '+55',
    timezone: 'GMT-3',
    currency: 'Brazilian Real',
    language: 'Portuguese',
  },
  {
    name: 'British Indian Ocean Territory',
    countryCode: '+246',
    timezone: 'GMT+6',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Brunei Darussalam',
    countryCode: '+673',
    timezone: 'GMT+8',
    currency: 'Brunei Dollar',
    language: 'Malay',
  },
  {
    name: 'Bulgaria',
    countryCode: '+359',
    timezone: 'GMT+3',
    currency: 'Bulgarian Lev',
    language: 'Bulgarian',
  },
  {
    name: 'Burkina Faso',
    countryCode: '+226',
    timezone: 'GMT+0',
    currency: 'West African CFA Franc',
    language: 'French',
  },
  {
    name: 'Burundi',
    countryCode: '+257',
    timezone: 'GMT+2',
    currency: 'Burundian Franc',
    language: 'French',
  },
  {
    name: 'Cambodia',
    countryCode: '+855',
    timezone: 'GMT+7',
    currency: 'Cambodian Riel',
    language: 'Khmer',
  },
  {
    name: 'Cameroon',
    countryCode: '+237',
    timezone: 'GMT+1',
    currency: 'Central African CFA Franc',
    language: 'English',
  },
  {
    name: 'Canada',
    countryCode: '+1',
    timezone: 'GMT-8',
    currency: 'Canadian Dollar',
    language: 'English',
  },
  {
    name: 'Cape Verde',
    countryCode: '+238',
    timezone: 'GMT-1',
    currency: 'Cape Verdean Escudo',
    language: 'Portuguese',
  },
  {
    name: 'Cayman Islands',
    countryCode: '+345',
    timezone: 'GMT-5',
    currency: 'Cayman Islands Dollar',
    language: 'English',
  },
  {
    name: 'Central African Republic',
    countryCode: '+236',
    timezone: 'GMT+1',
    currency: 'Central African CFA Franc',
    language: 'French',
  },
  {
    name: 'Chad',
    countryCode: '+235',
    timezone: 'GMT+1',
    currency: 'Central African CFA Franc',
    language: 'French',
  },
  {
    name: 'Chile',
    countryCode: '+56',
    timezone: 'GMT-4',
    currency: 'Chilean Peso',
    language: 'Spanish',
  },
  {
    name: 'Christmas Island',
    countryCode: '+61',
    timezone: 'GMT+7',
    currency: 'Australian Dollar',
    language: 'English',
  },
  {
    name: 'Cocos (Keeling) Islands',
    countryCode: '+61',
    timezone: 'GMT+6:30',
    currency: 'Australian Dollar',
    language: 'English',
  },
  {
    name: 'Colombia',
    countryCode: '+57',
    timezone: 'GMT-5',
    currency: 'Colombian Peso',
    language: 'Spanish',
  },
  {
    name: 'Comoros',
    countryCode: '+269',
    timezone: 'GMT+3',
    currency: 'Comorian Franc',
    language: 'Comorian',
  },
  {
    name: 'Congo',
    countryCode: '+242',
    timezone: 'GMT+1',
    currency: 'Central African CFA Franc',
    language: 'French',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    countryCode: '+243',
    timezone: 'GMT+1',
    currency: 'Congolese Franc',
    language: 'French',
  },
  {
    name: 'Cook Islands',
    countryCode: '+682',
    timezone: 'GMT-10',
    currency: 'New Zealand Dollar',
    language: 'English',
  },
  {
    name: 'Costa Rica',
    countryCode: '+506',
    timezone: 'GMT-6',
    currency: 'Costa Rican Colón',
    language: 'Spanish',
  },
  {
    name: "Cote d'Ivoire",
    countryCode: '+225',
    timezone: 'GMT+0',
    currency: 'West African CFA Franc',
    language: 'French',
  },
  {
    name: 'Croatia',
    countryCode: '+385',
    timezone: 'GMT+1',
    currency: 'Croatian Kuna',
    language: 'Croatian',
  },
  {
    name: 'Cuba',
    countryCode: '+53',
    timezone: 'GMT-5',
    currency: 'Cuban Peso',
    language: 'Spanish',
  },
  {
    name: 'Cyprus',
    countryCode: '+357',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Turkish',
  },
  {
    name: 'Czech Republic',
    countryCode: '+420',
    timezone: 'GMT+1',
    currency: 'Czech Koruna',
    language: 'Czech',
  },
  {
    name: 'Denmark',
    countryCode: '+45',
    timezone: 'GMT+1',
    currency: 'Danish Krone',
    language: 'Danish',
  },
  {
    name: 'Djibouti',
    countryCode: '+253',
    timezone: 'GMT+3',
    currency: 'Djiboutian Franc',
    language: 'French',
  },
  {
    name: 'Dominica',
    countryCode: '+1767',
    timezone: 'GMT-4',
    currency: 'East Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Dominican Republic',
    countryCode: '+1849',
    timezone: 'GMT-4',
    currency: 'Dominican Peso',
    language: 'Spanish',
  },
  {
    name: 'Ecuador',
    countryCode: '+593',
    timezone: 'GMT-5',
    currency: 'United States Dollar',
    language: 'Spanish',
  },
  {
    name: 'Egypt',
    countryCode: '+20',
    timezone: 'GMT+2',
    currency: 'Egyptian Pound',
    language: 'Arabic',
  },
  {
    name: 'El Salvador',
    countryCode: '+503',
    timezone: 'GMT-6',
    currency: 'United States Dollar',
    language: 'Spanish',
  },
  {
    name: 'Equatorial Guinea',
    countryCode: '+240',
    timezone: 'GMT+1',
    currency: 'Central African CFA Franc',
    language: 'Spanish',
  },
  {
    name: 'Eritrea',
    countryCode: '+291',
    timezone: 'GMT+3',
    currency: 'Eritrean Nakfa',
    language: 'English',
  },
  {
    name: 'Estonia',
    countryCode: '+372',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Estonian',
  },
  {
    name: 'Ethiopia',
    countryCode: '+251',
    timezone: 'GMT+3',
    currency: 'Ethiopian Birr',
    language: 'Amharic',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    countryCode: '+500',
    timezone: 'GMT-3',
    currency: 'Falkland Islands Pound',
    language: 'English',
  },
  {
    name: 'Faroe Islands',
    countryCode: '+298',
    timezone: 'GMT+0',
    currency: 'Danish Krone',
    language: 'Faroese',
  },
  {
    name: 'Fiji',
    countryCode: '+679',
    timezone: 'GMT+12',
    currency: 'Fijian Dollar',
    language: 'English',
  },
  {
    name: 'Finland',
    countryCode: '+358',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Finnish',
  },
  {
    name: 'France',
    countryCode: '+33',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'French Guiana',
    countryCode: '+594',
    timezone: 'GMT-3',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'French Polynesia',
    countryCode: '+689',
    timezone: 'GMT-10',
    currency: 'CFP Franc',
    language: 'French',
  },
  {
    name: 'Gabon',
    countryCode: '+241',
    timezone: 'GMT+1',
    currency: 'Central African CFA Franc',
    language: 'French',
  },
  {
    name: 'Gambia',
    countryCode: '+220',
    timezone: 'GMT+0',
    currency: 'Gambian Dalasi',
    language: 'English',
  },
  {
    name: 'Georgia',
    countryCode: '+995',
    timezone: 'GMT+4',
    currency: 'Georgian Lari',
    language: 'Georgian',
  },
  {
    name: 'Germany',
    countryCode: '+49',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'German',
  },
  {
    name: 'Ghana',
    countryCode: '+233',
    timezone: 'GMT+0',
    currency: 'Ghanaian Cedi',
    language: 'English',
  },
  {
    name: 'Gibraltar',
    countryCode: '+350',
    timezone: 'GMT+1',
    currency: 'Gibraltar Pound',
    language: 'English',
  },
  {
    name: 'Greece',
    countryCode: '+30',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Greek',
  },
  {
    name: 'Greenland',
    countryCode: '+299',
    timezone: 'GMT-3',
    currency: 'Danish Krone',
    language: 'Danish',
  },
  {
    name: 'Grenada',
    countryCode: '+1473',
    timezone: 'GMT-4',
    currency: 'East Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Guadeloupe',
    countryCode: '+590',
    timezone: 'GMT-4',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'Guam',
    countryCode: '+1671',
    timezone: 'GMT+10',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Guatemala',
    countryCode: '+502',
    timezone: 'GMT-6',
    currency: 'Guatemalan Quetzal',
    language: 'Spanish',
  },
  {
    name: 'Guernsey',
    countryCode: '+44',
    timezone: 'GMT+0',
    currency: 'British Pound Sterling',
    language: 'English',
  },
  {
    name: 'Guinea',
    countryCode: '+224',
    timezone: 'GMT+0',
    currency: 'Guinean Franc',
    language: 'French',
  },
  {
    name: 'Guinea-Bissau',
    countryCode: '+245',
    timezone: 'GMT+0',
    currency: 'West African CFA Franc',
    language: 'Portuguese',
  },
  {
    name: 'Guyana',
    countryCode: '+595',
    timezone: 'GMT-4',
    currency: 'Guyanese Dollar',
    language: 'English',
  },
  {
    name: 'Haiti',
    countryCode: '+509',
    timezone: 'GMT-5',
    currency: 'Haitian Gourde',
    language: 'French',
  },
  {
    name: 'Holy See (Vatican City State)',
    countryCode: '+379',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Latin',
  },
  {
    name: 'Honduras',
    countryCode: '+504',
    timezone: 'GMT-6',
    currency: 'Honduran Lempira',
    language: 'Spanish',
  },
  {
    name: 'Hungary',
    countryCode: '+36',
    timezone: 'GMT+1',
    currency: 'Hungarian Forint',
    language: 'Hungarian',
  },
  {
    name: 'Iceland',
    countryCode: '+354',
    timezone: 'GMT+0',
    currency: 'Icelandic Króna',
    language: 'Icelandic',
  },
  {
    name: 'India',
    countryCode: '+91',
    timezone: 'GMT+5:30',
    currency: 'Indian Rupee',
    language: 'Hindi',
  },
  {
    name: 'Indonesia',
    countryCode: '+62',
    timezone: 'GMT+9',
    currency: 'Indonesian Rupiah',
    language: 'Indonesian',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    countryCode: '+98',
    timezone: 'GMT+3:30',
    currency: 'Iranian Rial',
    language: 'Persian',
  },
  {
    name: 'Iraq',
    countryCode: '+964',
    timezone: 'GMT+3',
    currency: 'Iraqi Dinar',
    language: 'Arabic',
  },
  {
    name: 'Ireland',
    countryCode: '+353',
    timezone: 'GMT+0',
    currency: 'Euro',
    language: 'English',
  },
  {
    name: 'Isle of Man',
    countryCode: '+44',
    timezone: 'GMT+0',
    currency: 'British Pound Sterling',
    language: 'English',
  },
  {
    name: 'Israel',
    countryCode: '+972',
    timezone: 'GMT+2',
    currency: 'Israeli New Shekel',
    language: 'Hebrew',
  },
  {
    name: 'Italy',
    countryCode: '+39',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Italian',
  },
  {
    name: 'Jamaica',
    countryCode: '+1876',
    timezone: 'GMT-5',
    currency: 'Jamaican Dollar',
    language: 'English',
  },
  {
    name: 'Japan',
    countryCode: '+81',
    timezone: 'GMT+9',
    currency: 'Japanese Yen',
    language: 'Japanese',
  },
  {
    name: 'Jersey',
    countryCode: '+44',
    timezone: 'GMT+0',
    currency: 'British Pound Sterling',
    language: 'English',
  },
  {
    name: 'Jordan',
    countryCode: '+962',
    timezone: 'GMT+2',
    currency: 'Jordanian Dinar',
    language: 'Arabic',
  },
  {
    name: 'Kazakhstan',
    countryCode: '+77',
    timezone: 'GMT+6',
    currency: 'Kazakhstani Tenge',
    language: 'Russian',
  },
  {
    name: 'Kenya',
    countryCode: '+254',
    timezone: 'GMT+3',
    currency: 'Kenyan Shilling',
    language: 'Swahili',
  },
  {
    name: 'Kiribati',
    countryCode: '+686',
    timezone: 'GMT+12',
    currency: 'Australian Dollar',
    language: 'English',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    countryCode: '+850',
    timezone: 'GMT+9',
    currency: 'North Korean Won',
    language: 'Korean',
  },
  {
    name: 'Korea, Republic of South Korea',
    countryCode: '+82',
    timezone: 'GMT+9',
    currency: 'South Korean Won',
    language: 'Korean',
  },
  {
    name: 'Kuwait',
    countryCode: '+965',
    timezone: 'GMT+3',
    currency: 'Kuwaiti Dinar',
    language: 'Arabic',
  },
  {
    name: 'Kyrgyzstan',
    countryCode: '+996',
    timezone: 'GMT+6',
    currency: 'Kyrgyzstani Som',
    language: 'Kyrgyz',
  },
  {
    name: 'Laos',
    countryCode: '+856',
    timezone: 'GMT+7',
    currency: 'Lao Kip',
    language: 'Lao',
  },
  {
    name: 'Latvia',
    countryCode: '+371',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Latvian',
  },
  {
    name: 'Lebanon',
    countryCode: '+961',
    timezone: 'GMT+2',
    currency: 'Lebanese Pound',
    language: 'Arabic',
  },
  {
    name: 'Lesotho',
    countryCode: '+266',
    timezone: 'GMT+2',
    currency: 'Lesotho Loti',
    language: 'Sesotho',
  },
  {
    name: 'Liberia',
    countryCode: '+231',
    timezone: 'GMT+0',
    currency: 'Liberian Dollar',
    language: 'English',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    countryCode: '+218',
    timezone: 'GMT+2',
    currency: 'Libyan Dinar',
    language: 'Arabic',
  },
  {
    name: 'Liechtenstein',
    countryCode: '+423',
    timezone: 'GMT+1',
    currency: 'Swiss Franc',
    language: 'German',
  },
  {
    name: 'Lithuania',
    countryCode: '+370',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Lithuanian',
  },
  {
    name: 'Luxembourg',
    countryCode: '+352',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Luxembourgish',
  },
  {
    name: 'Macedonia',
    countryCode: '+389',
    timezone: 'GMT+1',
    currency: 'Macedonian Denar',
    language: 'Macedonian',
  },
  {
    name: 'Madagascar',
    countryCode: '+261',
    timezone: 'GMT+3',
    currency: 'Malagasy Ariary',
    language: 'Malagasy',
  },
  {
    name: 'Malawi',
    countryCode: '+265',
    timezone: 'GMT+2',
    currency: 'Malawian Kwacha',
    language: 'Chichewa',
  },
  {
    name: 'Malaysia',
    countryCode: '+60',
    timezone: 'GMT+8',
    currency: 'Malaysian Ringgit',
    language: 'Malay',
  },
  {
    name: 'Maldives',
    countryCode: '+960',
    timezone: 'GMT+5',
    currency: 'Maldivian Rufiyaa',
    language: 'Maldivian Dhivehi',
  },
  {
    name: 'Mali',
    countryCode: '+223',
    timezone: 'GMT+0',
    currency: 'West African CFA Franc',
    language: 'French',
  },
  {
    name: 'Malta',
    countryCode: '+356',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Maltese',
  },
  {
    name: 'Marshall Islands',
    countryCode: '+692',
    timezone: 'GMT+12',
    currency: 'United States Dollar',
    language: 'Marshallese',
  },
  {
    name: 'Martinique',
    countryCode: '+596',
    timezone: 'GMT-4',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'Mauritania',
    countryCode: '+222',
    timezone: 'GMT+0',
    currency: 'Mauritanian Ouguiya',
    language: 'Arabic',
  },
  {
    name: 'Mauritius',
    countryCode: '+230',
    timezone: 'GMT+4',
    currency: 'Mauritian Rupee',
    language: 'English',
  },
  {
    name: 'Mayotte',
    countryCode: '+262',
    timezone: 'GMT+3',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'Mexico',
    countryCode: '+52',
    timezone: 'GMT-7',
    currency: 'Mexican Peso',
    language: 'Spanish',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    countryCode: '+691',
    timezone: 'GMT+11',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Moldova',
    countryCode: '+373',
    timezone: 'GMT+2',
    currency: 'Moldovan Leu',
    language: 'Moldovan',
  },
  {
    name: 'Monaco',
    countryCode: '+377',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'Mongolia',
    countryCode: '+976',
    timezone: 'GMT+8',
    currency: 'Mongolian Tugrik',
    language: 'Mongolian',
  },
  {
    name: 'Montenegro',
    countryCode: '+382',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Montenegrin',
  },
  {
    name: 'Montserrat',
    countryCode: '+1664',
    timezone: 'GMT-4',
    currency: 'Eastern Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Morocco',
    countryCode: '+212',
    timezone: 'GMT+0',
    currency: 'Moroccan Dirham',
    language: 'Arabic',
  },
  {
    name: 'Mozambique',
    countryCode: '+258',
    timezone: 'GMT+2',
    currency: 'Mozambican Metical',
    language: 'Portuguese',
  },
  {
    name: 'Myanmar',
    countryCode: '+95',
    timezone: 'GMT+6:30',
    currency: 'Myanmar Kyat',
    language: 'Burmese',
  },
  {
    name: 'Namibia',
    countryCode: '+264',
    timezone: 'GMT+1',
    currency: 'Namibian Dollar',
    language: 'English',
  },
  {
    name: 'Nauru',
    countryCode: '+674',
    timezone: 'GMT+12',
    currency: 'Australian Dollar',
    language: 'Nauruan',
  },
  {
    name: 'Nepal',
    countryCode: '+977',
    timezone: 'GMT+5:45',
    currency: 'Nepalese Rupee',
    language: 'Nepali',
  },
  {
    name: 'Netherlands',
    countryCode: '+31',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Dutch',
  },
  {
    name: 'Netherlands Antilles',
    countryCode: '+599',
    timezone: 'GMT-4',
    currency: 'Netherlands Antillean Guilder',
    language: 'Dutch',
  },
  {
    name: 'New Caledonia',
    countryCode: '+687',
    timezone: 'GMT+11',
    currency: 'CFP Franc',
    language: 'French',
  },
  {
    name: 'New Zealand',
    countryCode: '+64',
    timezone: 'GMT+12',
    currency: 'New Zealand Dollar',
    language: 'English',
  },
  {
    name: 'Nicaragua',
    countryCode: '+505',
    timezone: 'GMT-6',
    currency: 'Nicaraguan Cordoba',
    language: 'Spanish',
  },
  {
    name: 'Niger',
    countryCode: '+227',
    timezone: 'GMT+1',
    currency: 'West African CFA Franc',
    language: 'French',
  },
  {
    name: 'Nigeria',
    countryCode: '+234',
    timezone: 'GMT+1',
    currency: 'Nigerian Naira',
    language: 'English',
  },
  {
    name: 'Niue',
    countryCode: '+683',
    timezone: 'GMT-11',
    currency: 'New Zealand Dollar',
    language: 'Niuean',
  },
  {
    name: 'Norfolk Island',
    countryCode: '+672',
    timezone: 'GMT+11',
    currency: 'Australian Dollar',
    language: 'English',
  },
  {
    name: 'Northern Mariana Islands',
    countryCode: '+1670',
    timezone: 'GMT+10',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Norway',
    countryCode: '+47',
    timezone: 'GMT+1',
    currency: 'Norwegian Krone',
    language: 'Norwegian',
  },
  {
    name: 'Oman',
    countryCode: '+968',
    timezone: 'GMT+4',
    currency: 'Omani Rial',
    language: 'Arabic',
  },
  {
    name: 'Pakistan',
    countryCode: '+92',
    timezone: 'GMT+5',
    currency: 'Pakistani Rupee',
    language: 'Urdu',
  },
  {
    name: 'Palau',
    countryCode: '+680',
    timezone: 'GMT+9',
    currency: 'United States Dollar',
    language: 'Palauan',
  },
  {
    name: 'Palestinian Territory, Occupied',
    countryCode: '+970',
    timezone: 'GMT+2',
    currency: 'Euro',
    language: 'Arabic',
  },
  {
    name: 'Panama',
    countryCode: '+507',
    timezone: 'GMT-5',
    currency: 'United States Dollar',
    language: 'Spanish',
  },
  {
    name: 'Papua New Guinea',
    countryCode: '+675',
    timezone: 'GMT+10',
    currency: 'Papua New Guinean Kina',
    language: 'English',
  },
  {
    name: 'Paraguay',
    countryCode: '+595',
    timezone: 'GMT-4',
    currency: 'Paraguayan Guarani',
    language: 'Spanish',
  },
  {
    name: 'Peru',
    countryCode: '+51',
    timezone: 'GMT-5',
    currency: 'Peruvian Sol',
    language: 'Spanish',
  },
  {
    name: 'Philippines',
    countryCode: '+63',
    timezone: 'GMT+8',
    currency: 'Philippine Peso',
    language: 'Filipino',
  },
  {
    name: 'Pitcairn',
    countryCode: '+872',
    timezone: 'GMT-8',
    currency: 'New Zealand Dollar',
    language: 'English',
  },
  {
    name: 'Poland',
    countryCode: '+48',
    timezone: 'GMT+1',
    currency: 'Polish Zloty',
    language: 'Polish',
  },
  {
    name: 'Portugal',
    countryCode: '+351',
    timezone: 'GMT+0',
    currency: 'Euro',
    language: 'Portuguese',
  },
  {
    name: 'Puerto Rico',
    countryCode: '+1939',
    timezone: 'GMT-4',
    currency: 'United States Dollar',
    language: 'Spanish',
  },
  {
    name: 'Qatar',
    countryCode: '+974',
    timezone: 'GMT+3',
    currency: 'Qatari Riyal',
    language: 'Arabic',
  },
  {
    name: 'Romania',
    countryCode: '+40',
    timezone: 'GMT+2',
    currency: 'Romanian Leu',
    language: 'Romanian',
  },
  {
    name: 'Russia',
    countryCode: '+7',
    timezone: 'GMT+3',
    currency: 'Russian Ruble',
    language: 'Russian',
  },
  {
    name: 'Rwanda',
    countryCode: '+250',
    timezone: 'GMT+2',
    currency: 'Rwandan Franc',
    language: 'Kinyarwanda',
  },
  {
    name: 'Reunion',
    countryCode: '+262',
    timezone: 'GMT+4',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'Saint Barthelemy',
    countryCode: '+590',
    timezone: 'GMT-4',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    countryCode: '+290',
    timezone: 'GMT+0',
    currency: 'Saint Helena Pound',
    language: 'English',
  },
  {
    name: 'Saint Kitts and Nevis',
    countryCode: '+1869',
    timezone: 'GMT-4',
    currency: 'Eastern Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Saint Lucia',
    countryCode: '+1758',
    timezone: 'GMT-4',
    currency: 'Eastern Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Saint Martin',
    countryCode: '+590',
    timezone: 'GMT-4',
    currency: 'Euro',
    language: 'English',
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryCode: '+508',
    timezone: 'GMT-3',
    currency: 'Euro',
    language: 'French',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCode: '+1784',
    timezone: 'GMT-4',
    currency: 'Eastern Caribbean Dollar',
    language: 'English',
  },
  {
    name: 'Samoa',
    countryCode: '+685',
    timezone: 'GMT+13',
    currency: 'Samoan Tala',
    language: 'English',
  },
  {
    name: 'San Marino',
    countryCode: '+378',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Italian',
  },
  {
    name: 'Sao Tome and Principe',
    countryCode: '+239',
    timezone: 'GMT+0',
    currency: 'Sao Tome and Principe Dobra',
    language: 'Portuguese',
  },
  {
    name: 'Saudi Arabia',
    countryCode: '+966',
    timezone: 'GMT+3',
    currency: 'Saudi Riyal',
    language: 'Arabic',
  },
  {
    name: 'Senegal',
    countryCode: '+221',
    timezone: 'GMT+0',
    currency: 'CFA Franc BCEAO',
    language: 'French',
  },
  {
    name: 'Serbia',
    countryCode: '+381',
    timezone: 'GMT+1',
    currency: 'Serbian Dinar',
    language: 'Serbian',
  },
  {
    name: 'Seychelles',
    countryCode: '+248',
    timezone: 'GMT+4',
    currency: 'Seychellois Rupee',
    language: 'Seychellois Creole',
  },
  {
    name: 'Sierra Leone',
    countryCode: '+232',
    timezone: 'GMT+0',
    currency: 'Sierra Leonean Leone',
    language: 'English',
  },
  {
    name: 'Singapore',
    countryCode: '+65',
    timezone: 'GMT+8',
    currency: 'Singapore Dollar',
    language: 'English',
  },
  {
    name: 'Slovakia',
    countryCode: '+421',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Slovak',
  },
  {
    name: 'Slovenia',
    countryCode: '+386',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Slovenian',
  },
  {
    name: 'Solomon Islands',
    countryCode: '+677',
    timezone: 'GMT+11',
    currency: 'Solomon Islands Dollar',
    language: 'English',
  },
  {
    name: 'Somalia',
    countryCode: '+252',
    timezone: 'GMT+3',
    currency: 'Somali Shilling',
    language: 'Arabic',
  },
  {
    name: 'South Africa',
    countryCode: '+27',
    timezone: 'GMT+2',
    currency: 'South African Rand',
    language: 'Afrikaans',
  },
  {
    name: 'South Sudan',
    countryCode: '+211',
    timezone: 'GMT+3',
    currency: 'South Sudanese Pound',
    language: 'English',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    countryCode: '+500',
    timezone: 'GMT-2',
    currency: 'Pound Sterling',
    language: 'English',
  },
  {
    name: 'Spain',
    countryCode: '+34',
    timezone: 'GMT+1',
    currency: 'Euro',
    language: 'Spanish',
  },
  {
    name: 'Sri Lanka',
    countryCode: '+94',
    timezone: 'GMT+5:30',
    currency: 'Sri Lankan Rupee',
    language: 'Sinhala',
  },
  {
    name: 'Sudan',
    countryCode: '+249',
    timezone: 'GMT+2',
    currency: 'Sudanese Pound',
    language: 'Arabic',
  },
  {
    name: 'Suriname',
    countryCode: '+597',
    timezone: 'GMT-3',
    currency: 'Surinamese Dollar',
    language: 'Dutch',
  },
  {
    name: 'Svalbard and Jan Mayen',
    countryCode: '+47',
    timezone: 'GMT+1',
    currency: 'Norwegian Krone',
    language: 'Norwegian',
  },
  {
    name: 'Swaziland',
    countryCode: '+268',
    timezone: 'GMT+2',
    currency: 'Swazi Lilangeni',
    language: 'Swazi',
  },
  {
    name: 'Sweden',
    countryCode: '+46',
    timezone: 'GMT+1',
    currency: 'Swedish Krona',
    language: 'Swedish',
  },
  {
    name: 'Switzerland',
    countryCode: '+41',
    timezone: 'GMT+1',
    currency: 'Swiss Franc',
    language: 'Romansh',
  },
  {
    name: 'Syrian Arab Republic',
    countryCode: '+963',
    timezone: 'GMT+2',
    currency: 'Syrian Pound',
    language: 'Arabic',
  },
  {
    name: 'Taiwan',
    countryCode: '+886',
    timezone: 'GMT+8',
    currency: 'New Taiwan Dollar',
    language: 'Mandarin',
  },
  {
    name: 'Tajikistan',
    countryCode: '+992',
    timezone: 'GMT+5',
    currency: 'Tajikistani Somoni',
    language: 'Russian',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    countryCode: '+255',
    timezone: 'GMT+3',
    currency: 'Tanzanian Shilling',
    language: 'Swahili',
  },
  {
    name: 'Thailand',
    countryCode: '+66',
    timezone: 'GMT+7',
    currency: 'Thai Baht',
    language: 'Thai',
  },
  {
    name: 'Timor-Leste',
    countryCode: '+670',
    timezone: 'GMT+9',
    currency: 'United States Dollar',
    language: 'Tetum',
  },
  {
    name: 'Togo',
    countryCode: '+228',
    timezone: 'GMT+0',
    currency: 'West African CFA franc',
    language: 'French',
  },
  {
    name: 'Tokelau',
    countryCode: '+690',
    timezone: 'GMT+13',
    currency: 'New Zealand Dollar',
    language: 'Tokelauan',
  },
  {
    name: 'Tonga',
    countryCode: '+676',
    timezone: 'GMT+13',
    currency: 'Tongan Paʻanga',
    language: 'Tongan',
  },
  {
    name: 'Trinidad and Tobago',
    countryCode: '+1868',
    timezone: 'GMT-4',
    currency: 'Trinidad and Tobago Dollar',
    language: 'English',
  },
  {
    name: 'Tunisia',
    countryCode: '+216',
    timezone: 'GMT+1',
    currency: 'Tunisian Dinar',
    language: 'Arabic',
  },
  {
    name: 'Turkey',
    countryCode: '+90',
    timezone: 'GMT+3',
    currency: 'Turkish Lira',
    language: 'Turkish',
  },
  {
    name: 'Turkmenistan',
    countryCode: '+993',
    timezone: 'GMT+5',
    currency: 'Turkmenistan Manat',
    language: 'Turkmen',
  },
  {
    name: 'Turks and Caicos Islands',
    countryCode: '+1649',
    timezone: 'GMT-5',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Tuvalu',
    countryCode: '+688',
    timezone: 'GMT+12',
    currency: 'Tuvaluan Dollar',
    language: 'Tuvaluan',
  },
  {
    name: 'Uganda',
    countryCode: '+256',
    timezone: 'GMT+3',
    currency: 'Ugandan Shilling',
    language: 'Swahili',
  },
  {
    name: 'Ukraine',
    countryCode: '+380',
    timezone: 'GMT+2',
    currency: 'Ukrainian Hryvnia',
    language: 'Ukrainian',
  },
  {
    name: 'United Arab Emirates',
    countryCode: '+971',
    timezone: 'GMT+4',
    currency: 'United Arab Emirates Dirham',
    language: 'Arabic',
  },
  {
    name: 'United Kingdom',
    countryCode: '+44',
    timezone: 'GMT+0',
    currency: 'British Pound Sterling',
    language: 'English',
  },
  {
    name: 'United States',
    countryCode: '+1',
    timezone: 'GMT-10',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Uruguay',
    countryCode: '+598',
    timezone: 'GMT-3',
    currency: 'Uruguayan Peso',
    language: 'Spanish',
  },
  {
    name: 'Uzbekistan',
    countryCode: '+998',
    timezone: 'GMT+5',
    currency: 'Uzbekistani Som',
    language: 'Uzbek',
  },
  {
    name: 'Vanuatu',
    countryCode: '+678',
    timezone: 'GMT+11',
    currency: 'Vanuatu Vatu',
    language: 'Bislama',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    countryCode: '+58',
    timezone: 'GMT-4',
    currency: 'Venezuelan Bolívar',
    language: 'Spanish',
  },
  {
    name: 'Vietnam',
    countryCode: '+84',
    timezone: 'GMT+7',
    currency: 'Vietnamese Dong',
    language: 'Vietnamese',
  },
  {
    name: 'Virgin Islands, British',
    countryCode: '+1284',
    timezone: 'GMT-4',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Virgin Islands, U.S.',
    countryCode: '+1340',
    timezone: 'GMT-4',
    currency: 'United States Dollar',
    language: 'English',
  },
  {
    name: 'Wallis and Futuna',
    countryCode: '+681',
    timezone: 'GMT+12',
    currency: 'CFP Franc',
    language: 'French',
  },
  {
    name: 'Yemen',
    countryCode: '+967',
    timezone: 'GMT+3',
    currency: 'Yemeni Rial',
    language: 'Arabic',
  },
  {
    name: 'Zambia',
    countryCode: '+260',
    timezone: 'GMT+2',
    currency: 'Zambian Kwacha',
    language: 'English',
  },
  {
    name: 'Zimbabwe',
    countryCode: '+263',
    timezone: 'GMT+2',
    currency: 'Zimbabwean Dollar',
    language: 'English',
  },
  {
    name: 'Other',
    countryCode: '-',
    timezone: 'GMT+0',
    currency: 'United States Dollar',
    language: 'English',
  },
];

export default countryEn;

import { useEffect, useState, useCallback, useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { Controller, useForm } from 'react-hook-form';
import { Flex, LoadingPage } from '@/layout';
import { CardWrapper, CompanyPic, SearchBar } from './style';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import DataTable, { FileMenu, TypeColumn } from '@/components/DataTable';
import { StatusTag } from '@/components/DealList/DealListItem/styles';
import { DeleteModal, InvitationModal, PermissionModal } from './Modal';
import { addSubAccountSendMails, getSubAccountList } from '@/api/auth.api';
import { errorHandler } from '@/utils/toastHandler';
import { VerifyModal } from '@/components/UI/Modal';

type MembersInfo = {
  id: string;
  name: string;
  email: string;
  jobPosition: string;
  status: string;
};

type SubAccount = MemberProfile & {
  review_status: string;
  reviewd_by: string;
};

const AddNewMembers = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const rawColumn = t('pages.home.addNewMembers.tabs', { returnObjects: true });
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState<MembersInfo[]>();

  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showPermissionModal, setPermissionModal] = useState<boolean>(false);
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);
  const [failedCounter, setFailedCounter] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { resetField, handleSubmit, control } = useForm<{ emails: string }>();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const res = (await addSubAccountSendMails({ emails: data.emails })).data;
      if (res.success) {
        setShowInviteModal(true);
        initData();
      }
    } catch (error: any) {
      errorHandler(error.response.data.data);
    } finally {
      resetField('emails');
      setIsLoading(false);
    }
  });

  const initData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = (await getSubAccountList()).data;
      if (res.success) {
        const listData = res.data.map((item: SubAccount) => ({
          id: item.id,
          name: item.acc_firstName ? item.acc_firstName + item.acc_lastName : '-',
          email: item.email,
          jobPosition: item.acc_jobPosition ? item.acc_jobPosition : '-',
          status: item.acc_firstName ? item.review_status : '0',
        }));

        // 移除第一個元素
        setMemberList(listData.slice(1));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  const menuItems: DataTableMenuItems[] = useMemo(
    () => [
      // {
      //   name: t('buttons.permissions'),
      //   onClick: (path) => {
      //     setPermissionModal(true);
      //   },
      // },
      {
        name: t('buttons.edit'),
        onClick: (path) => {
          navigate(`/profile/addNewMembers/edit/${path}`);
        },
      },
      {
        name: t('buttons.delete'),
        onClick: (path) => {
          setShowDeleteModal(true);
        },
      },
    ],
    [t]
  );

  const columns: ColumnDef<MembersInfo>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: rawColumn[0],
        sortable: true,
        cell: ({ row }) => (
          <Flex alignItems={'center'} gridGap={'10px'}>
            <CompanyPic />
            <TypeColumn text={row.original.name} />
          </Flex>
        ),
      },
      {
        accessorKey: 'email',
        header: rawColumn[1],
        cell: ({ row }) => <TypeColumn text={row.original.email} />,
      },
      {
        accessorKey: 'jobPosition',
        header: rawColumn[2],
      },
      {
        accessorKey: 'action',
        header: rawColumn[3],
        cell: ({ row }) => {
          if (row.original.status === '0') {
            return <StatusTag>{t('buttons.inviting')}</StatusTag>;
          } else {
            //   let added = hasMatchingProps(docLibrary, fileList, 'id', 'document_library_id');
            return <FileMenu path={row.original.id} items={menuItems} fileType={'pdf'} />;
          }
        },
      },
    ],
    [menuItems, rawColumn, t]
  );

  return (
    <PageContainer
      title={
        <InnerPageTitle
          link={'/profile/edit'}
          title={t('buttons.home')}
          subtitle={t('pages.home.addNewMembers.title')}
        />
      }
    >
      <LoadingPage isLoading={isLoading} />
      <InvitationModal showModal={showInviteModal} setShowModal={setShowInviteModal} />
      <DeleteModal showModal={showDeleteModal} setShowModal={setShowDeleteModal} />
      <PermissionModal showModal={showPermissionModal} setShowModal={setPermissionModal} />
      <VerifyModal
        formSubmit={onSubmit}
        type={'sentMail'}
        failedCounter={failedCounter}
        showModal={showVerifyModal}
        setShowModal={setShowVerifyModal}
      />
      <CardWrapper>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <SearchBar
            onSubmit={(e) => {
              e.preventDefault();
              setShowVerifyModal(true);
            }}
          >
            <Controller
              control={control}
              defaultValue=""
              name={'emails'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div style={{ width: '100%' }}>
                  <TextField
                    placeholder={t('form.sendInvite.placeholder')}
                    variant="outlined"
                    value={value}
                    sx={{ width: '100%', background: '#FFF', borderRadius: '5px 0 0 5px' }}
                    onChange={onChange}
                    size="small"
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                </div>
              )}
            />
            <Button
              type="button"
              size={'md'}
              variant={'primary'}
              rounded={false}
              onClick={() => setShowVerifyModal(true)}
            >
              {t('buttons.sendInvite')}
            </Button>
          </SearchBar>
        </Flex>
        {memberList ? <DataTable columns={columns} data={memberList} /> : <Loading />}
      </CardWrapper>
    </PageContainer>
  );
};

export default AddNewMembers;

import styled from 'styled-components';

const FormWrapper = styled.form`
  width: 100%;
  max-width: 930px;
  padding: 30px 50px;
  margin-bottom: 100px;
  background: #ffffff;
  border-radius: 15px;
`;

const CartInfoWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
`;

const Title = styled.div`
  color: ${(p) => p.theme.colors.gray500};
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 700;
  margin-bottom: 20px;
`;

const CartInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(p) => p.theme.colors.gray100};
  padding: 0 15px;
  border-bottom: 2px solid ${(p) => p.theme.colors.gray100};

  &:nth-child(odd) {
    background: ${(p) => p.theme.colors.gray100};
  }

  &:nth-child(even) {
    background: ${(p) => p.theme.colors.light};
  }

  .item_detail {
    display: flex;
    align-items: center;
    gap: 20px;

    .selector {
      min-width: 60px;

      .MuiInputBase-root {
        margin-top: 0;
      }
    }
  }

  &.header {
    background: ${(p) => p.theme.colors.light};
    border: 2px solid ${(p) => p.theme.colors.gray500};
    height: 60px;

    * {
      padding: 0 5px;
      display: flex;
      align-items: center;
    }

    .order_items {
      height: 100%;
      margin-left: 25px;
      border-left: 2px solid ${(p) => p.theme.colors.gray500};
    }

    .item_detail {
      height: 100%;
      .item_total {
        width: 50px;
      }

      .selector,
      .item_total {
        height: 100%;
        border-left: 2px solid ${(p) => p.theme.colors.gray500};
        word-break: keep-all;
      }
    }
  }
`;

const TotalWrapper = styled.div`
  text-align: right;
  margin-top: 20px;
  font-size: ${(p) => p.theme.fontSizes.base};
  color: ${(p) => p.theme.colors.gray500};

  span {
    font-size: ${(p) => p.theme.fontSizes.md};
    font-weight: 700;
    color: ${(p) => p.theme.colors.red};
  }

  .total {
    display: inline-block;
    color: ${(p) => p.theme.colors.gray500};
    font-size: ${(p) => p.theme.fontSizes.base};
    margin-right: 55px;
  }
`;

export { FormWrapper, CartInfoWrapper, Title, CartInfoItem, TotalWrapper };

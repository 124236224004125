import styled from 'styled-components';

const PolicyWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 25px 50px 20px 50px;
  margin-bottom: 25px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 1);
  position: relative;
  top: -200px;

  // 增加展開時和點擊關閉時的動畫效果
  transition: all 1s ease;

  &.active {
    top: 0px;
  }

  &.hidden {
    display: none;
  }
`;

export { PolicyWrapper };

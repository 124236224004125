import { useMemo, useState, useEffect, useCallback } from 'react';
// import DataTable, { TableColumn, TableStyles } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import type { ColumnDef } from '@tanstack/react-table';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import DataTable from '@/components/DataTable';
import FormWrapper from '@/components/FormStepper/Wrapper';
import Company from '@/components/Company';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import SearchHeader from './SearchHeader';
import { LoadingPage } from '@/layout';

import { getDealCompanyList, inviteCompany } from '@/api/deal.api';

interface MoreCompanyProps {
  invitedParticipantList?: {
    company: DealInvitedCompany[];
    personal: DealInvitedPersonal[];
  };
  setInvitedParticipantList: React.Dispatch<
    React.SetStateAction<
      | {
          company: DealInvitedCompany[];
          personal: DealInvitedPersonal[];
        }
      | undefined
    >
  >;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  queryId: string | null;
}

const MoreCompany = ({ invitedParticipantList, setInvitedParticipantList, queryId, setSelectedTab }: MoreCompanyProps) => {
    // @ts-ignore
  const { t } = useTranslation();

  const columns: ColumnDef<CompanyDetail>[] = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: '',
        accessorFn: (row) => row,
        cell: ({ row }) => {
          const invited = invitedParticipantList
            ? invitedParticipantList.company.some((company) => company.acc_id === row.original.id)
            : false;
          return (
            <Company
              key={`co-${row.original.id}`}
              data={row.original}
              children={
                // 若列表中已有邀請的公司，則不顯示邀請按鈕
                invitedParticipantList && invitedParticipantList.company.length === 0 ? (
                  <Button
                  variant={invited ? 'normal' : 'primary'}
                  size={'table'}
                  disabled={invited}
                  onClick={async () => {
                    if (!queryId) return;
                    setIsLoading(true);
                    try {
                      const res = (await inviteCompany({ acc_id: row.original.id, deal_id: queryId })).data;
                      setInvitedParticipantList(res.data);
                      successHandler(t('success.invite'));
                      setSelectedTab(1)
                    } catch (error: any) {
                      errorHandler(error.response.data.data);
                    }
                    setIsLoading(false);
                  }}
                >
                  {invited ? t('buttons.inviting') : t('buttons.invite')}
                </Button>
                )
                : null
              }
            />
          );
        },
      },
    ],
    [invitedParticipantList, queryId, setInvitedParticipantList, t]
  );

  const [companyList, setCompanyList] = useState<CompanyDetail[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCompanyList = useCallback(async () => {
    try {
      const res = (await getDealCompanyList()).data;
      setCompanyList(res.data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);

  return (
    <FormWrapper>
      <LoadingPage isLoading={isLoading} />
      <SearchHeader setCompanyList={setCompanyList} />
      {companyList ? <DataTable columns={columns} data={companyList} /> : <Loading />}
    </FormWrapper>
  );
};

export default MoreCompany;

const fakeData: FilesTable[] = [
  {
    isOfficialDoc: true,
    name: 'Sales-Contract-Template[demo]',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: true,
      isSign: false,
    },
    prcSigning: ['Cody Fisher', 'Esther Howard', 'Perry'],
    hkSigning: ['Michelle', 'Perry'],
  },
  {
    isOfficialDoc: true,
    name: 'Legal agreement',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: true,
      isSign: false,
    },
    prcSigning: ['Cody Fisher', 'Esther Howard'],
    hkSigning: ['Wang,Xiao-Ming', 'Cody Howard'],
  },
  {
    isOfficialDoc: true,
    name: 'RFQs v4.0',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: true,
      isSign: false,
    },
    prcSigning: ['Paula', 'Phillips'],
    hkSigning: ['Wang,Xiao-Ming', 'Cody Howard'],
  },
  {
    isOfficialDoc: true,
    name: 'RFQs v4.0',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: true,
      isSign: true,
    },
    prcSigning: ['Gary', 'Gregorio', 'Grayson'],
    hkSigning: ['Wang,Xiao-Ming', 'Cody Howard'],
  },
  {
    isOfficialDoc: false,
    name: 'Business Contract',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: false,
      isSign: false,
    },
    prcSigning: ['Cody Fisher', 'Esther Howard'],
    hkSigning: ['Wang,Xiao-Ming', 'Cody Howard'],
  },
  {
    isOfficialDoc: false,
    name: 'Business Contract',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: false,
      isSign: false,
    },
    prcSigning: ['Gary', 'Gregorio', 'Grayson'],
    hkSigning: ['Wang,Xiao-Ming', 'Cody Howard'],
  },
  {
    isOfficialDoc: false,
    name: 'Business Contract',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: false,
      isSign: false,
    },
    prcSigning: ['Cody Fisher', 'Esther Howard'],
    hkSigning: ['Michelle', 'Perry'],
  },
  {
    isOfficialDoc: false,
    name: 'Business Contract',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: false,
      isSign: false,
    },
    prcSigning: ['Cody Fisher', 'Esther Howard'],
    hkSigning: ['Michelle', 'Perry'],
  },
  {
    isOfficialDoc: false,
    name: 'Business Contract',
    folder: 'RFQ',
    type: 'pdf',
    size: '2 MB',
    updated: '2022/07/31  16:14:08',
    owner: '',
    action: '',
    sign: {
      need: false,
      isSign: false,
    },
    prcSigning: ['Cody Fisher', 'Esther Howard'],
    hkSigning: ['Wang,Xiao-Ming', 'Cody Howard'],
  },
].map((data, index) => ({
  ...data,
  id: index.toString(),
  prcSigning: data.prcSigning.map((p) => ({ name: p, sign: false })),
  hkSigning: data.hkSigning.map((p) => ({ name: p, sign: false })),
}));

export default fakeData;

import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Checkbox, FormControlLabel } from '@mui/material';
import { Box, Flex } from '@/layout';
import { ModalForm, ModalTitle, PermissionWrapper } from '../style';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
}

const InvitationModal = ({ showModal, setShowModal }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <ModalTitle>{t('pages.home.addNewMembers.modal.invitation')}</ModalTitle>

        <Box style={{ marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button onClick={() => setShowModal(false)} size={'lg'} variant={'primary'}>
            {t('buttons.ok')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

const DeleteModal = ({ showModal, setShowModal }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <ModalTitle>{t('pages.home.addNewMembers.modal.delete')}</ModalTitle>

        <Box
          style={{ marginTop: '50px', display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'center' }}
        >
          <Button size={'lg'} variant={'primary'}>
            {t('buttons.yes')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'underline' }}
          >
            {t('buttons.cancel')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

const PermissionModal = ({ showModal, setShowModal }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [permissions, setPermissions] = React.useState([
    {
      title: 'Sign Document',
      check: false,
    },
    {
      title: 'Meeting',
      check: false,
    },
    {
      title: 'Deal',
      check: false,
    },
  ]);

  const changePermission = (index: number) => {
    const newPermissions = [...permissions];
    newPermissions[index].check = !newPermissions[index].check;
    setPermissions(newPermissions);
  };

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm style={{ top: 'calc(50% - 200px)' }}>
        <ModalTitle style={{ textAlign: 'left' }}>Permission Management</ModalTitle>

        <Flex gridGap={10}>
          <Text color={theme.colors.primary500}>Name</Text>
          <Text>John Lee</Text>
        </Flex>

        <Box marginTop={15}>
          {permissions.map((p, i) => (
            <PermissionWrapper key={p.title}>
              <Flex>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: '#999999',
                        '&.Mui-checked': {
                          color: `${theme.colors.secondary500}`,
                        },
                      }}
                      checked={p.check}
                      onClick={() => changePermission(i)}
                      name="terms"
                    />
                  }
                  label={<span>{p.title}</span>}
                />
              </Flex>
            </PermissionWrapper>
          ))}
        </Box>

        <Box style={{ marginTop: '50px', display: 'flex', gap: '30px', alignItems: 'center' }}>
          <Button size={'lg'} variant={'primary'}>
            {t('buttons.ok')}
          </Button>
          <Button
            size={'lg'}
            variant={'primary-invert'}
            color="primary"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'underline' }}
          >
            {t('buttons.cancel')}
          </Button>
        </Box>
      </ModalForm>
    </Modal>
  );
};

export { InvitationModal, DeleteModal, PermissionModal };

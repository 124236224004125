import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProgressBar, TopWrapper } from './style';
import { H3 } from '@/components/UI/Typography/Heading';
import { convertToFileSize } from '@/utils/convert';
import { StorageAlmostFullModal, StorageFullModal } from '@/components/DocLibrary/Modal';
import { getStorageSpace } from '@/api/auth.api';

interface StorageBarProps {
  showStorageAlmostFullModal: boolean;
  showStorageFullModal: boolean;
  setShowStorageAlmostFullModal: Dispatch<SetStateAction<boolean>>;
  setShowStorageFullModal: Dispatch<SetStateAction<boolean>>;
}

export const StorageBar = ({
  showStorageAlmostFullModal,
  showStorageFullModal,
  setShowStorageAlmostFullModal,
  setShowStorageFullModal,
}: StorageBarProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [remain, setRemain] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [used, setUsed] = useState<number>(0);

  const converPersentage = () => {
    // 計算使用量百分比
    const persentage = (used / total) * 100;
    return persentage;
  };

  const checkStorage = useCallback(async () => {
    try {
      const res = (await getStorageSpace()).data;
      if (res.success) {
        setTotal(res.data.total);
        setUsed(res.data.used);
        setRemain(res.data.remaining);

        // 檢查是否快滿了, 條件為 90%
        if (res.data.used / res.data.total > 0.9) {
          setShowStorageAlmostFullModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    checkStorage();
  }, [checkStorage]);

  return (
    <TopWrapper>
      <StorageAlmostFullModal
        showModal={showStorageAlmostFullModal}
        setShowModal={setShowStorageAlmostFullModal}
        remain={convertToFileSize(remain)}
      />
      <StorageFullModal showModal={showStorageFullModal} setShowModal={setShowStorageFullModal} />
      <div className="title">
        <div className="icon">
          <img src="/images/docLibrary/cloud.svg" alt="" />
        </div>
        <span>{t('pages.documentLibrary.storage.title')}</span>
      </div>
      <div className="link">
        <Link to="/profile/subscriptionDetail">{t('pages.documentLibrary.storage.getMoreStorage')}</Link>
      </div>
      <div className="text">
        <H3>
          {t('pages.documentLibrary.storage.used', {
            used: convertToFileSize(used),
            total: convertToFileSize(total),
          })}
        </H3>
      </div>
      <ProgressBar>
        <div style={{ width: `${converPersentage()}%` }}></div>
      </ProgressBar>
    </TopWrapper>
  );
};

export const planFeatures: {
  [key: string]: { title: string; features: { name: string; times: string; key: string; check: boolean, allowIcon: boolean }[] }[];
} = {
  en: [
    {
      title: 'Free',
      features: [
        {
          name: 'e-Directory, Deal Management Flow',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: 'e-Signature',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Video Conference',
          times: 'Unlimited',
          key: '(With real-time transcription and translation)',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Document Translation',
          times: 'Unlimited',
          key: '(Available in 15 languages globally)',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Access to Contract Templates',
          times: 'Unlimited',
          key: '(Drafted by Legal Experts)',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Cross-border e-Signature',
          times: '0',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: true,
        },
      ],
    },
    {
      title: 'Standard',
      features: [
        {
          name: 'e-Directory listing and search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'editing content of e-Directory',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'AI Machine Translation <br/>(File size: Max 100MB per file; File format: docx, pdf, xlsx, pptx, txt)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'eBRAM Video Conference <br/>(1 session at a time with 20 attendees at max)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI search',
          times: 'Unlimited',
          key: 'LEI search',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Hongkongpost e-Cert (Organization) Application',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'Contract template',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'e-Signature (eBRAM)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
      ],
    },
    {
      title: 'Standard',
      features: [
        {
          name: 'e-Directory listing and search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'editing content of e-Directory',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'AI Machine Translation <br/>(File size: Max 100MB per file; File format: docx, pdf, xlsx, pptx, txt)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'eBRAM Video Conference <br/>(1 session at a time with 20 attendees at max)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Contract template',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'e-Signature (eBRAM)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
      ],
    },
    {
      title: 'Cross-border (HK)',
      features: [
        {
          name: 'e-Directory listing and search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'editing content of e-Directory',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'AI Machine Translation <br/>(File size: Max 100MB per file; File format: docx, pdf, xlsx, pptx, txt)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'eBRAM Video Conference <br/>(1 session at a time with 20 attendees at max)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI registration',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Hongkongpost e-Cert (Organization) Application',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'Contract template',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'e-Signature (eBRAM)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Cross-border e-Signature (HK)',
          times: '3',
          key: 'ESignatureHKTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: 'Cross-border (PRC)',
      features: [
        {
          name: 'e-Directory listing and search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'editing content of e-Directory',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'AI Machine Translation <br/>(File size: Max 100MB per file; File format: docx, pdf, xlsx, pptx, txt)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'eBRAM Video Conference <br/>(1 session at a time with 20 attendees at max)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI registration',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Contract template',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'e-Signature (eBRAM)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Cross-border e-Signature (China)',
          times: '3',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: 'Cross-border Pro (HK)',
      features: [
        {
          name: 'e-Directory listing and search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'editing content of e-Directory',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'AI Machine Translation <br/>(File size: Max 100MB per file; File format: docx, pdf, xlsx, pptx, txt)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'eBRAM Video Conference <br/>(1 session at a time with 20 attendees at max)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI registration',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Hongkongpost e-Cert (Organization) Application',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'Contract template',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'e-Signature (eBRAM)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Cross-border e-Signature (HK)',
          times: '10',
          key: 'ESignatureHKTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'PRC Corporate Credit Report Application',
          times: '1',
          key: 'ESignatureDigitalCertTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: 'Cross-border Pro (PRC)',
      features: [
        {
          name: 'e-Directory listing and search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'editing content of e-Directory',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'AI Machine Translation <br/>(File size: Max 100MB per file; File format: docx, pdf, xlsx, pptx, txt)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'eBRAM Video Conference <br/>(1 session at a time with 20 attendees at max)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI registration',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI search',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Contract template',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'e-Signature (eBRAM)',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'Cross-border e-Signature (China)',
          times: '10',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'PRC Corporate Credit Report Application',
          times: '1',
          key: 'ESignatureDigitalCertTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: 'Pro Plan',
      features: [
        {
          name: 'e-Directory, Deal Management Flow',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: 'e-Signature',
          times: 'Unlimited',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: 'Video Conference',
          times: 'Unlimited',
          key: '(With real-time transcription and translation)',
          check: false,
          allowIcon: true,
        },
        {
          name: 'Document Translation',
          times: 'Unlimited',
          key: '(Available in 15 languages globally)',
          check: false,
          allowIcon: true,
        },
        {
          name: 'Access to Contract Templates',
          times: 'Unlimited',
          key: '(Drafted by Legal Experts)',
          check: false,
          allowIcon: true,
        },
        {
          name: 'Cross-border e-Signature',
          times: '0',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: true,
        },
      ],
    },
  ],
  sc: [
    {
      title: '免费计划',
      features: [
        {
          name: '商业机构名录，交易管理流程',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: '电子签名',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '视频会议',
          times: '无上限',
          key: '(附即时抄录与翻译)',
          check: false,
          allowIcon: false,
        },
        {
          name: '文件翻译',
          times: '无上限',
          key: '(提供15种全球语言)',
          check: false,
          allowIcon: false,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '(由法律专家草拟)',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境签署',
          times: '0',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: true,
        }
      ],
    },
    {
      title: '标准 (香港)',
      features: [
        {
          name: '商业机构名录及搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自订商业机构名录内容',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻译 <br/>(文件大小: 每个文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '视象会议 <br/>(可同时使用一节视象会议; 最多20位参与者)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 编码搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '香港邮政电子证书申请(机构)',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地电子签署 (由eBRAM提供)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
      ],
    },
    {
      title: '标准 (中国)',
      features: [
        {
          name: '商业机构名录及搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自订商业机构名录内容',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻译 <br/>(文件大小: 每个文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '视象会议 <br/>(可同时使用一节视象会议; 最多20位参与者)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 编码搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地电子签署 (由eBRAM提供)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 (香港)',
      features: [
        {
          name: '商业机构名录及搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自订商业机构名录内容',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻译 <br/>(文件大小: 每个文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '视象会议 <br/>(可同时使用一节视象会议; 最多20位参与者)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律实体识别编码申请',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 编码搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '香港邮政电子证书申请(机构)',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地电子签署 (由eBRAM提供)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境签署(香港)',
          times: '3',
          key: 'ESignatureHKTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 (中国)',
      features: [
        {
          name: '商业机构名录及搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自订商业机构名录内容',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻译 <br/>(文件大小: 每个文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '视象会议 <br/>(可同时使用一节视象会议; 最多20位参与者)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律实体识别编码申请',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 编码搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地电子签署 (由eBRAM提供)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境签署(中国)',
          times: '3',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 Pro (香港)',
      features: [
        {
          name: '商业机构名录及搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自订商业机构名录内容',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻译 <br/>(文件大小: 每个文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '视象会议 <br/>(可同时使用一节视象会议; 最多20位参与者)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律实体识别编码申请',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 编码搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '香港邮政电子证书申请(机构)',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地电子签署 (由eBRAM提供)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境签署(香港)',
          times: '10',
          key: 'ESignatureHKTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '中国企业信用报告申请',
          times: '1',
          key: 'ESignatureDigitalCertTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 Pro (中国)',
      features: [
        {
          name: '商业机构名录，交易管理流程',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自订商业机构名录内容',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻译 <br/>(文件大小: 每个文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '视象会议 <br/>(可同时使用一节视象会议; 最多20位参与者)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律实体识别编码申请',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 编码搜寻',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地电子签署 (由eBRAM提供)',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境签署(中国)',
          times: '10',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '中国企业信用报告申请',
          times: '1',
          key: 'ESignatureDigitalCertTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '专业计画',
      features: [
        {
          name: '商业机构名录，交易管理流程',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: '电子签名',
          times: '无上限',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: '视频会议',
          times: '无上限',
          key: '(附即时抄录与翻译)',
          check: false,
          allowIcon: true,
        },
        {
          name: '文件翻译',
          times: '无上限',
          key: '(提供15种全球语言)',
          check: false,
          allowIcon: true,
        },
        {
          name: '合约范本',
          times: '无上限',
          key: '(由法律专家草拟)',
          check: false,
          allowIcon: true,
        },
        {
          name: '跨境签署',
          times: '0',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: true,
        }
      ],
    },
  ],
  tc: [
    {
      title: '免費計畫',
      features: [
        {
          name: '商業機構名錄，交易管理流程',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: '電子簽名',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '視訊會議',
          times: '無上限',
          key: '(附即時抄寫與翻譯)',
          check: false,
          allowIcon: false,
        },
        {
          name: '文件翻譯',
          times: '無上限',
          key: '(提供15種全球語言)',
          check: false,
          allowIcon: false,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '(由法律專家草擬)',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境簽署',
          times: '0',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '標準 (香港)',
      features: [
        {
          name: '商業機構名錄及搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自訂商業機構名錄內容',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻譯 <br/>(文件大小: 每個文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '視象會議 <br/>(可同時使用一節視象會議; 最多20位參與者)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 編碼搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '香港郵政電子證書申請(機構)',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地電子簽署 (由eBRAM提供)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
      ],
    },
    {
      title: '標準 (中國)',
      features: [
        {
          name: '商業機構名錄及搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自訂商業機構名錄內容',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻譯 <br/>(文件大小: 每個文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '視象會議 <br/>(可同時使用一節視象會議; 最多20位參與者)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 編碼搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地電子簽署 (由eBRAM提供)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 (香港)',
      features: [
        {
          name: '商業機構名錄及搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自訂商業機構名錄內容',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻譯 <br/>(文件大小: 每個文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '視象會議 <br/>(可同時使用一節視象會議; 最多20位參與者)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律實體識別編碼申請',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 編碼搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '香港郵政電子證書申請(機構)',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地電子簽署 (由eBRAM提供)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境簽署(香港)',
          times: '3',
          key: 'ESignatureHKTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 (中國)',
      features: [
        {
          name: '商業機構名錄及搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自訂商業機構名錄內容',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻譯 <br/>(文件大小: 每個文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '視象會議 <br/>(可同時使用一節視象會議; 最多20位參與者)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律實體識別編碼申請',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 編碼搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地電子簽署 (由eBRAM提供)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境簽署(中國)',
          times: '3',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 Pro (香港)',
      features: [
        {
          name: '商業機構名錄及搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自訂商業機構名錄內容',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻譯 <br/>(文件大小: 每個文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '視象會議 <br/>(可同時使用一節視象會議; 最多20位參與者)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律實體識別編碼申請',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 編碼搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '香港郵政電子證書申請(機構)',
          times: '1',
          key: 'ECertApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地電子簽署 (由eBRAM提供)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境簽署(香港)',
          times: '10',
          key: 'ESignatureHKTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '中國企業信用報告申請',
          times: '1',
          key: 'ESignatureDigitalCertTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '跨境 Pro (中國)',
      features: [
        {
          name: '商業機構名錄及搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '自訂商業機構名錄內容',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '人工智能AI翻譯 <br/>(文件大小: 每個文件最大100MB; 文件格式: docx, pdf, xlsx, pptx,txt)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '視象會議 <br/>(可同時使用一節視象會議; 最多20位參與者)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: 'LEI 法律實體識別編碼申請',
          times: '1',
          key: 'LEIApplicantTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: 'LEI 編碼搜尋',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '本地電子簽署 (由eBRAM提供)',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: false,
        },
        {
          name: '跨境簽署(中國)',
          times: '10',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: false,
        },
        {
          name: '中國企業信用報告申請',
          times: '1',
          key: 'ESignatureDigitalCertTimes',
          check: true,
          allowIcon: false,
        },
      ],
    },
    {
      title: '專業計畫',
      features: [
        {
          name: '商業機構名錄，交易管理流程',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: '電子簽名',
          times: '無上限',
          key: '',
          check: false,
          allowIcon: true,
        },
        {
          name: '視訊會議',
          times: '無上限',
          key: '(附即時抄寫與翻譯)',
          check: false,
          allowIcon: true,
        },
        {
          name: '文件翻譯',
          times: '無上限',
          key: '(提供15種全球語言)',
          check: false,
          allowIcon: true,
        },
        {
          name: '合約範本',
          times: '無上限',
          key: '(由法律專家草擬)',
          check: false,
          allowIcon: true,
        },
        {
          name: '跨境簽署',
          times: '0',
          key: 'ESignaturePRCTimes',
          check: true,
          allowIcon: true,
        },
      ],
    },
  ],
};

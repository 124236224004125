import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/redux/store.hook';
import { getNotificationSelector } from '@/redux/notification/notification.slice';

import { Flex } from '@/layout';
import Tabs from '@/components/UI/Tabs';
import NotificationCard from '@/components/Notification/Cards';

import { CardTitle, CardWrapper, ContentWrapper, LinkButton } from '../style';
import { Link } from 'react-router-dom';
import PATH from '@/router/path';
import { convertNotification } from '@/utils/convert';

export default function NotificationsCard() {
  // @ts-ignore
  const { t } = useTranslation();
  const tabLabel = t('pages.home.notifications.tabs', { returnObjects: true });
  const initTabIndex = 1;
  const [selectedTab, setSelectedTab] = useState<number>(initTabIndex);
  const [notification, setNotification] = useState<NotificationCards[]>();
  const notifications = useAppSelector(getNotificationSelector);
  const { notify } = notifications;

  const tabs: TabsType = [
    {
      label: `${tabLabel[0]} (${notification ? notification.length : 0})`,
      index: initTabIndex,
      Component: () =>
        notification && notification.length !== 0 ? (
          <Flex width={'100%'} flexDirection={'column'} gridGap={'15px'}>
            <NotificationCard data={notification} showSelect={false} selectedAry={[] as number[]} />
          </Flex>
        ) : (
          <ContentWrapper>
            <span>{t('pages.home.notifications.noData')}</span>
          </ContentWrapper>
        ),
    },
    {
      label: `${tabLabel[1]} (0)`,
      index: 2,
      Component: () => (
        <ContentWrapper>
          <span>{t('pages.home.notifications.noData')}</span>
        </ContentWrapper>
      ),
    },
    {
      label: `${tabLabel[2]} (0)`,
      index: 3,
      Component: () => (
        <ContentWrapper>
          <span>{t('pages.home.notifications.noData')}</span>
        </ContentWrapper>
      ),
    },
  ];

  const initData = useCallback(() => {
    // const topFiveUnRead = notify?.filter((n) => n.status === 1).slice(0, 5);
    setNotification(convertNotification(notify.slice(0, 5)));
  }, [notify]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <CardWrapper>
      <CardTitle>
        {t('notifications')}
        <LinkButton>
          <Link to={PATH.home.notifications}>{t('buttons.showAll')}</Link>
        </LinkButton>
      </CardTitle>
      <Tabs tabs={tabs} selectedTab={selectedTab} onClick={setSelectedTab} />
    </CardWrapper>
  );
}

const LEICountryData: { [key: string]: { [key: string]: { [key: string]: string } } } = {
  HK: {
    sc: {
      Province: 'CN-HK - 香港特别行政区',
      City: '910000 - 香港',
      PostCode: '999077',
    },
    tc: {
      Province: 'CN-HK - 香港特別行政區',
      City: '910000 - 香港',
      PostCode: '999077',
    },
    en: {
      Province: 'CN-HK - Hong Kong',
      City: 'CN-HK - Hong Kong',
      PostCode: '999077',
    },
  },
  MO: {
    sc: {
      Province: 'CN-MO - 澳门特别行政区',
      City: '920000 - 澳门',
      PostCode: '999078',
    },
    tc: {
      Province: 'CN-MO - 澳門特別行政區',
      City: '920000 - 澳門',
      PostCode: '999078',
    },
    en: {
      Province: '920000 - Macao',
      City: 'CN-MO - Macao',
      PostCode: '999078',
    },
  },
};

export default LEICountryData;

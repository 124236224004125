import styled from 'styled-components';

const NullPageWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const NullContent = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.md};
    line-height: 30px;
    text-align: center;
    color: ${(p) => p.theme.colors.gray300};
    margin-bottom: 50px;
`

const BlueBtn = styled.button`
    width: 280px;
    padding: 14px;
    background: ${(p) => p.theme.colors.primary500};
    color: ${(p) => p.theme.colors.light};
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.md};
    line-height: 23px;
    cursor: pointer;
`

const ServiceCardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 868px;
`

const ServiceCard = styled.div`
    padding: 25px;
    background: ${(p) => p.theme.colors.light};
    border-radius: 15px;
    margin-bottom: 15px;
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    color: ${(p) => p.theme.colors.gray500};
    margin-bottom: 15px;
`

const TitleLink = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: ${(p) => p.theme.colors.primary500};
`

const ListWrapper = styled.div`
    display: block;
    width: 100%;
`

const AccountList = styled.div`
    display: flex;
    padding: 10px 0;

`

const Action = styled.div`
    display: flex;
`

const ProgressBar = styled.div`
    width: 100%;
    height: 10px;
    background: #E9E9E9;
    border-radius: 5px;
    margin-top: 10px;

    > div {
        height: 100%;
        background: ${(p) => p.theme.colors.secondary500};
        border-radius: 5px;
    }
`

const GreenLink = styled.div`
    width:100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: ${(p) => p.theme.colors.secondary500};
    margin-bottom: 50px;
    cursor: pointer;
`
const GreenLinkSpan = styled.span`
    display: inline-block;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: ${(p) => p.theme.colors.secondary500};
    margin-bottom: 50px;
    cursor: pointer;
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    padding-right: 5px;
`

const AccountsAction = styled.span`
    display: inline-block;
    margin-left: auto;
`

const AccountsActionBtn = styled.button`
    cursor: pointer;
    margin-left: 30px;
    color: ${(p) => p.theme.colors.secondary500};
`

export {
    NullPageWrapper,
    NullContent,
    BlueBtn,
    ServiceCardWrapper,
    ServiceCard,
    Title,
    TitleLink,
    ListWrapper,
    AccountList,
    ProgressBar,
    GreenLink,
    GreenLinkSpan,
    Icon,
    AccountsAction,
    AccountsActionBtn
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

const initialState: CartState = {
  products: [],
  total: 0,
  returnURL: '',
  itemData: {
    id: '',
    applyId: '',
  },
  userData: {
    acc_coname: '',
    acc_country: '',
    acc_address: '',
    com_email: '',
    acc_firstName: '',
    acc_lastName: '',
    acc_BR: '',
  },
};

export const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem(state, action: PayloadAction<CartState>) {
      // 計算部分在頁面上處理, 此處只需將傳入的資料加入state即可
      action.payload.products.forEach((item) => {
        state.products.push(item);
      });
      state.total += action.payload.total;
      state.userData = {
        ...action.payload.userData,
        acc_address: action.payload.userData.acc_address?.split('@@_@@').join(' '),
      };
      state.returnURL = action.payload.returnURL;
      state.itemData = action.payload.itemData;
    },
    removeItem(state, action: PayloadAction<number>) {
      const index = state.products.findIndex((item) => item.id === action.payload.toString());

      if (index !== -1) {
        state.total -= state.products[index].price;
        state.products.splice(index, 1);
      }
    },
    clearCart(state) {
      state.products = [];
      state.total = 0;
      state.itemData = {
        id: '',
        applyId: '',
      };
      state.userData = {
        acc_coname: '',
        acc_country: '',
        acc_address: '',
        com_email: '',
        acc_firstName: '',
        acc_lastName: '',
        acc_BR: '',
      };
    },
  },
}); 

export const { addItem, removeItem, clearCart } = cart.actions;

export const getCartSelector = (store: RootState) => store.cart;

export default cart.reducer;
